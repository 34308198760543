import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { ICON_TAGS } from '@apprentage/constants';
import { useFlags } from 'flagsmith/react';
import { FaPhotoVideo, FaPlus } from 'react-icons/fa';
import {
  FLAG_RESOURCE_TAGS_READONLY,
  NOTICE_RESOURCE_TAGS_READONLY_TITLE
} from '../../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../actions/FeatureFlags';
import { setCurrentModal } from '../../../../actions/Modals';
import { withAuthorization } from '../../../Session';
import { canCreateResourceTag } from '../../../../services/currentUser';
import { ORG_CONSOLE_DASHBOARD } from '../../../../constants/routes';
import { getResourceGroupTags } from '../../../../actions/Resources';
import withOrgConsole from '../../../App/withOrgConsole';
import TagsList from './List';
import EmptyData from '../../../ManageContent/EmptyData';
import Loading from '../../../Loading';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import Tabs from '../Tabs';

const ResourcesTags = () => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_RESOURCE_TAGS_READONLY]);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const resourceGroupTags = useSelector((state) => state.resourceGroupTags);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Current User
  const role = currentUser?.role || [];

  useEffect(() => {
    if (orgId) {
      dispatch(getResourceGroupTags({ orgId }));
    }
  }, [dispatch, orgId]);

  const addTag = () => {
    if (flags?.resource_tags_readonly?.enabled && flags?.resource_tags_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCE_TAGS_READONLY_TITLE
      }));
      return;
    }

    dispatch(setCurrentModal({
      key: 'manageResourceGroupTag',
      data: {
        editMode: false,
        label: '',
        orgId
      }
    }));
  };

  if (!resourceGroupTags.list && !resourceGroupTags.fetched) {
    return (
      <Loading text="Loading Tags..." />
    );
  }

  const emptyDataOptions = [
    {
      contentType: 'group',
      title: 'Resource Tag',
      subtitle: 'Keep resources organized with tags.',
      icon: ICON_TAGS
    }
  ];

  return (
    <div className="row">
      <div className="col-sm-12">

        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Resources"
            iconComponent={() => <FaPhotoVideo size={20} className='mr-2' />}
            route={ORG_CONSOLE_DASHBOARD}
          />

          <Tabs active="tags" />

          <div className="card-body border-left border-right border-bottom bg-white">
            <div className="card">
              <div className="card-header px-3 d-flex align-items-center justify-content-between">
                <h5 className="m-0">
                  <i className={`${ICON_TAGS} mr-1`} /> All Tags
                </h5>

                {canCreateResourceTag(role, orgType) && (
                  <button
                    className="btn btn-sm btn-primary"
                    type="button"
                    title="Add Group"
                    onClick={addTag}
                  >
                    <span className='d-flex align-items-center'>
                      <FaPlus />
                      <span className='font-weight-bold ml-1'>
                        New
                      </span>
                    </span>
                  </button>
                )}
              </div>

              <div className="card-body p-0">
                {!resourceGroupTags.list && (
                  <EmptyData
                    disabled={false}
                    className="m-3"
                    options={emptyDataOptions}
                    data={resourceGroupTags.list}
                  >
                    <button
                      type="button"
                      className="btn btn-md btn-primary"
                      onClick={addTag}
                      disabled={!canCreateResourceTag(role)}
                    >
                      <span className='d-flex align-items-center'>
                        <FaPlus />
                        <span className='font-weight-bold ml-1'>
                          New
                        </span>
                      </span>
                    </button>
                  </EmptyData>
                )}

                <TagsList
                  data={resourceGroupTags.list}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ResourcesTags);
