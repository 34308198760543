const pathwayState = {
  id: '',
  title: '',
  jobProfileId: '',
  courseIds: null,
  isPublic: false,
  searchEnginesEnabled: false // TODO list all keys
};

export default pathwayState;
