import isWholeNumber from '../utils/isWholeNumber';
import percentOfNumber from '../utils/percentOfNumber';
import * as twApi from './tw-api';

export const generateDefaultWageRates = ({
  ojtHours,
  entryWage,
  defaultPeriods
}) => {
  const ojtHoursPeriodSplit = ojtHours / defaultPeriods;

  const wage1 = percentOfNumber(47, entryWage);
  const wage2 = percentOfNumber(67, entryWage);
  // const wage3 = percentOfNumber(87, entryWage);
  const rates = [
    {
      wageAmount: parseFloat(wage1.toFixed(2)),
      duration: Math.ceil(ojtHoursPeriodSplit)
    },
    {
      wageAmount: parseFloat(wage2.toFixed(2)),
      duration: Math.ceil(ojtHoursPeriodSplit)
    }
    // {
    //   wageAmount: parseFloat(wage3.toFixed(2)),
    //   duration: Math.ceil(ojtHoursPeriodSplit)
    // }
  ];

  // Based on ojtHours value the split value could be a number with decimal
  // This makes sure all values are whole numbers and total up to the value of ojtHours
  if (!isWholeNumber(ojtHoursPeriodSplit)) {
    rates[rates.length - 1].duration = ojtHours - Math.ceil(ojtHoursPeriodSplit);
  }

  return rates;
};

/**
 * Gets Occupation by keyword
 * @param {string} keyword - OnetCode
 * @param {string} location - PA, Pittsburgh, PA, zipcode
 * @param {wages} bool - show wages associated with occupation
 * @returns {Promise<object>}
 */
export async function getOccupationWages({
  keyword,
  location,
  wages = true,
  training = true
}) {
  const response = await twApi.get('/v1/external/cos/occupation', {
    keyword,
    location,
    wages,
    training
  });
  if (response.OccupationDetail) {
    const { OccupationDetail = {} } = response;
    const occupationData = response && response.OccupationDetail ? OccupationDetail[0] : null;
    const { Wages } = occupationData;

    const data = {
      wages: Wages
    };

    return data;
  }
  return response;
}
