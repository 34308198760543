import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams, Redirect } from 'react-router-dom';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { roleReadable } from '@apprentage/utils';
import { FaSortAmountUp } from 'react-icons/fa';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { withAuthorization } from '../../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS, SLASH } from '../../../../../../constants/routes';
import {
  getApprenticeship,
  getApprenticeshipUser,
  resetApprenticeship,
  resetApprenticeshipUser
} from '../../../../../../actions/Apprenticeships';
import { getLogbook, resetLogbook } from '../../../../../../actions/Logbooks';
import { canAccessUserLogbookRecords } from '../../../../../../permissions/access';
import withOrgConsole from '../../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../../OrgConsoleSubheader';
import Tabs from '../../Tabs';
import DotMenu from '../DotMenu';
import Loading from '../../../../../Loading';
import UserTabs from '../UserTabs';
import RecordsTabs from './RecordsTabs';
import Vitals from './Vitals';
import ReviewRecords from './ReviewRecords';
import AllRecords from './AllRecords';

const ApprenticeshipUserRecords = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookHours = useSelector((state) => state.logbookHours);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  // Training Program User
  const apprenticeshipId = params?.apprenticeshipId || '';
  const apprenticeshipUserId = params?.apprenticeshipUserId || '';
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }
  }, [apprenticeshipId, dispatch]);

  useEffect(() => {
    if (apprenticeship?.id && apprenticeshipUser?.id) {
      dispatch(
        getLogbook({
          apprenticeshipId: apprenticeship?.id,
          userId: apprenticeshipUser?.id,
          orgId,
          logbookHours: true,
          logbookCompHours: true
        })
      );
    }
  }, [apprenticeship?.id, apprenticeshipUser?.id, dispatch, orgId]);

  useEffect(() => {
    if (apprenticeshipUserId) {
      dispatch(getApprenticeshipUser(apprenticeshipUserId, apprenticeshipId, orgId));
    }

    return function cleanup() {
      const { href } = window.location;

      // Only reset if not going to apprentice user route
      if (
        !href.includes(`${apprenticeshipRoute}/users/${apprenticeshipUserId}`)
      ) {
        dispatch(resetApprenticeshipUser());
        dispatch(resetLogbook());
      }

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipId, apprenticeshipRoute, apprenticeshipUserId, dispatch, orgId]);

  if (!canAccessUserLogbookRecords(role)) {
    return <Redirect to={SLASH} />;
  }

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program" />;
  }

  if (!apprenticeship.user) {
    return <Loading text="Loading Training Program User" />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            iconComponent={() => <FaSortAmountUp size={20} />}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="users" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              // pageTitle={apprenticeshipUser.name}
              route={`${apprenticeshipRoute}/users`}
            >
              <NameBlock
                key={apprenticeshipUser?.id}
                name={apprenticeshipUser?.name}
                nameSize="sm"
                title={roleReadable(apprenticeshipUser?.role, organization?.type)}
                profilePicture={apprenticeshipUser?.profilePicture || DEFAULT_PROFILE_PIC}
                pictureSize="sm"
                className="my-1"
              />
              <DotMenu />
            </OrgConsoleSubheader>

            <UserTabs active="records" />

            <div className="border-left border-right border-bottom">
              <Vitals
                className='pt-3 px-3'
              />

              <div className='p-3'>
                <RecordsTabs
                  active={activeTab}
                  onClick={(tab) => {
                    setActiveTab(tab);
                  }}
                />
                {activeTab === 'all' && (
                  <div
                    className='border-left border-right border-bottom p-3'
                  >
                    {((!logbook?.id && !logbook.fetched) || !logbookHours.fetched) && (
                      <Loading
                        className='position-relative'
                        text="Loading Logbook..."
                      />
                    )}

                    {!logbook?.id && logbook.fetched && (
                      <div className='mt-3 text-center'>
                        <p>
                          No Logbook.
                        </p>
                      </div>
                    )}

                    {logbook?.id && (
                      <AllRecords />
                    )}
                  </div>
                )}
                {activeTab === 'review' && (
                  <>
                    <div className='border-left border-right border-bottom'>
                      <ReviewRecords
                        className='p-3'
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUserRecords);
