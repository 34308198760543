import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';
import { PROJECTS_PAGINATION_LIMIT } from '../constants/api';

const table = 'projects';

export const fetchProjects = async ({
  orgId,
  locationId,
  title,
  skillIds,
  body,
  ids,
  order = 'title',
  page = 1,
  select,
  limit = PROJECTS_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit,
    orgId
  };

  if (orgId) {
    params['f.orgId[eq]'] = orgId;
  }

  if (locationId) {
    params['f.locationId[eq]'] = locationId;
  }

  if (skillIds) {
    params['f.skillIds[ov]'] = skillIds.join(',');
  }

  if (title) {
    params['f.title[ilike]'] = title;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchProjectsByTitle = ({
  title,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchProjects({
      orgId,
      title,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ projects: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by title ${table}`, error);
        reject(error);
      });
  });
};

export const fetchProjectsByBody = ({
  body,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchProjects({
      orgId,
      body,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ projects: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by body ${table}`, error);
        reject(error);
      });
  });
};

export const fetchProjectsBySkillIds = ({
  skillIds,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchProjects({
      orgId,
      skillIds,
      select: ['id', 'title', 'skillIds', 'createdAt']
    })
      .then((response) => {
        resolve({ projects: response?.items });
      })
      .catch((error) => {
        console.error(`fetch by skillIds ${table}`, error);
        reject(error);
      });
  });
};

export const fetchProject = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateProject = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};

export const createProject = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};

export const deleteProject = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`delete ${table}`, error);
      reject(error);
    });
  });
};
