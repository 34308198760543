import React, { Fragment } from 'react';
import Collapse from '@apprentage/components/dist/components/Collapse';
import Audio from './Audio';

const AudioFiles = ({
  className = '',
  files = []
}) => {
  return (
    <div className={`AudioFiles ${className}`}>
      {Array.isArray(files) && files.length > 0 && (
        <>
          {files.map((file) => {
            if (!file?.type.includes('audio/')) {
              return null;
            }

            return (
              <Fragment key={file?.id}>
                <Collapse
                  id={file?.id}
                  title="Transcript"
                  className="Collapse my-3"
                  p={3}
                >
                  <div>
                    {file?.transcript}
                  </div>
                  <Audio
                    url={file?.url}
                    type={file?.type}
                    className='mt-3'
                  />
                </Collapse>
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AudioFiles;
