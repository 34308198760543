function formatGroup({
  title,
  type,
  orgId,
  itemIds,
  userRoles,
  groupTags,
  authorUserId,
  authorUserName
}) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (type !== undefined) {
    data.type = {
      'en-US': type
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  if (authorUserId !== undefined) {
    data.authorUserId = {
      'en-US': authorUserId
    };
  }

  if (authorUserName !== undefined) {
    data.authorUserName = {
      'en-US': authorUserName
    };
  }

  /**
   * {array of strings || undefined || null } userRoles
   * FE will send null which is then converted to undefined
   */
  if (userRoles !== undefined) {
    /**
     * null || undefined = All users access
     *
     * We need to check for undefined because when adding/removing itemIds to a group
     * no userRole info changed, and it would then pass undefined to BE and make the group accessible to all Users
     */
    data.userRoles = {
      'en-US': userRoles === null ? undefined : userRoles // BE needs the value to be undefined when there are no userRoles
    };
  }

  /**
   * {array of strings || undefined || null } groupTags
   * FE will send null which is then converted to undefined
   */
  if (groupTags !== undefined) {
    data.groupTags = {
      'en-US': groupTags === null ? undefined : groupTags // BE needs the value to be undefined when there are no groupTags
    };
  }

  if (itemIds !== undefined) {
    data.itemIds = {
      'en-US': itemIds === null ? undefined : itemIds // BE needs the value to be undefined when there are no itemIds
    };
  }

  return { fields: { ...data } };
}

export default formatGroup;
