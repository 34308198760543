import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ORG_CONSOLE_LOCATIONS } from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';

const Tabs = ({
  active,
  className = 'mt-3'
}) => {
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Misc
  const queryParams = new URLSearchParams(window.location.search);
  const redirectRoute = queryParams.get('redirectRoute');
  const locationId = params?.locationId || '';
  const orgId = organization?.id || '';

  return (
    <div className={className}>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link ${active === 'general' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_LOCATIONS}/manage/${locationId}`,
              urlParams: {
                orgId,
                ...(redirectRoute ? { redirectRoute } : {})
              }
            })}
            title="General Settings"
          >
            Settings
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
