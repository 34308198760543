import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaArrowAltCircleLeft } from 'react-icons/fa';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_RESOURCES } from '../../../constants/routes';
import { fetchResourceGroups } from '../../../services/resourceGroups';

const Breadcrumb = ({
  className = '',
  groupIds = null,
  resourceGroup,
  onClick
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Local State
  const [resourceGroupsList, setResourceGroupsList] = useState(null);

  const retrieveResourceGroups = useCallback(() => {
    fetchResourceGroups({
      ...(groupIds ? { ids: groupIds } : {}),
      select: ['title', 'id'],
      orgId: organization?.id
    }).then((response) => {
      setResourceGroupsList(response?.items);
    }).catch((error) => {
      console.error('getResourceGroups', error);
    });
  }, [groupIds, organization?.id]);

  useEffect(() => {
    retrieveResourceGroups();
  }, [retrieveResourceGroups]);

  const getGroupFromId = (id) => {
    if (!id || !resourceGroupsList) {
      return null;
    }

    if (!Array.isArray(resourceGroupsList)) {
      return null;
    }

    if (Array.isArray(resourceGroupsList) && resourceGroupsList.length === 0) {
      return null;
    }

    const group = resourceGroupsList.find((p) => p.id === id);

    if (group) {
      return group;
    }

    return null;
  };

  if (!resourceGroup?.id) {
    return null;
  }

  return (
    <nav
      className={`breadcrumb d-flex align-items-center justify-content-between ${className}`}
      aria-label="breadcrumb"
    >
      <ol className="breadcrumb m-0 p-0">
        <li className="breadcrumb-item text-nowrap">
          {onClick ? (
            <button
              className='btn-link'
              onClick={() => {
                onClick({
                  groupIds: null,
                  group: null
                });
              }}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaArrowAltCircleLeft
                  className="text-muted mr-1"
                />
                <span>
                  All Resources
                </span>
              </span>
            </button>
          ) : (
            <Link
              data-cy="ResourcesBreadcrumb-AllResources"
              to={routeWithOrgId({
                route: ORG_CONSOLE_RESOURCES,
                orgId
              })}
              title="All Resources"
            >
              <span className="d-flex align-items-center">
                <FaArrowAltCircleLeft
                  className="text-muted mr-1"
                />
                <span>
                  All Resources
                </span>
              </span>
            </Link>
          )}
        </li>

        {Array.isArray(groupIds) && groupIds.length > 0 && groupIds.map((id, i) => {
          const newGroupIds = groupIds.slice(0, i + 1);
          const groupIdsStr = newGroupIds.join(',');
          const newGroup = getGroupFromId(id);
          const title = newGroup?.title || '';

          return (
            <li
              className={`breadcrumb-item text-nowrap ${i === groupIds.length - 1 ? 'active' : ''}`}
              key={`breadcrumb-${id}`}
            >
              {i === groupIds.length - 1 ? (
                <span>
                  {resourceGroup?.title}
                </span>
              ) : (
                <>
                  {onClick ? (
                    <button
                      className='btn-link'
                      title={title}
                      onClick={() => {
                        onClick({
                          groupIds: newGroupIds,
                          group: newGroup
                        });
                      }}
                      type="button"
                    >
                      {title}
                    </button>
                  ) : (
                    <Link
                      title={title}
                      to={routeWithOrgId({
                        route: ORG_CONSOLE_RESOURCES,
                        urlParams: {
                          orgId,
                          groupIds: groupIdsStr
                        }
                      })}
                    >
                      {title}
                    </Link>
                  )}
                </>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
