import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { withAuthorization } from '../Session';
import { canConnectStripe } from '../../services/currentUser';
import Stripe from './Integrations/Stripe';

class Account extends Component {
  render() {
    const { currentUser } = this.props;

    if (!currentUser.id) {
      return null;
    }

    const role = pathOr([], ['role'], currentUser);

    return (
      <div className="mt-3 mb-5">
        <div className="py-2 mt-3 mb-1 border-bottom">
          <h6>
            <b>Account</b>
          </h6>
        </div>

        {canConnectStripe(role) && (
          <Stripe />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });
const condition = (user) => !!user.uid;

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition)
)(Account);
