import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_QUIZZES, MANAGE_CURRENT_ENTRY } from '../../constants/routes';
import { routeWithClassId } from '../../services/courses';
import { canManageCourseContent } from '../../services/currentUser';
import { manageEntry } from '../../services/entry';

const Tabs = ({
  className = '',
  active = 'all'
}) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const currentUser = useSelector((state) => state.currentUser);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  // Course
  const classId = pathOr(null, ['id'], course);
  const orgType = pathOr('', ['type'], organization);
  // Quiz
  const quizId = pathOr(null, ['id'], currentQuiz);
  const topicId = pathOr(null, ['topicId'], currentQuiz);
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });

  return (
    <div className={`${className} bg-light`}>
      <ul className="nav nav-tabs pl-3">
        <li className='nav-item'>
          <Link
            to={{
              pathname: MANAGE_CURRENT_ENTRY,
              search: manageEntry({
                manageType: 'edit',
                entryId: quizId,
                topicId,
                classId,
                contentType: 'quiz'
              })
            }}
            title="Settings"
            className={`nav-link mr-2 ${active === 'settings' ? 'active' : ''} ${manageCourse ? '' : 'disabled'} `}
          >
            Settings
          </Link>
        </li>
        <li className="nav-item">
          <Link
            title="Outcomes"
            className={`nav-link ${active === 'outcomes' ? 'active' : ''}`}
            to={routeWithClassId({
              route: `${CLASS_CONSOLE_QUIZZES}/${quizId}`,
              classId
            })}
          >
            Outcomes
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
