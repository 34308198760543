import React from 'react';
import ReactTooltip from 'react-tooltip';
import Table from './Table';
import InviteTypeCell from './InviteTypeCell';
import FullNameCell from '../ManageContent/Tables/FullNameCell';
import ActionsCell from './ActionsCell';
import ActionTakenCell from './ActionTakenCell';

const InvitesHistoryList = ({
  list,
  data // employer || orgLocation
}) => {
  // redux
  // const hiddenColumns = useMemo(() => {
  //   if (<conditional>) {
  //     return ['<accessor>'];
  //   }

  //   return [];
  // }, [<dependency>]);

  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: (props) => (
        <FullNameCell {...props} tooltipId='InvitesHistoryList' />
      )
    },
    {
      Header: 'Title',
      accessor: 'title'
    },
    {
      Header: 'Type',
      accessor: 'inviteType',
      Cell: (props) => (
        <InviteTypeCell {...props} />
      )
    },
    {
      Header: 'Action Taken',
      accessor: 'actionTaken',
      Cell: (props) => (
        <ActionTakenCell {...props} />
      )
    },
    {
      Header: '',
      accessor: 'updatedAt',
      disableSortBy: true,
      Cell: (props) => (
        <ActionsCell
          locationId={data?.id}
          {...props}
        />
      )
    }
  ], []);

  if (!list || (Array.isArray(list) && !list.length)) {
    return (
      <div className="p-4">
        No Invitations.
      </div>
    );
  }

  return (
    <>
      <Table
        // hiddenColumns={hiddenColumns}
        columns={columns}
        data={list}
      />
      <ReactTooltip id="InvitesHistoryList" />
    </>
  );
};

export default InvitesHistoryList;
