import { TURBINE_CONSOLE } from '../constants/urls';
/**
 * Generate Document URL
 *
 * @param {string} params.collection // Firebase collection name, Ex: employerAgreements
 * @param {string} params.id // Firebase document ID
 * @param {string} params.accessCode // random 6 digit string stored on document for access
 * @param {string} params.preview // boolean that disables editing of the document
 *
 * @returns {string} URL to document
 */
export const generateDocumentLink = (params) => {
  const searchParams = new URLSearchParams({
    ...(params?.accessCode ? { accessCode: params?.accessCode } : {}),
    ...(params?.preview ? { preview: params?.preview } : {})
  });
  let url = `${TURBINE_CONSOLE}/external/doc/${params?.collection}/${params?.id}`;

  if (searchParams.size !== 0) {
    url += `?${searchParams.toString()}`;
  }

  return url;
};
