import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { ICON_EXTERNAL_LINK } from '@apprentage/components/dist/constants/icons';
import { resetCurrentModal } from '../../../actions/Modals';
import { updateEmployerProfile } from '../../../services/employers';
import { getEmployerProfile } from '../../../actions/Employers';
import Modal from '../../Modal';
import Collapse from '../../ManageContent/Collapse';

const ManageWorkforceInformation = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const employerProfile = useSelector((state) => state.employerProfile);
  // Current Modal
  const key = 'manageEmployerProfile';
  const currentModalKey = pathOr(null, ['key'], currentModal);
  // Employer Profile
  const employerProfileId = pathOr(null, ['id'], employerProfile);
  // Local State
  const [loading, setLoading] = useState(false);

  const [ein, setEin] = useState(employerProfile.ein || '');
  const [naicsCode, setNaicsCode] = useState(employerProfile.naicsCode || '');
  const [totalEmployees, setTotalEmployees] = useState(employerProfile.totalEmployees || '');
  const [totalEmployeesFemale, setTotalEmployeesFemale] = useState(employerProfile.totalEmployeesFemale || '');
  const [totalEmployeesColor, setTotalEmployeesColor] = useState(employerProfile.totalEmployeesColor || '');
  const [totalJourneyWorkers, setTotalJourneyWorkers] = useState(employerProfile.totalJourneyWorkers || '');
  const [totalJourneyWorkersFemale, setTotalJourneyWorkersFemale] = useState(employerProfile.totalJourneyWorkersFemale || '');
  const [totalJourneyWorkersColor, setTotalJourneyWorkersColor] = useState(employerProfile.totalJourneyWorkersColor || '');

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSaveSuccessCallback = () => {
    dispatch(getEmployerProfile(employerProfileId)).then(() => {
      toast.success('Saved!');
      handleClose();
    });
  };

  const handleSaveErrorCallback = (error) => {
    setLoading(false);
    console.error(error);
    toast.error('Something went wrong, please try again.');
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    toast.info('Saving...');

    const dataToSave = {
      ...(ein ? { ein } : {}),
      naicsCode,
      totalEmployees,
      totalEmployeesFemale,
      totalEmployeesColor,
      totalJourneyWorkers,
      totalJourneyWorkersFemale,
      totalJourneyWorkersColor
    };

    updateEmployerProfile(dataToSave, employerProfileId).then(() => {
      handleSaveSuccessCallback();
    }).catch((error) => {
      handleSaveErrorCallback(error);
    });
  };

  if (currentModal && !currentModal.visible) return null;

  if (currentModalKey !== key) return null;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModalKey === key}
      close={handleClose}
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">
            Workforce Information
          </h1>
        </div>
        <form
          id="invite-user"
          name="invite-user"
          onSubmit={onSubmit}
        >
          <div className="card-body pb-4">
            <Collapse
              title="Employer Identification"
              className="mb-3"
              id="locationIdentificationCollapse"
              ariaExpanded
            >
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label
                    htmlFor="ein"
                  >
                    EIN
                  </label>
                  <input
                    type="text"
                    name="ein"
                    className="form-control"
                    value={ein}
                    placeholder='XX-XXXXXXX'
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setEin(value);
                    }}
                  />
                  <div className="small mt-1">
                    Ex: 31-5914023
                  </div>
                </div>

                <div className="col-sm-6">
                  <label htmlFor="naicsCode">
                    NAICS Code
                  </label>
                  <div />
                  <input
                    type="text"
                    name="naicsCode"
                    className="form-control"
                    value={naicsCode}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setNaicsCode(value);
                    }}
                  />
                  <div className="small mt-1">
                    <span>
                      Ex: 611210
                    </span>
                    <a
                      className='ml-2'
                      href='https://www.naics.com/company-lookup-tool/'
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Lookup NAICS code"
                    >
                      Lookup NAICS code <i className={ICON_EXTERNAL_LINK} />
                    </a>
                  </div>
                </div>
              </div>
            </Collapse>

            <Collapse
              title="Employee Demographics"
              className="my-3"
              id="employerProfileEmployeesCollapse"
              ariaExpanded
            >
              <div className="mb-3">
                <label htmlFor="totalEmployees">
                  Total Employees <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  name="totalEmployees"
                  className="form-control"
                  minLength={1}
                  min={1}
                  required
                  value={totalEmployees}
                  onChange={(e) => {
                    const { valueAsNumber } = e.currentTarget;

                    setTotalEmployees(valueAsNumber);
                  }}
                />
              </div>

              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="totalEmployeesFemale">
                    Total Female Employees <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    name="totalEmployeesFemale"
                    className="form-control"
                    value={totalEmployeesFemale}
                    minLength={1}
                    min={0}
                    required
                    onChange={(e) => {
                      const { valueAsNumber } = e.currentTarget;

                      setTotalEmployeesFemale(valueAsNumber);
                    }}
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="totalEmployeesColor">
                    Total Employees of Color <span className="text-danger">*</span>
                  </label>
                  <input
                    data-demo="disabled"
                    type="number"
                    name="totalEmployeesColor"
                    className="form-control"
                    value={totalEmployeesColor}
                    minLength={1}
                    min={0}
                    required
                    onChange={(e) => {
                      const { valueAsNumber } = e.currentTarget;

                      setTotalEmployeesColor(valueAsNumber);
                    }}
                  />
                </div>
              </div>
            </Collapse>

            <Collapse
              title="Employee Journeyworkers"
              className="mt-3"
              id="employerProfileJourneyWorkersCollapse"
              ariaExpanded
            >
              <div className="mb-3">
                <label htmlFor="totalJourneyWorkers">
                  Total Journeyworkers <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  name="totalJourneyWorkers"
                  className="form-control"
                  value={totalJourneyWorkers}
                  minLength={1}
                  min={0}
                  required
                  onChange={(e) => {
                    const { valueAsNumber } = e.currentTarget;

                    setTotalJourneyWorkers(valueAsNumber);
                  }}
                />
              </div>

              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label htmlFor="totalJourneyWorkersFemale">
                    Total Female Journeyworkers <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    name="totalJourneyWorkersFemale"
                    className="form-control"
                    value={totalJourneyWorkersFemale}
                    minLength={1}
                    min={0}
                    required
                    onChange={(e) => {
                      const { valueAsNumber } = e.currentTarget;

                      setTotalJourneyWorkersFemale(valueAsNumber);
                    }}
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="totalJourneyWorkersColor">
                    Total Journeyworkers of Color <span className="text-danger">*</span>
                  </label>
                  <input
                    data-demo="disabled"
                    type="number"
                    name="totalJourneyWorkersColor"
                    minLength={1}
                    min={0}
                    required
                    onChange={(e) => {
                      const { valueAsNumber } = e.currentTarget;

                      setTotalJourneyWorkersColor(valueAsNumber);
                    }}
                    className="form-control"
                    value={totalJourneyWorkersColor}
                  />
                </div>
              </div>
            </Collapse>
          </div>
          <div className="card-footer d-flex justify-content-between align-items-center">
            <button
              title="Save"
              aria-label="Save"
              className="btn btn-md btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ManageWorkforceInformation;
