import React from 'react';
import { Link } from 'react-router-dom';
import { FaEllipsisH, FaPencilAlt } from 'react-icons/fa';
import { ORG_CONSOLE_ASSISTANT_BASE } from '../../constants/routes';

const DotMenu = ({ assistantId }) => {
  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-white rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Manage Menu"
          title="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          <Link
            className="dropdown-item p-2"
            to={`${ORG_CONSOLE_ASSISTANT_BASE}/manage/${assistantId}`}
          >
            <span className='d-flex align-items-center'>
              <FaPencilAlt />
              <span className='ml-2'>
                Edit
              </span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
