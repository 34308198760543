import exportFromJSON from 'export-from-json';
import sanitize from 'sanitize-filename';
import { mdySimple } from '../../../utils/date';
import { locationTerminology } from '../../../services/locations';

const generateFileName = ({ orgName }) => {
  let fileName = 'Turbine Export - ';

  if (orgName) {
    fileName += `${orgName} - `;
  }

  fileName += `Users - ${mdySimple(new Date(), '-')}`;

  fileName = sanitize(fileName);

  return fileName;
};

const exportData = ({
  users = [],
  orgName,
  orgType,
  locationsHash = {},
  userGroupsHash = {}
}) => {
  const locationTerm = locationTerminology({ orgType }).text;
  const userGroupTerm = orgType === 'workforce' ? 'Department' : 'Group';

  const data = users.length && users.map((user, i) => {
    const locationId = user?.locationId || '';
    const locationName = locationsHash[locationId] ? locationsHash[locationId].name : '';
    let departmentNames = '';

    if (Array.isArray(user.groupIds) && user.groupIds.length > 0) {
      departmentNames = [];
      user.groupIds.forEach((groupId) => {
        if (userGroupsHash[groupId]) {
          departmentNames.push(userGroupsHash[groupId].title.trim());
        }
      });
      departmentNames = departmentNames.join(', ');
    }

    const dataToExport = {
      ' ': i + 1,
      Name: user.name,
      Email: user.email,
      Phone: user.phone,
      'Phone Ext': user.phoneExt,
      Mobile: user.mobilePhone,
      [userGroupTerm]: departmentNames,
      [locationTerm]: locationName,
      Role: user.role.join(', '),
      ID: user.id
    };

    return dataToExport;
  });

  const fileName = generateFileName({ orgName });

  const exportType = exportFromJSON.types.csv;

  exportFromJSON({ data, fileName, exportType });
};

export default exportData;
