import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { ICON_EXPERT } from '@apprentage/constants';
import {
  getUsers, resetUsers
} from '../../../actions/Users';
import { setCurrentClass } from '../../../actions/Class';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import SelectMenu from '../../ManageContent/SelectMenu';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_USERS } from '../../../constants/routes';
import { canAccessAllUsers } from '../../../services/currentUser';
import { updateCourse } from '../../../services/courses';

class ManageExperts extends Component {
  state = {
    key: 'manageExperts',
    loading: false,
    newTeachers: undefined,
    roles: ['teacher', 'admin', 'owner'],
    ...this.props.currentModal.data
  }

  componentDidMount() {
    const { roles } = this.state;

    this.props.getUsers({ roles, omitCourse: true });
  }

  handleFieldChange = (data) => {
    this.setState({
      ...data
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { newTeachers } = this.state;
    const { course } = this.props;
    const { id: classId } = course;

    this.setState({ loading: true });

    updateCourse({
      classId,
      teachers: newTeachers
    }).then(() => {
      toast.success('Course experts saved!');

      this.props.setCurrentClass({ classId }).then(() => {
        this.props.resetCurrentModal();
      });
    });
  }

  clearForm = () => {
    this.setState({
      newTeachers: null
    });
  }

  handleClose = () => {
    this.clearForm();
    this.props.resetCurrentModal();
  }

  render() {
    const {
      currentModal,
      users,
      course,
      organization,
      currentUser
    } = this.props;
    if (!currentModal?.visible) {
      return;
    }

    const { newTeachers, key, loading } = this.state;
    if (currentModal?.key !== key) {
      return;
    }

    // User
    const role = pathOr([], ['role'], currentUser);
    // Organization
    const { id: orgId, type: orgType, name: orgName } = organization;
    // Course
    const courseTeachers = pathOr(null, ['courseTeachers'], course);
    const title = pathOr(null, ['title'], course);
    // Misc
    const teachersOptions = users.list && users.list.length ? users.list.map(({ id, name }) => ({ value: id, label: name })) : [];
    const currentTeachers = courseTeachers && courseTeachers.length ? courseTeachers.map(({ id, name }) => ({ value: id, label: name })) : [];

    return (
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
        visible={currentModal.key === key}
        close={this.handleClose}
      >
        <div className="card">
          <div className="card-header">
            <h1 className="h5 m-0">
              <i className={`${ICON_EXPERT} mr-1`} /> Manage Experts
            </h1>
          </div>
          <form
            id="change-groups"
            name="change-groups"
            onSubmit={this.handleSubmit}
          >
            <div className="card-body">
              {teachersOptions ? (
                <Fragment>
                  <div className="mb-2 pb-2">
                    Choose at least one expert for <b>{title}</b>.
                  </div>

                  <div
                    id="user-group"
                    className="mb-4"
                    data-demo="disabled"
                  >
                    <SelectMenu
                      id="newTeachers"
                      name="newTeachers"
                      options={teachersOptions}
                      defaultValue={currentTeachers}
                      onChange={(data) => {
                        if (data.newTeachers && data.newTeachers.length === 0) {
                          // null converted to undefined when data is formatted before sent to BE
                          data.newTeachers = null;
                        }
                        this.handleFieldChange(data);
                      }}
                      isMulti
                    />
                  </div>
                  <div>
                    Course experts must have one of the following roles; <b>expert</b>, <b>admin</b> or <b>owner</b>.
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="h5">
                    No available experts.
                  </div>
                  <p>
                    Before assigning <b>Course {orgType !== 'workforce' ? 'Teachers' : 'Experts'}</b> to <b>{title}</b>, at least one {orgName} user must have the role <b>{orgType !== 'workforce' ? 'teacher' : 'expert'}, admin or owner</b>.
                  </p>
                </Fragment>
              )}
            </div>

            <div className="card-footer d-flex align-items-center justify-content-between">
              <button
                className="btn btn-primary btm-sm"
                type="submit"
                disabled={newTeachers === undefined || loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>

              {canAccessAllUsers(role, orgType) && (
                <Link
                  className="btn btn-sm btn-link"
                  onClick={this.handleClose}
                  disabled={loading}
                  to={routeWithOrgId({
                    route: ORG_CONSOLE_USERS,
                    orgId
                  })}
                >
                  Manage Users
                </Link>
              )}
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentModal,
  currentUser,
  organization,
  users,
  course
}) => ({
  currentModal,
  currentUser,
  organization,
  users,
  course
});

export default connect(mapStateToProps, {
  getUsers,
  setCurrentClass,
  resetUsers,
  resetCurrentModal
})(ManageExperts);
