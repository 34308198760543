import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { getApprenticeship, resetApprenticeship } from '../../../../../actions/Apprenticeships';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { getCourses } from '../../../../../actions/Courses';
import withOrgConsole from '../../../../App/withOrgConsole';
import Loading from '../../../../Loading';
import Form from './Form';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import { getEmployers } from '../../../../../actions/Employers';
import {
  getInstructionProviders,
  resetInstructionProviders
} from '../../../../../actions/InstructionProviders';
import { setCurrentModal } from '../../../../../actions/Modals';
import { MODAL_KEY_CHANGE_OCCUPATION } from '../../../../../constants/modals';

const Manage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // redux
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const instructionProviders = useSelector((state) => state.instructionProviders);
  const courses = useSelector((state) => state.courses);
  //  misc
  const orgId = organization?.id || '';
  const apprenticeshipId = params?.apprenticeshipId || null;
  const goBackRoute = useMemo(() => {
    if (apprenticeshipId) {
      return `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
    }

    return ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS;
  }, [apprenticeshipId]);

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    dispatch(resetInstructionProviders());

    dispatch(getCourses({ orgId }));
    dispatch(getEmployers({ orgId }));
    dispatch(getInstructionProviders({ orgId }));

    return function cleanup() {
      const { href } = window.location;
      const apprenticeshipRoute = `org/workforce/apprenticeships/${apprenticeshipId}`;

      // Only reset if not going to apprentice route
      if (!apprenticeshipId || !href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, []);

  if (apprenticeshipId && !apprenticeship.id) {
    return (
      <Loading text="Loading apprenticeship..." />
    );
  }

  if (!locations.list && !locations.fetched) {
    return (
      <Loading text="Loading employers..." />
    );
  }

  if (!instructionProviders.list && !instructionProviders.fetched) {
    return (
      <Loading text="Loading Instruction Providers..." />
    );
  }

  if (!courses.list && !courses.fetched) {
    return (
      <Loading text="Loading courses..." />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title || 'New Training Program'}
            iconComponent={() => <FaSortAmountUp size={20} />}
            route={goBackRoute}
            className="my-3"
          />

          <div className="bg-white shadow overflow-content border mt-3">
            <Form
              disableOccupation
              handleChangeOccupation={() =>
                dispatch(
                  setCurrentModal({
                    key: MODAL_KEY_CHANGE_OCCUPATION
                  })
                )}
              editOccupation={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Manage);
