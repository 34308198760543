import React from 'react';
import { statusFeedback } from '../../../../services/answers';

const StatusCell = ({ value: status = 'inReview' }) => {
  const {
    iconClass,
    alertType,
    title
  } = statusFeedback(status);

  return (
    <span className='d-flex align-items-center text-nowrap'>
      <span
        className={`assessment-status badge badge-${alertType}`}
      >
        <i className={iconClass} />
      </span>
      <span className="d-none d-md-inline">
        {title}
      </span>
    </span>
  );
};

export default StatusCell;
