import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { ICON_CHALLENGE } from '@apprentage/constants';
import { useParams } from 'react-router-dom';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_COURSES } from '../../../constants/routes';
import withOrgConsole from '../../App/withOrgConsole';
import Answers from '../../Answers';
import Loading from '../../Loading';
import Tabs from './Tabs';
import OrgConsoleHeader from '../OrgConsoleHeader';

const OrgChallenges = () => {
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Params
  const userId = params?.userId || null;

  if (!organization.id || !currentUser.id) {
    return (
      <Loading />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">

        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Challenges"
            icon={ICON_CHALLENGE}
            route={ORG_CONSOLE_COURSES}
          />

          <Tabs active='submissions' />

          <div className="card-body bg-white border-left border-right shadow">
            <Answers userId={userId} />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(OrgChallenges);
