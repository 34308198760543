import React, { useState } from 'react';
import {
  FaCog, FaEllipsisH, FaExclamationTriangle, FaThumbsUp
} from 'react-icons/fa';
import { FaCircleXmark } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { mdyDate } from '../../../../utils/date';
import KeyValueItem from '../../../KeyValueItem';
import { fetchFlags } from '../../../../services/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../actions/FeatureFlags';
import { NOTICE_LOGBOOKS_READONLY_TITLE, NOTICE_MAINTENANCE_MODE_BODY } from '../../../../constants/flagsmith';
import { recordManageApproval } from '../../../../services/logbookRecords';
import { canReviewLogbookRecords } from '../../../../permissions/manage';
import { setCurrentModal } from '../../../../actions/Modals';
import { MODAL_KEY_MANAGE_RECORD } from '../../../../constants/modals';

const ActionsCell = ({ column, row }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id;
  // Current User
  const role = currentUser?.role || [];
  const userId = currentUser?.id || [];
  const userName = currentUser?.name || [];
  // Cell Data
  const record = row?.original;
  // Local State
  const [loading, setLoading] = useState(false);

  const onClickApprove = () => {
    setLoading(true);
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.logbooks_readonly?.enabled && fetchedFlags?.logbooks_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
          modalBody: NOTICE_MAINTENANCE_MODE_BODY
          // preventClose: true
        }));
        setLoading(false);
        return;
      }

      recordManageApproval({
        recordId: record?.id,
        orgId,
        isApproved: true,
        currentUserId: userId,
        currentUserName: userName
      }).then(() => {
        column?.handleRefreshRecords();
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  const onClickRemoveApprove = () => {
    setLoading(true);
    fetchFlags().then((fetchedFlags) => {
      if (fetchedFlags?.logbooks_readonly?.enabled && fetchedFlags?.logbooks_readonly?.value) {
        dispatch(showFeatureFlagNoticeModal({
          modalTitle: NOTICE_LOGBOOKS_READONLY_TITLE,
          modalBody: NOTICE_MAINTENANCE_MODE_BODY
          // preventClose: true
        }));
        setLoading(false);
        return;
      }

      recordManageApproval({
        recordId: record?.id,
        orgId,
        isApproved: false
      }).then(() => {
        column?.handleRefreshRecords();
      }).finally(() => {
        setLoading(false);
      });
    });
  };

  const onClickEditRecord = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_RECORD,
        data: {
          record
        },
        callbackPrimaryAction: () => {
          column?.handleRefreshRecords();
          setLoading(false);
        }
      })
    );
  };

  return (
    <div className="btn-group d-flex align-items-center justify-content-end">
      <button
        className="btn-sm btn-white border text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label='Manage Menu'
        title="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div
          className="bg-dark px-4 py-2 text-white text-nowrap"
          data-demo="disabled"
        >
          {mdyDate(record?.date)}
        </div>

        <button
          type="button"
          className="dropdown-item border-bottom px-3 py-2"
          onClick={onClickEditRecord}
          disabled={
            loading
            || !canReviewLogbookRecords(role)
            || record?.isApproved
          }
        >
          <span className='d-flex align-items-center'>
            <FaCog className='mr-2' />
            <span>
              Edit
            </span>
          </span>
        </button>

        {!record?.isApproved && (
          <button
            type="button"
            className="dropdown-item border-bottom px-3 py-2"
            onClick={onClickApprove}
            disabled={
              loading
              || !canReviewLogbookRecords(role)
              || !Array.isArray(record?.competencyIds)
              || (Array.isArray(record?.competencyIds) && record?.competencyIds.length === 0)
            }
          >
            <span className='d-flex align-items-center'>
              <FaThumbsUp className='text-success mr-2' />
              <span>
                Approve
              </span>
            </span>
          </button>
        )}

        {record?.isApproved && (
          <button
            type="button"
            className="dropdown-item border-bottom px-3 py-2"
            onClick={onClickRemoveApprove}
            disabled={
              loading
              || !canReviewLogbookRecords(role)
            }
          >
            <span className='d-flex align-items-center'>
              <FaCircleXmark className='text-danger mr-2' />
              <span>
                Remove Approval
              </span>
            </span>
          </button>
        )}

        {(!Array.isArray(record?.competencyIds) || (Array.isArray(record?.competencyIds) && record?.competencyIds.length === 0)) && (
          <div className='alert-warning p-2'>
            <div className='d-flex align-items-top'>
              <FaExclamationTriangle
                size={30}
                className='text-warning mr-2'
              />
              <span>
                Records must include at least one competency before Approving.
              </span>
            </div>
          </div>
        )}

        <div className="bg-light p-2 small">
          <div
            className="bg-light px-2"
          >
            <KeyValueItem
              data-demo="disabled"
              className="text-nowrap mb-0 small"
              title="ID"
              value={record?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsCell;
