import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_LOGBOOKS } from '../../../constants/routes';
import withOrgConsole from '../../App/withOrgConsole';
import OrgConsoleHeader from '../OrgConsoleHeader';
import { getLogbook, resetLogbook } from '../../../actions/Logbooks';
import { getApprenticeship } from '../../../actions/Apprenticeships';
import Loading from '../../Loading';
import View from './View';

const Logbook = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  // Organization
  const orgId = organization?.id || '';
  // Logbook
  const logbookId = params?.logbookId;

  useEffect(() => {
    if (logbookId && !logbook?.id) {
      dispatch(getLogbook({
        id: logbookId,
        orgId,
        logbookHours: true,
        logbookCompHours: true
      })).then(({ apprenticeshipId }) => {
        dispatch(getApprenticeship(apprenticeshipId));
      });
    }
  }, [dispatch, logbook?.id, logbookId, orgId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetLogbook());
    };
  }, [dispatch]);

  if (logbookId && !logbook?.id) {
    return (
      <Loading text='Loading Logbook...' />
    );
  }

  if (!apprenticeship?.id) {
    return (
      <Loading text='Loading Training Program...' />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={logbook?.id ? logbook?.userName : 'New Logbook'}
            icon='fas fa-book'
            route={ORG_CONSOLE_LOGBOOKS}
          />

          {logbook?.id && apprenticeship?.id && (
            <View />
          )}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Logbook);
