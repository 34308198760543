import React from 'react';
import WidgetLink from '../../../../lists/WidgetsList/ListView/WidgetLink';
import { DASHBOARD_MODULE_TYPE } from '../../../../../constants/dashboard';

const TitleCell = ({ row, value: title }) => {
  const image = row?.original?.image || null;
  const body = row?.original?.body || null;
  const moduleType = row?.original?.moduleType || null;

  if (moduleType === DASHBOARD_MODULE_TYPE.links) {
    return (
      <WidgetLink
        image={image}
        title={title}
        body={body}
      />
    );
  }

  return (
    <div>
      {title}
    </div>
  );
};

export default TitleCell;
