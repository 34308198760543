import { isStudent, isTeacher } from '@apprentage/utils';
import isProgramTrainer from '../isProgramTrainer';
import isProgramManager from '../isProgramManager';
import isAdminOwnerSuperAdmin from '../isAdminOwnerSuperAdmin';

export default function canAccessTurbineLMS(role = [], orgType = '') {
  if (!orgType) {
    return false;
  }

  if (orgType === 'workforce' && (isStudent(role) || isTeacher(role))) {
    return true;
  }

  if (isProgramTrainer(role) || isProgramManager(role)) {
    return false;
  }

  return isAdminOwnerSuperAdmin(role);
}
