import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { isProgramManager, isProgramTrainer } from '@apprentage/utils';
import { FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  getApprenticeshipUser,
  resetApprenticeship,
  resetApprenticeshipUser
} from '../../../../../actions/Apprenticeships';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import DotMenu from './DotMenu';
import Loading from '../../../../Loading';
import InfoBlock from './InfoBlock';
import UserTabs from './UserTabs';
import TrainersBlock from './TrainersBlock';
import { getLogbook, resetLogbook } from '../../../../../actions/Logbooks';
import TraineesBlock from './TraineesBlock';
import {
  canAccessProgramTrainees,
  canHaveLogbook,
  canHaveTrainers
} from '../../../../../permissions/access';
import canHaveTrainees from '../../../../../permissions/access/canHaveTrainees';
import UserChecklist from './UserChecklist';
import { getEmployerAgreements } from '../../../../../actions/EmployerAgreements';
import { fetchLocation } from '../../../../../services/locations';
import { getApprenticeAgreements, resetApprenticeAgreement } from '../../../../../actions/ApprenticeAgreements';

const ApprenticeshipUser = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Training Program User
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipUserId = params?.apprenticeshipUserId || null;
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserRole = useMemo(() => {
    return apprenticeshipUser?.role || [];
  }, [apprenticeshipUser?.role]);
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }
  }, [apprenticeshipId, apprenticeshipUserId, dispatch]);

  useEffect(() => {
    if (
      apprenticeshipId
      && apprenticeshipUserId
      && canHaveLogbook(apprenticeshipUserRole)
    ) {
      dispatch(
        getLogbook({
          apprenticeshipId,
          userId: apprenticeshipUserId,
          orgId,
          logbookHours: true,
          logbookCompHours: true
        })
      );
    }
  }, [apprenticeshipId, apprenticeshipUserId, apprenticeshipUserRole, dispatch, orgId]);

  useEffect(() => {
    if (apprenticeshipUserId) {
      dispatch(getApprenticeshipUser(apprenticeshipUserId, apprenticeshipId, orgId)).then((response) => {
        // Apprentice Agreements
        if (response?.userProfileId && apprenticeshipId) {
          dispatch(getApprenticeAgreements({
            userProfileId: response?.userProfileId,
            userId: apprenticeshipUserId,
            apprenticeshipId
          }));
        }
        // TODO add appropriate resets
        if (response?.locationId) {
          fetchLocation({ locationId: response.locationId }).then((responseLocation) => {
            if (responseLocation?.employerProfileId) {
              dispatch(getEmployerAgreements({
                employerProfileId: responseLocation.employerProfileId,
                apprenticeshipId
              }));
            }
          });
        }
      });
    }

    return function cleanup() {
      const { href } = window.location;

      // Only reset if not going to apprentice user route
      if (
        !href.includes(`${apprenticeshipRoute}/users/${apprenticeshipUserId}`)
      ) {
        dispatch(resetApprenticeshipUser());
        dispatch(resetApprenticeAgreement());
        dispatch(resetLogbook());
      }

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipId, apprenticeshipRoute, apprenticeshipUserId, dispatch, orgId]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program" />;
  }

  if (apprenticeshipUserId && !apprenticeship.user) {
    return <Loading text="Loading Training Program User" />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            iconComponent={() => <FaSortAmountUp size={20} />}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="users" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle={apprenticeshipUser.name}
              route={`${apprenticeshipRoute}/users`}
            >
              <DotMenu />
            </OrgConsoleSubheader>

            <UserTabs active="info" />

            <div className="border-left border-right border-bottom p-3">

              <div className='row d-flex'>
                <div className='col-12 col-md-6 mb-3'>
                  <InfoBlock className='' />
                </div>
                {!isProgramManager(apprenticeshipUserRole) && !isProgramTrainer(apprenticeshipUserRole) && (
                  <div className='col-12 col-md-6'>
                    <UserChecklist />
                  </div>
                )}
              </div>

            </div>

            {canHaveTrainers(apprenticeshipUserRole) && <TrainersBlock />}
            {canHaveTrainees(apprenticeshipUserRole)
              && canAccessProgramTrainees(role) && <TraineesBlock />}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUser);
