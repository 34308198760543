import React from 'react';
import { useSelector } from 'react-redux';
import CompetenciesCollapse from '../../../OrgConsole/LogbookRecord/CompetenciesCollapse';

const CompetenciesCell = ({ column, value: competencyIds }) => {
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Competencies
  const competencies = apprenticeship?.competencies;

  return (
    <div
      style={{ minWidth: '300px' }}
    >
      <CompetenciesCollapse
        competencyIds={competencyIds}
        competencies={competencies}
        theme={column?.theme}
      />
    </div>
  );
};

export default CompetenciesCell;
