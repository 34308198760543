import React from 'react';
import List from './List';

const ResourceEmbeddingsList = ({
  list = []
}) => {
  if (!list.length) {
    return null;
  }

  return (
    <div className='ResourceEmbeddingsList bg-white'>
      <List
        data={list}
      />
    </div>
  );
};

export default ResourceEmbeddingsList;
