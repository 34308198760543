import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_QUIZZES } from '../../constants/routes';
import { routeWithClassId } from '../../services/courses';

const QuizCell = ({ value: quizId, classId, showQuizTitleAsLink }) => {
  const currentClassQuizzes = useSelector((state) => state.currentClassQuizzes);

  let quiz;
  let quizTitle = quizId;

  if (currentClassQuizzes.list) {
    quiz = currentClassQuizzes.list.find((q) => q.id === quizId);

    if (quiz) {
      const { title } = quiz;
      quizTitle = title;
    }
  }

  if (showQuizTitleAsLink) {
    return (
      <Link
        title={`View all ${quizTitle} Scores`}
        className='btn btn-sm btn-link d-flex align-items-center'
        to={routeWithClassId({
          route: `${CLASS_CONSOLE_QUIZZES}/${quizId}`,
          classId
        })}
      >
        {quizTitle}
      </Link>
    );
  }
  return (
    <div>
      {quizTitle}
    </div>
  );
};

export default QuizCell;
