import React from 'react';

const ChooseProvider = ({
  onClick
}) => {
  return (
    <div>
      <h4>
        Choose a provider
      </h4>
      <div
        className="row mt-3"
      >
        <div className="col-sm-12 col-md-4">
          <div className="card border mb-2">
            <div
              className="card-body text-center h-100"
              onClick={() => {
                onClick({
                  screen: 'MOODLE',
                  accept: '.txt'
                });
              }}
              style={{ cursor: 'pointer' }}
            >
              <img
                alt="Moodle"
                src="https://turbine-content.s3.us-east-2.amazonaws.com/moodle.jpeg"
                width="90"
              />
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-4">
          <div className="card border mb-2">
            <div
              className="card-body text-center h-100"
              onClick={() => {
                onClick({
                  screen: 'BLACKBOARD',
                  accept: '.txt'
                });
              }}
              style={{ cursor: 'pointer' }}
            >
              <img
                alt="Blackboard"
                src="https://turbine-content.s3.us-east-2.amazonaws.com/blackboard.jpg"
                width="90"
              />
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-4">
          <div className="card border mb-2">
            <div
              className="card-body text-center h-100"
              onClick={() => {
                onClick({
                  screen: 'CANVAS',
                  accept: '.txt'
                });
              }}
              style={{ cursor: 'pointer' }}
            >
              <img
                alt="Canvas"
                src="https://turbine-content.s3.us-east-2.amazonaws.com/canvas.jpg"
                width="90"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseProvider;
