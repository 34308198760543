import { fetchLogbook, fetchLogbookHours, fetchLogbooks } from '../services/logbooks';
import heapAnalytics from '../utils/heapAnalytics';
import {
  SET_LOGBOOKS,
  RESET_LOGBOOKS,
  RESET_LOGBOOK,
  SET_LOGBOOK,
  RESET_LOGBOOK_HOURS,
  SET_LOGBOOK_HOURS,
  SET_ENTRY_ORG_ID,
  RESET_LOGBOOK_RECORDS
} from './types';

export const getLogbooks = ({ orgId, employerId, apprenticeshipId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLogbooks({
        orgId,
        ...(apprenticeshipId ? { apprenticeshipId } : {}),
        ...(employerId ? { employerId } : {})
      })
        .then((list) => {
          let logbooksHash = null;

          if (Array.isArray(list) && list.length > 0) {
            logbooksHash = list.reduce((acc, curr) => {
              const result = {
                ...acc
              };
              result[curr?.userId] = curr;
              return result;
            }, {});
          }

          dispatch({
            type: SET_LOGBOOKS,
            list,
            hash: logbooksHash
          });
          resolve(list);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getLogbookHours = ({
  orgId,
  logbookId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLogbookHours({
        orgId,
        logbookIds: [logbookId],
        showCompHours: true
      }).then((response) => {
        const logbookHours = response?.items ? response?.items[0] : null;

        dispatch({
          type: SET_LOGBOOK_HOURS,
          logbookHours
        });

        resolve(logbookHours);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getLogbook = ({
  id,
  orgId,
  userId,
  apprenticeshipId,
  logbookHours = false,
  logbookCompHours = false
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (id && orgId) {
        // TODO pass orgId to fetchLogbook
        fetchLogbook(id)
          .then((response) => {
            if (!response) {
              reject(response);
            }

            // ORG CHECK
            dispatch({
              type: SET_ENTRY_ORG_ID,
              entryOrgId: response?.orgId || null
            });

            const logbook = { ...response, records: null };

            if (logbookHours) {
              dispatch(getLogbookHours({
                logbookId: id,
                orgId,
                ...(logbookCompHours ? { logbookCompHours } : {})
              }));
            }

            dispatch({
              type: SET_LOGBOOK,
              logbook
            });

            resolve(logbook);
          })
          .catch((error) => {
            console.error('getLogbook', error);
            reject(error);
          });
        return;
      }

      if (userId && apprenticeshipId) {
        fetchLogbooks({ userId, apprenticeshipId, orgId })
          .then((list) => {
            if (list && list.length > 1) {
              console.error('More than one logbook');
              // TODO heap send custom event
              heapAnalytics.track('Error - Logbook - More than one logbook', {
                userId,
                apprenticeshipId,
                orgId
              });
            }

            const logbook = list && list[0] ? list[0] : null;

            // ORG CHECK
            dispatch({
              type: SET_ENTRY_ORG_ID,
              entryOrgId: logbook?.orgId || null
            });

            if (logbookHours && logbook?.id) {
              dispatch(getLogbookHours({
                logbookId: logbook?.id,
                orgId,
                ...(logbookCompHours ? { logbookCompHours } : {})
              }));
            }

            dispatch({
              type: SET_LOGBOOK,
              logbook
            });

            resolve(logbook);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
        return;
      }

      reject(new Error('getLogbook, missing params'));
    });
  };
};

export const resetLogbook = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOGBOOK });
    dispatch({ type: RESET_LOGBOOK_HOURS });
    dispatch({ type: RESET_LOGBOOK_RECORDS });
  };
};

export const resetLogbooks = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOGBOOKS });
  };
};
