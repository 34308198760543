import React from 'react';
import { previewLink } from '../../../services/pathways';

const TitleCell = ({ value: OnetTitle, row }) => {
  const { original: { id, isExternal } } = row;

  return (
    <div
      style={{
        maxWidth: '200px'
      }}
    >
      <a
        className="btn btn-sm btn-link text-left text-ships-officer font-weight-bold"
        href={previewLink(id)}
        target="_blank"
        title="View in new tab"
        rel="noopener noreferrer"
      >
        {OnetTitle}
      </a>
      {isExternal && (
        <div className="d-flex align-items-center">
          <span className="h6 m-0">
            <span className="badge badge-success">
              Employer Created
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default TitleCell;
