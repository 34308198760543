import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';

const Tab = styled.div`
  display: inline-block;
  // padding: 0.5rem 1rem;
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
`;

const Dropdown = ({
  className,
  active
}) => {
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  return (
    <div className={className}>
      <Tab>
        <div
          className="nav-link active"
          to={routeWithOrgId({
            route: apprenticeshipRoute,
            orgId
          })}
          title="Dashboard"
        >
          <div className="btn-group align-items-center">
            <button
              className="btn-link text-ships-officer px-0 dropdown-toggle rounded text-capitalize"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              title="Manage Menu"
            >
              <span className='mr-1'>
                {active}
              </span>
            </button>

            <div
              className="dropdown-menu p-0 mt-2"
            >
              <div className="bg-dark px-4 py-2 text-white" />

              <Link
                className={`dropdown-item border-bottom p-2 ${active === 'dashboard' ? 'd-none' : ''}`}
                to={routeWithOrgId({
                  route: apprenticeshipRoute,
                  orgId
                })}
                title="Dashboard"
              >
                Dashboard
              </Link>

              <Link
                className={`dropdown-item border-bottom p-2 ${active === 'competencies' ? 'd-none' : ''}`}
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/competencies`,
                  orgId
                })}
                title="Competencies"
              >
                Competencies
              </Link>

              <Link
                className={`dropdown-item border-bottom p-2 ${active === 'users' ? 'd-none' : ''}`}
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/users`,
                  orgId
                })}
                title="Users"
              >
                Users
              </Link>

              <Link
                className={`dropdown-item border-bottom p-2 ${active === 'employers' ? 'd-none' : ''}`}
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/employers`,
                  orgId
                })}
                title="Employers"
              >
                Employers
              </Link>

              <Link
                className={`dropdown-item border-bottom p-2 ${active === 'courses' ? 'd-none' : ''}`}
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/courses`,
                  orgId
                })}
                title="Courses"
              >
                Courses
              </Link>
            </div>
          </div>
        </div>
      </Tab>
    </div>
  );
};

export default Dropdown;
