import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaAtom } from 'react-icons/fa';
import { fetchSkills } from '../services/skills';
import SkillsTag from './SkillsTag';

const SkillsTags = ({
  ids = null,
  title = '',
  className = '',
  showTagIcon = false,
  size = 'sm',
  onClick
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Local State
  const [selectedSkills, setSelectedSkills] = useState(null);

  useEffect(() => {
    if (Array.isArray(ids) && ids.length > 0) {
      fetchSkills({ ids, orgId, limit: ids.length }).then((response) => {
        setSelectedSkills(response.items);
      });
    }
  }, [ids, orgId]);

  if (Array.isArray(selectedSkills) && selectedSkills.length !== 0) {
    return (
      <div className={className}>
        {title && (
          <div
            className="mb-2"
          >
            <span
              className={`d-inline-flex align-items-center ${size === 'md' ? 'h6' : ''}`}
            >
              <FaAtom
                className={size === 'md' ? 'mr-2' : 'mr-1'}
              />
              <span className="font-weight-bold">
                {title}
              </span>
            </span>
          </div>
        )}

        <div>
          {selectedSkills.map(({ id, name }) => (
            <Fragment
              key={id}
            >
              <SkillsTag
                id={id}
                name={name}
                showTagIcon={showTagIcon}
                size={size}
                onClick={onClick}
              />
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default SkillsTags;
