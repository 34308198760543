const notificationState = {
  message: null,
  orgId: null,
  locationId: null,
  classId: null,
  postAsOrg: null, // boolean
  isDraft: null, // boolean
  authorName: null,
  authorId: null,
  authorUid: null,
  userRoles: null, // string[]
  id: null,
  createdAt: null,
  updatedAt: null
};

export default notificationState;
