import { DASHBOARD_PAGINATION_LIMIT } from '../constants/api';
import {
  createSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'dashboards';

export const fetchDashboardData = async ({ id }) => {
  try {
    const response = await fetchSupabaseEntry({ table, id });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchDashboardByType = async ({
  orgId,
  type = 'organization'
}) => {
  try {
    const params = {
      page: 1,
      order: '-createdAt',
      limit: DASHBOARD_PAGINATION_LIMIT
    };

    if (orgId) {
      params['f.orgId[eq]'] = orgId;
    }

    if (type) {
      params['f.type[eq]'] = type;
    }

    const response = await fetchSupabaseEntries(params, table);
    const items = Array.isArray(response?.items) && response.items.length > 0
      ? response?.items
      : null;
    const dashboard = items ? items[0] : null;

    return dashboard;
  } catch (error) {
    console.error(error);
  }
};

export const updateDashboardData = async (data, dashboardId) => {
  try {
    const response = await updateSupabaseEntry({
      table,
      id: dashboardId,
      data
    });
    return response;
  } catch (error) {
    console.error(`update ${table}`, error);
  }
};

export const createDashboard = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};
