import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC, ICON_SETTINGS } from '@apprentage/constants';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_USERS_READONLY,
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { setCurrentModal } from '../../../actions/Modals';
import {
  ProfilePictureIconContainer,
  ProfilePictureImgAndButtonContainer,
  ProfilePictureImgContainer
} from './styles';

const ProfilePicture = ({
  children,
  className = '',
  height = '150',
  width = '150'
}) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);

  const openImageCropModal = () => {
    if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_USERS_READONLY_TITLE,
        modalBody: NOTICE_USERS_READONLY_BODY
        // preventClose: true
      }));
      return;
    }

    dispatch(setCurrentModal({
      key: 'imageCrop'
    }));
  };

  if (!currentUser?.id || !organization?.id) return null;

  return (
    <div
      className={`profile-picture-container ${className}`}
    >
      <ProfilePictureImgAndButtonContainer
        height={height}
        width={width}
      >
        <ProfilePictureImgContainer
          height={height}
          width={width}
        >
          <img
            src={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
            className="w-100"
            alt={currentUser?.name}
          />
        </ProfilePictureImgContainer>
        <ProfilePictureIconContainer
          className="btn btn-sm btn-white profilePicture-stop-propagation"
          onClick={openImageCropModal}
          role="button"
        >
          <i className={ICON_SETTINGS} />
        </ProfilePictureIconContainer>
      </ProfilePictureImgAndButtonContainer>

      {children || null}
    </div>
  );
};

export default ProfilePicture;
