import {
  SET_EMPLOYER, RESET_EMPLOYER
} from '../../actions/types';
import employerState from '../initialState/employerState';

const initialState = {
  ...employerState,
  fetched: false
};

export default function employer(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYER: {
      return {
        ...state,
        ...action.employer
      };
    }
    case RESET_EMPLOYER:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
