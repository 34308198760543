import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaMobileAlt } from 'react-icons/fa';
import { VELA_LOGO_BLACK } from '@apprentage/constants';
import { resetCurrentModal } from '../../../actions/Modals';
import { QRCODE_VELA, VELA_HANDOFF_QRCODE_CONTAINER } from '../../../constants/assets';
import Modal from '../../Modal';
import './style.css';

const VelaHandoff = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Misc

  return (
    <Modal
      cssClassName='turbine-modal--style-card'
      visible={currentModal.visible}
      close={() => {
        dispatch(resetCurrentModal());
      }}
      pos="middle"
    >
      <div className="card">
        <div className="card-body d-flex justify-content-center">
          <div>
            <div className='h4 font-weight-bold text-center mt-3 mb-5'>
              Scan QR Code
              <br />
              <span className='d-inline-flex align-items-center'>
                <span className='mr-1'>to launch</span>
                <img
                  src={VELA_LOGO_BLACK}
                  alt="VELA"
                  height="30"
                  style={{
                    height: '30px'
                  }}
                />
              </span>

            </div>
            <div
              className='vela-handoff-container d-flex justify-content-center'
              style={{
                backgroundImage: `url(${VELA_HANDOFF_QRCODE_CONTAINER})`
              }}
            >
              <div className='m-auto'>

                <img
                  src={QRCODE_VELA}
                  alt="VELA"
                />
              </div>
            </div>
            <div className='d-flex justify-content-center mb-5'>
              <div className='d-flex align-items-center mt-5'>
                <FaMobileAlt size={50} />
                <div className='ml-2'>
                  <div className='font-weight-bold h6 mb-0'>
                    Hand off to mobile
                  </div>
                  <div>
                    Use your mobile device camera to
                    <br />
                    scan the QR code above.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VelaHandoff;
