import React from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentModal } from '../../actions/Modals';
import WageSchedule from './WageSchedule';

const WageSchedules = ({
  className = 'card mt-3',
  addressState = '',
  helpText = "Wage schedules set a trainee's compensation as they progress and gain skills. Each occupation should have at least one wage schedule, but multiple wage progression options can be provided to reflect employer, industry, and location differences.",
  showCreateNewButton = false,
  ojtHours = 0,
  canCreateNew = false,
  data
}) => {
  const dispatch = useDispatch();

  const createWageSchedule = () => {
    dispatch(setCurrentModal({
      key: 'manageWageSchedule',
      data: {
        addressState
      }
    }));
  };

  return (
    <div className={className}>
      <div className='card-body'>
        {helpText && (
          <div className='mb-4'>
            {helpText}
          </div>
        )}

        {data && Array.isArray(data) && data.map((wageSchedule) => (
          <WageSchedule
            key={`wageSchedule-${wageSchedule?.id}`}
            ojtHours={ojtHours}
            addressState={addressState}
            wageSchedule={wageSchedule}
            className="mb-3"
            showMenu
            readOnly
          />
        ))}

        {showCreateNewButton && (
          <div className='d-flex justify-content-center py-4 bg-light rounded'>
            <button
              type='button'
              className='btn btn-primary btn-sm'
              onClick={createWageSchedule}
              disabled={canCreateNew}
            >
              New Wage Schedule
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WageSchedules;
