import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import {
  createInstructionProvider, updateInstructionProvider
} from '../../../services/instructionProviders';
import { ORG_CONSOLE_INSTRUCTION_PROVIDER_BASE } from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';
import { resetCurrentModal } from '../../../actions/Modals';
import Address from '../../ManageContent/Address';
import { getInstructionProvider, getInstructionProviders } from '../../../actions/InstructionProviders';
import { fetchApprenticeship, updateApprenticeship } from '../../../services/apprenticeships';
import { getApprenticeship } from '../../../actions/Apprenticeships';

const Form = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const instructionProvider = useSelector((state) => state.instructionProvider);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentModal = useSelector((state) => state.currentModal);
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  // Organization
  const orgId = pathOr(null, ['id'], organization);
  // Misc
  const [loading, setLoading] = useState(false);
  const [id] = useState(instructionProvider?.id || '');
  const [name, setName] = useState(instructionProvider?.name || '');
  const [externalId, setExternalId] = useState(instructionProvider?.externalId || '');
  const [url, setUrl] = useState(instructionProvider?.url || '');
  const [phone, setPhone] = useState(instructionProvider?.phone || '');
  // Address
  const [addressLine1, setAddressLine1] = useState(instructionProvider?.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(instructionProvider?.addressLine2 || '');
  const [addressCity, setAddressCity] = useState(instructionProvider?.addressCity || '');
  const [addressState, setAddressState] = useState(instructionProvider?.addressState || '');
  const [addressPostalCode, setAddressPostalCode] = useState(instructionProvider?.addressPostalCode || '');
  const [addressCountry, setAddressCountry] = useState(instructionProvider?.addressCountry || '');
  // Contact
  const [contactFirstName, setContactFirstName] = useState(instructionProvider?.contactFirstName || '');
  const [contactLastName, setContactLastName] = useState(instructionProvider?.contactLastName || '');
  const [contactTitle, setContactTitle] = useState(instructionProvider?.contactTitle || '');
  const [contactEmail, setContactEmail] = useState(instructionProvider?.contactEmail || '');
  const [contactPhone, setContactPhone] = useState(instructionProvider?.contactPhone || '');
  const [contactPhoneExt, setContactPhoneExt] = useState(instructionProvider?.contactPhoneExt || '');

  const handleUserAddressOnChange = (e) => {
    const { name: fieldName, value } = e.currentTarget;

    if (fieldName === 'line1') {
      setAddressLine1(value);
    }

    if (fieldName === 'line2') {
      setAddressLine2(value);
    }

    if (fieldName === 'city') {
      setAddressCity(value);
    }

    if (fieldName === 'state') {
      setAddressState(value);
    }

    if (fieldName === 'postal_code') {
      setAddressPostalCode(value);
    }

    if (fieldName === 'country') {
      setAddressCountry(value);
    }
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const showSuccessAndClose = () => {
    toast.success('Instruction Provider saved!');
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      name,
      externalId,
      url,
      phone,
      // POC
      contactFirstName,
      contactLastName,
      ...(contactTitle ? { contactTitle } : {}),
      contactEmail,
      ...(contactPhone ? { contactPhone } : {}),
      ...(contactPhoneExt ? { contactPhoneExt } : {}),
      // Address
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressPostalCode,
      addressCountry
    };

    setLoading(true);

    if (id) {
      updateInstructionProvider(dataToSave, id).then(() => {
        dispatch(getInstructionProvider(id)).then(() => {
          showSuccessAndClose();
        });
      });
    } else {
      createInstructionProvider({
        ...dataToSave,
        orgId
      }).then((docId) => {
        // Adding from Apprenticeship
        if (apprenticeshipId) {
          toast.info('Updating Training Program....', { toastId: 'updatingProgramInfo' });
          fetchApprenticeship(apprenticeshipId).then((response) => {
            const instructionProviderIds = response?.instructionProviderIds || [];
            const newInstructionProviderIds = [
              docId,
              ...instructionProviderIds
            ];
            updateApprenticeship({
              instructionProviderIds: newInstructionProviderIds
            }, apprenticeshipId).then(() => {
              dispatch(getApprenticeship(apprenticeshipId));
              dispatch(getInstructionProviders({
                orgId,
                instructionProviderIds: newInstructionProviderIds
              }));
              toast.dismiss('updatingProgramInfo');
              showSuccessAndClose();
            });
          });
        } else {
          // Adding from root level Instruction Providers List
          document.location = routeWithOrgId({
            route: `${ORG_CONSOLE_INSTRUCTION_PROVIDER_BASE}/${docId}`,
            orgId
          });
          showSuccessAndClose();
        }
      });
    }
  };

  return (

    <form onSubmit={handleSubmit}>
      <div className="card-body">
        <div className="mb-3">
          <label className="mb-2">
            <b>Name:</b> <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            defaultValue={name}
            onChange={(e) => {
              const { value } = e.currentTarget;

              setName(value);
            }}
            autoFocus={!id}
            required
          />
        </div>

        <div className="mb-3">
          <label className="mb-2">
            <b>External ID:</b>
          </label>
          <input
            type="text"
            className="form-control"
            name="externalId"
            defaultValue={externalId}
            onChange={(e) => {
              const { value } = e.currentTarget;

              setExternalId(value);
            }}
          />
        </div>

        <div className="mb-3">
          <label className="mb-2">
            <b>Website:</b>
          </label>
          <input
            type="text"
            className="form-control"
            name="url"
            defaultValue={url}
            onChange={(e) => {
              const { value } = e.currentTarget;

              setUrl(value);
            }}
          />
          <div className="text-muted small mt-1">
            Ex: https://spacetec.us/certtec/
          </div>
        </div>

        <div className="mb-3">
          <label className="mb-2">
            <b>Phone:</b>
          </label>
          <input
            type="text"
            className="form-control"
            name="phone"
            defaultValue={phone}
            onChange={(e) => {
              const { value } = e.currentTarget;

              setPhone(value);
            }}
          />
        </div>

        <div className="card mb-3">
          <div className='card-header bg-secondary'>
            <h5 className='m-0 font-weight-bold'>
              Point of Contact
            </h5>
          </div>
          <div className="card-body border">
            <div className='d-flex mb-3'>
              <div className="mr-3 flex-fill">
                <label
                  htmlFor="contactFirstName"
                >
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="contactFirstName"
                  className="form-control"
                  value={contactFirstName}
                  required
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setContactFirstName(value);
                  }}
                  autoFocus={currentModal.data?.autoFocusFirstName || false}
                />
              </div>
              <div className="flex-fill">
                <label
                  htmlFor="contactLastName"
                >
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="contactLastName"
                  className="form-control"
                  value={contactLastName}
                  required
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setContactLastName(value);
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="contactTitle">
                Title
              </label>
              <input
                type="text"
                name="contactTitle"
                className="form-control"
                value={contactTitle}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setContactTitle(value);
                }}
              />
              <div className="small mt-1">
                Ex: President, Director of Education, etc.
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="contactEmail">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="contactEmail"
                className="form-control"
                value={contactEmail}
                required
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setContactEmail(value);
                }}
              />
            </div>

            <div className="row">
              <div className="col-6 mb-3">
                <label htmlFor="contactPhone">
                  Phone
                </label>
                <input
                  data-demo="disabled"
                  type="text"
                  name="contactPhone"
                  onChange={(e) => {
                    const { value: newPhone } = e.currentTarget;

                    setContactPhone(newPhone);
                  }}
                  className="form-control"
                  value={contactPhone}
                />
                <div className="small mt-1">
                  Ex: 412-444-8615
                </div>
              </div>

              <div className="col-6">
                <label htmlFor="contactPhoneExt">
                  Phone Ext.
                </label>
                <input
                  data-demo="disabled"
                  type="text"
                  name="contactPhoneExt"
                  onChange={(e) => {
                    const { value: newContactPhoneExt } = e.currentTarget;

                    setContactPhoneExt(newContactPhoneExt);
                  }}
                  className="form-control"
                  value={contactPhoneExt}
                />
                <div className="small mt-1">
                  Ex: 101
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3">
          <div className='card-header bg-secondary'>
            <h5 className='m-0 font-weight-bold'>
              Address
            </h5>
          </div>
          <div className="card-body border">
            <Address
              address={{
                line1: addressLine1,
                line2: addressLine2,
                city: addressCity,
                state: addressState,
                postal_code: addressPostalCode,
                country: addressCountry
              }}
              required
              editMode
              onChange={handleUserAddressOnChange}
              inputFieldStyle={{ padding: '5px 7px' }}
              autoFocus={false}
            />
          </div>
        </div>

      </div>

      <div className="card-footer d-flex justify-content-between mt-4">
        <div>
          <button
            className="btn btn-primary mr-2"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>

          <button
            className="btn btn-sm btn-link"
            type="button"
            disabled={loading}
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
