import React from 'react';
import { useSelector } from 'react-redux';
import DotMenuWorkforceInfo from './DotMenuWorkforceInfo';
import InfoField from '../../../../InfoField';

const IdentificationBlock = ({
  title = 'Employer Identification',
  className = 'card m-3 w-100',
  showMenu
}) => {
  const employerProfile = useSelector((state) => state.employerProfile);

  return (
    <div className={className}>
      <div className='card-header d-flex justify-content-between align-items-center'>
        <div className='font-weight-bold'>
          {title}
        </div>
        {showMenu && (
          <DotMenuWorkforceInfo />
        )}
      </div>

      <div className='card-body h-100'>
        <InfoField
          className="mb-2"
          name="EIN"
          value={employerProfile.ein}
        />
        <InfoField
          name="NAICS Code"
          value={employerProfile.naicsCode}
        />
      </div>
    </div>
  );
};

export default IdentificationBlock;
