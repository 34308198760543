export const mockRawText = `
01001100 01101111 01110010 01100101 01101101 00100000 01101001 01110000 01110011 01110101
 01101101 00100000 01100100 01101111 01101100 01101111 01110010 00100000 01110011 01101001
 01110100 00100000 01100001 01101101 01100101 01110100 00101100 00100000 01100011 01101111
 01101110 01110011 01100101 01100011 01110100 01100101 01110100 01110101 01110010 00100000
 01100001 01100100 01101001 01110000 01101001 01110011 01100011 01101001 01101110 01100111
 00100000 01100101 01101100 01101001 01110100 00101110 00100000 01000011 01110010 01100001
 01110011 00100000 01101101 01101111 01101100 01101100 01101001 01110011 00101100 00100000
 01100101 01110011 01110100 00100000 01101110 01101111 01101110 00100000 01110011 01101111
 01101100 01101100 01101001 01100011 01101001 01110100 01110101 01100100 01101001 01101110
 00100000 01110000 01110101 01101100 01110110 01101001 01101110 01100001 01110010 00101100
 00100000 01101101 01100001 01100111 01101110 01100001 00100000 01101110 01101001 01110011
 01101001 00100000 01101100 01100001 01101111 01110010 01100101 01100101 01110100 00100000
 01101001 01110000 01110011 01110101 01101101 00101100 00100000 01110101 01110100 00100000
 01110100 01100101 01101101 01110000 01101111 01110010 00100000 01101100 01100101 01100011
 01110100 01110101 01110011 00100000 01110100 01100101 01101100 01101100 01110101 01110011
 00100000 01100001 01110100 00100000 01101110 01101001 01110011 01101001 00101110 00100000
 01010110 01100101 01110011 01110100 01101001 01100010 01110101 01101100 01110101 01101101
 00100000 01101111 01110010 01101110 01100001 01110010 01100101 00100000 01100100 01101001
 01100111 01101110 01101001 01110011 01110011 01101001 01101101 00100000 01110000 01110101
 01110010 01110101 01110011 00101100 00100000 01110011 01100101 01100100 00100000 01110100
 01110010 01101001 01110011 01110100 01101001 01110001 01110101 01100101 00100000 01101101
 01100101 01110100 01110101 01110011 00100000 01100101 01100111 01100101 01110011 01110100
 01100001 01110011 00100000 01100101 01110101 00101110 00100000 01010000 01100101 01101100
 01101100 01100101 01101110 01110100 01100101 01110011 01110001 01110101 01100101 00100000
 01110110 01101001 01110100 01100001 01100101 00100000 01110011 01101111 01101100 01101100
 01101001 01100011 01101001 01110100 01110101 01100100 01101001 01101110 00100000 01100001
 01101110 01110100 01100101 00101100 00100000 01101110 01100101 01100011 00100000 01101001
 01101110 01110100 01100101 01110010 01100100 01110101 01101101 00100000 01101101 01100001
 01110011 01110011 01100001 00101110 00100000 01001110 01110101 01101100 01101100 01100001
 00100000 01110011 01100101 01101101 01110000 01100101 01110010 00100000 01100110 01100001
 01110101 01100011 01101001 01100010 01110101 01110011 00100000 01100001 01110010 01100011
 01110101 00101100 00100000 01101110 01101111 01101110 00100000 01110000 01101100 01100001
 01100011 01100101 01110010 01100001 01110100 00100000 01100101 01110011 01110100 00100000
 01001100 01101111 01110010 01100101 01101101 00100000 01101001 01110000 01110011 01110101
 01101101 00100000 01100100 01101111 01101100 01101111 01110010 00100000 01110011 01101001
 01110100 00100000 01100001 01101101 01100101 01110100 00101100 00100000 01100011 01101111
 01101110 01110011 01100101 01100011 01110100 01100101 01110100 01110101 01110010 00100000
 01100001 01100100 01101001 01110000 01101001 01110011 01100011 01101001 01101110 01100111
 00100000 01100101 01101100 01101001 01110100 00101110 00100000 01000011 01110010 01100001
 01110011 00100000 01101101 01101111 01101100 01101100 01101001 01110011 00101100 00100000
 01100101 01110011 01110100 00100000 01101110 01101111 01101110 00100000 01110011 01101111
 01101100 01101100 01101001 01100011 01101001 01110100 01110101 01100100 01101001 01101110
 00100000 01110000 01110101 01101100 01110110 01101001 01101110 01100001 01110010 00101100
 00100000 01101101 01100001 01100111 01101110 01100001 00100000 01101110 01101001 01110011
 01101001 00100000 01101100 01100001 01101111 01110010 01100101 01100101 01110100 00100000
 01101001 01110000 01110011 01110101 01101101 00101100 00100000 01110101 01110100 00100000
 01110100 01100101 01101101 01110000 01101111 01110010 00100000 01101100 01100101 01100011
 01110100 01110101 01110011 00100000 01110100 01100101 01101100 01101100 01110101 01110011
 00100000 01100001 01110100 00100000 01101110 01101001 01110011 01101001 00101110 00100000
 01010110 01100101 01110011 01110100 01101001 01100010 01110101 01101100 01110101 01101101
 00100000 01101111 01110010 01101110 01100001 01110010 01100101 00100000 01100100 01101001
 01100111 01101110 01101001 01110011 01110011 01101001 01101101 00100000 01110000 01110101
 01110010 01110101 01110011 00101100 00100000 01110011 01100101 01100100 00100000 01110100
 01110010 01101001 01110011 01110100 01101001 01110001 01110101 01100101 00100000 01101101
 01100101 01110100 01110101 01110011 00100000 01100101 01100111 01100101 01110011 01110100
 01100001 01110011 00100000 01100101 01110101 00101110 00100000 01010000 01100101 01101100
 01101100 01100101 01101110 01110100 01100101 01110011 01110001 01110101 01100101 00100000
 01110110 01101001 01110100 01100001 01100101 00100000 01110011 01101111 01101100 01101100
 01101001 01100011 01101001 01110100 01110101 01100100 01101001 01101110 00100000 01100001
 01101110 01110100 01100101 00101100 00100000 01101110 01100101 01100011 00100000 01101001
 01101110 01110100 01100101 01110010 01100100 01110101 01101101 00100000 01101101 01100001
 01110011 01110011 01100001 00101110 00100000 01001110 01110101 01101100 01101100 01100001
 00100000 01110011 01100101 01101101 01110000 01100101 01110010 00100000 01100110 01100001
 01110101 01100011 01101001 01100010 01110101 01110011 00100000 01100001 01110010 01100011
 01110101 00101100 00100000 01101110 01101111 01101110 00100000 01110000 01101100 01100001
 01100011 01100101 01110010 01100001 01110100 00100000 01100101 01110011 01110100 00100000
`;
