import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ICON_USERS } from '@apprentage/components/dist/constants/icons';
import { useHistory, useParams } from 'react-router-dom';
import { isProgramTrainer, isProgramManager } from '@apprentage/utils';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import { canAccessUserWageSchedule } from '../../../../../permissions/access';
import UsersList from '../../../../UsersList';

const TraineesBlock = ({
  title = 'Trainees',
  icon = ICON_USERS,
  className = 'card mt-3'
}) => {
  const history = useHistory();
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgId = organization?.id || null;
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserId = apprenticeshipUser?.id || null;
  const apprenticeshipUserSupervisorIds = apprenticeshipUser?.supervisorIds || [];

  const handleClickRow = (clickedUser) => {
    const route = `${apprenticeshipRoute}/users/${clickedUser.id}`;

    history.push(
      routeWithOrgId({
        route,
        orgId
      })
    );
  };

  const hiddenColumns = useMemo(() => {
    const cols = [
      'locationId',
      'remindersCell'
    ];

    if (isProgramManager(role) || isProgramTrainer(role)) {
      cols.push('userProfileId');
      cols.push('isApprentice');
    }

    if (!canAccessUserWageSchedule(role)) {
      cols.push('wageScheduleCell');
    }

    return cols;
  }, [role]);

  return (
    <div className={className}>
      <div className="card-header">
        <i className={`${icon} mr-1`} /> {title}
      </div>

      <UsersList
        key={apprenticeshipUserSupervisorIds.join(',')}
        supervisorIds={[apprenticeshipUserId]}
        apprenticeshipId={apprenticeshipId}
        handleClickRow={handleClickRow}
        hiddenColumns={hiddenColumns}
        excludeTestUsers
        excludeInactive
      />
    </div>
  );
};

export default TraineesBlock;
