import { client, clientManagement, CSPACE } from './contentful';
import convertObjectToUrlParams from '../utils/convertObjectToUrlParams';
import removeKeysWithUndefinedFromObject from '../utils/removeKeysWithUndefinedFromObject';
import { TURBINE } from '../constants/urls';

export const getEntry = (entryId) => {
  return new Promise((resolve, reject) => {
    client.getEntry(entryId).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const createEntry = ({ contentType, data }) => {
  return new Promise((resolve, reject) => {
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.createEntry(contentType, data))
      .then((entry) => entry.publish())
      // .then((entry) => console.log(`Entry ${entry.sys.id} published.`))
      .then((entry) => {
        setTimeout(() => {
          resolve(entry);
        }, 250);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const updateEntry = (data, id) => {
  return new Promise((resolve, reject) => {
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(id))
      .then((entry) => {
        entry.fields = {
          ...entry.fields,
          ...data.fields
        };

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEntry = (id) => {
  return new Promise((resolve, reject) => {
    // Update entry
    clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(id))
      .then((entry) => entry.unpublish())
      .then((entry) => entry.delete())
      .then((entry) => {
        setTimeout(() => {
          resolve(entry);
        }, 500);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

const manageDataPrep = (data) => {
  if (data.addTypes) data.addTypes = data.addTypes.join(',');

  return removeKeysWithUndefinedFromObject(data);
};

export const manageEntry = (data) => {
  const prepData = manageDataPrep(data);

  return convertObjectToUrlParams(prepData);
};

export const createEntryUrl = ({ classId, topicId, entryId }) => {
  let url = `${TURBINE}/classes`;

  if (classId) url += `/${classId}`;
  if (topicId) url += `/${topicId}`;
  if (entryId) url += `/${entryId}`;

  return url;
};

export const idFromContentType = ({ // TODO Remove. Why is this needed?
  contentType,
  orgId,
  classId,
  topicId,
  locationId,
  groupId,
  entryId
}) => {
  switch (contentType) {
    case 'organization':
      return orgId;
    case 'class':
      return classId;
    case 'topic':
      return topicId;
    case 'location':
      return locationId;
    case 'group':
      return groupId;
    default:
      return entryId;
  }
};

export const currentEntryContentTypes = (contentType) => {
  switch (contentType) {
    case 'material':
    case 'challenge':
    case 'quiz':
    case 'eUnitTracking':
    case 'eUnit':
      return true;
    default:
      return false;
  }
};
