export const fileNameFriendly = (str = '', replaceWithStr = '') => {
  return str.replace(/[<>^|!@:"*?\\/]+/g, replaceWithStr);
};

export const adhereToOSFilenames = (str = '', replaceWithStr = 'file') => {
  return str.replace(/^(CON|PRN|AUX|NUL|COM[1-9]|LPT[1-9])$/i, replaceWithStr);
};

export const camelToTitle = (camelCase = '') => {
  return camelCase.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, ' $1');
  // .replace(/([_])/g, ' $1 ')
  // .replace(/([[a-zA-Z]-[a-zA-Z]])/g, ' $1 ');
  // .replace(/^./, str => str.toUpperCase());
};

// export const findDatesInString = (str) => {
//   let result = null;
//   // result = str.match(/((\d{4}\-\d{2}\-\d{2})+)|((\d{2}\-\d{2}\-\d{4})+)/g); // from sean
//   result = str.match(/(?:\d{1}){2,4}([\-/ \.])[0-9]{2}[\-/ \.](?:\d{1}){2,4}/g);

//   if (result !== null) {
//     return result;
//   }

//   return null;
// };

export const unCamelCase = (str) => {
  str = str.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, '$1 $2');
  // str = str.toLowerCase(); //add space between camelCase text
  return str;
};

export const coerceWhiteSpacesToSingle = (str = '', replaceWithStr = ' ') => {
  return str.replace(/\s\s+/g, replaceWithStr);
};

export const hyphenToSpace = (str = '') => {
  // override = false
  // const hasDates = findDatesInString(str);
  // if (!override) { // hasDates &&
  //   return str;
  // }

  // replace hyphens with spaces
  let output = str.replace(/([a-zA-Z])-+([a-zA-Z])/g, '$1 $2');

  // rm double spaces
  output = coerceWhiteSpacesToSingle(output);

  return output;
};

export const plusToSpace = (str = '') => {
  return str.replaceAll('+', ' ');
};

export const underscoreToSpace = (str = '') => {
  // let output = str.replace(/_/g, ' ');
  // let output = str.replace(/(\S)_(\S)/g, '$1 $2');
  let output = str.replace(/_(\S)/g, ' $1');

  output = coerceWhiteSpacesToSingle(output);

  return output;
};
