import React from 'react';
import DateCell from '../../../ManageContent/Tables/DateCell';
import Table from './Table';
import TopicCell from './TopicCell';
import ReviewCell from './ReviewCell';
import StatusCell from './StatusCell';
import ChallengeCell from './ChallengeCell';
import ReviewCheckboxCell from './ReviewCheckboxCell';
import CourseCell from './CourseCell';
import UserNameCell from './UserNameCell';

const List = ({
  data,
  reviewActive,
  userNamePlainText = false,
  hideCourse = false,
  hideTopic = false,
  hideChallenge = false,
  hiddenColumns = []
}) => {
  const columns = React.useMemo(() => [
    { // TODO: Use IndeterminateCheckbox pattern
      Header: ReviewCheckboxCell,
      accessor: 'reviewCheckboxCol',
      Cell: ReviewCheckboxCell,
      disableSortBy: true
    },
    {
      Header: 'User',
      accessor: 'userName',
      Cell: (props) => (
        <UserNameCell
          {...props}
          userNamePlainText={userNamePlainText}
          hideCourse={hideCourse}
          hideTopic={hideTopic}
          hideChallenge={hideChallenge}
        />
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: StatusCell
    },
    {
      Header: 'Review',
      accessor: 'review',
      disableSortBy: true,
      Cell: (props) => (<ReviewCell {...props} reviewActive={reviewActive} />)
    },
    {
      Header: 'Submitted',
      accessor: 'createdAt',
      Cell: DateCell
    },
    {
      Header: 'Challenge',
      accessor: 'challengeId',
      Cell: ChallengeCell
    },
    {
      Header: 'Topic',
      accessor: 'topicId',
      Cell: TopicCell
    },
    {
      Header: 'Course',
      accessor: 'classId',
      Cell: CourseCell
    }
  ], [hideChallenge, hideCourse, hideTopic, reviewActive, userNamePlainText]);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Submissions.
        </div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
    />
  );
};

export default List;
