import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { getJobProfiles } from '../../actions/JobProfiles';
import Loading from '../Loading';
import List from './List';

const JobProfilesList = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const jobProfiles = useSelector((state) => state.jobProfiles);
  // misc
  const orgId = pathOr(null, ['id'], organization);

  useEffect(() => {
    if (orgId) {
      dispatch(getJobProfiles({ orgId }));
    }
  }, [orgId]);

  if (!jobProfiles.fetched && !jobProfiles.list) {
    return (
      <Loading text="Loading Job Profiles..." />
    );
  }

  return (
    <div className="jobProfiles-list">
      <List
        data={jobProfiles.list}
      />
    </div>
  );
};

export default JobProfilesList;
