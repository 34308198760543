const Divider = () => `
  <table
    border="0"
    cellpadding="0"
    cellspacing="0"
    class="divider_block"
    role="presentation"
    style="mso-table-lspace: 0; mso-table-rspace: 0;"
    width="100%"
  >
    <tbody>
      <tr>
        <td
          style="padding-top:10px;padding-bottom:10px;"
        >
          <div align="center">
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              role="presentation"
              style="mso-table-lspace: 0; mso-table-rspace: 0;"
              width="100%"
            >
              <tbody>
                <tr>
                  <td
                    class="divider_inner"
                    style="font-size: 1px; line-height: 1px; border-top: 1px dashed #DCDCDC;"
                  >
                    <span></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
`;

export default Divider;
