import React from 'react';
import CompletionCertificateBadge from '../CompletionCertificateBadge';

const CertificatesCell = ({ row, manageCertificate }) => {
  const user = row?.original;

  return (
    <CompletionCertificateBadge
      user={user}
      manageCertificate={manageCertificate}
    />
  );
};

export default CertificatesCell;
