const generateHashFromArray = (arr, key = 'id') => {
  const hash = {};

  if (!arr) {
    return hash;
  }

  arr.forEach((arrElem) => {
    hash[arrElem[key]] = {
      ...arrElem
    };
  });

  return hash;
};

export default generateHashFromArray;
