function formatAgreement({
  accepted,
  acceptedDate,
  acceptedName,
  acceptedEmail
}) {
  const data = {};

  if (accepted !== undefined) {
    data.accepted = {
      'en-US': accepted
    };
  }

  if (acceptedDate !== undefined) {
    data.acceptedDate = {
      'en-US': acceptedDate
    };
  }

  if (acceptedName !== undefined) {
    data.acceptedName = {
      'en-US': acceptedName
    };
  }

  if (acceptedEmail !== undefined) {
    data.acceptedEmail = {
      'en-US': acceptedEmail
    };
  }

  return { fields: { ...data } };
}

export default formatAgreement;
