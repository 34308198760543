import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import { VELA_ICON } from '@apprentage/constants';
import Table from './Table';
import ApprenticeCell from './ApprenticeCell';
import LocationCell from '../../ManageContent/Tables/LocationCell';
import ProfileCell from './ProfileCell';
import NameCell from './NameCell';
import RoleCell from '../../ManageContent/Tables/RoleCell';
import NumberCell from '../../ManageContent/Tables/NumberCell';
import WageScheduleCell from './WageScheduleCell';
import RemindersCell from './RemindersCell';

const List = ({
  data, handleClickRow, hiddenColumns, showRole = false
}) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Organization
  const orgType = pathOr(null, ['type'], organization);
  // Training Program
  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);
  // Empty data
  const hasData = data && Array.isArray(data) && data.length > 0;

  // table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: (props) => (
          <NameCell
            {...props}
            showRole={showRole}
            handleClickRow={handleClickRow}
          />
        )
      },
      {
        Header: 'Role',
        accessor: 'role',
        disableSortBy: true,
        Cell: (props) => <RoleCell {...props} />
      },
      {
        Header: orgType !== 'workforce' ? 'Employer' : 'Office',
        accessor: 'locationId',
        Cell: (props) => <LocationCell {...props} />
      },
      {
        Header: 'Require PII',
        accessor: 'isApprentice',
        Cell: (props) => <ApprenticeCell {...props} />
      },
      {
        Header: 'Personal Info.',
        accessor: 'userProfileId',
        Cell: (props) => <ProfileCell {...props} />
      },
      {
        Header: 'Wage Schedule',
        accessor: 'wageScheduleCell',
        Cell: (props) => <WageScheduleCell {...props} apprenticeshipId={apprenticeshipId} />
      },
      {
        Header: 'Email Reminders',
        accessor: 'remindersCell',
        Cell: RemindersCell
      },
      {
        Header: () => {
          return (
            <span className='d-flex-inline align-items-center'>
              <img
                src={VELA_ICON}
                alt="VELA"
                className="d-none d-sm-inline-block mr-2"
                height={20}
                style={{
                  height: '20px'
                }}
              />
              <span>Records</span>
            </span>
          );
        },
        accessor: 'recordsCount',
        Cell: NumberCell
      },
      {
        Header: () => {
          return (
            <span className='d-flex-inline align-items-center'>
              <FaCircle className='text-primary mr-2 d-inline' />
              <span>Hours Logged</span>
            </span>
          );
        },
        accessor: 'recordsHours',
        Cell: NumberCell
      },
      {
        Header: () => {
          return (
            <span className='d-flex-inline align-items-center'>
              <FaCheckCircle className='text-success mr-2 d-inline' />
              <span>Hours Approved</span>
            </span>
          );
        },
        accessor: 'recordsHoursApproved',
        Cell: NumberCell
      }
    ],
    [apprenticeshipId, handleClickRow, orgType, showRole]
  );

  if (!hasData) {
    return null;
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
      handleClickRow={handleClickRow}
      className="hasNavTabs noExteriorBorder"
    />
  );
};

export default List;
