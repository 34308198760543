import { SET_USER } from '../../actions/types';

const initialState = {
  fetched: false,
  uid: null
  // displayName: null,
  // photoURL: null,
  // email: null,
  // emailVerified: false,
  // phoneNumber: null,
  // isAnonymous: false,
  // metadata: null, // { lastSignInTime, creationTime },
  // providerData: null // []
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        fetched: true,
        uid: action.user?.uid
      };
    default:
      return state;
  }
}
