import generateHashFromArray from '../../../utils/generateHashFromArray';
import {
  CREATE_COURSE_GROUP,
  RESET_COURSE_GROUPS, SET_COURSE_GROUPS
} from '../../../actions/types';

const initialState = {
  fetched: false,
  list: null,
  hash: null,
  groupTags: null, // string[]
  groupIds: null, // string[]
  courseGroupIds: null // [{}] TODO use groupIds instead
};

export default function courseGroups(state = initialState, action) {
  switch (action.type) {
    case SET_COURSE_GROUPS:
      return {
        ...state,
        fetched: true,
        list: action.list,
        hash: generateHashFromArray(action?.list),
        groupTags: action.groupTags,
        groupIds: action.groupIds
      };
    case CREATE_COURSE_GROUP:
      return {
        ...state,
        list: action.list,
        hash: generateHashFromArray(action?.list)
      };
    case RESET_COURSE_GROUPS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
