import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import { FaGear } from 'react-icons/fa6';
import { ICON_CHALLENGE } from '@apprentage/constants';
import withConsole from '../../../App/withConsole';
import { manageSubmissions, resetCurrentAnswers } from '../../../../actions/Answers';
import { routeWithClassId } from '../../../../services/courses';
import { CLASS_CONSOLE_CHALLENGES, CLASS_CONSOLE_CHALLENGES_SUBMISSIONS } from '../../../../constants/routes';
import { canManageCourseContent } from '../../../../services/currentUser';
import { withAuthorization } from '../../../Session';
import EntryButton from '../../../btns/EntryButton';
import Answers from '../../../Answers';

const ConsoleSubmissions = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const currentChallenge = useSelector((state) => state.currentChallenge);
  const currentAnswers = useSelector((state) => state.currentAnswers);
  // Params
  const challengeId = params?.challengeId || '';
  const userId = params?.userId || '';
  // Organization
  const orgType = organization?.type || '';
  // Challenge
  const topicId = currentChallenge?.topicId || '';
  const pagination = currentAnswers?.pagination || '';
  // Pagination
  const paginationTotal = pagination?.total || '';
  // Query Params
  const queryParams = new URLSearchParams(window.location.search);
  const classId = queryParams.get('classId');

  useEffect(() => { // TODO is this needed?
    return function cleanup() {
      dispatch(resetCurrentAnswers());
    };
  }, []);

  const manageCourse = canManageCourseContent({ course, currentUser, orgType });

  return (
    <>
      <div className="card my-3">
        <div className="card-header bg-light p-3 d-flex align-items-center justify-content-between">
          <h5 className="m-0 text-capitalize d-flex align-items-center">
            <i className={`${ICON_CHALLENGE} mr-2`} aria-hidden="true" /> Challenges
          </h5>
          {challengeId && (
            <EntryButton
              title="Preview"
              classId={classId}
              topicId={topicId}
              entryId={challengeId}
            />
          )}
        </div>

        <div className="pt-3 bg-light">
          <ul className="nav nav-tabs pl-3">
            <li className="nav-item">
              <Link
                className="nav-link mr-2"
                to={routeWithClassId({
                  route: CLASS_CONSOLE_CHALLENGES,
                  classId
                })}
                title="All Challenges"
              >
                All
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={routeWithClassId({
                  route: `${CLASS_CONSOLE_CHALLENGES_SUBMISSIONS}`,
                  classId
                })}
                className="nav-link active d-flex align-items-center"
              >
                <span>
                  Submissions
                </span>
                <span
                  className="badge bg-white border px-2 py-1 ml-2"
                  style={{ color: '#495057' }}
                >
                  {currentAnswers.list && currentAnswers.list.length !== paginationTotal && `${currentAnswers.list.length} / `}{paginationTotal}
                </span>
              </Link>
            </li>
          </ul>
        </div>

        <Answers
          classId={classId}
          userId={userId}
        />
      </div>

      {manageCourse && (currentAnswers.list && currentAnswers.list.length !== 0) && (
        <div className="mb-3">
          <div className="d-flex justify-content-end mb-1">
            <button
              onClick={() => {
                dispatch(manageSubmissions(challengeId));
              }}
              className="btn-link text-capitalize d-flex align-items-center"
              type="button"
            >
              <FaGear
                className="text-ships-officer mr-1"
                size={15}
              />
              <span>
                Manage Submissions
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(ConsoleSubmissions);
