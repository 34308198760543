import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { withAuthorization } from '../../components/Session';
import { ONBOARDING } from '../../constants/routes';
import withOrgConsole from '../../components/App/withOrgConsole';
import './style.css';

const Onboarding = () => {
  useEffect(() => {
    document.body.classList.add('onboarding');

    return function cleanup() {
      document.body.classList.remove('onboarding');
    };
  }, []);

  return <Redirect to={`${ONBOARDING}/welcome`} />;
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Onboarding);
