export const SLASH = '/';
export const CLASSES = '/'; // change to classes when we have a dashboard
export const SEARCH_CONTENT = '/search';
export const SEARCHES_USER = `${SEARCH_CONTENT}/audit/user`;
export const SEARCHES_ALL = `${SEARCH_CONTENT}/audit/all`;
// ONBOARDING
export const ONBOARDING = '/onboarding';
export const ONBOARDING_WELCOME = `${ONBOARDING}/welcome`;
export const ONBOARDING_TEAM_CONTEXT = `${ONBOARDING}/team-context`;
export const ONBOARDING_WORKFORCE_SPACE = `${ONBOARDING}/workforce-space`;
export const ONBOARDING_ORG_CONTEXT = `${ONBOARDING}/org-context`;
// Org Console
export const ORG_CONSOLE_BASE = '/org';
// Apprentage
export const ORG_CONSOLE_WORKFORCE = `${ORG_CONSOLE_BASE}/workforce`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS = `${ORG_CONSOLE_WORKFORCE}/apprenticeships`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/:apprenticeshipId`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_MANAGE = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/manage/:apprenticeshipId?`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_COMPETENCIES = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/competencies`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_COURSES = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/courses`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_COURSES_MANAGE = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_COURSES}/manage`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_INSTRUCTION_PROVIDERS = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/instruction-providers`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYERS = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/employers`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYER = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/employer/:apprenticeshipEmployerId`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYER_DOCS = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/employer/:apprenticeshipEmployerId/docs`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_EMPLOYER_WAGES = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/employer/:apprenticeshipEmployerId/wage-schedules`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_INVITES = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/invites`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USERS = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/users`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_TRAINERS = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP}/trainers`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USERS}/:apprenticeshipUserId`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_DOCS = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USERS}/:apprenticeshipUserId/docs`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_LOGBOOK = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USERS}/:apprenticeshipUserId/logbook`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_RECORDS = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USERS}/:apprenticeshipUserId/records`;
export const ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USER_WAGE_SCHEDULE = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIP_USERS}/:apprenticeshipUserId/wage-schedule`;
// Org Console
export const ORG_CONSOLE_SKILLS = `${ORG_CONSOLE_BASE}/skills`;
export const ORG_CONSOLE_DASHBOARD = `${ORG_CONSOLE_BASE}/dashboard`;
export const ORG_CONSOLE_LOCATIONS = `${ORG_CONSOLE_BASE}/locations`;
export const ORG_CONSOLE_LOCATION_MANAGE = `${ORG_CONSOLE_LOCATIONS}/manage/:locationId?`;
export const ORG_CONSOLE_LOCATION_BASE = `${ORG_CONSOLE_BASE}/location`;
export const ORG_CONSOLE_LOCATION = `${ORG_CONSOLE_LOCATION_BASE}/:locationId?`;
export const ORG_CONSOLE_NOTIFICATIONS = `${ORG_CONSOLE_BASE}/notifications`;
export const ORG_CONSOLE_WIDGETS = `${ORG_CONSOLE_BASE}/widgets/:widgetType?`;
export const ORG_CONSOLE_WIDGETS_REORDER = `${ORG_CONSOLE_WIDGETS}/reorder`;
export const ORG_CONSOLE_RESOURCES = `${ORG_CONSOLE_BASE}/resources`;
export const ORG_CONSOLE_CHALLENGES = `${ORG_CONSOLE_BASE}/challenges`;
export const ORG_CONSOLE_CHALLENGES_USER = `${ORG_CONSOLE_BASE}/challenges/user-submissions/:userId`;
export const ORG_CONSOLE_RESOURCES_TAGS = `${ORG_CONSOLE_BASE}/resources/tags`;
export const ORG_CONSOLE_COURSES = `${ORG_CONSOLE_BASE}/courses`;
// Logbooks
export const ORG_CONSOLE_LOGBOOKS = `${ORG_CONSOLE_BASE}/logbooks`;
export const ORG_CONSOLE_LOGBOOK_BASE = `${ORG_CONSOLE_BASE}/logbook`;
export const ORG_CONSOLE_LOGBOOK = `${ORG_CONSOLE_LOGBOOK_BASE}/:logbookId?`;
// Assistants
export const ORG_CONSOLE_ASSISTANTS = `${ORG_CONSOLE_BASE}/assistants`;
export const ORG_CONSOLE_ASSISTANT_BASE = `${ORG_CONSOLE_BASE}/assistant`;
export const ORG_CONSOLE_ASSISTANT = `${ORG_CONSOLE_ASSISTANT_BASE}/:assistantId?`;
export const ORG_CONSOLE_ASSISTANT_MANAGE = `${ORG_CONSOLE_ASSISTANT_BASE}/manage/:assistantId?`;
// Projects
export const ORG_CONSOLE_PROJECTS = `${ORG_CONSOLE_BASE}/projects`;
export const ORG_CONSOLE_PROJECT_BASE = `${ORG_CONSOLE_BASE}/project`;
export const ORG_CONSOLE_PROJECT = `${ORG_CONSOLE_PROJECT_BASE}/:projectId?`;
export const ORG_CONSOLE_PROJECT_MANAGE = `${ORG_CONSOLE_PROJECT_BASE}/manage/:projectId?`;
// Instruction Providers
export const ORG_CONSOLE_INSTRUCTION_PROVIDERS = `${ORG_CONSOLE_BASE}/instruction-providers`;
export const ORG_CONSOLE_INSTRUCTION_PROVIDER_BASE = `${ORG_CONSOLE_BASE}/instruction-provider`;
export const ORG_CONSOLE_INSTRUCTION_PROVIDER = `${ORG_CONSOLE_INSTRUCTION_PROVIDER_BASE}/:instructionProviderId?`;
// Pathways
export const ORG_CONSOLE_PATHWAYS = `${ORG_CONSOLE_BASE}/pathways`;
export const ORG_CONSOLE_PATHWAY = `${ORG_CONSOLE_BASE}/pathways/:pathwayId`;
export const ORG_CONSOLE_PATHWAY_CERTS = `${ORG_CONSOLE_PATHWAY}/certificates/`;
export const ORG_CONSOLE_PATHWAY_PUBLISH = `${ORG_CONSOLE_PATHWAY}/publish/`;
export const ORG_CONSOLE_PATHWAY_JOB_PROFILE = `${ORG_CONSOLE_PATHWAY}/job-profile/:jobProfileId`;
// Job Profiles
export const ORG_CONSOLE_JOB_PROFILES = `${ORG_CONSOLE_BASE}/pathways/job-profiles`;
// Courses
export const ORG_CONSOLE_COURSES_GROUP = `${ORG_CONSOLE_BASE}/courses/group/:groupId`;
export const ORG_CONSOLE_COURSES_GROUP_REORDER = `${ORG_CONSOLE_BASE}/courses/group/:groupId/reorder`;
export const ORG_CONSOLE_COURSES_GROUP_MANAGE = `${ORG_CONSOLE_BASE}/courses/group/:groupId/manage`;
export const ORG_CONSOLE_COURSES_CATALOG = `${ORG_CONSOLE_BASE}/courses/catalog`;
export const ORG_CONSOLE_COURSES_GROUPS = `${ORG_CONSOLE_BASE}/courses/groups`;
export const ORG_CONSOLE_COURSES_SYNDICATION = `${ORG_CONSOLE_BASE}/courses/syndication`;
export const ORG_CONSOLE_COURSES_GROUPS_REORDER = `${ORG_CONSOLE_BASE}/courses/groups/reorder`;
// Users
export const ORG_CONSOLE_USERS = `${ORG_CONSOLE_BASE}/users`;
export const ORG_CONSOLE_USER = `${ORG_CONSOLE_USERS}/:directoryUserId/:deepLinkActiveTab?`;
export const ORG_CONSOLE_USERS_GROUPS = `${ORG_CONSOLE_BASE}/user/groups`;
export const ORG_CONSOLE_USERS_GROUPS_REORDER = `${ORG_CONSOLE_BASE}/user/groups/reorder`;
// Settings
export const ORG_CONSOLE_SETTINGS = `${ORG_CONSOLE_BASE}/settings`;
export const ORG_CONSOLE_SETTINGS_LOCATION = `${ORG_CONSOLE_BASE}/settings/location/:locationId`;
// Org Manage
export const ORG_MANAGE = '/org/manage';

// COURSE CONSOLE
export const CLASS_CONSOLE_BASE = '/classes';
export const CLASS_CONSOLE = `${CLASS_CONSOLE_BASE}/:classId`;
export const CLASS_CONSOLE_SETTINGS = `${CLASS_CONSOLE_BASE}/settings`;
export const CLASS_CONSOLE_PACING = `${CLASS_CONSOLE_BASE}/pacing`;
export const CLASS_CONSOLE_PUBLISH = `${CLASS_CONSOLE_BASE}/publish`;
export const CLASS_CONSOLE_BRANDING = `${CLASS_CONSOLE_BASE}/branding`;
export const CLASS_CONSOLE_DASHBOARD = `${CLASS_CONSOLE_BASE}/dashboard`;
export const CLASS_CONSOLE_STREAMING = `${CLASS_CONSOLE_BASE}/streaming`;
export const CLASS_CONSOLE_NOTIFICATIONS = `${CLASS_CONSOLE_BASE}/notifications`;
export const CLASS_CONSOLE_QUIZZES = `${CLASS_CONSOLE_BASE}/quizzes`;
export const CLASS_CONSOLE_CHALLENGES = `${CLASS_CONSOLE_BASE}/challenges`;
export const CLASS_CONSOLE_CHALLENGES_SUBMISSIONS = `${CLASS_CONSOLE_CHALLENGES}/submissions`;
export const CLASS_CONSOLE_CHALLENGES_SUBMISSIONS_USER = `${CLASS_CONSOLE_CHALLENGES}/user-submissions/:userId`;
export const CLASS_CONSOLE_CHALLENGE_BASE = `${CLASS_CONSOLE_BASE}/challenge`;
export const CLASS_CONSOLE_CHALLENGE = `${CLASS_CONSOLE_BASE}/challenge/:challengeId`;
export const CLASS_CONSOLE_CHALLENGE_SUBMISSIONS = `${CLASS_CONSOLE_CHALLENGE}/submissions`;
export const CLASS_CONSOLE_CHALLENGE_SUBMISSIONS_USER = `${CLASS_CONSOLE_CHALLENGE}/user-submissions/:userId`;
export const CLASS_CONSOLE_QUIZ = `${CLASS_CONSOLE_QUIZZES}/:quizId`;
export const CLASS_CONSOLE_QUIZ_OUTCOME = `${CLASS_CONSOLE_QUIZ}/outcome/:quizOutcomeId`;
export const CLASS_CONSOLE_QUIZ_OUTCOMES = `${CLASS_CONSOLE_BASE}/quiz-outcomes`;
export const CLASS_CONSOLE_QUIZ_USER_OUTCOMES = `${CLASS_CONSOLE_QUIZ_OUTCOMES}/:userId`;
export const CLASS_CONSOLE_USERS = `${CLASS_CONSOLE_BASE}/users`;
export const CLASS_CONSOLE_USER_ROLES = `${CLASS_CONSOLE_USERS}/roles`;
export const CLASS_CONSOLE_COHORTS = `${CLASS_CONSOLE_BASE}/cohorts`;
export const CONSOLE_CE_ATTENDANCE = `${CLASS_CONSOLE_BASE}/ce/attendance`;
export const CONSOLE_CE_TRACKING = `${CLASS_CONSOLE_BASE}/ce/tracking`;
export const CONSOLE_CE_SETTINGS = `${CLASS_CONSOLE_BASE}/ce/settings`;

export const syndicatedCourseAccessibleRoutes = [
  CLASS_CONSOLE_USERS,
  CLASS_CONSOLE_QUIZZES,
  CLASS_CONSOLE_CHALLENGES
];

// COURSE CERTIFICATE
export const CERTIFICATE = '/certificate';
export const CERTIFICATE_CLASS = `${CERTIFICATE}/:classId`;
// MISC
export const MANAGE_CURRENT_ENTRY = '/entry/manage';
export const BILLING = '/billing';
export const BILLING_NEW_SUBSCRIPTION = '/billing/subscriptions/new';
export const ACCOUNT = '/account';
export const ACCOUNT_INTEGRATIONS = `${ACCOUNT}/integrations/:integration`;

// AUTH
export const SSO = '/sso';
export const LOGIN = '/login';
export const LOGIN_ORG = '/login/:organizationSlug?';

// Experts & Admins Only
// TODO refactor to change "assessments" to "challenges"
export const ASSESSMENTS = '/assessments';
export const ASSESSMENT = '/assessments/:answerId/:classId/:topicId/:userId';
// export const MEMBERS = '/members';

// ORG Dashboard
export const DASHBOARD_CONFIGURE = '/dashboard-config';
