import sysState from './sysState';

const courseState = {
  enableCourseContent: true,
  inviteOnly: false,
  externalCourseUrl: '',
  topicIds: null, // string[]
  locationIds: null,
  orgId: '',
  orgIds: null,
  title: '',
  overview: '', // "Long Description"
  schedule: '',
  cohorts: null, // TODO course flatten, remove field
  subjects: null, // ref[{}] // TODO course flatten, remove field
  description: '', // "Short Description"
  topics: null, // string[] "Course Tags"
  prerequisite: null, // TODO course flatten, remove field
  classColor: '',
  classImage: '',
  integration: null,
  slackChannel: '',
  teachers: null, // ref[{}] // TODO course flatten, remove field
  observers: null, // ref[{}] // TODO course flatten, remove field

  // TODO move to Cohort
  showStreamLink: false,
  isLive: false,
  streamingLink: '',
  // /TODO move to Cohort

  dashboard: false, // Is this an enterprise flag?
  locked: true,
  testClass: false, // TODO remove field, not needed anymore
  openEnrollment: false,
  registerUrl: '',
  leaderboard: false, // TODO remove field, boolean
  trackAttendance: false,

  // TODO move to firebase
  completionCertificate: false,
  completionCertificateLogo: '',
  completionCertificateDescription: '',
  // /TODO move to firebase

  guidedCourse: false,
  courseResourceGroupIds: null, // string[]
  notifications: null, // {}
  courseVideo: '',

  // TODO implement this functionality
  courseAdminUserIds: null, // string[]
  courseEditorUserIds: null, // string[]
  // /TODO implement this functionality

  courseSlug: '',
  isPublic: false,
  searchEnginesEnabled: false,

  courseReadOnly: false,

  skillIds: null,

  userIds: null, // Authors
  userId: null, // Author

  ...sysState
};

export default courseState;
