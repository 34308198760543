import { findObjectByIndex } from '@apprentage/utils';

function findObjectByKeyPartialVal(objArray, key, value) {
  if (key.includes('.')) { // Ex: 'sys.id'
    const keys = key.split('.');
    return objArray.find((obj) => keys.reduce(findObjectByIndex, obj).includes(value));
  }

  return objArray.find((obj) => obj[key].includes(value));
}

export default findObjectByKeyPartialVal;
