import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { roleReadable } from '@apprentage/utils';
import { FaBell, FaShieldAlt, FaThumbtack } from 'react-icons/fa';
import { mdySimple } from '../../../utils/date';
import { canEditDeleteNotification } from '../../../services/currentUser';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import UserProfilePicture from '../../UserProfilePicture';
import DotMenu from './DotMenu';

const Notification = ({
  data,
  listConfig,
  showUserRoles = false,
  showMenu = false,
  empty,
  className = ''
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || '';
  // Notification
  const createdAt = data?.createdAt || '';
  const postAsOrg = data?.postAsOrg || false;
  const isPinned = data?.isPinned || false;
  const authorName = data?.authorName || '';
  const authorId = data?.authorId || '';
  const authorUid = data?.authorUid || '';
  const message = data?.message || '';
  const userRoles = data?.userRoles || [];
  // Organization
  const orgLogo = organization?.orgLogo || '';
  const orgName = organization?.name || '';
  // User
  const userId = currentUser?.id || '';
  const role = currentUser?.role || [];

  const readableRoles = Array.isArray(userRoles) && userRoles.length !== 0
    ? userRoles.map((r) => roleReadable([r], orgType))
    : null;

  return (
    <div className={`d-flex ${className}`}>
      <div>

        {empty ? (
          <div
            className='bg-white d-flex align-items-center justify-content-center'
            style={{
              width: '50px',
              height: '50px',
              border: '1px dashed #dee2e6',
              borderRadius: '100%'
            }}
          >
            <FaBell size={20} className='text-muted' />
          </div>
        ) : (
          <>
            {postAsOrg ? (
              <OrgAvatarContainer className="border p-1">
                <OrgLogo
                  src={orgLogo}
                  alt={orgName}
                  height="40"
                  width="40"
                />
              </OrgAvatarContainer>
            ) : (
              <UserProfilePicture
                userId={authorId}
                alt={authorName}
              />
            )}
          </>
        )}
      </div>

      <div className="ml-3 w-100">
        <NotificationMessageContainer
          data-demo="disabled"
          className="notification-message-container m-0"
        >
          <div className="d-flex align-items-center justify-content-between mb-1">
            <div
              className="font-weight-bold"
              style={{ fontSize: '1rem' }}
            >
              {postAsOrg !== undefined && (
                <>
                  {postAsOrg ? orgName : authorName}
                </>
              )}
            </div>
            <div className="d-flex align-items-center">
              {createdAt && (
                <div
                  className="text-muted"
                  data-demo="disabled"
                  data-author-id={authorId}
                  data-author-uid={authorUid}
                >
                  {mdySimple(createdAt)}
                </div>
              )}
              {showMenu && canEditDeleteNotification(role, userId, authorId) && (
                <DotMenu
                  data={data}
                  listConfig={listConfig}
                  className="ml-2"
                />
              )}
            </div>
          </div>
          {empty ? (
            <div
              className="shadow p-3 rounded bg-white position-relative"
              style={{
                minHeight: '80px',
                border: '1px dashed #dee2e6'
              }}
            />
          ) : (
            <div
              className={`shadow p-3 rounded bg-white position-relative ${isPinned && 'border border-primary-light shadow-primary-light'}`}
              style={{
                wordBreak: 'break-word',
                minHeight: '80px'
              }}
            >
              <RenderMarkdown
                source={message}
                enableNewEditor={false}
              />
              {showUserRoles && readableRoles && (
                <div className="text-muted d-flex align-items-center">
                  <FaShieldAlt />
                  <span className="ml-1">{readableRoles.join(', ')}</span>
                </div>
              )}
              {isPinned && (
                <div
                  className="position-absolute"
                  style={{
                    top: '5px',
                    left: '5px'
                  }}
                >
                  <FaThumbtack className="text-primary" />
                </div>
              )}
            </div>
          )}

        </NotificationMessageContainer>
      </div>
    </div>
  );
};

const NotificationMessageContainer = styled.div`
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const OrgAvatarContainer = styled.div`
  height: 40px;
  width: 40px;
  overflow: hidden;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: white;
`;

export default Notification;
