import React from 'react';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import RenderFile from './RenderFile';

const Results = () => {
  const currentAnswer = useSelector((state) => state.currentAnswer);
  const currentChallenge = useSelector((state) => state.currentChallenge);

  if (!currentAnswer.id || !currentChallenge.id) {
    return null;
  }

  const results = pathOr(null, ['results'], currentAnswer);
  const questions = pathOr(null, ['questions'], currentChallenge);
  let serializedResults = null;

  if (results && results.formData) {
    serializedResults = Object.entries(results.formData).map(([key, obj]) => ({
      title: obj.title,
      value: obj.value,
      type: obj.type,
      id: key
    }));
  }

  const removeFileCallback = ({ questionId }) => {
    console.log('questionId', questionId);
    // TODO update UI.
  };

  return (
    <div>
      {serializedResults && serializedResults.map(({
        id, title, value, type
      }) => {
        const question = questions ? questions.find(({ questionId }) => questionId && questionId === id) : null;

        return (
          <div key={id}>
            {type === 'header' ? (
              <div
                className="border-bottom mb-3"
              >
                <div className="h6 font-weight-bold border-bottom pb-3">
                  {title}:
                </div>
              </div>
            ) : (
              <div className="mb-3">
                <div className='font-weight-bold mb-1'>
                  <RenderMarkdown
                    source={title}
                    enableNewEditor
                  />
                </div>

                {question && question.description && (
                  <div className="mt-2">
                    <RenderMarkdown
                      source={question.description}
                      enableNewEditor
                    />
                  </div>
                )}

                {type === 'file' && (
                  <RenderFile
                    id={id}
                    contentId={currentAnswer.id}
                    contentType="answer"
                    value={value}
                    removeFileCallback={() => {
                      removeFileCallback({ questionId: id });
                    }}
                  />
                )}

                {(type === 'text' || type === 'textarea' || type === 'radio') && (
                  <div className="border p-2">
                    {value}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Results;
