import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { ICON_PATHWAY } from '@apprentage/constants';
import { getJobProfile, resetJobProfile } from '../../../actions/JobProfiles';
import { getPathway, resetPathway } from '../../../actions/Pathways';
import { withAuthorization } from '../../Session';
import withOrgConsole from '../../App/withOrgConsole';
import Loading from '../../Loading';
import Collapse from '../../ManageContent/Collapse';
import NavTabs from './NavTabs';
import OrgConsoleHeader from '../OrgConsoleHeader';
import { ORG_CONSOLE_PATHWAYS } from '../../../constants/routes';

const Certificates = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const pathway = useSelector((state) => state.pathway);
  const jobProfile = useSelector((state) => state.jobProfile);
  // Misc
  const pathwayId = pathOr(null, ['pathwayId'], params);
  const jobProfileId = pathOr(null, ['jobProfileId'], pathway);

  useEffect(() => {
    const href = pathOr('', ['location', 'href'], window);
    const pathwayRoute = `org/pathways/${pathwayId}`;

    if (pathwayId && !pathway.id) {
      dispatch(getPathway(pathwayId));
    }

    return function cleanup() {
      // Only reset pathway if not going to other pathway management pages
      if (!href.includes(pathwayRoute)) {
        dispatch(resetPathway());
      }
    };
  }, []);

  useEffect(() => {
    dispatch(getJobProfile(jobProfileId));

    return function cleanup() {
      dispatch(resetJobProfile());
    };
  }, [jobProfileId]);

  if (!pathway.id) {
    return (
      <Loading />
    );
  }

  const badgeClassName = (CertDetailListValue) => {
    if (CertDetailListValue === 'Yes') {
      return 'badge badge-success';
    }

    if (CertDetailListValue === 'No') {
      return 'badge badge-danger';
    }

    return 'font-weight-bold mt-2';
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={pathway.title}
            icon={ICON_PATHWAY}
            route={ORG_CONSOLE_PATHWAYS}
          />

          <NavTabs
            activeTab='certificates'
          />

          <div
            className="bg-white border-bottom border-left border-right p-3"
          >
            {jobProfile && jobProfile.selectedCerts && (
              <div>
                <div className="mt-3">
                  {jobProfile.selectedCerts.map((selectedCert, i) => (
                    <Collapse
                      id={`certificate-${selectedCert.Id}-${i}`}
                      title={selectedCert.Name}
                      className="my-2"
                      badge={() => {
                        if (selectedCert.Acronym) {
                          return (
                            <span className='badge badge-info'>
                              {selectedCert.Acronym}
                            </span>
                          );
                        }

                        return null;
                      }}
                    >
                      {selectedCert.Description && (
                        <div className="mb-3">
                          {selectedCert.Description}
                        </div>
                      )}

                      {selectedCert.Organization && (
                        <div className="mb-3">
                          <div className="font-weight-bold mb-2">
                            Accrediting Organization:
                          </div>
                          <div className="mb-1">
                            {selectedCert.OrganizationUrl ? (
                              <a
                                className="font-weight-bold"
                                href={selectedCert.OrganizationUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {selectedCert.Organization}
                              </a>
                            ) : (
                              <span className="font-weight-bold">
                                {selectedCert.Organization}
                              </span>
                            )}
                          </div>

                          {selectedCert.OrganizationAddress && (
                            <div>
                              {selectedCert.OrganizationAddress}
                            </div>
                          )}
                        </div>
                      )}

                      {selectedCert.CertDetailList && (
                        <div className="mb-3">
                          <h6 className="font-weight-bold">
                            Details:
                          </h6>

                          {selectedCert.CertDetailList.map(({ Name, Value }) => (
                            <div className="mb-1 p-2 border">
                              {Name && (
                                <div>
                                  {Name}
                                </div>
                              )}
                              {Value && (
                                <div className='h6 m-0'>
                                  <div
                                    className={badgeClassName(Value)}
                                  >
                                    {Value}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </Collapse>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Certificates);
