import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_WORKFORCE } from '../../../constants/routes';
import withOrgConsole from '../../App/withOrgConsole';
import OrgConsoleHeader from '../OrgConsoleHeader';
import LogbooksList from '../../lists/LogbooksList';

const Logbooks = () => {
  const logbooks = useSelector((state) => state.logbooks);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={`Logbooks (${Array.isArray(logbooks?.list) ? logbooks.list.length : 0})`}
            icon='fas fa-book'
            route={ORG_CONSOLE_WORKFORCE}
          />

          <div
            className="bg-white border shadow rounded mt-3 p-3 overflow-content"
          >
            <div className='d-flex justify-content-end mb-3'>
              <div>
                Total: {Array.isArray(logbooks.list) ? logbooks.list.length : 0}
              </div>
            </div>
            <LogbooksList />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Logbooks);
