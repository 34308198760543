import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import { FaArrowsAlt, FaUsers } from 'react-icons/fa';
import { resetUserGroups } from '../../../../actions/Groups';
import { withAuthorization } from '../../../Session';
import { routeWithOrgId } from '../../../../services/organizations';
import { ORG_CONSOLE_DASHBOARD, ORG_CONSOLE_USERS_GROUPS } from '../../../../constants/routes';
import withOrgConsole from '../../../App/withOrgConsole';
import Form from './Form';
import Tabs from '../Tabs';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsoleSubheader';

const ReorderUserGroups = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const userGroups = useSelector((state) => state.userGroups);
  // Misc
  const orgId = pathOr('', ['id'], organization);
  const userGroupIds = pathOr(null, ['userGroupIds'], organization);
  const userGroupsRoute = routeWithOrgId({
    route: ORG_CONSOLE_USERS_GROUPS,
    orgId
  });

  useEffect(() => {
    return function cleanup() {
      const href = pathOr('', ['location', 'href'], window);

      // Only reset if not going to apprentice route
      if (!href.includes(ORG_CONSOLE_USERS_GROUPS)) {
        dispatch(resetUserGroups());
      }
    };
  }, []);

  if (!userGroups.list || !userGroupIds) {
    history.replace(userGroupsRoute);
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-xl-7">
        <OrgConsoleHeader
          pageTitle="Users"
          iconComponent={() => <FaUsers size={25} className='mr-2' />}
          route={ORG_CONSOLE_DASHBOARD}
        />

        <Tabs active="groups" />

        <div
          className='mb-5 bg-white border-left border-right border-bottom p-3'
        >
          <OrgConsoleSubheader
            pageTitle="Reorder"
            iconComponent={() => <FaArrowsAlt size={15} className='mr-2' />}
            iconStyle={{
              fontSize: '1rem'
            }}
            route={ORG_CONSOLE_USERS_GROUPS}
          />

          <div className='card mt-3'>
            <div className='card-header bg-dark border-dark'>
              <h6 className='m-0 text-white font-weight-bold'>
                User Groups
              </h6>
            </div>
            <Form />
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ReorderUserGroups);
