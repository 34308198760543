import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { ORG_CONSOLE_COURSES } from '../../../../constants/routes';
import { updateGroup } from '../../../../services/groups';
import sortArrByArr from '../../../../utils/sortArrByArr';
import { routeWithOrgId } from '../../../../services/organizations';
import ItemsDnd from '../../../ManageContent/Dnd/Items';

const Form = () => {
  const params = useParams();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courseGroup = useSelector((state) => state.courseGroup);
  const courses = useSelector((state) => state.courses);
  // Local State
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [reorderedList, setReorderedList] = useState(courses.list);

  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const groupId = pathOr(null, ['groupId'], params);
  const itemIds = pathOr(null, ['itemIds'], courseGroup);
  const courseGroupRoute = routeWithOrgId({
    route: `${ORG_CONSOLE_COURSES}/group/${groupId}`,
    orgId
  });
  const sortedList = courses.list ? sortArrByArr(courses.list, itemIds, 'id') : null;

  const items = useMemo(() => {
    const list = hasChanges ? reorderedList : sortedList;

    return list;
  }, [hasChanges, reorderedList]);

  const saveListOrder = () => {
    setLoading(true);

    const dataToSave = {
      itemIds: reorderedList.map((item) => item.id)
    };

    updateGroup(dataToSave, groupId).then(() => {
      toast.success('Saved!');
      history.replace(courseGroupRoute);
    });
  };

  const onUpdate = (newListOrder) => {
    setHasChanges(true);
    setReorderedList(newListOrder && newListOrder.length > 0 ? newListOrder : []);
  };

  // const sortedList = courses ? sortArrByArr(courses, itemIds, 'id') : null;
  // const items = hasChanges ? reorderedList : sortedList;

  console.log('items', items);
  return (
    <Fragment>
      <div
        id="org-courses"
        className="card-body"
      >
        {items && items.length ? (
          <ItemsDnd
            key={items.length}
            items={items}
            onUpdate={onUpdate}
          />
        ) : (
          <div>No courses</div>
        )}
      </div>

      <div className="card-footer d-flex justify-content-between">
        <div>
          <button
            className="btn btn-primary mr-2"
            type="button"
            onClick={saveListOrder}
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <Link
            to={courseGroupRoute}
            title="Cancel"
            className='btn btn-link'
            disabled={loading}
          >
            Cancel
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Form;
