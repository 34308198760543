import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromArray
} from '@apprentage/utils';
import { FaWaveSquare } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { createPathway, getPathway } from '../../../actions/Pathways';
import { ORG_CONSOLE_PATHWAYS } from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';
import { updatePathway } from '../../../services/pathways';
import Modal from '../../Modal';
import SelectMenu from '../../ManageContent/SelectMenu';

const CreatePathway = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  const jobProfiles = useSelector((state) => state.jobProfiles);
  // Local State
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(currentModal?.data?.title || '');
  const [courseIds, setCourseIds] = useState(currentModal?.data?.courseIds || []);
  const [jobProfileId, setJobProfileId] = useState(currentModal?.data?.jobProfileId || '');
  // Organization
  const { id: orgId } = organization;
  // Pathway
  const pathwayId = currentModal?.data?.pathwayId || null;
  // Course
  const courseOptions = reactSelectOptionsFromArray(courses.list, 'title');
  const courseDefaultVals = reactSelectDefaultValues(courseIds, courseOptions);
  // Job Profile
  const jobProfileOptions = reactSelectOptionsFromArray(jobProfiles.list, 'OnetTitle');
  const jobProfileDefaultVal = reactSelectDefaultValue(jobProfileId, jobProfileOptions);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      title,
      courseIds,
      jobProfileId
    };

    if (!courseIds || (courseIds && Array.isArray(courseIds) && courseIds.length === 0)) {
      toast.error('Select at least one course.');
      return;
    }

    setLoading(true);

    toast.info('Saving Pathway...');

    if (pathwayId) {
      // Update Pathway
      updatePathway(pathwayId, dataToSave).then(() => {
        dispatch(getPathway(pathwayId)).then(() => {
          toast.success('Pathway Saved!');
          handleClose();
        });
      });

      return;
    }

    dispatch(createPathway({
      ...dataToSave,
      orgId
    })).then((id) => {
      toast.success('Pathway Saved!');

      const route = routeWithOrgId({
        route: `${ORG_CONSOLE_PATHWAYS}/${id}`,
        orgId
      });

      history.replace(route);

      setTimeout(() => {
        handleClose();
      }, 500);
    });
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card border-0">
        <div className="card-header bg-dark">
          <h5 className='m-0 d-flex align-items-center'>
            <FaWaveSquare className='mr-2' />
            <span>
              {pathwayId ? 'Edit' : 'Create'} Pathway
            </span>
          </h5>
        </div>
        <form onSubmit={onSubmit}>
          <div className="card-body">
            <h6 className="mb-3">
              A career pathway outlines a sequence of work experience, education and/or training activities needed to secure a job and advance over time to successively higher levels of employment.
            </h6>
            <div id="course-title" className="mb-4">
              <label htmlFor="title">
                <p className="font-weight-bold">
                  What's the name of this pathway?
                </p>
              </label>
              <input
                id="title"
                type="text"
                className="form-control form-control-md"
                name="title"
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setTitle(value);
                }}
                defaultValue={title}
                autoFocus
                required
              />
            </div>
            <div id="class-group" className="mb-4">
              <label htmlFor="groupId">
                <p className="font-weight-bold m-0">
                  Choose Courses
                </p>
              </label>
              <SelectMenu
                id="courseIds"
                name="courseIds"
                options={courseOptions}
                defaultValue={courseDefaultVals}
                onChange={({ courseIds: newCourseIds }) => {
                  setCourseIds(newCourseIds);
                }}
                isMulti
              />
            </div>
            <div id="class-group" className="mb-4">
              <label htmlFor="groupId">
                <p className="font-weight-bold m-0">
                  Choose a Job Profile
                </p>
              </label>
              <SelectMenu
                id="jobProfileId"
                name="jobProfileId"
                options={jobProfileOptions}
                defaultValue={jobProfileDefaultVal}
                onChange={({ jobProfileId: newJobProfileId }) => {
                  setJobProfileId(newJobProfileId);
                }}
              />
            </div>
          </div>
          <div
            className="card-footer d-flex align-items-center justify-content-between"
          >
            <button
              className="btn btn-sm btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving..' : 'Save'}
            </button>

            {/* {pathwayId && (
              <button
                className="btn btn-sm btn-primary"
                type="button"
                disabled={loading}
                onClick={handleDelete}
              >
                <i className={`${ICON_TRASH} text-danger mr-1`} /> Delete
              </button>
            )} */}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreatePathway;
