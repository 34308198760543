import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchUsersCompletedTopic } from '../../../services/courseDashboard/users';
import { numChallengesInTopic, numQuizzesInTopic } from '../../../services/courseDashboard/topics';
import NumberCard from '../../NumberCard';

const TopicInsights = ({ topic }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  // Local State
  const [completedTopic, setCompletedTopic] = useState(0);
  const [numQuizzes, setNumQuizzes] = useState(0);
  const [numChallenges, setNumChallenges] = useState(0);

  useEffect(() => {
    let mounted = true;

    fetchUsersCompletedTopic({
      orgId,
      completedTopicId: topic.id
    }).then((completedTopicTotal) => {
      if (mounted) {
        setCompletedTopic(completedTopicTotal);
      }
    });

    numQuizzesInTopic({
      orgId,
      topicId: topic.id
    }).then(({ quizzesTotal }) => {
      if (mounted) {
        setNumQuizzes(quizzesTotal);
      }
    });

    numChallengesInTopic({
      orgId,
      topicId: topic.id
    }).then((challengesTotal) => {
      if (mounted) {
        setNumChallenges(challengesTotal);
      }
    });

    return function cleanup() {
      mounted = false;
    };
  }, [orgId, topic.id]);

  if (!topic?.id || !organization?.id) {
    return null;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-4">
          <NumberCard
            num={completedTopic}
            text="Users Completed Topic"
          />
        </div>

        <div className="col-sm-4">
          <NumberCard
            num={numQuizzes}
            text="Topic Quizzes"
          />
        </div>

        <div className="col-sm-4">
          <NumberCard
            num={numChallenges}
            text="Topic Challenges"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TopicInsights;
