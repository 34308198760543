import { useEffect } from 'react';

export function useDebounceEffect(
  fn,
  waitTime, // number
  deps // DependencyList,
) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn.apply(undefined, deps); // eslint-disable-line prefer-spread
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
