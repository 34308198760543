import { pathOr } from 'ramda';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaCopy, FaEllipsisH, FaMapMarkerAlt, FaPaperPlane, FaPencilAlt
} from 'react-icons/fa';
import { ORG_CONSOLE_LOCATIONS } from '../../constants/routes';
import { routeWithOrgId } from '../../services/organizations';
import { canEditLocation } from '../../services/currentUser';
import { getFullAddress, locationTerminology } from '../../services/locations';
import { setCurrentModal } from '../../actions/Modals';
import copyToClipBoard from '../../utils/copyToClipBoard';

const DotMenuInfoBlock = ({ data }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const orgType = pathOr(null, ['type'], organization);
  const role = pathOr([], ['role'], currentUser);
  const manageLocation = canEditLocation(role);
  const locationTerm = locationTerminology({ orgType });

  const locationId = pathOr(null, ['id'], data);
  const mapLink = pathOr(null, ['mapLink'], data);
  const fullAddress = getFullAddress({ location: data });

  const redirectRoute = useMemo(() => {
    if (window.location.href.includes('/org/workforce/apprenticeships/')) {
      return window.location.pathname;
    }

    return '';
  }, []);

  const inviteToCreateJobProfile = () => {
    dispatch(setCurrentModal({
      key: 'inviteUserCreateJobProfile',
      data: {
        locationId
      }
    }));
  };

  return (
    <div className="btn-group align-items-center">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH size={15} />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap" />

        {mapLink && (
          <a
            className="dropdown-item border-bottom py-2"
            href={mapLink}
            role="button"
            title="View on map"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className='d-flex align-items-center'>
              <FaMapMarkerAlt size={15} />
              <span className='ml-2'>
                Open Map
              </span>
            </span>
          </a>
        )}

        <button
          type="button"
          title='Copy Address'
          className="dropdown-item border-bottom py-2"
          onClick={() => {
            copyToClipBoard(fullAddress);
            toast.success('Address Copied!');
          }}
        >
          <span className='d-flex align-items-center'>
            <FaCopy size={15} />
            <span className='ml-2'>
              Copy Address
            </span>
          </span>
        </button>

        {manageLocation && orgType === 'community' && (
          <button
            type="button"
            className="dropdown-item border-bottom py-2"
            onClick={inviteToCreateJobProfile}
          >
            <span className='d-flex align-items-center'>
              <FaPaperPlane size={15} />
              <span className='ml-2'>
                Invite: Create Job Profile
              </span>
            </span>
          </button>
        )}

        {manageLocation && (
          <Link
            className="dropdown-item py-2"
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_LOCATIONS}/manage/${locationId}`,
              urlParams: {
                orgId,
                ...(redirectRoute ? { redirectRoute } : {})
              }
            })}
            title={`Edit ${locationTerm.text}`}
          >
            <span className='d-flex align-items-center'>
              <FaPencilAlt size={15} />
              <span className='ml-2'>
                Edit
              </span>
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default DotMenuInfoBlock;
