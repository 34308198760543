import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FaArrowRight, FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  resetApprenticeship
} from '../../../../../actions/Apprenticeships';
import { resetUsers } from '../../../../../actions/Users';
import { routeWithOrgId } from '../../../../../services/organizations';
import { getEmployers } from '../../../../../actions/Employers';
import { canInviteProgramUsers } from '../../../../../permissions/invite';
import { canAccessAllLocations, canAccessUserWageSchedule } from '../../../../../permissions/access';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import Loading from '../../../../Loading';
import Tabs from '../Tabs';
import UsersList from '../../../../UsersList';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import DotMenu from '../DotMenu';
import UsersTabs from './UsersTabs';
import AddExistingUser from './AddExistingUser';
import isProgramTrainer from '../../../../../permissions/isProgramTrainer';
import isProgramManager from '../../../../../permissions/isProgramManager';

const ApprenticeshipUsersTrainers = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  const users = useSelector((state) => state.users);
  //  CurrentUser
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  //  Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId)).then((response) => {
        const config = {
          orgId,
          ...(response?.employerIds
            ? { employerIds: response.locationIds }
            : {})
        };

        dispatch(getEmployers(config));
      });
    }
  }, [apprenticeshipId, dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      const { href } = window.location;

      dispatch(resetUsers()); // TODO does this need logic like logic below?

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipRoute, dispatch]);

  const handleClickRow = (clickedUser) => {
    const route = `${apprenticeshipRoute}/users/${clickedUser.id}`;

    history.push(
      routeWithOrgId({
        route,
        orgId
      })
    );
  };

  const hiddenColumns = useMemo(() => {
    const result = ['userProfileId', 'isApprentice', 'remindersCell'];

    if (!canAccessAllLocations(role)) {
      result.push('locationId');
    }

    if (!canAccessUserWageSchedule(role)) {
      result.push('wageScheduleCell');
    }

    return result;
  }, [role]);

  const excludeUserIds = useMemo(() => {
    let ids = null;
    if (Array.isArray(users?.list)) {
      ids = users.list.map((user) => user.id);
    }

    return ids;
  }, [users.list]);

  const userListConfig = useMemo(() => {
    const config = {
      orgId,
      apprenticeshipId,
      roles: ['programManager', 'programTrainer', 'expert', 'admin', 'owner'],
      excludeTestUsers: true,
      excludeInactive: true
    };

    if (isProgramTrainer(role) || isProgramManager(role)) {
      config.supervisorIds = [currentUser?.id];
    }

    return config;
  }, [apprenticeshipId, currentUser?.id, orgId, role]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program Users" />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            iconComponent={() => <FaSortAmountUp size={20} />}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          >
            <DotMenu />
          </OrgConsoleHeader>

          <Tabs active="users" />

          <div className="bg-white shadow overflow-content border-left border-right border-bottom">
            <div className="p-3">
              <OrgConsoleSubheader
                pageTitle="Users"
                className="mb-3"
              />

              <UsersTabs active="trainers" />
              <div className="border-left border-right border-bottom">
                <UsersList
                  roles={['programManager', 'programTrainer', 'admin', 'owner']}
                  hiddenColumns={hiddenColumns}
                  apprenticeshipId={apprenticeshipId}
                  handleClickRow={handleClickRow}
                  excludeTestUsers
                  excludeInactive
                />

                {canInviteProgramUsers(role) && (
                  <AddExistingUser
                    userIdsToExclude={excludeUserIds}
                    userListConfig={userListConfig}
                    style={{
                      minHeight: '130px'
                    }}
                  >
                    <div
                      className="pt-3 w-100 text-center"
                    >
                      <Link
                        to={`${apprenticeshipRoute}/invites`}
                        className="btn btn-sm btn-primary"
                      >
                        <span className='d-flex align-items-center'>
                          <span className="mr-2">Invite Users</span>
                          <FaArrowRight />
                        </span>
                      </Link>
                    </div>
                  </AddExistingUser>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUsersTrainers);
