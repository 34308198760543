import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { canCreateResourceTag } from '../../../../../services/currentUser';
import Table from './Table';
import LabelCell from './LabelCell';
import EditCell from './EditCell';

const TagsList = ({
  data
}) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!canCreateResourceTag(role, orgType)) {
      cols.push('id');
    }

    return cols;
  }, [orgType, role]);

  const columns = React.useMemo(() => [
    {
      Header: 'Tag',
      accessor: 'label', // accessor is the "key" in the data
      Cell: LabelCell
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: EditCell
    }
  ], []);

  if (!data || (data && data.length === 0)) {
    return null;
  }

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default TagsList;
