import { SET_CURRENT_MODAL, RESET_CURRENT_MODAL } from './types';

export const setCurrentModal = (currentModal) => {
  document.body.classList.add('turbine-modal-active');

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_CURRENT_MODAL,
        currentModal: {
          ...currentModal
        }
      });
      resolve();
    });
  };
};

export const resetCurrentModal = () => {
  document.body.classList.remove('turbine-modal-active');

  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_MODAL });
  };
};
