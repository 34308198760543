import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';
import SupportEmailLine from '../SupportEmailLine';

const inviteUserCreateJobProfilesHtml = ({
  linkText = 'Create Job Profile',
  orgName,
  locationName,
  inviteLink
}) => {
  return `
    <p>
      You've been invited by ${orgName} to create ${locationName} Job Profiles!
    </p>
    <p>
      The Job Profiles you create help ${orgName} deliver instruction tailored to employers needs in our region.
    </p>
    <p>
      To get started, click the ${linkText} button below.
    </p>

    <div
      align="center"
      style="line-height:10px; padding-top:20px;padding-bottom:20px;"
    >
      ${ButtonPrimary({ text: linkText, href: inviteLink })}
    </div>

    ${SupportEmailLine()}

    <p>
      Team ${orgName}
    </p>

    ${Divider()}

    <p>
      "${linkText}" button not working?
    </p>

    <p>
      Copy and paste this link in your browser:
    </p>

    <p>
      ${inviteLink}
    </p>
  `;
};

export default inviteUserCreateJobProfilesHtml;
