import React from 'react';
import { FaCog, FaEllipsisH } from 'react-icons/fa';

const EditCell = ({ row, onClickEdit }) => {
  // Skill
  const id = row?.original?.id || '';
  const name = row?.original?.name || '';

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white">
            <h6 className="m-0 text-nowrap">
              {name}
            </h6>
          </div>

          <button
            className="dropdown-item py-2"
            type="button"
            title={`Edit ${name}`}
            onClick={() => {
              onClickEdit(id);
            }}
          >
            <span className='d-flex align-items-center'>
              <FaCog />
              <span className='ml-2'>
                Settings
              </span>
            </span>
          </button>

        </div>
      </div>
    </div>
  );
};

export default EditCell;
