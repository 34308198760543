/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetSideSheet } from '../../actions/SideSheets';
import UserDetails from '../lists/UsersList/UserDetails';
import './style.css';
import {
  SHEET_KEY_CHOOSE_RESOURCE_FOLDER,
  SHEET_KEY_DAILY_RECORDS,
  SHEET_KEY_IMPORT_MATERIAL,
  SHEET_KEY_IMPORT_RESOURCE_MATERIAL,
  SHEET_KEY_MANAGE_USER_COMPETENCIES,
  SHEET_KEY_MATERIAL,
  SHEET_KEY_MATERIAL_ADD,
  SHEET_KEY_MATERIAL_TEMPLATE,
  SHEET_KEY_PROJECT,
  SHEET_KEY_RESUME_SCREENER,
  SHEET_KEY_USER_DETAILS
} from '../../constants/sideSheets';
import DailyRecords from '../OrgConsole/WorkforceSuite/Apprenticeship/Dashboard/DailyRecords';
import ProjectSideSheet from './ProjectSideSheet';
import MaterialSideSheet from './MaterialSideSheet';
import MaterialTemplateSideSheet from './MaterialTemplateSideSheet';
import MaterialAddSideSheet from './MaterialAddSideSheet';
import ImportMaterialSideSheet from './ImportMaterialSideSheet';
import ImportResourceMaterialSideSheet from './ImportResourceMaterialSideSheet';
import ResumeScreenerSideSheet from './ResumeScreenerSideSheet';
import ChooseResourceFolderSideSheet from './ChooseResourceFolderSideSheet';
import ManageUserCompetenciesSideSheet from './ManageUserCompetenciesSideSheet';

const SideSheets = () => {
  const dispatch = useDispatch();
  const sideSheetContainer = useRef();
  const sideSheetRef = useRef();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const sideSheet = useSelector((state) => state.sideSheet);

  const animateLeft = () => {
    if (sideSheetRef.current) {
      sideSheetRef.current.classList.add('animateLeft');
    }
  };

  const animateRight = () => {
    if (sideSheetRef.current) {
      sideSheetRef.current.classList.add('animateRight');
    }
  };

  const handleCloseSideSheet = () => {
    setTimeout(animateRight, 50);
    setTimeout(() => {
      dispatch(resetSideSheet());
      if (sideSheet?.callbackPrimaryAction) {
        sideSheet?.callbackPrimaryAction();
      }
    }, 250);
  };

  useEffect(() => {
    if (sideSheetContainer?.current) {
      if (sideSheet.key && sideSheet?.visible) {
        sideSheetContainer.current.style.opacity = 100;
      } else {
        sideSheetContainer.current.style.opacity = 0;
      }
    }

    if (sideSheetRef?.current) {
      if (sideSheet.key && sideSheet?.visible) {
        setTimeout(animateLeft, 50);
      } else {
        setTimeout(animateRight, 50);
      }
    }
  }, [sideSheet?.key, sideSheet?.visible, sideSheetContainer?.current]);

  if (!currentUser.id || !sideSheet.key) {
    return;
  }

  return (
    <>
      <div
        ref={sideSheetContainer}
        className="side-sheet-container"
        onClick={handleCloseSideSheet}
      />
      <div
        ref={sideSheetRef}
        className={`side-sheet ${sideSheet?.className || ''}`}
      >
        {sideSheet.key === SHEET_KEY_USER_DETAILS && (
          <UserDetails
            handleClose={handleCloseSideSheet}
            showNameBlock
            className="h-100 w-100"
          />
        )}

        {sideSheet.key === SHEET_KEY_DAILY_RECORDS && (
          <DailyRecords className="w-100 h-100" />
        )}

        {sideSheet.key === SHEET_KEY_PROJECT && (
          <ProjectSideSheet
            className="w-100 h-100"
          />
        )}

        {sideSheet.key === SHEET_KEY_MATERIAL && (
          <MaterialSideSheet
            className="w-100 h-100"
          />
        )}

        {sideSheet.key === SHEET_KEY_MATERIAL_ADD && (
          <MaterialAddSideSheet
            className="w-100 h-100"
          />
        )}

        {sideSheet.key === SHEET_KEY_MANAGE_USER_COMPETENCIES && (
          <ManageUserCompetenciesSideSheet
            className="w-100 h-100"
          />
        )}

        {sideSheet.key === SHEET_KEY_CHOOSE_RESOURCE_FOLDER && (
          <ChooseResourceFolderSideSheet
            className="w-100 h-100"
          />
        )}

        {sideSheet.key === SHEET_KEY_MATERIAL_TEMPLATE && (
          <MaterialTemplateSideSheet
            className="w-100 h-100"
          />
        )}

        {sideSheet.key === SHEET_KEY_IMPORT_MATERIAL && (
          <ImportMaterialSideSheet />
        )}

        {sideSheet.key === SHEET_KEY_IMPORT_RESOURCE_MATERIAL && (
          <ImportResourceMaterialSideSheet />
        )}

        {sideSheet.key === SHEET_KEY_RESUME_SCREENER && (
          <ResumeScreenerSideSheet />
        )}
      </div>
    </>
  );
};

export default SideSheets;
