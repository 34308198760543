import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { deleteEntry } from '../../../services/entry';
import { canDeleteEntry } from '../../../services/currentUser';
import { removeTopicFromClass } from '../../../services/courses';
import { setCurrentClass } from '../../../actions/Class';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';
import { MANAGE_CURRENT_ENTRY } from '../../../constants/routes';

const DeleteTopic = ({
  id,
  title,
  contentType = 'topic',
  buttonText = 'Delete Topic',
  sections
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const currentTopic = useSelector((state) => state.currentTopic);
  // Course
  const classId = course?.id || null;
  const prerequisiteTopics = currentTopic?.prerequisiteTopics || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [id]);

  const showDeleteButton = useMemo(() => {
    if (!canDeleteEntry(role)) {
      return false;
    }

    if (sections) {
      return false;
    }

    if (prerequisiteTopics !== null) {
      return false;
    }

    if (currentTopic.cdnFiles && currentTopic.cdnFiles.length !== 0) {
      return false;
    }

    return true;
  }, [currentTopic.cdnFiles, prerequisiteTopics, role, sections]);

  const handleDelete = () => {
    setLoading(true);
    removeTopicFromClass({ topicId: id, classId }).then(() => {
      deleteEntry(id).then(() => {
        // Update Class
        toast.success(`${title} deleted!`);

        dispatch(setCurrentClass({ classId })).then(() => {
          // Redirect user to course content
          history.replace(`${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=class&classId=${classId}`);
        });
      }).catch((error) => {
        console.error('deleteEntry Topic', error);
      });
    }).catch((error) => {
      console.error('removeTopicFromClass', error);
    });
  };

  return (
    <DeleteContent
      id={id}
      title={title}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className="mt-2 mb-3"
    >
      <ol className="m-0 px-4">
        <li className="mb-2">
          Delete all of this Topic's Materials, Challenges and Quizzes. <TaskStatus statusType="content" complete={!sections} />
        </li>
        <li className="mb-2">
          Delete all of this Topic's Files <TaskStatus statusType="files" complete={!currentTopic.cdnFiles || currentTopic.cdnFiles.length === 0} />
        </li>
        {prerequisiteTopics && Array.isArray(prerequisiteTopics) && prerequisiteTopics.length ? (
          <li>
            Remove this Topic as the prerequisite from these Topics:
            <ul className="mt-2">
              {prerequisiteTopics.map((topic, topicIndex) => (
                <li
                  className="font-weight-bold"
                  key={topicIndex}
                >
                  {topic.title}
                </li>
              ))}
            </ul>
          </li>
        ) : (
          <li>
            Ensure no other Topics list this Topic as a prerequisite. <TaskStatus statusType="prerequisite" complete={!prerequisiteTopics} />
          </li>
        )}
      </ol>
    </DeleteContent>
  );
};

export default DeleteTopic;
