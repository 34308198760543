import React, { useEffect } from 'react';
import { withAuthorization } from '../../Session';
// import { ORG_CONSOLE_DASHBOARD } from '../../../constants/routes';
import Dashboard from './Dashboard';
import './Dashboard/style.css';

const WorkforceSuite = () => {
  useEffect(() => {
    document.body.classList.add('bkg-pattern-light');

    return function cleanup() {
      document.body.classList.remove('bkg-pattern-light');
    };
  }, []);

  return (
    <div className="pb-4">
      <Dashboard />
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(WorkforceSuite);
