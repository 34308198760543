import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import {
  ICON_ELLIPSIS,
  ICON_SEND,
  ICON_USER_LOCK
} from '@apprentage/components/dist/constants/icons';
import { INVITE_TYPE_REGISTRATION } from '@apprentage/constants';
import { toast } from 'react-toastify';
import { canManageUsers } from '../../../services/currentUser';
import { sendEmailUserInviteHtml } from '../../../services/mailer';
import {
  deleteInvite,
  generateInviteLink,
  inviteStatus,
  updateInvite
} from '../../../services/invites';
import { getInvites } from '../../../actions/Invites';
import KeyValueItem from '../../KeyValueItem';
import { canAccessAllLocations } from '../../../permissions/access';
import { canManageProgramUsers } from '../../../permissions/manage';

const ActionsCell = ({ row }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const course = useSelector((state) => state.course);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Current User
  const role = pathOr([], ['role'], currentUser);
  const currentUserName = pathOr('', ['name'], currentUser);
  const manageUsers = canManageProgramUsers(role) || canManageUsers(role);
  // Organization
  const orgName = pathOr(null, ['name'], organization);
  const orgEmail = pathOr(null, ['email'], organization);
  const orgLogo = pathOr(null, ['orgLogo'], organization);
  // Row
  const employerProfileId = pathOr('', ['original', 'employerProfileId'], row);
  const apprenticeshipId = pathOr('', ['original', 'apprenticeshipId'], row);
  const classId = pathOr('', ['original', 'classId'], row);
  const inviteId = pathOr('', ['original', 'id'], row);
  const inviteType = pathOr('', ['original', 'inviteType'], row);
  const accessCode = pathOr('', ['original', 'accessCode'], row);
  const firstName = pathOr('', ['original', 'firstName'], row);
  const lastName = pathOr('', ['original', 'lastName'], row);
  const email = pathOr('', ['original', 'email'], row);
  const sentAt = pathOr('', ['original', 'sentAt'], row);
  const viewedAt = pathOr('', ['original', 'viewedAt'], row);
  const locationId = pathOr('', ['original', 'locationId'], row);
  const locationName = pathOr('Employer', ['name'], locations.hash[locationId]);
  // Misc
  const apprenticeshipInvitation = Boolean(apprenticeshipId);
  const apprenticeshipTitle = pathOr('', ['title'], apprenticeship);
  const courseInvitation = Boolean(classId);
  const courseTitle = pathOr('', ['title'], course);

  const {
    text: statusText,
    className,
    dataTip
  } = inviteStatus({ viewedAt, sentAt });

  const getInvitesConfig = {
    // All Locations or the location the currentUser has access to
    ...(canAccessAllLocations(role) ? {} : { locationId }),
    // Apprenticeship
    ...(apprenticeshipId ? { apprenticeshipId } : {}),
    // Employer Profile
    ...(employerProfileId ? { employerProfileId } : {}),
    // Registration
    ...(inviteType === INVITE_TYPE_REGISTRATION
      ? { inviteType: INVITE_TYPE_REGISTRATION }
      : {})
  };

  const sendReminder = () => {
    const inviteLink = generateInviteLink(inviteId);

    const apprenticeshipConfigs = apprenticeshipInvitation
      ? {
        apprenticeshipInvitation,
        apprenticeshipTitle,
        fullName: `${firstName} ${lastName}`
      }
      : {};

    const courseConfigs = courseInvitation
      ? { courseInvitation, courseTitle }
      : {};

    toast.info('Processing...');

    updateInvite(
      {
        sentAt: new Date().toISOString()
      },
      inviteId
    ).then(() => {
      sendEmailUserInviteHtml({
        orgName,
        orgEmail,
        orgLogo,
        locationName,
        currentUserName,
        inviteLink,
        inviteType,
        emails: [email],
        accessCode,
        ...apprenticeshipConfigs,
        ...courseConfigs
      })
        .then(() => {
          dispatch(getInvites(getInvitesConfig)).then(() => {
            toast.success('Reminder sent!');
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  const revokeInvite = () => {
    toast.info('Processing...');
    deleteInvite(inviteId)
      .then(() => {
        dispatch(getInvites(getInvitesConfig)).then(() => {
          toast.success('Invite revoked!');
        });
      })
      .catch((error) => {
        console.error(error);
        toast.error('Something went wrong, try again.');
      });
  };

  return (
    <div className="btn-group d-flex align-items-center justify-content-end">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label='Manage Menu'
        title="Manage Menu"
      >
        <i className={ICON_ELLIPSIS} />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div
          className="bg-dark px-4 py-2 text-white text-nowrap"
          data-demo="disabled"
        >
          {firstName} {lastName}
        </div>

        {manageUsers && accessCode && (
          <button
            type="button"
            className="dropdown-item border-bottom  py-2"
            onClick={sendReminder}
          >
            <i className={`${ICON_SEND} mr-1`} /> Send Reminder
          </button>
        )}

        {manageUsers && accessCode && (
          <button
            type="button"
            className="dropdown-item border-bottom  py-2"
            onClick={revokeInvite}
            disabled={!accessCode}
          >
            <i
              className={`${ICON_USER_LOCK} ${accessCode ? 'text-danger' : ''}`}
            />{' '}
            Revoke Invite
          </button>
        )}

        <div className="bg-light px-2 py-3 small">
          <div
            className="bg-light px-2"
            data-demo="disabled"
          >
            <div className="mb-2">
              <div>
                <strong>Status:</strong>
                <span className={`${className} ml-2 px-1`}>{statusText}</span>
              </div>
              {dataTip}
            </div>

            <KeyValueItem
              className="text-nowrap mb-0"
              title="EMAIL"
              value={email}
            />

            <KeyValueItem
              className="text-nowrap mb-1"
              title="ID"
              value={inviteId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsCell;
