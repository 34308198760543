import { API_IPAPI, API_IPAPI_GEO } from '../constants/api';
import { isLocalhost } from '../serviceWorker';
import * as twApi from './tw-api';

/**
 * Gets Occupations by keyword
 * @param {string} ip - optional ip address
 * @returns {Promise<string>} - A URL that allows a company to connect their account to Turbine LMS Stripe Acc.
 */
export async function getGeoLocation(ip) {
  let config = ip ? { ip } : undefined;

  if (isLocalhost) {
    config = { ip: '71.116.62.57' };
  }
  const response = await twApi.get(API_IPAPI, config);

  return response;
}

export async function getCoordinates(address = '') {
  const response = await twApi.get(API_IPAPI_GEO, { address });

  return response;
}
