import Divider from '../Divider';
import OrgLogo from '../OrgLogo';
import PoweredByTurbineLink from '../PoweredByTurbineLink';
import PoweredByTurbineLogo from '../PoweredByTurbineLogo';

const baseTemplate = ({
  orgName,
  orgLogo,
  title,
  bodyContent,
  trackingSlug
}) => `
  <!DOCTYPE html>
    <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
      <head>
        <title></title>
        <meta charset="utf-8"/>
        <meta content="width=device-width, initial-scale=1.0" name="viewport"/>
        <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
        <style>
          * {
            box-sizing: border-box;
          }

          th.column {
            padding: 0
          }

          a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: inherit !important;
          }

          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
          }

          p {
            line-height: inherit
          }

          @media (max-width:520px) {
            .icons-inner {
              text-align: center;
            }

            .icons-inner td {
              margin: 0 auto;
            }

            .fullMobileWidth,
            .row-content {
              width: 100% !important;
            }

            .image_block img.big {
              width: auto !important;
            }

            .stack .column {
              width: 100%;
              display: block;
            }
          }
        </style>
      </head>

      <body
        style="margin: 0; background-color: #000; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;"
      >

      <table
        border="0"
        cellpadding="0"
        cellspacing="0"
        class="nl-container"
        role="presentation"
        style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #000000; background-image: url('https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png'); background-repeat: repeat;padding-top:25px;"
        width="100%"
      >
        <tbody>
          <tr>
            <td>

              <!-- MAIN 1 -->

              <table
                align="center"
                border="0"
                cellpadding="0"
                cellspacing="0"
                class="row row-9"
                role="presentation"
                width="100%"
              >
                <tbody>
                  <tr>
                    <td>

                      <!-- MAIN 2 -->
                      <table
                        align="center"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        class="row-content stack"
                        role="presentation"
                        style="mso-table-lspace: 0; mso-table-rspace: 0; background-color: #fff;"
                        width="500"
                      >
                        <tbody>
                          <tr>
                            <th
                              class="column"
                              style="mso-table-lspace: 0; mso-table-rspace: 0; font-weight: 400; text-align: left; vertical-align: top; border-bottom: 1px dashed #000000; border-left: 0px solid #000000; border-right: 0px solid #000000; border-top: 1px dashed #000000; padding-left: 10px; padding-right: 10px;"
                              width="100%"
                            >

                              ${OrgLogo({ orgName, orgLogo })}

                              <!-- BODY -->
                              <table
                                border="0"
                                cellpadding="10"
                                cellspacing="0"
                                class="text_block"
                                role="presentation"
                                style="mso-table-lspace: 0; mso-table-rspace: 0; word-break: break-word;"
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td>
                                      <div
                                        style="font-family: sans-serif"
                                      >
                                        <h1
                                          style="margin: 0; font-size: 24px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; direction: ltr; font-weight: normal; letter-spacing: normal; margin-top: 0; margin-bottom: 0;"
                                        >
                                          <strong>${title}</strong>
                                        </h1>
                                        <div
                                          style="font-size: 15px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; color: #555555; line-height: 1.2;"
                                        >
                                          ${bodyContent}

                                          ${Divider()}

                                          ${PoweredByTurbineLink({ trackingSlug })}

                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <!-- /BODY 3 -->

                            </th>
                          </tr>
                        </tbody>
                      </table>
                      <!-- /MAIN 2 -->

                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- /MAIN 1 -->

              ${PoweredByTurbineLogo({ trackingSlug })}

            </td>
          </tr>
        </tbody>
      </table>

    </body>
  </html>
`;

export default baseTemplate;
