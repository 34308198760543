import React from 'react';
import { ICON_CHECK_CIRCLE, ICON_TRIANGLE_BANG } from '@apprentage/components/dist/constants/icons';

const TaskStatus = ({ complete, statusType }) => {
  if (complete) {
    return (
      <i
        data-cy={`TaskStatus-${statusType}-success`}
        className={`${ICON_CHECK_CIRCLE} text-keppel`}
      />
    );
  }

  return (
    <i
      data-cy={`TaskStatus-${statusType}-error`}
      className={`${ICON_TRIANGLE_BANG} text-danger`}
    />
  );
};

export default TaskStatus;
