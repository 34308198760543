import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AsyncSelectMenu from 'react-select/async';
import { FaAtom, FaPencilAlt } from 'react-icons/fa';
import {
  canManageSkills, reactSelectDefaultValues, reactSelectOptionsFromArray
} from '@apprentage/utils';
import { fetchSkills } from '../../../services/skills';
import { ORG_CONSOLE_SKILLS } from '../../../constants/routes';

const SkillsSelectMenu = ({
  title = 'Skills',
  subtitle = '',
  showManageLink = false,
  manageLink = ORG_CONSOLE_SKILLS,
  showHelpText = false,
  className = '',
  ids = null,
  onChange
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Current User
  const role = currentUser?.role || [];
  const [skillsList, setSkillsList] = useState(null);

  const options = useMemo(() => {
    if (Array.isArray(skillsList) && skillsList.length > 0) {
      return reactSelectOptionsFromArray(skillsList, 'name');
    }

    return [];
  }, [skillsList]);

  const [selectedSkills, setSelectedSkills] = useState();
  const [defaultSelectedSkills, setDefaultSelectedSkills] = useState();

  useEffect(() => {
    if (orgId) {
      fetchSkills({ orgId, limit: 1000 }).then((response) => {
        setSkillsList(response.items);
      });
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    setSelectedSkills(defaultSelectedSkills);
  }, [defaultSelectedSkills]);

  const loadOptions = useCallback((searchKey) => {
    return new Promise((resolve) => {
      if (!defaultSelectedSkills && Array.isArray(ids) && ids.length > 0) {
        fetchSkills({
          orgId,
          ids,
          limit: ids.length
        }).then((response) => {
          const currOptions = reactSelectOptionsFromArray(response?.items, 'name');
          const currValues = reactSelectDefaultValues(ids, currOptions);

          setDefaultSelectedSkills(currValues);
        });
      }

      if (searchKey) {
        fetchSkills({
          orgId,
          name: searchKey,
          limit: 1000
        }).then((response) => {
          const searchOptions = reactSelectOptionsFromArray(response?.items, 'name');
          resolve(searchOptions);
        });
        return;
      }

      resolve(options);
    });
  }, [defaultSelectedSkills, options, orgId, ids]);

  const handleOnChange = (option) => {
    setSelectedSkills(option);
    if (onChange) {
      onChange(option);
    }
  };

  if (!skillsList) {
    return null;
  }

  return (
    <div className={`SkillsSelectMenu position-relative ${className}`}>
      <div className="mb-2 h6">
        <span className="d-flex align-items-center">
          <FaAtom />
          <span className="ml-1 font-weight-bold">{title}</span>
        </span>
      </div>

      {subtitle && <div>{subtitle}</div>}

      <AsyncSelectMenu
        isMulti
        className="focus-none-box-shadow"
        loadOptions={loadOptions}
        defaultOptions
        value={selectedSkills}
        placeholder="Search & Select Skills..."
        onChange={handleOnChange}
      />

      {(showHelpText || showManageLink) && (
        <div className='mt-1 small d-flex align-items-center'>
          {showHelpText && (
            <span>
              Domain-general and domain-specific skills.
            </span>
          )}
          {manageLink && showManageLink && canManageSkills(role, orgType) && (
            <Link
              className="btn-link ml-2"
              to={manageLink}
            >
              <span className='d-flex align-items-center'>
                <FaPencilAlt size={10} />
                <span className='ml-1'>
                  Manage Skills
                </span>
              </span>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default SkillsSelectMenu;
