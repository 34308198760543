import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { ICON_CHECK_CIRCLE } from '@apprentage/components/dist/constants/icons';
import { updateAgreement, getAgreement } from '../../../actions/Agreements';
import { resetCurrentModal } from '../../../actions/Modals';
import { dateTimestamp, dayMdyDate } from '../../../utils/date';
import { routeWithOrgId } from '../../../services/organizations';
import { BILLING } from '../../../constants/routes';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import Modal from '../../Modal';
import './style.css';

class BillingAgreement extends Component {
  state = {
    key: 'billingAgreement',
    loading: false
  };

  handleClose = () => {
    this.props.resetCurrentModal();
  };

  handleError = () => {
    toast.error('Something went wrong, refresh and try again.');
    this.setState({ loading: false });
  };

  handleBillingAccept = ({ id, title }) => {
    const { currentUser, location, organization } = this.props;
    const orgId = pathOr(null, ['id'], organization);
    const name = pathOr(null, ['name'], currentUser);
    const email = pathOr(null, ['email'], currentUser);
    const billingRoute = routeWithOrgId({
      route: BILLING,
      urlParams: { orgId }
    });

    this.setState({ loading: true });

    toast.info('Processing acceptance...');

    this.props
      .updateAgreement({
        id,
        accepted: true,
        acceptedDate: dateTimestamp(),
        acceptedName: name,
        acceptedEmail: email
      })
      .then(() => {
        toast.success(`You accepted the ${title}`);

        if (location.search.includes('agreement=decline')) {
          // Rm the "?agreement=decline"
          this.props.history.replace(billingRoute);
        }

        this.handleClose();
      })
      .catch(() => {
        this.handleError();
      });
  };

  handleDecline = () => {
    const { location, organization } = this.props;
    const orgId = pathOr(null, ['id'], organization);
    const billingRoute = routeWithOrgId({
      route: BILLING,
      urlParams: {
        orgId,
        agreement: 'decline'
      }
    });

    if (location.pathname === BILLING) {
      this.handleClose();
    } else {
      this.props.history.replace(billingRoute);
    }
  };

  render() {
    const { currentModal, billingAgreement } = this.props;

    if (currentModal.key && !currentModal.visible) {
      return null;
    }

    const { key, loading } = this.state;
    if (currentModal.key !== key) return null;

    if (!billingAgreement) return null;

    const {
      id,
      title,
      body,
      accepted,
      acceptedDate,
      acceptedName,
      acceptedEmail,
      sys: { updatedAt }
    } = billingAgreement;

    return (
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
        visible={currentModal.key === key}
        close={accepted ? this.handleClose : false}
      >
        <div className="card">
          <div className="card-header">
            <h5 className="m-0 font-weight-bold">{title}</h5>
          </div>

          {accepted && acceptedDate && acceptedName && acceptedEmail && (
            <div className="alert alert-secondary m-0">
              <i className={`${ICON_CHECK_CIRCLE} text-keppel`} /> Accepted on{' '}
              {acceptedDate} by {acceptedName} ({acceptedEmail}).
            </div>
          )}

          {body && (
            <div className="card-body">
              <div className="text-muted text-right mb-2">
                Last updated: {dayMdyDate(updatedAt)}
              </div>
              <RenderMarkdown source={body} />

              {!accepted && (
                <p className="m-0">
                  Clicking <b>"I Accept"</b> indicates acceptance of all the
                  above terms as of {dayMdyDate(new Date())} (the "Effective
                  Date").
                </p>
              )}
            </div>
          )}

          {!accepted && (
            <div className="card-footer">
              <button
                className="btn btn-md btn-primary"
                disabled={loading}
                onClick={() => {
                  this.handleBillingAccept({ id, title });
                }}
                type="button"
              >
                I Accept
              </button>
              <button
                className="btn btn-link"
                disabled={loading}
                onClick={this.handleDecline}
                type="button"
              >
                Decline
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  organization,
  currentUser,
  billingAgreement,
  currentModal
}) => ({
  organization,
  currentUser,
  billingAgreement,
  currentModal
});

export default compose(
  withRouter,
  connect(mapStateToProps, { updateAgreement, getAgreement, resetCurrentModal })
)(BillingAgreement);
