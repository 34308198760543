import React from 'react';
import { CLASS_COLOR_DEFAULT } from '../constants/globals';

const CourseImageColor = ({
  title,
  classColor,
  classImage,
  className = '',
  height = 40,
  width = 40
}) => {
  if (classImage) {
    return (
      <img
        className={className}
        src={classImage}
        alt={title}
        height={height}
        width={width}
        style={{
          height: `${height}px`,
          width: `${width}px`
        }}
      />
    );
  }

  return (
    <div
      className={className}
      style={{
        backgroundColor: classColor || CLASS_COLOR_DEFAULT,
        height: `${height}px`,
        width: `${width}px`
      }}
    />
  );
};

export default CourseImageColor;
