import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import React, { useMemo } from 'react';
import { isActiveMembership } from '../../services/currentUser';

const NameBlock = ({
  name = '',
  nameSize = 'lg',
  role = '', // array
  membership = '', // array
  testUser = '', // boolean
  title = '',
  profilePicture = '',
  pictureHeight = '80',
  pictureWidth = '80',
  className = '',
  onClick
}) => {
  const nameSizeClassName = useMemo(() => {
    if (nameSize === 'sm') {
      return 'h6';
    }

    if (nameSize === 'md') {
      return 'h5';
    }

    return 'h4';
  }, [nameSize]);

  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <img
          data-demo="disabled"
          className="bd-placeholder-img rounded-circle mr-2"
          src={profilePicture || DEFAULT_PROFILE_PIC}
          alt={name}
          height={pictureHeight}
          width={pictureWidth}
          style={{
            height: `${pictureHeight}px`,
            width: `${pictureWidth}px`
          }}
        />
        <div>
          <div
            className={`${nameSizeClassName} m-0`}
            data-demo="disabled"
          >
            {onClick ? (
              <button
                className="btn-link"
                onClick={onClick}
                type="button"
              >
                {name}
              </button>
            ) : (
              name
            )}
          </div>

          {title && (
            <div
              className="text-muted"
              data-demo="disabled"
            >
              {title}
            </div>
          )}

          <div className='d-flex'>
            {role && !title && (
              <div
                className="text-muted mr-2"
                data-demo="disabled"
              >
                {role}
              </div>
            )}
            {testUser && (
              <div>
                <div className="badge badge-warning">Test Account</div>
              </div>
            )}
            {membership && !isActiveMembership(membership) && (
              <div>
                <div className="badge badge-danger">Inactive</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameBlock;
