import React, { useMemo } from 'react';
import { coerceToResourceName } from '../../../services/resources';
import Collapse from '../../ManageContent/Collapse';

const ActualFilename = ({
  filename
}) => {
  const actualFilename = useMemo(() => {
    return coerceToResourceName({
      str: filename,
      fixCamelCase: true,
      fixHyphens: true,
      fixUnderScores: true,
      fixPlus: true
    });
  }, [filename]);
  return (
    <Collapse
      id="filename-restrictions"
      title={actualFilename || 'Filename Guidelines'}
      className='bg-light mt-2'
      badge={() => {
        return (
          <>
            {actualFilename ? (
              <span className={`badge badge-${filename !== actualFilename ? 'warning' : 'success'} ml-2`}>
                {filename !== actualFilename ? 'Safe' : ''} Filename
              </span>
            ) : null}
          </>
        );
      }}
    >
      <h6>
        Naming Conventions
      </h6>
      <ul>
        <li>
          For better search results, use spaces in filenames.
        </li>
        <li>
          Underscores are converted to spaces.
        </li>
        <li>
          Including an extension (ex: .pdf, .jpg) is not required.
        </li>
        <li>
          Hyphens connecting words <code>Hello-World</code> convert to <code>Hello World</code>.
        </li>
        {/* <li>
          Hyphens in dates (2004-23-01 or 02-23-2008) will be preserved.
        </li> */}
        <li>
          Camel Casing <code>camelCase</code> converts to <code>camel Case</code>.
        </li>
      </ul>

      <h6>
        Restricted Characters
      </h6>
      <ul>
        <li>
          &lt; (less than)
        </li>
        <li>
          &gt; (greater than)
        </li>
        <li>
          : (colon)
        </li>
        <li>
          “ (double quote)
        </li>
        <li>
          / (forward slash)
        </li>
        <li>
          \ (backslash)
        </li>
        <li>
          | (vertical bar or pipe)
        </li>
        <li>
          ? (question mark)
        </li>
        <li>
          ! (exclamation point)
        </li>
        <li>
          ^ (caret)
        </li>
        <li>
          * (asterisk)
        </li>
      </ul>

      <h6>
        Restricted Words
      </h6>
      <div>
        CON, PRN, AUX, NUL, COM1, COM2, COM3, COM4, COM5, COM6, COM7, COM8, COM9, LPT1, LPT2, LPT3, LPT4, LPT5, LPT6, LPT7, LPT8, and LPT9
      </div>
    </Collapse>
  );
};

export default ActualFilename;
