import sysState from './sysState';

const locationState = {
  defaultLocation: false,
  enableNewEditor: false,
  locationImage: '',
  orgId: '',
  name: '',
  subtitle: '',
  managers: null, // reference [{}] // TODO refactor in lms & lms-admin, use managerIds
  managerIds: null, // string[]
  mapLink: '',
  phone: '',
  fax: '',
  integration: null, // {}
  ...sysState
};

export default locationState;
