import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getLocations } from '../../actions/Locations';
import WeatherSmall from './WeatherSmall';
import { ORG_CONSOLE_LOCATION_BASE } from '../../constants/routes';
import './style.css';

const LocationsList = ({
  className = '',
  // showHeader = false,
  excludeDefaultLocation = false,
  locationIds
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const dictionary = useSelector((state) => state.dictionary);
  const locations = useSelector((state) => state.locations);
  // Organization
  const orgId = organization?.id || null;
  // Locations
  const config = useMemo(() => ({
    orgId,
    excludeDefaultLocation,
    ...(locationIds ? { locationIds } : {})
  }), [excludeDefaultLocation, locationIds, orgId]);

  useEffect(() => {
    if (orgId && locationIds) {
      dispatch(getLocations(config));
    }
  }, [config, dispatch, locationIds, orgId]);

  if (!locations.list.length) {
    return (
      <div
        className='py-5 text-center'
      >
        No {dictionary?.locations}
      </div>
    );
  }

  return (
    <div
      className={`LocationsList d-flex flex-column ${className}`}
    >
      {locations.list && locations.list.map((location) => {
        return (
          <div
            key={location?.id}
            className='card-header bg-white border p-3 mb-3 rounded location-row-clickable position-relative'
            aria-hidden
            onClick={() => {
              history.push(`${ORG_CONSOLE_LOCATION_BASE}/${location?.id}`);
            }}
          >
            <div
              className='d-flex align-items-center justify-content-between' // flex-fill
            >
              <div
                className="m-0 d-flex align-items-center h5"
              >
                {location?.locationImage && (
                  <img
                    src={location?.locationImage}
                    className='mr-2'
                    alt="Logo"
                    height="30"
                    width="30"
                    style={{
                      height: '30px',
                      width: '30px'
                    }}
                  />
                )}

                {/* {!location?.locationImage && (
                  <i
                    className={`${dictionary?.locationIcon} mr-2`}
                    aria-hidden="true"
                  />
                )} */}

                <div className="ml-2 d-flex align-items-center">
                  <span>{location?.name}</span>
                  {/* {badge && badge()} */}
                </div>
              </div>

              <div className='d-flex align-items-center'>
                {location?.addressPostalCode && (
                  <div className='d-none d-sm-block'>
                    <WeatherSmall
                      postalCode={location?.addressPostalCode}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LocationsList;
