import React from 'react';
import { roleReadable } from '@apprentage/utils';
import { useSelector } from 'react-redux';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import NameBlock from '../../ManageContent/NameBlock';

const NameCell = ({
  value: name, row, showRole = false, handleClickRow
}) => {
  const organization = useSelector((state) => state.organization);

  return (
    <NameBlock
      className="text-left"
      name={name}
      {...(handleClickRow
        ? { onClick: () => { handleClickRow(row.original); } }
        : {})}
      {...(showRole
        ? { role: roleReadable(row?.original?.role, organization?.type) }
        : {})}
      membership={row?.original.membership}
      testUser={row?.original.testUser}
      nameSize="sm"
      pictureHeight={40}
      pictureWidth={40}
      profilePicture={row?.original?.profilePicture || DEFAULT_PROFILE_PIC}
    />
  );
};

export default NameCell;
