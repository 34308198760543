import {
  SET_PAYMENTS, // TODO Deprecate (legacy)
  SET_INVOICES,
  SET_PAYMENT_METHODS,
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTION,
  SET_PRODUCTS,
  SET_PRICE,
  SET_CUSTOMER
} from '../../actions/types';

const initialState = {
  customer: null,
  invoices: null, // [{}]
  paymentMethods: null, // [{}]
  products: null, // [{}]
  subscriptions: null, // [{}]
  subscription: null, // {}
  price: null, // {}
  payments: null // TODO Deprecate (legacy)
};

export default function billing(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.customer
      };
    case SET_INVOICES:
      return {
        ...state,
        invoices: action.invoices
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods
      };
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.subscriptions
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.subscription
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products
      };
    case SET_PRICE:
      return {
        ...state,
        price: action.price
      };
    // LEGACY
    case SET_PAYMENTS:
      return {
        ...state,
        payments: action.payments
      };
    default:
      return state;
  }
}
