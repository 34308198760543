import React from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { ICON_BRIEFCASE, ICON_SEND } from '@apprentage/constants';
import { setCurrentModal } from '../../../actions/Modals';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_WORKFORCE } from '../../../constants/routes';
import withOrgConsole from '../../App/withOrgConsole';
import JobProfilesList from '../../JobProfilesList';
import OrgConsoleHeader from '../OrgConsoleHeader';

const JobProfiles = () => {
  const dispatch = useDispatch();

  const manageJobProfile = () => {
    dispatch(setCurrentModal({
      key: 'manageJobProfile',
      data: {
        jobProfileId: null
      }
    }));
  };

  const inviteToCreateJobProfile = () => {
    dispatch(setCurrentModal({
      key: 'inviteUserCreateJobProfile'
    }));
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Job Profiles"
            icon={ICON_BRIEFCASE}
            route={ORG_CONSOLE_WORKFORCE}
          >
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={manageJobProfile}
              >
                Add Job Profile
              </button>
              <button
                type="button"
                className="btn btn-sm btn-primary dropdown-toggle dropdown-toggle-split border-left"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right p-0">
                <button
                  type="button"
                  className="dropdown-item py-2"
                  onClick={inviteToCreateJobProfile}
                >
                  <i className={`${ICON_SEND} mr-1`} /> Invite: Create Job Profile
                </button>
              </div>
            </div>
          </OrgConsoleHeader>

          <div
            className="bg-white border shadow rounded mt-3 p-3 overflow-content"
          >
            <JobProfilesList />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(JobProfiles);
