import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaTriangleExclamation } from 'react-icons/fa6';

const ApprovalCell = ({ value: isApproved }) => {
  if (isApproved) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
      >
        <div
          className='d-flex align-items-center'
        >
          <FaCheckCircle className='text-keppel' />
          <span className='ml-1'>
            Approved
          </span>
        </div>
      </div>
    );
  }

  if (!isApproved) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
      >
        <div
          className='d-flex align-items-center'
        >
          <FaTriangleExclamation className='text-warning' />
          <span className='ml-1'>
            Unapproved
          </span>
        </div>
      </div>
    );
  }

  return null;
};

export default ApprovalCell;
