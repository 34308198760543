import { NOTIFICATIONS_PAGINATION_LIMIT } from '../constants/api';
import { fetchNotifications } from '../services/notifications';
import {
  RESET_NOTIFICATIONS_COURSE,
  RESET_NOTIFICATIONS_ORG,
  SET_ENTRY_ORG_ID,
  SET_NOTIFICATIONS_COURSE,
  SET_NOTIFICATIONS_ORG,
  // SET_NOTIFICATION_FILES,
  RESET_NOTIFICATION_FILES,
  SET_NOTIFICATIONS_ORG_PINNED,
  SET_NOTIFICATIONS_COURSE_PINNED
} from './types';

export const resetNotificationsOrg = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_NOTIFICATIONS_ORG
    });
  };
};

export const resetNotificationsCourse = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_NOTIFICATIONS_COURSE
    });
  };
};

export const resetNotificationFiles = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_NOTIFICATION_FILES
    });
  };
};

export const getNotifications = ({
  limit = NOTIFICATIONS_PAGINATION_LIMIT,
  page = 1,
  order = '-createdAt',
  isDraft = null,
  isPinned = null,
  includePinned = false,
  orgId,
  locationId,
  classId = null
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        orgId,
        order,
        limit,
        page,
        classId,
        isDraft,
        isPinned
      };

      if (locationId) {
        config.locationId = locationId;
      }

      if (includePinned) {
        fetchNotifications({
          ...config,
          isPinned: true
        }).then((response) => {
          dispatch({
            type: classId ? SET_NOTIFICATIONS_COURSE_PINNED : SET_NOTIFICATIONS_ORG_PINNED,
            pinned: response?.items || []
          });
        });
      }

      fetchNotifications(config)
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: classId ? SET_NOTIFICATIONS_COURSE : SET_NOTIFICATIONS_ORG,
            list: response?.items || [],
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};
