import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Table from './Table';
import DateCell from './DateCell';
import NumberCell from '../../../ManageContent/Tables/NumberCell';
import TranscriptCell from './TranscriptCell';
import ActionsCell from './ActionsCell';
import ApprovalCell from './ApprovalCell';
import CompetenciesCell from './CompetenciesCell';
import { canReviewLogbookRecords } from '../../../../permissions/manage';
import IndeterminateCheckboxHeader from './IndeterminateCheckboxHeader';
import IndeterminateCheckboxCell from './IndeterminateCheckboxCell';

const List = ({
  data,
  handleRefreshRecords,
  onSelectionChange,
  theme
}) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);

  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!canReviewLogbookRecords(role)) {
      cols.push('actionsCol');
      cols.push('select');
    }

    return cols;
  }, [role]);

  const columns = React.useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: DateCell
    },
    {
      Header: 'Hours',
      accessor: 'hours',
      Cell: NumberCell
    },
    {
      Header: 'Transcript',
      accessor: 'transcript',
      disableSortBy: true,
      Cell: TranscriptCell
    },
    {
      Header: 'Competencies',
      accessor: 'competencyIds',
      disableSortBy: true,
      theme,
      Cell: CompetenciesCell
    },
    {
      Header: 'Status',
      accessor: 'isApproved',
      handleRefreshRecords,
      Cell: ApprovalCell
    },
    {
      Header: '',
      accessor: 'actionsCol',
      disableSortBy: true,
      handleRefreshRecords,
      Cell: ActionsCell
    }
  ], [handleRefreshRecords, theme]);

  const handleHooks = (hooks) => {
    if (!hiddenColumns.includes('select')) {
      hooks.visibleColumns.push((visibleColumns) => [
        {
          id: 'selection',
          Header: IndeterminateCheckboxHeader,
          Cell: IndeterminateCheckboxCell
        },
        ...visibleColumns
      ]);
    }
  };

  if (!data || (data && data.length === 0)) {
    return (
      <div
        className="ColoredBox rounded shadow position-relative p-3 mb-3 my-auto d-flex justify-content-center align-items-center"
      >
        <div
          className="BoxText m-auto text-center h-100"
          style={{
            fontSize: '1rem'
          }}
        >
          No records.
        </div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      handleHooks={handleHooks}
      onSelectionChange={onSelectionChange}
      data={data}
      theme={theme}
    />
  );
};

export default List;
