import React from 'react';
import Reference from '../Reference';
import { getClassId, getTopicId } from '../../../services/references';
import { flattenItems } from '../../../services/contentful';
import isPublished from '../../../utils/isPublished';
import './style.css';

const References = ({
  contents,
  classId,
  topicId,
  searchParams,
  addContent,
  editContent,
  addTypes
}) => {
  let publishedItems = contents.filter((item) => isPublished(item));

  publishedItems = flattenItems(publishedItems); // TODO course flatten, topic flatten

  if (publishedItems && publishedItems.length) {
    return (
      <div className="references references-manage rounded border p-1">
        {publishedItems.map((content, i) => (
          <Reference
            key={i}
            referenceIndex={i}
            content={content}
            classId={getClassId({ classId, content })}
            topicId={getTopicId({ topicId, content })}
            searchParams={searchParams}
            addContent={addContent}
            editContent={editContent}
            addTypes={addTypes}
          />
        ))}
      </div>
    );
  }

  return null;
};

export default References;
