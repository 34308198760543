import React from 'react';

const Color = ({
  handleClick,
  color,
  activeColor,
  className = '',
  tooltipContent,
  tooltipId
}) => {
  return (
    <button
      className={`ColorSwatches--color ${className}`}
      data-hex={color.hex}
      // data-tooltip-content={tooltipContent}
      // data-tooltip-id={tooltipId}
      data-tip={tooltipContent}
      data-for={tooltipId}
      style={{
        backgroundColor: color,
        cursor: handleClick ? 'pointer' : 'default'
      }}
      type="button"
      {...(handleClick ? { onClick: () => { handleClick(color); } } : {})}
    >
      {activeColor !== undefined && activeColor === color && (
        <i className="fas fa-check text-white" />
      )}
    </button>
  );
};

export default Color;
