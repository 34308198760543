import { pathOr } from 'ramda';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TbWorldSearch } from 'react-icons/tb';
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa';
import { setCurrentClass } from '../../../actions/Class';
import { publicCourseUrl, updateCourse } from '../../../services/courses';
import { canManageCoursePublishSettings } from '../../../services/currentUser';
import RadioGroup from '../../ManageContent/RadioGroup';
import Collapse from '../../ManageContent/Collapse';

const Form = () => {
  const dispatch = useDispatch();
  const publicCourseEmbedCodeRef = useRef();
  const publicCourseUrlRef = useRef();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const data = useSelector((state) => state.course);
  // Misc
  const role = pathOr([], ['role'], currentUser);
  const orgSlug = pathOr(null, ['slug'], organization);
  const classId = pathOr(null, ['id'], data);
  const classTitle = pathOr(null, ['title'], data);
  const courseSlug = pathOr(null, ['courseSlug'], data);
  const manageCoursePublishSettings = canManageCoursePublishSettings(role);
  // Local state
  const [isPublic, setIsPublic] = useState(data.isPublic || false);
  const [searchEnginesEnabled, setSearchEnginesEnabled] = useState(data.searchEnginesEnabled || false);

  const selectAll = (e) => {
    e.currentTarget.select();
  };

  const copyLink = (refElem) => {
    const { value } = refElem.current;

    refElem.current.focus();
    refElem.current.select();

    navigator.clipboard.writeText(value);

    toast.success('Link copied!');
  };

  const iframeHeight = () => {
    // TODO make dynamic
    return '500px';
  };

  const iframeWidth = () => {
    // TODO make dynamic
    return '100%';
  };

  const publicPathwayEmbedCode = () => {
    const src = publicCourseUrl({
      embed: true,
      courseSlug,
      classId,
      orgSlug
    });

    return `<iframe src="${src}" title="${classTitle}" height="${iframeHeight()}" width="${iframeWidth()}">Powered by Turbine Workforce</iframe>`;
  };

  const handleIsPublicChange = (e) => {
    const { value } = e.currentTarget;
    const dataToSave = {
      classId,
      isPublic: value === 'true',
      searchEnginesEnabled: value === 'true'
    };

    setIsPublic(dataToSave.isPublic);
    setSearchEnginesEnabled(dataToSave.searchEnginesEnabled);

    updateCourse(dataToSave).then(() => {
      toast.success(`Course Page is ${dataToSave.isPublic ? 'Public' : 'Private'}`);
      dispatch(setCurrentClass({ classId }));
    });
  };

  const handleSearchEnginesEnabledChange = (e) => {
    const { value } = e.currentTarget;
    const dataToSave = {
      classId,
      searchEnginesEnabled: value === 'true'
    };

    setSearchEnginesEnabled(dataToSave.searchEnginesEnabled);

    updateCourse(dataToSave).then(() => {
      toast.success(`Course Page is ${dataToSave.searchEnginesEnabled ? 'visible in' : 'hidden from'} search engines`);
      dispatch(setCurrentClass({ classId }));
    });
  };

  return (
    <div>
      <div className="card border-bottom my-3">
        <div className="card-header p-3 d-flex align-items-center justify-content-between">
          <h1 className="h5 m-0">
            <span className='d-flex align-items-center'>

              <TbWorldSearch />
              <span className='ml-2'>
                Publish
              </span>
            </span>
          </h1>

          {isPublic && (
            <a
              className="btn btn-sm btn-info"
              href={publicCourseUrl({
                embed: false,
                courseSlug,
                classId,
                orgSlug
              })}
              target="_blank"
              rel="noopener noreferrer"
              title="Preview"
            >
              <span className='d-flex align-items-center'>
                <span className='mr-2'>
                  Public Page
                </span>
                <FaExternalLinkAlt className='small' />
              </span>
            </a>
          )}
        </div>
        <div className="card-body">
          <div
            id="course-isPublic"
            className={`${manageCoursePublishSettings ? '' : 'opacity-50 pointer-events-none'} form-group mb-3`}
          >
            <label className="mb-1">
              <b>Course Page Status:</b>
            </label>
            <div className="text-muted medium mb-2">
              Make this course public to share a link or embed it in your website to streamline user enrollment.
            </div>
            <RadioGroup
              name="isPublic"
              value={isPublic}
              labelYes="Public"
              labelNo="Private"
              onChange={handleIsPublicChange}
            />
          </div>

          {isPublic && (
            <Collapse
              id="courseSearchEnginesEnabledCollapse"
              title="Search Engine Optimization"
              ariaExpanded={isPublic}
              className="mb-3"
            >
              <div
                id="course-searchEnginesEnabled"
                className={`${manageCoursePublishSettings ? '' : 'opacity-50 pointer-events-none'} form-group mb-3`}
              >
                <label className="mb-1">
                  <b>SEO: Search Engine Visibility</b>
                </label>
                <div className="text-muted medium mb-2">
                  Control whether or not this course page appears in search results on sites like Google.
                </div>
                <RadioGroup
                  name="searchEnginesEnabled"
                  value={searchEnginesEnabled}
                  labelYes="Visible"
                  labelNo="Hidden"
                  onChange={handleSearchEnginesEnabledChange}
                />
              </div>
            </Collapse>
          )}

          {isPublic && (
            <Collapse
              id="courseIsPublicCollapse"
              title="Course Page URL & Embed Code"
              ariaExpanded={isPublic}
            >
              <div className="border p-2 mb-3">
                <p>
                  Copy the <b>Course Page URL</b> and share via email, social media, etc.
                </p>
                <div className="mb-2">
                  Course Page URL:
                </div>
                <div className="input-group">
                  <input
                    readOnly
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    defaultValue={publicCourseUrl({
                      embed: false,
                      courseSlug,
                      classId,
                      orgSlug
                    })}
                    onClick={selectAll}
                    ref={publicCourseUrlRef}
                  />
                  <div className="input-group-append">
                    <button
                      title="Copy Link"
                      aria-label="Copy Link"
                      type='button'
                      className='btn btn-info'
                      onClick={() => {
                        copyLink(publicCourseUrlRef);
                      }}
                    >
                      <FaCopy />
                    </button>
                  </div>
                </div>
              </div>

              <div className="border p-2">
                <p>
                  Copy the <b>Course Embed Code</b> and include in the code of your website.
                </p>
                <div className="mb-2">
                  Course Embed Code:
                </div>
                <div className="input-group">
                  <textarea
                    readOnly
                    style={{ minHeight: '100px' }}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    value={publicPathwayEmbedCode()}
                    onClick={selectAll}
                    ref={publicCourseEmbedCodeRef}
                  />
                  <div className="input-group-append">
                    <button
                      title="Copy Link"
                      aria-label="Copy Link"
                      type='button'
                      className='btn btn-info'
                      onClick={() => {
                        copyLink(publicCourseEmbedCodeRef);
                      }}
                    >
                      <FaCopy />
                    </button>
                  </div>
                </div>
              </div>
            </Collapse>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;
