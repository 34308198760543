import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaBook, FaClock, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { goToElem } from '../../../services/manageContent';
import { createCompetency, deleteCompetency, updateCompetency } from '../../../services/competencies';
import DotMenu from './DotMenu';

const ManageUserCompetenciesSideSheet = ({ className = '' }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const sideSheet = useSelector((state) => state.sideSheet);
  // Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  const ojtHours = apprenticeship?.ojtHours || 0;
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user;
  // Side Sheet
  const userCompetenciesHash = useMemo(() => {
    return sideSheet?.data?.userCompetenciesHash || {};
  }, [sideSheet?.data?.userCompetenciesHash]);
  const competencies = sideSheet?.data?.competencies || null;
  const competencyGroups = sideSheet?.data?.competencyGroups || null;
  const competencyId = sideSheet?.data?.competencyId || null;
  const callbackPrimaryAction = sideSheet?.callbackPrimaryAction || null;

  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(false);

  const totalUserCompetenciesHours = useMemo(() => {
    let total = 0;

    if (Array.isArray(competencyGroups) && competencyGroups.length > 0) {
      competencyGroups.forEach((cg) => {
        if (Array.isArray(cg.competencyIds) && cg.competencyIds.length > 0) {
          cg.competencyIds.forEach((cId) => {
            if (formValues[cId]) {
              if (formValues[cId].totalHours === '') {
                // User competency added in the past, user removed the value
                // OR user added a value, then removed it
                // Need to check if competency exists in the DB
                total += parseInt(competencies[cId]?.totalHours, 10);
              } else {
                total += parseInt(formValues[cId].totalHours, 10);
              }
            } else if (userCompetenciesHash[cId]) {
              // User competency added in past and no current changes to these hours
              total += parseInt(userCompetenciesHash[cId].totalHours, 10) || 0;
            } else {
              total += parseInt(competencies[cId]?.totalHours, 10) || 0;
            }
          });
        }
      });
    }

    return total;
  }, [competencies, competencyGroups, formValues, userCompetenciesHash]);

  const totalHours = useMemo(() => {
    let total = 0;

    if (Array.isArray(competencyGroups) && competencyGroups.length > 0) {
      competencyGroups.forEach((cg) => {
        if (Array.isArray(cg.competencyIds)) {
          cg.competencyIds.forEach((cId) => {
            total += parseInt(competencies[cId]?.totalHours, 10);
          });
        }
      });
    }

    return total;
  }, [competencies, competencyGroups]);

  const hoursMatch = useMemo(() => {
    return totalUserCompetenciesHours === ojtHours && ojtHours > 0;
  }, [ojtHours, totalUserCompetenciesHours]);

  const onChange = (e) => {
    const { value, name, dataset } = e.currentTarget;

    setFormValues((prev) => ({
      ...prev,
      [name]: { totalHours: value, groupId: dataset?.group }
    }));
  };

  const onClick = () => {
    const formCompetencies = Object.entries(formValues).map(([key, value]) => ({
      id: userCompetenciesHash[key] ? userCompetenciesHash[key]?.id : null,
      compId: key,
      userId: apprenticeshipUser?.id,
      totalHours: value.totalHours > 0 ? parseInt(value.totalHours, 10) : value.totalHours,
      groupId: value.groupId,
      apprenticeshipId,
      orgId
    }));
    // console.log('formValues', formValues);
    // console.log('userCompetenciesHash', userCompetenciesHash);
    // console.log('formCompetencies', formCompetencies);

    setLoading(true);
    toast.info('Saving competencies...', { autoClose: false, toastId: 'savingUserCompetencies' });

    const promises = formCompetencies.map(async (competency) => {
      const { id, ...dataToSave } = competency;
      if (id) {
        if (competency.totalHours === '') {
          return await deleteCompetency(id);
        }
        return await updateCompetency(dataToSave, id);
      }

      return await createCompetency(dataToSave);
    });

    Promise.all(promises).then(async (responses) => {
      toast.dismiss('savingUserCompetencies');
      toast.success('Competencies saved!');
      if (callbackPrimaryAction) {
        callbackPrimaryAction(responses);
      }
    });
  };

  useEffect(() => {
    if (competencyId && document.getElementById(competencyId)) {
      goToElem(competencyId);
      document.getElementById(competencyId).focus();
    }
  }, [competencyId]);

  return (
    <div className={`card ${className}`}>

      <div className='card-header d-flex align-items-center justify-content-between'>
        {!hoursMatch && (
          <div
            className='alert alert-danger d-flex align-items-center position-absolute w-100 py-3'
            style={{
              top: 0,
              left: 0
            }}
          >
            <FaClock />
            <div className='ml-2'>
              Competency Hours (<strong>{totalUserCompetenciesHours}</strong>) don't match OJT Hours (<strong>{ojtHours}</strong>)
            </div>
          </div>
        )}

        <div
          className="h6 m-0 font-weight-bold"
          style={{
            width: '72%'
          }}
        >
          <span
            className="d-flex align-items-center"
          >
            <FaBook size={20} className='d-none d-sm-block mr-2' />
            <span
              className="text-nowrap"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              Logbook Competencies
            </span>
          </span>
        </div>
        <div className='d-flex align-items-center'>
          <DotMenu />
        </div>
      </div>

      <div className='card-body overflow-y-scroll'>
        <div
          className='bg-2f2f2f text-white rounded d-flex align-items-center justify-content-between p-3 mb-3'
        >
          <div className='d-flex align-items-center'>
            <FaClock className='mr-2' size={20} />
            <div className='h6 m-0'>
              {apprenticeshipUser?.firstName}'s Target Hours
            </div>
          </div>
        </div>

        {Array.isArray(competencyGroups) && competencyGroups?.length > 0 && competencyGroups.map((competencyGroup) => {
          const childCompetencies = competencyGroup?.competencyIds?.length
            ? competencyGroup?.competencyIds.map((cId) => competencies[cId])
            : [];

          return (
            <div
              key={competencyGroup?.id}
              className='border mb-3'
            >
              <div className='bg-light p-3'>
                <h5 className='mb-0'>
                  {competencyGroup?.description}
                </h5>
              </div>
              <div>
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Competency</th>
                      <th
                        scope="col"
                        style={{ width: '125px' }}
                        className='text-center text-nowrap'
                      >
                        User Hours
                      </th>
                      <th
                        scope="col"
                        className='text-center'
                        style={{ width: '100px' }}
                      >
                        <span className='text-nowrap text-center'>
                          Target Hours
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {childCompetencies.map((cc, i) => {
                      let value = userCompetenciesHash[cc?.id] ? parseInt(userCompetenciesHash[cc?.id].totalHours, 10) : '';

                      if (formValues[cc?.id]) {
                        if (formValues[cc?.id].totalHours === '') {
                          value = formValues[cc?.id].totalHours;
                        } else {
                          value = parseInt(formValues[cc?.id].totalHours, 10);
                        }
                      }

                      return (
                        <tr
                          key={`cc-${i}`}
                          className={`${value === 0 ? 'bg-ffe9eb' : ''} ${value > 0 ? 'bg-e9f4ff' : ''}`}
                        >
                          <td>{cc.description}</td>
                          <td
                            aria-label={`Hours: ${cc.description}`}
                            style={{ width: '125px' }}
                            className='text-nowrap'
                          >
                            <input
                              id={cc?.id}
                              type="number"
                              name={cc?.id}
                              data-group={competencyGroup.id}
                              value={value}
                              className='form-control'
                              step={1}
                              onChange={onChange}
                            />
                          </td>
                          <td
                            className='text-center'
                            style={{ width: '100px' }}
                          >
                            <div className='d-flex align-items-center justify-content-center'>
                              {cc.totalHours}
                              {value === 0 && (
                                <FaEyeSlash
                                  size={20}
                                  className='text-danger ml-2'
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
      <div className='card-footer d-flex justify-content-between'>
        <button
          type='button'
          className='btn btn-primary'
          onClick={onClick}
          disabled={!hoursMatch || loading}
        >
          Save
        </button>
        <div
          className='d-flex align-items-center'
        >
          <FaClock
            size={20}
            className={`${hoursMatch ? 'text-keppel' : 'text-danger'} mr-2`}
          />
          <div
            className='d-flex align-items-center border'
          >
            <div
              style={{ width: '125px' }}
              className={`${hoursMatch ? 'text-keppel' : 'text-danger'} font-weight-bold text-nowrap text-center bg-white py-2 px-4 border-right`}
            >
              {totalUserCompetenciesHours}
            </div>
            <div
              className='text-center py-2 px-4'
              style={{ width: '110px' }}
            >
              {totalHours}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUserCompetenciesSideSheet;
