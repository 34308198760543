import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'chart.js/auto';
import Skeleton from 'react-loading-skeleton';
import { Chart, getElementAtEvent } from 'react-chartjs-2';
import { FaChartLine, FaPrint } from 'react-icons/fa';
import { canAccessAllLocations } from '@apprentage/utils';
import { VELA_ICON } from '@apprentage/constants';
import * as ChartUtils from '../../../../../utils/chartjsUtils';
import { mdDate } from '../../../../../utils/date';
import heapAnalytics from '../../../../../utils/heapAnalytics';
import { setSideSheet } from '../../../../../actions/SideSheets';
import { SHEET_KEY_DAILY_RECORDS } from '../../../../../constants/sideSheets';
import { locationTerminology } from '../../../../../services/locations';

const DailyUsage = ({
  rawData,
  rawLabels,
  numDaysAgo = 60,
  onChangeUsageDate,
  callbackPrimaryAction
}) => {
  const chartRef = useRef();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || '';
  // Records
  const isDataPresent = Array.isArray(rawData) && rawData?.length > 0;
  const hasEmployers = Array.isArray(apprenticeship?.employerIds) && apprenticeship?.employerIds?.length > 0;
  // Local State
  const [dataType, setDataType] = useState('Records');

  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const preppedData = useMemo(() => {
    return rawData?.map((locationData) => {
      return {
        ...locationData,
        lineData: locationData.data.map((dateData) => {
          let yAxisData = 0;

          if (Array.isArray(dateData.records)) {
            yAxisData = dateData.records.reduce((acc, curr) => {
              return acc + parseFloat(curr.hours);
            }, 0);
          }

          return {
            x: mdDate(dateData.date),
            y: dataType === 'Records' ? dateData.records.length : yAxisData,
            dataKey: dateData.date
          };
        })
      };
    });
  }, [dataType, rawData]);

  const chartData = useMemo(() => {
    return {
      labels: Object.values(rawLabels).reverse(),
      datasets: preppedData.map((data, index) => ({
        label: data.name,
        data: data.lineData,
        fill: false,
        borderColor: ChartUtils.namedColor(index),
        backgroundColor: ChartUtils.transparentize(
          ChartUtils.namedColor(index),
          0.5
        ),
        tension: 0.1
      }))
    };
  }, [preppedData, rawLabels]);

  const legendText = useMemo(() => {
    let text = `Logbook ${dataType}`;

    if (canAccessAllLocations(role)) {
      text += ` per ${locationTerminology({ orgType }).text}`;
    }

    return text;
  }, [dataType, orgType]);

  const onClickPlotLine = (event) => {
    const eventData = getElementAtEvent(chartRef.current, event);

    heapAnalytics.track(
      'Training Program - Dashboard - VELA Usage - Daily Records - Open'
    );

    if (eventData.length) {
      const dataObj = chartData.datasets[eventData[0].datasetIndex].data[eventData[0].index];

      dispatch(
        setSideSheet({
          key: SHEET_KEY_DAILY_RECORDS,
          data: {
            recordsDate: dataObj?.dataKey,
            callbackPrimaryAction
          }
        })
      );
    }
  };

  const handlePrint = () => {
    // https://www.chartjs.org/docs/latest/developers/api.html#tobase64image-type-quality
    const url = chartRef.current.toBase64Image();
    const myWindow = window.open('', 'myWindow', 'width=1000,height=600');

    heapAnalytics.track('Training Program - Dashboard - VELA Usage - Print');

    myWindow.document.write(
      `<h3 style="text-align:center">VELA Usage over time</h3><img src="${url}" onload="window.print()" width="100%" />`
    );
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: legendText
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card h-100">
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="h6 m-0 d-flex align-items-center font-weight-bold">
              <img
                src={VELA_ICON}
                alt="Voice Enabled Logbook Assistant"
                height="20"
                style={{
                  height: '20px'
                }}
              />
              <span className="ml-2 d-none d-sm-flex">
                Logbook Tracking
              </span>
            </div>

            <div className="d-flex align-items-center">
              <select
                name="gteDate"
                value={numDaysAgo}
                className="form-control ml-2"
                style={{
                  width: '140px'
                }}
                onChange={onChangeUsageDate}
                disabled={!hasEmployers}
              >
                <option value={30}>Last 30 days</option>
                <option value={60}>Last 60 days</option>
                <option value={90}>Last 90 days</option>
              </select>

              <select
                name="dataType"
                value={dataType}
                className="form-control ml-2"
                style={{
                  width: '110px'
                }}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setDataType(value);
                }}
                disabled={!hasEmployers}
              >
                <option value="Records">Records</option>
                <option value="Hours">Hours</option>
              </select>
            </div>

            <button
              className="btn btn-sm btn-outline-primary ml-2"
              onClick={handlePrint}
              disabled={!isDataPresent}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPrint />
                <span className="ml-1 d-none d-lg-inline">Print</span>
              </span>
            </button>
          </div>
          <div className="card-body h-100 position-relative">
            {isDataPresent ? (
              <Chart
                type="line"
                ref={chartRef}
                data={chartData}
                options={options}
                onClick={onClickPlotLine}
              />
            ) : (
              <>
                {logbookRecords?.fetched ? (
                  <>
                    <div className="d-flex align-items-center justify-content-center mt-4 mb-2">
                      <FaChartLine
                        size={70}
                        className="text-muted"
                      />
                    </div>
                    <h5 className="text-center font-weight-bold mb-4">
                      This chart is empty!
                    </h5>
                    <p>
                      No one has yet logged a task in their logbook! Remind the
                      trainees to start using VELA to easily track their tasks,
                      activities and time in their logbook.
                    </p>
                  </>
                ) : (
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                    <div className="d-none d-sm-block col-12 col-sm-6 col-md-4 mt-1">
                      <Skeleton
                        width="100%"
                        height="148px"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyUsage;
