import { RESET_LOGBOOKS, SET_LOGBOOKS } from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  hash: null
};

export default function logbooks(state = initialState, action) {
  switch (action.type) {
    case SET_LOGBOOKS: {
      return {
        ...state,
        list: action.list,
        hash: action.hash,
        fetched: true
      };
    }
    case RESET_LOGBOOKS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
