import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaCheckSquare,
  FaDatabase,
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaImage,
  FaInfoCircle,
  FaPaintBrush,
  FaPaperPlane,
  FaPlus,
  FaRegSquare,
  FaTenge,
  FaUser
} from 'react-icons/fa';
import pluralize from 'pluralize';
import ordinal from 'ordinal';
import ReactTooltip from 'react-tooltip';
import { ORG_CONSOLE_ASSISTANT_BASE, ORG_CONSOLE_ASSISTANTS } from '../../constants/routes';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_MANAGE_PROMPT } from '../../constants/modals';
import { createIntegration, updateIntegration } from '../../services/integrations';
import { SHEET_KEY_CHOOSE_RESOURCE_FOLDER } from '../../constants/sideSheets';
import { resetSideSheet, setSideSheet } from '../../actions/SideSheets';
import removeStringFromArray from '../../utils/removeStringFromArray';
import DeleteAssistant from '../../components/ManageContent/DeleteContent/DeleteAssistant';
import ItemsDnd from '../../components/ManageContent/Dnd/Items';
import ColorPicker from '../../components/ManageContent/ColorPicker';
import AgentImagePicker from './AgentImagePicker';
import SearchFolders from '../../components/SearchContent/SearchFolders';
import AgentImage from './AgentImages/AgentImage';
import TagInput from '../../components/ManageContent/TagInput';
import Switch from '../../components/Switch';
import MessageContainer from '../Assistant/MessageContainer';
import DefaultPrompts from '../Assistant/DefaultPrompts';
import PoweredBy from './PoweredBy';

const Form = () => {
  const orgNicknameRef = useRef(null);
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const assistant = useSelector((state) => state.assistant);
  // Organization
  const orgId = organization?.id || '';
  const orgName = organization?.name || '';
  // Current User
  const userId = currentUser?.id || null;
  // Default Values
  const defaultColorBtnBkg = '#000000';
  const defaultWelcomeMessage = "👋🏻 Hello, I'm an AI Assistant here to answer any questions you have about {ORG_NICKNAME}.";
  const defaultAgentImage = 'https://turbine-content.s3.us-east-2.amazonaws.com/bot-helmet.png';
  const defaultNudgeInterval = 1500;
  const defaultNoResponseMsg = "I can't give an accurate or relevant response to your query. I'll try again, if you rephrase it.";

  let defaultSystemPrompt = 'You are a helpful assistant. ';
  defaultSystemPrompt += 'Your primary duty is to receive requests and provide a response using only information that you are provided. ';
  defaultSystemPrompt += 'All material pieces of information in your response must be sourced and verifiable from the provided context. ';
  defaultSystemPrompt += 'No external information should be used. ';
  defaultSystemPrompt += 'If a request falls outside this scope ask for clarification if the request seems genuine. ';
  defaultSystemPrompt += 'If a request or topic is inappropriate, respond politely that you do not consider such content.';

  // Local State
  const [loading, setLoading] = useState(false);
  const [useOrgName, setUseOrgName] = useState(true);
  const [showNudgeMsg, setShowNudgeMsg] = useState(assistant?.id ? Boolean(assistant?.nudgeInterval) : true);
  // Local State DB vals
  const [name, setName] = useState(assistant?.name || 'My New Assistant');
  const [nudgeInterval, setNudgeInterval] = useState(assistant?.nudgeInterval || defaultNudgeInterval);
  const [colorBtnBkg, setColorBtnBkg] = useState(assistant?.colorBtnBkg || defaultColorBtnBkg);
  const [welcomeMessage, setWelcomeMessage] = useState(assistant?.welcomeMessage || defaultWelcomeMessage);
  const [orgNickname, setOrgNickname] = useState(assistant?.orgNickname || '');
  const [embedUrls, setEmbedUrls] = useState(assistant?.embedUrls || []);
  const [resourceGroupIds, setResourceGroupIds] = useState(assistant?.resourceGroupIds || null);
  const [agentImage, setAgentImage] = useState(assistant?.agentImage || defaultAgentImage);
  const [archived, setArchived] = useState(assistant?.archived || false);
  const [defaultPrompts, setDefaultPrompts] = useState(assistant?.defaultPrompts || []);
  const [systemPrompt, setSystemPrompt] = useState(assistant?.systemPrompt || null);
  const [serviceEmail, serServiceEmail] = useState(assistant?.serviceEmail || null);
  const [showServiceEmail, setShowServiceEmail] = useState(Boolean(assistant?.serviceEmail));
  const [serviceDelayCount, setServiceDelayInterval] = useState(assistant?.serviceDelayCount || 2);

  const showSuccess = () => {
    toast.success('Project saved!');
    setLoading(false);
  };

  const managePrompt = (data) => {
    const config = {
      key: MODAL_KEY_MANAGE_PROMPT,
      data: {
        index: data?.index || 0
      },
      callbackPrimaryAction: (prompt) => {
        const newDefaultPrompts = [...defaultPrompts || []];

        newDefaultPrompts[prompt.index] = prompt.text;

        setDefaultPrompts(newDefaultPrompts);
      }
    };

    if (data?.item?.title) {
      config.data.text = data?.item?.title;
    }

    dispatch(setCurrentModal(config));
  };

  const chooseKnowledgeBase = () => {
    dispatch(setSideSheet({
      key: SHEET_KEY_CHOOSE_RESOURCE_FOLDER,
      data: {
        title: 'Choose Knowledge Base',
        moveToResources: true
      },
      callbackPrimaryAction: (resourceGroupId) => {
        const newResourceGroupIds = [...(resourceGroupIds || [])];

        if (resourceGroupId) {
          newResourceGroupIds.push(resourceGroupId);
          setResourceGroupIds(newResourceGroupIds);
        }

        dispatch(resetSideSheet());
      }
    }));
  };

  /**
   *
   * @param {object} param.item
   * @param {number} param.index item's index in array
   */
  const handleDeleteItem = ({ item }) => {
    let newDefaultPrompts = [...defaultPrompts];

    newDefaultPrompts = removeStringFromArray(defaultPrompts, item?.title);

    setDefaultPrompts(newDefaultPrompts);
  };

  const onSaveAssistant = (e) => {
    e.preventDefault();

    const dataToSave = {
      name,
      version: 1,
      type: 'embedRagResourceGroups',
      colorBtnBkg: colorBtnBkg || null,
      welcomeMessage: welcomeMessage || null,
      orgNickname: orgNickname || null,
      embedUrls: null,
      resourceGroupIds: null,
      agentImage: agentImage || null,
      archived: archived || null,
      defaultPrompts: null,
      nudgeInterval,
      systemPrompt: null,
      serviceEmail: serviceEmail || null,
      serviceDelayCount: serviceEmail ? serviceDelayCount : null
    };

    const trimmedSystemPrompt = (systemPrompt || '').trim();

    if (trimmedSystemPrompt) {
      dataToSave.systemPrompt = trimmedSystemPrompt;
    }

    if (Array.isArray(defaultPrompts) && defaultPrompts.length > 0) {
      dataToSave.defaultPrompts = defaultPrompts;
    }

    if (Array.isArray(resourceGroupIds) && resourceGroupIds.length > 0) {
      dataToSave.resourceGroupIds = resourceGroupIds;
    }

    if (Array.isArray(embedUrls) && embedUrls.length > 0) {
      dataToSave.embedUrls = embedUrls;
    }

    if (!dataToSave.colorBtnBkg) {
      toast.error('Button Color is required.');
      return;
    }

    if (!dataToSave.agentImage) {
      toast.error('Button Image is required.');
      return;
    }

    if (!dataToSave.resourceGroupIds) {
      toast.error('Knowledge Base is required');
      return;
    }

    if (!dataToSave.embedUrls) {
      toast.error('Enter at least one Web Page URL');
      return;
    }

    setLoading(true);

    if (assistant?.id) {
      updateIntegration(dataToSave, assistant?.id).then(() => {
        showSuccess();
        document.location = `${ORG_CONSOLE_ASSISTANT_BASE}/${assistant.id}`;
      });
    } else {
      createIntegration({
        ...dataToSave,
        orgId,
        userId,
        poweredBy: true
      }).then((response) => {
        if (response?.id) {
          showSuccess();
          document.location = `${ORG_CONSOLE_ASSISTANT_BASE}/${response?.id}`;
        } else if (response?.error) {
          console.error(response?.message || response?.error);
          toast.error(response.error);
        }
      });
    }
  };

  const onChangeResourceGroupIds = (groupId) => {
    let newSearchGroupIds = [];

    if (Array.isArray(resourceGroupIds)) {
      newSearchGroupIds = resourceGroupIds.filter((gId) => gId !== groupId);
    }

    if (Array.isArray(newSearchGroupIds) && newSearchGroupIds.length > 0) {
      setResourceGroupIds(newSearchGroupIds);
    } else {
      setResourceGroupIds(null);
    }
  };

  const initialPrompts = useMemo(() => {
    if (!defaultPrompts || (Array.isArray(defaultPrompts) && defaultPrompts.length === 0)) {
      return null;
    }

    return defaultPrompts.map((prompt) => ({
      postAsCpu: true,
      isClickable: true,
      onlyMessage: true,
      message: prompt
    }));
  }, [defaultPrompts]);

  const handleColorChange = ({ colorBtnBkg: newColorBtnBkg }) => {
    setColorBtnBkg(newColorBtnBkg);
  };

  const handleAgentImageChange = ({ agentImage: newAgentImage }) => {
    setAgentImage(newAgentImage);
  };

  const nudgeIntervalReadable = useMemo(() => {
    return nudgeInterval / 1000;
  }, [nudgeInterval]);

  const validEmail = useMemo(() => {
    return serviceEmail && serviceEmail.includes('@') && serviceEmail.includes('.');
  }, [serviceEmail]);

  useEffect(() => {
    if (!useOrgName) {
      orgNicknameRef.current.focus();
    }
  }, [useOrgName]);

  return (
    <>
      <div
        className="d-flex align-items-center shadow mt-3 rounded rounded-3 bg-white p-3"
      >
        <AgentImage
          className="mb-0"
          imgSrc={agentImage}
          imgName={name}
          bkgColor={colorBtnBkg}
        />
        <h4 className="m-0">{name}</h4>
      </div>

      <div>
        <div className='row my-4'>
          <div className='col-6'>
            <div className="card h-100">
              <div
                className="card-header d-flex align-items-center justify-content-between"
              >
                <div className='d-flex align-items-center h6 m-0 font-weight-bold'>
                  <FaInfoCircle />
                  <span className='ml-2'>
                    Configuration
                  </span>
                </div>
                {assistant?.id && (
                  <Switch
                    id="archived"
                    label="Archived"
                    value={archived}
                    onChange={() => {
                      setArchived(!archived);
                    }}
                  />
                )}
              </div>
              <div className='card-body p-0 h-100'>
                <div className="p-3 border-bottom">
                  <div className="mb-2">
                    <strong>Name:</strong> <span className="text-danger">*</span>
                  </div>
                  <div className="mb-2">
                    Internal name for your reference.
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setName(value);
                    }}
                    autoFocus={!assistant?.id}
                    required
                  />
                  <div className="text-muted small mt-1">
                    Ex: Marketing Website FAQ
                  </div>
                </div>

                <div className="p-3 border-bottom">
                  <div className="mb-2 d-flex align-items-center justify-content-between">
                    <div>
                      <strong>Organization Nickname:</strong> {!useOrgName && (<span className="text-danger">*</span>)}
                    </div>
                    <Switch
                      id="useOrgName"
                      label="Organization Name"
                      value={useOrgName}
                      onChange={() => {
                        setUseOrgName(!useOrgName);
                        setOrgNickname('');
                      }}
                    />
                  </div>

                  {useOrgName ? (
                    <input
                      type="text"
                      className="form-control"
                      name="orgName"
                      value={orgName}
                      readOnly
                      disabled
                    />
                  ) : (
                    <input
                      id="testing"
                      ref={orgNicknameRef}
                      type="text"
                      className="form-control"
                      name="orgNickname"
                      value={orgNickname}
                      required={!useOrgName}
                      onChange={(e) => {
                        const { value } = e.currentTarget;

                        setOrgNickname(value);
                      }}
                    />
                  )}

                </div>

                <div className="p-3 border-bottom">
                  <div className="mb-2">
                    <b>Welcome Message:</b> <span className="text-danger">*</span>
                  </div>
                  <textarea
                    className="form-control"
                    name="welcomeMessage"
                    value={welcomeMessage}
                    required
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setWelcomeMessage(value);
                    }}
                  />
                  <div className="text-muted small">
                    Use {'{ORG_NICKNAME}'} = {orgNickname || orgName}
                  </div>
                </div>

                <div className="p-3 border-bottom">
                  <div className="mb-2 d-flex align-items-center justify-content-between">
                    <div>
                      <div className='font-weight-bold'>
                        Nudge Visitor
                      </div>
                      <div>
                        Draw attention to Assistant Button
                      </div>

                    </div>
                    <Switch
                      id="showNudgeMsg"
                      label="Enabled"
                      value={showNudgeMsg}
                      onChange={() => {
                        const newVal = !showNudgeMsg;
                        setShowNudgeMsg(newVal);
                        if (!newVal) {
                          setNudgeInterval(null);
                        } else {
                          setNudgeInterval(defaultNudgeInterval);
                        }
                      }}
                    />
                  </div>

                  {showNudgeMsg && (
                    <>
                      <div className='border rounded p-2'>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className='ml-3'>
                            1
                          </div>
                          <div>
                            2
                          </div>
                          <div>
                            3
                          </div>
                          <div>
                            4
                          </div>
                          <div>
                            5
                          </div>
                          <div>
                            6
                          </div>
                          <div>
                            7
                          </div>
                          <div>
                            8
                          </div>
                          <div>
                            9
                          </div>
                          <div className='mr-3'>
                            10
                          </div>
                        </div>

                        <div>
                          <input
                            className="form-control"
                            type="range"
                            name="nudgeInterval"
                            value={nudgeInterval}
                            required={showNudgeMsg}
                            step={250}
                            min={1000}
                            max={10000}
                            onChange={(e) => {
                              const { valueAsNumber } = e.currentTarget;

                              setNudgeInterval(valueAsNumber);
                            }}
                          />
                        </div>
                      </div>

                      <div className="mt-2 d-flex align-items-center">
                        <p className='m-0'>
                          Wait <strong>{nudgeIntervalReadable} {pluralize('second', nudgeIntervalReadable)}</strong> after visitor scrolls webpage, then show welcome message above Assitant button.
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <div className="p-3">
                  <div className="mb-1">
                    <b>Website URLs:</b> <span className="text-danger">*</span>
                  </div>
                  <div className='mb-2'>
                    Enter the URLs where you will embed this Assistant.
                  </div>
                  <TagInput
                    id="embedUrls"
                    name="embedUrls"
                    tags={embedUrls}
                    onChange={({ embedUrls: newEmbedUrls }) => {
                      // null converted to undefined when data is formatted before sent to BE
                      setEmbedUrls(
                        newEmbedUrls.length === 0 ? null : newEmbedUrls
                      );
                    }}
                  />
                  <div className='small text-muted mt-1'>
                    Ex: https://website.com, https://blog.website.com
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className="card h-100">
              <div
                className="card-header d-flex align-items-center justify-content-between"
                style={{
                  height: '59px'
                }}
              >
                <div className='d-flex align-items-center h6 m-0 font-weight-bold'>
                  <FaEye />
                  <span className='ml-2'>
                    Preview
                  </span>
                </div>

              </div>
              <div className='card-body bkg-pattern-light h-100'>
                <div
                  data-tip="Welcome Message"
                  data-for="humanHelpPreview"
                >
                  <MessageContainer
                    agentImage={agentImage}
                    colorBtnBkg={colorBtnBkg}
                    postAsCpu
                    orgName={orgNickname || orgName}
                    text={welcomeMessage.replace('{ORG_NICKNAME}', orgNickname || orgName)}
                    id="welcome-message"
                  />
                </div>

                {Array.isArray(defaultPrompts) && defaultPrompts.length > 0 && (
                  <div
                    data-tip="Helper Prompts"
                    data-for="humanHelpPreview"
                  >
                    <MessageContainer
                      onlyMessage
                    >
                      <>
                        <DefaultPrompts
                          list={initialPrompts}
                        />
                      </>
                    </MessageContainer>
                  </div>
                )}

                <div
                  className="p-0 p-sm-3 bg-white"
                  data-tip="Search Box"
                  data-for="humanHelpPreview"
                >
                  <div className="d-flex">
                    <div className="position-relative w-100">
                      <textarea
                        type="text"
                        className="form-control form-control-lg w-100 "
                        name={name}
                        defaultValue=""
                        placeholder="Ask a question..."
                        style={{
                          paddingRight: '3.5rem',
                          height: '71px'
                        }}
                      />

                      <button
                        type="submit"
                        className="btn btn-lg btn-white p-2 text-primary d-flex align-items-center justify-content-center position-absolute"
                        disabled
                        aria-label='Submit'
                        style={{
                          borderRadius: '100%',
                          border: 0,
                          bottom: '10px',
                          right: '10px',
                          width: '50px',
                          height: '50px',
                          zIndex: 71
                        }}
                      >
                        <FaPaperPlane size={25} />
                      </button>
                    </div>

                  </div>
                </div>
                <PoweredBy className='text-center mt-2' theme='dark' trackingCode='assistant' />
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaPaintBrush />
                <span className='ml-2'>
                  Color
                </span>
              </span>
            </h6>
          </div>
          <div className='card-body'>
            <ColorPicker
              label="Button Background Color"
              id="colorBtnBkg"
              name="colorBtnBkg"
              hexColor={colorBtnBkg}
              hexColorDefault={colorBtnBkg}
              onChange={handleColorChange}
              showLabel={false}
              chooseCustomColor
            />
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaImage />
                <span className='ml-2'>
                  Image
                </span>
              </span>
            </h6>
          </div>
          <div className='card-body'>
            <AgentImagePicker
              label="Agent Image"
              id="agentImage"
              name="agentImage"
              url={agentImage}
              urlDefault={defaultAgentImage}
              onChange={handleAgentImageChange}
              showLabel={false}
              bkgColor={colorBtnBkg}
            // uploadImage
            />
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaDatabase />
                <span className='ml-2'>
                  Knowledge Base
                </span>
              </span>
            </h6>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary d-flex align-items-center"
              onClick={chooseKnowledgeBase}
            >
              <FaPlus size={18} />
              <span className="ml-1">Add</span>
            </button>
          </div>
          <div className='card-body'>
            {Array.isArray(resourceGroupIds) && resourceGroupIds.length > 0 ? (
              <SearchFolders
                ids={resourceGroupIds}
                onChange={onChangeResourceGroupIds}
              />
            ) : (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{
                  height: '50px'
                }}
              >
                <span>
                  No Knowledge Base
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaTenge />
                <span className='ml-2'>
                  Helper Prompts
                </span>
              </span>
            </h6>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary d-flex align-items-center"
              onClick={() => {
                managePrompt({ index: Array.isArray(defaultPrompts) ? defaultPrompts.length : 0 });
              }}
            >
              <FaPlus size={18} />
              <span className="ml-1">New</span>
            </button>
          </div>
          <div className='card-body p-0'>
            {Array.isArray(defaultPrompts) && defaultPrompts.length > 0 ? (
              <ItemsDnd
                key={defaultPrompts.length}
                items={defaultPrompts.map((prompt, i) => ({ id: `prompt-${i}`, title: prompt }))}
                handleDeleteItem={handleDeleteItem}
                handleEditItem={managePrompt}
                onUpdate={(newListOrder) => {
                  const newDefaultPrompts = newListOrder.map((item) => item?.title);
                  setDefaultPrompts(newDefaultPrompts);
                }}
              />

            ) : (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{
                  height: '50px'
                }}
              >
                <span>
                  No default prompts
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaTenge />
                <span className='ml-2'>
                  Guidelines & Instructions
                </span>
              </span>
            </h6>
          </div>
          <div className='card-body'>
            <div
              className="mb-2 d-flex align-items-center justify-content-between"
            >
              <div className='font-weight-bold'>System Prompt:</div>
              <div className='h6 m-0'>
                <div className='badge badge-info d-flex align-items-center'>
                  <FaEyeSlash />
                  <span className='ml-1'>
                    Not shown to user
                  </span>
                </div>
              </div>
            </div>

            <div className='border p-3 rounded mt-2'>
              <div>
                {defaultSystemPrompt}
              </div>
              <textarea
                className="form-control mt-2"
                placeholder='Optionally, include additional instructions...'
                name="systemPrompt"
                value={systemPrompt}
                required
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setSystemPrompt(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className='row mt-3 mb-4'>
          <div className='col-6'>
            <div className="card h-100">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold">
                  <span className='d-flex align-items-center'>
                    <FaUser />
                    <span className='ml-2'>
                      Human Help
                    </span>
                  </span>
                </h6>
              </div>
              <div className='card-body'>
                <p className="mb-2">
                  Take the following actions when the assistant can't respond in a useful or accurate way:
                </p>

                <div className='mb-3 border p-3'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <div>
                        <FaCheckSquare
                          className='text-keppel'
                          size={20}
                        />
                      </div>
                      <div className='ml-3'>
                        <h6 className='font-weight-bold m-0'>
                          Non-helpful response
                        </h6>
                        <div>
                          Encourage user to rephrase their request.
                        </div>
                      </div>
                    </div>
                    <FaTenge size={20} className='text-muted' />
                  </div>
                </div>

                {/* Service Email */}
                <div className='mb-3 border p-3'>
                  <div
                    className='d-flex align-items-center justify-content-between'
                    onClick={() => {
                      setShowServiceEmail(!showServiceEmail);
                    }}
                    aria-hidden
                  >
                    <div className='d-flex align-items-center'>
                      <div>
                        {showServiceEmail && (
                          <FaCheckSquare
                            className='text-keppel'
                            size={20}
                          />
                        )}

                        {!showServiceEmail && (
                          <FaRegSquare size={20} />
                        )}

                      </div>
                      <div className='ml-3'>
                        <h6 className='font-weight-bold m-0'>
                          Customer Service
                        </h6>
                        <div>
                          User can provide their email for further assistance
                        </div>
                      </div>
                    </div>
                    <FaEnvelope
                      size={20}
                      className={`text-muted ${showServiceEmail ? '' : 'opacity-25'}`}
                    />
                  </div>

                  {showServiceEmail && (
                    <>
                      <div className='mt-3'>
                        <div className="mb-2">
                          <div className=''>
                            <div>
                              <strong>Customer Service Email:</strong> <span className="text-danger">*</span>
                            </div>
                            <div>
                              Email to receive a copy of user's chat transcript.
                            </div>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          name="serviceEmail"
                          value={serviceEmail}
                          placeholder='help@website...'
                          onChange={(e) => {
                            const { value } = e.currentTarget;

                            serServiceEmail(value);
                          }}
                          required
                        />
                      </div>

                      {validEmail && (
                        <div className='mt-3'>
                          <div className="mb-2">
                            <div className=''>
                              <div>
                                <strong>Customer Service Delay</strong> <span className="text-danger">*</span>
                              </div>
                            </div>

                          </div>
                          <div className='border rounded p-2'>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className='ml-3'>
                                {ordinal(1)}
                              </div>
                              <div>
                                {ordinal(2)}
                              </div>
                              <div>
                                {ordinal(3)}
                              </div>
                              <div className='mr-3'>
                                {ordinal(4)}
                              </div>
                            </div>

                            <div>
                              <input
                                className="form-control"
                                type="range"
                                name="serviceDelayCount"
                                value={serviceDelayCount}
                                required={showServiceEmail}
                                step={1}
                                min={1}
                                max={4}
                                onChange={(e) => {
                                  const { valueAsNumber } = e.currentTarget;

                                  setServiceDelayInterval(valueAsNumber);
                                }}
                              />
                            </div>
                          </div>

                          <p className="text-muted mt-1">
                            Show email form after <strong>{ordinal(serviceDelayCount)}</strong> non-helpful response
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* <p className="mb-2">
                  Provide an email to receive questions from users who did not receive a helpful response from the Assistant.
                </p> */}

              </div>
            </div>
          </div>

          <div className='col-6'>
            <div className="card h-100">
              <div
                className="card-header d-flex align-items-center justify-content-between"
              >
                <div className='d-flex align-items-center h6 m-0 font-weight-bold'>
                  <FaEye />
                  <span className='ml-2'>
                    Preview
                  </span>
                </div>

              </div>
              <div className='card-body bkg-pattern-light h-100'>
                <div
                  data-tip="Non-helpful Response"
                  data-for="humanHelpPreview"
                >
                  <MessageContainer
                    agentImage={agentImage}
                    colorBtnBkg={colorBtnBkg}
                    postAsCpu
                    orgName={orgNickname || orgName}
                    text={defaultNoResponseMsg}
                    id="nonHelpfulResponse"
                  />
                </div>

                {showServiceEmail && (
                  <div
                    data-tip="Customer Service"
                    data-for="humanHelpPreview"
                  >
                    <MessageContainer
                      onlyMessage
                    >
                      <div>
                        <p>
                          If you like, I can forward your question to someone at {orgName} who can answer it.
                        </p>
                        <div className='mb-3'>
                          <label>
                            <strong>Best email to reach you?</strong> <span className="text-danger">*</span>
                          </label>
                          <input
                            className='form-control form-control-sm '
                            defaultValue=""
                            type="text"
                            required
                            placeholder='youremail@...'
                          />
                        </div>
                        <div className='d-flex justify-content-end'>
                          <button
                            type="button"
                            className='btn btn-sm btn-primary'
                            disabled
                          >
                            <span className='d-flex align-items-center'>
                              <span className='mr-2'>
                                Send
                              </span>
                              <FaPaperPlane />
                            </span>
                          </button>
                        </div>
                      </div>
                    </MessageContainer>
                  </div>
                )}
              </div>
            </div>
          </div>

        </div>

        <div className="bg-white border rounded p-3 d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="submit"
              disabled={loading}
              onClick={onSaveAssistant}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            <Link
              type="button"
              name="Cancel"
              className="btn btn-sm btn-link"
              disabled={loading}
              to={assistant?.id ? `${ORG_CONSOLE_ASSISTANT_BASE}/${assistant?.id}` : ORG_CONSOLE_ASSISTANTS}
            >
              Cancel
            </Link>
          </div>
        </div>

        {assistant?.id && (
          <DeleteAssistant
            name={name}
            className="mt-2"
            contentId={assistant?.id}
          />
        )}
      </div>
      <ReactTooltip id="humanHelpPreview" />
    </>
  );
};

export default Form;
