import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../constants/routes';

const Tabs = () => {
  const apprenticeships = useSelector((state) => state.apprenticeships);

  return (
    <ul
      className="nav nav-tabs border-0 position-relative"
      style={{ zIndex: 1 }}
    >
      <li className='nav-item mr-2'>
        <Link
          to={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
          className="nav-link active"
        >
          <span>
            Active
          </span>
          {apprenticeships?.pagination && (
            <span className='badge bg-white border ml-2 text-ships-officer'>
              {apprenticeships?.pagination?.total || 0}
            </span>
          )}
        </Link>
      </li>

      {/* <li className='nav-item disabled'>
        <Link
          to={CLASS_CONSOLE_USER_ROLES}
          className="nav-link"
          disabled
        >
          Archived
        </Link>
      </li> */}
    </ul>
  );
};

export default Tabs;
