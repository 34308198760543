const employerProfileState = {
  contactFirstName: '',
  contactLastName: '',
  contactTitle: '',
  contactEmail: '',
  contactPhone: '',
  contactPhoneExt: '',
  ein: '',
  naicsCode: '',
  totalEmployees: undefined,
  totalEmployeesFemale: undefined,
  totalEmployeesColor: undefined,
  totalJourneyWorkers: undefined,
  totalJourneyWorkersFemale: undefined,
  totalJourneyWorkersColor: undefined,
  inviteHistory: null,
  journeyWorkerEntryWage: undefined,
  orgId: '',
  locationId: '',
  // sys
  id: '',
  createdAt: '',
  updatedAt: '',
  content_type: 'employerProfile'
};

export default employerProfileState;
