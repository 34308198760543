import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import { GiTeacher } from 'react-icons/gi';
import { FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_INSTRUCTION_PROVIDERS, ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../constants/routes';
import { getInstructionProvider, resetInstructionProvider } from '../../../actions/InstructionProviders';
import { routeWithOrgId } from '../../../services/organizations';
import withOrgConsole from '../../App/withOrgConsole';
import OrgConsoleHeader from '../OrgConsoleHeader';
import Loading from '../../Loading';
import InfoBlock from './InfoBlock';
import ApprenticeshipsList from '../../lists/ApprenticeshipsList';

const InstructionProvider = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  const instructionProvider = useSelector((state) => state.instructionProvider);
  // misc
  const orgId = organization?.id || '';
  const instructionProviderId = params?.instructionProviderId || null;

  const handleOnClickRow = (apprenticeshipId) => {
    const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

    history.push(routeWithOrgId({
      // route: `${apprenticeshipRoute}/instruction-providers/${instructionProviderId}`,
      route: `${apprenticeshipRoute}/instruction-providers`,
      orgId
    }));
  };

  useEffect(() => {
    if (instructionProviderId) {
      dispatch(getInstructionProvider(instructionProviderId));
    }

    return function cleanup() {
      dispatch(resetInstructionProvider());
    };
  }, []);

  if (!instructionProvider?.id) {
    return (
      <Loading text="Loading Instruction Provider..." />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={instructionProvider.name}
            iconComponent={() => <GiTeacher size={25} className='mr-2' />}
            route={ORG_CONSOLE_INSTRUCTION_PROVIDERS}
          />

          <div
            className="bg-white shadow p-3 overflow-content border-left border-right border-bottom mt-3"
          >
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <div className="card h-100 bg-white">
                  <div className='card-header d-flex justify-content-between align-items-center'>
                    <div className='font-weight-bold d-flex align-items-center'>
                      <FaSortAmountUp size={20} />
                      <span className='ml-2'>
                        Training Programs
                      </span>
                    </div>
                  </div>

                  <div className='card-body h-100 p-0'>
                    <ApprenticeshipsList
                      displayType="TABLE"
                      instructionProviderId={instructionProviderId}
                      hiddenColumns={['employerIds', 'ojtHours']}
                      onClickRow={handleOnClickRow}
                    />
                  </div>
                </div>

              </div>

              <div className='col-12 col-sm-6'>
                <InfoBlock
                  className='card h-100'
                  showMenu
                  data={instructionProvider}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(InstructionProvider);
