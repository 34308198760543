import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ICON_SETTINGS, ICON_TRIANGLE_BANG } from '@apprentage/components/dist/constants/icons';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { FaExclamationTriangle } from 'react-icons/fa';
import { setCurrentClass } from '../../../actions/Class';
import { updateCourse } from '../../../services/courses';
import { setCurrentModal } from '../../../actions/Modals';
import {
  canAccessCourseSyndication,
  canManageCourseSyndication
} from '../../../services/currentUser';
import { canManageCourseAuthors } from '../../../permissions/manage';
import TagInput from '../../ManageContent/TagInput';
import RadioGroup from '../../ManageContent/RadioGroup';
import RenderTinyMceEditor from '../../ManageContent/Wysiwyg/RenderTinyMceEditor';
import Collapse from '../../ManageContent/Collapse';
import CourseType from './CourseType';
import Organizations from '../../ManageContent/Organizations';
import Alert from '../../Alert';
import SkillsSelectMenu from '../../OrgConsole/Skills/SkillsSelectMenu';
import UsersListAndSelect from './UserListAndSelect';
import { MODAL_KEY_SEARCH_SELECT_USERS } from '../../../constants/modals';
import LocationSelect from '../../ManageContent/LocationSelect';

const Form = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const dictionary = useSelector((state) => state.dictionary);
  const currentUser = useSelector((state) => state.currentUser);
  const locations = useSelector((state) => state.locations);
  const data = useSelector((state) => state.course);
  // Organization
  const orgType = organization?.type || null;
  // Current User
  const role = currentUser?.role || [];
  // Course
  const classId = data?.id || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(data?.title);
  const [courseTags, setCourseTags] = useState(data?.topics || []);
  const [courseVideo, setCourseVideo] = useState(data?.courseVideo);

  // Settings
  const [userId, setUserId] = useState(data?.userId);
  const [userIds, setUserIds] = useState(data?.userIds || []);
  const [openEnrollment, setOpenEnrollment] = useState(data?.openEnrollment);
  const [inviteOnly, setInviteOnly] = useState(data?.inviteOnly);
  const [courseReadOnly, setCourseReadOnly] = useState(data?.courseReadOnly);
  const [trackAttendance, setTrackAttendance] = useState(data?.trackAttendance);
  const [orgIds, setOrgIds] = useState(data?.orgIds || []);
  // const [leaderboard, setLeaderboard] = useState(data.leaderboard);
  const [skillIds, setSKillIds] = useState(data?.skillIds || []);
  const [locationIds, setLocationIds] = useState(data?.locationIds || []);

  const onChangeSkills = (options) => {
    setSKillIds(options.map((skill) => skill.value));
  };

  // Course Type
  const [enableCourseContent, setEnableCourseContent] = useState(
    data?.enableCourseContent
  );
  const [externalCourseUrl, setExternalCourseUrl] = useState(
    data?.externalCourseUrl
  );

  // EDITOR
  const descriptionRef = useRef(null);
  const scheduleRef = useRef(null);
  const overviewRef = useRef(null); // "Long Description"

  const onSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      classId,
      enableCourseContent,
      externalCourseUrl,
      title,
      topics: courseTags,
      courseVideo,
      // Radio Settings
      openEnrollment,
      inviteOnly,
      courseReadOnly,
      trackAttendance,
      leaderboard: false,
      skillIds: skillIds.length ? skillIds : null,
      locationIds: locationIds.length ? locationIds : null,
      userId,
      userIds: userIds.length ? userIds : null,
      orgIds: orgIds.length ? orgIds : null
    };

    if (!userId && canManageCourseAuthors(role)) {
      toast.error('Course Author is required.');
      return null;
    }

    setLoading(true);

    if (
      descriptionRef
      && descriptionRef.current
      && descriptionRef.current.isDirty
    ) {
      dataToSave.description = descriptionRef.current.getContent();
    }

    if (scheduleRef && scheduleRef.current && scheduleRef.current.isDirty) {
      dataToSave.schedule = scheduleRef.current.getContent();
    }

    if (overviewRef && overviewRef.current && overviewRef.current.isDirty) {
      dataToSave.overview = overviewRef.current.getContent();
    }

    updateCourse(dataToSave).then(() => {
      toast.success('Course settings saved!');
      dispatch(setCurrentClass({ classId }));
      setLoading(false);
    });
  };

  const manageOrgIds = () => {
    dispatch(setCurrentModal({
      key: 'searchSelectOrganizations',
      data: {
        ids: orgIds
      },
      callbackPrimaryAction: (newOrgIds) => {
        setOrgIds(newOrgIds);
      }
    }));
  };

  const selectContributors = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEARCH_SELECT_USERS,
        data: {
          title: 'Select Authors',
          ids: userIds,
          searchFilters: {
            excludeRoles: [
              ...(orgType === 'community' ? ['student', 'programTrainer', 'programManager'] : []),
              'superAdmin'
            ]
          }
        },
        callbackPrimaryAction: (newUserIds) => {
          setUserIds(newUserIds);
        }
      })
    );
  };

  const selectAuthor = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEARCH_SELECT_USERS,
        data: {
          title: 'Select Author',
          ids: userId ? [userId] : [],
          searchFilters: {
            excludeRoles: [
              ...(orgType === 'community' ? ['student', 'programTrainer', 'programManager'] : []),
              'superAdmin'
            ]
          }
        },
        callbackPrimaryAction: (newUserIds) => {
          if (Array.isArray(newUserIds)) {
            if (newUserIds.length === 0) {
              toast.error('Course Author is required.');
              return null;
            }

            setUserId(newUserIds[0]);
          }
        }
      })
    );
  };

  return (
    <form name="form-courseSettings" onSubmit={onSubmit}>
      <div className="card border-bottom my-3">
        <div className="card-header p-3">
          <h1 className="h5 m-0">
            <i className={`${ICON_SETTINGS} mr-1`} aria-hidden="true" /> Settings
          </h1>
        </div>
        <div className="card-body p-0">
          <div className="list-group list-group-flush">
            <Collapse
              title="Course Type"
              className="m-0"
              badge={() => {
                return (
                  <>
                    {enableCourseContent === false && (
                      <span className="badge badge-warning">External</span>
                    )}
                  </>
                );
              }}
              id="chooseCourseType"
            >
              <div className="p-0 p-md-3">
                <CourseType
                  enableCourseContent={enableCourseContent}
                  setEnableCourseContent={setEnableCourseContent}
                  externalCourseUrl={externalCourseUrl}
                  setExternalCourseUrl={setExternalCourseUrl}
                />
              </div>
            </Collapse>

            <div id="class-title" className="list-group-item pb-3">
              <label htmlFor="title">
                <b>Title:</b> <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                defaultValue={title}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setTitle(value);
                }}
                required
              />
            </div>

            <RenderTinyMceEditor
              id="course-description"
              contentId={classId}
              contentType="class"
              className="list-group-item"
              title="Short Description"
              subtitle="Brief description of course."
              defaultValue={data?.description}
              editorRef={descriptionRef}
              uploadImgButton={false}
              uploadPdfButton={false}
              embedMediaButton={false}
              maxCharCount={5000}
              required
            />

            <div className="py-4 list-group-item">
              <div className="mb-2 font-weight-bold d-flex align-items-center">
                <i className={dictionary?.locationIcon} />
                <span className='ml-2'>
                  {dictionary?.locations}
                </span>
              </div>
              {Array.isArray(locations.list) && locations.list.length !== 0 && (
                <LocationSelect
                  id="selectedLocationIds"
                  name="selectedLocationIds"
                  className="mr-0 mr-sm-2 flex-fill"
                  defaultValue={locationIds}
                  data={locations?.list}
                  isMulti
                  onChange={({ selectedLocationIds }) => {
                    const newValues = selectedLocationIds.length === 0 ? [] : selectedLocationIds;

                    setLocationIds(newValues);
                  }}
                />
              )}
            </div>

            <UsersListAndSelect
              title="Contributors"
              className="py-4 list-group-item"
              userIds={userIds}
              onClickSelectUsers={selectContributors}
              selectUsersButtonText="Select Contributors"
              noDataText='No contributors selected'
              footerText='Users who have contributed to this course.'
            />

            <div className='py- list-group-item'>
              {!userId && canManageCourseAuthors(role) && (
                <div className='d-flex align-items-center mb-2'>
                  <FaExclamationTriangle className='text-danger' />
                  <span className='ml-2'>
                    Auto-populated on new Courses, choose an author for this existing Course.
                  </span>
                </div>
              )}
              <UsersListAndSelect
                title="Author"
                userIds={userId ? [userId] : []}
                {...(canManageCourseAuthors(role) ? { onClickSelectUsers: selectAuthor } : {})}
                selectUsersButtonText="Select Author"
                noDataText='No author selected'
              />
            </div>

            <SkillsSelectMenu
              showManageLink
              showHelpText
              className='position-relative list-group-item py-4'
              ids={skillIds}
              onChange={onChangeSkills}
            />

            <Collapse
              title="Tags"
              id="courseTagsCollapse"
              ariaExpanded
              badge={() => (
                <span className='h6 m-0'>
                  <span className='badge badge-danger ml-2'>
                    deprecated
                  </span>
                </span>
              )}
            >
              <div className='d-flex align-items-center mb-2'>
                <FaTriangleExclamation className='text-danger' />
                <span className='ml-1 font-weight-bold'>
                  This feature is deprecated, use Skills instead. This feature will be removed APR 2024.
                </span>
              </div>
              <div className="text-muted medium mb-2">
                Simplified tags describing the contents of this course.
              </div>
              <TagInput
                id="courseTags"
                name="courseTags"
                tags={courseTags}
                onChange={({ courseTags: newCourseTags }) => {
                  // null converted to undefined when data is formatted before sent to BE
                  setCourseTags(
                    newCourseTags.length === 0 ? null : newCourseTags
                  );
                }}
              />
            </Collapse>

            <Collapse title="Long Description" id="courseLongDescription">
              <RenderTinyMceEditor
                id="course-overview"
                contentId={classId}
                contentType="class"
                subtitle="More detailed information about the Course that shows on the Course dashboard."
                defaultValue={data?.overview}
                editorRef={overviewRef}
                uploadPdfButton={false}
              />
            </Collapse>

            <Collapse title="Schedule" id="courseSchedule">
              <RenderTinyMceEditor
                id="course-schedule"
                contentId={classId}
                contentType="class"
                subtitle="Ex: Weekly. Tue & Thr 7-9pm"
                defaultValue={data?.schedule}
                editorRef={scheduleRef}
                uploadImgButton={false}
                uploadPdfButton={false}
                embedMediaButton={false}
                maxCharCount={5000}
              />
            </Collapse>

            <Collapse title="Course Video" id="courseVideo">
              <div>
                <label className="mb-1">
                  <b>Embed Code:</b>
                </label>
                <div className="text-muted medium mb-2">
                  Video showcasing the course that displays on the course
                  invitation page.
                </div>
                <textarea
                  name="courseVideo"
                  className="form-control mb-2"
                  defaultValue={courseVideo}
                  placeholder="<iframe src=..."
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setCourseVideo(value);
                  }}
                />
                <div className="text-muted small">
                  Paste embed code from youtube, vimeo, etc.
                </div>
              </div>
            </Collapse>

            <Collapse
              title="Advanced Settings"
              id="advancedSettings"
            >
              <div id="class-openEnrollment" className="list-group-item">
                <label className="mb-1">
                  <b>Open Enrollment:</b> <span className="text-danger">*</span>
                </label>
                <div className="text-muted medium mb-2">
                  Controls whether users can enroll in this Course.
                </div>
                <RadioGroup
                  name="openEnrollment"
                  value={openEnrollment}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setOpenEnrollment(value === 'true');
                  }}
                />
              </div>

              <div id="class-trackAttendance" className="list-group-item">
                <label className="mb-1">
                  <b>Take Attendance:</b> <span className="text-danger">*</span>
                </label>
                <div className="text-muted medium mb-2">
                  Allow teachers and admins to manually take attendance.
                </div>
                <RadioGroup
                  name="trackAttendance"
                  value={trackAttendance}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setTrackAttendance(value === 'true');
                  }}
                />
              </div>

              <div id="class-openEnrollment" className="list-group-item">
                <label className="mb-1">
                  <b>Invitation Only:</b>
                </label>
                <div className="text-muted medium mb-2">
                  Users can only enroll if sent an invitation.
                </div>
                <RadioGroup
                  name="inviteOnly"
                  value={inviteOnly}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setInviteOnly(value === 'true');
                  }}
                />
              </div>

              <div id="class-openEnrollment" className="list-group-item">
                <label className="mb-1">
                  <b>Read Only:</b>
                </label>
                <div className="text-muted medium mb-1">
                  Allow anyone to view this course's content without enrolling.
                </div>
                <div className="text-muted medium mb-2">
                  Users can view Quizzes and Challenges, but can NOT submit answers.
                </div>
                <RadioGroup
                  name="courseReadOnly"
                  value={courseReadOnly}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setCourseReadOnly(value === 'true');
                  }}
                />
              </div>
            </Collapse>

            {canAccessCourseSyndication(role) && (
              <Collapse
                title="Syndication ( Outbound )"
                id="syndicationControls"
                badge={() => {
                  return (
                    <>
                      <span className="h6 ml-2 m-0">
                        <span className="badge badge-info">
                          {orgIds.length}
                        </span>
                      </span>
                    </>
                  );
                }}
              >
                <>
                  <div
                    className='mb-2'
                  >
                    <strong>Users</strong> from these Organizations can enroll in this course.
                  </div>
                  <div
                    className='mb-3'
                  >
                    <strong>Administrators</strong> from these Organizations can <strong>NOT</strong> modify content, but <strong>CAN</strong> see their own user's Challenge Answers and Quiz Outcomes.
                  </div>
                  <div
                    className='card'
                  >
                    <div
                      className='card-header bg-white font-weight-bold h6 m-0'
                    >
                      Organizations
                    </div>
                    <Organizations
                      key={orgIds}
                      orgIds={orgIds}
                      className="card-body"
                    >
                      {canManageCourseSyndication(role) ? (
                        <button
                          className="btn btn-sm btn-outline-primary"
                          type="button"
                          title="Manage Organizations"
                          onClick={manageOrgIds}
                          disabled={loading}
                        >
                          <i className={`${ICON_SETTINGS} mr-1`} /> Manage Organizations
                        </button>
                      ) : (
                        <Alert
                          type=""
                          icon={`${ICON_TRIANGLE_BANG} text-warning`}
                          className="m-0 w-100 border"
                        >
                          <div>
                            Owner permissions are required to syndicate this course.
                          </div>
                        </Alert>
                      )}
                    </Organizations>
                  </div>
                </>
              </Collapse>
            )}

            {/* <div id="class-leaderboard" className="list-group-item">
              <label className="mb-1">
                <b>Leaderboard:</b>
              </label>
              <div className="text-muted medium mb-2">
                Enable the leaderboard and user performance tracking.
              </div>
              <RadioGroup
                name="leaderboard"
                value={leaderboard}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setLeaderboard(value === 'true');
                }}
              />
            </div> */}
          </div>
        </div>
        <div className="card-footer d-flex p-3">
          <button
            className="btn btn-primary d-block"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
