import React from 'react';
import OrgLocation from '../../OrgLocation';

const LocationCell = ({ value: locationId }) => {
  return (
    <OrgLocation
      locationId={locationId}
    />
  );
};

export default LocationCell;
