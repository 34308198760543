import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ICON_CHALLENGE } from '@apprentage/constants';
import { resetCurrentAnswers, setCurrentAnswers } from '../../../actions/Answers';
import { routeWithClassId } from '../../../services/courses';
import { CLASS_CONSOLE_CHALLENGES, CLASS_CONSOLE_CHALLENGE_BASE } from '../../../constants/routes';
import { resetCurrentChallenge, setCurrentChallenge } from '../../../actions/Challenges';
import withConsole from '../../App/withConsole';
import EntryButton from '../../btns/EntryButton';
import Form from './Form';
import CdnFiles from '../../ManageContent/CdnFiles';
import DeleteChallenge from '../../ManageContent/DeleteContent/DeleteChallenge';
import Breadcrumb from '../Breadcrumb';
import Loading from '../../Loading';

const Challenge = () => {
  const dispatch = useDispatch();
  // Redux
  const currentAnswers = useSelector((state) => state.currentAnswers);
  const currentChallenge = useSelector((state) => state.currentChallenge);
  // Misc
  const queryParams = new URLSearchParams(window.location.search);
  const entryId = queryParams.get('entryId');
  const classId = queryParams.get('classId');
  const topicId = queryParams.get('topicId');

  const contentType = queryParams.get('contentType');
  const title = currentChallenge?.title || '';

  const pagination = currentAnswers?.pagination || '';
  const paginationTotal = pagination?.total || '';

  useEffect(() => {
    if (contentType === 'challenge' && entryId) {
      dispatch(setCurrentChallenge(entryId));
      dispatch(setCurrentAnswers({
        challengeId: entryId,
        limit: 200
      }));
    }

    return function cleanup() {
      dispatch(resetCurrentChallenge());
      dispatch(resetCurrentAnswers());
    };
  }, [entryId, contentType]);

  if (entryId && !currentChallenge.id) {
    return (
      <Loading text="Loading Challenge..." />
    );
  }

  return (
    <>
      <Breadcrumb
        className="my-3"
        currentPageTitle={currentChallenge.title}
      />

      <div className="card my-3">
        <div className="card-header bg-light p-3 d-flex align-items-center justify-content-between">
          <h5 className="m-0 text-capitalize d-flex align-items-center">
            <i className={`${ICON_CHALLENGE} mr-2`} aria-hidden="true" /> {title || 'New Challenge'}
          </h5>
          {entryId && (
            <EntryButton
              title="Preview"
              classId={classId}
              topicId={topicId}
              entryId={entryId}
            />
          )}
        </div>

        <div className="pt-3 bg-light">
          <ul className="nav nav-tabs pl-3">
            <li className="nav-item">
              <Link
                className="nav-link active mr-1"
                to={routeWithClassId({
                  route: CLASS_CONSOLE_CHALLENGES,
                  classId
                })}
                title="Settings"
              >
                Settings
              </Link>
            </li>
            {entryId && (
              <li className="nav-item">
                <Link
                  to={routeWithClassId({
                    route: `${CLASS_CONSOLE_CHALLENGE_BASE}/${entryId}/submissions/course`,
                    classId
                  })}
                  className="nav-link d-flex align-items-center"
                >
                  <span>
                    Submissions
                  </span>
                  <span
                    className="badge bg-white border px-2 py-1 ml-2"
                    style={{ color: '#495057' }}
                  >
                    {currentAnswers.list && currentAnswers.list.length !== paginationTotal && `${currentAnswers.list.length} / `}{paginationTotal}
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>

        <Form />
      </div>

      {currentChallenge.id && (
        <DeleteChallenge
          contentId={entryId}
          title={title}
        />
      )}

      {currentChallenge.id && (
        <div className="mb-5">
          <CdnFiles
            id="challenge-integration-files"
            contentId={currentChallenge.id}
            contentType="challenge"
            files={currentChallenge.cdnFiles}
            editMenu
          />
        </div>
      )}
    </>
  );
};

export default withConsole(Challenge);
