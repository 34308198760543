import React from 'react';

const SecurityMeasuresLink = () => {
  const url = 'https://turbineworkforce.com/security-privacy';

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="btn-link"
      href={url}
    >
      Security Measures
    </a>
  );
};

export default SecurityMeasuresLink;
