// TODO: Move this component to component repo
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import './style.css';

const CardContent = ({
  imageUrl = '',
  title = '',
  mobileTitle = '',
  titleIconComponent,
  externalLink = false,
  subTitle = '',
  size = 'md',
  className = 'bg-white'
}) => {
  const titleSize = size === 'md' ? 'h2' : 'h3';

  return (
    <>
      {imageUrl && (
        <img
          src={imageUrl}
          alt={title}
          className="card-img-top"
        />
      )}
      <div
        className={`card-body ${className} p-3 h-100 w-100`}
      >
        <div
          className={`font-weight-bolder ${titleSize} card-title d-flex align-items-center justify-content-between`}
        >
          <div className='d-flex align-items-center'>
            {titleIconComponent && titleIconComponent()}
            {title && (
              <span className='d-none d-md-block'>
                {title}
              </span>
            )}
            <span className='d-md-none'>
              {mobileTitle || title}
            </span>
          </div>
          {externalLink && (
            <FaExternalLinkAlt className='opacity-50' style={{ marginTop: '-20px' }} size={18} />
          )}
        </div>
        <div className="d-flex w-100">
          <div
            className="card-text"
          >
            {subTitle}
          </div>
        </div>
      </div>
    </>
  );
};

export const DashboardCards = ({
  href,
  onClickRoutePath = '',
  onClick,
  imageUrl = '',
  title = '',
  mobileTitle = '',
  titleIconComponent,
  externalLink = false,
  className = '',
  cardContentClassName = '',
  subTitle = ''
}) => {
  const classNames = useMemo(() => {
    return `DashboardCard card shadow border rounded overflow-hidden w-100 h-100 ${className}`;
  }, [className]);

  if (onClick) {
    return (
      <div
        className={`${classNames} cursor-pointer`}
        onClick={onClick}
        aria-hidden
      >
        <CardContent
          className={cardContentClassName}
          imageUrl={imageUrl}
          title={title}
          mobileTitle={mobileTitle}
          subTitle={subTitle}
          titleIconComponent={titleIconComponent}
        />
      </div>
    );
  }

  if (href) {
    return (
      <a
        href={href}
        className={classNames}
        title={title}
        aria-label={title}
        {...(externalLink ? { target: '_blank', rel: 'noreferrer' } : {})}
      >
        <CardContent
          className={cardContentClassName}
          externalLink={externalLink}
          imageUrl={imageUrl}
          title={title}
          subTitle={subTitle}
          titleIconComponent={titleIconComponent}
        />
      </a>
    );
  }

  return (
    <Link
      className={classNames}
      to={onClickRoutePath}
    >
      <CardContent
        className={cardContentClassName}
        imageUrl={imageUrl}
        title={title}
        subTitle={subTitle}
        titleIconComponent={titleIconComponent}
      />
    </Link>
  );
};
