import { toast } from 'react-toastify';
import { SLACK_REPORT_ISSUE_CHANNEL } from '../constants/api';
import * as twApi from './tw-api';

export const slackReportIssue = ({
  message,
  fromName,
  orgName,
  fromEmail
}) => {
  return new Promise((resolve, reject) => {
    const formattedMessage = `
      ${message}
      --
      From: ${fromName}
      Email: ${fromEmail}
      Organization: ${orgName}
      URL: ${window.location.href}
    `;

    twApi.post(SLACK_REPORT_ISSUE_CHANNEL, '', JSON.stringify({
      message: formattedMessage
    })).then((response) => {
      if (response && response.ok) {
        resolve(response);
      } else {
        toast.error(response.message); // ex: 'Something went wrong, try again'
      }
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};
