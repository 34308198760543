import React from 'react';

const Audio = ({
  id = 'turbine-audio',
  className = '',
  url = '',
  type = ''
}) => {
  return (
    <div className={className}>
      <audio
        id={id}
        controls
      >
        <source src={url} type={type} />
        <track kind="captions" title='Recording' />
      </audio>
    </div>
  );
};

export default Audio;
