function formatOrganization({
  slug,
  url,
  name,
  description,
  billing,
  restrictRegister,
  // Groups
  userGroupIds,
  courseGroupIds,
  // /Groups
  dashboardWidgetIds,
  integrateMicrosoftTeams,
  orgLogo,
  orgIndustry,
  enableCourseCatalog,
  welcomeMessage,
  enableNewEditor
  // integrateSlack,
  // slackToken,
  // slackTeamId,
  // slackJoinLink,
  // tos,
  // register,
  // privacyPolicy,
  // userSeats,
  // expertSeats,
}) {
  const data = {};

  if (enableNewEditor !== undefined) {
    data.enableNewEditor = {
      'en-US': enableNewEditor
    };
  }

  if (welcomeMessage !== undefined) {
    data.welcomeMessage = {
      'en-US': welcomeMessage
    };
  }

  if (name !== undefined) {
    data.name = {
      'en-US': name
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (slug !== undefined) {
    data.slug = {
      'en-US': slug
    };
  }

  if (url !== undefined) {
    data.url = {
      'en-US': url
    };
  }

  if (orgLogo !== undefined) {
    data.orgLogo = {
      'en-US': orgLogo
    };
  }

  if (orgIndustry !== undefined) {
    data.orgIndustry = {
      'en-US': orgIndustry
    };
  }

  if (billing !== undefined) {
    data.billing = {
      'en-US': billing
    };
  }

  if (restrictRegister !== undefined) {
    data.restrictRegister = {
      'en-US': restrictRegister
    };
  }

  // Groups
  if (userGroupIds !== undefined) {
    data.userGroupIds = {
      'en-US': userGroupIds
    };
  }

  if (courseGroupIds !== undefined) {
    data.courseGroupIds = {
      'en-US': courseGroupIds
    };
  }
  // /Groups

  if (dashboardWidgetIds !== undefined) {
    data.dashboardWidgetIds = {
      'en-US': dashboardWidgetIds === null ? undefined : dashboardWidgetIds // BE needs the value to be undefined when there are no dashboardWidgetIds
    };
  }

  if (integrateMicrosoftTeams !== undefined) {
    data.integrateMicrosoftTeams = {
      'en-US': integrateMicrosoftTeams
    };
  }

  if (enableCourseCatalog !== undefined) {
    data.enableCourseCatalog = {
      'en-US': enableCourseCatalog
    };
  }

  return { fields: { ...data } };
}

export default formatOrganization;
