import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  sendEmailVerification,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut
} from '@firebase/auth';
import { getMessaging } from '@firebase/messaging';
import { getAnalytics } from '@firebase/analytics';
import { app, auth } from '../../firebaseConfig';
import { fetchUserByUid } from '../../services/user'; // TODO move to actions

class Firebase {
  constructor() {
    this.analytics = getAnalytics(app);
    this.messaging = getMessaging(app);

    /**
     * GOOGLE
     *
     * https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk
     */
    const googleAuthProvider = new GoogleAuthProvider();
    // googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    this.googleProvider = googleAuthProvider;

    /**
     * FACEBOOK
     *
     * https://firebase.google.com/docs/auth/web/facebook-login#handle_the_sign-in_flow_with_the_firebase_sdk
     */
    const facebookAuthProvider = new FacebookAuthProvider();
    // facebookAuthProvider.addScope('user_birthday');
    this.facebookProvider = facebookAuthProvider;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    signInWithPopup(auth, this.googleProvider);

  // doSignInWithFacebook = () =>
  //   signInWithPopup(auth, this.facebookProvider);

  doSignOut = () => signOut(auth);

  doPasswordReset = (email) => sendPasswordResetEmail(auth, email);

  doSendEmailVerification = () =>
    sendEmailVerification(auth.currentUser, {
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = (password) =>
    updatePassword(auth.currentUser, password);

  // TODO Merge Auth and DB User API

  onAuthUserListener = (next, fallback) =>
    onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        let count = 0;
        let userExistsContentful = setInterval(() => {
          console.log('onAuthStateChanged', count);

          // TODO refactor using recursive pattern
          fetchUserByUid(authUser.uid).then((response) => {
            if (response) {
              // Login
              userExistsContentful = clearInterval(userExistsContentful);

              next({ authUser, currentUser: response });
            } else {
              // New Member Registration (User not in Contentful yet)
              count += 1;

              if (count >= 10) {
                userExistsContentful = clearInterval(userExistsContentful);
                // TODO stop loading
                console.error('CM:Account creation error');
                fallback();
              }
            }
          });
        }, 1000);
      } else {
        fallback();
      }
    });

  // *** User API ***

  // user = uid => this.db.ref(`users/${uid}`);
  //
  // users = () => this.db.ref('users');

  // *** Message API ***

  // message = uid => this.db.ref(`messages/${uid}`);
  //
  // messages = () => this.db.ref('messages');
}

export default Firebase;
