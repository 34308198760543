import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import {
  checkCronUserExists, createCronUser, deleteCronUser
} from '../../../services/cronUsers';
import { canManageLogbookReminders } from '../../../permissions/manage';
import Switch from '../../Switch';

const RemindersCell = ({ row }) => {
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  const cronUsers = useSelector((state) => state.cronUsers);
  // Current User
  const role = currentUser?.role || [];
  // User
  const userId = row?.original?.id || null;
  const orgId = row?.original?.orgId || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;

  const cronUser = useMemo(() => {
    if (!cronUsers.hash) {
      return undefined;
    }

    if (Object.entries(cronUsers.hash || {}).length === 0) {
      return undefined;
    }

    if (cronUsers?.hash[userId] === undefined) {
      return undefined;
    }

    return cronUsers?.hash[userId];
  }, [cronUsers?.hash, userId]);

  // Local State
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(cronUser !== undefined || false);
  const [cronUserId, setCronUserId] = useState(cronUser?.id || null);

  const handleCreateCronUser = async () => {
    setLoading(true);
    const dataToSave = {
      cronId: 'abae15c1-8291-407e-8be1-86168e906286',
      orgId,
      apprenticeshipId,
      userId
    };

    const cronUserExists = await checkCronUserExists(dataToSave);

    if (cronUserExists) {
      toast.warning('Email Reminder already activated, refresh page.', { autoClose: false });
    } else {
      createCronUser(dataToSave).then((response) => {
        setValue(true);
        setCronUserId(response?.id);
      }).catch((error) => {
        toast.error('Something went wrong, try again.');
        console.error(error.message);
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const handleDeleteCronUser = () => {
    setLoading(true);

    deleteCronUser(cronUserId).then(() => {
      setValue(false);
      setCronUserId(null);
    }).catch((error) => {
      toast.error('Something went wrong, try again.');
      console.error(error.message);
    }).finally(() => {
      setLoading(false);
    });
  };

  if (!canManageLogbookReminders(role)) {
    return (
      <div className='d-flex align-items-center'>
        {value ? (
          <FaCheckCircle className='text-success' />
        ) : (
          <FaTimesCircle className="text-error" />
        )}
        <span className='ml-2'>
          Logbook
        </span>
      </div>
    );
  }

  if (!cronUsers.fetched) {
    return (
      <Skeleton
        className='w-100'
        count={1}
        style={{ height: '35px' }}
      />
    );
  }

  return (
    <Switch
      id={`apprenticeshipReminder-${userId}`}
      label={loading ? 'Loading...' : 'Logbook'}
      value={value}
      disabled={loading}
      onChange={() => {
        if (!value) {
          handleCreateCronUser();
        } else {
          handleDeleteCronUser();
        }
      }}
    />
  );
};

export default RemindersCell;
