import findObjectIndexByKey from './findObjectIndexByKey';

function removeObjectFromArray(arr, key, id) {
  const index = findObjectIndexByKey(arr, key, id);
  const arrCopy = arr.slice();

  arrCopy.splice(index, 1);

  return arrCopy;
}

export default removeObjectFromArray;
