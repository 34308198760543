import React from 'react';
import { useSelector } from 'react-redux';
import { FaCheckCircle } from 'react-icons/fa';
import { DUPLICATE_ICON } from '../../../constants/assets';
import { apprenticeshipTypesEnum } from '../../OrgConsole/WorkforceSuite/Apprenticeship/Manage/constants';
import Badge from '../../Badge';

const DuplicateApprenticeshipTitle = ({
  handleContinue,
  handleBack,
  modalTitle = ''
}) => {
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);

  return (
    <div>
      <div className="pt-4 d-flex justify-content-center align-items-center flex-column">
        <div>
          <img
            src={DUPLICATE_ICON}
            alt="Duplicate Training Program"
            height="30"
            style={{ height: '30px' }}
          />
        </div>
        <h5 className="py-2 mb-0">{modalTitle}</h5>
      </div>
      <div className="card-body pb-4">
        <div className="bg-light p-4 mx-4 shadow border">
          <div className="mb-3">
            <div className="border bg-white px-3 py-2 d-flex align-items-center justify-content-between">
              <div>
                <div className="font-weight-bold">{apprenticeship?.title}</div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 mb-3">
              <div className="border bg-white px-3 py-2 d-flex align-items-center justify-content-between">
                <div>
                  <div className="font-weight-bold mb-1">
                    OJT Hours ({apprenticeship?.ojtHours})
                  </div>
                </div>
                <div>
                  <FaCheckCircle
                    className="text-keppel"
                    size={18}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 mb-3">
              <div className="border bg-white px-3 py-2 d-flex align-items-center justify-content-between">
                <div className="font-weight-bold mb-1">
                  RIT Hours ({apprenticeship?.instructionHours})
                </div>
                <div>
                  <FaCheckCircle
                    className="text-keppel"
                    size={18}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="text-left mb-1">Program Type</div>
            <div className="border bg-white px-3 py-2 d-flex align-items-center justify-content-between">
              <div>
                <div className="font-weight-bold mb-1">
                  {apprenticeshipTypesEnum[apprenticeship?.type]}
                </div>
              </div>
              <div>
                <FaCheckCircle
                  className="text-keppel"
                  size={18}
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="text-left mb-1">Occupation</div>
            <div className="border bg-white px-3 py-2 d-flex align-items-center justify-content-between">
              <div>
                <div className="font-weight-bold mb-1">
                  {apprenticeship?.occupationName}
                </div>
                <ul className="m-0">
                  <li className="ml-2">
                    <span>RAPIDS Code: {apprenticeship?.rapidsCode}</span>
                  </li>
                  <li className="ml-2">
                    <span>
                      O*NET Code: {apprenticeship?.occupationONetCode}
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <FaCheckCircle
                  className="text-keppel"
                  size={18}
                />
              </div>
            </div>
          </div>

          <div className="mb-3">
            <div className="text-left mb-1">Competency Model</div>
            <div className="border bg-white px-3 py-2 d-flex align-items-center justify-content-between">
              <div>
                <div className="font-weight-bold mb-1 d-flex align-items-center">
                  <span>Competencies</span>
                  <Badge className="ml-1">
                    {Object.keys(apprenticeship?.competencies || {}).length}
                  </Badge>
                </div>
              </div>
              <div>
                <FaCheckCircle
                  className="text-keppel"
                  size={18}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer d-flex align-items-center justify-content-between">
        {handleBack && (
          <button
            type="button"
            className="btn btn-link btn-md"
            onClick={handleBack}
          >
            Back
          </button>
        )}
        <div className="d-flex align-items-center">
          <div className="mr-3">
            <strong>Next Step:</strong> Review & Edit
          </div>
          <button
            type="button"
            className="btn btn-md btn-primary"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default DuplicateApprenticeshipTitle;
