import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchSkills } from '../services/skills';
import {
  SET_ENTRY_ORG_ID,
  SET_ORG_SKILLS,
  RESET_ORG_SKILLS
} from './types';

export const getSkills = ({
  orgId,
  name = '',
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = 'name'
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchSkills({
        orgId,
        page,
        limit,
        ...(name ? { name } : {}),
        order
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          let skillsHash = null;

          if (Array.isArray(list) && list.length > 0) {
            skillsHash = list.reduce((acc, curr) => {
              const result = {
                ...acc
              };
              result[curr?.id] = curr;
              return result;
            }, {});
          }

          dispatch({
            type: SET_ORG_SKILLS,
            list,
            hash: skillsHash,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetSkills = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ORG_SKILLS });
  };
};
