import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import Collapse from '@apprentage/components/dist/components/Collapse';
import { FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { getApprenticeship, resetApprenticeship } from '../../../../../actions/Apprenticeships';
import {
  getEmployer, getWageSchedules, resetEmployer, resetWageSchedules
} from '../../../../../actions/Employers';
import { hasRequiredWorkforceInformation } from '../../../../../services/employers';
import {
  getEmployerAgreements, resetEmployerAgreement, resetEmployerAgreements
} from '../../../../../actions/EmployerAgreements';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import Loading from '../../../../Loading';
import InfoBlock from '../../../../Location/InfoBlock';
import ContactBlock from '../../../../Location/ContactBlock';
import NoEmployerProfileNextStep from './NoEmployerProfileNextStep';
import InvitesBlock from '../../../../Location/InvitesBlock';
import WorkforceInformationChecklist from './WorkforceInformationChecklist';
import EmployeesBlock from './EmployeesBlock';
import JourneyworkersBlock from './JourneyworkersBlock';
import IdentificationBlock from './IdentificationBlock';
import EmployerTabs from './EmployerTabs';

const ApprenticeshipEmployer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employer = useSelector((state) => state.employer);
  const employerProfile = useSelector((state) => state.employerProfile);
  // Params
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipEmployerId = params?.apprenticeshipEmployerId || null;
  // Employer Profile
  const employerProfileId = employerProfile?.id || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  const requiredWorkforceInformation = useMemo(() => {
    if (employerProfileId) {
      return hasRequiredWorkforceInformation(employerProfile);
    }

    return false;
  }, [employerProfileId]);

  useEffect(() => {
    dispatch(resetWageSchedules());
    dispatch(resetEmployerAgreements());

    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));

      if (apprenticeshipEmployerId) {
        dispatch(getEmployer(apprenticeshipEmployerId));
      }
    }

    return function cleanup() {
      const { href } = window.location;

      dispatch(resetEmployer());
      dispatch(resetEmployerAgreement());

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, []);

  useEffect(() => {
    if (employerProfileId && apprenticeshipId) {
      dispatch(getEmployerAgreements({
        employerProfileId,
        apprenticeshipId
      }));

      dispatch(getWageSchedules({
        employerProfileId,
        apprenticeshipId
      }));
    }
  }, [employerProfileId]);

  if (apprenticeshipId && !apprenticeship.id) {
    return (
      <Loading text="Loading Training Program..." />
    );
  }

  if (apprenticeshipEmployerId && !employer.id) {
    return (
      <Loading text="Loading Employer..." />
    );
  }

  if (employerProfileId && !employerProfile.id) {
    return (
      <Loading text="Loading Workforce Info..." />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            iconComponent={() => <FaSortAmountUp size={20} />}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="employers" />

          <div
            className="bg-white shadow p-3 overflow-content border-left border-right border-bottom"
          >
            <OrgConsoleSubheader
              pageTitle={employer.name}
              route={`${apprenticeshipRoute}/employers`}
              locationImage={employer.locationImage}
            />

            <EmployerTabs active="info" />

            <div className='border-left border-right border-bottom p-3'>

              {apprenticeshipId && (
                <div className='row'>
                  <div className='col-sm-12 col-md-6 d-flex align-items-stretch'>
                    <InfoBlock
                      showMenu
                      data={employer}
                      className="card mt-3 w-100"
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 d-flex align-items-stretch'>
                    {employerProfileId ? (
                      <ContactBlock
                        data={employer}
                      />
                    ) : (
                      <NoEmployerProfileNextStep
                        data={employer}
                      />
                    )}
                  </div>
                </div>
              )}

              {requiredWorkforceInformation && (
                <Collapse
                  title="Workforce Information"
                  id="employerWorkforceInformation"
                  className="mt-3"
                  p={3}
                // ariaExpanded={!checklistComplete}
                >

                  <div
                    className='row'
                  >
                    <div
                      className='col-sm-12 col-md-4 d-flex align-items-stretch'
                    >
                      <IdentificationBlock
                        showMenu
                      />
                    </div>
                    <div
                      className='col-sm-12 col-md-4 d-flex align-items-stretch'
                    >
                      <EmployeesBlock
                        showMenu
                      />
                    </div>
                    <div
                      className='col-sm-12 col-md-4 d-flex align-items-stretch'
                    >
                      <JourneyworkersBlock
                        showMenu
                      />
                    </div>
                  </div>
                </Collapse>
              )}

            </div>
            <WorkforceInformationChecklist
              data={employer}
            />

            {employerProfileId && apprenticeshipId && (
              <InvitesBlock
                data={employer}
              />
            )}
          </div>
        </div>
      </div>

    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipEmployer);
