import {
  RESET_PROJECT, RESET_PROJECT_FILES, SET_PROJECT, SET_PROJECT_FILES
} from '../../actions/types';
import projectState from '../initialState/projectState';

const initialState = {
  ...projectState,
  fetched: false,
  cdnFiles: null
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT:
      return {
        ...state,
        ...action.project,
        cdnFiles: null,
        fetched: true
      };
    case SET_PROJECT_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles || null
      };
    case RESET_PROJECT_FILES:
      return {
        ...state,
        cdnFiles: initialState.cdnFiles
      };
    case RESET_PROJECT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
