import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import ManageButton from '../../btns/ManageButton';
import Badge from '../../Badge';
import CohortContainer from './CohortContainer';
import isPublished from '../../../utils/isPublished';

const CeuSettings = () => {
  // redux
  const course = useSelector((state) => state.course);
  const currentCohort = useSelector((state) => state.currentCohort);
  // misc
  const classId = pathOr(null, ['id'], course);
  const eUnitTracking = pathOr(null, ['eUnitTracking', 'fields'], currentCohort); // TODO cohort flatten

  if (!currentCohort.id) {
    return (
      <CohortContainer title="Settings" />
    );
  }

  if (!isPublished(eUnitTracking)) {
    return false;
  }

  const {
    title,
    code,
    semesterCode
  } = eUnitTracking;

  return (
    <CohortContainer title="Settings">
      <div className="p-3">
        <div className="bd-callout">
          <h5>
            <b>Education Unit</b>
          </h5>

          <div className="form-group mb-3">
            <label className="mb-1">
              <b>Tracking Settings Title:</b> <Badge>{title}</Badge>
            </label>
            <div className="text-muted medium mb-2">
              For internal use only.
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-1">
              <b>Code:</b> <Badge>{code}</Badge>
            </label>
            <div className="text-muted medium mb-2">
              Course Number, Class code, etc.
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-1">
              <b>Semester Code:</b> <Badge>{semesterCode}</Badge>
            </label>
            <div className="text-muted medium mb-2">
              Current semester code.
            </div>
          </div>

          <ManageButton
            className="btn btn-sm btn-primary"
            classId={classId}
            manageType="edit"
            buttonText="Edit Settings"
            entryId={currentCohort.eUnitTracking.sys.id}
            contentType="eUnitTracking"
          />
        </div>
      </div>
    </CohortContainer>
  );
};

const mapStateToProps = ({
  user,
  course,
  currentCohort
}) => ({
  user,
  course,
  currentCohort
});
const condition = (user) => !!user.uid;

export default compose(
  connect(mapStateToProps),
  withAuthorization(condition),
  withConsole
)(CeuSettings);
