import {
  SET_INSTRUCTION_PROVIDER,
  RESET_INSTRUCTION_PROVIDER
} from '../../actions/types';
import instructionProviderState from '../initialState/instructionProviderState';

const initialState = {
  ...instructionProviderState,
  fetched: false
};

export default function instructionProvider(state = initialState, action) {
  switch (action.type) {
    case SET_INSTRUCTION_PROVIDER:
      return {
        ...state,
        ...action.instructionProvider,
        fetched: true
      };
    case RESET_INSTRUCTION_PROVIDER:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
