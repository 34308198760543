import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ICON_ELLIPSIS,
  ICON_PENCIL
} from '@apprentage/components/dist/constants/icons';
import { ORG_CONSOLE_PROJECT_BASE } from '../../constants/routes';

const DotMenu = () => {
  // Redux
  const project = useSelector((state) => state.project);

  // Misc
  const projectId = project?.id || '';

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Manage Menu"
          title="Manage Menu"
        >
          <i className={ICON_ELLIPSIS} />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          <Link
            className="dropdown-item p-2"
            to={`${ORG_CONSOLE_PROJECT_BASE}/manage/${projectId}`}
          >
            <i className={`${ICON_PENCIL} mr-1`} /> Edit
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
