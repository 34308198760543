import isPublished from '../utils/isPublished';

export const getClassId = ({ classId, content }) => {
  if (classId) {
    if (content && content.sys && content.sys.contentType) {
      if (content.sys.contentType.sys.id.includes('class')) {
        // Currently editing Class, user
        // clicked edit on another Class, ex: prerequisite
        return content.sys.id;
      }
    }

    return classId;
  }

  return undefined;
};

export const getTopicId = ({ topicId, content }) => {
  if (topicId) {
    if (isPublished(content)) {
      if (content.contentType.includes('topic')) {
        // Currently editing Topic, user
        // clicked edit on another Topic, ex: prerequisite
        return content.id;
      }
    }

    return topicId;
  }

  if (isPublished(content)) {
    // TODO revisit better way to handle editing references from
    // root of class that are nested in topics, like eUnitTracking / eUnits
    // W/o this, topicId is set when not in a topic
    if (content.contentType.includes('eUnit')) {
      return undefined;
    }

    if (content.contentType.includes('class')) {
      return undefined;
    }

    return content.id;
  }

  return undefined;
};

export const getEntryId = ({ entryId, content }) => {
  if (entryId) {
    if (isPublished(content)) {
      if (content.contentType.includes('class')) {
        // Currently editing Class, user
        // clicked edit on another Class, ex: prerequisite
        return undefined;
      }
    }

    return entryId;
  }

  if (isPublished(content)) {
    // Currently editing Class, user
    // clicked edit on another Class, ex: prerequisite
    if (content.contentType.includes('class')) {
      return undefined;
    }
  }

  return undefined;
};
