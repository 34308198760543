import exportFromJSON from 'export-from-json';
import sanitize from 'sanitize-filename';
import { courseCompleteCertificate } from '../../../services/user';
import { mdySimple } from '../../../utils/date';

const generateFileName = ({ course }) => {
  let fileName = 'Turbine Export - ';

  if (course && course?.id) {
    fileName += `${course.title} - `;
  }

  fileName += `Users - ${mdySimple(new Date(), '-')}`;

  fileName = sanitize(fileName);

  return fileName;
};

const exportData = ({
  users = [],
  course
}) => {
  const data = users.length && users.map((user, i) => {
    const completionCertificateData = courseCompleteCertificate({ course, user });
    const dataToExport = {
      ' ': i + 1,
      'User Name': user.name,
      'User ID ': user.id,
      'User Status ': user.membership.includes('inactive') ? 'Inactive' : 'Active'
    };

    if (course && course?.id) {
      dataToExport['Course Title'] = course.title;

      if (course?.completionCertificate && completionCertificateData) {
        const { id, issueDate, acceptedDate } = completionCertificateData;

        dataToExport['Cert. Issued'] = issueDate ? mdySimple(issueDate) : ' ';
        dataToExport['Cert. Accepted'] = acceptedDate ? mdySimple(acceptedDate) : '';
        dataToExport['Cert. ID'] = id || '';
      }
    }

    return dataToExport;
  });

  const fileName = generateFileName({ course });

  const exportType = exportFromJSON.types.csv;

  exportFromJSON({ data, fileName, exportType });
};

export default exportData;
