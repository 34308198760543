import React from 'react';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useFlags } from 'flagsmith/react';
import { getFirebaseEntry } from '../../../../../services/firebaseProxy';
import { setCurrentModal } from '../../../../../actions/Modals';
import { showFeatureFlagNoticeModal } from '../../../../../actions/FeatureFlags';
import {
  FLAG_RESOURCE_TAGS_READONLY,
  NOTICE_RESOURCE_TAGS_READONLY_TITLE
} from '../../../../../constants/flagsmith';

const EditCell = ({ value: id, row }) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_RESOURCE_TAGS_READONLY]);
  // Row
  const label = row?.original?.label || '';

  const onClickEdit = () => {
    if (flags?.resource_tags_readonly?.enabled && flags?.resource_tags_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCE_TAGS_READONLY_TITLE
      }));
      return;
    }

    getFirebaseEntry({
      id,
      collection: 'resourceGroupTags'
    }).then((resourceGroupTag) => {
      dispatch(setCurrentModal({
        key: 'manageResourceGroupTag',
        data: {
          editMode: true,
          ...resourceGroupTag,
          id
        }
      }));
    }).catch((error) => {
      console.error('get resourceGroupTags', error);
    });
  };

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white">
            <h6 className="m-0 text-nowrap">
              {label}
            </h6>
          </div>
          <button
            className="dropdown-item py-2"
            type="button"
            title={`Edit ${label}`}
            onClick={onClickEdit}
          >
            <span className='d-flex align-items-center'>
              <FaCog />
              <span className='ml-2'>
                Settings
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCell;
