import styled from 'styled-components';

export const QuizDescriptionContainer = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;

export const QuestionTitleContainer = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;
