import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { ICON_TRIANGLE_BANG } from '@apprentage/constants';
import { FaMarkdown } from 'react-icons/fa';
import Showdown from 'showdown';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import { uploadFile } from '../../../services/aws';
import { TINYMCE_KEY } from '../../../constants/globals';
import { saveCdnFiles } from '../../../actions/Entry';
import FileUploader from '../FileUploader';
import Modal from '../../Modal';
import Alert from '../../Alert';
import { coerceToResourceName, createResource } from '../../../services/resources';
import { getResourcesByParentId } from '../../../actions/Resources';
import { SUPABASE_CONTENT_TYPES } from '../../../constants/api';

const RenderTinyMceEditor = ({
  id,
  className = '',
  title,
  subtitle,
  maxCharCount = 50000,
  // onImageUpload,
  // onImagePreview,
  // onImageUploadBefore,
  defaultValue = '',
  contentId,
  contentType,
  editorRef,
  importMarkdownButton = true,
  uploadPdfButton = true,
  embedMediaButton = true,
  uploadImgButton = true,
  height = 500,
  onChange,
  onExceedMaxChars,
  autoFocus = false,
  forcedRootBlock = 'p',
  required
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  const nameConfig = {
    fixCamelCase: true,
    fixHyphens: true,
    fixUnderScores: true,
    fixPlus: true
  };
  // Local State
  const [chooseFileModal, setChooseFileModal] = useState(false);
  const [importMarkdownModal, setImportMarkdownModal] = useState(false);
  const [markdown, setMarkdown] = useState('');
  const [maxCharsExceeded, setMaxCharsExceeded] = useState(false);
  const [charCount, setCharCount] = useState(defaultValue && defaultValue?.length ? defaultValue.length : 0);

  const handleImageUploadBefore = (file, success, failure) => { // progress
    // docs have first arg as blob, but its an abstraction
    // https://stackoverflow.com/questions/41009605/tinymce-editor-with-react-cannot-access-local-files
    // https://www.tiny.cloud/docs/configure/file-image-upload/#file_picker_callback
    // uploadHandler is a function
    const blob = file.blob();
    console.log(blob);

    toast.info('Uploading image...', { toastId: 'uploadingImageInfo' });

    // // Legacy
    // // TODO use onImageUploadBefore
    // if (onImagePreview && typeof onImagePreview === 'function') {
    //   // TODO may need to pass files[0]
    //   onImagePreview(blob);
    // }
    // // // /Legacy

    // if (onImageUploadBefore && typeof onImageUploadBefore === 'function') {
    //   onImageUploadBefore(blob);
    // }

    uploadFile({
      prefix: `${AWS_USER_PREFIX}/${orgId}`,
      file: blob,
      orgId
    }).then((response) => {
      const fileObj = {
        ...response // id, url
      };

      if (blob.name) fileObj.name = blob.name;
      if (blob.type) fileObj.type = blob.type;
      if (blob.size) fileObj.size = blob.size;

      toast.dismiss('uploadingImageInfo');
      toast.info('Optimizing image...', { toastId: 'optimizingImageInfo' });

      // Add image in editor
      success(fileObj.url);

      if (SUPABASE_CONTENT_TYPES.includes(contentType)) {
        // Supabase
        const parentType = contentType;
        const parentId = contentId;
        const dataToCreate = {
          // File Info
          id: fileObj?.id,
          url: fileObj?.url,
          size: fileObj?.size,
          type: fileObj.type,
          name: coerceToResourceName({ str: fileObj?.name, ...nameConfig }),
          // /File Info
          orgId,
          userId: currentUser?.id,
          userName: currentUser?.name,
          parentType,
          parentId
        };

        createResource(dataToCreate).then(() => {
          // Get File Resources
          toast.dismiss('optimizingImageInfo');
          toast.success('Image uploaded');

          dispatch(getResourcesByParentId(contentId, parentType));
        });
      } else {
        // Contentful
        // Update entity's integration.cdnFiles by contentType (course, topic, entry (material, challenge, quiz, ...)
        dispatch(saveCdnFiles({
          data: { fileObj },
          contentId,
          contentType
        })).then(() => {
          toast.success('Image uploaded');
          // if (onImageUpload && typeof onImageUpload === 'function') {
          //   onImageUpload(fileObj);
          // }
        });
      }
    }).catch((error) => {
      failure(`Image upload failed ${error}`);
      console.error(error);
    });
  };

  const getCharacterCount = () => {
    let content = '';
    let count = 0;
    if (editorRef && editorRef.current) {
      content = editorRef.current.getContent();

      if (content) {
        count = content.length;
      }
    }

    setCharCount(count);

    if (!maxCharsExceeded && count > maxCharCount) {
      setMaxCharsExceeded(true);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(true);
      }
    }

    if (maxCharsExceeded && count <= maxCharCount) {
      setMaxCharsExceeded(false);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(false);
      }
    }

    return charCount;
  };

  const handleInsertUploadedFile = (fileObj) => {
    const { url, name } = fileObj;

    if (editorRef && editorRef.current) {
      editorRef.current.insertContent(`
        <div>
          <p>Download/View: <a target="_blank" href="${url}">${name}</a></p>
          <iframe src="${url}" style="width: 100%;" width="100%" height="2200"></iframe>
        </div>
      `); // HTML
      setChooseFileModal(false);
    } else {
      setChooseFileModal(false);
    }

    // Update entity's integration.cdnFiles (class, topic, entry (material, challenge, quiz, etc)
    dispatch(saveCdnFiles({
      data: { fileObj },
      contentId,
      contentType
    })).then(() => {
      toast.success('File uploaded!');
    });
  };

  const convertMarkdownToHtml = () => {
    const converter = new Showdown.Converter();

    const bodyHTML = converter.makeHtml(markdown);

    editorRef.current.execCommand('mceSetContent', false, bodyHTML);
    setImportMarkdownModal(false);
  };

  let uploadPdfStr = '';
  let importMarkdownStr = '';
  let embedMediaStr = '';
  let embedImgStr = '';
  const plugins = [
    'autolink',
    'autoresize',
    'codesample',
    'link',
    'lists',
    // 'powerpaste',
    'table',
    'codesample'
    // 'quickbars'
  ];

  // Image Upload enabled
  if (uploadImgButton) {
    embedImgStr = 'image';
    plugins.push('image');
  }

  // Embed Media enabled
  if (embedMediaButton) {
    embedMediaStr = 'media';
    plugins.push('media');
  }

  // Upload PDF enabled
  if (uploadPdfButton) {
    uploadPdfStr = 'uploadPdf';
  }

  // Import Markdown enabled
  if (importMarkdownButton) {
    importMarkdownStr = 'importMarkdown';
  }

  const init = {
    toolbar: `'removeformat formatselect | bold italic strikethrough | alignleft aligncenter alignright | bullist numlist | link | ' ${embedImgStr} ${embedMediaStr} ${uploadPdfStr} ${importMarkdownStr}`,
    // undo redo |
    toolbar_sticky: true,
    menubar: 'insert format table tools',
    contextmenu: false,
    statusbar: false,
    auto_focus: autoFocus,
    relative_urls: false,
    forced_root_block: forcedRootBlock,
    height,
    plugins,
    mobile: {
      menubar: 'edit insert format',
      toolbar: `'bold italic | bullist numlist | link | ' ${embedImgStr}`,
      selection_toolbar: false,
      inline: false
    },
    // link_assume_external_targets: true,
    link_class_list: [
      { title: 'None', value: '' },
      { title: 'Primary Button', value: 'btn btn-sm btn-primary' },
      { title: 'Secondary Button', value: 'btn btn-sm btn-secondary' },
      { title: 'Info Button', value: 'btn btn-sm btn-info' },
      { title: 'Warning Button', value: 'btn btn-sm btn-warning' },
      { title: 'Danger Button', value: 'btn btn-sm btn-danger' },
      { title: 'Link Button', value: 'btn btn-sm btn-link' }
    ],
    convert_newlines_to_brs: true,
    powerpaste_word_import: 'clean',
    powerpaste_html_import: 'clean',
    image_caption: true,
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    image_advtab: true,
    images_reuse_filename: true,
    images_upload_handler: handleImageUploadBefore,
    // quickbars_insert_toolbar: 'quicktable image media codesample',
    // quickbars_selection_toolbar: 'bold italic underline | formatselect | bullist numlist | blockquote quicklink',
    setup: (editor) => {
      if (importMarkdownButton) {
        editor.ui.registry.addButton('importMarkdown', {
          icon: 'non-breaking',
          tooltip: 'Import Markdown',
          onAction(_) { // eslint-disable-line no-unused-vars
            setImportMarkdownModal(true);
          }
        });
      }

      if (uploadPdfButton) {
        editor.ui.registry.addButton('uploadPdf', {
          icon: 'browse',
          tooltip: 'Upload/Embed PDF',
          onAction(_) { // eslint-disable-line no-unused-vars
            setChooseFileModal(true);
          }
        });
      }
    }
  };

  return (
    <>
      <div
        id={id}
        className={`position-relative ${className}`}
        style={{
          zIndex: 0
        }}
      >
        {title && (
          <label className="mb-1 text-capitalize">
            <b>{title}:</b> {required && (<span className="text-danger">*</span>)}
          </label>
        )}
        {subtitle && (
          <div className="text-muted medium mb-2">
            {subtitle}
          </div>
        )}

        <Editor
          apiKey={TINYMCE_KEY}
          onInit={(evt, editor) => editorRef.current = editor} // eslint-disable-line no-return-assign
          initialValue={defaultValue}
          init={init}
          onChange={() => {
            getCharacterCount();

            if (onChange) {
              onChange();
            }
          }}
          onKeyUp={getCharacterCount}
        />

        <div className="d-flex justify-content-end mb-2">
          <div>
            <strong>{maxCharCount - charCount}</strong> characters remaining
          </div>
        </div>

        {charCount > maxCharCount && (
          <Alert
            type="danger"
            icon={`${ICON_TRIANGLE_BANG} text-danger`}
            className="m-0 mb-5 w-100 border"
          >
            <div>
              This field exceeds the maximum number of characters ({maxCharCount}) allowed.
            </div>
          </Alert>
        )}
      </div>

      {importMarkdownModal && (
        <Modal
          visible={importMarkdownModal}
          cssClassName="turbine-modal--style-card turbine-modal--chooseFile"
          theme="dark"
          close={() => {
            setImportMarkdownModal(false);
            setMarkdown('');
          }}
        >
          <div className="card">
            <div className="card-header p-3 bg-dark text-white">
              <h6 className="m-0 font-weight-bold ml-2">
                <span className='d-flex align-items-center'>
                  <FaMarkdown size={22} />
                  <span className='ml-2'>
                    Import Markdown
                  </span>
                </span>
              </h6>
            </div>
            <div className='card-body'>
              <div className='font-weight-bold mb-2'>
                Markdown
              </div>
              <textarea
                type="text"
                className="form-control form-control-lg w-100"
                style={{
                  minHeight: '200px'
                }}
                placeholder='Paste Markdown...'
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setMarkdown(value);
                }}
              />
              <div className="mt-2 text-muted d-flex justify-content-end">
                <a
                  href='https://turbine-content.s3.us-east-2.amazonaws.com/markdown-cheatsheet-online.pdf'
                  target='_blank'
                  rel="noreferrer"
                >
                  <span className='d-flex align-items-center'>
                    <FaMarkdown size={18} className='d-inline mr-1' />
                    <span>
                      Markdown Guide
                    </span>
                  </span>
                </a>
              </div>
            </div>
            <div className='card-footer'>
              <button
                type="button"
                className="btn btn-sm btn-primary mt-3"
                onClick={convertMarkdownToHtml}
              >
                Convert Markdown to HTML
              </button>
            </div>
          </div>
        </Modal>
      )}

      {chooseFileModal && (
        <Modal
          visible={chooseFileModal}
          cssClassName="turbine-modal--style-card turbine-modal--chooseFile"
          theme="dark"
          close={() => {
            setChooseFileModal(false);
          }}
        >
          <div className="card">
            <div className="card-header p-3 bg-dark text-white">
              <h6 className="m-0 font-weight-bold ml-2">Choose File</h6>
            </div>
            <div className='card-body'>
              <FileUploader
                onFileUploadCallback={handleInsertUploadedFile}
                orgId={orgId}
              />
              <div className="mt-2 text-muted">
                Supported File types: <strong>.pdf</strong>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RenderTinyMceEditor;
