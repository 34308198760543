function imageOrientation(src) {
  let orientation;
  const img = new Image();
  img.src = src;

  if (img.naturalWidth > img.naturalHeight) {
    orientation = 'landscape';
  } else if (img.naturalWidth < img.naturalHeight) {
    orientation = 'portrait';
  } else {
    orientation = 'square';
  }

  return orientation;
}

export default imageOrientation;
