import { toast } from 'react-toastify';
import { fetchSupabaseEntries, fetchSupabaseEntry, updateSupabaseEntry } from './supabaseProxy';
import formatData from './formatData';

export const fetchUserByUid = async (uid = '') => {
  if (!uid) {
    throw new Error('UID is required!');
  }

  try {
    const response = await fetchSupabaseEntries({ 'f.uid[eq]': uid }, 'users');
    const items = response?.items;
    const user = Array.isArray(items) && items[0] !== undefined ? items[0] : null;
    return user;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * Update User in Supabase
 *
 * @param {Object} data
 * @param {String} userId
 * @returns { id: '', data: '' }
 */
export const updateUser = async (data, userId) => {
  try {
    const entry = await fetchSupabaseEntry({
      table: 'users',
      id: userId
    });

    try {
      const response = await updateSupabaseEntry({
        id: userId,
        data: formatData(data, 'user', entry),
        table: 'users'
      });

      return response;
    } catch (updateError) {
      toast.error('Something went wrong updating user.');
      // TODO heap
      console.error(updateError);
    }
  } catch (fetchError) {
    toast.error('Something went wrong fetching user.');
    // TODO heap
    console.error(fetchError);
  }
};

// /**
//  * Update User in Supabase
//  *
//  * TODO
//  * - review how attendance is saved
//  *
//  * @param {Object} data
//  * @param {String} userId
//  * @returns { id: '', data: '' }
//  */
// export const updateUser = async (data, userId) => {
//   const {
//     role, // String
//     membership, // String
//     cohortId, // String
//     cohortIds, // String[]
//     classId, // String
//     classIds, // String[]
//     courseIds, // String[]
//     completedClassId, // String
//     completedTopicId, // String
//     certificateId, // String
//     acceptedCertificateCourseId, // String
//     ...remainingData
//   } = data;

//   try {
//     const entry = await fetchSupabaseEntry({
//       table: 'users',
//       id: userId
//     });

//     const dataToSave = {
//       ...remainingData
//     };

//     if (role) {
//       dataToSave.role = [role];
//     }

//     if (membership) {
//       dataToSave.membership = [membership];
//     }

//     // COHORTS
//     if (cohortId) {
//       dataToSave.cohortIds = Array.isArray(entry?.cohortIds) ? entry?.cohortIds : [];
//       // De-dupe
//       if (!dataToSave.cohortIds.includes(cohortId)) {
//         dataToSave.cohortIds.push(cohortId);
//       }
//     }

//     if (cohortIds !== undefined) {
//       dataToSave.cohortIds = cohortIds;
//     }

//     // Course Enrollment
//     if (classId || classIds) {
//       dataToSave.courseIds = Array.isArray(entry?.courseIds) ? entry?.courseIds : [];

//       if (classId) {
//         // De-dupe
//         if (!dataToSave.courseIds.includes(classId)) {
//           dataToSave.courseIds.push(classId);
//         }
//       }

//       // Membership - upgrade (Add multiple classes)
//       if (Array.isArray(classIds)) {
//         // De-dupe
//         classIds.forEach((id) => {
//           if (!dataToSave.courseIds.includes(id)) {
//             dataToSave.courseIds.push(id);
//           }
//         });
//       }
//     }

//     if (courseIds !== undefined) {
//       dataToSave.courseIds = courseIds;
//     }

//     // Assessment @ Class Level (handle completion)
//     if (completedClassId) {
//       dataToSave.completedCourseIds = Array.isArray(entry?.completedCourseIds) ? entry?.completedCourseIds : [];
//       // De-dupe
//       if (!dataToSave.completedCourseIds.includes(completedClassId)) {
//         dataToSave.completedCourseIds.push(completedClassId);
//       }
//     }

//     // Assessment @ Topic Level (handle completion)
//     if (completedTopicId) {
//       dataToSave.completedCourseTopicIds = Array.isArray(entry?.completedCourseTopicIds) ? entry?.completedCourseTopicIds : [];
//       // De-dupe
//       if (!dataToSave.completedCourseTopicIds.includes(completedTopicId)) {
//         dataToSave.completedCourseTopicIds.push(completedTopicId);
//       }
//     }

//     if (certificateId && acceptedCertificateCourseId) {
//       dataToSave.integration = entry?.integration ? entry?.integration : {};

//       const uniqueCertificateData = dataToSave?.integration?.uniqueCertificateData;
//       const uniqueCertificate = uniqueCertificateData[certificateId];

//       if (uniqueCertificateData && uniqueCertificate) {
//         if (!uniqueCertificate.acceptedDate) {
//           dataToSave.integration.uniqueCertificateData[certificateId].acceptedDate = dateTimestamp();
//         }

//         // Save course ID so admins can filter users by accepted certificates
//         dataToSave.acceptedCertificateCourseIds = Array.isArray(entry?.acceptedCertificateCourseIds) ? entry?.acceptedCertificateCourseIds : [];

//         // Dedupe
//         if (!dataToSave.acceptedCertificateCourseIds.includes(acceptedCertificateCourseId)) {
//           dataToSave.acceptedCertificateCourseIds.push(acceptedCertificateCourseId);
//         }
//       }
//     }

//     try {
//       await updateSupabaseEntry({
//         id: userId,
//         data: dataToSave,
//         table: 'users'
//       });
//       return dataToSave;
//     } catch (updateError) {
//       toast.error('Something went wrong updating user.');
//       // TODO heap
//       console.error(updateError);
//     }
//   } catch (fetchError) {
//     toast.error('Something went wrong fetching user.');
//     // TODO heap
//     console.error(fetchError);
//   }
// };

export const courseCompleteCertificate = ({ user, course }) => {
  // const uniqueCertificateIdSpec = course?.integration?.uniqueCertificateIdSpec || null;
  const uniqueCertificateData = user?.integration?.uniqueCertificateData || null;

  // if (uniqueCertificateIdSpec) { // && uniqueCertificateIdSpec.oneTimeUse
  if (uniqueCertificateData) {
    const uniqueCertificates = Object.entries(uniqueCertificateData).map(
      ([key, certInfo]) => ({
        id: key,
        ...certInfo
      })
    );

    if (Array.isArray(uniqueCertificates) && uniqueCertificates.length > 0) {
      const uniqueCertificate = uniqueCertificates.find(
        (c) => c.classId === course.id
      );

      if (uniqueCertificate) {
        return uniqueCertificate;
      }
    }
  }
  // }

  return false;
};
