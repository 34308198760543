import pluralize from 'pluralize';
import React from 'react';
import { FaFile, FaFolder, FaFolderOpen } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

const FolderInfo = ({
  className = '',
  resourceGroupTitle,
  hasFiles,
  hasEmbeddedFiles,
  embeddedFiles
}) => {
  // Redux
  const resourceGroup = useSelector((state) => state.resourceGroup);

  return (
    <>
      <div
        className={className}
      >
        <span
          className="h5 m-0 d-inline-flex align-items-center Resources-title-container p-2"
        >
          <FaFolderOpen size={20} className='d-none d-sm-block mr-2' />
          <span className='Resources-title'>
            {resourceGroupTitle}
          </span>
          {Array.isArray(resourceGroup?.list) && (
            <span
              className="h6 m-0 ml-2 d-none d-sm-block"
              // data-tooltip-content={`${resourceGroup?.list.length} Folders`}
              // data-tooltip-id="FolderInfo"
              data-tip={pluralize('Folder', resourceGroup?.list.length, true)}
              data-for="FolderInfo"
            >
              <span className="badge bg-white border d-flex align-items-center">
                <FaFolder className='text-primary' />
                <span className='ml-1'>
                  {resourceGroup?.list.length}
                </span>
              </span>
            </span>
          )}

          {hasFiles && resourceGroup?.cdnFiles.length > 0 && resourceGroup?.cdnFiles.length !== embeddedFiles.length && (
            <span
              className="h6 m-0 ml-2 d-none d-sm-block"
              // data-tooltip-content={`${resourceGroup?.cdnFiles.length} Files`}
              // data-tooltip-id="FolderInfo"
              data-tip={pluralize('File', resourceGroup?.cdnFiles.length, true)}
              data-for="FolderInfo"
            >
              <span className="badge bg-white border d-flex align-items-center">
                <FaFile size={10} />
                <span className='ml-1'>
                  {resourceGroup?.cdnFiles.length}
                </span>
              </span>
            </span>
          )}

          {hasEmbeddedFiles && (
            <span
              className="h6 m-0 ml-2 d-none d-sm-block"
              // data-tooltip-content={`${resourceGroup?.cdnFiles.length} Files`}
              // data-tooltip-id="FolderInfo"
              data-tip={pluralize('Embedded File', embeddedFiles.length, true)}
              data-for="FolderInfo"
            >
              <span className="badge bg-white border d-flex align-items-center">
                <FaFile size={10} className='text-keppel' />
                <span className='ml-1'>
                  {embeddedFiles.length}
                </span>
              </span>
            </span>
          )}
        </span>
      </div>
      <ReactTooltip id="FolderInfo" />
    </>
  );
};

export default FolderInfo;
