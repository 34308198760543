import React, { useState } from 'react';
import { FaArrowsAlt, FaCog, FaEllipsisH } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import {
  DASHBOARD_MODULE_TYPE,
  DASHBOARD_MODULES
} from '../../../constants/dashboard';
import ConfirmationToast from '../../Toasts/ConfirmationToast';

const DotMenu = ({
  id,
  className,
  handleRemove,
  moduleData,
  moduleType,
  handleEditModule,
  handleClickReorder
}) => {
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const onConfirm = () => {
    handleRemove();
    setShowRemoveConfirmation(false);
  };
  const handleCancel = () => {
    setShowRemoveConfirmation(false);
  };
  return (
    <div className={`btn-group align-items-center ${className}`}>
      <button
        className="btn-sm btn-white border text-muted"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label="Manage Menu"
        title="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap">
          {DASHBOARD_MODULES[moduleType]}
        </div>

        <button
          type="button"
          id={`module-settings-${id}`}
          className="dropdown-item py-2 border-bottom"
          onClick={() => handleEditModule(moduleType)}
        >
          <span className="d-flex align-items-center">
            <FaCog />
            <span className="ml-2">Settings</span>
          </span>
        </button>

        {moduleType === DASHBOARD_MODULE_TYPE.links && (
          <button
            type="button"
            className="dropdown-item py-2 border-bottom"
            onClick={handleClickReorder}
            disabled={moduleData?.selectedIds?.length < 2}
          >
            <span className="d-flex align-items-center">
              <FaArrowsAlt />
              <span className="ml-2">Reorder</span>
            </span>
          </button>
        )}

        <button
          type="button"
          className="dropdown-item py-2"
          onClick={() => {
            setShowRemoveConfirmation(true);
          }}
        >
          <span className="d-flex align-items-center">
            <FaTrash color="red" />
            <span className="ml-2">Remove</span>
          </span>
        </button>
      </div>

      {showRemoveConfirmation && (
        <ConfirmationToast
          handleClose={handleCancel}
          handleCancel={handleCancel}
          onConfirm={onConfirm}
          toastType="danger"
          primaryActionBtnText="Remove"
        />
      )}
    </div>
  );
};

export default DotMenu;
