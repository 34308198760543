import React from 'react';

const ProjectVideo = ({ projectVideo = null }) => {
  if (!projectVideo) {
    return null;
  }

  return (
    <div
      className="embed-container position-relative"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: projectVideo }}
    />
  );
};

export default ProjectVideo;
