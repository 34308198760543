import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { reactSelectDefaultValue, roleReadable } from '@apprentage/utils';
import { FaSortAmountUp, FaUserCog } from 'react-icons/fa';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import SelectMenu from '../../ManageContent/SelectMenu';
import NameBlock from '../../ManageContent/NameBlock';
import { getApprenticeshipUserInstructionProvider, getInstructionProviders } from '../../../actions/InstructionProviders';
import { updateUserProfile } from '../../../services/userProfiles';

const UserApprenticeshipSettings = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentModal = useSelector((state) => state.currentModal);
  const instructionProviders = useSelector((state) => state.instructionProviders);
  // Modal
  const user = currentModal?.data?.user || null;
  // Training Program
  const apprenticeshipId = apprenticeship?.id || '';
  const apprenticeshipInstructionProviderIds = apprenticeship?.instructionProviderIds || [];
  const apprenticeshipUserProfile = apprenticeship?.userProfile || null;
  const apprenticeshipUserInstructionProvider = apprenticeship?.userInstructionProvider || null;
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // Local State
  const [loading, setLoading] = useState(false);
  // Instruction Provider
  const [instructionProviderId, setInstructionProviderId] = useState(apprenticeshipUserInstructionProvider?.id);
  const [instructionProviderChanged, setInstructionProviderChanged] = useState(false);

  const instructionProviderOptions = useMemo(() => {
    if (instructionProviders.list) {
      return instructionProviders.list.map((instructionProvider) => ({
        label: instructionProvider.name,
        value: instructionProvider.id
      }));
    }

    return null;
  }, [instructionProviders.list]);

  // Default Values
  const instructionProvidersDefaultValue = reactSelectDefaultValue(instructionProviderId, instructionProviderOptions);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const userInstructionProviderIds = apprenticeshipUserProfile?.instructionProviders || {};
    const userInstructionProviderArr = Object.keys(userInstructionProviderIds);
    const updatedInstructionProviders = {
      ...(userInstructionProviderArr.length !== 0 ? userInstructionProviderIds : {})
    };

    if (instructionProviderId) {
      updatedInstructionProviders[apprenticeshipId] = instructionProviderId;
    } else {
      delete updatedInstructionProviders[apprenticeshipId];
    }

    updateUserProfile({
      instructionProviders: updatedInstructionProviders
    }, apprenticeshipUserProfile?.id).then(() => {
      toast.success('Instruction Provider updated!');
      dispatch(getApprenticeshipUserInstructionProvider({
        instructionProviderId
      })).then(() => {
        dispatch(resetCurrentModal());
      });
    });
  };

  const dataHasChanged = useMemo(() => {
    return instructionProviderChanged;
  }, [instructionProviderChanged]);

  useEffect(() => {
    const config = {
      orgId,
      instructionProviderIds: apprenticeship?.instructionProviderIds || undefined
    };

    if (!apprenticeshipInstructionProviderIds.length !== 0) {
      dispatch(getInstructionProviders(config));
    }
  }, []);

  return (
    <Modal
      cssClassName={`turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={false}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h1 className="h5 m-0 d-flex align-items-center">
            <FaUserCog size={25} className='mr-2' />
            <span>
              User Settings
            </span>
          </h1>
        </div>

        <NameBlock
          name={user?.name}
          role={roleReadable(user?.role, orgType)}
          profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
          pictureHeight={50}
          pictureWidth={50}
          className="p-3 border-bottom"
        />

        <form onSubmit={onSubmit}>
          <div className='p-3'>
            <h6 className='m-0'>
              Training Program
            </h6>
          </div>

          <div className="card mx-3 mb-3">
            <div className="card-header bg-secondary text-white">
              <h1 className="h5 m-0 d-flex align-items-center">
                <FaSortAmountUp size={25} className='mr-2' />
                <span>
                  {apprenticeship?.title}
                </span>
              </h1>
            </div>
            <div className="card-body">
              {/* Groups */}

              <div
                id="apprenticeshipInstructionProvidersSelect"
                className="mb-4"
              >
                <label htmlFor="instructionProviderIds">
                  <strong>Instruction Provider</strong> <span className="text-danger">*</span>
                </label>
                <SelectMenu
                  id="instructionProviderId"
                  name="instructionProviderId"
                  options={instructionProviderOptions}
                  defaultValue={instructionProvidersDefaultValue}
                  placeholder="Select Instruction Provider..."
                  required
                  onChange={({ instructionProviderId: newInstructionProviderId }) => {
                    setInstructionProviderId(newInstructionProviderId);
                    setInstructionProviderChanged(true);
                  }}
                />
              </div>

            </div>

          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading || !dataHasChanged}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            <button
              className="btn btn-link btm-sm ml-2"
              type="button"
              disabled={loading}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UserApprenticeshipSettings;
