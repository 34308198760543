import React from 'react';
import { ICON_LINK, ICON_TURBINE } from '@apprentage/constants';

const CourseContentCell = ({ value: enableCourseContent }) => {
  return (
    <div className="d-flex align-items-center justify-content-center text-nowrap">
      {enableCourseContent ? (
        <div
          // className='font-weight-bold'
          style={{ color: '#959595' }}
        >
          <i className={ICON_TURBINE} /> Internal
        </div>
      ) : (
        <div>
          <i className={ICON_LINK} /> External
        </div>
      )}
    </div>
  );
};

export default CourseContentCell;
