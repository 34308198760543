import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { FaArrowLeft } from 'react-icons/fa';
import CircleButton from '@apprentage/components/dist/components/CircleButton';
import limitCharactersWithEllipsis from '../../../utils/limitCharactersWithEllipsis';

const ChooseTrainingProgramTemplate = ({
  trainingList,
  title,
  setTitle,
  handleSearch,
  handleSelectTrainingProgram,
  handleClickBack,
  loadingTraining = false
}) => {
  const [limit, setLimit] = useState(6);
  const limit250Characters = limitCharactersWithEllipsis(250);
  return (
    <div className="p-3 px-md-4">
      <div className="d-flex align-items-center">
        {handleClickBack && (
          <CircleButton
            className="mr-3"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              handleClickBack?.();
            }}
          >
            <FaArrowLeft
              size={18}
              className="text-primary"
            />
          </CircleButton>
        )}
        <div className="flex-grow-1">
          <h3 className="font-weight-bolder text-dark text-center">
            Create Training Program
          </h3>
        </div>
      </div>
      <div className="mt-3 mt-md-4">
        <h5 className="font-weight-bold text-dark">
          Customize Proven Frameworks
        </h5>
        <div className="mb-3">
          Replicate a proven work-based learning program that includes competencies, an approved
          occupation, and relevant identifiers including RAPIDS & O*NET codes.
        </div>
        <SearchBox
          onSubmit={(value) => {
            handleSearch(value);
            setTitle(value);
          }}
          value={title}
          onClear={() => {
            setLimit(6);
          }}
          name="training-program"
          placeholder="Search..."
          autoFocus
          className="d-flex justify-content-start w-100"
        />
        <div className="mt-3">
          <h6 className="font-weight-bold text-dark mb-3">
            Choose a Training Template
          </h6>

          {trainingList?.length === 0 && (
            <div>No training templates found based on your search.</div>
          )}

          {trainingList?.length > 0 && (
            <>
              <div className="bg-light px-3 pb-3 border">
                <div
                  style={{
                    overflowY: 'scroll'
                    // height: '350px'
                  }}
                  className="row"
                >
                  {trainingList?.map((program, index) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 mt-3"
                        key={index}
                      >
                        <TrainingProgramTemplate
                          title={program?.title}
                          subTitle={limit250Characters(
                            program?.description || ''
                          )}
                          handleSelect={() => {
                            handleSelectTrainingProgram(program);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              {trainingList?.length > 0 && !loadingTraining && (
                <div className="col-12 my-3 d-flex justify-content-center">
                  <button
                    className="btn btn-link text-decoration-underline"
                    type="button"
                    onClick={() => {
                      handleSearch(title, limit + 6);
                      setLimit((preLimit) => preLimit + 6);
                    }}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          )}

          {loadingTraining && (
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 mt-3">
                <Skeleton
                  width="100%"
                  height="100px"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-3">
                <Skeleton
                  width="100%"
                  height="100px"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-3">
                <Skeleton
                  width="100%"
                  height="100px"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-3">
                <Skeleton
                  width="100%"
                  height="100px"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-3">
                <Skeleton
                  width="100%"
                  height="100px"
                />
              </div>
              <div className="col-12 col-sm-6 col-md-4 mt-3">
                <Skeleton
                  width="100%"
                  height="100px"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseTrainingProgramTemplate;

const TrainingProgramTemplate = ({
  title = '',
  subTitle = '',
  handleSelect
}) => {
  return (
    <div className="card-hover-transition card h-100 flex flex-column justify-content-between ">
      <div className="card-content p-3">
        <div>
          <h5 className="text-dark">{title}</h5>
          <p className="h6">{subTitle}</p>
        </div>
      </div>
      <div className="card-footer">
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={handleSelect}
        >
          Select
        </button>
      </div>
    </div>
  );
};
