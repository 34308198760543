import React from 'react';
import { ICON_INFO_CIRCLE } from '@apprentage/constants';
import { inviteStatus } from '../../../services/invites';

const StatusCell = ({
  row
}) => {
  const viewedAt = row?.original?.viewedAt || '';
  const sentAt = row?.original?.sentAt || '';

  const {
    text,
    className,
    dataTip
  } = inviteStatus({ viewedAt, sentAt });

  return (
    <span className="h6 m-0 d-flex align-items-center">
      <span className={`mr-1 badge badge-${className}`}>
        {text}
      </span>
      <span className='d-none d-sm-inline-block ml-1'>
        <i
          className={`${ICON_INFO_CIRCLE} text-muted opacity-50`}
          // data-tooltip-content={dataTip}
          // data-tooltip-id="InvitesList"
          data-tip={dataTip}
          data-for="InvitesList"
        />
      </span>
    </span>
  );
};

export default StatusCell;
