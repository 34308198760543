import React from 'react';
import {
  FaFileAlt,
  FaFileAudio,
  FaRobot,
  FaSearch
} from 'react-icons/fa';

const Knowledge = () => {
  return (
    <div>
      <div className='h5 mb-2 d-flex align-items-center'>
        <span>
          KnowledgeOps
        </span>
      </div>

      <div className='d-flex'>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaSearch size={30} />
        </div>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaFileAlt size={30} />
        </div>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaRobot size={30} />
        </div>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaFileAudio size={30} />
        </div>
      </div>
    </div>
  );
};

export default Knowledge;
