import React from 'react';

const RenderHtml = ({ className = '', html = null }) => {
  if (!html) {
    return null;
  }

  return (
    <div
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default RenderHtml;
