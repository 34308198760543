import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import AddressReadable from '../AddressReadable';
import Loading from '../Loading';

const CertificateLocation = () => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const userLocation = useSelector((state) => state.userLocation);
  // Location
  const name = pathOr(null, ['name'], userLocation);
  const subtitle = pathOr(null, ['subtitle'], userLocation);
  // Address : Location
  const addressLine1 = pathOr('', ['addressLine1'], userLocation);
  const addressLine2 = pathOr('', ['addressLine2'], userLocation);
  const addressCity = pathOr('', ['addressCity'], userLocation);
  const addressState = pathOr('', ['addressState'], userLocation);
  const addressPostalCode = pathOr('', ['addressPostalCode'], userLocation);
  const addressCountry = pathOr('', ['addressCountry'], userLocation);

  if (!currentUser.id) {
    return null;
  }

  if (!organization.id || !userLocation.id) {
    return (
      <Loading text="Loading user info..." />
    );
  }

  return (
    <div className="location-addressInfo">
      {name && (
        <small>
          <p className="font-weight-bold m-0">
            {name}
          </p>
        </small>
      )}

      {subtitle && (
        <small>
          <p className="m-0">
            {subtitle}
          </p>
        </small>
      )}

      <AddressReadable
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        addressCity={addressCity}
        addressState={addressState}
        addressPostalCode={addressPostalCode}
        addressCountry={addressCountry}
      />
    </div>
  );
};

export default CertificateLocation;
