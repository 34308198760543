export const appsHash = {
  zoom: {
    title: 'Zoom',
    body: 'Video conferencing',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-zoom.jpg',
    url: 'https://zoom.us'
  },
  googleDrive: {
    title: 'Google Drive',
    body: 'File storage and collaboration',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-google-drive.jpg',
    url: 'https://drive.google.com'
  },
  googleMail: {
    title: 'Gmail',
    body: 'Company email',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-google-mail.jpg',
    url: 'https://mail.google.com'
  },
  slack: {
    title: 'Slack',
    body: 'Team communication',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-slack.jpg',
    url: 'https://slack.com'
  },
  sharepoint: {
    title: 'SharePoint',
    body: 'Content management',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-share-point.jpg',
    url: 'https://sharepoint.com'
  },
  outlook: {
    title: 'Outlook',
    body: 'Company email',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-outlook.jpg',
    url: 'https://drive.google.com'
  },
  teams: {
    title: 'Teams',
    body: 'Team communication',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-teams.jpg',
    url: 'https://teams.microsoft.com'
  },
  mailchimp: {
    title: 'Mailchimp',
    body: 'Email marketing',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-mailchimp.jpg',
    url: 'https://mailchimp.com'
  },
  hubspot: {
    title: 'Hubspot',
    body: 'Company CRM',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-hubspot.jpg',
    url: 'https://hubspot.com'
  },
  salesforce: {
    title: 'Salesforce',
    body: 'Company CRM',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-salesforce.jpg',
    url: 'https://salesforce.com'
  },
  squarespace: {
    title: 'Squarespace',
    body: 'Website builder',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-squarespace.jpg',
    url: 'https://squarespace.com'
  },
  lastPass: {
    title: 'LastPass',
    body: 'Password management',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-lastpass.jpg',
    url: 'https://lastpass.com'
  },
  constantContact: {
    title: 'Constant Contact',
    body: 'Email marketing',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-constantcontact.jpg',
    url: 'https://constantcontact.com'
  },
  adp: {
    title: 'ADP',
    body: 'HR',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-adp.jpg',
    url: 'https://www.workforcenow.adp.com'
  },
  xero: {
    title: 'Xero',
    body: 'Expense management',
    image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-xero.jpg',
    url: 'https://www.xero.com'
  }
  // stripe: {
  //   title: 'Stripe',
  //   image: 'https://turbine-content.s3.us-east-2.amazonaws.com/default-link-stripe.jpg'
  // }
};
