import {
  SET_USER_WEATHER_CURRENT, RESET_USER_WEATHER_CURRENT
} from '../../actions/types';

const initialState = {
  location: null,
  current: null,
  fetched: false
};

export default function userWeather(state = initialState, action) {
  switch (action.type) {
    case SET_USER_WEATHER_CURRENT:
      return {
        ...state,
        location: action.location,
        current: action.current,
        fetched: true
      };
    case RESET_USER_WEATHER_CURRENT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
