import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Form = ({
  onSuccessCallback,
  onCancelCallback
}) => {
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Modal Data
  const modalData = currentModal?.data || {};
  // Local State
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(modalData?.text || '');

  const onSubmit = () => {
    if (!text || text.trim() === '') {
      toast.error('A prompt is required');
      return;
    }

    const dataToSave = {
      index: modalData?.index || 0,
      text
    };

    setLoading(true);

    // Update Record
    if (onSuccessCallback && typeof onSuccessCallback === 'function') {
      onSuccessCallback(dataToSave);
    }
  };

  return (
    <div
      className="card"
    >
      <div
        className="card-header bg-dark py-3"
      >
        <h5 className="font-weight-bold m-0 text-capitalize">
          {modalData?.text ? 'Edit' : 'Create'} Prompt
        </h5>
      </div>

      <div className="card-body">
        <div>
          <label className="mb-0">
            <b>Prompt:</b> <span className="text-danger">*</span>
          </label>
          <textarea
            id="-description"
            name="text"
            type='text'
            className="form-control mt-2"
            value={text}
            style={{
              minHeight: '130px'
            }}
            autoFocus
            required
            placeholder="Enter a question your customers might ask..."
            onChange={(e) => {
              const { value } = e.currentTarget;

              setText(value);
            }}
          />
          <div className="text-muted medium">
            Ex: What are the benefits of using your product or service?
          </div>
        </div>
      </div>

      <div className="card-footer py-3">
        <button
          className="btn btn-primary btn-md"
          type="submit"
          disabled={loading}
          onClick={onSubmit}
        >
          Save
        </button>
        <button
          className="btn btn-link btn-md ml-2"
          type="button"
          disabled={loading}
          onClick={onCancelCallback}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Form;
