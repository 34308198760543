import { sortCaseInsensitive } from '@apprentage/utils';
import {
  createFirebaseEntry, getFirebaseEntries, updateFirebaseEntry
} from '../services/firebaseProxy';
import { actionFromParentType, fetchResources } from '../services/resources';
import {
  SET_ENTRY_ORG_ID,
  SET_RESOURCE_GROUP_FILES,
  SET_RESOURCE_GROUP_TAGS
} from './types';

export const getResourcesByParentId = (
  parentId,
  parentType = 'resourceGroup' // TODO change to resourceGroups
) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!parentId) {
        console.error('Missing parameter, parentId is required!');
        reject(new Error('Missing parameter, parentId is required!'));
      }

      if (!parentType) {
        console.error('Missing parameter, parentType is required!');
        reject(new Error('Missing parameter, parentType is required!'));
      }

      fetchResources({ parentId }).then((response) => {
        const list = Array.isArray(response?.items) ? response.items : null;
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        dispatch({
          type: actionFromParentType(parentType),
          cdnFiles: list
        });

        resolve(list);
      });
    });
  };
};

export const setResourceGroupFiles = (cdnFiles = []) => {
  return (dispatch, getState) => {
    const { resourceGroup } = getState();
    return new Promise((resolve) => {
      const newCdnFiles = [
        ...(resourceGroup?.cdnFiles ? resourceGroup.cdnFiles : []),
        ...cdnFiles
      ];

      const sortedCdnFiles = newCdnFiles.sort((a, b) => sortCaseInsensitive(a, b, 'name'));

      dispatch({
        type: SET_RESOURCE_GROUP_FILES,
        cdnFiles: sortedCdnFiles
      });
      resolve(sortedCdnFiles);
    });
  };
};

export const getResourceGroupTags = ({ orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId) {
        console.error('Missing parameter, orgId is required!');
        reject(new Error('Missing parameter, orgId is required!'));
      }

      getFirebaseEntries({
        collection: 'resourceGroupTags',
        orgId
      }).then((response) => {
        const list = response.items && response.items.length ? response.items : null;

        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        dispatch({
          type: SET_RESOURCE_GROUP_TAGS,
          list
        });
        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const saveResourceGroupTags = ({
  data, id, orgId, authorUserId, authorUserName
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (id) {
        // EDIT
        updateFirebaseEntry({
          data,
          collection: 'resourceGroupTags',
          id
        }).then(() => {
          dispatch(getResourceGroupTags({ orgId })).then((list) => {
            resolve(list);
          });
        }).catch((error) => {
          console.error(error);
          reject(error);
        });
      } else {
        // CREATE
        createFirebaseEntry({
          data: {
            ...data,
            orgId,
            authorUserId,
            authorUserName
          },
          collection: 'resourceGroupTags'
        }).then(() => {
          dispatch(getResourceGroupTags({ orgId })).then((list) => {
            resolve(list);
          });
        }).catch((error) => {
          console.error(error);
          reject(error);
        });
      }
    });
  };
};
