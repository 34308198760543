import React from 'react';
import { useSelector } from 'react-redux';
import { ICON_USER_INFO } from '@apprentage/constants';
import InfoField from '../InfoField';
import DotMenuContactBlock from './DotMenuContactBlock';

const ContactBlock = ({
  title = 'Point of Contact',
  icon = ICON_USER_INFO,
  className = 'card mt-3 w-100',
  data
}) => {
  // redux
  const employerProfile = useSelector((state) => state.employerProfile);

  return (
    <div className={className}>
      <div
        className='card-header d-flex align-items-center justify-content-between'
      >
        <div className='font-weight-bold'>
          <i className={`${icon} mr-1`} /> {title}
        </div>
        <DotMenuContactBlock
          data={data}
        />
      </div>

      <div className='card-body'>
        <InfoField
          className="mb-2"
          name="Name"
          value={`${employerProfile?.contactFirstName || ''} ${employerProfile?.contactLastName || ''}`}
        />

        <InfoField
          className="mb-2"
          name="Title"
          value={employerProfile?.contactTitle || ''}
        />

        <InfoField
          className="mb-2"
          name="Phone"
          value={employerProfile.contactPhone}
        />

        <InfoField
          className="mb-2"
          name="Email"
          value={employerProfile.contactEmail}
        />
      </div>
    </div>
  );
};

export default ContactBlock;
