import React from 'react';
import OrgLocation from '../../../OrgLocation';

const LocationNameCell = ({ row }) => {
  const locationId = row?.original?.locationId || '';
  if (!locationId) {
    return null;
  }

  return (
    <OrgLocation
      locationId={locationId}
    />
  );
};

export default LocationNameCell;
