import React from 'react';
import { useSelector } from 'react-redux';
import Notification from '../../../components/lists/NotificationsList/Notification';

const Activity = () => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div className='mb-2'>
      <div className='h5 mb-2 d-flex align-items-center'>
        <span className='h5 m-0'>
          Activity
        </span>
        {/* <span className='mb-0 ml-2'>
          <span className='badge badge-secondary'>
            Notifications
          </span>
        </span> */}
      </div>
      <Notification
        key={1}
        data={{
          createdAt: new Date().toISOString(),
          postAsOrg: true,
          authorName: currentUser?.name,
          authorId: currentUser?.id,
          authorUid: currentUser?.uid,
          message: 'Our new Turbine Workforce Space is live!'
        }}
      // className=""
      />
      {/* <Notification
        key={2}
        empty
        data={{
          postAsOrg: false
        }}
      /> */}
    </div>
  );
};

export default Activity;
