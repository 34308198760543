import React from 'react';
import { hrMin } from '../../../utils/date';
import RenderMarkdown from '../../../components/ManageContent/RenderMarkdown';
import UserProfilePicture from '../../../components/UserProfilePicture';
import './three-dot-loader.css';
import './style.css';

const MessageContainer = ({
  id,
  agentImage,
  colorBtnBkg,
  className = 'mb-3',
  orgName,
  authorName,
  text,
  date = new Date().toISOString(),
  postAsCpu = false,
  children,
  onlyMessage = false
}) => {
  return (
    <div
      key={`message-${id}`}
      className={`MessageContainer ${className}`}
    >
      <div
        style={{
          display: onlyMessage ? 'none' : 'flex',
          justifyContent: 'center'
        }}
      >
        {postAsCpu ? (
          <div
            className="MessageAvatar"
            style={{
              background: colorBtnBkg
            }}
          >
            <img
              src={agentImage}
              alt={orgName}
              height={30}
              style={{
                height: '30px'
              }}
            />
          </div>
        ) : (
          <div>
            <UserProfilePicture
              alt={orgName}
            />
          </div>
        )}
      </div>

      <div
        className="ml-3 w-100"
      >
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div
            className='font-weight-bold text-dark' // opacity-75
            style={{ fontSize: '1rem' }}
          >
            {postAsCpu ? orgName : authorName}
          </div>
          <div className='d-flex align-items-center'>
            <div
              className="text-muted"
              style={{
                display: onlyMessage ? 'none' : 'block'
              }}
            >
              {hrMin(date)}
            </div>
            {/* {postAsCpu && (
            <PromptDotMenu
              key={material?.id || id}
              output={output}
              outputTitle={outputTitle}
              resourceId={resource?.id}
              promptId={id}
              promptType={promptType}
              promptText={promptText}
            />
          )} */}
          </div>
        </div>
        <div
          className="shadow p-3 rounded bg-white position-relative"
          style={{
            wordBreak: 'break-word'
          }}
        >
          {children || (
            <>
              {text ? (
                <RenderMarkdown
                  source={text || ''}
                />
              ) : (
                <div className="pb-3 px-3 pt-0">
                  <div className="three-dot-loader m-0" />
                </div>
              )}
            </>
          )}

        </div>
      </div>

    </div>
  );
};

export default MessageContainer;
