const logbookRecordState = {
  id: null,
  date: null,
  hours: 0,
  transcript: null,
  logbookId: null,
  competencyIds: null, // string[]
  userName: null,
  locationId: null,
  userId: null,
  apprenticeshipId: null,
  orgId: null,
  approvedAt: null,
  isApproved: null, // bool
  approvedByUserId: null,
  approvedByName: null,
  createdAt: null,
  updatedAt: null
};

export default logbookRecordState;
