import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import NumberStat from '../../NumberStat';
import { fetchProjects } from '../../../services/projects';

import { fetchLogbookHours } from '../../../services/logbooks';
import { fetchMaterials } from '../../../services/materials';
import { fetchUsers } from '../../../services/users';
import { fetchCourses } from '../../../services/courses';

const Vitals = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeships = useSelector((state) => state.apprenticeships);
  const dictionary = useSelector((state) => state.dictionary);
  //  Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // Apprenticeship
  const locationId = params?.locationId || '';

  // Current User
  const userId = currentUser?.id || '';
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [totalLogbookHours, setLogbookHours] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalMaterials, setTotalMaterials] = useState(0);
  const [totalCourses, setTotalCourses] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const numTrainingPrograms = useMemo(() => {
    return Array.isArray(apprenticeships?.list) ? apprenticeships?.list?.length : 0;
  }, [apprenticeships?.list]);

  useEffect(() => {
    fetchProjects({
      orgId,
      locationId,
      count: true
    }).then((response) => {
      if (response?.total) {
        setTotalProjects(response?.total);
      }
    });
  }, [locationId, orgId, role]);

  useEffect(() => {
    fetchMaterials({
      orgId,
      locationId,
      count: true
    }).then((response) => {
      if (response?.total) {
        setTotalMaterials(response?.total);
      }
    });
  }, [locationId, orgId, role]);

  useEffect(() => {
    fetchCourses({
      orgId,
      locationIds: [locationId]
    }).then((response) => {
      if (Array.isArray(response)) {
        setTotalCourses(response?.length);
      }
    });
  }, [locationId, orgId, role]);

  useEffect(() => {
    fetchLogbookHours({
      orgId,
      locationId,
      showCompHours: true
    }).then((response) => {
      if (response?.totalHours) {
        setLogbookHours(response?.totalHours);
      }
      if (response?.totalRecords) {
        setTotalRecords(response?.totalRecords);
      }
    });
  }, [locationId, orgId, role]);

  useEffect(() => {
    if (locationId) {
      const config = {
        orgId,
        locationId,
        excludeRoles: [
          'superAdmin'
        ],
        excludeInactive: true,
        excludeTestUsers: true,
        count: true
      };

      fetchUsers(config).then((response) => {
        if (response?.total) {
          setTotalUsers(response?.total);
        }
      });
    }
  }, [dispatch, locationId, orgId, role, userId]);

  if (orgType === 'community') {
    return (
      <div className="row my-3 mt-md-5">
        <div className="col-6 col-md-6 col-lg-3 mb-3">
          <NumberStat
            num={totalUsers}
            numTitle={pluralize(dictionary?.user, totalUsers)}
          />
        </div>

        <div className="col-6 col-md-6 col-lg-3 mb-3">
          <NumberStat
            num={numTrainingPrograms}
            numTitle={pluralize('Training Program', numTrainingPrograms)}
          />
        </div>

        <div className="col-6 col-md-6 col-lg-3 mb-3">
          <NumberStat
            num={totalRecords}
            numTitle={pluralize('Logbook Record', totalRecords || 0)}
          />
        </div>

        <div className="col-6 col-md-6 col-lg-3 mb-3">
          <NumberStat
            num={totalLogbookHours}
            numTitle={pluralize('Logbook Hours', totalLogbookHours)}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="row my-3 mt-md-5">
      <div className="col-6 col-md-6 col-lg-3 mb-3">
        <NumberStat
          num={totalUsers}
          numTitle={pluralize(dictionary?.user, totalUsers)}
        />
      </div>

      <div className="col-6 col-md-6 col-lg-3 mb-3">
        <NumberStat
          num={totalProjects}
          numTitle={pluralize('Project', totalProjects || 0)}
        />
      </div>

      <div className="col-6 col-md-6 col-lg-3 mb-3">
        <NumberStat
          num={totalMaterials}
          numTitle={pluralize('Materials', totalMaterials)}
        />
      </div>

      <div className="col-6 col-md-6 col-lg-3 mb-3">
        <NumberStat
          num={totalCourses}
          numTitle={pluralize('Course', totalCourses)}
        />
      </div>
    </div>
  );
};

export default Vitals;
