import { isAdminOwnerSuperAdmin } from '@apprentage/utils';

// Supabase
export default function canUnpublishMaterial({
  role = [],
  userId,
  resourceUserId
}) {
  if (!role || (Array.isArray(role) && role.length === 0)) {
    return false;
  }

  if (!userId || !resourceUserId) {
    return false;
  }

  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  return userId === resourceUserId;
}
