import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isOwner, isSuperAdmin, percentComplete } from '@apprentage/utils';
import { FaArrowCircleRight } from 'react-icons/fa';
import slugify from 'slugify';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { toast } from 'react-toastify';
import { DEFAULT_ORG_LOGO } from '@apprentage/constants';
import * as aws from '../../../services/aws';
import { IMAGE_TURBINE_RETRO_ICON_WHITE } from '../../../constants/assets';
import { withAuthorization } from '../../../components/Session';
import { ONBOARDING_WORKFORCE_SPACE } from '../../../constants/routes';
import { cheerioParseOrgContext } from '../../../services/cheerio';
import { updateOrganization, uploadOrgLogoUpdateOrg } from '../../../services/organizations';
import { fetchOpenGraphData } from '../../../services/openGraph';
import { getOrg } from '../../../actions/Organizations';
import { createSkill, fetchSkills } from '../../../services/skills';
import urlHasProtocol from '../../../utils/urlHasProtocol';
import withOrgConsole from '../../../components/App/withOrgConsole';
import ProgressBars from '../../../components/ProgressBars';
import InputChangeValue from '../InputChangeValue';
import ImageUploadAndPreview from '../../../components/ManageContent/ImageUploadAndPreview';

/**
 *
 * Total Onboarding Steps: 6
 *
 * (1) Welcome
 * (5) Team Context
 * (5) Org Context
 * (1) Workforce Space
 *
 */

const OrgContext = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const turbineLogo = useRef(null);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Local State
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('slugConfirm');
  const [totalSteps] = useState(5);
  const [currentStep, setCurrentStep] = useState(1);
  const [hasWebsite, setHasWebsite] = useState(null);
  const [showAppsTertiaryLink, setShowAppsTertiaryLink] = useState(false);
  const [hasOrgSkills, setHasOrgSkills] = useState(null);

  const percentage = percentComplete({
    current: currentStep,
    total: totalSteps
  });
  //
  const [orgUrl, setOrgUrl] = useState(organization?.orgUrl || null);
  const [description, setDescription] = useState(organization?.description || null);
  const [orgLogo, setOrgLogo] = useState(organization?.orgLogo);
  // Current User
  const email = currentUser?.email || null;
  const role = currentUser?.role || [];
  // // Organization
  const orgId = organization?.id || null;
  const orgName = organization?.name || null;

  const [slug, setSlug] = useState(null);

  const emailProviders = useMemo(() => ([
    'gmail.com',
    'yahoo.com',
    'protonmail.com',
    'proton.me',
    'outlook.com',
    'yahoo.com',
    'ymail.com',
    'aol.com',
    'msn.com',
    'hotmail.com',
    'live.com',
    'icloud.com',
    'me.com'
  ]), []);

  const hostNameFromEmail = useMemo(() => {
    const emailArr = email.split('@');
    return emailArr[1];
  }, [email]);

  const isPersonalEmail = useMemo(() => {
    return emailProviders.includes(hostNameFromEmail);
  }, [emailProviders, hostNameFromEmail]);

  const slugFromOrgName = useMemo(() => {
    const slugifiedOrgName = slugify(orgName, {
      lower: true,
      remove: /[*+~.()'"!:@]/g
    });
    return slugifiedOrgName;
  }, [orgName]);

  const timeoutAppsTertiaryLink = useCallback(() => {
    if (currentScreen === 'contextTeamApps') {
      if (!showAppsTertiaryLink) {
        setTimeout(() => {
          setShowAppsTertiaryLink(true);
        }, 2000);
      }
    }
  }, [currentScreen, showAppsTertiaryLink]);

  const currentScreenTitle = useMemo(() => {
    switch (currentScreen) {
      case 'slugConfirm':
      case 'domainConfirm':
      case 'website':
      case 'logoConfirm':
      case 'descriptionConfirm':
        return 'Organization Context';
      default:
        return 'Turbine Workforce';
    }
  }, [currentScreen]);

  useEffect(() => {
    document.body.classList.add('onboarding');

    return function cleanup() {
      document.body.classList.remove('onboarding');
    };
  }, []);

  useEffect(() => {
    if (turbineLogo?.current) {
      setTimeout(() => {
        turbineLogo.current.classList.add('scale-up');
      }, 250);
    }
  }, []);

  useEffect(() => {
    timeoutAppsTertiaryLink();
  }, [timeoutAppsTertiaryLink]);

  useEffect(() => {
    fetchSkills({ orgId }).then((orgSkills) => {
      if (Array.isArray(orgSkills?.items) && orgSkills?.items.length > 0) {
        setHasOrgSkills(Boolean(orgSkills?.total || 0));
      }
    });
  }, [orgId]);

  if (!isOwner(role) && !isSuperAdmin()) {
    history.push(ONBOARDING_WORKFORCE_SPACE);
    return;
  }

  return (
    <div
      className='h-100 row'
    >
      <div
        className='position-fixed'
        style={{
          top: 0,
          width: '100%',
          left: 0
        }}
      >
        <ProgressBars
          size="md"
          className='w-100 border-radius-0'
          data={[
            {
              className: 'bg-000',
              valueNow: currentStep,
              valueMax: totalSteps,
              valueMin: 0,
              style: { width: percentage },
              text: ''
            }
          ]}
        />
      </div>
      <div
        className='col'
      >
        <div
          className="d-flex flex-column align-items-center justify-content-center h-100"
        >
          <span
            ref={turbineLogo}
            className="turbine-logo overflow-hidden shadow"
            style={{
              borderRadius: '1.5rem'
            }}
          >
            <img
              src={IMAGE_TURBINE_RETRO_ICON_WHITE}
              height={80}
              style={{
                height: '80px'
              }}
              alt="Turbine"
            />
          </span>
          <h5
            className="my-4 font-weight-bold"
          >
            {currentScreenTitle}
          </h5>

          <div
            className="action-box"
          >
            {currentScreen === 'slugConfirm' && (
              <div>
                <InputChangeValue
                  key={description}
                  name="description"
                  title="Enter your vanity URL"
                  helpText="This is where your admins will login to Turbine"
                  inputGroupPrepend="admin.turbine.is/login/"
                  defaultValue={slug || slugFromOrgName}
                  primaryActionText="Continue"
                  copyValue={`https://admin.turbine.is/login/${slug || slugFromOrgName}`}
                  callbackPrimaryAction={(value) => {
                    setSlug(value);
                    setCurrentStep(2);

                    if (isPersonalEmail) {
                      // User signed up with a personal email
                      // confirm they have a website
                      setCurrentScreen('website');
                    } else {
                      setCurrentScreen('domainConfirm');
                    }
                  }}
                />
              </div>
            )}

            {currentScreen === 'website' && (
              <div>
                <InputChangeValue
                  title="Do you have a company website?"
                  isBoolean
                  callbackPrimaryAction={() => {
                    setCurrentStep(3);
                    setHasWebsite(true);
                    setCurrentScreen('domainConfirm');
                  }}
                  callbackSecondaryAction={() => {
                    setCurrentStep(3);
                    setHasWebsite(false);
                    setCurrentScreen('logoUpload');
                  }}
                />
              </div>
            )}

            {currentScreen === 'domainConfirm' && (
              <div>
                <InputChangeValue
                  title={hasWebsite ? 'What is your company website address?' : "Is this your company's website?"}
                  name="orgUrl"
                  inputGroupPrepend="https://"
                  loading={loading}
                  defaultValue={hasWebsite ? '' : orgUrl || hostNameFromEmail}
                  primaryActionText='Continue'
                  callbackPrimaryAction={(value) => {
                    const orgUrlWithProtocol = `https://${value}`;

                    if (value.includes('https://')) {
                      toast.error('No need to include https://');
                      return;
                    }

                    if (!value.includes('.')) {
                      toast.error('Enter a valid website address.');
                      return;
                    }

                    setOrgUrl(value);
                    setLoading(true);

                    fetchOpenGraphData(orgUrlWithProtocol).then((og) => {
                      const ogImg = Array.isArray(og?.ogImage) && og?.ogImage.length > 0 ? og?.ogImage[0] : null;
                      const ogImgUrl = ogImg?.url && urlHasProtocol(ogImg?.url) ? ogImg?.url : null;

                      if (ogImgUrl) {
                        setOrgLogo(ogImgUrl);
                      }
                    });

                    cheerioParseOrgContext(orgUrlWithProtocol).then((orgCtx) => {
                      const dataToSave = {};

                      // URL
                      if (!organization?.url) {
                        // Save url, if NO organization.url
                        dataToSave.url = orgUrlWithProtocol;
                      }

                      // DESCRIPTION
                      if (orgCtx?.orgDescription) {
                        // Save description, if NO organization.description
                        if (!organization?.description) {
                          dataToSave.description = orgCtx?.orgDescription;
                        }

                        setDescription(orgCtx?.orgDescription);
                      }

                      // INDUSTRY
                      if (orgCtx?.orgIndustry && !organization?.orgIndustry) {
                        // Save orgIndustry, if NO organization.orgIndustry
                        dataToSave.orgIndustry = orgCtx?.orgIndustry.trim();
                      }

                      if (Object.entries(dataToSave).length > 0) {
                        updateOrganization(dataToSave, orgId).then(() => {
                          dispatch(getOrg({ orgId }));
                        });
                      }

                      // ORG LOGO
                      if (orgCtx?.orgLogo && !organization?.orgLogo) {
                        setOrgLogo(orgCtx?.orgLogo);
                      }

                      // SKILLS
                      if (!hasOrgSkills && Array.isArray(orgCtx?.orgSkills) && orgCtx?.orgSkills.length > 0) {
                        orgCtx.orgSkills.forEach((skillName) => {
                          createSkill({
                            name: skillName.trim(),
                            difficulty: 1,
                            orgId
                          });
                        });
                      }

                      if (!organization?.description && orgCtx?.orgDescription) {
                        setCurrentScreen('descriptionConfirm');
                        setLoading(false);
                        setCurrentStep(3);
                      } else if (!organization?.orgLogo && orgCtx?.orgLogo) {
                        setCurrentScreen('logoConfirm');
                        setLoading(false);
                        setCurrentStep(3);
                      } else if (orgCtx?.error) {
                        console.error(orgCtx?.error);
                        toast.error('Did you misspell your website address?');
                        setLoading(false);
                      } else {
                        history.push(ONBOARDING_WORKFORCE_SPACE);
                      }
                    }).catch((error) => {
                      console.error(error);
                      setCurrentStep(3);
                      history.push(ONBOARDING_WORKFORCE_SPACE);
                    });
                  }}
                />
              </div>
            )}

            {currentScreen === 'descriptionConfirm' && (
              <div>
                <InputChangeValue
                  key={description}
                  name="description"
                  title="Is this description accurate?"
                  textarea
                  textareaStyle={{
                    minHeight: '225px'
                  }}
                  loading={loading}
                  defaultValue={description}
                  callbackPrimaryAction={(value) => {
                    setDescription(value);

                    setLoading(true);
                    updateOrganization({ description: value }, orgId).then(() => {
                      dispatch(getOrg({ orgId })).then(() => {
                        setCurrentStep(4);
                        setLoading(false);
                        if (orgLogo) {
                          setCurrentScreen('logoConfirm');
                        } else {
                          setCurrentScreen('logoUpload');
                        }
                      });
                    });
                  }}
                />
              </div>
            )}

            {currentScreen === 'logoConfirm' && (
              <div>
                <div className='d-flex justify-content-center mb-3'>
                  <OrgLogo
                    src={orgLogo}
                    alt={orgName}
                    height="80"
                  />
                </div>

                <InputChangeValue
                  title="Is this your Logo?"
                  name="orgLogo"
                  isBoolean
                  loading={loading}
                  callbackPrimaryAction={() => {
                    setLoading(true);
                    uploadOrgLogoUpdateOrg(orgLogo, orgId).then((newOrgUrl) => {
                      setOrgLogo(newOrgUrl);
                      dispatch(getOrg({ orgId })).then(() => {
                        setLoading(false);
                        history.push(ONBOARDING_WORKFORCE_SPACE);
                      });
                    });
                  }}
                  callbackSecondaryAction={() => {
                    setCurrentScreen('logoUpload');
                  }}
                />
              </div>
            )}

            {currentScreen === 'logoUpload' && (
              <div className="InputChangeValue">
                <div className="InputChangeValue-title my-4">
                  Upload {orgName}'s Logo
                </div>

                <div id="class-image" className="py-4 list-group-item">
                  <ImageUploadAndPreview
                    stateKey="orgLogo"
                    defaultValue={orgLogo || DEFAULT_ORG_LOGO}
                    contentId={orgId}
                    contentType="organization"
                    style={{ margin: '.8rem 0' }}
                    onImageUpload={(imageUploadData) => {
                      setLoading(true);
                      setOrgLogo(imageUploadData.orgLogo);
                      aws.updateAcl({
                        acl: 'public-read',
                        fileUrl: imageUploadData.orgLogo,
                        orgId
                      }).then(() => {
                        updateOrganization({ orgLogo: imageUploadData.orgLogo }, orgId).then(() => {
                          dispatch(getOrg({ orgId }));

                          setCurrentStep(5);
                          setLoading(false);
                          history.push(ONBOARDING_WORKFORCE_SPACE);
                        });
                      });
                    }}
                    onImagePreview={() => {
                      setLoading(true);
                      toast.info('Processing image...');
                    }}
                    allowRemoveImage={false}
                    imageAltText={orgName}
                  />
                </div>

                <div
                  className="mt-4 d-flex align-items-center justify-content-center"
                >
                  <button
                    className={`btn ${orgLogo.length === 0 ? 'btn-white' : 'bg-000 text-white'} px-5 py-2 text-nowrap position-relative overflow-hidden`}
                    type='button'
                    onClick={() => {
                      setCurrentStep(5);
                      setLoading(true);
                      updateOrganization({ orgLogo }, orgId).then(() => {
                        dispatch(getOrg({ orgId }));

                        setCurrentStep(4);
                        history.push(ONBOARDING_WORKFORCE_SPACE);
                      });
                    }}
                    disabled={!orgLogo === 0 || loading}
                  >
                    <span style={{ zIndex: 2 }} className='d-flex align-items-center position-relative'>
                      <span
                        className='position-relative mr-2'
                      >
                        Continue
                      </span>
                      <FaArrowCircleRight />
                    </span>
                    {!orgLogo && (
                      <span className="start-btn-pulse" />
                    )}
                  </button>
                </div>

                <div
                  className="mt-3 d-flex align-items-center justify-content-center"
                >
                  <button
                    onClick={() => {
                      setCurrentStep(5);
                      setLoading(false);
                      history.push(ONBOARDING_WORKFORCE_SPACE);
                    }}
                    disabled={loading}
                    className="btn btn-sm btn-link"
                    type='button'
                  >
                    I'll do this later
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(OrgContext);
