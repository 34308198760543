import { useEffect, useState } from 'react';

const TypingText = ({ text, ms = 50 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [transcript] = useState(text);
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    if (text && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, ms);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, ms, text]);

  return currentText;
};

export default TypingText;
