import React from 'react';
import {
  ICON_CHALLENGE,
  ICON_COURSE,
  ICON_MATERIAL,
  ICON_QUIZ,
  ICON_TOPIC
} from '@apprentage/constants';

function contentTypeIcon({ contentType }) {
  if (contentType === 'material') {
    return (
      <i className={ICON_MATERIAL} />
    );
  }

  if (contentType === 'challenge') {
    return (
      <i className={ICON_CHALLENGE} />
    );
  }

  if (contentType === 'quiz') {
    return (
      <i className={ICON_QUIZ} />
    );
  }

  if (contentType === 'topic') {
    return (
      <i className={ICON_TOPIC} />
    );
  }

  if (contentType === 'class') {
    return (
      <i className={ICON_COURSE} />
    );
  }

  // TODO: answer approved
  // <i className="fas fa-clock text-info" /> // undefined
  // <i className={`${CIRCLE_CHECK} text-success`} /> // true
  // <i className="fas fa-exclamation-circle text-danger" /> // false

  return null;
}

export default contentTypeIcon;
