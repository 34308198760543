import React from 'react';
import { ICON_CHECK_CIRCLE, ICON_TRIANGLE_BANG } from '@apprentage/components/dist/constants/icons';

const ProfileCell = ({ value: userProfileId }) => {
  if (userProfileId) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_CHECK_CIRCLE} text-success mr-1`} /> Completed
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center text-nowrap">
      <i className={`${ICON_TRIANGLE_BANG} text-danger mr-1`} /> Incomplete
    </div>
  );
};

export default ProfileCell;
