import { RESET_CURRENT_CLASS_ANSWERS, SET_CURRENT_CLASS_ANSWERS } from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function currentClassAnswers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLASS_ANSWERS:
      return {
        ...state,
        list: action.currentClassAnswers,
        fetched: true
      };
    case RESET_CURRENT_CLASS_ANSWERS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
