import React from 'react';
import './style.css';

const MessageAvatar = (props) => {
  const {
    children,
    className = '',
    style = {},
    height = 40,
    width = 40,
    ...remainingProps
  } = props;
  return (
    <div
      className={`MessageAvatar ${className}`}
      style={{
        ...style,
        height: `${height}px`,
        width: `${width}px`
      }}
      {...remainingProps}
    >
      {children || null}
    </div>
  );
};

export default MessageAvatar;
