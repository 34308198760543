import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { canAccessTopNavLocationBar } from '../../permissions/access';
import OrgLocation from '../OrgLocation';

const TopNavLocation = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = pathOr([], ['role'], currentUser);
  const locationId = pathOr('', ['locationId'], currentUser);

  if (!canAccessTopNavLocationBar(role)) {
    return;
  }

  return (
    <div className='bg-dark px-3 py-2'>
      <OrgLocation
        locationId={locationId}
        theme="dark"
      />
    </div>
  );
};

export default TopNavLocation;
