import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { Redirect, useHistory } from 'react-router-dom';
import { withAuthorization } from '../Session';
import { resetCurrentTopic } from '../../actions/Topic';
import { setCurrentClass, resetCurrentClass } from '../../actions/Class';
import { SLASH } from '../../constants/routes';
import ManageMaterial from '../Material';
import ManageChallenge from '../Console/Challenge';
import ManageTopic from '../Topic';
import ManageCourse from '../Class'; // TODO rename to Course
import ManageQuiz from '../Quiz';
import ManageEducationUnitTracking from '../EducationUnitTracking';
import './style.css';

function goToTop() {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

const ManageCurrentEntry = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  // Misc
  const orgId = organization?.id || '';
  // Query Params
  const queryParams = new URLSearchParams(window.location.search);
  const addTypes = queryParams.get('addTypes');
  const manageType = queryParams.get('manageType');
  const classId = queryParams.get('classId');
  const contentType = queryParams.get('contentType');
  const entryId = queryParams.get('entryId');
  const topicId = queryParams.get('topicId');

  useEffect(() => {
    // Prevent dropdown menu from closing
    $(document).on('click', '.admin-menu--reference .dropdown-collapse-container', (e) => {
      e.stopPropagation();
    });
  }, []);

  useEffect(() => {
    if (classId) {
      dispatch(setCurrentClass({ classId }));
    }
  }, [classId, dispatch]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCurrentClass());
    };
  }, [dispatch]);

  useEffect(() => {
    if (contentType === 'class' && classId) {
      dispatch(resetCurrentTopic());
      // SCROLL UP TO TOP OF PAGE
      goToTop();
    }
  }, [classId, contentType, dispatch, entryId, topicId]);

  // Ensure url has params and minimum vals
  if (!queryParams || !manageType) {
    return (
      <Redirect to={SLASH} />
    );
  }

  // if (!course.fetched && !course.id) {
  //   return (
  //     <Loading />
  //   );
  // }

  // Ensure logged in user is part of the organization who owns the course
  if (course.id && course.orgId !== orgId) {
    history.replace(SLASH);

    return null;
  }

  return (
    <div
      className={`ManageCurrentEntry contentType-${addTypes || contentType}`}
    >
      {contentType === 'material' && (
        <ManageMaterial />
      )}

      {contentType === 'challenge' && (
        <ManageChallenge />
      )}

      {contentType === 'quiz' && (
        <ManageQuiz />
      )}

      {contentType === 'eUnitTracking' && (
        <ManageEducationUnitTracking />
      )}

      {contentType === 'topic' && (
        <ManageTopic />
      )}

      {contentType === 'class' && (
        <ManageCourse />
      )}
    </div>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(ManageCurrentEntry);
