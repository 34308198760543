import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { roleReadable } from '@apprentage/utils';
import {
  DEFAULT_ORG_LOGO,
  MICROSOFT_TEAMS_ICON,
  SLACK_ICON,
  STRIPE_ICON
} from '@apprentage/constants';
import { ORG_CONSOLE_DASHBOARD } from '../../constants/routes';
import { routeWithOrgId, updateOrganization } from '../../services/organizations';
import { getOrg } from '../../actions/Organizations';
import RadioGroup from '../ManageContent/RadioGroup';
import ImageUploadAndPreview from '../ManageContent/ImageUploadAndPreview';
import Stripe from '../Account/Integrations/Stripe';
import RenderTinyMceEditor from '../ManageContent/Wysiwyg/RenderTinyMceEditor';
import { updateAcl } from '../../services/aws';
// import RenderMdEditor from '../ManageContent/Wysiwyg/RenderMdEditor';

const Form = () => {
  const dispatch = useDispatch();
  const welcomeMessageRef = useRef(null);
  // redux
  const data = useSelector((state) => state.organization);
  // Local State
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [enableNewEditor] = useState(data.enableNewEditor);
  const [id] = useState(data.id);
  const [slug] = useState(data.slug);
  const [name] = useState(data.name);
  const [billing, setBilling] = useState(data.billing);
  const [restrictRegister, setRestrictRegister] = useState(data.restrictRegister);
  const [orgLogo, setOrgLogo] = useState(data.orgLogo);
  const [integration] = useState(data.integration);
  const [integrateMicrosoftTeams, setIntegrateMicrosoftTeams] = useState(data.integrateMicrosoftTeams);
  const [integrateSlack, setIntegrateSlack] = useState(data.integrateSlack);
  const [integrateStripe, setIntegrateStripe] = useState(data.integrateStripe);
  const [enableCourseCatalog, setEnableCourseCatalog] = useState(data.enableCourseCatalog);
  // const [welcomeEmail, setWelcomeEmail] = useState(data.welcomeEmail);
  const [welcomeMessage] = useState(data.welcomeMessage);
  // tos,
  // register,
  // privacyPolicy,
  // userSeats,
  // expertSeats

  // const slackChatLinks = integration?.slack?.links?.join || null;
  const orgId = data?.id || null;
  const orgType = data?.type || null;
  const stripeUserSubscriptions = integration?.stripe?.plans || null;
  const orgConsoleDashboardRoute = routeWithOrgId({
    route: ORG_CONSOLE_DASHBOARD,
    orgId: id
  });

  // if (editorRef.current) {
  //   console.log(editorRef.current.getContent());
  // }

  const showSuccess = () => {
    toast.success('Organization updated!');
    setLoading(false);
  };

  const editOrg = ({
    name: inputName,
    tos: inputTos,
    register: inputRegister,
    privacyPolicy: inputPrivacyPolicy,
    userSeats: inputUserSeats,
    expertSeats: inputExpertSeats
  }) => {
    const dataToSave = {
      billing,
      restrictRegister,
      enableCourseCatalog,
      enableNewEditor,
      integrateMicrosoftTeams,
      integrateSlack,
      integrateStripe
    };

    if (welcomeMessageRef && welcomeMessageRef.current && welcomeMessageRef.current.isDirty) {
      dataToSave.welcomeMessage = welcomeMessageRef.current.getContent();
    }

    if (orgLogo) dataToSave.orgLogo = orgLogo;

    // Handled by form values
    if (inputName) dataToSave.name = inputName.value;
    if (inputTos) dataToSave.tos = inputTos.value;
    if (inputRegister) dataToSave.register = inputRegister.value;
    if (inputPrivacyPolicy) dataToSave.privacyPolicy = inputPrivacyPolicy.value;
    // Seats
    if (inputUserSeats) dataToSave.userSeats = inputUserSeats.value;
    if (inputExpertSeats) dataToSave.expertSeats = inputExpertSeats.value;

    updateOrganization(dataToSave, id).then(() => {
      dispatch(getOrg({ orgId: id }));
      showSuccess();
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    editOrg(e.target.elements);
  };

  // const handleSaveWelcomeEmail = ({ design, html }) => {
  //   console.log('exportHtml', html);
  //   console.log('exportHtml', design);
  //   setWelcomeEmail(html);
  // }

  return (
    <div>
      <form onSubmit={onSubmit}>
        {/* <div className="bg-dark p-3">
          <h5 className="m-0 text-white">
            {name}
          </h5>
        </div> */}

        <div className="">

          <div id="org-settings" className="card mb-3">
            {/* <div className="card-header">
              <h5 className="m-0 text-capitalize">
                Organization Settings
              </h5>
            </div> */}
            <div className="card-content">

              <div className="list-group list-group-flush">
                <div id="org-name" className="py-4 list-group-item">
                  <label htmlFor="name">
                    <b>Name:</b> <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    defaultValue={name}
                    required
                  />
                </div>

                <div id="class-image" className="py-4 list-group-item">
                  <div className="font-weight-bold mb-2">
                    Logo:
                  </div>

                  <ImageUploadAndPreview
                    stateKey="orgLogo"
                    defaultValue={orgLogo || DEFAULT_ORG_LOGO}
                    contentId={id}
                    contentType="organization"
                    style={{ margin: '.8rem 0' }}
                    onImageUpload={(imageUploadData) => {
                      setOrgLogo(imageUploadData.orgLogo);
                      updateAcl({
                        acl: 'public-read',
                        fileUrl: imageUploadData.orgLogo,
                        orgId
                      });
                      setLoading(false);
                      setLoadingText(null);
                    }}
                    onImagePreview={() => {
                      setLoading(true);
                      setLoadingText('Processing image...');
                    }}
                    allowRemoveImage={false}
                    imageAltText={`${name}`}
                  />
                </div>

                {enableNewEditor && (
                  <RenderTinyMceEditor
                    id="org-welcomeMessage"
                    contentId={id}
                    contentType="organization"
                    className="list-group-item mb-3"
                    title="Welcome Message"
                    subtitle="Message shown to new users when they first join."
                    defaultValue={welcomeMessage}
                    editorRef={welcomeMessageRef}
                  />
                )}

                <div id="org-restricted-register" className="list-group-item">
                  <label className="mb-1">
                    <b>Restrict Registration:</b>
                  </label>
                  <div className="text-muted medium mb-2">
                    Only allow users to register that are sent an invitation. Hides "Sign Up" on the login screen.
                  </div>
                  <RadioGroup
                    name="restrictRegister"
                    value={restrictRegister}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setRestrictRegister(value === 'true');
                    }}
                  />
                </div>

                <div id="org-billing" className="list-group-item">
                  <label className="mb-1">
                    <b>User Billing:</b>
                  </label>
                  <div className="text-muted medium mb-2">
                    Enable billing page for {roleReadable(['student'], orgType)}s. <b>Stripe integration required.</b>
                  </div>
                  <RadioGroup
                    name="billing"
                    value={billing}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setBilling(value === 'true');
                    }}
                  />
                </div>

                <div id="org-enableCourseCatalog" className="list-group-item">
                  <label className="mb-1">
                    <b>Course Catalog:</b>
                  </label>
                  <div className="text-muted medium mb-2">
                    The Course Catalog allows users to discover courses. If hidden, users can only access courses they are enrolled in.
                  </div>
                  <RadioGroup
                    name="enableCourseCatalog"
                    value={enableCourseCatalog}
                    labelYes="Visible"
                    labelNo="Hidden"
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setEnableCourseCatalog(value === 'true');
                    }}
                  />
                </div>

                <div id="class-slug" className="list-group-item">
                  <label htmlFor="slug">
                    <b>Slug:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Machine readable name of organization for use in URLs.
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="slug"
                    defaultValue={slug}
                    required
                    disabled={!!id}
                  />
                </div>

              </div>
            </div>
          </div>

          {/* <RenderMdEditor
            defaultValue={testEditor}
            onChange={(content) => {
              setTestEditor(content);
            }}
          /> */}

          <div id="org-settings" className="card mb-3">
            <div className="card-header">
              <h5 className="m-0 text-capitalize">
                Integrations
              </h5>
            </div>

            <div id="org-integrations" className="card-body">

              {/* MICROSOFT TEAMS */}
              <div id="org-integrations-microsoft-teams" className="d-flex border mb-3">
                <div className="border-right p-3 d-flex align-items-center justify-content-center">
                  <div>
                    <img src={MICROSOFT_TEAMS_ICON} alt="Microsoft Teams Icon" width="25" />
                  </div>
                </div>
                <div className="p-3">
                  <div className="mb-1 h6 font-weight-bold border-bottom pb-2">
                    Microsoft Teams
                  </div>
                  <div className="mb-2">
                    Teams Chat integration surfaces a chat button for quick direct messaging.
                    <br />
                    Users are required to use their work email for their Turbine Workforce account.
                  </div>

                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="integrateMicrosoftTeams"
                      defaultChecked={integrateMicrosoftTeams}
                      onClick={(e) => {
                        const { checked } = e.currentTarget;

                        setIntegrateMicrosoftTeams(!!checked);
                      }}
                    />
                    <label
                      className="custom-control-label text-nowrap"
                      htmlFor="integrateMicrosoftTeams"
                    >
                      Chat in Teams
                    </label>
                  </div>
                </div>
              </div>

              {/* SLACK */}
              <div id="org-integrations-slack" className="d-flex border mb-3">
                <div className="border-right p-3 d-flex align-items-center justify-content-center">
                  <div>
                    <img src={SLACK_ICON} alt="Slack Icon" width="25" />
                  </div>
                </div>
                <div className="p-3">
                  <div className="mb-1 h6 font-weight-bold border-bottom pb-2">
                    Slack
                  </div>
                  <div className="mb-2">
                    Slack Workspace integration surfaces a chat button for quick direct messaging.
                    <br />
                    Optionally, on a per-course basis, enable integrations of private or public Slack channels for the course.
                  </div>
                  <div>

                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="slackChatLinks"
                        defaultChecked={integrateSlack}
                        onClick={(e) => {
                          const { checked } = e.currentTarget;

                          setIntegrateSlack(!!checked);
                        }}
                        disabled
                      />
                      <label
                        className="custom-control-label text-nowrap"
                        htmlFor="slackChatLinks"
                      >
                        Chat in Slack
                      </label>
                    </div>

                  </div>
                </div>
              </div>

              {/* STRIPE */}
              {orgType !== 'workforce' && (
                <div id="org-integrations-stripe" className="d-flex border overflow-hidden">
                  <div className="border-right p-3 d-flex align-items-center justify-content-center">
                    <div>
                      <img src={STRIPE_ICON} alt="Stripe Icon" width="25" />
                    </div>
                  </div>
                  <div className="p-3">
                    {integrateStripe ? (
                      <div>
                        <div className="mb-1 h6 font-weight-bold border-bottom pb-2">
                          Stripe Connect
                        </div>
                        <div>
                          When a user purchases a Course, your business name, as configured in Stripe, will appear on their Credit Card statement. <small><a href="https://stripe.com/connect" target="_blank" rel="noopener noreferrer">Learn more</a></small>
                        </div>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="stripeUserSubscriptions"
                            defaultChecked={!!stripeUserSubscriptions}
                            onClick={(e) => {
                              const { checked } = e.currentTarget;

                              setIntegrateStripe(!!checked);
                            }}
                            disabled
                          />
                          <label
                            className="custom-control-label text-nowrap"
                            htmlFor="stripeUserSubscriptions"
                          >
                            User Membership/Subscription
                          </label>
                        </div>
                      </div>
                    ) : (
                      <Stripe />
                    )}
                  </div>
                </div>
              )}

            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div>
              <button
                className="btn btn-primary mr-2"
                type="submit"
                disabled={loading}
              >
                {loading ? (loadingText || 'Saving...') : 'Save'}
              </button>

              <Link
                className="btn btn-md btn-link"
                to={orgConsoleDashboardRoute}
                title="Cancel"
                disabled={loading}
              >
                Cancel
              </Link>
            </div>
          </div>

        </div> {/* card-body */}

        <input type="hidden" value={id} name="id" />
        <input type="hidden" value="edit" name="manageType" />
        <input type="hidden" value="organization" name="type" />
      </form>
    </div>
  );
};

export default Form;
