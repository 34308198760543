import React, { Fragment, useState } from 'react';
import { ICON_QUIZ } from '@apprentage/constants';
import Outcome from './Outcome';

const OutcomesHeader = ({
  className = ''
}) => (
  <thead className={`${className} border-0`}>
    <tr>
      <th scope="col">Submitted</th>
      <th scope="col">Score</th>
      <th scope="col">Percentage</th>
      <th scope="col">Attempts</th>
      <th scope="col">Points</th>
      <th scope="col">Feedback</th>
    </tr>
  </thead>
);

const Outcomes = ({
  className = '',
  quizOutcomes,
  showHeader = false
}) => {
  const [showPrevScores, setShowPrevScores] = useState(false);
  const [hasPrevScores, setHasPrevScores] = useState(false);

  if (!quizOutcomes.list) return null;

  return (
    <div
      className={`CurrentQuizOutcomes-container border-right border-left border-bottom ${showHeader ? 'border-top' : ''} ${className}`}
    >
      {showHeader && (
        <div
          className="card-header bg-light p-3 border-0"
        >
          <h5 className="m-0">
            <i className={ICON_QUIZ} /> Outcomes
          </h5>
        </div>
      )}

      <div className="card-body p-0">
        <table className="table table-striped m-0">
          <OutcomesHeader />
          <tbody>
            {quizOutcomes.list.map((outcome, i) => {
              if (outcome.prevScores && !hasPrevScores) {
                setHasPrevScores(true);
              }

              return (
                <Fragment key={i}>
                  <Outcome
                    key={`${outcome.id}-${i}`}
                    data={outcome}
                  />
                  {outcome.prevScores && outcome.prevScores.map((prevOutcome, prevOutcomeIndex) => (
                    <Outcome
                      key={`${outcome.id}-prevOutcome-${prevOutcomeIndex}`}
                      data={prevOutcome}
                      className={showPrevScores ? '' : 'd-none'}
                      prevOutcome
                    />
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {hasPrevScores && (
        <div className="card-footer d-flex justify-content-center">
          <button
            className="btn-link p-0"
            type="button"
            onClick={() => {
              setShowPrevScores(!showPrevScores);
            }}
          >
            {showPrevScores ? 'Hide' : 'View'} Previous Scores
          </button>
        </div>
      )}
    </div>
  );
};

export default Outcomes;
