import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  SEARCH_CONTENT,
  SEARCHES_ALL,
  SEARCHES_USER
} from '../../constants/routes';
import { routeWithOrgId } from '../../services/organizations';
import { canAccessAllSearches } from '../../permissions/access';

const Tabs = ({
  active,
  className = 'mt-3'
}) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item">
          <Link
            className={`nav-link mr-2 ${active === 'search' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: SEARCH_CONTENT,
              orgId
            })}
            title="Search"
          >
            Search
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link mr-2 ${active === 'searchesUser' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: SEARCHES_USER,
              orgId
            })}
            title="My Searches"
          >
            My Searches
          </Link>
        </li>

        {canAccessAllSearches(role) && (
          <li className="nav-item">
            <Link
              className={`nav-link mr-2 ${active === 'searchesAll' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: SEARCHES_ALL,
                orgId
              })}
              title="All Searches"
            >
              All Searches
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Tabs;
