import React from 'react';

const SubscriptionRow = ({
  images, name, children
}) => {
  return (
    <div
      className="bg-white p-3 border d-flex justify-content-between align-items-center mb-2"
    >
      <div>
        {images && images.length && (
          <img
            className="mr-2"
            src={images[0]}
            alt={name}
            height="32"
            style={{
              height: '32px'
            }}
          />
        )}
        <b>{name}</b> Platform
      </div>

      {children && (
        <>
          {children}
        </>
      )}
    </div>
  );
};

export default SubscriptionRow;
