import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import Form from './Form';
import CdnFiles from '../../ManageContent/CdnFiles';

const CourseSettings = () => {
  const course = useSelector((state) => state.course);

  return (
    <>
      <Form />

      <div className="mb-5">
        <CdnFiles
          id="class-integration-files"
          contentId={course.id}
          contentType="class"
          files={course.cdnFiles}
          editMenu
        />
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(CourseSettings);
