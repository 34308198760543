import {
  SET_CURRENT_CLASS, RESET_CURRENT_CLASS, SET_COURSE_FILES
} from '../../actions/types';
import courseState from '../initialState/courseState';

const initialState = {
  ...courseState,
  cdnFiles: null,
  courseTeachers: null,
  fetched: false
};

export default function course(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_CLASS:
      return {
        ...state,
        ...action.course,
        cdnFiles: action.cdnFiles,
        courseTeachers: action.courseTeachers,
        fetched: true
      };

    case SET_COURSE_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_CURRENT_CLASS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
