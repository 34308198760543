import React from 'react';
// import DateCell from '../../../ManageContent/Tables/DateCell';
import Table from './Table';
import ImageCell from './ImageCell';
import IsFeaturedCell from './IsFeaturedCell';
import ButtonCell from './ButtonCell';
import MenuCell from './MenuCell';
import IncludeDataCell from './IncludeDataCell';

const List = ({
  data,
  hiddenColumns = []
}) => {
  const columns = React.useMemo(() => [
    {
      Header: '',
      accessor: 'image',
      Cell: ImageCell,
      disableSortBy: true
    },
    {
      Header: 'Title',
      accessor: 'title'
      // Cell: TitleCell
    },
    {
      Header: 'Subtitle',
      accessor: 'body', // TODO change to subtitle
      disableSortBy: true
    },
    {
      Header: 'Pinned',
      id: 'isFeatured',
      accessor: (props) => props?.isFeatured,
      Cell: IsFeaturedCell
    },
    {
      Header: 'User Data',
      accessor: 'dataCol',
      Cell: IncludeDataCell,
      disableSortBy: true
    },
    {
      Header: '',
      accessor: 'url',
      disableSortBy: true,
      Cell: ButtonCell
    },
    {
      Header: '',
      accessor: 'menuCol',
      Cell: MenuCell,
      disableSortBy: true
    }
  ], []);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Tools.
        </div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={data}
    />
  );
};

export default List;
