import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import {
  createFirebaseEntry,
  deleteFirebaseEntry,
  getFirebaseEntriesNew,
  getFirebaseEntry,
  updateFirebaseEntry
} from './firebaseProxy';
import * as twApi from './tw-api';
import { COS_TRAINING_INSTITUTIONS } from '../constants/api';

const collection = 'instructionProviders';

/**
 * @param {Array} ids
 * @param {String} orgId
 */
export const fetchInstructionProviders = ({
  ids = [],
  orgId,
  order = 'name',
  limit = 100,
  after,
  before
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      order,
      limit,
      ...(after ? { after } : {}),
      ...(before ? { before } : {})
    };

    // Training Program: Workforce
    if (orgId) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getFirebaseEntriesNew(
      {
        collection,
        ...config
      },
      'v2'
    )
      .then((response) => {
        resolve({
          list: response?.items?.length ? response?.items : null,
          total: response?.total || 0,
          pagination: response?.pagination || null
        });
      })
      .catch((error) => {
        console.error(`fetch ${collection}`, error);
        reject(error);
      });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchInstructionProvider = (id) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntry({ collection, id })
      .then((response) => {
        const project = response || null;

        resolve(project);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const createInstructionProvider = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection })
      .then((response) => {
        const docId = pathOr(null, ['docId'], response);

        resolve(docId);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${collection}`, error);
      });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const updateInstructionProvider = (data, id) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${collection}`, error);
      });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const deleteInstructionProvider = (id) => {
  return new Promise((resolve) => {
    deleteFirebaseEntry({
      collection,
      id
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`delete ${collection}`, error);
      });
  });
};

export const fetchTrainingInstitutions = async ({
  location = '',
  radius = '',
  occupation = '',
  programName = '',
  programLength = '',
  state = '',
  sortColumns = '',
  sortDirections = '',
  startRecord = '',
  limitRecord = ''
}) => {
  const config = {};
  const isValidValue = (value) =>
    !(value === '' || value === undefined || value === null);
  if (isValidValue(location)) config.location = location;
  if (isValidValue(radius)) config.radius = radius;
  if (isValidValue(occupation)) config.occupation = occupation;
  if (isValidValue(programName)) config.programName = programName;
  if (isValidValue(programLength)) config.programLength = programLength;
  if (isValidValue(state)) config.state = state;
  if (isValidValue(sortColumns)) config.sortColumns = sortColumns;
  if (isValidValue(sortDirections)) config.sortDirections = sortDirections;
  if (isValidValue(startRecord)) config.startRecord = startRecord;
  if (isValidValue(limitRecord)) config.limitRecord = limitRecord;
  const response = await twApi.get(COS_TRAINING_INSTITUTIONS, config);
  return response;
};
