import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import './style.css';

const DefaultPrompts = ({
  list,
  // loading = false,
  onClick
}) => {
  if (Array.isArray(list) && list.length > 0) {
    return (
      <div className='DefaultPrompts'>
        <p>
          Here are some examples of questions I've answered recently:
        </p>
        <div className='border rounded'>
          {list.map((defaultPrompt, index) => (

            <div
              key={`defaultPrompt-${index}`}
              className={`p-3 defaultPrompt ${index === 0 ? '' : 'border-top'} ${onClick ? '' : 'pointer-events-none'}`}
              {...(onClick ? { onClick: () => { onClick(defaultPrompt.message); } } : {})}
              aria-label={defaultPrompt.message}
              aria-hidden
            >
              <span className='d-flex align-items-center justify-content-between'>
                <span className='mr-2'>
                  {defaultPrompt.message}
                </span>

                <FaChevronRight />
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default DefaultPrompts;
