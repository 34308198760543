import {
  fetchInstructionProvider,
  fetchInstructionProviders
} from '../services/instructionProviders';
import {
  SET_INSTRUCTION_PROVIDERS,
  RESET_INSTRUCTION_PROVIDERS,
  SET_INSTRUCTION_PROVIDER,
  RESET_INSTRUCTION_PROVIDER,
  SET_APPRENTICESHIP_USER_INSTRUCTION_PROVIDER,
  SET_ENTRY_ORG_ID
} from './types';

export const getInstructionProviders = ({
  orgId,
  instructionProviderIds,
  limit,
  after,
  before,
  page
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchInstructionProviders({
        orgId,
        ...(instructionProviderIds ? { ids: instructionProviderIds } : {}),
        limit,
        after,
        before
      }).then((response) => {
        const list = response?.list;

        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        dispatch({
          type: SET_INSTRUCTION_PROVIDERS,
          limit,
          list,
          total: response?.total,
          pagination: response?.pagination,
          page
        });
        resolve(response);
      }).catch((error) => {
        console.error('fetch instruction providers', error);
        reject(error);
      });
    });
  };
};

export const getInstructionProvider = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchInstructionProvider(id).then((instructionProvider) => {
        dispatch({
          type: SET_INSTRUCTION_PROVIDER,
          instructionProvider,
          ...(instructionProvider?.cdnFiles ? { cdnFiles: instructionProvider?.cdnFiles } : {})
        });
        resolve(instructionProvider);
      }).catch((error) => {
        console.error('fetch instruction provider', error);
        reject(error);
      });
    });
  };
};

/**
 *
 * Apprentice User Instruction Provider
 *
 */
export const getApprenticeshipUserInstructionProvider = ({
  instructionProviderId = '',
  instructionProviders = {},
  apprenticeshipId = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Given: instructionProviderId, use it
      let id = instructionProviderId;

      // Given: no instructionProviderId is provided
      // And: instructionProviders has an entry for the apprenticeshipId
      if (!id && apprenticeshipId && instructionProviders[apprenticeshipId] !== undefined) {
        id = instructionProviders[apprenticeshipId];
      }

      if (!id) {
        console.error('getApprenticeshipUserWageSchedule: missing required arguments');
        resolve(null);
        return;
      }

      fetchInstructionProvider(id).then((userInstructionProvider) => {
        dispatch({
          type: SET_APPRENTICESHIP_USER_INSTRUCTION_PROVIDER,
          userInstructionProvider
        });
        resolve(userInstructionProvider);
      }).catch((error) => {
        console.error('getApprenticeshipUserInstructionProvider', error);
        reject(error);
      });
    });
  };
};

export const resetInstructionProvider = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INSTRUCTION_PROVIDER });
  };
};

export const resetInstructionProviders = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INSTRUCTION_PROVIDERS });
  };
};
