import { dateTimestamp } from '../../utils/date';

function formatUser(data, entry) {
  const {
    userProfileId,
    membership,
    role,
    classId,
    classIds, // String[]
    completedClassId, // (NEW) String[] || null
    completedClassIdsToRemove, // (NEW) String[] || null
    completedTopicId, // (NEW) String[] || null
    completedTopicIdsToRemove, // (NEW) String[] || null
    cohortId,
    extRegClassId,
    apprenticeshipId,
    // LMS (Adding a Certificate)
    uniqueCertificateId, // (NEW)
    uniqueCertificateData, // (NEW) Object
    // ADMIN (Accepting a Certificate)
    certificateId,
    acceptedCertificateCourseId,
    ...remainingData
  } = data;

  const dataToSave = {
    ...remainingData
  };

  if (userProfileId !== undefined) {
    // Edge Case: Create vs Edit mode
    // Given: Admin opened User Profile Form when the User had no User Profile
    // When: User created their User Profile in (lms or lms-console)
    // Then: Admin tried saving the User Profile
    if (entry?.userProfileId) {
      throw Error('User already has a PII User Profile.');
    }

    dataToSave.userProfileId = userProfileId;
  }

  if (membership !== undefined) {
    dataToSave.membership = [membership];
  }

  if (role !== undefined) {
    dataToSave.role = [role];
  }

  // Course Enrollment
  if (classId !== undefined || classIds !== undefined) {
    dataToSave.courseIds = Array.isArray(entry?.courseIds) ? entry?.courseIds : [];

    // De-dupe
    if (classId) {
      if (!dataToSave.courseIds.includes(classId)) {
        dataToSave.courseIds.push(classId);
      }
    }

    // De-dupe
    if (Array.isArray(classIds)) {
      classIds.forEach((id) => {
        if (!dataToSave.courseIds.includes(id)) {
          dataToSave.courseIds.push(id);
        }
      });
    }
  }

  // COURSES: Completed Courses
  if (completedClassId !== undefined) {
    dataToSave.completedCourseIds = Array.isArray(entry?.completedCourseIds) ? entry?.completedCourseIds : [];

    // De-dupe
    if (!dataToSave.completedCourseIds.includes(completedClassId)) {
      dataToSave.completedCourseIds.push(completedClassId);
    }
  }

  // COURSES: Data Clean Up: Remove Completed Class IDs
  if (Array.isArray(completedClassIdsToRemove) && Array.isArray(entry?.completedCourseIds)) {
    // Remove all Courses if their IDs are in completedClassIdsToRemove array
    const newCompletedClassIds = entry?.completedCourseIds.filter((id) => !completedClassIdsToRemove.includes(id));

    dataToSave.completedCourseIds = newCompletedClassIds.length === 0 ? null : newCompletedClassIds;
  }

  // COURSES: Completed Topics
  if (completedTopicId !== undefined) {
    dataToSave.completedCourseTopicIds = Array.isArray(entry?.completedCourseTopicIds) ? entry?.completedCourseTopicIds : [];

    // De-dupe
    if (!dataToSave.completedCourseTopicIds.includes(completedTopicId)) {
      dataToSave.completedCourseTopicIds.push(completedTopicId);
    }
  }

  // COURSES: Data Clean Up: Remove Completed Topic IDs
  if (Array.isArray(completedTopicIdsToRemove) && Array.isArray(entry?.completedCourseTopicIds)) {
    // Remove all Topics if their IDs are in completedTopicIdsToRemove array
    const newCompletedTopics = entry?.completedCourseTopicIds.filter((topicId) => !completedTopicIdsToRemove.includes(topicId));

    dataToSave.completedCourseTopicIds = newCompletedTopics.length === 0 ? null : newCompletedTopics;
  }

  // COURSES: Completion Certificates
  if (uniqueCertificateId !== undefined) {
    dataToSave.uniqueCertificateIds = Array.isArray(entry?.uniqueCertificateIds) ? entry?.uniqueCertificateIds : [];

    // De-dupe
    if (!dataToSave.uniqueCertificateIds.includes(uniqueCertificateId)) {
      dataToSave.uniqueCertificateIds.push(uniqueCertificateId);
    }
  }

  // COURSES: External Registration
  if (extRegClassId !== undefined) {
    dataToSave.externalRegisteredCourseIds = Array.isArray(entry?.externalRegisteredCourseIds) ? entry?.externalRegisteredCourseIds : [];

    // De-dupe
    if (!dataToSave.externalRegisteredCourseIds.includes(extRegClassId)) {
      dataToSave.externalRegisteredCourseIds.push(extRegClassId);
    }
  }

  // COURSES : Cohorts
  if (cohortId !== undefined) {
    dataToSave.cohortIds = Array.isArray(entry?.cohortIds) ? entry?.cohortIds : [];

    if (cohortId !== undefined) {
      dataToSave.cohortIds = Array.isArray(entry?.cohortIds) ? entry?.cohortIds : [];

      // De-dupe
      if (!dataToSave.cohortIds.includes(cohortId)) {
        dataToSave.cohortIds.push(cohortId);
      }
    }
  }

  // COURSES : Completion Certificates
  if (uniqueCertificateData !== undefined) {
    // if no integration key, create default structure
    dataToSave.integration = !entry?.integration ? {} : entry?.integration;

    let newData = {};

    if (entry?.integration?.uniqueCertificateData === undefined) {
      // if uniqueCertificateData undefined, set to data
      newData = { ...uniqueCertificateData };
    } else {
      // has uniqueCertificateData, spread existing, add new from data
      newData = {
        ...entry.integration.uniqueCertificateData,
        ...uniqueCertificateData
      };
    }

    dataToSave.integration.uniqueCertificateData = newData;
  }

  if (certificateId && acceptedCertificateCourseId) {
    dataToSave.integration = entry?.integration ? entry?.integration : {};

    const certData = dataToSave?.integration?.uniqueCertificateData;
    const cert = certData && certData[certificateId] ? certData[certificateId] : null;

    if (certData && cert) {
      if (!cert.acceptedDate) {
        dataToSave.integration.uniqueCertificateData[certificateId].acceptedDate = dateTimestamp();
      }

      // Save course ID so admins can filter users by accepted certificates
      dataToSave.acceptedCertificateCourseIds = Array.isArray(entry?.acceptedCertificateCourseIds) ? entry?.acceptedCertificateCourseIds : [];

      // Dedupe
      if (!dataToSave.acceptedCertificateCourseIds.includes(acceptedCertificateCourseId)) {
        dataToSave.acceptedCertificateCourseIds.push(acceptedCertificateCourseId);
      }
    }
  }

  // APPRENTICESHIPS
  if (apprenticeshipId !== undefined) {
    // Mark user as apprentice (gather private data)
    //
    //
    // TODO Temporarily comment out for Demo
    //
    // dataToSave.isApprentice = true;
    //
    //

    dataToSave.apprenticeshipIds = Array.isArray(entry?.apprenticeshipIds) ? entry?.apprenticeshipIds : [];

    // De-dupe
    if (apprenticeshipId) {
      if (!dataToSave.apprenticeshipIds.includes(apprenticeshipId)) {
        dataToSave.apprenticeshipIds.push(apprenticeshipId);
      }
    }
  }

  return dataToSave;
}

export default formatUser;
