import { referenceType } from '../contentful';

function formatChallenge({
  difficulty,
  questions,
  challengeType, // url || form
  title,
  question,
  solution, // Solution (Hint)
  instructorNotes,
  classId,
  topicId,
  orgId,
  submissionPromptText,
  activeChallenge,
  enableNewEditor,
  challengeSkills
}) {
  const data = {};

  if (difficulty !== undefined) {
    data.difficulty = {
      'en-US': difficulty
    };
  }

  if (challengeType !== undefined) {
    data.challengeType = {
      'en-US': challengeType
    };
  }

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (classId !== undefined) {
    data.class = {
      'en-US': referenceType(classId)
    };

    data.classId = {
      'en-US': classId
    };
  }

  if (topicId !== undefined) {
    data.topicId = {
      'en-US': topicId
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  // Challenge Type : URL
  if (question !== undefined) {
    data.question = {
      'en-US': question
    };
  }

  // Challenge Type : Form
  if (questions !== undefined) {
    data.questions = {
      'en-US': questions
    };
  }

  // Solution (Hint)
  if (solution !== undefined) {
    data.solution = {
      'en-US': solution
    };
  }

  if (instructorNotes !== undefined) {
    data.instructorNotes = {
      'en-US': instructorNotes
    };
  }

  if (submissionPromptText !== undefined) {
    data.submissionPromptText = {
      'en-US': submissionPromptText
    };
  }

  if (activeChallenge !== undefined) {
    data.activeChallenge = {
      'en-US': activeChallenge
    };
  }

  /**
   * {array of strings || undefined || null } challengeSkills
   * FE will send null which is then converted to undefined
   */
  if (challengeSkills !== undefined) {
    data.challengeSkills = {
      // BE needs the value to be undefined when there are no challengeSkills
      'en-US': challengeSkills === null ? undefined : challengeSkills
    };
  }

  if (enableNewEditor !== undefined) {
    data.enableNewEditor = {
      'en-US': enableNewEditor
    };
  }

  return { fields: { ...data } };
}

export default formatChallenge;
