import React, { useEffect, useRef, useState } from 'react';
import { FaPaperPlane, FaTimesCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DotMenu from './DotMenu';

const InputField = ({
  className = '',
  performQuery,
  id = 'searchValueInput',
  name = 'searchValueInput',
  value,
  disabled = false,
  placeholder = ''
  // autoFocus = false
}) => {
  const searchInputRef = useRef(null);
  const [searchInputValue, setSearchInputValue] = useState(value || '');

  const clearSearchResults = () => {
    setSearchInputValue('');
  };

  const onChange = (e) => {
    const { value: inputValue } = e.currentTarget;

    setSearchInputValue(inputValue);
  };

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  return (
    <div
      className={`${className} p-3`}
      style={{
        backgroundColor: '#3e3f4b'
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (!searchInputValue.length) {
            toast.error('Query is required.');
            return false;
          }

          performQuery(searchInputValue, 'userPrompt');
          clearSearchResults();
        }}
      >
        <div className="d-flex">

          <div className="position-relative w-100 mr-2">
            <textarea
              id={id}
              ref={searchInputRef}
              type="text"
              className="form-control form-control-lg w-100 border-0"
              name={name}
              value={searchInputValue}
              onChange={onChange}
              placeholder={placeholder}
              disabled={disabled}
              autoFocus
            />
            {searchInputValue && (
              <button
                type="button"
                className="btn btn-link position-absolute"
                onClick={clearSearchResults}
                title="Reset Search"
                aria-label="Reset Search"
                style={{
                  top: '5px',
                  right: '5px'
                }}
              >
                <FaTimesCircle />
              </button>
            )}
          </div>
          <div>
            <DotMenu
              disabled={disabled}
              performQuery={performQuery}
            />

            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={disabled}
              title='Send'
              aria-label="label"
            >
              <FaPaperPlane />
            </button>
          </div>

        </div>
      </form>
    </div>
  );
};

export default InputField;
