import React from 'react';
import { Link } from 'react-router-dom';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { MANAGE_CURRENT_ENTRY } from '../../../../constants/routes';
import { manageEntry } from '../../../../services/entry';

const EditCell = ({ value: id, row }) => {
  const { original: { title, topicId, classId } } = row;

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white">
            <h6 className="m-0 text-nowrap">
              {title}
            </h6>
          </div>
          <Link
            to={{
              pathname: MANAGE_CURRENT_ENTRY,
              search: manageEntry({
                manageType: 'edit',
                entryId: id,
                topicId,
                classId,
                contentType: 'challenge'
              })
            }}
            title="Settings"
            className="dropdown-item py-2"
          >
            <span className='d-flex align-items-center'>
              <FaCog />
              <span className='ml-2'>
                Settings
              </span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EditCell;
