import { pathOr } from 'ramda';
import React from 'react';

const NameCell = ({
  value: name,
  row,
  height = 40,
  width = 40
}) => {
  const locationImage = pathOr(null, ['original', 'locationImage'], row);
  const subtitle = pathOr(null, ['original', 'subtitle'], row);

  return (
    <div
      data-cy="EmployersList-name"
      className="d-flex align-items-center"
    // style={{
    //   backgroundColor: classColor || CLASS_COLOR_DEFAULT,
    //   height: `${height}px`,
    //   width: `${width}px`
    // }}
    >
      {locationImage && (
        <img
          src={locationImage}
          alt={name}
          height={height}
          width={width}
          style={{
            height: `${height}px`,
            width: `${width}px`
          }}
        />
      )}

      <div className='ml-2'>
        <div className='font-weight-bold'>
          {name}
        </div>

        {subtitle && (
          <div>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default NameCell;
