import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaCopy, FaEllipsisH, FaPencilAlt } from 'react-icons/fa';
import { getFullAddress } from '../../../services/locations';
import { setCurrentModal } from '../../../actions/Modals';
import copyToClipBoard from '../../../utils/copyToClipBoard';
import { canManageInstructionProvider } from '../../../permissions/manage';

const DotMenuInfoBlock = ({ data }) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const instructionProvider = useSelector((state) => state.instructionProvider);
  // Current User
  const role = currentUser?.role || [];
  // Instruction Provider
  const fullAddress = getFullAddress({ location: data });

  const editInstructionProvider = () => {
    dispatch(setCurrentModal({
      key: 'manageInstructionProvider',
      data: {
        instructionProviderId: instructionProvider?.id
      }
    }));
  };

  return (
    <div className="btn-group align-items-center">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label="Manage Menu"
      >
        <FaEllipsisH size={15} />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap" />

        <button
          type="button"
          title='Copy Address'
          className="dropdown-item border-bottom py-2"
          onClick={() => {
            copyToClipBoard(fullAddress);
            toast.success('Address Copied!');
          }}
        >
          <span className='d-flex align-items-center'>
            <FaCopy size={15} />
            <span className='ml-2'>
              Copy Address
            </span>
          </span>
        </button>

        {/* {canManageInstructionProvider(role) && (
          <Link
            className="dropdown-item py-2"
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_LOCATIONS}/manage/${locationId}`,
              urlParams: {
                orgId,
                ...(redirectRoute ? { redirectRoute } : {})
              }
            })}
            title={`Edit ${locationTerm.text}`}
          >
            <i className={`${ICON_PENCIL} mr-1`} /> Edit
          </Link>
        )} */}

        {canManageInstructionProvider(role) && (
          <button
            type="button"
            title='Edit'
            className="dropdown-item py-2"
            onClick={editInstructionProvider}
          >
            <span className='d-flex align-items-center'>
              <FaPencilAlt size={15} />
              <span className='ml-2'>
                Edit
              </span>
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default DotMenuInfoBlock;
