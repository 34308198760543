const userProfileState = {
  // sys
  id: null,
  createdAt: null,
  updatedAt: null,
  // Apprentice (Generated when signing)
  employmentStatus: null,
  employmentStatusDate: null,
  ssn: null,
  gender: null,
  dob: null,
  ethnicity: null,
  races: null,
  veteranStatus: null,
  disability: null,
  educationLevel: null,
  orgReferral: null,
  citizenStatus: null,
  addressLine1: null,
  addressLine2: null,
  addressCity: null,
  addressState: null,
  addressPostalCode: null,
  addressCountry: null,
  criminalBkg: null,
  instructionProviders: null,
  wageSchedules: null
};

export default userProfileState;
