import {
  SET_CURRENT_ENTRY, RESET_CURRENT_ENTRY, SET_CURRENT_ENTRY_FILES
} from '../../actions/types';

const initialState = {
  id: '',
  title: '',
  name: '',
  code: '',
  createdAt: '',
  updatedAt: '',
  cdnFiles: null,
  cdnFileNames: null,
  itemIds: null
};

export default function currentEntry(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ENTRY:
      return {
        ...state,
        ...action.currentEntry,
        cdnFiles: action.cdnFiles,
        cdnFileNames: action.cdnFileNames,
        itemIds: action.itemIds || initialState.itemIds
      };
    case SET_CURRENT_ENTRY_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles,
        cdnFileNames: action.cdnFileNames
      };
    case RESET_CURRENT_ENTRY:
    // case RESET_CURRENT_CLASS: // TODO why is this needed?
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
