import React from 'react';
import {
  FaBell, FaCode, FaImages, FaLink
} from 'react-icons/fa';
import { DASHBOARD_MODULE_TYPE, DASHBOARD_MODULES } from '../../../constants/dashboard';

export const modulesTypesHash = {
  [DASHBOARD_MODULE_TYPE.projects]: {
    title: DASHBOARD_MODULES.PROJECT,
    icon: <FaImages />,
    type: DASHBOARD_MODULE_TYPE.projects
  },
  [DASHBOARD_MODULE_TYPE.notifications]: {
    title: DASHBOARD_MODULES.NOTIFICATIONS,
    icon: <FaBell />,
    type: DASHBOARD_MODULE_TYPE.notifications
  },
  [DASHBOARD_MODULE_TYPE.embeds]: {
    title: DASHBOARD_MODULES.EMBED_WIDGETS_LIST,
    icon: <FaCode />,
    type: DASHBOARD_MODULE_TYPE.embeds
  },
  [DASHBOARD_MODULE_TYPE.links]: {
    title: DASHBOARD_MODULES.LINK_WIDGETS_LIST,
    icon: <FaLink />,
    type: DASHBOARD_MODULE_TYPE.links
  }
};
