import { pathOr } from 'ramda';
import { client, flattenItem } from '../services/contentful';
import { RESET_E_UNIT_TRACKING, SET_E_UNIT_TRACKING } from './types';

export const setEUnitTracking = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      client.getEntry(id).then((response) => {
        const eUnitTracking = response ? flattenItem(response) : null;
        const sessionCredits = pathOr(null, ['integration', 'sessionCredits'], eUnitTracking);
        const cdnFiles = pathOr(null, ['integration', 'cdnFiles'], eUnitTracking);

        dispatch({
          type: SET_E_UNIT_TRACKING,
          eUnitTracking,
          sessionCredits,
          cdnFiles
        });
        resolve(eUnitTracking);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetEUnitTracking = () => {
  return (dispatch) => {
    dispatch({ type: RESET_E_UNIT_TRACKING });
  };
};
