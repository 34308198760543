import React from 'react';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import { VELA_ICON } from '@apprentage/constants';
import Table from './Table';
import DateCell from '../../../ManageContent/Tables/DateCell';
import UserName from './UserNameCell';
import LocationCell from '../../../ManageContent/Tables/LocationCell';
import NumberCell from '../../../ManageContent/Tables/NumberCell';

const List = ({
  data,
  editItem,
  handleClickRow
}) => {
  const columns = React.useMemo(() => [
    {
      Header: 'User',
      accessor: 'userName',
      Cell: (props) => (
        <UserName {...props} />
      )
    },
    {
      Header: 'Employer',
      accessor: 'employerId',
      Cell: (props) => (
        <LocationCell {...props} />
      )
    },
    {
      Header: () => {
        return (
          <span className='d-flex-inline align-items-center'>
            <img
              src={VELA_ICON}
              alt="VELA"
              className="d-none d-sm-inline-block mr-2"
              height={20}
              style={{
                height: '20px'
              }}
            />
            <span>Records</span>
          </span>
        );
      },
      accessor: 'recordsCount',
      Cell: NumberCell
    },
    {
      Header: () => {
        return (
          <span className='d-flex-inline align-items-center'>
            <FaCircle className='text-primary mr-2 d-inline' />
            <span>Hours Logged</span>
          </span>
        );
      },
      accessor: 'recordsHours',
      Cell: NumberCell
    },
    {
      Header: () => {
        return (
          <span className='d-flex-inline align-items-center'>
            <FaCheckCircle className='text-success mr-2 d-inline' />
            <span>Hours Approved</span>
          </span>
        );
      },
      accessor: 'recordsHoursApproved',
      Cell: NumberCell
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: (props) => (
        <DateCell {...props} />
      )
    }
  ], [editItem]);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Logbooks.
        </div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      data={data}
    />
  );
};

export default List;
