import { pathOr } from 'ramda';
import { updateUserAttendanceAndUsers } from '../actions/Users';
import { roleReadable } from './currentUser';
import { updateUser } from './user';

/**
 * Return attendance records grouped by courseCohorts
 * @param {arr} courseAttendance
 * @param {string} cohortId
 * @returns {arr}
 */
export const courseAttendanceByCohort = ({ courseAttendance, cohortId }) => {
  if (!courseAttendance || !cohortId) return false;

  const attendanceEntries = courseAttendance.filter((item, i) => {
    if (cohortId && item.cohort && item.cohort.id && item.cohort.id === cohortId) {
      // Expose overall index of attendance record in user.attendance
      // to support editing attendance records
      // "courseAttendanceIndex" not saved to DB
      item.courseAttendanceIndex = i;

      return item;
    }

    return false;
  });

  return attendanceEntries;
};

export const generateCourseRecord = ({
  student, classId, classTitle, currentCohort, attendance
}) => {
  let courseRecord = {};
  const cohortId = pathOr(null, ['id'], currentCohort);

  if (classId) {
    if (attendance[classId] !== undefined) {
      const data = {
        student,
        title: classTitle || '',
        id: classId,
        attendance: attendance[classId]
      };

      if (cohortId) {
        const { eUnitTracking, eUnits } = currentCohort;
        const cohortAttendance = courseAttendanceByCohort({
          courseAttendance: attendance[classId],
          cohortId
        });

        if (eUnitTracking) {
          data.eUnitTracking = eUnitTracking;
        }

        if (eUnits) {
          data.eUnits = eUnits;
        }

        // TODO wouldn't these IDs always be the same?
        if (currentCohort.id && currentCohort.id === cohortId) {
          data.attendance = cohortAttendance;
        }
      }

      courseRecord = data;
    }
  }

  return courseRecord;
};

export const totalSessionCredits = ({ sessionCredits }) => {
  if (!sessionCredits) return null;

  let totalCredits = 0;

  sessionCredits.forEach(({ number }) => {
    totalCredits += number;
  });

  return totalCredits;
};

export const updateUserAttendance = ({
  courseAttendanceIndex,
  selectedRecord,
  orgType,
  classId,
  userId
}) => {
  return (dispatch, getState) => {
    const { currentUser, users } = getState();
    const reviewerId = pathOr('', ['id'], currentUser);
    const reviewerRole = pathOr([], ['role'], currentUser);
    const reviewerName = pathOr('', ['name'], currentUser);

    const user = users.list ? users.list.find((u) => u.id === userId) : null;

    const userAttendance = pathOr(undefined, ['attendance'], user);

    return new Promise((resolve, reject) => {
      const data = {
        ...selectedRecord,
        date: selectedRecord.records[0],
        recordTaker: roleReadable({ role: reviewerRole, orgType }),
        instructor: {
          id: reviewerId,
          name: reviewerName
        }
      };

      const attendance = (userAttendance === undefined) ? {} : { ...userAttendance };

      if (!attendance[classId]) {
        attendance[classId] = [];
      }

      if (courseAttendanceIndex !== undefined && attendance[classId][courseAttendanceIndex]) {
        // Edit
        attendance[classId][courseAttendanceIndex] = data;
      } else {
        // Add new
        attendance[classId].push(data);
      }

      attendance[classId].forEach((att) => {
        // TODO how does courseAttendanceIndex get into Redux?
        if (att.courseAttendanceIndex) delete att.courseAttendanceIndex;
      });
      // /TODO

      // Sort by session
      // attendance[classId].sort((a, b) => (a.session > b.session) ? 1 : -1);

      attendance[classId].sort((a, b) => ((a.cohort.id > b.cohort.id) ? 1 : (a.cohort.id === b.cohort.id) ? ((a.session > b.session) ? 1 : -1) : -1));

      updateUser({
        attendance
      }, userId).then(() => {
        dispatch(updateUserAttendanceAndUsers({
          userId,
          attendance
        })).then(() => {
          resolve();
        });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};
