import {
  isAdminOwnerSuperAdmin,
  isProgramManager,
  isProgramTrainer
} from '@apprentage/utils';

export default function canAccessProgramTrainingDocs(role = [], enableWorkforceSuite = false) {
  if (!enableWorkforceSuite) {
    return false;
  }

  return (
    isProgramTrainer(role)
    || isProgramManager(role)
    || isAdminOwnerSuperAdmin(role)
  );
}
