import React from 'react';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { MODULES_PAGINATION_LIMIT } from '../../../../constants/api';
import PaginationSupabase from '../../../ManageContent/PaginationSupabase';
import List from './List';

const SelectModulesData = ({
  title,
  tableData = {},
  handleOnClickRow,
  setDataConfig,
  handleSubmitConfig,
  handleClose
}) => {
  return (
    <>
      <div className="card-body p-0">
        {title && (
          <div className="h5 p-3 m-0">
            {title}
          </div>
        )}

        <div className="p-3">
          <SearchBox
            className="w-100"
            onSubmit={(value) => {
              if (setDataConfig) {
                setDataConfig({
                  page: 1,
                  limit: MODULES_PAGINATION_LIMIT,
                  searchValue: value
                });
              }
            }}
            onClear={() => {
              const searchBox = document.querySelector('input[name="moduleDataSearch"]');
              searchBox.focus();
            }}
            name="moduleDataSearch"
            value=""
            placeholder="Search by title..."
            autoFocus
          />
        </div>

        <List
          data={tableData.list}
          handleOnClickRow={handleOnClickRow}
        />

      </div>
      <div className="card-footer d-flex justify-content-between">
        <div>
          <button
            className="btn btn-primary mr-2"
            onClick={handleSubmitConfig}
            title="Save"
            type="button"
          >
            Save
          </button>

          <button
            className="btn btn-sm btn-link"
            onClick={handleClose}
            title="Cancel"
            type="button"
          >
            Cancel
          </button>
        </div>

        {Array.isArray(tableData.list) && tableData?.list?.length > 0 && (
          <PaginationSupabase
            items={tableData?.list}
            pagination={tableData?.pagination}
            page={tableData?.pagination?.page}
            className=""
            style={{}}
            onClickNext={() => {
              setDataConfig((prev) => ({
                ...prev,
                page: tableData?.pagination?.page + 1
              }));
            }}
            onClickPrev={() => {
              setDataConfig((prev) => ({
                ...prev,
                page: tableData?.pagination?.page - 1
              }));
            }}
          />
        )}
      </div>
    </>
  );
};

export default SelectModulesData;
