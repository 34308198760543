import React from 'react';
import countries from '../constants/countries';

const AddressReadable = ({
  className,
  addressLine1 = '',
  addressLine2 = '',
  addressCity = '',
  addressState = '',
  addressPostalCode = '',
  addressCountry = ''
}) => {
  if (addressLine1 || addressLine2 || addressCity || addressState || addressPostalCode || addressCountry) {
    const country = addressCountry ? countries.find((c) => c.code === addressCountry) : null;

    return (
      <div
        className={className}
        data-demo="disabled"
      >
        {addressLine1 && (
          <div>
            {addressLine1}
            <br />
            {addressLine2 || ''}
          </div>
        )}

        {(addressCity || addressState || addressPostalCode) && (
          <div>
            {addressCity ? `${addressCity}, ` : ''}{addressState} {addressPostalCode}
          </div>
        )}

        {country && country.name && (
          <div>
            {country.name}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default AddressReadable;
