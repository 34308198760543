import React from 'react';
import Table from './Table';

const List = ({
  data,
  handleClickRow
}) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'City',
      accessor: 'addressCity'
    },
    {
      Header: 'Zip Code',
      accessor: 'addressPostalCode'
    }
  ], []);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Instruction Providers.
        </div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      data={data}
    />
  );
};

export default List;
