import React, {
  Fragment, useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHEET_KEY_MATERIAL } from '../../../../../../constants/sideSheets';
import { getMaterial } from '../../../../../../actions/Materials';
import { handleOpenUserSideSheet, setSideSheet } from '../../../../../../actions/SideSheets';
import { getApprenticeshipMaterials } from '../../../../../../actions/Apprenticeships';
import Note from './Note';
import PaginationSupabase from '../../../../../ManageContent/PaginationSupabase';

const Notes = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const apprenticeshipMaterials = useSelector((state) => state.apprenticeshipMaterials);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;

  const hasMaterials = useMemo(() => {
    return Array.isArray(apprenticeshipMaterials?.list) && apprenticeshipMaterials.list.length > 0;
  }, [apprenticeshipMaterials.list]);

  // Pagination
  const initialListConfig = {
    orgId,
    apprenticeshipId,
    page: 1,
    limit: 1
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  const retrieveApprenticeshipMaterials = useCallback(() => {
    dispatch(getApprenticeshipMaterials(listConfig));
  }, [dispatch, listConfig]);

  useEffect(() => {
    retrieveApprenticeshipMaterials();
  }, [retrieveApprenticeshipMaterials]);

  const onClickMaterial = (id, editMode = false) => {
    dispatch(getMaterial(id)).then(() => {
      dispatch(setSideSheet({
        key: SHEET_KEY_MATERIAL,
        data: {
          editMode,
          tinyMceEditor: {
            uploadPdfButton: false,
            embedMediaButton: false,
            uploadImgButton: false
          }
        },
        className: 'MaterialSideSheet',
        callbackPrimaryAction: () => {
          retrieveApprenticeshipMaterials();
        }
      }));
    });
  };

  const onClickTeamMember = (userId) => {
    dispatch(handleOpenUserSideSheet({
      userId,
      orgId,
      currentUserOrgId: currentUser?.orgId
      // route,
      // history
    }));
  };

  if (!hasMaterials) {
    return null;
  }

  return (
    <div
      className="Apprenticeship-Dashboard-Notes h-lg-100"
    >
      <div className='h-lg-100'>
        <div className='shadow rounded bg-white border h-100'>
          {apprenticeshipMaterials.list.map((material) => (
            <Fragment key={material?.id}>
              <Note
                className={apprenticeshipMaterials.pagination.total > 1 ? 'apprenticeshipMaterials-pagination' : ''}
                materialId={material?.id}
                authorId={material?.userId}
                createdAt={material?.createdAt}
                body={material?.body}
                onClickTeamMember={onClickTeamMember}
                onClickEdit={(id) => {
                  onClickMaterial(id, true);
                }}
                onClickView={(id) => {
                  onClickMaterial(id);
                }}
              />
            </Fragment>
          ))}

          {hasMaterials && (
            <div className='card-footer py-0 px-2'>
              <PaginationSupabase
                items={apprenticeshipMaterials?.list}
                pagination={apprenticeshipMaterials?.pagination}
                page={apprenticeshipMaterials?.pagination?.page}
                className=''
                style={{}}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: apprenticeshipMaterials?.pagination?.page + 1
                  }));
                  // goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: apprenticeshipMaterials?.pagination?.page - 1
                  }));
                  // goToElem('root');
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notes;
