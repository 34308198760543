import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaCopy } from 'react-icons/fa';
import copyToClipBoard from '../utils/copyToClipBoard';

const InfoItem = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px;
`;
/**
 *
 * @param {string} param.title REQUIRED
 * @param {string} param.value REQUIRED : this is the value to copy
 * @param {boolean} param.copy enables copy button
 * @param {string} param.className
 * @returns UI that shows title and value with optional copy button
 */
const KeyValueItem = ({
  title = '',
  value,
  copy = true,
  className = '',
  size = 'sm'
}) => {
  const sizeEnum = {
    sm: 'small',
    md: 'medium',
    lg: 'large'
  };

  return (
    <div
      className={`${sizeEnum[size]} d-flex align-items-center ${className}`}
    >
      {title && (
        <strong
          className='mr-2'
        >
          {title}:
        </strong>
      )}

      <InfoItem
        className='text-nowrap'
        data-demo="disabled"
      >
        {value}
      </InfoItem>
      {copy && (
        <button
          type="button"
          title={`Copy ${title}`}
          aria-label={`Copy ${title}`}
          className='btn btn-sm btn-link m-0 px-1'
          onClick={() => {
            copyToClipBoard(value);
            toast.success(`${title} Copied!`);
          }}
        >
          <FaCopy />
        </button>
      )}
    </div>
  );
};

export default KeyValueItem;

KeyValueItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  copy: PropTypes.bool,
  className: PropTypes.string
};
