import React from 'react';
import {
  FaEllipsisH, FaEye, FaFileAlt, FaPencilAlt
} from 'react-icons/fa';
import { mdySimple } from '../../../../../../utils/date';

const DotMenu = ({
  createdAt,
  materialId,
  onClickEdit,
  onClickView,
  className = ''
}) => {
  return (
    <div className={`btn-group align-items-center ${className}`}>
      <button
        className="btn-sm btn-white border text-muted"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label='Manage Menu'
        title="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap">
          <span className='d-flex align-items-center'>
            <FaFileAlt />
            <span className='ml-2'>
              {mdySimple(createdAt)}
            </span>
          </span>
        </div>

        <button
          type="button"
          className="dropdown-item border-bottom px-3 py-2"
          onClick={() => {
            onClickView(materialId);
          }}
        >
          <span className='d-flex align-items-center'>
            <FaEye />
            <span className='ml-2'>
              View
            </span>
          </span>
        </button>
        <button
          type="button"
          className="dropdown-item px-3 py-2"
          onClick={() => {
            onClickEdit(materialId);
          }}
        >
          <span className='d-flex align-items-center'>
            <FaPencilAlt />
            <span className='ml-2'>
              Edit
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default DotMenu;
