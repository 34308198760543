function serializeQuiz(quiz) {
  const currentQuiz = {
    ...quiz,
    rubric: [],
    questions: []
  };

  const {
    id: quizId, title, questions, description, difficulty
  } = quiz;

  currentQuiz.id = quizId;
  currentQuiz.title = title;
  currentQuiz.description = description;
  currentQuiz.difficulty = parseInt(difficulty, 10);

  questions.forEach((question, i) => {
    const data = {
      id: `${quizId}_${i}`,
      title: question.title,
      answers: question.answers
    };

    if (question.description) {
      data.description = question.description;
    }

    if (question.hint) {
      data.hint = question.hint;
    }

    currentQuiz.questions.push(data);

    question.answers.forEach((answer, answerIndex) => {
      if (answer.correct) {
        currentQuiz.rubric.push(answerIndex);
      }
    });
  });

  return currentQuiz;
}

export default serializeQuiz;
