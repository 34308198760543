import { IMG_WEATHER_DEFAULT } from '@apprentage/constants';
import React, { useEffect, useState } from 'react';
import { fetchCurrentWeather } from '../../services/weather';

const WeatherSmall = ({
  postalCode,
  className = ''
}) => {
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    fetchCurrentWeather({
      postalCodes: [postalCode]
    }).then((currentWeather) => {
      if (currentWeather?.current) {
        setWeather(currentWeather?.current);
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [postalCode]);

  return (
    <div className={className}>

      <div
        className="topNav-icon-dropdown-btn d-flex align-items-self rounded pl-2 pt-1 pr-1"
        style={{
          height: 'auto'
        }}
      >
        <span className='small font-weight-bold mr-1'>
          {Math.round(weather?.temp_f || 60)}<sup>&deg;</sup>
        </span>

        <img
          src={weather?.condition?.icon || IMG_WEATHER_DEFAULT}
          height="28"
          style={{
            height: '28px'
          }}
          alt={weather?.condition?.text || 'Current weather'}
        />
      </div>
    </div>
  );
};

export default WeatherSmall;
