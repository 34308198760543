import React from 'react';
import { CLASS_COLOR_DEFAULT } from '../../../constants/globals';

const ClassImage = ({
  title,
  classColor,
  classImage
}) => {
  if (classImage) {
    return (
      <img
        className="card-img-top"
        src={classImage}
        alt={title}
      />
    );
  }

  return (
    <div
      className="card-header"
      style={{
        backgroundColor: classColor || CLASS_COLOR_DEFAULT
      }}
    />
  );
};

export default ClassImage;
