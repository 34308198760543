import React from 'react';
import { useSelector } from 'react-redux';
import AttendanceButton from '../../../btns/AttendanceButton';

const AttendanceCell = ({ row, takeAttendance }) => {
  const course = useSelector((state) => state.course);
  const user = row?.original;

  return (
    <AttendanceButton
      disabled={!course?.isLive}
      showTakeAttendance={takeAttendance}
      user={user}
    />
  );
};

export default AttendanceCell;
