import {
  RESET_CURRENT_ANSWER, SET_CURRENT_ANSWER
} from '../../actions/types';
import answerState from '../initialState/answerState';

const initialState = {
  ...answerState,
  cdnFiles: null,
  fetched: false
};

export default function currentAnswer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ANSWER:
      return {
        ...state,
        ...action.currentAnswer,
        cdnFiles: action.cdnFiles,
        fetched: true
      };
    case RESET_CURRENT_ANSWER:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
