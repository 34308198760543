import React from 'react';

const ReviewCheckboxCell = () => {
  return (
    <div>
      <input type="checkbox" checked readOnly />
    </div>
  );
};

export default ReviewCheckboxCell;
