import styled from 'styled-components';

export const ProfilePictureImgAndButtonContainer = styled.div`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  position: relative;
`;

export const ProfilePictureImgContainer = styled.div`
  border: 1px solid #2c3a47;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;

  img {
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
  }
`;

export const ProfilePictureIconContainer = styled.button`
  background: white;
  bottom: 0;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 0;
  position: absolute;
  text-align: center;

  i {
    font-size: 1rem;
  }
`;
