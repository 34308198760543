import {
  RESET_NOTIFICATIONS_ORG,
  SET_NOTIFICATIONS_ORG,
  SET_NOTIFICATIONS_ORG_PINNED
} from '../../actions/types';

const initialState = {
  list: null,
  pinned: null,
  fetched: false,
  pagination: null
};

export default function notificationsOrg(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS_ORG:
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    case SET_NOTIFICATIONS_ORG_PINNED:
      return {
        ...state,
        pinned: action?.pinned || initialState?.pinned
      };
    case RESET_NOTIFICATIONS_ORG:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
