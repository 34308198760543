import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { v4 as uuid } from 'uuid';
import { FaTimes } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { updateApprenticeship } from '../../../services/apprenticeships';
import { getApprenticeship } from '../../../actions/Apprenticeships';
import findObjectIndexByKey from '../../../utils/findObjectIndexByKey';
import KeyValueItem from '../../KeyValueItem';
import Modal from '../../Modal';
import FieldTextArea from '../../ManageContent/FieldTextarea';

const ManageCompetencyGroup = () => {
  const dispatch = useDispatch();
  // redux
  const currentModal = useSelector((state) => state.currentModal);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // misc
  const modalTitle = pathOr('Competency Group', ['data', 'modalTitle'], currentModal);
  const competencyGroup = pathOr({}, ['data', 'competencyGroup'], currentModal);
  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);
  const competencyGroups = pathOr([], ['competencyGroups'], apprenticeship);
  // local state
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(competencyGroup?.description || '');

  if (!currentModal?.visible) return null;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const dataToSave = {
      competencyGroups: competencyGroups.slice(0)
    };

    const childDataToSave = {
      id: competencyGroup?.id || uuid(),
      ...competencyGroup,
      description
    };

    if (competencyGroup?.id) {
      // EDIT
      const cgI = findObjectIndexByKey(competencyGroups, 'id', competencyGroup?.id);
      dataToSave.competencyGroups[cgI] = childDataToSave;
    } else {
      // CREATE
      dataToSave.competencyGroups.push(childDataToSave);
    }

    updateApprenticeship(dataToSave, apprenticeshipId).then(() => {
      dispatch(getApprenticeship(apprenticeship?.id));
      handleClose();
    }).catch((error) => {
      setLoading(false);
      console.error(error);
    });
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
      theme="dark"
      full
    >
      <div className="card">
        <div className="card-header bg-dark d-flex align-items-center justify-content-between">
          <h1 className="h5 m-0 text-white">
            {modalTitle}
          </h1>
          <button
            title="Close"
            aria-label="Close"
            type="button"
            className="close text-white"
            onClick={handleClose}
          >
            <FaTimes />
          </button>
        </div>

        <form
          onSubmit={onSubmit}
        >
          <div className="card-body">
            <div className='mb-4'>
              <div className='font-weight-bold mb-1'>
                Description:
              </div>
              <FieldTextArea
                value={description}
                maxCharCount={400}
                autoFocus
                onChange={(newBody) => {
                  setDescription(newBody);
                }}
              />
            </div>
          </div>
          <div className="card-footer d-flex align-items-center justify-content-between">
            <button
              className="btn btn-primary btm-sm"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            {competencyGroup?.id && (
              <small>
                <KeyValueItem
                  title='ID'
                  value={competencyGroup?.id}
                />
              </small>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ManageCompetencyGroup;
