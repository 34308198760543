import React from 'react';

const PaginationFirebase = ({
  items,
  pagination, // { next, prev }
  page,
  limit,
  total,
  onClickNext,
  onClickPrev
}) => {
  if (!items || (items && !items.length) || !pagination) {
    return null;
  }

  const numPages = (total >= items.length) ? Math.ceil(total / limit) : 1;

  if (numPages === 1) {
    return null;
  }

  const nextDisabled = !pagination?.next || (page === pagination?.pages);
  const prevDisabled = !pagination?.prev || (page === 1);

  return (
    <div
      style={{
        position: 'sticky',
        bottom: 0,
        background: 'white'
      }}
      className="d-flex justify-content-center py-3 border-top"
    >
      <nav>
        <ul className="pagination m-0">

          <li className={`page-item ${prevDisabled ? 'disabled' : ''}`}>
            <button
              className="page-link"
              tabIndex="-1"
              aria-disabled="true"
              onClick={onClickPrev}
              disabled={prevDisabled}
              type="button"
            >
              Previous
            </button>
          </li>

          <li className={`page-item ${nextDisabled ? 'disabled' : ''}`}>
            <button
              className="page-link"
              tabIndex="-1"
              aria-disabled="true"
              onClick={onClickNext}
              disabled={nextDisabled}
              type="button"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationFirebase;
