import { pathOr } from 'ramda';
import { client, flattenItem, flattenItems } from '../services/contentful';
import serializeQuiz from '../serializers/quiz';
import {
  SET_CURRENT_QUIZ,
  RESET_CURRENT_QUIZ,
  SET_QUIZ_OUTCOMES,
  RESET_QUIZ_OUTCOMES,
  // Content Flow
  SET_CURRENT_CLASS_QUIZZES,
  SET_CURRENT_CLASS_QUIZ_OUTCOMES,
  SET_CURRENT_TOPIC_QUIZ_OUTCOMES,
  RESET_CURRENT_CLASS_QUIZZES,
  RESET_CURRENT_CLASS_QUIZ_OUTCOMES,
  RESET_CURRENT_TOPIC_QUIZ_OUTCOMES,
  SET_QUIZ_OUTCOME,
  RESET_QUIZ_OUTCOME,
  SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID,
  SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID
} from './types';
import { serializeQuizOutcome, serializeQuizOutcomes } from '../serializers/quizOutcomes';
import { fetchQuizOutcome } from '../services/courseDashboard/quizzes';
import { setCurrentModal } from './Modals';

// Content Flow

// TODO - implement similar function like "setCurrentAnswers"
// to create <Progress /> like Challenges
// setCurrentUserQuizOutcomes = () => {...}

export const manageQuizOutcomes = (quizId) => {
  return (dispatch) => {
    dispatch(setCurrentModal({
      key: 'manageQuizOutcomes',
      data: {
        quizId
      }
    }));
  };
};

export const setCurrentClassQuizzes = ({ classId, activeQuiz, limit = 200 }) => {
  return (dispatch) => {
    const config = {
      content_type: 'quiz',
      'fields.classId': classId,
      limit
    };

    if (activeQuiz) {
      config['fields.activeQuiz'] = true;
    }

    return client.getEntries(config).then(({ items }) => {
      const currentClassQuizzes = items.length ? flattenItems(items) : null;

      dispatch({
        type: SET_CURRENT_CLASS_QUIZZES,
        currentClassQuizzes
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const setCurrentClassQuizOutcomes = ({ classId, userId, limit = 200 }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'quizOutcome',
        'fields.classId': classId,
        limit
      };

      if (userId) {
        config['fields.userId'] = userId;
      }

      return client.getEntries(config).then(({ items }) => {
        const currentClassQuizOutcomes = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CURRENT_CLASS_QUIZ_OUTCOMES,
          currentClassQuizOutcomes
        });
        resolve(currentClassQuizOutcomes);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const setCurrentTopicQuizOutcomes = ({ topicId, userId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'quizOutcome',
        'fields.topicId': topicId
      };

      if (userId) {
        config['fields.userId'] = userId;
      }

      return client.getEntries(config).then(({ items }) => {
        const currentTopicQuizOutcomes = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_CURRENT_TOPIC_QUIZ_OUTCOMES,
          currentTopicQuizOutcomes
        });
        resolve(currentTopicQuizOutcomes);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

// end Content Flow

export const getQuiz = ({ quizId }) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      client.getEntry(quizId).then((response) => {
        const flattenResponse = response ? flattenItem(response) : null;
        const currentQuiz = flattenResponse ? serializeQuiz(flattenResponse) : null;
        const cdnFiles = pathOr(null, ['integration', 'cdnFiles'], flattenResponse);

        dispatch({
          type: SET_CURRENT_QUIZ,
          currentQuiz,
          cdnFiles
        });

        resolve(currentQuiz);
      });
    });
  };
};

export const getQuizOutcome = (quizOutcomeId) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      fetchQuizOutcome(quizOutcomeId).then((response) => {
        const quizOutcome = response ? serializeQuizOutcome(response) : null;

        dispatch({
          type: SET_QUIZ_OUTCOME,
          quizOutcome
        });

        resolve(quizOutcome);
      });
    });
  };
};

export const setQuizOutcomesActiveOrgId = (activeOrgId, activeLocationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID,
      activeOrgId,
      activeLocationId
    });
  };
};

export const setQuizOutcomesActiveLocationId = (activeLocationId) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID,
      activeLocationId
    });
  };
};

export const getQuizOutcomes = ({
  orgId,
  locationId,
  quizId,
  userId,
  classId,
  limit = 200,
  skip = 0
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'quizOutcome',
        limit,
        skip
      };

      if (quizId) {
        config['fields.quizId'] = quizId;
      }

      if (userId) {
        config['fields.userId'] = userId;
      }

      if (classId) {
        config['fields.classId'] = classId;
      }

      if (orgId) {
        config['fields.orgId'] = orgId;
      }

      if (locationId) {
        config['fields.locationId[eq]'] = locationId;
      }

      // TODO refactor to use "fetchQuizOutcomes"

      return client.getEntries(config).then(({
        items,
        limit: entriesLimit,
        skip: entriesSkip,
        total
      }) => {
        const quizOutcomes = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_QUIZ_OUTCOMES,
          quizOutcomes: quizOutcomes ? serializeQuizOutcomes(quizOutcomes) : quizOutcomes,
          pagination: {
            limit: entriesLimit,
            skip: entriesSkip,
            total
          }
        });
        resolve(quizOutcomes);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetCurrentClassQuizzes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZZES });
  };
};

export const resetCurrentClassQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS_QUIZ_OUTCOMES });
  };
};

export const resetQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_QUIZ_OUTCOMES });
  };
};

export const resetCurrentTopicQuizOutcomes = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_TOPIC_QUIZ_OUTCOMES });
  };
};

export const resetCurrentQuiz = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_QUIZ });
  };
};

export const resetQuizOutcome = () => {
  return (dispatch) => {
    dispatch({ type: RESET_QUIZ_OUTCOME });
  };
};
