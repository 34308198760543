import React from 'react';
import { toast } from 'react-toastify';
import * as aws from '../../../services/aws';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import { coerceToResourceName } from '../../../services/resources';

// REQUIRED props
// ***********************
// onFileUploadCallback @func
// orgId @str
// accept @str
// ************************

const FileUploader = ({
  id = '',
  name = '',
  style,
  awsPrefix = AWS_USER_PREFIX,
  multiple = false,
  hideToastOptimize,
  orgId = '',
  accept = '',
  onChange,
  onFileUploadCallback
}) => {
  const defaultId = 'fileUploader';

  const handleOnChange = (files) => {
    if (onChange && multiple) {
      onChange(files);
    } else if (onChange && !multiple) {
      onChange(files[0]);
    }
  };

  const callback = (fileObj) => {
    if (!hideToastOptimize) {
      toast.info('Optimizing file...');
    }

    onFileUploadCallback(fileObj);
  };

  const onFileUploadHook = (e) => {
    const { files } = e.currentTarget;

    if (multiple) {
      handleOnChange(files);
    } else {
      const blob = files[0];

      toast.dismiss('invalidChar');

      if (blob.name.includes('+')) {
        toast.error('Invalid character "+" in filename.', {
          autoClose: false,
          toastId: 'invalidChar'
        });
        return;
      }

      handleOnChange(files);

      toast.info('Uploading file...');

      aws.uploadFile({
        prefix: `${awsPrefix}/${orgId}`,
        file: blob,
        orgId
      }).then((response) => {
        const fileObj = {
          url: response?.url,
          id: response?.id
        };

        if (blob.name) {
          fileObj.name = coerceToResourceName({
            str: blob.name,
            fixCamelCase: true,
            fixHyphens: true,
            fixUnderScores: true,
            fixPlus: true
          });
        }

        if (blob.type) fileObj.type = blob.type;
        if (blob.size) fileObj.size = blob.size;

        // Add file as embed syntax in editor
        callback(fileObj);
      });
    }
  };

  return (
    <input
      data-cy="uploadFiles-input"
      id={id || defaultId}
      name={name || id || defaultId}
      style={style}
      type="file"
      multiple={multiple}
      onChange={onFileUploadHook}
      accept={accept || 'application/pdf, application/vnd.ms-excel'}
    />
  );
};

export default FileUploader;
