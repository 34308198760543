import React, { useState } from 'react';
import Select from 'react-select';
// import './style.css';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];

const SelectMenu = ({
  name,
  options,
  onChange,
  defaultValue,
  isMulti = false,
  isClearable = false,
  className = '',
  placeholder = '',
  isDisabled = false
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleFieldChange = (data) => {
    setSelectedOption(data);

    // When isClearable is true, and a user clicks the "X"
    // to clear the field, the data is null
    if (!data || data === null) {
      onChange({ [name]: data });
      return;
    }

    if (isMulti) {
      const result = data ? data.map((d) => d.value) : null;

      onChange({ [name]: result });
      return;
    }

    onChange({ [name]: data.value });
  };

  return (
    <Select
      className={className}
      value={selectedOption}
      styles={{ menu: (base) => ({ ...base, zIndex: 9999 }) }}
      onChange={handleFieldChange}
      options={options}
      isMulti={isMulti}
      isClearable={isClearable}
      placeholder={placeholder}
      isDisabled={isDisabled}
    />
  );
};

export default SelectMenu;
