import React from 'react';
import './style.css';

const Alert = ({
  type,
  code,
  children,
  icon,
  iconComponent,
  className = ''
}) => {
  return (
    <div
      className={`alert alert-${type} ${icon ? 'alertWithIcon' : ''} ${className}`}
      role="alert"
      code={code || ''}
    >
      {iconComponent && iconComponent()}
      {icon ? (
        <>
          <div className="alertWithIcon-icon">
            <i className={icon} />
          </div>
          <div className="alertWithIcon-content">
            {children}
          </div>
        </>
      ) : children}
    </div>
  );
};

export default Alert;
