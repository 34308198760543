import React, { useState } from 'react';
import ImageUploader from '../ImageUploader';

const ImageUploadAndPreview = ({
  stateKey,
  defaultValue = '',
  contentId,
  contentType,
  removeImageText = 'Remove image',
  imageAltText = stateKey,
  onImageRemove,
  onImageUpload,
  onImagePreview,
  allowRemoveImage = true
}) => {
  const [entityImage, setEntityImage] = useState(defaultValue);

  const onImageUploadCallback = ({ url }) => {
    setEntityImage(url);

    if (onImageUpload && typeof onImageUpload === 'function') {
      console.log('onImageUpload', {
        [stateKey]: url
      });
      onImageUpload({
        [stateKey]: url
      });
    }
  };

  const onImageRemoveCallback = () => {
    setEntityImage('');

    if (onImageRemove && typeof onImageRemove === 'function') {
      onImageRemove({
        [stateKey]: ''
      });
    }
  };

  const previewFile = (file) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // convert image file to base64 string
      setEntityImage(reader.result);

      if (onImagePreview && typeof onImagePreview === 'function') {
        onImagePreview(reader.result);
      }
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="ImageUploadAndPreview">
      {stateKey && entityImage && (
        <div className="border shadow-sm p-2" style={{ width: '150px' }}>
          <img src={entityImage} alt={imageAltText} className="w-100" />
        </div>
      )}

      <div className="d-flex align-items-start flex-column">
        <ImageUploader
          inputId={stateKey}
          name={stateKey}
          saveToCdnFiles
          contentId={contentId}
          contentType={contentType}
          style={{ margin: '.8rem 0' }}
          onImageUploadCallback={onImageUploadCallback}
          onImagePreviewCallback={previewFile}
        />

        {entityImage && allowRemoveImage && (
          <button
            className="btn-link px-0"
            onClick={onImageRemoveCallback}
            type="button"
          >
            {removeImageText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageUploadAndPreview;
