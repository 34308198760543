import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaArrowsAlt } from 'react-icons/fa';
import { getChallenges } from '../../../actions/Challenges';
import { canManageWidgets } from '../../../services/currentUser';
import { ORG_CONSOLE_BASE } from '../../../constants/routes';
import List from './List';
import './style.css';

const LinksList = ({
  data = null,
  className = '',
  classId,
  userNamePlainText = false,
  showReorderButton = false,
  hideCourse = false,
  hideTopic = false,
  hideChallenge = false,
  reviewActive = false,
  orgId
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Embeds
  const hasData = (Array.isArray(data) && data.length > 0);
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!canManageWidgets(role)) {
      cols.push('menuCol');
    }

    return cols;
  }, [role]);

  useEffect(() => {
    const config = {};

    if (orgId) config.orgId = orgId;
    if (classId) config.classId = classId;

    dispatch(getChallenges(config));
  }, [classId, dispatch, orgId]);

  return (
    <div
      className={`challenge-answers-list overflow-x-hidden ${className}`}
    >
      <List
        data={data || []}
        hiddenColumns={hiddenColumns}
        userNamePlainText={userNamePlainText}
        hideTopic={hideTopic}
        hideChallenge={hideChallenge}
        hideCourse={hideCourse}
        reviewActive={reviewActive}
      />

      {showReorderButton && hasData && (
        <div
          className='d-flex justify-content-center border-top pt-4 mt-4'

        >
          <Link
            className="btn btn-sm btn-outline-primary"
            to={`${ORG_CONSOLE_BASE}/widgets/link/reorder`}
          >
            <span className="d-flex align-items-center">
              <FaArrowsAlt size={15} />
              <span className="ml-2">Reorder</span>
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default LinksList;
