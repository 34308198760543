import React from 'react';
import ClassificationTags from '../../../OrgConsole/Logbook/ClassificationTags';

const DescriptionCell = ({
  value: description,
  row,
  showClassifications
}) => {
  return (
    <div>
      <div>
        {description}
      </div>

      {showClassifications && (
        <ClassificationTags
          data={row?.original?.classificationIds}
          showAutoGenTag
        />
      )}
    </div>
  );
};

export default DescriptionCell;
