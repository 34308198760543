import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { GiTeacher } from 'react-icons/gi';
import DotMenu from './DotMenu';
import { canManageProgramUsers } from '../../permissions/manage';

const UserInstructionProvider = ({ showIcon = false, className = '' }) => {
  // redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const currentUserRole = pathOr([], ['role'], currentUser);
  // Training Program
  const apprenticeshipUserInstructionProvider = pathOr(null, ['userInstructionProvider'], apprenticeship);

  return (
    <div className={className}>
      <div className='font-weight-bold'>
        Instruction Provider:
      </div>
      {apprenticeshipUserInstructionProvider?.id && (
        <div className='mt-2 d-flex align-items-center justify-content-between border p-2'>
          <div className='d-flex align-items-center'>
            {showIcon && (
              <GiTeacher
                size={25}
                className='mr-2'
                color="#8e44ad"
              />
            )}
            <span>
              {apprenticeshipUserInstructionProvider?.name || 'Community College of Allegheny County'}
            </span>
          </div>
          {canManageProgramUsers(currentUserRole) && (
            <DotMenu />
          )}
        </div>
      )}

    </div>
  );
};

export default UserInstructionProvider;
