import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import pluralize from 'pluralize';
import { ICON_AWARD, ICON_COURSE } from '@apprentage/constants';
import { getPathways } from '../../../actions/Pathways';
import { getJobProfiles } from '../../../actions/JobProfiles';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_PATHWAYS } from '../../../constants/routes';
import isPublished from '../../../utils/isPublished';
import KeyValueItem from '../../KeyValueItem';
import Loading from '../../Loading';

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
  background-color: white;

  &:hover {
    border: #0984e3 solid 1px;
    background-color: #e2f8ff;
  }
`;

const ItemContentContainer = styled.div`
  cursor: pointer;
`;

const PathwaysList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const pathways = useSelector((state) => state.pathways);
  const courses = useSelector((state) => state.courses);
  const jobProfiles = useSelector((state) => state.jobProfiles);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  let whichCourses = null;

  useEffect(() => {
    dispatch(getPathways({ orgId }));
    dispatch(getJobProfiles({ orgId }));
  }, []);

  if (courses.list) {
    whichCourses = courses.list.filter((c) => isPublished(c));
    // Exclude test courses if they exist and the user is not a test user
    whichCourses = whichCourses.filter((c) => {
      if (c.testClass && !currentUser.testUser) return c;

      return c;
    });
  }

  if (!pathways.fetched && !pathways.list) {
    return <Loading text="Loading Pathways..." />;
  }

  if ((pathways.fetched && !pathways.list) || !whichCourses) {
    return <div className="p-5">No Pathways.</div>;
  }

  const onClickPathway = ({ pathwayId }) => {
    const route = routeWithOrgId({
      route: `${ORG_CONSOLE_PATHWAYS}/${pathwayId}`,
      orgId
    });

    history.push(route);
  };

  return (
    <div
      data-cy="PathwaysList"
      className="references-container"
    >
      {pathways.list.map((pathway) => {
        const pathwayCourses = pathway.courseIds
          ? pathway.courseIds.map((courseId) =>
            whichCourses.find((course) => course.id === courseId))
          : null;
        const pathwayId = pathOr(null, ['id'], pathway);
        const pathwayTitle = pathOr(null, ['title'], pathway);
        const isPublic = pathOr(null, ['isPublic'], pathway);
        const jobProfile = jobProfiles.list
          && jobProfiles.list.find((jp) => jp.id === pathway.jobProfileId);

        return (
          <Container
            key={pathwayId}
            className="reference d-flex justify-content-between align-items-center rounded mb-3"
          >
            <ItemContentContainer
              className="w-100 h-100 py-3 px-3"
              role="button"
              title={pathwayTitle}
              onClick={() => {
                onClickPathway({
                  pathwayId,
                  pathwayCourses
                });
              }}
            >
              <h5
                className="font-weight-bold mb-3"
                data-cy="PathwaysList-title"
              >
                <span>{pathwayTitle}</span>
                <span className="ml-1 h6 m-0">
                  <span
                    className={`ml-1 badge badge-${
                      isPublic ? 'success' : 'warning'
                    }`}
                  >
                    {isPublic ? 'Public' : 'Private'}
                  </span>
                </span>
              </h5>

              {pathwayCourses && (
                <div>
                  <div className="font-weight-bold h6 m-0">
                    <i className={`${ICON_COURSE} mr-1`} />
                    {pluralize('Course', pathwayCourses.length, true)}
                  </div>
                  <ul className="pl-4 mt-1">
                    {pathwayCourses.map((pathwayCourse) => {
                      if (!pathwayCourse) {
                        return null;
                      }

                      return (
                        <li key={pathwayCourse.id}>{pathwayCourse.title}</li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {jobProfile && jobProfile.selectedCerts && (
                <div>
                  <div className="font-weight-bold h6 m-0">
                    <i className={`${ICON_AWARD} mr-1`} />
                    {pluralize(
                      'Certificate',
                      jobProfile.selectedCerts.length,
                      true
                    )}
                  </div>
                  <ul className="pl-4 mt-1">
                    {jobProfile.selectedCerts.map((selectedCert) => (
                      <li key={selectedCert.Id}>{selectedCert.Name}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="d-flex justify-content-end">
                <KeyValueItem
                  title="ID"
                  value={pathwayId}
                />
              </div>
            </ItemContentContainer>
          </Container>
        );
      })}
    </div>
  );
};

export default PathwaysList;
