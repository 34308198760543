/**
 * Note : When Creating new modal key please prefix it with "MODAL_KEY"
 */
export const MODAL_KEY_RELOAD = 'reload';
export const MODAL_KEY_CONFIRM_ACTION = 'confirmAction';

export const MODAL_KEY_UPLOAD_FILES = 'uploadFiles';
export const MODAL_KEY_MANAGE_CDN_FILE = 'manageCdnFile';

export const MODAL_KEY_USER_SIGNATURE = 'userSignature';
export const MODAL_KEY_SEARCH_SELECT_USERS = 'searchSelectUsers';

export const MODAL_KEY_CREATE_TRAINING_PROGRAM = 'createTrainingProgram';
export const MODAL_KEY_ONBOARD_WELCOME = 'onBoardWelcome';
export const MODAL_KEY_CLONE_SUCCESSFUL_TRAININGS = 'cloneSuccessfulTrainings';
export const MODAL_KEY_BUILD_YOUR_OWN_TRAINING = 'buildYourOwnTraining';

export const MODAL_KEY_MANAGE_COMPETENCY = 'manageCompetency';
export const MODAL_KEY_COMPETENCY_INITIAL = 'competencyInitial';

export const MODAL_KEY_MANAGE_RECORD = 'manageRecord';

export const MODAL_KEY_CHANGE_OCCUPATION = 'changeOccupation';
export const MODAL_KEY_MANAGE_NOTIFICATION = 'manageNotification';
export const MODAL_KEY_NOTIFICATIONS_MENU = 'notificationsMenu';

export const MODAL_KEY_MANAGE_WIDGET = 'manageWidget';
export const MODAL_KEY_WIDGET_TYPE = 'widgetType';

export const MODAL_KEY_COURSE_CREATE_FROM_DOC = 'courseCreateFromDoc';

export const MODAL_KEY_MANAGE_SUBMISSIONS = 'manageSubmissions';

export const MODAL_KEY_USER_INVITATION = 'userInvitation';
export const MODAL_KEY_USER_INVITATION_CONFIRM = 'userInvitationConfirm';

export const MODAL_KEY_SEARCH_CONTENT = 'searchContent';

export const MODAL_KEY_MANAGE_PROMPT = 'addEditPrompt';

/** Dashboard */
export const MODAL_KEY_DASHBOARD_NEW_MODULE = 'createNewDashboardModule';
export const MODAL_KEY_DASHBOARD_CHANGE_LAYOUT = 'changeDashboardLayout';
export const MODAL_KEY_DASHBOARD_MODULE_CONFIG = 'configModule';
export const MODAL_KEY_DASHBOARD_REORDER_LINK_WIDGETS = 'dashboardReorderLinkWidgets';

export const MODAL_KEY_MANAGE_GROUP = 'manageGroup';
export const MODAL_KEY_RESOURCE_PREVIEW = 'resourcePreview';
export const MODAL_KEY_VELA_HANDOFF = 'velaHandoff';
