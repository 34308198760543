import { RESET_CURRENT_TOPIC_ANSWERS, SET_CURRENT_TOPIC_ANSWERS } from '../../actions/types';

const initialState = {
  fetched: false,
  list: null
};

export default function currentTopicAnswers(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_TOPIC_ANSWERS:
      return {
        ...state,
        list: action.currentTopicAnswers,
        fetched: true
      };
    case RESET_CURRENT_TOPIC_ANSWERS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
