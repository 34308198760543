import React from 'react';
import { useSelector } from 'react-redux';
import { FaFileMedical } from 'react-icons/fa6';
import { MdDriveFileMove } from 'react-icons/md';
import DotMenu from './DotMenu';
import ChooseResourceFolder from '../../OrgConsole/Resources/ChooseResourceFolder';

const ChooseResourceFolderSideSheet = ({ className = '' }) => {
  // Redux
  const sideSheet = useSelector((state) => state.sideSheet);
  // Side Sheet
  const moveToResources = sideSheet?.data?.moveToResources || false;
  const callbackPrimaryAction = sideSheet?.callbackPrimaryAction || null;
  const sideSheetTitle = sideSheet?.data?.title || 'Choose Resource Folder';

  return (
    <div className={`card ${className}`}>
      <CardHeader
        title={sideSheetTitle}
        moveToResources={moveToResources}
      />

      <div className='card-body overflow-y-scroll'>
        <ChooseResourceFolder
          onSelect={(data) => {
            // data = { resourceGroup, resourceGroupIds, resourceGroupsList }
            if (typeof callbackPrimaryAction === 'function') {
              callbackPrimaryAction(data?.resourceGroup?.id);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ChooseResourceFolderSideSheet;

const CardHeader = ({ title, moveToResources }) => {
  return (
    <div className='card-header d-flex align-items-center justify-content-between'>
      <div
        className="h6 m-0 font-weight-bold"
        style={{
          width: '72%'
        }}
      >
        <span
          className="d-flex align-items-center"
        >
          {moveToResources ? (
            <MdDriveFileMove size={20} className='d-none d-sm-block mr-2' />
          ) : (
            <FaFileMedical size={20} className='d-none d-sm-block mr-2' />
          )}
          <span
            className="text-nowrap"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {title}
          </span>
        </span>
      </div>
      <div className='d-flex align-items-center'>
        <DotMenu />
      </div>
    </div>
  );
};
