import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import { queryEngine } from '../../../services/openAi';
import Modal from '../../Modal';
import Overview from './Overview';
import ProcessDoc from './ProcessDoc';
import VelaOverview from './VelaOverview';
import KnowledgeCapture from './KnowledgeCapture';
import CourseSummary from './CourseSummary';

const CreateCourseFromDocument = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  // Organization
  const orgId = organization?.id || null;
  // local State
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('overview');
  const [refId, setRefId] = useState('overview');
  const [trainingCourse, setTrainingCourse] = useState(null);

  const overviewClickHandler = (docUuid) => {
    setLoading(true);
    setRefId(docUuid);
    setCurrentScreen('knowledgeCapture');
    setLoading(false);
  };

  const captureKnowledgeClickHandler = (docUuid) => {
    setLoading(true);
    setRefId(docUuid);

    setCurrentScreen('processDoc');

    queryEngine({
      promptType: 'courseCreate',
      orgId,
      refId: docUuid
    }).then((response) => {
      console.log('response', response);

      const answer = response?.data?.answer;
      let cleanAnswer = answer.replace('```json', '');
      cleanAnswer = cleanAnswer.replace('```', '');
      const answerAsJson = JSON.parse(cleanAnswer);
      console.log(answerAsJson);

      setTrainingCourse(answerAsJson?.trainingCourse || null);
      setLoading(false);
      setCurrentScreen('courseSummary');
    });
  };

  const processDocClickHandler = () => {
    setCurrentScreen('courseSummary');
  };

  const courseSummaryClickHandler = () => {
    setCurrentScreen('velaOverview');
  };

  const velaOverviewClickHandler = () => {
    setCurrentScreen('');
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  console.log('refId', refId);

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
    >
      <div className="p-3 p-md-5">
        {currentScreen === 'overview' && (
          <Overview
            onClick={overviewClickHandler}
            loading={loading}
          />
        )}
        {currentScreen === 'knowledgeCapture' && (
          <KnowledgeCapture
            onClick={captureKnowledgeClickHandler}
            loading={loading}
          />
        )}
        {currentScreen === 'processDoc' && (
          <ProcessDoc
            onClick={processDocClickHandler}
            loading={loading}
          />
        )}
        {currentScreen === 'courseSummary' && (
          <CourseSummary
            onClick={courseSummaryClickHandler}
            trainingCourse={trainingCourse}
            loading={loading}
          />
        )}
        {currentScreen === 'velaOverview' && (
          <VelaOverview
            onClick={velaOverviewClickHandler}
            loading={loading}
          />
        )}
      </div>
    </Modal>
  );
};

export default CreateCourseFromDocument;
