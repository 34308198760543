import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../actions/Modals';
import { canManageCompetency } from '../../../permissions/manage';
import List from './List';
import { MODAL_KEY_MANAGE_COMPETENCY } from '../../../constants/modals';

const CompetenciesList = ({
  list = [],
  competencyGroupId,
  showClassifications
}) => {
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];

  const handleClickRow = (competency) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_COMPETENCY,
        data: {
          competency,
          competencyGroupId
        }
      })
    );
  };

  if (!list.length) {
    return null;
  }

  return (
    <div className="apprenticeship-competencies-list">
      <List
        data={list}
        handleClickRow={canManageCompetency(role) ? handleClickRow : undefined}
        showClassifications={showClassifications}
      />
    </div>
  );
};

export default CompetenciesList;
