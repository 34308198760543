import {
  SET_APPRENTICESHIPS, RESET_APPRENTICESHIPS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  pagination: null
};

export default function apprenticeships(state = initialState, action) {
  switch (action.type) {
    case SET_APPRENTICESHIPS:
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    case RESET_APPRENTICESHIPS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
