import {
  RESET_QUIZ_OUTCOME, SET_QUIZ_OUTCOME
} from '../../actions/types';
import quizOutcomeState from '../initialState/quizOutcomeState';

const initialState = {
  ...quizOutcomeState,
  fetched: false
};

export default function quizOutcome(state = initialState, action) {
  switch (action.type) {
    case SET_QUIZ_OUTCOME:
      return {
        ...state,
        ...action.quizOutcome,
        fetched: true
      };
    case RESET_QUIZ_OUTCOME:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
