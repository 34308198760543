import sysState from './sysState';

const groupState = {
  type: '',
  title: '',
  itemIds: null, // string[]
  orgId: '',
  authorUserId: '',
  authorUserName: '',
  groupTags: null,
  ...sysState
};

export default groupState;
