import React from 'react';
import { FaChevronRight, FaMagic } from 'react-icons/fa';

const Prompt = ({
  className = 'defaultPromptOption p-3 rounded text-white mb-3',
  promptText,
  promptType,
  promptBadge,
  showButton = false,
  showIcon = false,
  onClick,
  disabled = false
}) => {
  return (
    <div
      className={`${className} ${disabled && 'pointer-events-none'}`}
      aria-hidden
      onClick={() => {
        onClick(promptText, promptType);
      }}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          {showIcon && (
            <FaMagic className='opacity-50 d-none d-sm-block mr-2' />
          )}

          <span>
            {promptText}
          </span>
        </div>

        <div className='d-flex align-items-center'>
          {promptBadge && (
            <span
              className='badge badge-dark badge-pill text-white font-weight-normal p-2 ml-3'
            >
              {promptBadge}
            </span>
          )}

          {showButton && (
            <div
              className={`btn btn-primary btn-md d-none d-sm-block ${promptBadge ? 'ml-2' : ''}`}
            // onClick={() => {
            //   onClick(promptText, promptType);
            // }}
            >
              <FaChevronRight />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Prompt;
