import { fetchUser } from '../services/currentUser';
import { setCurrentModal } from './Modals';
import { getAgreement } from './Agreements';
import {
  SET_USERS,
  SET_CURRENT_USER,
  SET_USER,
  RESET_USERS,
  REMOVE_USER_FROM_LIST,
  SET_ENTRY_ORG_ID
} from './types';
import { fetchLocation } from '../services/locations';
import heapAnalytics from '../utils/heapAnalytics';
import { fetchUsers } from '../services/users';

export const upgradeMembershipModal = ({ content, purchaseCourse, price }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { membership } = currentUser;

    dispatch(
      setCurrentModal({
        key: 'upgradeMembership',
        data: {
          membership,
          content,
          purchaseCourse,
          price
        }
      })
    );
  };
};

export const setUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      user
    });
  };
};

export const resetUser = () => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      user: null
    });
    dispatch({
      type: SET_CURRENT_USER,
      currentUser: null
    });
  };
};

export const resetUsers = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USERS
    });
  };
};

export const removeUserFromList = ({ userId }) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER_FROM_LIST,
      userId
    });
  };
};

export const getUsers = ({
  searchValue = '',
  roles = ['student', 'teacher', 'admin', 'owner', 'programManager', 'programTrainer'],
  omitCourse = false, // TODO user roles (This allows us to pull all teachers in an org when in the class console)
  excludeTestUsers,
  excludeInactive,
  excludeRoles,
  hasCertificate = undefined,
  excludeAcceptedCertificates = undefined,
  order = 'name',
  limit = 200,
  page = 1,
  orgId,
  cohortId,
  locationId,
  apprenticeshipId,
  supervisorIds,
  skillIds,
  userIds
}) => {
  return (dispatch, getState) => {
    const { organization, course, currentCohort } = getState();

    return new Promise((resolve, reject) => {
      const config = {
        limit,
        page
      };

      // This setup allows for passing orgId:false to omit it
      // and pull all users from all orgs for migration purposes.
      // If no orgId passed, default to organization ID.
      if (orgId === undefined) config.orgId = organization.id;
      // If orgId passed, use it
      if (orgId) config.orgId = orgId;

      if (order) {
        config.order = order;
      }

      if (searchValue) {
        config.searchValue = searchValue;
      }

      if (roles) {
        config.roles = roles;
      }

      if (excludeRoles) {
        config.excludeRoles = excludeRoles;
        delete config.roles;
      }

      if (hasCertificate) {
        config.hasCertificate = hasCertificate;
      }

      if (supervisorIds) {
        config.supervisorIds = supervisorIds;
      }

      if (skillIds) {
        config.skillIds = skillIds;
      }

      if (userIds) {
        config.userIds = userIds;
      }

      if (excludeTestUsers) {
        config.excludeTestUsers = excludeTestUsers;
      }

      if (excludeInactive) {
        config.excludeInactive = excludeInactive;
      }

      if (excludeAcceptedCertificates) { // TODO this can be removed
        config.excludeAcceptedCertificates = excludeAcceptedCertificates;
      }

      if (omitCourse) { // TODO this can be removed
        config.omitCourse = omitCourse;
      }

      if (cohortId || currentCohort?.id) {
        config.cohortId = cohortId || currentCohort?.id;
      }

      if (course?.id && !omitCourse) {
        config.courseId = course?.id;

        if (excludeAcceptedCertificates !== undefined) {
          config.acceptedCertificateCourseId = course?.id;
        }
      }

      if (apprenticeshipId) {
        config.apprenticeshipId = apprenticeshipId;
      }

      if (locationId) {
        config.locationId = locationId;
      }

      fetchUsers(config)
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_USERS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const setCurrentUser = (currentUser) => {
  const { locationId, agreementId } = currentUser;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLocation({ locationId })
        .then((userLocation) => {
          if (agreementId) {
            dispatch(getAgreement({ agreementId }));
          }

          const dataToSave = {
            userLocation,
            currentUser
          };

          heapAnalytics.addUserProperties({
            locationId: userLocation?.id,
            locationName: userLocation?.name
          });

          dispatch({
            type: SET_CURRENT_USER,
            ...dataToSave
          });

          resolve(dataToSave);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getCurrentUser = ({ userId, select }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUser({ userId, select }).then((response) => {
        if (response?.id) {
          dispatch(setCurrentUser(response)).then(() => {
            resolve(response);
          });
        } else {
          console.error('Current user missing.');
          reject(new Error('Current user missing.'));
        }
      });
    });
  };
};

export const updateUserAttendanceAndUsers = ({ userId, attendance }) => {
  if (!userId || !attendance) return null;

  return (dispatch, getState) => {
    const { users } = getState();

    if (!users.list) return null;

    return new Promise((resolve) => {
      const updatedUsers = users.list.slice(0);
      const userIndex = updatedUsers.findIndex((u) => u.id === userId);

      if (userIndex === -1) return null;

      updatedUsers[userIndex] = {
        ...updatedUsers[userIndex],
        attendance
      };

      dispatch({
        type: SET_USERS,
        list: updatedUsers
      });

      resolve(updatedUsers);
    });
  };
};
