import React from 'react';
import Transcript from '../../../OrgConsole/LogbookRecord/Transcript';

const TranscriptCell = ({ value }) => {
  return (
    <Transcript text={value} />
  );
};

export default TranscriptCell;
