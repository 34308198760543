function formatEUnitTracking({
  enableNewEditor,
  title,
  code,
  semesterCode,
  trackingInterval,
  sessions,
  currentSession,
  attendancePerSession,
  sessionCredits,
  sessionsTopicSync,
  info
}) {
  const data = {};

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (code !== undefined) {
    data.code = {
      'en-US': code
    };
  }

  if (semesterCode !== undefined) {
    data.semesterCode = {
      'en-US': semesterCode
    };
  }

  if (enableNewEditor !== undefined) {
    data.enableNewEditor = {
      'en-US': enableNewEditor // bool
    };
  }

  if (sessionsTopicSync !== undefined) {
    data.sessionsTopicSync = {
      'en-US': sessionsTopicSync // bool
    };
  }

  if (trackingInterval !== undefined) {
    data.trackingInterval = {
      'en-US': parseInt(trackingInterval, 10) // int
    };
  }

  if (currentSession !== undefined) {
    data.currentSession = {
      'en-US': parseInt(currentSession, 10) // int
    };
  }

  if (attendancePerSession !== undefined) {
    data.attendancePerSession = {
      'en-US': parseInt(attendancePerSession, 10) // int
    };
  }

  if (sessionCredits !== undefined) {
    if (sessionCredits === null) {
      data.integration = undefined;
    } else {
      data.integration = {
        'en-US': {
          sessionCredits
        }
      };
    }
  }

  // OPTIONAL
  if (sessions !== undefined) {
    data.sessions = {
      'en-US': parseInt(sessions, 10) // int
    };
  }

  if (info !== undefined) {
    data.info = {
      'en-US': info
    };
  }

  return { fields: { ...data } };
}

export default formatEUnitTracking;
