import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCurrentModal } from '../../../actions/Modals';
import { ORG_CONSOLE_BASE } from '../../../constants/routes';
import { canManageWidgets } from '../../../services/currentUser';
import Modal from '../../Modal';
import WidgetsList from '../../lists/WidgetsList';
import { fetchWidgets } from '../../../services/widgets';

const AppsMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || null;
  const dashboardWidgetIds = useMemo(() => {
    return organization?.dashboardWidgetIds || [];
  }, [organization?.dashboardWidgetIds]);

  // Local State
  const [widgetLinksList, setWidgetLinksList] = useState([]);
  const [widgetLinksListFetched, setWidgetLinksListFetched] = useState(false);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const goToWidgets = () => {
    dispatch(resetCurrentModal());
    history.push(`${ORG_CONSOLE_BASE}/widgets`);
  };

  const retrieveWidgetLinks = useCallback(() => {
    if (orgId) {
      fetchWidgets({
        orgId,
        type: 'link',
        isFeatured: true,
        sortByIds: dashboardWidgetIds
      }).then((response) => {
        setWidgetLinksListFetched(true);
        setWidgetLinksList(response?.list);
      });
    }
  }, [dashboardWidgetIds, orgId]);

  useEffect(() => {
    retrieveWidgetLinks();
  }, [retrieveWidgetLinks]);

  return (
    <>
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal.visible}
        close={handleClose}
        theme="dark"
      >
        <div className="card">
          <div className="card-header p-3 bg-dark text-white">
            <h6 className="m-0 font-weight-bold ml-2">Toolbox</h6>
          </div>
          <div
            className="py-3 d-flex justify-content-center"
            style={{
              minHeight: '400px'
            }}
          >
            <WidgetsList
              className="container"
              isFeatured
              manualFetchData
              data={widgetLinksList}
              dataFetched={widgetLinksListFetched}
            />
          </div>

          {canManageWidgets(role) && (
            <div className="card-footer d-flex justify-content-center">
              <button
                className="btn btn-link p-0"
                type="button"
                onClick={goToWidgets}
              >
                Manage Tools
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AppsMenu;
