import React from 'react';

const Tabs = ({
  className = '',
  activeTab,
  setActiveTab,
  cardStyle = false
}) => {
  return (
    <div
      className={`d-flex align-items-end border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${activeTab === 'materials' && 'active'}`}
            type='button'
            disabled={activeTab === 'materials'}
            onClick={() => {
              setActiveTab('materials');
            }}
          >
            Materials
          </button>
        </li>

        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'myMaterials' && 'active'}`}
            type='button'
            disabled={activeTab === 'myMaterials'}
            onClick={() => {
              setActiveTab('myMaterials');
            }}
          >

            <span className="d-flex align-items-center">
              <span>
                My Materials
              </span>
              <span className="mb-0 mx-2">
                <span
                  className="badge alert-warning alert px-2 m-0 small"
                  style={{
                    paddingTop: '2.5px',
                    paddingBottom: '2.5px'
                  }}
                >
                  drafts
                </span>
              </span>
            </span>
          </button>
        </li>

      </ul>
    </div>
  );
};

export default Tabs;
