import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentModal } from '../../../../../actions/Modals';
import { setSideSheet } from '../../../../../actions/SideSheets';
import { MODAL_KEY_RESOURCE_PREVIEW } from '../../../../../constants/modals';
import { SHEET_KEY_MATERIAL } from '../../../../../constants/sideSheets';
import { getResourceMaterial } from '../../../../../actions/Materials';
import ResourceIcon from './ResourceIcon';

const NameCell = ({ value: name, row }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Resource
  const id = row?.original?.id || '';
  const type = row?.original?.type || '';
  const url = row?.original?.url || '';
  const isEmbedded = row?.original?.isEmbedded || null;

  const onClickName = () => {
    if (type === 'material') {
      dispatch(getResourceMaterial({ resourceId: id, orgId })).then(() => {
        dispatch(setSideSheet({
          key: SHEET_KEY_MATERIAL,
          className: 'MaterialSideSheet'
        }));
      });
    } else {
      dispatch(setCurrentModal({
        key: MODAL_KEY_RESOURCE_PREVIEW,
        data: {
          resource: row?.original,
          modalTitle: name,
          iframeSrc: url
        }
      }));
    }
  };

  if (type === 'link') {
    return (
      <div className='d-flex align-items-center'>
        <ResourceIcon
          type={type}
          url={url}
          name={name}
          isEmbedded={isEmbedded}
        />
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="btn-link ml-2 text-left"
          title="View in new tab"
          style={{
            minWidth: '230px'
          }}
          aria-label="View in new tab"
          data-cy="CdnFile-Name"
          data-filename={name}
          data-id={id}
        >
          {name}
        </a>
      </div>
    );
  }

  return (
    <div className='d-flex align-items-center'>
      <ResourceIcon
        type={type}
        url={url}
        name={name}
        isEmbedded={isEmbedded}
      />

      <button
        type="button"
        onClick={onClickName}
        className="btn-link ml-2 text-left"
        style={{
          minWidth: '230px'
        }}
        title="Preview"
        aria-label="Preview"
        data-cy="CdnFile-Name"
        data-filename={name}
        data-id={id}
      >
        {name}
      </button>

    </div>
  );
};

export default NameCell;
