import { isAdminOwnerSuperAdmin } from '@apprentage/utils';

export default function canDeleteMaterial(
  role = [],
  contentType = null,
  userId = null,
  materialUserId = null
) {
  if (!role || (Array.isArray(role) && role.length === 0)) {
    return false;
  }

  if (!contentType || !userId) {
    return false;
  }

  // Contentful
  if (isAdminOwnerSuperAdmin(role)) {
    return true;
  }

  // Supabase
  if (contentType === 'materials') {
    return userId === materialUserId;
  }

  return false;
}
