const projectState = {
  id: null,
  orgId: null,
  userId: null,
  type: null,
  agentImage: null,
  embedUrls: null,
  resourceGroupIds: null,
  orgNickname: null,
  name: null,
  defaultPrompts: null,
  colorBtnBkg: null,
  welcomeMessage: null,
  archived: null,
  updatedAt: null,
  createdAt: null,
  version: null
};

export default projectState;
