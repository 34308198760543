import { PROJECTS_PAGINATION_LIMIT } from '../constants/api';
import { fetchSearches } from '../services/searches';
import {
  SET_ENTRY_ORG_ID,
  RESET_SEARCHES,
  SET_SEARCHES,
  SET_SEARCHES_USERS_HASH
} from './types';
import uniqueStringArray from '../utils/uniqueStringArray';
import { fetchUsers } from '../services/users';

export const getSearches = ({
  orgId,
  locationId,
  userId,
  archived = null,
  limit = PROJECTS_PAGINATION_LIMIT,
  page = 1,
  order = '-createdAt',
  title = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchSearches({
        archived,
        locationId,
        orgId,
        userId,
        page,
        limit,
        order,
        title,
        select: ['id, orgId, userId, query, createdAt', 'resourceIds', 'answer']
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          const userIds = list.map((item) => item.userId);

          if (Array.isArray(userIds) && userIds.length > 0) {
            fetchUsers({
              orgId,
              userIds: uniqueStringArray(userIds),
              select: [
                'id',
                'name',
                'title',
                'orgId',
                'role',
                'membership',
                'testUser',
                'profilePicture'
              ]
            }).then((usersResponse) => {
              let usersHash = {};

              if (Array.isArray(usersResponse.items) && usersResponse.items.length > 0) {
                usersHash = usersResponse.items.reduce((acc, curr) => {
                  const result = {
                    ...acc
                  };

                  result[curr.id] = curr;

                  return result;
                }, {});

                dispatch({
                  type: SET_SEARCHES_USERS_HASH,
                  usersHash
                });
              }
            });
          }

          dispatch({
            type: SET_SEARCHES,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const resetSearches = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SEARCHES });
  };
};
