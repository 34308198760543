import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  canAccessUserLogbookRecords,
  canHaveLogbook
} from '../../../../../../permissions/access';
import { fetchLogbookRecords } from '../../../../../../services/logbookRecords';

const RecordsTabs = ({
  active,
  className = '',
  onClick
}) => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = pathOr([], ['role'], currentUser);
  // Training Program
  const apprenticeshipUser = pathOr(null, ['user'], apprenticeship);
  const apprenticeshipUserRole = pathOr([], ['role'], apprenticeshipUser);

  // Local State
  const [unapprovedRecords, setUnapprovedRecords] = useState(0);

  useEffect(() => {
    if (logbook.fetched && logbook?.id) {
      fetchLogbookRecords({
        orgId,
        logbookId: logbook?.id,
        isApproved: null,
        count: true
      }).then((response) => {
        setUnapprovedRecords(response?.total);
      });
    }
  }, [logbook?.id, logbook.fetched]);

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs flex-nowrap nav-tabs-scrollable"
      >
        {canAccessUserLogbookRecords(role) && canHaveLogbook(apprenticeshipUserRole) && (
          <li className="nav-item ml-2">
            <button
              type="button"
              className={`nav-link d-flex align-items-center ${active === 'all' ? 'active' : ''}`}
              title="All"
              onClick={() => {
                onClick('all');
              }}
            >
              <span>All</span>
              <span className="ml-2 badge bg-white border">
                {logbookRecords?.pagination?.total || 0}
              </span>
            </button>
          </li>
        )}

        {canAccessUserLogbookRecords(role) && canHaveLogbook(apprenticeshipUserRole) && (
          <li
            className="nav-item ml-2"
            style={{ display: 'none' }}
          >
            <button
              type="button"
              className={`nav-link d-flex align-items-center ${active === 'review' ? 'active' : ''}`}
              title="Review"
              disabled={unapprovedRecords === 0}
              onClick={() => {
                onClick('review');
              }}
            >
              <span>Review Records</span>
              {unapprovedRecords > 0 && (
                <span className="ml-2 badge badge-warning">
                  {unapprovedRecords || 0}
                </span>
              )}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default RecordsTabs;
