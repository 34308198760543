import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import { withAuthorization } from '../../Session';
import { getLocation, resetLocation } from '../../../actions/Locations';
import withOrgConsole from '../../App/withOrgConsole';
import { ORG_CONSOLE_LOCATIONS, SLASH } from '../../../constants/routes';
import { canCreateLocation, canEditLocation } from '../../../services/currentUser';
import { locationTerminology } from '../../../services/locations';
import Loading from '../../Loading';
import Form from './Form';
import CdnFiles from '../../ManageContent/CdnFiles';
import OrgConsoleHeader from '../OrgConsoleHeader';
import Tabs from './Tabs';
import { entryOrgMatchesUserOrg } from '../../../services/organizations';

const Location = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const orgLocation = useSelector((state) => state.orgLocation);
  // Misc
  const queryParams = new URLSearchParams(window.location.search);
  const redirectRoute = queryParams.get('redirectRoute');
  const locationId = params?.locationId || '';
  const role = currentUser?.role || [];
  const orgType = organization?.type || '';
  const editLocation = canEditLocation(role);
  const createLocation = canCreateLocation(role);
  const locationTerm = locationTerminology({ orgType });

  useEffect(() => {
    if (!orgLocation.id && locationId) {
      dispatch(getLocation(locationId)).then((response) => {
        entryOrgMatchesUserOrg(
          response?.orgLocation?.orgId,
          currentUser?.orgId
        );
      });
    }

    // User doesn't have permissions to create a location
    // Send them to SlASH
    if (!locationId && !createLocation) {
      history.push(SLASH);
    }

    return function cleanup() {
      const { href } = window?.location;

      // Only reset location if not going to employerProfile
      if (!href.includes('locations/manage') && !href.includes('workforce')) {
        dispatch(resetLocation());
      }
    };
  }, [locationId]);

  if (locationId && !orgLocation.id) {
    return (
      <Loading text={`Loading ${locationTerm.text}...`} />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">

        <div className="mb-5">
          <OrgConsoleHeader
            className='mb-3'
            pageTitle={orgLocation.name || `Create ${locationTerm.text}`}
            icon={locationTerm.icon}
            locationImage={orgLocation.locationImage}
            route={redirectRoute ? decodeURIComponent(redirectRoute) : ORG_CONSOLE_LOCATIONS}
          />

          <Tabs active="general" />

          {(locationId && editLocation) || (!locationId && createLocation) ? (
            <>
              <Form
                data={orgLocation}
              />

              {locationId && (
                <div className="my-4">
                  <CdnFiles
                    id="orgLocation-integration-files"
                    files={orgLocation.cdnFiles}
                    contentId={locationId}
                    contentType="location"
                    editMenu
                  />
                </div>
              )}
            </>
          ) : (
            <div>You do not have permission to edit this location.</div>
          )}
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Location);
