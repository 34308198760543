import React from 'react';
import { useSelector } from 'react-redux';
import { isOwner } from '@apprentage/utils';
import Modal from '../../Modal';
import Owner from './Owner';
import NotOwner from './NotOwner';

const OnboardWelcome = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  // Current User
  const role = currentUser?.role || [];

  return (
    <Modal
      visible={currentModal.visible}
      pos="middle"
    >
      <div className="p-3 p-md-5">
        <div className="text-center">
          {currentUser.name && (
            <p className="font-weight-bold text-dark">Hi {currentUser.name},</p>
          )}

          {isOwner(role) && (
            <Owner />
          )}

          {!isOwner(role) && (
            <NotOwner />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OnboardWelcome;
