import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaArrowsAlt, FaEllipsisH } from 'react-icons/fa';
import { ORG_CONSOLE_USERS_GROUPS_REORDER } from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';
import { canManageUsers } from '../../../../services/currentUser';

const DotMenu = () => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Permissions
  const manageUsers = canManageUsers(role);

  if (!manageUsers) {
    return null;
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH size={15} />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          <Link
            className="dropdown-item py-2"
            to={routeWithOrgId({
              route: ORG_CONSOLE_USERS_GROUPS_REORDER,
              orgId
            })}
            title="Reorder"
          >
            <span className='d-flex align-items-center'>
              <FaArrowsAlt size={15} />
              <span className='ml-2'>
                Reorder
              </span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
