import {
  RESET_QUIZ_OUTCOMES,
  RESET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID,
  RESET_QUIZ_OUTCOMES_ACTIVE_ORG_ID,
  SET_QUIZ_OUTCOMES,
  SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID,
  SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID
} from '../../actions/types';

const initialState = {
  list: null,
  pagination: null,
  fetched: false,
  activeOrgId: undefined,
  activeLocationId: undefined
};

export default function quizOutcomes(state = initialState, action) {
  switch (action.type) {
    case SET_QUIZ_OUTCOMES:
      return {
        ...state,
        list: action.quizOutcomes,
        pagination: action.pagination || initialState.pagination,
        fetched: true
      };
    case SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID:
      return {
        ...state,
        activeOrgId: action.activeOrgId,
        activeLocationId: action.activeLocationId || initialState.activeLocationId
      };
    case SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID:
      return {
        ...state,
        activeLocationId: action.activeLocationId
      };
    case RESET_QUIZ_OUTCOMES_ACTIVE_ORG_ID:
      return {
        ...state,
        activeOrgId: initialState.activeOrgId
      };
    case RESET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID:
      return {
        ...state,
        activeLocationId: initialState.activeLocationId
      };
    case RESET_QUIZ_OUTCOMES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
