import React, { Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table'; // useFilters
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';
import {
  canAccessCourses,
  canManageCourseContent
} from '../../../services/currentUser';
import { routeWithClassId } from '../../../services/courses';
import {
  CLASS_CONSOLE_USERS,
  MANAGE_CURRENT_ENTRY
} from '../../../constants/routes';

function Table({
  columns,
  data,
  courseIds,
  hiddenColumns = []
}) {
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = pathOr([], ['role'], currentUser);
  // Organization
  const orgType = pathOr('', ['type'], organization);

  const onClickRow = (row) => {
    const { original } = row;
    const classId = pathOr(null, ['id'], original);
    const enableCourseContent = pathOr(null, ['enableCourseContent'], original);
    const manageCourse = canManageCourseContent({
      course: original,
      currentUser,
      orgType
    });

    let route = routeWithClassId({
      route: CLASS_CONSOLE_USERS,
      classId
    });

    if (manageCourse && enableCourseContent) {
      route = `${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=class&classId=${classId}`;
    }

    history.push(route);
  };

  const initialState = {
    ...(courseIds
      ? {}
      : {
        sortBy: [
          {
            id: 'title',
            desc: false
          }
        ]
      }),
    hiddenColumns
  };

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable(
    {
      columns,
      initialState,
      data
    },
    useSortBy
  );

  const canClickCourseRow = useMemo(() => {
    if (hiddenColumns.includes('createdAt')) {
      // Checkbox column is hidden (used for managing courses)

      if (canAccessCourses(role)) {
        return true;
      }
    }
    return false;
  }, [hiddenColumns, role]);

  return (
    <table
      className="table table-bordered m-0"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return column.hideHeader ? null : (
                <th
                  style={{ verticalAlign: 'center' }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className='text-nowrap'
                >
                  {column.render('Header')}
                  {column.canSort && (
                    <span className="ml-1">
                      {column.isSorted ? (
                        <Fragment>
                          {column.isSortedDesc ? (
                            <i className="fas fa-sort-down" />
                          ) : (
                            <i className="fas fa-sort-up" />
                          )}
                        </Fragment>
                      ) : (
                        <i
                          className="fas fa-sort"
                          style={{ opacity: '.25' }}
                        />
                      )}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={
                canClickCourseRow
                  ? () => {
                    onClickRow(row);
                  }
                  : null
              }
              className={canClickCourseRow ? 'row-clickable' : ''}
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape())
};
