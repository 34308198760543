import React, { Fragment } from 'react';
import {
  ICON_CHECK_CIRCLE,
  ICON_X_CIRCLE,
  ICON_TRIANGLE_BANG
} from '@apprentage/components/dist/constants/icons';
import isPublished from '../../utils/isPublished';

export const quizFeedback = ({ score, maxScore, iconOnly = true }) => {
  const quizScore = (score * 100) / maxScore;
  const text = {
    100: 'Perfect score! Great job on this quiz',
    75: 'Great job! You can see hints for the questions you missed below the question text.',
    50: 'Good work! Check over the questions you missed and try them again.',
    25: 'Please review the material and question hints and retake this quiz',
    0: 'Please review the material and question hints and retake this quiz'
  };
  // think the final feedback display should be "You got X out of N correct (Y%)." and then a message:
  if (quizScore === 100) {
    return (
      <Fragment>
        <i
          className={`${ICON_CHECK_CIRCLE} text-keppel ${!iconOnly && 'mr-1'}`}
          aria-hidden="true"
        />
        {!iconOnly && <span>{text[100]}</span>}
      </Fragment>
    );
  }

  if (quizScore >= 75) {
    return (
      <Fragment>
        <i
          className={`fas fa-thumbs-up text-keppel ${!iconOnly && 'mr-1'}`}
          aria-hidden="true"
        />
        {!iconOnly && <span>{text[75]}</span>}
      </Fragment>
    );
  }

  if (quizScore >= 50) {
    return (
      <Fragment>
        <i
          className={`${ICON_TRIANGLE_BANG} text-warning ${
            !iconOnly && 'mr-1'
          }`}
          aria-hidden="true"
        />
        {!iconOnly && <span>{text[50]}</span>}
      </Fragment>
    );
  }

  if (quizScore >= 25) {
    return (
      <Fragment>
        <i
          className={`fas fa-exclamation-circle text-danger ${
            !iconOnly && 'mr-1'
          }`}
          aria-hidden="true"
        />
        {!iconOnly && <span>{text[25]}</span>}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <i
        className={`${ICON_X_CIRCLE} text-danger ${!iconOnly && 'mr-1'}`}
        aria-hidden="true"
      />
      {!iconOnly && <span>{text[0]}</span>}
    </Fragment>
  );
};

export const quizTotalScore = ({ score, maxScore }) => {
  const percentage = (score / maxScore) * 100;

  return `${Math.round(percentage)}%`;
};

export const quizAccepted = ({ score, maxScore }) => {
  const percentage = (score / maxScore) * 100;

  if (percentage >= 75) return true;

  return false;
};

export const quizTotalPoints = ({ score, maxScore, difficulty }) => {
  // attempts
  // TODO use attempts to decrease awarded points
  if (score === maxScore) {
    return maxScore * difficulty;
  }

  if (quizAccepted({ score, maxScore })) {
    return score * difficulty;
  }

  return '-';
};

export const classQuizOutcomesEval = ({ quizzes, quizOutcomes }) => {
  // TODO is this needed, why not consolidate with topicQuizOutcomesEval?
  const quizHash = {};
  const correctOutcomes = [];

  if (!quizzes || (quizzes && Array.isArray(quizzes) && quizzes.length === 0)) {
    // No Quizzes, no need to eval
    console.log('classQuizOutcomesEval: no quizzes', { quizzes, quizOutcomes });
    return true;
  }

  if (quizzes.length && quizOutcomes && quizOutcomes.length) {
    quizOutcomes.forEach((outcome) => {
      if (isPublished(outcome)) {
        const {
          id: outcomeId, score, maxScore, quizId
        } = outcome;

        // Quiz key doesn't exist, create key
        if (quizHash[quizId] === undefined) quizHash[quizId] = [];

        if (quizAccepted({ score, maxScore })) {
          quizHash[quizId].push(outcomeId);
        }
      }
    });

    quizzes.forEach((quiz) => {
      if (quizHash[quiz.sys.id] && quizHash[quiz.sys.id].length > 0) {
        correctOutcomes.push([quiz.sys.id, quizHash[quiz.sys.id][0]]); // [quizId, quizOutcome (approved)]
      }
    });

    // Evaluate total topic quizzes against number of accepted QuizOutcomes
    // left side of expression can be greater than if a user had submitted more than one accepted QuizOutcome
    if (correctOutcomes.length >= quizzes.length) {
      // Add Topic to user Completed Topics
      console.log('classQuizOutcomesEval: approved', { quizzes, quizOutcomes });
      return true;
    }
    // Still more Quizzes that need accepted QuizOutcomes
    console.log('classQuizOutcomesEval: more quizOutcomes needed', {
      quizzes,
      quizOutcomes
    });
    return false;
  }

  // No Quizzes, no need to eval
  console.log('classQuizOutcomesEval: edgeCase: false', {
    quizzes,
    quizOutcomes
  });

  return false;
};

export const topicQuizOutcomesEval = ({ quizzes, quizOutcomes }) => {
  const quizHash = {};
  const correctOutcomes = [];

  if (!quizzes || (quizzes && Array.isArray(quizzes) && quizzes.length === 0)) {
    // No Quizzes, no need to eval
    console.log('topicQuizOutcomesEval: no quizzes', { quizzes, quizOutcomes });
    return true;
  }

  if (quizzes.length && quizOutcomes && quizOutcomes.length) {
    quizOutcomes.forEach((outcome) => {
      if (isPublished(outcome)) {
        const {
          id: outcomeId, score, maxScore, quizId
        } = outcome;

        // Quiz key doesn't exist, create key
        if (quizHash[quizId] === undefined) quizHash[quizId] = [];

        if (quizAccepted({ score, maxScore })) {
          quizHash[quizId].push(outcomeId);
        }
      }
    });

    quizzes.forEach((quiz) => {
      if (quizHash[quiz.sys.id] && quizHash[quiz.sys.id].length > 0) {
        correctOutcomes.push([quiz.sys.id, quizHash[quiz.sys.id][0]]); // [quizId, quizOutcome (approved)]
      }
    });

    // Evaluate total topic quizzes against number of accepted QuizOutcomes
    // left side of expression can be greater than if a user had submitted more than one accepted QuizOutcome
    if (correctOutcomes.length >= quizzes.length) {
      // Add Topic to user Completed Topics
      console.log('topicQuizOutcomesEval: approved', { quizzes, quizOutcomes });
      return true;
    }
    // Still more Quizzes that need accepted QuizOutcomes
    console.log('topicQuizOutcomesEval: more quizOutcomes needed', {
      quizzes,
      quizOutcomes
    });
    return false;
  }

  // No Quizzes, no need to eval
  console.log('topicQuizOutcomesEval: edgecase: false', {
    quizzes,
    quizOutcomes
  });
  return false;
};
