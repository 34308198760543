import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import { resetCurrentModal } from '../../../actions/Modals';
import { setCurrentAnswers } from '../../../actions/Answers';
import { unpublish } from '../../../services/contentful';
import Modal from '../../Modal';
import AnswersList from '../../lists/AnswersList';
import Pagination from '../../Pagination';
import Loading from '../../Loading';

const ManageSubmissions = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const course = useSelector((state) => state.course);
  const currentAnswers = useSelector((state) => state.currentAnswers);
  // Challenge
  const challengeId = currentModal?.data?.challengeId || null;
  // Answers List
  const hideCourse = Boolean(course?.id);
  // Pagination
  const pagination = currentAnswers?.pagination || null;
  const total = pagination?.total || 0;
  const limit = pagination?.limit || 200;
  const skip = pagination?.skip || 0;
  // Local State
  const [loading, setLoading] = useState(false);

  const numSubmissions = useMemo(() => {
    return currentAnswers?.list ? currentAnswers?.list.length : 0;
  }, [currentAnswers?.list]);

  if (!currentAnswers.list || !currentAnswers.list.length) {
    return false;
  }

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleClickPage = (page) => {
    const currentPageIndex = parseInt(page, 10);
    const config = {
      challengeId,
      limit,
      skip: currentPageIndex * limit,
      ...(course?.id ? { classId: course?.id } : {})
      // TODO: include userId
    };

    if (currentPageIndex !== skip) {
      dispatch(setCurrentAnswers(config));
    }
  };

  const deleteEntries = () => {
    setLoading(true);

    const entriesToUnpublish = currentAnswers.list.map((a) => a.id);

    unpublish(entriesToUnpublish, 'Entry').then(() => {
      dispatch(
        setCurrentAnswers({
          ...(challengeId ? { challengeId } : { classId: course?.id }),
          skip: 0,
          limit
        })
      ).then((response) => {
        if (!response.currentAnswers) {
          toast.success('Submissions deleted!');
          handleClose();
        } else {
          toast.info('Please review and delete remaining submissions', {
            autoClose: false
          });
          setLoading(false);
        }
      });
    });
  };

  const deleteVerbiage = ({ baseText }) => {
    return `${baseText} ${pluralize('Submission', numSubmissions, true)}`;
  };

  const confirmDelete = () => {
    const stringCheck = 'permanently delete';
    const promptText = `${deleteVerbiage({ baseText: 'Permanently delete' })}?\n\nType "permanently delete" in the text field and click OK.`;
    const confirmString = prompt(promptText);

    if (confirmString === null || confirmString === '') {
      // No action
      // Cancel or empty field
    } else if (confirmString !== stringCheck) {
      toast.info(
        'We found a typo, try again if you wish to delete submissions.',
        { autoClose: false }
      );
    } else {
      // Success
      // entered stringCheck value and clicked OK
      deleteEntries();
    }
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-fullscreen turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
      pos="middle"
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0 d-flex align-items-center">
            <span>Manage Submissions</span>
            <span className="badge bg-white border ml-2">
              {currentAnswers.list
                && total !== 0
                && `${currentAnswers.list.length}`}
              {total === 0 || total <= limit ? '' : ` / ${total}`}
            </span>
          </h1>
        </div>

        <div className="card-body p-0">
          {loading ? (
            <Loading text={deleteVerbiage({ baseText: 'Deleting' })} />
          ) : (
            <AnswersList
              classId={course?.id}
              userNamePlainText
              hideCourse={hideCourse}
              hideTopic
              hideChallenge
              reviewActive
            />
          )}
        </div>

        {currentAnswers.list && pagination && (
          <Pagination
            items={currentAnswers.list}
            paginationData={pagination}
            handleClickPage={handleClickPage}
          />
        )}

        <div className="card-footer">
          <button
            onClick={() => {
              toast.dismiss();
              confirmDelete();
            }}
            className="btn btn-primary btm-sm"
            type="button"
            disabled={loading}
          >
            {loading ? 'Deleting...' : deleteVerbiage({ baseText: 'Delete' })}
          </button>

          <button
            onClick={handleClose}
            className="btn btn-link ml-2"
            type="button"
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ManageSubmissions;
