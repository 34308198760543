import currencyFormatter from 'currency-formatter';
import {
  PRODUCT_COMMUNITY_STANDARD, PRODUCT_ENTERPRISE_BUSINESS
} from '../constants/billing';

export const turbineProductType = (product) => {
  switch (product) {
    case PRODUCT_ENTERPRISE_BUSINESS:
      return 'Business';
    case PRODUCT_COMMUNITY_STANDARD:
      return 'Version';
    default:
      return product;
  }
};

export const formatCurrency = (amount) => {
  return currencyFormatter.format(amount, { code: 'USD' });
};

export const serializeProductsBySubscriptions = ({ products, subscriptions }) => {
  if (!subscriptions) return products;

  const productsClone = products.slice(0);

  productsClone.forEach((product) => {
    subscriptions.forEach((subscription) => {
      if (product && subscription && subscription.plan) {
        if (product.id === subscription.plan.product) {
          product.purchased = true;
        }
      }
    });
  });

  return productsClone;
};

// TODO currently not used
// will be needed in Subscriptions component when UI is built to filter
// subscriptions by status
export const statusStyle = (status) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'incomplete':
      return 'warning';
    case 'unpaid':
    case 'past_due':
      return 'danger';
    case 'canceled':
    case 'incomplete_expired':
      return 'secondary';
    case 'trialing':
      return 'info';
    default:
      return 'bg-light';
  }
};

const pricing = {
  defaultNumSeats: 15,
  minSeats: 12,
  grad: []
};

// Tier 1 : Graduated Pricing
pricing.grad.push({
  firstUnit: 0,
  lastUnit: 12,
  perUnit: 37.50
});

// Tier 2 : Graduated Pricing
pricing.grad.push({
  firstUnit: pricing.grad[0].lastUnit + 1,
  lastUnit: 50,
  perUnit: 21
});

// Tier 3 : Graduated Pricing
pricing.grad.push({
  firstUnit: pricing.grad[1].lastUnit + 1,
  perUnit: 17
});

export const pricingCalculator = (seats = pricing.defaultNumSeats) => {
  let cost = 0;
  let tier1Cost = 0;
  let tier2Cost = 0;
  let tier3Cost = 0;
  let monthlyCost = 0;
  let monthlyCostPerSeat = 0;

  // Tier 1
  if (seats > pricing.grad[0].firstUnit) {
    tier1Cost = pricing.grad[0].perUnit * seats;

    monthlyCostPerSeat = pricing.grad[0].perUnit.toFixed(2);

    if (seats > pricing.grad[0].lastUnit) {
      tier1Cost = pricing.grad[0].perUnit * pricing.grad[0].lastUnit;
    }
  }

  // Tier 2
  if (seats >= pricing.grad[1].firstUnit) {
    const tier2Seats = seats - pricing.grad[0].lastUnit;
    tier2Cost += tier2Seats * pricing.grad[1].perUnit;

    monthlyCostPerSeat = pricing.grad[1].perUnit;

    if (seats > pricing.grad[1].lastUnit) {
      tier2Cost = pricing.grad[1].perUnit * (pricing.grad[1].lastUnit - pricing.grad[0].lastUnit);
    }
  }

  // Tier 3
  if (seats >= pricing.grad[2].firstUnit) {
    const tier3Seats = seats - pricing.grad[1].lastUnit;

    monthlyCostPerSeat = pricing.grad[2].perUnit;
    tier3Cost += tier3Seats * pricing.grad[2].perUnit;
  }

  cost = tier1Cost + tier2Cost + tier3Cost;
  monthlyCost = cost.toLocaleString();

  return {
    monthlyCostPerSeat,
    monthlyCost
  };
};
