import sysState from './sysState';

const quizState = {
  allowPracticeAfterSubmission: false,
  enableNewEditor: false,
  topicId: '',
  orgId: '',
  activeQuiz: false,
  guidedQuiz: false,
  difficulty: '', // string<1-5>
  questions: null, // [{}]
  description: '',
  classId: '',
  quizSkills: null, // string[]
  title: '',
  integration: null, //
  ...sysState
};

export default quizState;
