import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import {
  FaArrowCircleRight, FaCalendarAlt, FaClipboardList, FaLink, FaSyncAlt
} from 'react-icons/fa';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import Collapse from '@apprentage/components/dist/components/Collapse';
import { roleReadable } from '@apprentage/utils';
import { withAuthorization } from '../Session';
import {
  setCurrentClassQuizzes, resetCurrentClassQuizzes,
  setCurrentClassQuizOutcomes, resetCurrentClassQuizOutcomes,
  setCurrentTopicQuizOutcomes, resetCurrentTopicQuizOutcomes
} from '../../actions/Quizzes';
import {
  setCurrentClassAnswers, resetCurrentClassAnswers,
  setCurrentTopicAnswers, resetCurrentTopicAnswers,
  setCurrentAnswer, resetCurrentAnswer
} from '../../actions/Answers';
import {
  getChallenges, resetChallenges,
  setCurrentChallenge, resetCurrentChallenge
} from '../../actions/Challenges';
import {
  setCurrentTopic, resetCurrentTopic
} from '../../actions/Topic';
import { handleOpenUserSideSheet } from '../../actions/SideSheets';
import { setCurrentClass } from '../../actions/Class';
import { updateAnswer } from '../../services/answers';
import { MANAGE_CURRENT_ENTRY, ORG_CONSOLE_CHALLENGES } from '../../constants/routes';
import { notifyMember, topicApproval } from '../../services/assessments';
import { answerEnum } from '../../constants/answers';
import { routeWithClassId } from '../../services/courses';
import { dateTimestamp, dayMdyDate } from '../../utils/date';
import { canManageCourseContent, fetchUser } from '../../services/currentUser';
import { manageEntry } from '../../services/entry';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import Loading from '../Loading';
import AnswerTabs from '../tabs/AnswerTabs';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import OrgConsoleSubheader from '../OrgConsole/OrgConsoleSubheader';
import CourseHeader from '../Console/CourseHeader';
import Feedback from './Feedback';
import Results from './Results';
import StatusFeedbackBadge from './StatusFeedbackBadge';

/* TRI-STATE APPROVAL LOGIC (user.status)
 *
 * In Review:                 inReview
 * Approved:                  accepted (unlocks next topic & class, if applicable)
 * Approved with Changes:     requestChanges (unlocks next topic, if applicable)
 * Rejected:                  rejected
 *
 */

const Assessment = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const currentUser = useSelector((state) => state.currentUser);
  const currentAnswer = useSelector((state) => state.currentAnswer);
  const currentChallenge = useSelector((state) => state.currentChallenge);
  const challenges = useSelector((state) => state.challenges);
  const currentClassQuizzes = useSelector((state) => state.currentClassQuizzes);
  const currentClassQuizOutcomes = useSelector((state) => state.currentClassQuizOutcomes);
  const currentTopicQuizOutcomes = useSelector((state) => state.currentTopicQuizOutcomes);
  const currentClassAnswers = useSelector((state) => state.currentClassAnswers);
  const currentTopic = useSelector((state) => state.currentTopic);
  const currentTopicAnswers = useSelector((state) => state.currentTopicAnswers);

  // Local State
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('submission');
  const [userNotified, setUserNotified] = useState(false);
  const [answerUser, setAnswerUser] = useState(null);
  // Organization
  const orgName = organization?.name || null;
  const orgType = organization?.type || null;
  // Params
  const answerId = params?.answerId || null;
  const classId = params?.classId || null;
  const topicId = params?.topicId || null;
  const userId = params?.userId || null;
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  const redirectUrl = searchParams.get('redirectUrl');

  // Topic
  const topicQuizzes = currentTopic?.quizes || null;
  const topicChallenges = currentTopic?.challenges || null;
  // Current User
  const reviewerUserId = currentUser?.id || null;
  const reviewerName = currentUser?.name || null;
  // Course
  const classTitle = course?.title || null;
  // Answer
  const feedback = currentAnswer?.feedback || null;
  const feedbackList = feedback?.list || [];
  const challengeId = currentAnswer?.challengeId || null;
  const answerTopicId = currentAnswer?.topicId || null;
  const answerCourseId = currentAnswer?.classId || null;
  // Challenge
  const challengeTitle = currentChallenge?.title || '';
  const instructorNotes = currentChallenge?.instructorNotes || '';
  const enableNewEditor = currentChallenge?.enableNewEditor || null;
  // User
  const userEmail = answerUser?.email || null;
  const userName = answerUser?.name || null;
  // Permissions
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });

  const refreshAssessment = () => {
    dispatch(resetCurrentAnswer());
    dispatch(setCurrentAnswer(answerId));
  };

  const goBackUrl = useMemo(() => {
    if (redirectUrl) {
      /**
       * Course Submissions: /classes/challenges
       * Challenge Submissions: /classes/challenge
       */
      if (redirectUrl.includes('classes/')) {
        return routeWithClassId({
          route: redirectUrl,
          classId
        });
      }

      if (redirectUrl.includes('org/challenges')) {
        return redirectUrl;
      }
    }

    return ORG_CONSOLE_CHALLENGES;
  }, [classId, redirectUrl]);

  const orgConsoleSubheaderTitle = useMemo(() => {
    if (redirectUrl) {
      if (redirectUrl.includes('classes/challenges')) {
        return 'Course Submissions';
      }

      if (redirectUrl.includes('classes/challenge')) {
        return 'Challenge Submissions';
      }
    }

    return 'All Submissions';
  }, [redirectUrl]);

  const handleReviewAnswer = (e) => {
    e.preventDefault();
    let notified = false; // TODO is this needed?
    const { notes: notesData, status: statusData } = e.target.elements;
    const { value: status } = statusData;
    const { value: notes } = notesData;

    const dataToSave = {
      answerId,
      status,
      feedback: {
        list: [
          {
            userName: reviewerName,
            userId: reviewerUserId,
            date: dateTimestamp(),
            body: notes
          },
          ...feedbackList
        ]
      }
    };

    setLoading(true);

    if (status === 'requestChanges') { // Answer accepted, changes requested
      notified = true;
      notifyMemberApprovalWithChanges(notes);

      topicApproval({
        catalyst: 'challenge',
        topicId,
        classId,
        userId,
        topicChallenges,
        topicAnswers: currentTopicAnswers.list,
        topicQuizzes,
        topicQuizOutcomes: currentTopicQuizOutcomes.list,
        classQuizOutcomes: currentClassQuizOutcomes.list,
        classQuizzes: currentClassQuizzes.list,
        classChallenges: challenges.list,
        classAnswers: currentClassAnswers.list
      });
    } else if (status === 'accepted') {
      if (currentTopic.id && !notified) {
        notifyMemberApproval(notes);

        topicApproval({
          catalyst: 'challenge',
          topicId,
          classId,
          userId,
          topicChallenges,
          topicAnswers: currentTopicAnswers.list,
          topicQuizzes,
          topicQuizOutcomes: currentTopicQuizOutcomes.list,
          classQuizOutcomes: currentClassQuizOutcomes.list,
          classQuizzes: currentClassQuizzes.list,
          classChallenges: challenges.list,
          classAnswers: currentClassAnswers.list
        });
      }

      // TODO Additional logic needed?: Rm Class from Completed Classes
    } else {
      // "rejected" - Answer { status: rejected }
      notifyMemberRejected(notes);
    }

    updateAnswer(dataToSave).then(() => {
      history.push(goBackUrl);
    });
  };

  const sendMemberNotification = ({
    message,
    subject,
    status
  }) => {
    const dataToSend = {
      message,
      subject,
      status,
      //
      orgName,
      classId,
      classTitle,
      topicId,
      entryId: challengeId,
      challengeTitle,
      userEmail,
      userName,
      reviewerName
    };

    notifyMember(dataToSend); // TODO email api
  };

  const notifyMemberRejected = (notes) => {
    sendMemberNotification({
      message: 'You need to make some changes and resubmit.',
      subject: `Submission ${answerEnum.rejected}`,
      status: 'rejected',
      notes
    });
  };

  const notifyMemberApproval = (notes) => {
    if (userNotified === true) return null; // Notify member on Slack [ONCE]

    setUserNotified(true);

    sendMemberNotification({
      message: 'Your submission was accepted!',
      subject: `Submission ${answerEnum.accepted}!`,
      status: 'accepted',
      notes
    });
  };

  const notifyMemberApprovalWithChanges = (notes) => {
    if (userNotified === true) return null; // only notify member on Slack once

    setUserNotified(true);

    sendMemberNotification({
      message: 'Your submission was accepted, but you still have changes to make.',
      subject: `Submission ${answerEnum.requestChanges}`,
      status: 'requestChanges',
      notes
    });
  };

  const retrieveAnswerUser = useCallback(() => {
    if (currentAnswer?.userId && !answerUser?.id) {
      fetchUser({
        userId: currentAnswer?.userId,
        select: [
          'id', 'email', 'name', 'role', 'title', 'orgId', 'profilePicture', 'membership'
        ]
      }).then((user) => {
        setAnswerUser(user);

        return user;
      });
    }
  }, [answerUser?.id, currentAnswer?.userId]);

  useEffect(() => {
    retrieveAnswerUser();
  }, [retrieveAnswerUser]);

  // Set Answers (Course & Topic)
  // Set Quiz Outcomes (Course & Topic)
  useEffect(() => {
    if (answerUser?.id) {
      dispatch(setCurrentClassAnswers(classId, answerUser?.id));
      dispatch(setCurrentTopicAnswers(topicId, answerUser?.id));
      // QUIZZES
      dispatch(setCurrentClassQuizOutcomes({ classId, userId: answerUser?.id }));
      dispatch(setCurrentTopicQuizOutcomes({ topicId, userId: answerUser?.id }));
    }
  }, [answerUser?.id, classId, dispatch, topicId]);

  // Set Answer
  useEffect(() => {
    dispatch(setCurrentAnswer(answerId));
  }, [answerId, currentAnswer?.id, dispatch]);

  // Set Course Parts (challenge, challenges quizzes class topic)
  useEffect(() => {
    if (currentAnswer?.id) {
      if (currentAnswer?.challengeId) {
        dispatch(setCurrentChallenge(currentAnswer.challengeId));
      }

      if (currentAnswer?.classId) {
        dispatch(setCurrentClass({ classId: currentAnswer?.classId }));
        dispatch(getChallenges({ classId: currentAnswer?.classId }));
        // QUIZZES
        dispatch(setCurrentClassQuizzes({ classId, activeQuiz: true }));
      }

      if (currentAnswer?.topicId) {
        dispatch(setCurrentTopic({ topicId: currentAnswer?.topicId }));
      }
    }
  }, [
    classId,
    currentAnswer.challengeId,
    currentAnswer?.classId,
    currentAnswer?.id,
    currentAnswer?.topicId,
    dispatch
  ]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetCurrentAnswer());
      dispatch(resetCurrentTopic());
      dispatch(resetCurrentTopicAnswers());
      dispatch(resetCurrentClassAnswers());
      dispatch(resetChallenges());
      dispatch(resetCurrentChallenge());
      // QUIZZES
      dispatch(resetCurrentClassQuizzes());
      dispatch(resetCurrentClassQuizOutcomes());
      dispatch(resetCurrentTopicQuizOutcomes());
    };
  }, [dispatch]);

  if (!currentAnswer?.id) {
    return (
      <Loading text="Loading Submission..." />
    );
  }

  if (!currentChallenge?.id) {
    return (
      <Loading text="Loading Submission..." />
    );
  }

  if (currentAnswer?.userId && !answerUser?.id) {
    return (
      <Loading text="Loading User..." />
    );
  }

  if (!currentAnswer?.id) {
    return null;
  }

  return (
    <main role="main" className="assessments">
      <CourseHeader />

      <div className="row mt-3">
        <div className="col">
          <OrgConsoleSubheader
            pageTitle={orgConsoleSubheaderTitle}
            rawRoute={goBackUrl}
            className='mb-3'
          />

          <OrgConsoleHeader
            pageTitle={currentAnswer?.title || 'Submission'}
            iconComponent={() => <FaClipboardList />}
            className='mb-3'
            badge={() => (
              <StatusFeedbackBadge
                className='ml-2'
                status={currentAnswer?.status}
              />
            )}
          />

          <div className="card mb-5">
            <NameBlock
              profilePicture={answerUser?.profilePicture || DEFAULT_PROFILE_PIC}
              name={userName}
              membership={answerUser?.membership}
              nameSize="md"
              pictureSize='sm'
              className='bg-light pt-3 px-3'
              title={answerUser?.title || roleReadable(answerUser?.role)}
              onClick={() => {
                dispatch(handleOpenUserSideSheet({
                  userId,
                  orgId: organization?.id,
                  currentUserOrgId: currentAnswer?.orgId
                }));
              }}
            />
            <div
              className="w-100"
            >
              <AnswerTabs
                cardStyle
                status={currentAnswer?.status}
                instructorNotes={instructorNotes}
                className='pt-3'
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                }}
              />
            </div>

            <div
              style={{
                display: activeTab === 'submission' ? 'block' : 'none'
              }}
            >
              <div className="card-body">
                <div className='d-flex align-items-top justify-content-between mb-3'>
                  <div>
                    <div className='d-flex align-items-center'>
                      <FaCalendarAlt />
                      <span className='ml-1'>
                        <strong>Submitted:</strong> {dayMdyDate(currentAnswer?.createdAt)}
                      </span>
                    </div>
                  </div>
                  <button
                    className="btn btn-sm btn-outline-secondary"
                    onClick={refreshAssessment}
                    title="Reload data"
                    type="button"
                  >
                    <span className='d-flex align-items-center'>
                      <FaSyncAlt />
                      <span className='d-none d-sm-block ml-1'>
                        Reload
                      </span>
                    </span>
                  </button>
                </div>

                <div className='mb-2'>
                  <div className=''>
                    <div className='h5'>
                      Topic:
                    </div>

                    {answerCourseId && answerTopicId && (
                      <div className='bg-light border rounded p-3'>
                        {manageCourse ? (
                          <Link
                            to={{
                              pathname: MANAGE_CURRENT_ENTRY,
                              search: manageEntry({
                                manageType: 'edit',
                                entryId: answerTopicId,
                                topicId: answerTopicId,
                                classId: answerCourseId,
                                contentType: 'topic'
                              })
                            }}
                            title={`Edit ${currentTopic?.title}`}
                            className='btn-link'
                          >
                            <div
                              className='h6 m-0'
                            >
                              {currentTopic?.title}
                            </div>
                          </Link>
                        ) : (
                          <div
                            className='h6 m-0'
                          >
                            {currentTopic?.title}
                          </div>
                        )}
                      </div>
                    )}

                  </div>
                </div>

                <div className='mb-2'>
                  <div className='d-flex align-items-center'>
                    <div className='h5 m-0'>
                      Challenge
                    </div>

                    {/* {currentChallenge?.activeChallenge && (
                        <span className='d-flex align-items-center ml-2'>
                          <FaCheckCircle size={12} className='text-keppel' />
                          <span className='ml-1'>Active</span>
                        </span>
                      )}

                      {!currentChallenge?.activeChallenge && (
                        <span
                          className="badge badge-error ml-2"
                        >
                          Inactive
                        </span>
                      )} */}

                  </div>
                </div>

                <Collapse
                  id="challenge-info"
                  title={currentChallenge?.title}
                  className="mb-3"
                  p={3}
                  badge={() => {
                    return (
                      <span className='badge badge-secondary h6 mb-0 ml-2'>
                        {currentChallenge?.challengeType.toUpperCase()}
                      </span>
                    );
                  }}
                >
                  <RenderMarkdown
                    source={currentChallenge?.question}
                    enableNewEditor={enableNewEditor}
                  />

                  {currentChallenge?.submissionPromptText && (
                    <RenderMarkdown
                      className="mt-3"
                      source={currentChallenge?.submissionPromptText}
                      enableNewEditor={enableNewEditor}
                    />
                  )}
                </Collapse>

                <h5 className='font-weight-bold'>
                  Answer
                </h5>

                <Collapse
                  id="answer-info"
                  title={currentAnswer?.title}
                  className="mb-3"
                  ariaExpanded
                  p={3}
                  badge={() => (
                    <StatusFeedbackBadge
                      className='ml-2'
                      status={currentAnswer?.status}
                    />
                  )}
                >
                  {currentAnswer?.solution && (
                    <p className='d-flex align-items-center mb-0'>
                      <FaLink className='text-primary' />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={currentAnswer?.solution}
                        className='ml-2'
                      >
                        {currentAnswer?.solution}
                      </a>
                    </p>
                  )}

                  {currentAnswer?.results?.formData && (
                    <Results />
                  )}
                </Collapse>

              </div>
              <div className='card-footer'>
                <button
                  className='btn btn-outline-primary btn-md'
                  type='button'
                  onClick={() => {
                    setActiveTab('review');
                  }}
                >
                  <span className='d-flex align-items-center'>
                    <span className='mr-2'>
                      Review Answer
                    </span>
                    <FaArrowCircleRight />
                  </span>
                </button>
              </div>
            </div>

            <div
              style={{
                display: activeTab === 'review' ? 'block' : 'none'
              }}
            >
              <form
                name="review-answer"
                onSubmit={handleReviewAnswer}
              >
                <div className="card-body">

                  <div className='d-flex align-items-top justify-content-between mb-2'>
                    <h6 className='m-0 font-weight-bold'>
                      Review Submission
                    </h6>
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={refreshAssessment}
                      title="Reload data"
                      type="button"
                    >
                      <span className='d-flex align-items-center'>
                        <FaSyncAlt />
                        <span className='d-none d-sm-block ml-1'>
                          Reload
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="status"
                      className="font-weight-bold"
                    >
                      Status
                    </label>
                    <select
                      id="status"
                      name="status"
                      className="form-control"
                      defaultValue={currentAnswer?.status}
                      required
                    >
                      <option value="">Choose a value</option>
                      <option value="requestChanges">
                        ⭐ {answerEnum.requestChanges}
                      </option>
                      <option value="accepted">
                        ✅ {answerEnum.accepted}
                      </option>
                      <option value="rejected">
                        ❌ {answerEnum.rejected}
                      </option>
                    </select>
                  </div>
                  <div className="form-group my-3">
                    <div className="d-flex justify-content-between">
                      <div
                        htmlFor="notes"
                        className="mt-1 font-weight-bold"
                      >
                        Feedback
                      </div>
                    </div>
                    <div className="text-muted mb-2">
                      Leave feedback about this submission for the user to review.
                    </div>
                    <textarea
                      id="notes"
                      name="notes"
                      className="form-control"
                      required
                      autoFocus
                    />

                    {feedback && (
                      <>
                        <div className="mt-2 mb-1 small text-muted">
                          Previous Feedback:
                        </div>
                        <Feedback
                          feedback={feedback}
                        />
                      </>
                    )}
                  </div>

                  <div className="form-group my-3 d-none">
                    <small className="text-muted"><b>User ID:</b> {userId}</small>
                    <br />
                    <small className="text-muted"><b>ID:</b> {currentAnswer.id}</small>
                  </div>
                </div>
                <div className="card-footer justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save'}
                  </button>
                  <Link
                    to={goBackUrl}
                    title="Cancel"
                    disabled={loading}
                    className="btn btn-link ml-2"
                  >
                    Cancel
                  </Link>
                </div>
              </form>
            </div>

            <div
              className="card-body overflow-hidden"
              style={{
                display: activeTab === 'instructorNotes' ? 'block' : 'none'
              }}
            >
              <h6 className='font-weight-bold mb-3'>
                Instructor Notes
              </h6>

              <RenderMarkdown
                source={instructorNotes}
                enableNewEditor={enableNewEditor}
              />
            </div>

          </div>
        </div>
      </div>
    </main>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Assessment);
