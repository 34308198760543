import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { resetCurrentModal } from '../../../actions/Modals';
import { LAYOUTS } from '../../../constants/dashboard';
import Modal from '../../Modal';

const ChangeDashboardLayout = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Current Modal
  const {
    activeLayout = '',
    selectedLayout = ''
  } = currentModal?.data;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSelectLayout = (layout) => {
    currentModal?.callbackPrimaryAction(layout);
    handleClose();
  };

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h5 className='m-0 d-flex align-items-center'>
            <RiLayoutMasonryFill className='mr-2' />
            <span>
              Layout
            </span>
            <span className='badge badge-secondary ml-2'>
              Settings
            </span>
          </h5>
        </div>
        <div className="card-body container">
          <div className="container">
            <div className="row">
              {Object.keys(LAYOUTS).map((layout) => {
                return (
                  <div className="col-12 col-md-6 mb-3">
                    <LayoutWrapper
                      key={layout}
                      layoutName={layout}
                      layoutData={LAYOUTS[layout]}
                      activeLayout={activeLayout}
                      selected={selectedLayout === layout}
                      handleLayoutSelect={handleSelectLayout}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const LayoutWrapper = ({
  layoutData,
  activeLayout,
  selected,
  layoutName,
  handleLayoutSelect
}) => {
  return (
    <div
      className="p-3 rounded rounded-4"
      style={{ border: '1px dashed black' }}
    >
      <Layout layout={layoutData} />
      {activeLayout !== layoutName && !selected && (
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-md btn-primary"
            onClick={() => {
              handleLayoutSelect(layoutName);
            }}
          >
            Select
          </button>
        </div>
      )}
      {activeLayout === layoutName && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="mr-2">
            <input
              type="checkbox"
              checked={activeLayout === layoutName && selected}
              onClick={() => handleLayoutSelect(layoutName)}
            />
          </div>
          <div>Current</div>
        </div>
      )}

      {selected && activeLayout !== layoutName && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="mr-2">
            <input
              type="checkbox"
              checked
            />
          </div>
          <div>Selected</div>
        </div>
      )}
    </div>
  );
};

const Layout = ({ layout }) => {
  return (
    <div className="row g-2 p-3">
      {layout.map((size, index) => (
        <div className={`col-${size} border border-1 rounded p-5`}>
          <h6 className="text-center">{index + 1}</h6>
        </div>
      ))}
    </div>
  );
};

export default ChangeDashboardLayout;
