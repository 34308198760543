import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import Form from './Form';

const ManagePrompt = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onSuccessCallback = (data) => {
    if (currentModal?.callbackPrimaryAction) {
      currentModal.callbackPrimaryAction(data);
    }

    handleClose();
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <Form
        onSuccessCallback={onSuccessCallback}
        onCancelCallback={handleClose}
      />
    </Modal>
  );
};

export default ManagePrompt;
