import React, { useMemo } from 'react';
import { ICON_EXTERNAL_LINK } from '@apprentage/components/dist/constants/icons';
import { createEntryUrl } from '../../../services/entry';
import { redirectToTurbineApp } from '../../../services/auth';

const EntryButton = ({
  title = '',
  target = '_blank',
  className = 'btn btn-sm btn-primary',
  classId,
  topicId,
  entryId
}) => {
  const entryUrl = useMemo(() => {
    const data = {};

    if (classId) {
      data.classId = classId;
    }

    if (topicId && entryId) {
      data.topicId = topicId;
      data.entryId = entryId;
    }

    return createEntryUrl(data);
  }, [classId, topicId, entryId]);

  return (
    <a
      href={redirectToTurbineApp(entryUrl)}
      title={title}
      className={`${className} text-nowrap d-flex align-items-center`}
      target={target}
      rel={target === '_self' ? '' : 'noopener noreferrer'}
    >
      <span className="d-none d-md-inline mr-2">{title}</span>

      {target !== '_self' && (
        <small>
          <i className={ICON_EXTERNAL_LINK} />
        </small>
      )}
    </a>
  );
};

export default EntryButton;
