import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { LIGHT_GRAY, LIGHT_SEA_GREEN } from '../../../constants/dataVisualization';
import NumberCard from '../../NumberCard';

const UserCourseCompletionChart = ({
  courseCompletionRate,
  completedCourses,
  usersEnrolled,
  title
}) => {
  const data = {
    labels: ['Users Completed', 'Users Enrolled'],
    datasets: [
      {
        data: [completedCourses, usersEnrolled],
        backgroundColor: [
          LIGHT_SEA_GREEN,
          LIGHT_GRAY
        ],
        borderWidth: 1
      }
    ]
  };

  if (courseCompletionRate !== '0%') {
    return (
      <div className="card h-100">
        {title && (
          <div className="card-header d-flex align-items-center justify-content-between">
            <span className="h5 m-0 font-weight-bold">
              {title}
            </span>
            <span className="h5 m-0">
              <span className="py-2 px-3 badge bg-light border">
                {usersEnrolled}
              </span>
            </span>
          </div>
        )}
        <div className="card-body">
          <Chart type="doughnut" data={data} />
        </div>
        <div className="card-footer d-flex align-items-center justify-content-between">
          <div>
            <span className="mr-1">
              Completion Rate:
            </span>
            <span className="h5 m-0">
              <span className="badge badge-info">
                {courseCompletionRate}
              </span>
            </span>
          </div>
          <div>
            <span className="mr-1">
              Active:
            </span>
            <span className="h5 m-0">
              <span className="badge bg-light border">
                {usersEnrolled - completedCourses}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <NumberCard
      num="0%"
      text="Completion Rate"
    />
  );
};

export default UserCourseCompletionChart;
