import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { FaCheckCircle, FaUserCheck } from 'react-icons/fa';
import { updateUser } from '../../../services/user';
import { dateTimestamp, mdySimple } from '../../../utils/date';
import findObjectByKeyPartialVal from '../../../utils/findObjectByKeyPartialVal';

const AttendanceButton = ({
  user,
  showTakeAttendance,
  disabled
}) => {
  // Redux
  const course = useSelector((state) => state.course);
  const currentUser = useSelector((state) => state.currentUser);
  // Local state
  const [loading, setLoading] = useState(false);
  const [present, setPresent] = useState(false);
  // Misc
  const reviewerId = currentUser?.id || '';
  const reviewerName = currentUser?.name || '';
  const userId = user?.id || '';
  const attendance = user?.attendance || null;
  const name = user?.name || '';
  let attendanceTaken = false;
  let latestRecord = false;

  useEffect(() => {
    // https://www.npmjs.com/package/react-tooltip/v/4.5.1#3-tooltip-not-binding-to-dynamic-content
    ReactTooltip.rebuild();
  }, [showTakeAttendance]);

  const takeAttendance = () => {
    // const { id: reviewerId, name: reviewerName } = currentUser;
    const currentDateTimestamp = dateTimestamp(); // { utcHours: false }
    const data = {
      class: {
        id: course.id,
        title: course.title
      },
      instructor: {
        id: reviewerId,
        name: reviewerName
      },
      date: currentDateTimestamp
    };

    let attendanceObj = {};

    if (attendance !== undefined) {
      attendanceObj = attendance;
    }

    if (!attendanceObj[course.id]) {
      attendanceObj[course.id] = [];
    }

    attendanceObj[course.id].push(data);

    setLoading(true);

    updateUser({
      attendance: attendanceObj
    }, userId)
      .then(() => {
        setLoading(false);
        setPresent(true);
      })
      .catch((error) => {
        setLoading(false);

        console.error(error);
      });
  };

  if (attendance && attendance[course.id] && attendance[course.id].length) {
    const attendanceClass = attendance[course.id];
    // const latestRecord = attendanceClass[attendanceClass.length - 1];
    const currentDateTimestamp = dateTimestamp(); // { utcHours: false }
    const todaysDate = currentDateTimestamp.split('T')[0];
    latestRecord = findObjectByKeyPartialVal(
      attendanceClass,
      'date',
      todaysDate
    );

    if (latestRecord) {
      attendanceTaken = true;
    }
  }

  if (attendanceTaken && !loading) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <FaCheckCircle
          size={15}
          className='text-success'
        />
        <span className='ml-2'>
          {mdySimple(latestRecord.date)}
        </span>
      </div>
    );
  }

  if (loading || present) {
    return (
      <div
        className="btn btn-sm btn-success text-nowrap"
        disabled
      >
        <span className='d-flex align-items-center'>
          <FaUserCheck size={15} />
          <span className='ml-2'>
            {loading ? 'Loading...' : 'Present'}
          </span>
        </span>

      </div>
    );
  }

  if (showTakeAttendance) {
    if (disabled) {
      return (
        <div
          style={{ cursor: 'default' }}
          className='btn btn-sm btn-outline-secondary'
          // data-tip={disabled ? 'Enable Live Stream to take Attendance' : 'Mark user present'}
          // data-tooltip-id="MembersList"
          data-tip={disabled ? 'Enable Live Stream to take Attendance' : 'Mark user present'}
          data-for="MembersList"
        >
          <span className='d-flex align-items-center'>
            <FaUserCheck size={15} />
            <span className='ml-2'>
              Present
            </span>
          </span>
        </div>
      );
    }

    return (
      <button
        onClick={takeAttendance}
        className="btn btn-sm btn-outline-success text-nowrap"
        title={`${name} is Present`}
        disabled={loading}
        type="button"
      >
        <span className='d-flex align-items-center'>
          <FaUserCheck size={15} />
          <span className='ml-2'>
            Present
          </span>
        </span>
      </button>
    );
  }

  return (
    <div
      className="text-nowrap text-muted"
      disabled
    >
      <i className="fas fa-user-times mr-1" /> Not present
    </div>
  );
};

export default AttendanceButton;
