import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { client, flattenItems } from './contentful';
import { updateEntry } from './entry';
import formatData from './formatData';

export const updateChallenge = (data) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'challenge');

    updateEntry(preppedData, data?.challengeId).then((updatedChallenge) => {
      setTimeout(() => {
        resolve(updatedChallenge);
      }, 250);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchChallenges = ({
  skillIds,
  classId,
  topicId,
  orgId,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'challenge',
      limit,
      'fields.orgId': orgId
    };

    if (select) {
      config.select = select.join(',');
    }

    if (classId) {
      config['fields.classId'] = classId;
    }

    if (topicId) {
      config['fields.topicId'] = topicId;
    }

    if (skillIds) {
      config['fields.challengeSkills[in]'] = skillIds.join(',');
    }

    client.getEntries(config).then(({ items }) => {
      const list = items.length ? flattenItems(items) : null;
      resolve(list);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchChallengesBySkillIds = ({
  skillIds,
  classId,
  topicId,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchChallenges({
      orgId,
      classId,
      topicId,
      skillIds,
      limit: 200,
      select: ['sys.id', 'fields.title', 'fields.challengeSkills', 'sys.createdAt']
    })
      .then((response) => {
        resolve({ challenges: response }); // TODO responses?.items when migrated to Supabase
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
