import React from 'react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';

const Transcript = ({ text = '' }) => (
  <div>
    <sup>
      <FaQuoteLeft
        size={10}
        className='d-inline'
      />
    </sup>
    <span className='mx-2'>
      {text}
    </span>
    <sup>
      <FaQuoteRight
        size={10}
        className='d-inline'
      />
    </sup>
  </div>
);

export default Transcript;
