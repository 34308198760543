import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  FaImages, FaLayerGroup, FaSortAmountUp, FaUsers
} from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_LOCATIONS, ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../constants/routes';
import { locationTerminology } from '../../../services/locations';
import { getLocation } from '../../../actions/Locations';
import { entryOrgMatchesUserOrg, routeWithOrgId } from '../../../services/organizations';
import withOrgConsole from '../../App/withOrgConsole';
import OrgConsoleHeader from '../OrgConsoleHeader';
import Loading from '../../Loading';
import InfoBlock from '../../Location/InfoBlock';
import ApprenticeshipsList from '../../lists/ApprenticeshipsList';
import Vitals from './Vitals';
import WeatherSmall from '../../LocationsList/WeatherSmall';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import ProjectsList from '../../lists/ProjectsList';
import { goToElem } from '../../../services/manageContent';
import { setSideSheet } from '../../../actions/SideSheets';
import { SHEET_KEY_PROJECT } from '../../../constants/sideSheets';
import { getProject } from '../../../actions/Projects';
import MembersList from '../../MembersList';
import CoursesList from '../../CoursesList';
import { getCourses } from '../../../actions/Courses';

const LocationView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const dictionary = useSelector((state) => state.dictionary);
  const orgLocation = useSelector((state) => state.orgLocation);
  const projects = useSelector((state) => state.projects);
  const users = useSelector((state) => state.users);
  const courses = useSelector((state) => state.courses);
  // Current User
  const userOrgId = currentUser?.orgId || null;
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const enableProjects = organization?.enableProjects || false;
  const enableWorkforceSuite = organization?.enableWorkforceSuite || false;
  // Location
  const locationId = params?.locationId || null;
  const locationTerm = locationTerminology({ orgType });

  const initialListConfig = {
    orgId,
    page: 1,
    limit: 6,
    locationId,
    title: ''
  };

  const initialUsersListConfig = {
    orgId,
    page: 1,
    limit: 10,
    locationId,
    title: '',
    excludeInactive: true,
    excludeTestUsers: true
  };

  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);
  const [usersListConfig, setUsersListConfig] = useState(initialUsersListConfig);

  const handleOnClickRow = (apprenticeshipId) => {
    const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

    history.push(routeWithOrgId({
      route: `${apprenticeshipRoute}/employer/${locationId}`,
      orgId
    }));
  };

  const numCourses = useMemo(() => {
    return Array.isArray(courses?.list) ? courses?.list?.length : 0;
  }, [courses?.list]);

  const projectsHalfWidth = useMemo(() => {
    return (enableWorkforceSuite || numCourses > 0);
  }, [enableWorkforceSuite, numCourses]);

  useEffect(() => {
    if (orgLocation?.orgId && userOrgId) {
      if (!entryOrgMatchesUserOrg(orgLocation?.orgId, userOrgId)) {
        toast.error('Access to this Entry is not allowed (ORG)');
      }
    }
  }, [orgLocation?.orgId, userOrgId]);

  useEffect(() => {
    if (locationId && !orgLocation?.id) {
      dispatch(getLocation(locationId));
    }
  }, [dispatch, locationId, orgLocation?.id]);

  useEffect(() => {
    if (orgId && orgType === 'workforce') {
      dispatch(getCourses({ locationIds: [locationId], orgId }));
    }
  }, [dispatch, locationId, orgId, orgType]);

  if (!orgLocation?.id) {
    return (
      <Loading text={`Loading ${locationTerm.text}...`} />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={orgLocation.name}
            icon={dictionary?.locationIcon}
            image={orgLocation.locationImage}
            route={ORG_CONSOLE_LOCATIONS}
          >
            <WeatherSmall
              className='d-none d-sm-block'
              postalCode={orgLocation?.addressPostalCode}
            />
          </OrgConsoleHeader>

          <div className='row mt-3'>
            <div className='col-12 col-sm-6'>
              <Vitals />
            </div>
            <div className='col-12 col-sm-6'>
              <InfoBlock
                title={locationTerm.text}
                icon={locationTerm.icon}
                className='card h-100'
                data={orgLocation}
                showMenu
              />
            </div>
          </div>

          <div className='row mt-3'>
            {enableWorkforceSuite && (
              <div className={`col-12 ${enableProjects ? 'col-sm-6' : ''}`}>
                <div className="card h-100">
                  <div className='h6 m-0 card-header d-flex justify-content-between align-items-center'>
                    <div className='font-weight-bold d-flex align-items-center'>
                      <FaSortAmountUp />
                      <span className='ml-2'>
                        Training Programs
                      </span>
                    </div>
                  </div>

                  <div className='card-body h-100 p-0'>
                    <ApprenticeshipsList
                      displayType="TABLE"
                      employerId={locationId}
                      hiddenColumns={['employerIds', 'ojtHours']}
                      onClickRow={handleOnClickRow}
                    />
                  </div>
                </div>
              </div>
            )}

            {(orgType === 'workforce' && numCourses > 0) && (
              <div className={`col-12 ${enableProjects ? 'col-sm-6' : ''}`}>
                <div className="card h-100">
                  <div className='h6 m-0 card-header d-flex justify-content-between align-items-center'>
                    <div className='font-weight-bold d-flex align-items-center'>
                      <FaLayerGroup />
                      <span className='ml-2'>
                        Courses
                      </span>
                    </div>
                  </div>

                  <div className='card-body h-100 p-0'>
                    <CoursesList
                      list={courses?.list}
                      showGroupsCol
                    />
                  </div>
                </div>
              </div>
            )}

            {enableProjects && (
              <div
                id="locationProjects"
                className={`col-12 ${projectsHalfWidth ? 'col-sm-6' : ''}`}
              >
                <div className="card h-100">
                  <div className='h6 m-0 card-header d-flex justify-content-between align-items-center'>
                    <div className='font-weight-bold d-flex align-items-center'>
                      <FaImages />
                      <span className='ml-2'>
                        Projects
                      </span>
                    </div>
                  </div>

                  <div className='card-body h-100 p-0 overflow-x-scroll'>
                    <ProjectsList
                      listConfig={listConfig}
                      {...(projectsHalfWidth ? {} : { showLearningModulesCol: true, showSkillsCol: true, showCreatedAtCol: true })}
                      onClick={(project) => {
                        dispatch(getProject(project?.id));
                        dispatch(setSideSheet({
                          key: SHEET_KEY_PROJECT,
                          className: 'ProjectSideSheet',
                          data: {
                            skipUnmount: false
                          }
                        }));
                      }}
                    />

                    {Array.isArray(projects.list) && projects.list.length > 0 && (
                      <PaginationSupabase
                        items={projects?.list}
                        pagination={projects?.pagination}
                        page={projects?.pagination?.page}
                        className=''
                        style={{}}
                        onClickNext={() => {
                          setListConfig((prev) => ({
                            ...prev,
                            page: projects?.pagination?.page + 1
                          }));
                          // goToElem('locationProjects');
                        }}
                        onClickPrev={() => {
                          setListConfig((prev) => ({
                            ...prev,
                            page: projects?.pagination?.page - 1
                          }));
                          // goToElem('locationProjects');
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div id="locationUsers" className='row mt-3'>
            <div
              className="col-12"
            >
              <div className="card h-100">
                <div className='h6 m-0 card-header d-flex justify-content-between align-items-center'>
                  <div className='font-weight-bold d-flex align-items-center'>
                    <FaUsers />
                    <span className='ml-2'>
                      {dictionary?.users}
                    </span>
                  </div>
                </div>

                <div className='card-body h-100 p-0'>
                  <MembersList
                    // route={`${ORG_CONSOLE_LOCATION_BASE}/${locationId}`}
                    listConfig={usersListConfig}
                    // showSkillsCol={showSkillsColumn}
                    showContactCol
                    showGroupsCol
                  />
                  {Array.isArray(users.list) && users.list.length > 0 && (
                    <PaginationSupabase
                      items={users?.list}
                      pagination={users?.pagination}
                      page={users?.pagination?.page}
                      onClickNext={() => {
                        setUsersListConfig((prev) => ({
                          ...prev,
                          page: users?.pagination?.page + 1
                        }));
                        goToElem('locationUsers');
                      }}
                      onClickPrev={() => {
                        setUsersListConfig((prev) => ({
                          ...prev,
                          page: users?.pagination?.page - 1
                        }));
                        goToElem('locationUsers');
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(LocationView);
