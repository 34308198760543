import React from 'react';
import Skeleton from 'react-loading-skeleton';

const TableLoading = () => {
  return (
    <table
      className='table table-bordered m-0'
    >
      <thead>
        <tr>
          <th>
            <Skeleton />
          </th>
        </tr>
        <tr>
          <th>
            <Skeleton />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Skeleton />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default TableLoading;
