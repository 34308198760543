import isProgramTrainer from '../isProgramTrainer';
import isProgramManager from '../isProgramManager';

export default function canAccessTopNavLocationBar(role = []) {
  if (isProgramTrainer(role) || isProgramManager(role)) {
    return true;
  }

  return false;
}
