import React from 'react';
import { FaLayerGroup } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const Courses = () => {
  const dictionary = useSelector((state) => state.dictionary);

  return (
    <div className='mb-3'>
      <h5>
        {dictionary?.courses}
      </h5>
      <div className='d-flex justify-content-between'>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaLayerGroup size={30} />
        </div>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaLayerGroup size={30} />
        </div>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaLayerGroup size={30} />
        </div>
        <div
          className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white'
          style={{
            border: '1px dashed #dee2e6',
            width: '30%'
          }}
        >
          <FaLayerGroup size={30} />
        </div>
      </div>
    </div>
  );
};

export default Courses;
