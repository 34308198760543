import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { FaSearch } from 'react-icons/fa';
import { setCurrentModal } from '../../../actions/Modals';
import { resetSearch } from '../../../actions/Search';
import { MODAL_KEY_SEARCH_CONTENT } from '../../../constants/modals';
import './style.css';

const SearchBoxLauncher = ({
  id,
  className = 'border-right',
  placeholder = 'Search',
  searchGroupIds,
  modalTitle,
  searchContentTypes
}) => {
  const dispatch = useDispatch();

  const openSearchContentModal = () => {
    dispatch(resetSearch());
    dispatch(setCurrentModal({
      key: MODAL_KEY_SEARCH_CONTENT,
      data: {
        modalTitle,
        searchGroupIds,
        searchContentTypes
      }
    }));
  };

  return (
    <div
      {...(id ? { id } : {})}
      className={`search-box-launcher ${className}`}
    >
      <div className='d-block d-sm-none input-group bg-white'>
        <button
          className="topNav-icon-dropdown-btn"
          type="button"
          aria-label="Search"
          data-cy="TopNav-SearchContent-btn"
          onClick={openSearchContentModal}
          style={{
            width: '35px'
          }}
        >
          <FaSearch className='search-box-launcher-mobile-icon' />
        </button>
      </div>

      <div
        className="search-box-launcher-input-container d-none d-sm-block position-relative pl-4"
        onClick={openSearchContentModal}
      >
        <SearchInput
          type="text"
          className="form-control"
          name="searchValueInput"
          defaultValue=''
          placeholder={placeholder}
        />
        <div
          className='topNav-icon-dropdown-btn position-absolute'
          style={{
            top: 0,
            left: 0,
            cursor: 'pointer',
            width: '45px'
          }}
        >
          <FaSearch />
        </div>
      </div>
    </div>
  );
};

const SearchInput = styled.input`
  height: 62px;
  background: none;
  font-size: 1rem;
`;

export default SearchBoxLauncher;
