import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { compose } from 'redux';
import { withAuthorization } from '../../Session';
import { getCourses } from '../../../actions/Courses';
import isPublished from '../../../utils/isPublished';
import Form from './Form';
import withConsole from '../../App/withConsole';

const CoursePacing = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  // Misc
  const orgId = pathOr(null, ['id'], organization);

  useEffect(() => {
    dispatch(getCourses({ orgId }));
  }, []);

  const {
    sys, // TODO course flatten
    ...remainingCourseData
  } = course;

  const data = {
    ...remainingCourseData
  };

  // If prerequisite exists, save locked and prerequisiteId in the state
  if (data && isPublished(data.prerequisite) && data.prerequisite.sys) {
    data.prerequisiteId = data.prerequisite.sys.id; // TODO course flatten
    data.locked = true;
  }

  return (
    <Form
      data={data}
    />
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(CoursePacing);
