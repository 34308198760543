import React from 'react';
import styled from 'styled-components';

const LargeNumber = styled.div`
  font-size: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const NumberCard = ({
  num,
  text,
  className = '',
  icon
}) => {
  return (
    <div className={`card ${className}`}>
      <div className="card-body h-100">
        <LargeNumber>
          {num}
        </LargeNumber>
      </div>
      <div className="card-footer d-flex align-items-center justify-content-center">
        <span className='d-flex align-items-center'>{icon ? icon() : ''}{text}</span>
      </div>
    </div>
  );
};

export default NumberCard;
