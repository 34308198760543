import React, { useState } from 'react';
import { FaCloudUploadAlt, FaCog, FaFan } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import KnowledgeCaptureImg from './knowledge-capture.png';

const KnowledgeCapture = ({
  onClick,
  loading
}) => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgName = organization?.name || '';
  // Local State
  const [refId, setRefId] = useState('cb9a024a-5906-4b81-b526-a2c36de525f6');
  // const [refId, setRefId] = useState('2204079f-1279-4db8-b8d1-e070006a471b');
  const [showRefIdField, setShowRefIdField] = useState(false);

  return (
    <div>
      <div className='d-flex align-items-center mb-3'>
        <FaCloudUploadAlt size={30} />
        <div className='ml-2'>
          <div>
            Upload a document
          </div>
          <h1 className="h6 m-0 font-weight-bold text-purple-light">
            Knowledge Capture begins now
          </h1>
        </div>
      </div>

      <div className="h5 mt-4 mb-3">
        Each document is a building block that informs your workforce
        of how to do something at {orgName}.
      </div>

      <div className='bg-light p-3 rounded my-4'>
        <img
          className='w-100'
          src={KnowledgeCaptureImg}
          alt="Vela Overview"
        />
      </div>

      <p className="font-weight-bold mb-4 text-center">
        Choose a file to begin capturing knowledge.
      </p>

      <div className='d-flex align-items-center justify-content-center'>
        <button
          type="button"
          disabled={!refId || loading}
          className="btn bg-purple-light btn-md text-white"
          onClick={() => {
            onClick(refId);
          }}
        >
          <span className='d-flex align-items-center'>
            {loading ? (
              <FaFan className='fa-spin' />
            ) : (
              <FaCloudUploadAlt />
            )}
            <span className='ml-2'>
              Choose File
            </span>
          </span>
        </button>
      </div>

      <div className='d-flex justify-content-end'>
        <button
          type='button'
          className='btn btn-sm btn-white small'
          aria-label="Toggle Ref ID field"
          onClick={() => {
            setShowRefIdField(!showRefIdField);
          }}
        >
          <FaCog />
        </button>
      </div>

      {showRefIdField && (
        <div className='mb-4 small'>
          <div className='font-weight-bold'>
            Ref ID
          </div>
          <input
            type='text'
            value={refId}
            autoFocus
            onChange={(e) => {
              const { value } = e.currentTarget;
              setRefId(value);
            }}
          />
          <div className='text-muted'>
            Ex: 6342d2fa-a12e-4bd8-aa7f-9ebd01a26945
          </div>
        </div>
      )}
    </div>
  );
};

export default KnowledgeCapture;
