import React from 'react';
import { compose } from 'redux';
import { ICON_COURSE } from '@apprentage/constants';
import { withAuthorization } from '../../Session';
import withOrgConsole from '../../App/withOrgConsole';
import CreateCourseButton from '../../btns/CreateCourseButton';
import Classes from '../../Classes';
import Tabs from './Tabs';
import OrgConsoleHeader from '../OrgConsoleHeader';
import { ORG_CONSOLE_DASHBOARD } from '../../../constants/routes';

const Courses = () => {
  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Courses"
            icon={ICON_COURSE}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <CreateCourseButton />
          </OrgConsoleHeader>

          <Tabs active="catalog" />

          <div
            className="card-body bg-white border-left border-right border-bottom overflow-content"
          >
            <Classes />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Courses);
