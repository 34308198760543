import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import { withAuthorization } from '../Session';
import { resetClassCertificate } from '../../actions/Class';
import { CONSOLE_CE_ATTENDANCE, ORG_CONSOLE_DASHBOARD } from '../../constants/routes';
import { mdySimple, ySimple } from '../../utils/date';
import CourseInfo from './CourseInfo';
import { totalSessionCredits } from '../../services/attendance';
import CategoryCredits from './CategoryCredits';
import SignatureBlock from './SignatureBlock';
import Loading from '../Loading';
import { routeWithOrgId } from '../../services/organizations';

const Certificate = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  // Redux
  const course = useSelector((state) => state.course);
  const userLocation = useSelector((state) => state.userLocation);
  const organization = useSelector((state) => state.organization);
  const classCertificate = useSelector((state) => state.classCertificate);
  // Misc
  const classId = pathOr(null, ['classId'], params);
  const orgId = pathOr(null, ['id'], organization);
  const orgName = pathOr(null, ['name'], organization);
  const completionCertificateDescription = pathOr(null, ['completionCertificateDescription'], course);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetClassCertificate());
    };
  }, []);

  const goBack = () => {
    const orgConsoleDashboardRoute = routeWithOrgId({
      route: ORG_CONSOLE_DASHBOARD,
      orgId
    });

    if (params && classId) {
      history.push(`${CONSOLE_CE_ATTENDANCE}?classId=${classId}`);
    } else {
      history.push(orgConsoleDashboardRoute);
    }
  };

  if (!organization.id || !userLocation.id) {
    return (
      <Loading />
    );
  }

  if (!classCertificate?.id) { // This "id" is the classId, not a certificate "id"
    goBack();
    return null;
  }

  const { managerIds } = userLocation;
  const attendance = classCertificate?.attendance || [];
  const eUnitTracking = classCertificate?.eUnitTracking || null;
  const studentOrgId = classCertificate?.student?.orgId;
  const name = classCertificate?.student?.name;
  const locationId = classCertificate?.student?.locationId;

  const courseCodes = [];
  const categories = {};
  let attendanceDate = null;

  attendance.forEach((item) => {
    if (item?.date && attendanceDate === null) {
      attendanceDate = item?.date;
    }

    if (item.sessionTopic) {
      const sessionTopic = item?.sessionTopic || null; // { category: "Redux", id: "KwEB0GJW5X51uCkUUEiTF", level: "Overview", title: "Redux in the Ecosystem" }
      const sessionCredits = item?.sessionCredits || null; // [{ number: 2.5, type:""}]
      const category = sessionTopic?.category || ''; // "Redux"

      if (category) {
        if (categories[category] === undefined) {
          categories[category] = 0;
        }

        categories[category] += 1 * totalSessionCredits({ sessionCredits });
      }
    }

    if (Array.isArray(item?.eUnitCodes)) {
      item.eUnitCodes.forEach((eUnitCode) => {
        if (!courseCodes.includes(eUnitCode)) {
          courseCodes.push(eUnitCode);
        }
      });
    }
  });

  return (
    <main role="main" className="col-sm-12 py-3">

      <div className="d-flex d-print-none align-items-center justify-content-between border-bottom pb-3 mb-3">

        <button
          className="btn btn-md btn-outline-primary d-print-none"
          title="Go Back"
          onClick={() => {
            goBack();
          }}
          type="button"
        >
          <i className="fas fa-chevron-left" /> Go Back
        </button>
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
        <h4 className="mt-2">
          {orgName}
        </h4>
        <button
          className="btn btn-md btn-outline-primary d-print-none"
          title="Print"
          onClick={() => {
            window.print();
          }}
          type="button"
        >
          <i className="fas fa-print" /> Print
        </button>
      </div>

      {classCertificate.id && ( // This "id" is the classId, not a certificate "id"
        <div>
          <p>
            {ySimple(attendanceDate)} SEMESTER RECORD OF ATTENDANCE
          </p>
          <div className="my-3">
            <CourseInfo
              title={classCertificate.title}
              eUnitTracking={eUnitTracking || null}
              courseCodes={courseCodes || null}
            />
          </div>
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th
                  scope="col"
                  colSpan={2}
                >
                  Sessions
                </th>
                <th scope="col">
                  Category
                </th>
                <th scope="col">
                  Level
                </th>
                <th
                  scope="col"
                  className="text-center"
                >
                  Credits
                </th>
                <th
                  scope="col"
                  className="text-center"
                >
                  Attendance Date
                </th>
                {/* <th scope="col">Last</th>
                <th scope="col">Handle</th> */}
              </tr>
            </thead>
            <tbody>
              {attendance && attendance.map((attendanceRow, i) => {
                const {
                  sessionCredits,
                  sessionTopic,
                  date
                } = attendanceRow;

                return (
                  <tr key={i}>
                    <td
                      colSpan={2}
                    >
                      {sessionTopic && sessionTopic.title ? sessionTopic.title : '-'}
                      <br />
                    </td>

                    <td>
                      {sessionTopic && sessionTopic.category ? sessionTopic.category : '-'}
                    </td>

                    <td>
                      {sessionTopic && sessionTopic.level ? sessionTopic.level : '-'}
                    </td>

                    <td className="text-center">
                      {sessionCredits && sessionCredits.map(({ number, type }, scIndex) => (
                        <div key={scIndex}>
                          <span>{number}</span>
                          {type && (
                            <span className="ml-2">
                              ({type})
                            </span>
                          )}
                        </div>
                      ))}
                    </td>

                    <td className="text-center">
                      {mdySimple(date)}
                    </td>
                  </tr>
                );
              })}

            </tbody>
          </table>

          <div className="my-3">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div className="mb-2">
                      <b data-demo="disabled">{name}</b> successfully completed the following:
                    </div>
                    <CategoryCredits categories={categories} />
                  </td>
                  <td>
                    {studentOrgId === orgId ? (
                      <SignatureBlock
                        userIds={managerIds}
                      />
                    ) : (
                      <SignatureBlock
                        locationId={locationId}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {completionCertificateDescription && (
            <div className="my-3">
              {completionCertificateDescription}
            </div>
          )}

        </div>
      )}
    </main>
  );
};

const condition = (user) => !!user.uid;

export default withAuthorization(condition)(Certificate);
