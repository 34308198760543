import React from 'react';
import { useSelector } from 'react-redux';
import prettyBytes from 'pretty-bytes';
import { ICON_EXTERNAL_LINK } from '@apprentage/components/dist/constants/icons';
import { isSuperAdmin } from '@apprentage/utils';
import { mdySimple } from '../../../utils/date';
import { TURBINE } from '../../../constants/urls';
import KeyValueItem from '../../KeyValueItem';

const FileMetadata = ({
  className = '',
  size,
  type,
  userName,
  userId,
  createdAt,
  url,
  body
}) => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgType = organization?.type || '';
  // Current User
  const role = currentUser?.role || [];
  // File
  const authorUrl = `${TURBINE}/directory/${userId}`;

  return (
    <div
      className={className}
      data-cy="FileSettings-FileMetadata"
    >
      {body && (
        <div className='mb-3'>
          <div className='font-weight-bold d-block'>
            Description:
          </div>
          <div>
            {body}
          </div>
        </div>
      )}

      {size && (
        <div className='mb-2 d-flex'>
          <div className='font-weight-bold'>
            Size:
          </div>
          <div className='text-nowrap ml-2'>
            {prettyBytes(size)}
          </div>
        </div>
      )}

      <div className='mb-2 d-flex'>
        <div className='font-weight-bold'>
          Type:
        </div>
        <div className='text-nowrap ml-2'>
          {type}
        </div>
      </div>

      {createdAt && (
        <div className='mb-2 d-flex'>
          <div className='font-weight-bold'>
            Created:
          </div>
          <div className='text-nowrap ml-2'>
            {mdySimple(createdAt)}
          </div>
        </div>
      )}

      {userName && userId && (
        <div className='mb-1 d-flex'>
          <div className='font-weight-bold'>
            Author:
          </div>
          <div className='text-nowrap ml-2'>
            {orgType === 'workforce' ? (
              <a
                href={authorUrl}
                target="_blank"
                rel="noopener noreferrer"
                title="View in new tab"
                data-cy="CdnFile-Settings-AuthorLink"
              >
                {userName} <small className="ml-1"><i className={ICON_EXTERNAL_LINK} /></small>
              </a>
            ) : (
              <span data-cy="CdnFile-Settings-AuthorName">
                {userName}
              </span>
            )}
          </div>
        </div>
      )}

      {isSuperAdmin(role) && (
        <KeyValueItem value={url} title="URL" copy size="md" />
      )}
    </div>
  );
};

export default FileMetadata;
