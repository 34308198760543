import {
  RESET_WIDGET_EMBEDS, SET_WIDGET_EMBEDS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  pagination: null
};

export default function widgetLinks(state = initialState, action) {
  switch (action.type) {
    case SET_WIDGET_EMBEDS: {
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    }
    case RESET_WIDGET_EMBEDS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
