import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { resetEmployers } from '../../../../../actions/Employers';
import List from '../../../../EmployersList/List';

const EmployerTable = ({
  employerList = [],
  className = ''
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Apprenticeship
  const apprenticeshipId = apprenticeship?.id || null;
  // Routing
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  const employersRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}/employers`;

  const handleClickRow = (employer) => {
    history.push(`${apprenticeshipRoute}/employer/${employer.id}`);
  };

  useEffect(() => {
    return function cleanup() {
      const { href } = window?.location;

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetEmployers());
      }
    };
  }, [apprenticeshipId, apprenticeshipRoute, dispatch]);

  return (
    <div className={`card-body h-100 shadow border border-1 ${className}`}>
      <div className="d-flex justify-content-between flex-row mb-4">
        <div className="h4 m-0">Program Employers</div>
        <div>
          <button
            type="button"
            className="btn btn-link btn-sm"
            onClick={() => {
              history.push(employersRoute);
            }}
          >
            <span className='d-flex align-items-center'>
              <span className='mr-2'>
                View All
              </span>
              <FaArrowAltCircleRight />
            </span>
          </button>
        </div>
      </div>
      <List
        data={employerList}
        wageSchedulesEnum={apprenticeshipId}
        hiddenColumns={['managerIds']}
        handleClickRow={handleClickRow}
      />
    </div>
  );
};

export default EmployerTable;
