import { RESET_SEARCHES, SET_SEARCHES, SET_SEARCHES_USERS_HASH } from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  pagination: null,
  usersHash: null
};

export default function searches(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCHES: {
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    }
    case SET_SEARCHES_USERS_HASH: {
      return {
        ...state,
        usersHash: action.usersHash || null
      };
    }
    case RESET_SEARCHES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
