const employerAgreementState = {
  accessCode: null, // Radom 6 digit integer
  apprenticeshipId: '',
  employerProfileId: '',
  locationId: '',
  name: '',
  orgId: '',
  signerSignature: '',
  signerIpAddress: '',
  signerInviteSentAt: '',
  signerName: '',
  signerTitle: '',
  wageSchedules: null,
  // sys
  id: '',
  createdAt: '',
  updatedAt: ''
};

export default employerAgreementState;
