import {
  SET_EMPLOYERS, RESET_EMPLOYERS
} from '../../actions/types';
import generateHashFromArray from '../../utils/generateHashFromArray';

const initialState = {
  list: null,
  hash: null,
  fetched: false
};

export default function employers(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYERS:
      return {
        ...state,
        list: action.list,
        hash: generateHashFromArray(action.list),
        fetched: true
      };
    case RESET_EMPLOYERS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
