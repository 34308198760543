import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import LocationImage from './LocationImage';

const OrgLocation = ({
  className = '',
  locationId,
  small = false,
  imageHeight = 20,
  theme = 'light',
  showOnlyImage = false,
  showOnlyName = false
}) => {
  // Redux
  const locations = useSelector((state) => state.locations);
  // Location
  const orgLocation = useMemo(() => {
    const locationsHash = locations?.hash || {};
    return locationsHash[locationId] || {};
  }, [locationId, locations?.hash]);

  const locationImage = orgLocation?.locationImage || null;
  const locationName = orgLocation?.name || null;

  if (!orgLocation?.id) {
    return null;
  }

  if (showOnlyName) {
    return (
      <div className={className}>
        <span
          className={`${small ? 'small' : ''} ${theme === 'dark' ? 'text-white' : 'text-muted'}`}
        >
          {locationName}
        </span>
      </div>
    );
  }

  if (showOnlyImage) {
    return (
      <div className={className}>
        <LocationImage
          src={locationImage}
          alt={locationName}
          height={imageHeight}
          theme={theme}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="m-0 d-flex align-items-center">
        <LocationImage
          src={locationImage}
          alt={locationName}
          height={imageHeight}
          theme={theme}
        />

        <span
          className={`${small ? 'small' : ''} ${theme === 'dark' ? 'text-white' : 'text-muted'}`}
        >
          {locationName}
        </span>
      </div>
    </div>
  );
};

export default OrgLocation;
