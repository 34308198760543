import React from 'react';

const SelectBtnCell = ({
  column,
  row
}) => {
  return (
    <div
      className='d-flex justify-content-end'
    >
      <button
        data-cy="ResourcesList-selectBtn"
        title="Select"
        className="btn btn-sm btn-outline-primary"
        onClick={() => {
          if (typeof column?.onSelect === 'function') {
            column?.onSelect(row?.original);
          }
        }}
        type='button'
      >
        Select
      </button>
    </div>
  );
};

export default SelectBtnCell;
