import React from 'react';

const TimeBadge = ({
  hours,
  className = 'ml-2 h6 m-0',
  badgeType = 'info',
  ...props
}) => {
  return (
    <span
      className={className}
      {...props}
    >
      <span
        className={`badge badge-${badgeType}`}
      >
        {hours}
      </span>
    </span>
  );
};

export default TimeBadge;
