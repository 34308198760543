import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { FaPlus, FaRegObjectGroup, FaUsers } from 'react-icons/fa';
import { resetUserGroups } from '../../../../actions/Groups';
import { withAuthorization } from '../../../Session';
import { ORG_CONSOLE_DASHBOARD, ORG_CONSOLE_USERS_GROUPS } from '../../../../constants/routes';
import { setCurrentModal } from '../../../../actions/Modals';
import withOrgConsole from '../../../App/withOrgConsole';
import Tabs from '../Tabs';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsoleSubheader';
import UserGroupsList from '../../../lists/UserGroupsList';
import DotMenu from './DotMenu';
import { MODAL_KEY_MANAGE_GROUP } from '../../../../constants/modals';

const Groups = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return function cleanup() {
      const { href } = window.location;

      // Only reset if not going to apprentice route
      if (!href.includes(ORG_CONSOLE_USERS_GROUPS)) {
        dispatch(resetUserGroups());
      }
    };
  }, []);

  const createGroup = () => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_MANAGE_GROUP,
      data: {
        type: 'user',
        editMode: false
      }
    }));
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-xl-7 m-auto">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Users"
            iconComponent={() => <FaUsers size={25} className='mr-2' />}
            route={ORG_CONSOLE_DASHBOARD}
          >
            <button
              className='btn btn-sm btn-primary'
              title="Create Group"
              onClick={createGroup}
              type="button"
            >
              <span className='d-flex align-items-center'>
                <FaPlus />
                <span className='font-weight-bold ml-1'>
                  New
                </span>
              </span>
            </button>
          </OrgConsoleHeader>

          <Tabs active="groups" />

          <div
            className='bg-white p-3 border-right border-bottom border-left'
          >
            <OrgConsoleSubheader
              pageTitle="User Groups"
              className='mb-3'
              iconComponent={() => <FaRegObjectGroup className='mr-2' size={15} />}
            >
              <DotMenu />
            </OrgConsoleSubheader>
            <UserGroupsList />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Groups);
