import React from 'react';

const CategoryCredits = ({ categories }) => {
  if (!categories) return null;

  let totalCredits = 0;

  return (
    <div className="course-info">
      {Object.entries(categories).map((categoryCredit, i) => {
        totalCredits += categoryCredit[1];

        return (
          <div
            key={i}
            className="d-flex mb-2"
          >
            <div
              className="border-bottom text-center"
              style={{ minWidth: '50px' }}
            >
              {categoryCredit[1]}
            </div>
            <div>
              Credits in {categoryCredit[0]}
            </div>
          </div>
        );
      })}

      <div className="d-flex">
        <div
          className="border-bottom text-center"
          style={{ minWidth: '50px' }}
        >
          <b>{totalCredits}</b>
        </div>
        <div>
          <b>Total Continuing Education Credits</b>
        </div>
      </div>
    </div>
  );
};

export default CategoryCredits;
