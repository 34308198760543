import { mdySimple } from '../../../utils/date';

const DateCell = ({ value }) => {
  if (value) {
    return mdySimple(value);
  }

  return '-';
};

export default DateCell;
