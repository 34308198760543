import React, { useMemo } from 'react';
import { FaCheckSquare, FaRegCircle, FaRegSquare } from 'react-icons/fa';
import { FaCircleDot } from 'react-icons/fa6';
import { DASHBOARD_MODULE_TYPE } from '../../../../../constants/dashboard';

const SelectBtnCell = ({ column, row }) => {
  const moduleType = row?.original?.moduleType || null;

  const showCheckbox = useMemo(() => {
    return moduleType === DASHBOARD_MODULE_TYPE.links;
  }, [moduleType]);

  const showRadio = useMemo(() => {
    return moduleType === DASHBOARD_MODULE_TYPE.projects || moduleType === DASHBOARD_MODULE_TYPE.embeds;
  }, [moduleType]);

  return (
    <div
      className="d-flex align-items-center mt-1"
    >
      <button
        data-cy="ResourcesList-selectBtn"
        title="Select"
        className={`btn-link ${row.original.selected ? '' : 'text-muted'}`}
        onClick={() => {
          if (typeof column?.onSelect === 'function') {
            column?.onSelect(row?.original, row.index);
          }
        }}
        type="button"
      >
        {row.original.selected ? (
          <>
            {showCheckbox && (
              <FaCheckSquare size={20} />
            )}

            {showRadio && (
              <FaCircleDot size={20} />
            )}
          </>
        ) : (
          <>
            {showCheckbox && (
              <FaRegSquare size={20} />
            )}

            {showRadio && (
              <FaRegCircle size={20} />
            )}
          </>
        )}
      </button>
    </div>
  );
};

export default SelectBtnCell;
