import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ICON_SETTINGS, ICON_TRIANGLE_BANG } from '@apprentage/components/dist/constants/icons';
import { canManageUserRoles } from '../../../services/currentUser';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import { setCurrentModal } from '../../../actions/Modals';
import { routeWithClassId, updateCourse } from '../../../services/courses';
import { setCurrentClass } from '../../../actions/Class';
import { instructorTerminology } from '../../../services/users';
import {
  CLASS_CONSOLE_USERS,
  CLASS_CONSOLE_USER_ROLES
} from '../../../constants/routes';
import { MODAL_KEY_SEARCH_SELECT_USERS } from '../../../constants/modals';
import arraysValuesAreEqual from '../../../utils/arraysValuesAreEqual';
import Users from '../../ManageContent/Users';
import Alert from '../../Alert';
import './style.css';

const UserRoles = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  // Local State
  const initialTeacherIds = course.teachers
    ? course.teachers.map(({ sys }) => sys.id)
    : [];
  const [loading, setLoading] = useState(false);
  // TODO use useState instead of this pattern:
  const [currentState, setState] = useState({
    courseEditorIds: course.courseEditorIds || [],
    courseAdminIds: course.courseAdminIds || [],
    teacherIds: initialTeacherIds
  });
  // Organization
  const orgType = organization?.type || null;
  const instructorTerm = instructorTerminology({ orgType });
  // Course
  const classId = course?.id || null;
  // Current User
  const role = currentUser?.role || [];

  const { teacherIds } = currentState;

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    updateCourse({
      classId,
      teacherIds
    }).then(() => {
      toast.success('User Roles updated!');

      dispatch(setCurrentClass({ classId })).then(() => {
        setLoading(false);
      });
    });
  };

  const handleFieldChange = (newState) => {
    setState({
      ...currentState,
      ...newState
    });
  };

  const manageInstructors = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEARCH_SELECT_USERS,
        data: {
          title: `Manage ${instructorTerm}s`,
          ids: teacherIds,
          searchFilters: {
            excludeRoles: [
              ...(orgType === 'community' ? ['student', 'programTrainer', 'programManager'] : []),
              'superAdmin'
            ]
          }
        },
        callbackPrimaryAction: (newTeacherIds) => {
          handleFieldChange({
            teacherIds: newTeacherIds
          });
        }
      })
    );
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-between mt-3">
        <h5 className="m-0 d-inline pl-3 pb-3">User Roles</h5>
        <ul
          className="nav nav-tabs border-0 position-relative"
          style={{ zIndex: 1 }}
        >
          <li className="nav-item mr-2">
            <Link
              to={routeWithClassId({
                route: CLASS_CONSOLE_USERS,
                classId
              })}
              className="nav-link"
            >
              Users
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={routeWithClassId({
                route: CLASS_CONSOLE_USER_ROLES,
                classId
              })}
              className="nav-link active"
            >
              Roles
            </Link>
          </li>
        </ul>
      </div>
      <div
        className="card"
        style={{ borderTopRightRadius: 0 }}
      >
        <form onSubmit={onSubmit}>
          <div className="card-content">
            <div className="px-3 border-bottom d-flex justify-content-between align-items-center">
              <ul className="nav user-roles-nav">
                <li className="nav-item">
                  <Link
                    to={routeWithClassId({
                      route: CLASS_CONSOLE_USER_ROLES,
                      classId
                    })}
                    className="nav-link active"
                  >
                    {instructorTerm}s
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#">
                    Editor
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Admins
                  </a>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="card-body">
            <Users
              key={teacherIds.join(',')}
              userIds={teacherIds}
            >
              {canManageUserRoles(role) ? (
                <button
                  className="btn btn-sm btn-outline-primary"
                  type="button"
                  title={`Manage ${instructorTerm}s`}
                  onClick={manageInstructors}
                  disabled={!canManageUserRoles(role)}
                >
                  <i className={`${ICON_SETTINGS} mr-1`} />
                  <span>Manage {instructorTerm}s</span>
                </button>
              ) : (
                <Alert
                  type=""
                  icon={`${ICON_TRIANGLE_BANG} text-warning`}
                  className="m-0 w-100 border"
                >
                  <div>
                    <span>
                      Admin or Owner permissions are required to modify
                    </span>
                    <span> {instructorTerm}s</span>.
                  </div>
                </Alert>
              )}
            </Users>
          </div>
          <div className="card-footer bg-white text-muted py-2">
            <i className="fas fa-info-circle mr-1" />
            <span>
              {instructorTerm}s are displayed on the course dashboard and
              receive user submission notifications. Users can message
              instructors.
            </span>
          </div>

          <div className="card-footer p-3 d-flex align-items-center bg-light">
            <button
              className="btn btn-md btn-primary"
              type="submit"
              disabled={
                loading || arraysValuesAreEqual(initialTeacherIds, teacherIds)
              }
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withConsole)(UserRoles);
