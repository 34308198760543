import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  FaArrowsAlt, FaCog, FaEllipsisH, FaTasks
} from 'react-icons/fa';
import { ORG_CONSOLE_COURSES } from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';
import { setCurrentModal } from '../../../../actions/Modals';
import { canAccessCoursesManagement } from '../../../../services/currentUser';
import { MODAL_KEY_MANAGE_GROUP } from '../../../../constants/modals';

const DotMenu = () => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const courseGroup = useSelector((state) => state.courseGroup);
  // Misc
  const orgId = organization?.id || '';
  const role = currentUser?.role || [];
  const itemIds = courseGroup?.itemIds || null;
  const manageCourseGroups = canAccessCoursesManagement(role);

  if (!manageCourseGroups) {
    return null;
  }

  const handleEditGroup = () => {
    dispatch(setCurrentModal({
      key: MODAL_KEY_MANAGE_GROUP,
      data: {
        type: 'course',
        editMode: true,
        group: courseGroup
      }
    }));
  };

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          <Link
            className={`dropdown-item py-2 border-bottom ${!itemIds ? 'disabled' : ''}`}
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_COURSES}/group/${groupId}/reorder`,
              orgId
            })}
            title="Reorder"
          >
            <span className='d-flex align-items-center'>
              <FaArrowsAlt />
              <span className='ml-2'>
                Reorder
              </span>
            </span>
          </Link>
          <Link
            className="dropdown-item py-2 border-bottom"
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_COURSES}/group/${groupId}/manage`,
              orgId
            })}
            title="Manage Courses"
          >
            <span className='d-flex align-items-center'>
              <FaTasks />
              <span className='ml-2'>
                Manage Courses
              </span>
            </span>
          </Link>
          <button
            className="dropdown-item py-2"
            onClick={handleEditGroup}
            title="Settings"
            type="button"
          >
            <span className='d-flex align-items-center'>
              <FaCog />
              <span className='ml-2'>
                Settings
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
