import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { FaArrowLeft } from 'react-icons/fa';
import CircleButton from '@apprentage/components/dist/components/CircleButton';
import { getOccupations } from '../../services/careerOneStop';

const ChooseApprovalOccupationTemplate = ({
  handleBackPress,
  onItemClick,
  occupation
}) => {
  const [listOccupations, setListOccupations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOccupationList = async () => {
    setIsLoading(true);
    try {
      const response = await getOccupations({ keyword: occupation });
      if (response.ErrorCode === 404) {
        console.error(response.Message);
      } else {
        setListOccupations(response.OccupationList);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getOccupationList();
  }, []);

  const OccupationListBlock = () => {
    return listOccupations?.map((item) => (
      <div
        className="d-flex flex-md-row flex-column align-items-center justify-content-between border rounded p-3 mb-4"
        key={item.OnetCode}
      >
        <div className="text-center text-md-left mr-md-5">
          <h5 className="text-dark font-weight-bold">{item.OnetTitle}</h5>
          <p>{item.OccupationDescription}</p>
        </div>
        <button
          type="button"
          className="btn btn-md bg-purple-light text-white px-3"
          onClick={(e) => {
            e.preventDefault();
            onItemClick(item);
          }}
        >
          Choose
        </button>
      </div>
    ));
  };

  const Loader = () => (
    <div className="p-3">
      <Skeleton
        count={4}
        className="mb-3"
        style={{ height: '105px' }}
      />
    </div>
  );

  return (
    <div className="p-3">
      <div className="mt-2 d-flex align-items-center mb-5 ">
        <CircleButton
          className="mr-3"
          onClick={(e) => {
            e.preventDefault();
            handleBackPress();
          }}
        >
          <FaArrowLeft
            size={18}
            className="text-primary"
          />
        </CircleButton>
        <h4 className="font-weight-bold text-dark text-center w-100">
          Choose an approved occupation
        </h4>
      </div>
      <h5 className="text-dark font-weight-bold my-4">Occupations</h5>
      <div style={{ overflowY: 'scroll', height: '400px' }}>
        {isLoading ? (
          <Loader />
        ) : !isLoading && listOccupations?.length === 0 ? (
          <OccupationNotFound
            handleTryAgain={handleBackPress}
            occupationName={occupation}
          />
        ) : (
          <OccupationListBlock />
        )}
      </div>
    </div>
  );
};

export default ChooseApprovalOccupationTemplate;

const OccupationNotFound = ({ handleTryAgain, occupationName }) => {
  return (
    <div className="d-flex p-3 flex-column h-100 align-center justify-content-center text-center">
      <h4>{`No approved occupation's found for: ${occupationName}`}</h4>
      <button
        type="button"
        className="btn btn-link text-decoration-none btn-md"
        onClick={handleTryAgain}
      >
        Try with other occupation name
      </button>
    </div>
  );
};
