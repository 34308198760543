import React from 'react';
import { useSelector } from 'react-redux';
import { canAccessSkills } from '@apprentage/utils';
import { canAccessCourses, canAccessResources } from '../../../services/currentUser';
import { DashboardCards } from '../WorkforceSuite/Dashboard/DashboardCards';
import {
  ORG_CONSOLE_ASSISTANTS, ORG_CONSOLE_COURSES, ORG_CONSOLE_RESOURCES, ORG_CONSOLE_SKILLS
} from '../../../constants/routes';
import {
  IMAGE_CARD_ASSISTANTS, IMAGE_CARD_COURSES, IMAGE_CARD_RESOURCES,
  IMAGE_CARD_SKILLS
} from '../../../constants/assets';
import { canAccessAssistants } from '../../../permissions/access';

const DashboardKnowledgeManagement = ({
  title = 'KnowledgeOps',
  resources = false,
  className = ''
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgType = organization?.type || '';
  const enableResources = organization?.enableResources || false;
  // Current User
  const role = currentUser?.role || [];
  return (
    <div className={`DashboardKnowledgeManagement ${className}`}>
      <div className="pb-2">
        <div className="row">
          <div className='col'>
            <div>
              <div
                className="h3 m-0 font-weight-bold"
              >
                {title}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-3">
        <div className="row">

          {resources && canAccessResources(role, enableResources, orgType) && (
            <div className={`col-12 col-sm-6 ${resources ? 'col-md-4 mb-3' : ''}`}>
              <DashboardCards
                title="Resources"
                onClickRoutePath={ORG_CONSOLE_RESOURCES}
                subTitle="Store team files, training materials, marketing assets and much more."
                imageUrl={IMAGE_CARD_RESOURCES}
              />
            </div>
          )}

          {canAccessCourses(role) && (
            <div className={`col-12 col-sm-6 ${resources ? 'col-md-4 mb-3' : ''}`}>
              <DashboardCards
                title="Courses"
                subTitle="Link an external course or build one using Turbine LMS with certificates & attendance."
                onClickRoutePath={ORG_CONSOLE_COURSES}
                imageUrl={IMAGE_CARD_COURSES}
              />
            </div>
          )}

          {canAccessAssistants(role) && (
            <div className={`col-12 col-sm-6 ${resources ? 'col-md-4 mb-3' : ''}`}>
              <DashboardCards
                title="Assistants"
                subTitle="GenAI assistants powered by Turbine Resources for teams and customers."
                onClickRoutePath={ORG_CONSOLE_ASSISTANTS}
                imageUrl={IMAGE_CARD_ASSISTANTS}
              />
            </div>
          )}

          {!canAccessAssistants(role) && canAccessSkills(role, orgType) && (
            <div className="col-12 col-sm-6 col-md-4 mb-3">
              <DashboardCards
                title="Skills"
                subTitle="Create a catalog of skills that users can learn by taking courses or participating in training programs."
                onClickRoutePath={ORG_CONSOLE_SKILLS}
                imageUrl={IMAGE_CARD_SKILLS}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardKnowledgeManagement;
