import React from 'react';
import { useDispatch } from 'react-redux';
import { FaBell, FaCog, FaEllipsisH } from 'react-icons/fa';
import { setCurrentModal } from '../../../actions/Modals';
import { mdySimple } from '../../../utils/date';
import { MODAL_KEY_MANAGE_NOTIFICATION } from '../../../constants/modals';

const DotMenu = ({ data, listConfig, className = '' }) => {
  const dispatch = useDispatch();

  function manageNotification() {
    dispatch(setCurrentModal({
      key: MODAL_KEY_MANAGE_NOTIFICATION,
      data: {
        listConfig,
        notification: data,
        notificationType: data?.classId ? 'course' : 'organization'
      }
    }));
  }

  return (
    <div className={`btn-group align-items-center ${className}`}>
      <button
        className="btn-sm btn-white border text-muted"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-label='Manage Menu'
        title="Manage Menu"
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap">
          <span className='d-flex align-items-center'>
            <FaBell />
            <span className='ml-2'>
              {mdySimple(data?.date)}
            </span>
          </span>
        </div>

        <button
          type="button"
          className="dropdown-item border-bottom py-2"
          onClick={manageNotification}
        >
          <span className='d-flex align-items-center'>
            <FaCog />
            <span className='ml-2'>
              Settings
            </span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default DotMenu;
