import sysState from './sysState';

const topicState = {
  enableNewEditor: false,
  classId: '',
  featured: false,
  locked: false,
  title: '',
  description: '',
  sections: null, // [{}] references // TODO topic flatten
  instructionNotes: '',
  category: null,
  level: null,
  quizes: null, // TODO topic flatten
  challenges: null, // TODO topic flatten
  prerequisite: null,
  integration: null,
  ...sysState
};

export default topicState;
