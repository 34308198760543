import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import $ from 'jquery';
import { FaCog, FaDownload, FaUsers } from 'react-icons/fa';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import { FaCirclePlus } from 'react-icons/fa6';
import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromArray,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import pluralize from 'pluralize';
import { getUsers } from '../../../actions/Users';
import { setCurrentCohort, resetCurrentCohort } from '../../../actions/Cohorts';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import {
  canExportUserData, canManageCompletionCertificates, canViewTestUsers
} from '../../../services/currentUser';
import { userRolesEnum } from '../../../services/users';
import exportData from './exportData';
import MembersList from '../../MembersList';
import InviteUserButton from '../../btns/InviteUserButton';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import Switch from '../../Switch';
import './style.css';
import Tabs from './Tabs';

const initialListConfig = {
  excludeTestUsers: true,
  excludeInactive: true,
  excludeAcceptedCertificates: false,
  limit: 50,
  roles: [
    'student', 'teacher', 'admin', 'owner', 'programManager', 'programTrainer'
  ]
};

const Users = () => {
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  const currentCohort = useSelector((state) => state.currentCohort);
  const users = useSelector((state) => state.users);
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);
  const [excludeTestUsers, setExcludeTestUsers] = useState(initialListConfig.excludeTestUsers);
  const [excludeInactive, setExcludeInactive] = useState(initialListConfig.excludeInactive);
  const [excludeAcceptedCertificates, setExcludeAcceptedCertificates] = useState(initialListConfig.excludeAcceptedCertificates);
  const [cohortId, setCohortId] = useState('');
  const [userRoles, setUserRoles] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  // local state
  const [manageCertificate, setManageCertificate] = useState(false);
  const [takeAttendance, setTakeAttendance] = useState(false);
  const [hasCertificate, setHasCertificate] = useState(false);
  // Organization
  const orgType = organization?.type || '';
  // Current User
  const role = currentUser?.role || [];

  // User Roles
  const userRolesOptions = reactSelectOptionsFromEnum(userRolesEnum({ orgType }));
  const userRolesDefaultValues = reactSelectDefaultValues(userRoles, userRolesOptions);
  // Course Cohorts
  const courseCohortsOptions = reactSelectOptionsFromArray([
    ...(courseCohorts.list ? [{ id: '', title: 'All Cohorts' }, ...courseCohorts.list] : [])
  ], 'title');
  const courseCohortsDefaultValue = reactSelectDefaultValue(cohortId, courseCohortsOptions);
  // Tools : Certificates
  const showCertificateManageTools = course?.completionCertificate && canManageCompletionCertificates(role);

  const filterList = (key, value) => {
    const config = { ...listConfig, searchValue };

    setListConfig(config);

    if (key === 'excludeTestUsers') {
      setExcludeTestUsers(value);
    }

    if (key === 'excludeInactive') {
      setExcludeInactive(value);
    }

    if (key === 'searchValue') {
      setSearchValue(value);
    }

    if (key === 'hasCertificate') {
      setHasCertificate(value);
    }

    if (key === 'excludeAcceptedCertificates') {
      setExcludeAcceptedCertificates(value);
    }

    if (key === 'cohortId') {
      if (value === '') {
        dispatch(resetCurrentCohort());
      } else {
        dispatch(setCurrentCohort({ cohortId: value }));
      }
      setCohortId(value);
    }

    if (key === 'userRoles') {
      setUserRoles(!value ? initialListConfig.roles : value);
    }

    setListConfig((prev) => ({
      ...prev,
      page: 1, // Prevent limiting query to specific offset of records
      ...(key === 'excludeTestUsers' ? { excludeTestUsers: value } : {}),
      ...(key === 'excludeInactive' ? { excludeInactive: value } : {}),
      ...(key === 'hasCertificate' ? { hasCertificate: value } : {}),
      ...(key === 'excludeAcceptedCertificates' ? { excludeAcceptedCertificates: value } : {}),
      ...(key === 'cohortId' ? { cohortId: value } : {}),
      ...(key === 'searchValue' ? { searchValue: value } : {}),
      ...(key === 'userRoles' ? { roles: !value ? initialListConfig.roles : value } : {})
    }));
  };

  /**
   *
   * @param {*} e // event
   * @param {*} key // options: takeAttendance, manageCertificate
   */
  const toggleTools = (e, key) => {
    const { checked } = e.currentTarget;

    if (key === 'manageCertificate') {
      setManageCertificate(!!checked);
    }

    if (key === 'takeAttendance') {
      setTakeAttendance(!!checked);
    }

    if (!checked) {
      // This is needed to refresh the user list after managing certificates
      // to reduce the number of api calls made when updating users.
      dispatch(getUsers(listConfig));
    }
  };

  useEffect(() => {
    $(document).on('click', '.userFilters-stop-propagation', (e) => {
      e.stopPropagation();
    });
  }, []);

  if (!course?.id) {
    return null;
  }

  return (
    <>
      <div
        className="d-flex align-items-end justify-content-between mt-3"
      >
        <h5 className="m-0 d-inline pl-3 pb-3 d-flex align-items-center">
          <span>
            Users
          </span>
          <InviteUserButton
            className="btn btn-sm btn-primary ml-2"
          >
            <span className='d-flex align-items-center'>
              <FaCirclePlus />
              <span className='font-weight-bold ml-1'>
                New
              </span>
            </span>
          </InviteUserButton>
        </h5>

        <Tabs />
      </div>
      <div
        className="card"
        style={{ borderTopRightRadius: 0 }}
      >
        <div className="card-content">
          <div className="px-3 pt-3 d-flex justify-content-between align-items-center">
            <SearchBox
              className="w-100"
              onSubmit={(value) => {
                filterList('searchValue', value);
              }}
              onClear={() => {
                const searchBox = document.querySelector(
                  'input[name="userName"]'
                );

                searchBox.focus();
              }}
              name="userName"
              value={searchValue}
              // loading={loading}
              placeholder="Search by name..."
              autoFocus
            />
            <div className="d-flex align-items-center">
              {(Array.isArray(courseCohortsOptions) && courseCohortsOptions.length !== 0) && (
                <div>
                  <SelectMenu
                    className="cohort-select mr-2"
                    id="cohortId"
                    name="cohortId"
                    placeholder="Choose Cohort"
                    options={courseCohortsOptions}
                    defaultValue={courseCohortsDefaultValue}
                    onChange={({ value }) => {
                      filterList('cohortId', value);
                    }}
                  />
                </div>
              )}

              <div className="dropdown">
                <button
                  className={`btn btn-sm ${(hasCertificate || excludeTestUsers || excludeInactive) ? 'btn-primary' : 'btn-outline-secondary'} dropdown-toggle mr-2`}
                  type="button"
                  id="userFilters"
                  title="Filters"
                  aria-label='Filters'
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-filter" />
                </button>
                <div
                  className="userFilters-stop-propagation dropdown-menu dropdown-menu-right p-3"
                  aria-labelledby="userFilters"
                >
                  <h6 className='font-weight-bold'>
                    Filters
                  </h6>
                  <div className="mb-2">
                    {canViewTestUsers(role) && (
                      <Switch
                        id="excludeTestUsers"
                        label="Exclude Test Users"
                        className='mb-3'
                        value={excludeTestUsers}
                        onChange={() => {
                          filterList('excludeTestUsers', !excludeTestUsers);
                        }}
                      />
                    )}

                    <Switch
                      id="excludeInactive"
                      label="Exclude Inactive Users"
                      value={excludeInactive}
                      onChange={() => {
                        filterList('excludeInactive', !excludeInactive);
                      }}
                    />

                    <div className='mt-3'>
                      <h6 className='font-weight-bold'>
                        User Roles:
                      </h6>

                      <SelectMenu
                        className="user-roles-select-menu zIndex-1000"
                        id="userRoles"
                        name="userRoles"
                        placeholder="Choose Role"
                        options={userRolesOptions}
                        isMulti
                        defaultValue={userRolesDefaultValues}
                        onChange={(values) => {
                          const newValues = values.length === 0 ? null : values.map((tag) => tag.value);

                          filterList('userRoles', newValues);
                        }}
                      />
                    </div>
                  </div>

                  {course?.completionCertificate && (
                    <div className='mt-3'>
                      <h6 className='font-weight-bold'>
                        Certificates
                      </h6>

                      <Switch
                        id="hasCertificate"
                        className='mb-2'
                        label="Certificate Issued"
                        value={hasCertificate}
                        onChange={() => {
                          filterList('hasCertificate', !hasCertificate);
                        }}
                      />

                      <Switch
                        id="excludeAcceptedCertificates"
                        label="Exclude Accepted Certificates"
                        value={excludeAcceptedCertificates}
                        onChange={() => {
                          filterList('excludeAcceptedCertificates', !excludeAcceptedCertificates);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {(showCertificateManageTools || course?.trackAttendance) && (
                <div className="dropdown">
                  <button
                    className={`btn btn-sm ${(manageCertificate || takeAttendance) ? 'btn-primary' : 'btn-outline-secondary'} dropdown-toggle mr-2 d-flex py-2 align-items-center`}
                    type="button"
                    id="userConfig"
                    title="Settings"
                    aria-label='Settings'
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FaCog />
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right p-3"
                    aria-labelledby="userConfig"
                  >
                    {showCertificateManageTools && (
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="manageCertificates"
                          onClick={(e) => {
                            toggleTools(e, 'manageCertificate');
                          }}
                        />
                        <label
                          className="custom-control-label text-nowrap"
                          htmlFor="manageCertificates"
                        >
                          Manage Certificates
                        </label>
                      </div>
                    )}

                    {course?.trackAttendance && (
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="takeAttendance"
                          onClick={(e) => {
                            toggleTools(e, 'takeAttendance');
                            //
                          }}
                        />
                        <label
                          className="custom-control-label text-nowrap"
                          htmlFor="takeAttendance"
                        >
                          Take Attendance
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {canExportUserData(role, orgType) && (
                <div className="d-none d-sm-flex">
                  <button
                    className="btn btn-sm btn-outline-primary d-flex align-items-center"
                    onClick={() => {
                      exportData({
                        users: users.list,
                        course
                      });
                    }}
                    title="Export Data"
                    type="button"
                    disabled={!users.list}
                  >
                    <FaDownload />
                    <span className='text-nowrap ml-1'>
                      Export
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          {currentCohort?.id && (
            <div
              className='p-3 bg-light border rounded m-3 d-flex justify-content-between'
            >
              <span className='d-flex align-items-center'>
                <FaUsers size={20} />
                <span>
                  <span className='ml-1 font-weight-bold d-none d-sm-flex-inline'>
                    Cohort
                  </span>
                  <span className='ml-2'>
                    {currentCohort?.title}
                  </span>
                </span>
              </span>

              {users?.pagination && (
                <span className='h6 m-0'>
                  <span className='badge bg-white border text-ships-officer'>
                    {pluralize('User', users?.pagination?.total || 0, true)}
                  </span>
                </span>
              )}
            </div>
          )}
          <div className='px-3'>
            <MembersList
              listConfig={listConfig}
              takeAttendance={takeAttendance}
              manageCertificate={manageCertificate}
            />

            {Array.isArray(users.list) && users.list.length > 0 && (
              <PaginationSupabase
                items={users?.list}
                pagination={users?.pagination}
                page={users?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: users?.pagination?.page + 1
                  }));
                  // goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: users?.pagination?.page - 1
                  }));
                  // goToElem('root');
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(Users);
