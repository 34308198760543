import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { locationTerminology } from '../../../../services/locations';
import reactTableSortCaseInsensitive from '../../../../utils/reactTableSortCaseInsensitive';
import Table from './Table';
import NameCell from './NameCell';
import GroupsCell from './GroupsCell';
import LocationNameCell from './LocationNameCell';
import ContactCell from './ContactCell';
import PhoneExtCell from './PhoneExtCell';
import AttendanceCell from './AttendanceCell';
import CertificatesCell from './CertificatesCell';
import CertificateActionsCell from './CertificateActionsCell';
import SkillsCell from '../../../ManageContent/Tables/SkillsCell';

const List = ({
  data,
  hiddenColumns,
  // Course Action Handlers
  takeAttendance,
  manageCertificate,
  // Click User Name Action Handler
  handleOnClickRow
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const userGroups = useSelector((state) => state.userGroups);
  const locations = useSelector((state) => state.locations);
  // Organization
  const orgType = organization?.type || '';
  // Locations
  const locationsHash = useMemo(() => {
    return locations?.hash || {};
  }, [locations?.hash]);

  const usersList = useMemo(() => {
    let list = data || [];

    if (Array.isArray(data) && data.length > 0) {
      list = data.map((user) => {
        const location = user?.locationId ? locationsHash[user?.locationId] : null;

        return {
          ...user,
          locationName: location?.id ? location?.name : user?.locationId,
          // Add departments key to enable sorting on the column
          // TODO make departments clickable like resource tags
          departments: Array.isArray(userGroups.list) ? user.groupIds?.map((groupId) => {
            const userGroup = userGroups.list?.find((g) => g.id === groupId);
            return !userGroup ? '' : userGroup.title;
          }).join(',') : null
        };
      });
      return list;
    }
    return list;
  }, [data, locationsHash, userGroups.list]);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props) => <NameCell {...props} handleOnClickRow={handleOnClickRow} />,
      sortType: reactTableSortCaseInsensitive
    },
    {
      Header: orgType === 'workforce' ? 'Department' : 'Groups',
      accessor: 'departments',
      Cell: GroupsCell
    },
    {
      Header: 'Skills',
      accessor: 'skillIds',
      Cell: SkillsCell
    },
    {
      Header: 'Phone Ext',
      accessor: 'phoneExt',
      Cell: PhoneExtCell
    },
    {
      Header: locationTerminology({ orgType }).text,
      accessor: 'locationName',
      Cell: LocationNameCell
    },
    {
      Header: 'Attendance',
      accessor: 'attendanceCol',
      Cell: (props) => <AttendanceCell {...props} takeAttendance={takeAttendance} />
    },
    {
      Header: 'Certificates',
      accessor: 'certificatesCol',
      Cell: (props) => <CertificatesCell {...props} manageCertificate={manageCertificate} />
    },
    {
      Header: '',
      accessor: 'contactCol',
      disableSortBy: true,
      Cell: ContactCell
    },
    {
      Header: '',
      accessor: 'certificateActionsCol',
      disableSortBy: true,
      Cell: CertificateActionsCell
    }
  ], [orgType, handleOnClickRow, takeAttendance, manageCertificate]);

  return (
    <Table
      columns={columns}
      data={usersList}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
