import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import {
  canAccessUserLogbook,
  canAccessUserLogbookRecords,
  canAccessUserWageSchedule,
  canAccessUserDocs,
  canHaveLogbook
} from '../../../../../permissions/access';
import { fetchLogbookRecords } from '../../../../../services/logbookRecords';

const UserTabs = ({ active, className = 'mt-3' }) => {
  const params = useParams();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || null;
  // Training Program
  const apprenticeshipId = params?.apprenticeshipId || '';
  const apprenticeshipUserId = params?.apprenticeshipUserId || null;
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserRole = apprenticeshipUser?.role || [];
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;
  // Local State
  const [unapprovedRecords, setUnapprovedRecords] = useState(0);

  useEffect(() => {
    if (logbook.fetched && logbook?.id) {
      fetchLogbookRecords({
        orgId,
        logbookId: logbook?.id,
        isApproved: null,
        count: true
      }).then((response) => {
        setUnapprovedRecords(response?.total);
      });
    }
  }, [logbook?.id, logbook.fetched, orgId]);

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs flex-nowrap nav-tabs-scrollable"
      >
        <li className="nav-item">
          <Link
            className={`nav-link ${active === 'info' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}`,
              orgId
            })}
            title="Information"
          >
            Info
          </Link>
        </li>

        {canAccessUserLogbook(role) && canHaveLogbook(apprenticeshipUserRole) && (
          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'logbook' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/logbook`,
                orgId
              })}
              title="Logbook"
            >
              Logbook
            </Link>
          </li>
        )}

        {canAccessUserLogbookRecords(role) && canHaveLogbook(apprenticeshipUserRole) && (
          <li className="nav-item ml-2">
            <Link
              className={`nav-link d-flex align-items-center ${active === 'records' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/records`,
                orgId
              })}
              title="Records"
            >
              <span>Records</span>
              <span className="ml-2 badge badge-warning">
                {unapprovedRecords}
              </span>
            </Link>
          </li>
        )}

        {canAccessUserWageSchedule(role) && (
          <li className="nav-item ml-2 text-nowrap">
            <Link
              className={`nav-link ${active === 'wage-schedule' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/wage-schedule`,
                orgId
              })}
              title="Wage Schedule"
            >
              <span className='d-block d-sm-none'>
                Wages
              </span>
              <span className='d-none d-sm-block'>
                Wage Schedule
              </span>
            </Link>
          </li>
        )}

        {canAccessUserDocs(role) && (
          <li className="nav-item ml-2 text-nowrap">
            <Link
              className={`nav-link ${active === 'docs' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/docs`,
                orgId
              })}
              title="Documents"
            >
              <span className='d-block d-sm-none'>
                Docs
              </span>
              <span className='d-none d-sm-block'>
                Documents
              </span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default UserTabs;
