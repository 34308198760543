import React from 'react';
import { mdySimple } from '../../../../utils/date';

const DateCell = ({ value }) => {
  return (
    <div>
      <div>
        {value ? mdySimple(value) : '-'}
      </div>
    </div>
  );
};

export default DateCell;
