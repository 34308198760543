import { RESET_COHORTS, SET_COHORTS } from '../../actions/types';

const initialState = {
  fetched: false,
  list: null
};

export default function courseCohorts(state = initialState, action) {
  switch (action.type) {
    case SET_COHORTS:
      return {
        ...state,
        fetched: true,
        list: action.courseCohorts
      };
    case RESET_COHORTS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
