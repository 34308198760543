import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ICON_EXTERNAL_LINK, ICON_CHEVRON_RIGHT } from '@apprentage/constants';
import { US_FED_MINIMUM_WAGE } from '../../../constants/wageSchedules';
import { ySimple } from '../../../utils/date';

const StepOne = ({
  updateState,
  addressState = '',
  wageSchedule
}) => {
  // redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // local state
  const [loading, setLoading] = useState(false);
  // misc
  const occupationName = pathOr('', ['occupationName'], apprenticeship);
  const wageScheduleId = pathOr('', ['id'], wageSchedule);
  const [title, setTitle] = useState(wageSchedule?.title || `${occupationName} ${ySimple(new Date())}`);
  const [entryWage, setEntryWage] = useState(wageSchedule?.entryWage);

  const onSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      entryWage: parseFloat(entryWage.toFixed(2)),
      title,
      currentStep: 1
    };

    setLoading(true);

    updateState(dataToSave);
  };

  return (
    <form
      onSubmit={onSubmit}
    >
      <div className="card-body">

        <div className="mb-3">
          <label className="mb-2">
            <b>Title:</b> <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            name="title"
            defaultValue={title}
            autoFocus={!wageScheduleId}
            onChange={(e) => {
              const { value } = e.currentTarget;

              setTitle(value);
            }}
            required
          />
          <div className="text-muted medium mt-1 small">
            Ex: Jr. CNC Machinist {ySimple(new Date())}
          </div>
        </div>

        {/* Appendix E #3 */}
        <div className='mt-3'>
          <label
            htmlFor="entryWage"
            className='font-weight-bold m-0'
          >
            Journeyworker Entry Wage <span className="text-danger">*</span>
          </label>
          <div className='mb-2'>
            This is the wage a trainee will be paid <strong>after</strong> they successfully complete the training program. It can not be less than minimum wage.
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="entryWage">$</span>
            </div>
            <input
              type="number"
              name="entryWage"
              className="form-control"
              value={entryWage}
              step="0.01"
              min={US_FED_MINIMUM_WAGE}
              required
              placeholder=''
              onChange={(e) => {
                const { valueAsNumber } = e.currentTarget;

                setEntryWage(valueAsNumber);
              }}
            />
          </div>
          <div className="small mt-1">
            <span>
              Ex: 16.00
            </span>
            <a
              className='ml-2 text-nowrap'
              href={`https://www.dol.gov/agencies/whd/minimum-wage/state#${addressState.toLocaleLowerCase()}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Lookup Minimum Wage"
            >
              Lookup Minimum Wage <i className={ICON_EXTERNAL_LINK} />
            </a>
          </div>
        </div>
        {/* /Appendix E #3 */}
      </div>

      <div className='card-footer d-flex justify-content-end align-items-center'>
        <div className='mr-2'>
          Wage Rates
        </div>
        <button
          className="btn btn-primary btm-sm"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Next'} <i className={ICON_CHEVRON_RIGHT} />
        </button>
      </div>

    </form>
  );
};

export default StepOne;
