import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { roleReadable, sortArrByArr } from '@apprentage/utils';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { useHistory } from 'react-router-dom';
import { fetchUsers } from '../../../../services/users';

const Supervisors = ({
  className = '',
  userIds,
  route
}) => {
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  // local state
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (Array.isArray(userIds) && userIds.length !== 0) {
      fetchUsers({
        userIds,
        orgId,
        select: ['id', 'name', 'title', 'profilePicture']
      }).then((response) => {
        if (mounted) {
          setSupervisors(response?.items);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [userIds, orgId]);

  if (!Array.isArray(supervisors) || !supervisors.length) {
    return null;
  }

  const whichSupervisors = supervisors ? sortArrByArr(supervisors, userIds, 'id') : null;

  return (
    <div
      id="UserDetails-Supervisors"
      className={className}
    >
      {Array.isArray(whichSupervisors) && whichSupervisors.map((user, i) => (
        <NameBlock
          data-demo="disabled"
          key={`supervisor-user-${user?.id}-${i}`}
          name={user?.name}
          nameSize="sm"
          title={user?.title || roleReadable(user?.role, orgType)}
          profilePicture={user?.profilePicture || DEFAULT_PROFILE_PIC}
          pictureSize="sm"
          className="mb-3"
          {...(route ? {
            onClick: () => {
              history.push(`${route}/${user?.id}`);
            }
          } : {})}

        />
      ))}
    </div>
  );
};

export default Supervisors;
