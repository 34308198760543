import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import Modal from '../Modal';
import Item from './Item';

class Credits extends Component {
  constructor(props) {
    super(props);

    const { data, parent, child } = this.props;

    this.state = {
      // Parent Item
      isNewParent: null,
      parentIndex: null,
      parentModal: false,
      parentDraft: null,
      showDialogConfirmDeleteParentItem: false,
      parentItems: data,
      parent,
      child
    };
  }

  setModalData = ({ key, val }) => {
    this.setState({
      [key]: val
    });
  }

  toggleModal = ({ key, val }) => {
    this.setState({
      [key]: val
    });
  }

  manageParent = (index) => {
    const { parentItems, parentDraft } = this.state;
    let parentIndex = index;
    let tempData = {
      ...parentDraft
    };
    let isNewParent = false;

    if (parentIndex === undefined) {
      // Add New Parent
      parentIndex = (parentItems && parentItems.length) || 0;
      // tempData = { title: '', answers: [] };
      isNewParent = true;
    } else if (parentItems && parentItems[parentIndex]) {
      // Edit Existing Parent
      tempData = {
        ...parentItems[parentIndex]
      };
    }

    this.setState({
      parentDraft: tempData,
      parentIndex,
      parentModal: true, // open parent modal
      isNewParent
    });
  }

  closeParentModal = () => {
    this.setState({
      parentModal: false,
      parentDraft: null,
      isNewParent: null
    });
  }

  confirmDeleteParent = (i) => {
    this.setState({
      parentIndex: i,
      showDialogConfirmDeleteParentItem: true
    });
  }

  deleteParent = () => {
    const { parentIndex, parentItems } = this.state;
    const parentItemsNew = [...parentItems];

    parentItemsNew.splice(parentIndex, 1);

    this.setState({
      parentIndex: null,
      parentItems: parentItemsNew,
      showDialogConfirmDeleteParentItem: false
    }, () => {
      this.props.onUpdate(parentItemsNew); // Save to parent
    });
  }

  saveParent = () => {
    const { parentItems, parentIndex, parentDraft } = this.state;
    const { number } = parentDraft;

    if (!number || number.length === 0) {
      toast.error('Number of credits required');

      return false;
    }

    const parentItemsNew = parentItems ? [...parentItems] : [];

    if (this.state.isNewParent) {
      parentItemsNew.push(parentDraft);
    } else {
      parentItemsNew[parentIndex] = parentDraft;
    }

    this.setState({
      parentItems: parentItemsNew
    }, () => {
      this.props.onUpdate(parentItemsNew); // Save to parent
    });

    this.closeParentModal();
  }

  saveTextField = (e) => {
    const { currentTarget } = e;
    const {
      name,
      type,
      value,
      dataset: {
        temp: tempDataKey
      }
    } = currentTarget;
    const tempData = this.state[tempDataKey];

    this.setState({
      [tempDataKey]: {
        ...tempData,
        [name]: type === 'number' ? parseFloat(value, 10) : value
      }
    });
  }

  render() {
    const {
      parentItems,
      parentDraft,
      parent,
      showDialogConfirmDeleteParentItem
    } = this.state;

    const dialogConfirmDeleteParent = {
      visible: showDialogConfirmDeleteParentItem,
      title: 'Confirmation',
      text: 'Are you sure you want to delete?',
      primaryButton: {
        func: this.deleteParent,
        text: 'Yes'
      },
      secondaryButton: {
        func: () => {
          this.setState({
            showDialogConfirmDeleteParentItem: false
          });
        }
      },
      close: () => {
        this.setState({
          showDialogConfirmDeleteParentItem: false
        });
      }
    };

    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center">
          <label htmlFor="type" className="mb-2">
            <b>{parent.title}:</b> <span className="text-danger">*</span>
          </label>
        </div>

        {parentItems && parentItems.length > 0 && (
          <div className="references rounded border p-1">
            {parentItems.map((parentItem, i) => (
              <Item
                key={i}
                index={i}
                number={parentItem.number}
                type={parentItem.type}
                onClickEdit={this.manageParent}
                onClickDelete={this.confirmDeleteParent}
              />
            ))}
          </div>
        )}

        <div className="references-actions mt-2">
          <button
            className="btn btn-link"
            onClick={this.manageParent.bind(this, undefined)}
            type="button"
          >
            <i className="fas fa-plus mr-1" />
            <span>
              Add Credit
            </span>
          </button>
        </div>

        <Modal
          visible={this.state.parentModal}
          close={this.closeParentModal}
          pos="middle"
        >
          <div className='card'>
            <div className='card-header'>
              Session Credits
            </div>
            <div className='card-body'>
              <div className="mb-3">
                <label htmlFor="creditNumber" className="mb-2">
                  <b>Credits</b> <span className="text-danger">*</span>
                </label>
                <div className="text-muted medium mb-2">
                  Number of credits students can earn per session.
                </div>
                <input
                  id="creditNumber"
                  type="number"
                  className="form-control"
                  name="number"
                  defaultValue={(parentDraft && parentDraft.number) || ''}
                  data-temp="parentDraft"
                  onChange={this.saveTextField}
                  required
                  autoFocus
                />
                <small className="mt-1 text-muted d-flex align-items-center">
                  Ex: 3, 2.5, 0.5
                </small>
              </div>

              <div className="mb-3">
                <label htmlFor="creditType" className="mb-2">
                  <b>Type</b>
                </label>
                <div className="text-muted medium mb-2">
                  Enter the type of credit (optional).
                </div>
                <input
                  id="creditType"
                  type="text"
                  className="form-control"
                  name="type"
                  defaultValue={(parentDraft && parentDraft.type) || ''}
                  data-temp="parentDraft"
                  onChange={this.saveTextField}
                />
                <small className="mt-1 text-muted d-flex align-items-center">
                  Ex: Substantive, Ethics, etc.
                </small>
              </div>
            </div>
            <div className='card-footer'>
              <button
                className="btn btn-md btn-primary"
                onClick={this.saveParent}
                type="button"
              >
                Save
              </button>
              <button
                className="btn btn-link ml-3"
                onClick={this.closeParentModal}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {dialogConfirmDeleteParent.visible && (
          <Modal.Dialog {...dialogConfirmDeleteParent} />
        )}

      </Fragment>
    );
  }
}

export default Credits;
