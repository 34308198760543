import React, { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

const CompetenciesCollapse = ({
  competencyIds = [],
  competencies = {},
  theme = 'dark'
}) => {
  const [showCompetencies, setShowCompetencies] = useState(false);

  if ((!competencyIds) || (Array.isArray(competencyIds) && competencyIds.length === 0)) {
    return null;
  }

  return (
    <div
      className='mt-3 rounded px-2 py-1'
      style={{
        ...(showCompetencies ? {} : {
          backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, .25)' : 'transparent'
        }),
        border: theme === 'dark' ? 0 : '1px solid rgba(210, 210, 210)'
      }}
    >
      <div
        className={`d-flex align-items-center justify-content-between cursor-pointer ${showCompetencies && 'mb-2'}`}
        onClick={() => {
          setShowCompetencies(!showCompetencies);
        }}
        aria-hidden
      >
        <span className='d-flex align-items-center p-1'>
          <span className='small mr-2'>
            Competencies:
          </span>
          <span className={`${theme === 'dark' ? 'theme-dark' : 'theme-light'} d-flex`}>
            <label
              className='badge badge-primary p-1 m-0'
            >
              {competencyIds.length}
            </label>
          </span>
        </span>
        <span
          className='mr-1'
        >
          {showCompetencies ? (
            <FaMinus className={theme === 'dark' && 'text-white'} />
          ) : (
            <FaPlus className={theme === 'dark' && 'text-white'} />
          )}
        </span>
      </div>

      {showCompetencies && (
        <div className={theme}>
          {competencyIds.map((compId, i) => {
            const hasCompDesc = competencies && competencies[compId] !== undefined && competencies[compId]?.description;
            return (
              <div
                key={`${compId}-${i}`}
                className={`px-2 py-1 rounded mr-3 mb-2 small ${theme === 'dark' ? '' : 'alert-primary'}`}
                style={{
                  ...(theme === 'dark' ? { backgroundColor: 'rgba(0, 0, 0, .15)' } : {}),
                  border: '1px rgba(0, 0, 0, .25) solid',
                  maxWidth: '300px',
                  overflow: 'hidden'
                }}
              >
                {hasCompDesc ? competencies[compId].description : compId}
              </div>
            );
          })}
        </div>
      )}

    </div>
  );
};

export default CompetenciesCollapse;
