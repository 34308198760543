import { FLAGSMITH_API_KEY } from '../constants/flagsmith';

export const fetchFlags = () => {
  return new Promise((resolve, reject) => {
    const url = 'https://edge.api.flagsmith.com/api/v1/flags';

    fetch(url, {
      headers: {
        'X-Environment-Key': FLAGSMITH_API_KEY
      }
    })
      .then((response) => response.json())
      .then((flags) => {
        if (Array.isArray(flags)) {
          const flagsHash = flags.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.feature.name]: {
                enabled: curr.enabled,
                value: curr.feature_state_value
              }
            };
          }, {});

          resolve(flagsHash);
        }
        resolve(null);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
