import React from 'react';
import ReactTooltip from 'react-tooltip';
import './style.css';

function height(size) {
  switch (size) {
    case 'sm':
      return '1rem';
    case 'md':
      return '1.25rem';
    case 'lg':
      return '3rem';
    default:
      return '1rem';
  }
}

const ProgressBars = ({
  id = `progressBar-${new Date().toISOString()}`,
  style = {},
  data = [
    // {
    //   title,
    //   valueNow,
    //   valueMin = '',
    //   valueMax = 100,
    //   text,
    //   style = {},
    //   className = ''
    // }
  ],
  className = '',
  size = 'sm'
}) => {
  return (
    <div
      className={`progress progress-size-${size} ${className}`}
      style={{
        height: height(size),
        ...style
      }}
    >
      {data.map((bar, index) => {
        const defaultText = bar?.valueNow === 0 ? '' : `${bar?.valueNow}%`;

        return (
          <div
            key={`id-${index}`}
            title={bar?.title}
            className={`progress-bar progress-size-${size} ${bar?.className}`}
            role="progressbar"
            aria-valuenow={bar?.valueNow}
            aria-valuemin={bar?.valueMin}
            aria-valuemax={bar?.valueMax}
            // data-tooltip-content={bar?.dataTip}
            // data-tooltip-id={id}
            data-tip={bar?.dataTip}
            data-for={id}
            style={{
              width: bar?.valueNow,
              ...bar?.style
            }}
          >
            {bar?.text !== undefined ? bar?.text : defaultText}
          </div>
        );
      })}

      <ReactTooltip id={id} />
    </div>
  );
};

export default ProgressBars;
