import {
  createFirebaseEntry, getFirebaseEntries, getFirebaseEntry
} from '../services/firebaseProxy';
import {
  SET_JOB_PROFILES, RESET_JOB_PROFILES, SET_JOB_PROFILE, RESET_JOB_PROFILE,
  SET_ENTRY_ORG_ID
} from './types';

export const getJobProfiles = ({ orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntries({
        collection: 'jobProfiles',
        orgId
      }).then(({ items }) => {
        const list = items || [];

        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        dispatch({
          type: SET_JOB_PROFILES,
          list
        });
        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getJobProfile = (id, collection = 'jobProfiles') => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntry({ collection, id }).then((response) => {
        const jobProfile = response || null;
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: jobProfile?.orgId || null
        });

        dispatch({ type: SET_JOB_PROFILE, jobProfile });
        resolve(jobProfile);
      }).catch((error) => {
        console.error('get jobProfile', error);
        reject(error);
      });
    });
  };
};

export const createJobProfile = (data) => {
  return (dispatch, getState) => {
    const { jobProfiles } = getState();

    return new Promise((resolve, reject) => {
      createFirebaseEntry({
        data,
        collection: 'jobProfiles'
      }).then(() => {
        const newJobProfiles = [
          data,
          ...(jobProfiles.list || [])
        ];

        dispatch({
          type: SET_JOB_PROFILES,
          list: newJobProfiles
        });
        resolve(newJobProfiles);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetJobProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_JOB_PROFILE });
  };
};

export const resetJobProfiles = () => {
  return (dispatch) => {
    dispatch({ type: RESET_JOB_PROFILES });
  };
};
