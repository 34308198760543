const reactTableSortCaseInsensitive = (prev, curr, columnId) => { // (prev: Row, curr: Row, columnId: string)
  if (prev.original[columnId]?.toLowerCase() > curr.original[columnId]?.toLowerCase()) {
    return 1;
  } if (prev.original[columnId]?.toLowerCase() < curr.original[columnId]?.toLowerCase()) {
    return -1;
  }
  return 0;
};

export default reactTableSortCaseInsensitive;
