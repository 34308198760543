const currentUserState = {
  isApprentice: false,
  userProfileId: '',
  orgId: '',
  courseIds: null, // string[]
  completedCourseIds: null, // string[]
  completedCourseTopicIds: null, // string[]
  locationId: '',
  cohortIds: null, // string[]
  externalRegisteredCourseIds: null, // string[]
  educationUnitIds: null, // string[]
  testUser: false,
  uid: '',
  name: '',
  // PROFILE // TODO move to Firebase
  title: '',
  headline: '',
  phone: '',
  phoneExt: '',
  mobilePhone: '',
  aboutMe: '',
  suffix: '',
  currentPositionDate: '', // TODO is this being used?
  credentials: '', // TODO is this being used?
  // /PROFILE
  email: '',
  slackUserId: '',
  role: null, // string[]
  membership: null, // string[]
  attendance: null, // {}
  updatePaymentMethod: null, // TODO this can be removed
  onboarded: null, // boolean
  profilePicture: '',
  integration: null,
  uniqueCertificateIds: null, // string[]
  agreementId: '',
  userSignature: '',
  calendarLink: '',
  groupIds: null, // string[]
  acceptedCertificateCourseIds: null, // string[]
  supervisorIds: null, // string[]
  externalUserId: '',
  apprenticeshipIds: null, // string[]
  firstName: '',
  lastName: '',
  middleName: '',
  linkedInUrl: '',
  userRoles: null,
  skillIds: null,
  createdAt: '',
  updatedAt: '',
  id: ''
};

export default currentUserState;
