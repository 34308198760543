const ButtonPrimary = ({
  text,
  href,
  target = '_blank'
}) => `
  <!--[if mso]><a:roundrect xmlns:a="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${href}" style="height:42px;width:121px;v-text-anchor:middle;" arcsize="10%" stroke="false" fillcolor="#8e44ad"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff!important; font-family:Arial, sans-serif; font-size:16px"><![endif]-->
  <a
    href="${href}"
    style="text-decoration:none;display:inline-block;color:#ffffff!important;background-color:#8e44ad;border-radius:4px;width:auto;border-top:1px solid #8e44ad;border-right:1px solid #8e44ad;border-bottom:1px solid #8e44ad;border-left:1px solid #8e44ad;padding-top:5px;padding-bottom:5px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;text-align:center;mso-border-alt:none;word-break:keep-all;"
    target="${target}"
  >
    <span
      style="padding-left:20px;padding-right:20px;font-size:16px;display:inline-block;letter-spacing:normal;"
    >
      <span
        style="font-size: 16px; line-height: 2; word-break: break-word; mso-line-height-alt: 32px;"
      >
        ${text}
      </span>
    </span>
  </a>
  <!--[if mso]></center></v:textbox></a:roundrect><![endif]-->
`;

export default ButtonPrimary;
