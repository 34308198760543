import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { FaBell } from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import { SLASH } from '../../../constants/routes';
import { canPostOrgNotifications } from '../../../services/currentUser';
import { goToElem } from '../../../services/manageContent';
import { NOTIFICATIONS_PAGINATION_LIMIT } from '../../../constants/api';
import withOrgConsole from '../../App/withOrgConsole';
import NotificationsList from '../../lists/NotificationsList';
import PostNotification from '../../btns/PostNotification';
import OrgConsoleHeader from '../OrgConsoleHeader';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import NotificationsTabs from '../../tabs/NotificationsTabs';

const Notifications = () => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const notificationsOrg = useSelector((state) => state.notificationsOrg);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Pagination
  const initialListConfig = {
    orgId,
    classId: null,
    isDraft: null,
    page: 1,
    limit: NOTIFICATIONS_PAGINATION_LIMIT
  };
  // Local State
  const [listConfig, setListConfig] = useState(initialListConfig);

  return (
    <div className="row">
      <div className="col-sm-12">

        <OrgConsoleHeader
          pageTitle="Notifications"
          iconComponent={() => <FaBell />}
          route={SLASH}
        >
          {canPostOrgNotifications(role) && (
            <PostNotification
              notificationType='organization'
              listConfig={listConfig}
            />
          )}
        </OrgConsoleHeader>

        <NotificationsTabs
          listConfig={listConfig}
          setListConfig={setListConfig}
          className='mt-3'
        />

        <div className="bg-white border-left border-right border-bottom rounded">

          <div className="py-4 px-3">
            <NotificationsList
              listConfig={listConfig}
              accessControls={false}
              showUserRoles
              showMenu
            />

            {Array.isArray(notificationsOrg.list) && notificationsOrg.list.length > 0 && (
              <PaginationSupabase
                items={notificationsOrg?.list}
                pagination={notificationsOrg?.pagination}
                page={notificationsOrg?.pagination?.page}
                onClickNext={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: notificationsOrg?.pagination?.page + 1
                  }));
                  goToElem('root');
                }}
                onClickPrev={() => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: notificationsOrg?.pagination?.page - 1
                  }));
                  goToElem('root');
                }}
              />
            )}
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Notifications);
