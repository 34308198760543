import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_MAINTENANCE } from '@apprentage/constants';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';

const FeatureFlagNotice = () => {
  const dispatch = useDispatch();
  // redux
  const currentModal = useSelector((state) => state.currentModal);
  const modalData = currentModal?.data || {};
  const modalTitle = modalData?.modalTitle || 'Feature Flag';
  const modalBody = modalData?.modalBody || 'This feature is currently unavailable.';
  const preventClose = modalData?.preventClose || false;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={preventClose ? false : handleClose}
      theme="dark"
      pos="middle"
    >
      <div className="card">
        <div className="card-header p-3 bg-dark text-white">
          <h6 className="m-0 font-weight-bold">
            {modalTitle}
          </h6>
        </div>
        <div className="p-3 d-flex flex-column justify-content-center">
          <p>
            {modalBody}
          </p>
          <img
            src={IMAGE_MAINTENANCE}
            alt='Maintenance'
            className='w-100'
          />
        </div>
      </div>
    </Modal>
  );
};

export default FeatureFlagNotice;
