import React, { useEffect } from 'react';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withAuthorization } from '../../../Session';
import { getPathway, resetPathway } from '../../../../actions/Pathways';
import withOrgConsole from '../../../App/withOrgConsole';
import Form from './Form';
import Loading from '../../../Loading';

const PathwayPublish = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const pathway = useSelector((state) => state.pathway);
  // misc
  const pathwayId = pathOr(null, ['pathwayId'], params);

  useEffect(() => {
    if (pathwayId || (pathwayId && !pathway.id)) {
      dispatch(getPathway(pathwayId));
    }

    return function cleanup() {
      const href = pathOr('', ['location', 'href'], window);
      const pathwayRoute = `org/pathways/${pathwayId}`;
      // Only reset pathway if not going to other pathway management pages
      if (!href.includes(pathwayRoute)) {
        dispatch(resetPathway());
      }
    };
  }, []);

  if (!pathway.id) {
    return (
      <Loading text="Loading Pathway..." />
    );
  }

  return (
    <Form />
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(PathwayPublish);
