import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table'; // useFilters
import { pathOr } from 'ramda';
import { useHistory } from 'react-router-dom';
import { CLASS_CONSOLE_QUIZZES } from '../../constants/routes';
import { routeWithClassId } from '../../services/courses';

function Table({
  columns,
  data,
  classId
}) {
  const history = useHistory();
  // misc
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  );

  const onClickRow = (row) => {
    const { original } = row;
    const quizId = pathOr(null, ['id'], original);
    const route = routeWithClassId({
      route: `${CLASS_CONSOLE_QUIZZES}/${quizId}`,
      classId
    });

    history.push(route);
  };

  return (
    <table
      className='table table-border-top-0 m-0'
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                style={{ verticalAlign: 'center' }}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                {column.canSort && (
                  <span className='ml-1'>
                    {column.isSorted ? (
                      <Fragment>
                        {column.isSortedDesc ? (
                          <i className='fas fa-sort-down' />
                        ) : (
                          <i className='fas fa-sort-up' />
                        )}
                      </Fragment>
                    ) : (
                      <i className='fas fa-sort' style={{ opacity: '.25' }} />
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={() => {
                onClickRow(row);
              }}
              className="row-clickable"
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape())
};
