import { RESET_CURRENT_QUIZ, SET_CURRENT_QUIZ, SET_QUIZ_FILES } from '../../actions/types';
import quizState from '../initialState/quizState';

const initialState = {
  ...quizState,
  cdnFiles: null,
  fetched: false,
  rubric: null
};

export default function currentQuiz(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_QUIZ:
      return {
        ...state,
        ...action.currentQuiz,
        cdnFiles: action.cdnFiles,
        fetched: true
      };
    case SET_QUIZ_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles
      };
    case RESET_CURRENT_QUIZ:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
