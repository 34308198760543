import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaExclamationTriangle } from 'react-icons/fa';
import Button from '@apprentage/components/dist/components/Button';

const ConfirmationToast = ({
  onConfirm,
  title = 'Are you sure?',
  subtitle = '',
  primaryActionBtnText = 'Ok',
  toastId = 'ConfirmToastId',
  toastType = 'info',
  iconComponent,
  handleCancel,
  handleClose,
  autoClose = false
}) => {
  useEffect(() => {
    toast.dismiss();
    toast(
      <div className='px-4'>
        <span className="d-flex justify-content-center py-3 ">
          {iconComponent || <FaExclamationTriangle className={toastType === 'danger' ? 'text-danger' : 'text-warning'} style={{ strokeWidth: '1.5' }} size={40} />}
        </span>
        <p className='text-dark'>
          {title}
        </p>
        {subtitle && (
          <div className='text-dark mb-3'>
            {subtitle}
          </div>
        )}
        <div className='d-flex justify-content-end py-3'>
          <Button
            className="btn btn-sm btn-outline-secondary mr-2"
            type="button"
            variant="white"
            onClick={() => {
              toast.dismiss();
              handleCancel(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className={`btn btn-sm ${toastType === 'danger' ? 'btn-outline-danger' : 'btn-outline-primary'} text-nowrap`}
            variant='primary'
            type="button"
            onClick={() => {
              toast.dismiss(toastId);
              onConfirm();
            }}
          >
            {primaryActionBtnText}
          </Button>

        </div>
      </div>,
      {
        toastId,
        position: 'top-center',
        role: 'confirms',
        icon: true,
        autoClose,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        closeButton: true,
        onClose: handleClose
      }
    );
  }, []);

  return null;
};

export default ConfirmationToast;
