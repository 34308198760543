import React, { useEffect, useState } from 'react';
import { ICON_CHECK_CIRCLE, ICON_TRIANGLE_BANG } from '@apprentage/components/dist/constants/icons';
import { fetchUserProfile } from '../../../services/userProfiles';

const WageScheduleCell = ({
  apprenticeshipId,
  row
}) => {
  const userProfileId = row?.original?.userProfileId || null;
  const [hasWageSchedule, setHasWageSchedule] = useState(false);

  useEffect(() => {
    if (userProfileId) {
      fetchUserProfile(userProfileId).then((userProfile) => {
        if (userProfile?.wageSchedules) {
          const wageSchedulesArr = Object.keys(userProfile.wageSchedules);

          if (wageSchedulesArr.length && userProfile.wageSchedules[apprenticeshipId] !== undefined) {
            setHasWageSchedule(true);
          }
        }
      });
    }
  }, [apprenticeshipId, userProfileId]);

  if (hasWageSchedule) {
    return (
      <div className="d-flex align-items-center text-nowrap">
        <i className={`${ICON_CHECK_CIRCLE} text-success mr-1`} /> Completed
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center text-nowrap">
      <i className={`${ICON_TRIANGLE_BANG} text-danger mr-1`} /> Incomplete
    </div>
  );
};

export default WageScheduleCell;
