import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { ICON_PATHWAY } from '@apprentage/constants';
import { getGroups, resetCourseGroups } from '../../../actions/Groups';
import { resetCourses, getCourses } from '../../../actions/Courses';
import { getOrg } from '../../../actions/Organizations';
import { getJobProfiles } from '../../../actions/JobProfiles';
import { withAuthorization } from '../../Session';
import { ORG_CONSOLE_WORKFORCE } from '../../../constants/routes';
import withOrgConsole from '../../App/withOrgConsole';
import Loading from '../../Loading';
import PathwaysList from './PathwaysList';
import AddPathwayButton from '../../btns/AddPathwayButton';
import OrgConsoleHeader from '../OrgConsoleHeader';

const Pathways = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';

  useEffect(() => {
    dispatch(getOrg({ orgId }));
    dispatch(getCourses({ orgId }));
    dispatch(getGroups({ orgId, type: 'course' }));
    dispatch(getJobProfiles({ orgId }));

    return function cleanup() {
      dispatch(resetCourses());
      dispatch(resetCourseGroups());
    };
  }, [dispatch, orgId]);

  if (!currentUser?.id || !organization?.id) {
    return (
      <Loading />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle='Pathways'
            route={ORG_CONSOLE_WORKFORCE}
            icon={ICON_PATHWAY}
          >
            <AddPathwayButton />
          </OrgConsoleHeader>
          <div
            className="bg-white shadow border mt-3 rounded p-3"
          >
            <PathwaysList />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Pathways);
