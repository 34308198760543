import React from 'react';

const Blackboard = () => {
  return (
    <div className="position-relative">
      <div className='mb-3'>
        <img
          alt="Blackboard"
          src="https://turbine-content.s3.us-east-2.amazonaws.com/blackboard.jpg"
          width="100"
        />
      </div>
      <div className='mb-3'>
        Coming soon.
      </div>
    </div>
  );
};

export default Blackboard;
