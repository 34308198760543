import React from 'react';

const TitleCell = ({ value: title }) => {
  return ( // row
    <div className="d-flex align-items-center font-weight-bold">
      {title}
    </div>
  );
};

export default TitleCell;
