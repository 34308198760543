import React, { useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { summarizeRawText } from '../../../../../../services/resources';
import RecordsList from '../../../../../lists/RecordsList';

const ReviewRecords = ({ className = 'card mt-3' }) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  // Misc
  const ANALYZING = 'Analyzing records...';
  // Organization
  const orgId = organization?.id || '';
  // Apprenticeship User
  const apprenticeshipUser = apprenticeship?.user || null;
  // Local state
  const [summarizing, setSummarizing] = useState(false);
  const [recordsSummary, setRecordsSummary] = useState('');

  const unapprovedRecords = useMemo(() => {
    const data = {
      list: [],
      transcripts: [],
      competencyIds: [],
      hours: 0
    };

    if (!logbookRecords.list || (Array.isArray(logbookRecords.list) && logbookRecords.list.length === 0)) {
      return data;
    }

    logbookRecords.list.forEach((record) => {
      if (!record.isApproved) {
        data.list.push(record);
        data.transcripts.push(record?.transcript);

        // Competencies
        if (Array.isArray(record.competencyIds)) {
          data.competencyIds = [
            ...data.competencyIds,
            ...record.competencyIds
          ];
        }

        if (record?.hours) {
          data.hours += record?.hours;
        }
      }
    });

    return data;
  }, [logbook?.id]);

  // console.log('transcripts', unapprovedRecords?.transcripts);
  // console.log('list', unapprovedRecords?.list);
  // console.log('hours', unapprovedRecords?.hours);

  useEffect(() => {
    if (!recordsSummary && unapprovedRecords?.transcripts.length) {
      setSummarizing(true);
      summarizeRawText({
        topic: `What were most of the main tasks that ${apprenticeshipUser?.firstName} completed?`,
        rawText: `Tasks ${apprenticeshipUser?.firstName} completed on-the-job ${unapprovedRecords?.transcripts.join(', ')}`,
        orgId,
        maxCharCount: 250
      }).then((response) => {
        setRecordsSummary(response);
        setSummarizing(false);
      });
    }
  }, [apprenticeshipUser?.firstName, orgId, recordsSummary, unapprovedRecords?.transcripts]);

  return (
    <div className={className}>

      <div
        className='bg-light px-3 py-2 border'

      >
        <div
          className='mt-2 mb-2'
          style={{
            fontSize: '1.25rem',
            fontWeight: 'normal',
            lineHeight: '2.5rem'
          }}
        >
          {apprenticeshipUser?.firstName} accrued <span className='alert-warning font-weight-bold p-1 small'>{pluralize('hour', parseFloat(unapprovedRecords.hours).toFixed(2), true)}</span> across <span className='alert-primary font-weight-bold p-1 small'>{pluralize('competency', unapprovedRecords.competencyIds.length, true)}</span> in the following <span className='alert-secondary font-weight-bold p-1 small'>{pluralize('task', unapprovedRecords.list.length, true)}</span>.
        </div>
        {unapprovedRecords.list.length && (
          <div
            className='mb-2'
            style={{
              fontSize: '1.25rem',
              fontWeight: 'normal',
              minHeight: '80px'
            }}
          >
            {summarizing ? ANALYZING : recordsSummary}
          </div>
        )}
      </div>

      {Array.isArray(logbookRecords?.list) && logbookRecords.list.length > 0 && (
        <div
          className="card-body"
          style={{ minHeight: '300px' }}
        >
          <RecordsList
            list={unapprovedRecords.list}
          />
        </div>
      )}

      {!logbookRecords?.list && !logbookRecords.fetched && (
        <div className="p-3">
          <Skeleton
            count={3}
            className="mb-3"
            style={{ height: '55px' }}
          />
        </div>
      )}

      {!logbookRecords?.list && logbookRecords.fetched && (
        <div className="py-5 d-flex justify-content-center flex-column">
          <div className="mt-3 w-100 text-center">No Records.</div>
        </div>
      )}
    </div>
  );
};

export default ReviewRecords;
