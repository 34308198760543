import { utils, writeFile } from 'xlsx';
import sanitize from 'sanitize-filename';
import { percentComplete } from '@apprentage/utils';
import { mdySimple } from '../../../utils/date';

const generateFileName = ({ userName, apprenticeshipTitle }) => {
  let fileName = 'Turbine - ';

  fileName += `Logbook - ${userName} - ${apprenticeshipTitle} ${mdySimple(new Date(), '-')}`;

  fileName = sanitize(fileName);

  return fileName;
};

/* This function creates gap rows */
function createGapRows(ws, nRows) {
  const ref = utils.decode_range(ws['!ref']); // get original range
  ref.e.r += nRows; // add to ending row
  ws['!ref'] = utils.encode_range(ref); // reassign row
}

const exportData = ({
  logbookHours,
  logbookId,
  apprenticeshipId,
  apprenticeshipTitle,
  ojtHours,
  competencies = {},
  orgName,
  locationName,
  userName,
  userId
}) => {
  const fileName = generateFileName({ userName, apprenticeshipTitle });

  const wb = utils.book_new();

  const percentProgramComplete = percentComplete({
    current: logbookHours?.totalHours,
    total: ojtHours
  });

  const competencyHours = logbookHours?.competencyHours ? Object.entries(logbookHours?.competencyHours) : null;

  const logbookInfo = [
    ['Name:', userName],
    ['Organization:', orgName],
    ['Employer:', locationName],
    ['Training Program:', apprenticeshipTitle],
    ['Target OJT Hours', ojtHours]
  ];

  const logbookRecords = [
    ['Total Competencies', Array.isArray(competencyHours) ? competencyHours.length : 0],
    ['Total Hours', logbookHours?.totalHours],
    ['Approved Hours', logbookHours?.approvedHours],
    ['Total Records', logbookHours?.totalRecords]
  ];

  const logbookPercentageComplete = [
    ['Percent Complete', percentProgramComplete]
  ];

  const logbookMetadata = [
    ['User ID', userId],
    ['Apprenticeship ID', apprenticeshipId],
    ['Logbook ID', logbookId]
  ];

  /**
   * Logbook Info
   * Logbook Records
   * Logbook Percentage
   * Logbook Metadata
   */
  // https://docs.sheetjs.com/docs/api/utilities/#array-of-arrays-input
  const wsLogbook = utils.aoa_to_sheet(logbookInfo);

  createGapRows(wsLogbook, 1);
  utils.sheet_add_aoa(wsLogbook, logbookRecords, { origin: -1 });

  createGapRows(wsLogbook, 1);
  utils.sheet_add_aoa(wsLogbook, logbookPercentageComplete, { origin: -1 });

  createGapRows(wsLogbook, 3);
  utils.sheet_add_aoa(wsLogbook, logbookMetadata, { origin: -1 });

  createGapRows(wsLogbook, 2);
  utils.sheet_add_aoa(wsLogbook, [[`Exported: ${mdySimple(new Date(), '/')}`]], { origin: -1 });
  utils.sheet_add_aoa(wsLogbook, [['Powered by Turbine Workforce']], { origin: -1 });

  wsLogbook['!cols'] = [
    { width: 20 },
    { width: 20 }
  ];

  utils.book_append_sheet(wb, wsLogbook, 'Logbook');

  /**
   * Competencies
   */
  let compData = [];

  if (Array.isArray(competencyHours)) {
    competencyHours.forEach(([key, compHours]) => {
      const comp = competencies[key] || {};

      const percentCompetencyComplete = percentComplete({
        current: compHours,
        total: comp.totalHours
      });

      if (comp?.description) {
        compData.push([
          compHours,
          comp.totalHours,
          percentCompetencyComplete,
          comp.description
        ]);
      }
    });
  }

  const sortByCompDescription = (prev, curr) => {
    if (prev[3].toLowerCase() > curr[3].toLowerCase()) {
      return 1;
    } if (prev[3].toLowerCase() < curr[3].toLowerCase()) {
      return -1;
    }
    return 0;
  };

  // Sort
  if (compData.length > 0) {
    compData.sort(sortByCompDescription);

    // Add number column
    // (if added sooner, then number columns will not be chronologically accurate)
    compData = compData.map((item, i) => ([`${i + 1}`, ...item]));
  }

  const userAnswersData = [
    ['', 'Hours Logged', 'Target Hours', 'Percent Complete', 'Competency'],
    ...compData
  ];

  const wsCompetencies = utils.aoa_to_sheet(userAnswersData);

  createGapRows(wsCompetencies, 2);
  utils.sheet_add_aoa(wsCompetencies, [[`Exported: ${mdySimple(new Date(), '/')}`]], { origin: -1 });
  utils.sheet_add_aoa(wsCompetencies, [['Powered by Turbine Workforce']], { origin: -1 });

  wsCompetencies['!cols'] = [
    { width: 5 },
    { width: 15, textAlign: 'center' },
    { width: 15 },
    { width: 20 },
    { width: 300 }
  ];

  utils.book_append_sheet(wb, wsCompetencies, 'Competencies');

  writeFile(wb, `${fileName}.xlsx`);
};

export default exportData;
