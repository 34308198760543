import { SET_CURRENT_USER } from '../../actions/types';
import locationState from '../initialState/locationState';

const initialState = {
  ...locationState,
  fetched: false
};

export default function userLocation(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...action.userLocation,
        fetched: true
      };
    default:
      return state;
  }
}
