import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { IMG_WEATHER_DEFAULT } from '@apprentage/constants';
import { FaSyncAlt } from 'react-icons/fa';
import { getUserWeather } from '../../actions/Weather';
import CurrentWeather from './CurrentWeather';

const TopNavWeather = () => {
  const dispatch = useDispatch();
  // redux
  const userLocation = useSelector((state) => state.userLocation);
  const userWeather = useSelector((state) => state.userWeather);
  // misc
  const addressPostalCode = userLocation?.addressPostalCode || [];
  const userWeatherLocation = userWeather?.location || null;
  const userWeatherCurrent = userWeather?.current || null;

  // console.log('userWeatherLocation', userWeatherLocation);
  // console.log('userWeatherCurrent', userWeatherCurrent);

  useEffect(() => {
    $(document).on('click', '#weatherReloadButton', (e) => {
      e.stopPropagation();
    });
  }, []);

  useEffect(() => {
    if (addressPostalCode.length) {
      dispatch(getUserWeather({ postalCode: addressPostalCode }));
    }
  }, []);

  const refreshCurrentWeather = () => {
    dispatch(getUserWeather({ postalCode: addressPostalCode }));
  };

  return (
    <div className="dropdown">
      <button
        className="topNav-icon-dropdown-btn d-flex border align-items-self rounded pl-2 pt-1 pr-1"
        id="dropdownWeather"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        type="button"
        data-cy="TopNav-OrgWeather-btn"
        onClick={refreshCurrentWeather}
        style={{
          marginRight: '0.75rem',
          height: 'auto'
        }}
      >
        <span className='small font-weight-bold mr-1'>
          {Math.round(userWeatherCurrent?.temp_f || 60)}<sup>&deg;</sup>
        </span>

        <img
          src={userWeatherCurrent?.condition?.icon || IMG_WEATHER_DEFAULT}
          height="28"
          style={{
            height: '28px'
          }}
          alt={userWeatherCurrent?.condition?.text || 'Current weather'}
        />
      </button>

      <div
        className="dropdown-menu dropdown-menu-right p-0 notifications-dropdown"
        aria-labelledby="dropdownWeather"
        style={{
          top: '47px'
        }}
      >
        <div
          className="card-header bg-dark text-white d-flex align-items-center justify-content-between"
        >
          <div className='d-flex align-items-center'>
            {userWeatherCurrent?.condition?.icon && (
              <img
                src={userWeatherCurrent.condition.icon}
                className='mr-1'
                alt={userWeatherCurrent?.condition?.text || 'Current weather'}
                height="28"
                style={{
                  height: '28px'
                }}
              />
            )}
            <div className="m-0 h6 font-weight-bold">
              {userLocation?.addressCity || 'Weather'}
            </div>
          </div>
          <button
            id="weatherReloadButton"
            className="btn btn-sm btn-outline-secondary d-flex align-items-center"
            onClick={refreshCurrentWeather}
            title="Reload Weather"
            aria-label="Reload Weather"
            type="button"
          >
            <FaSyncAlt />
          </button>
        </div>
        <div className="card-body">
          <CurrentWeather
            location={userWeatherLocation}
            current={userWeatherCurrent}
          />
        </div>

        <div className="card-footer text-center text-muted">
          <small>
            Last updated: {userWeatherCurrent?.last_updated}
          </small>
        </div>
      </div>
    </div>
  );
};

export default TopNavWeather;
