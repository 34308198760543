import React, { useMemo } from 'react';
import {
  coerceToResourceName,
  fileType,
  getFileSize
} from '../../../services/resources';

function UploadMultipleFiles(props) {
  const bgColor = useMemo(() => {
    if (props.uploadStatus === 'Not started') {
      return '';
    }

    if (props.uploadProgress > 0 && props.uploadProgress < 100) {
      return `linear-gradient(to right, #c9d8f0 ${props.uploadProgress}%, white 50%)`;
    }

    if (props.uploadProgress === 100) {
      return '#e5f2df';
    }
  }, [props.uploadStatus, props.uploadProgress]);

  const opacityStyle = useMemo(() => {
    if (props.uploadStatus === 'Not started') {
      return '';
    }

    if (props.uploadProgress <= 0) {
      return '.4';
    }

    return '';
  }, [props.uploadStatus, props.uploadProgress]);

  const fileTypeData = useMemo(() => {
    return fileType(props.file?.type);
  }, [props.file]);

  const renderFileUploadStatus = () => {
    if (props.uploadStatus !== 'Not started' && props.uploadProgress === 100) {
      return <i className="fas fa-check-circle text-success fa-lg mt-2 " />;
    }

    if (props.uploadProgress > 0) {
      return <b className="text-primary mt-2">{props.uploadProgress}%</b>;
    }
  };

  return (
    <div
      key={props.file?.lastModifiedDate}
      className="border rounded-lg p-2 mt-3"
      style={{
        background: bgColor,
        opacity: opacityStyle
      }}
    >
      <div className="row">
        <div className="col-1">
          <i className={`${fileTypeData?.icon} fa-2x mt-1`} />
        </div>
        <div className="col-9 pl-4">
          <small className="row">
            {coerceToResourceName({
              str: props.file?.name,
              fixCamelCase: true,
              fixHyphens: true,
              fixUnderScores: true,
              fixPlus: true
            })}
          </small>
          <small className="row">
            {getFileSize(props.file?.size)} | {fileTypeData.name}
          </small>
        </div>
        <div className="col-2 d-flex justify-content-end">
          {renderFileUploadStatus()}
        </div>
      </div>
    </div>
  );
}

export default UploadMultipleFiles;
