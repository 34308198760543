import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import { ICON_CHECK_CIRCLE } from '@apprentage/components/dist/constants/icons';
import { getProducts, getPrice } from '../../../actions/Billing';
import { BILLING_NEW_SUBSCRIPTION } from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';
import { serializeProductsBySubscriptions } from '../../../services/billing';
import SubscriptionRow from '../NewSubscription/SubscriptionRow';
import './style.css';

class TurbineAccess extends Component {
  componentDidMount() {
    this.props.getProducts().catch((error) => console.warn(error.message));
  }

  render() {
    const { billing, organization } = this.props;
    const orgId = pathOr(null, ['id'], organization);
    const products = pathOr(null, ['products'], billing);
    const subscriptions = pathOr(null, ['subscriptions'], billing);

    if (!products) {
      return null;
    }

    const serializedProducts = serializeProductsBySubscriptions({
      subscriptions,
      products
    });

    if (!serializedProducts) {
      return null;
    }

    return (
      <div className="payment-methods-container mt-4">
        <h5>Turbine Workforce Access</h5>
        <div className="card overflow-hidden">
          <div className="card-body bg-light">
            {serializedProducts.map(
              ({
                id, name, images, metadata, purchased
              }, i) => (
                <SubscriptionRow
                  key={i}
                  images={images}
                  name={name}
                  metadata={metadata}
                >
                  {purchased ? (
                    <span className="m-0 py-2 px-3 text-uppercase">
                      Purchased{' '}
                      <i className={`${ICON_CHECK_CIRCLE} text-success ml-1`} />
                    </span>
                  ) : (
                    <div>
                      {metadata.price_description}
                      <Link
                        className="btn btn-sm btn-primary ml-2"
                        to={routeWithOrgId({
                          route: BILLING_NEW_SUBSCRIPTION,
                          urlParams: {
                            orgId,
                            priceId: metadata.price,
                            productId: id
                          }
                        })}
                        title="Get Started"
                      >
                        Get Started
                      </Link>
                    </div>
                  )}
                </SubscriptionRow>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ billing }) => ({ billing });

export default connect(mapStateToProps, { getProducts, getPrice })(
  TurbineAccess
);
