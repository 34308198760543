import React from 'react';
import {
  FaEllipsisH, FaExternalLinkSquareAlt, FaImages,
  FaPencilAlt,
  FaTimes
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ORG_CONSOLE_PROJECT_BASE } from '../../../constants/routes';
import { resetSideSheet } from '../../../actions/SideSheets';

const DotMenu = ({ projectId }) => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-white rounded bg-white py-1 border border-1"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          aria-label="Menu"
          title="Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          <Link
            className="dropdown-item p-2 border-bottom"
            to={`${ORG_CONSOLE_PROJECT_BASE}/${projectId}`}
            onClick={() => {
              dispatch(resetSideSheet());
            }}
          >
            <span className='d-flex align-items-center justify-content-between'>
              <span className='d-flex align-items-center'>
                <FaImages />
                <span className='ml-2 small'>
                  Open in Page
                </span>
              </span>
              <FaExternalLinkSquareAlt size={15} className='opacity-50' />
            </span>
          </Link>
          <Link
            className="dropdown-item border-bottom p-2"
            to={`${ORG_CONSOLE_PROJECT_BASE}/manage/${projectId}`}
            onClick={() => {
              dispatch(resetSideSheet());
            }}
          >
            <span className='d-flex align-items-center'>
              <FaPencilAlt size={15} />
              <span className='ml-2 small'>
                Edit
              </span>
            </span>
          </Link>

          <button
            className="dropdown-item p-2"
            type='button'
            onClick={() => {
              dispatch(resetSideSheet());
            }}
          >
            <span className='d-flex align-items-center'>
              <FaTimes size={15} />
              <span className='ml-2 small'>
                Close
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
