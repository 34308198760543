import {
  RESET_RESOURCE_GROUP, SET_RESOURCE_GROUP, SET_RESOURCE_GROUP_FILES
} from '../../../actions/types';
import resourceGroupState from '../../initialState/resourceGroupState';

const initialState = {
  ...resourceGroupState,
  list: null,
  cdnFiles: null
};

export default function resourceGroup(state = initialState, action) {
  switch (action.type) {
    case SET_RESOURCE_GROUP:
      return {
        ...state,
        ...action.resourceGroup,
        list: action.list || null
        // cdnFiles: action.cdnFiles || initialState.cdnFiles
      };
    case SET_RESOURCE_GROUP_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles || initialState.cdnFiles
      };
    case RESET_RESOURCE_GROUP:
      return {
        ...state,
        ...initialState,
        list: initialState.list,
        cdnFiles: initialState.cdnFiles
      };
    default:
      return state;
  }
}
