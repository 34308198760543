import React from 'react';
import { useSelector } from 'react-redux';

const GroupsCell = ({ row }) => {
  const classId = row?.original?.id || null;
  const courseGroups = useSelector((state) => state.courseGroups);

  return (
    <div className="d-flex align-items-center flex-wrap font-weight-bold">
      {courseGroups.list && courseGroups.list.map(({ id, itemIds, title }) => {
        if (itemIds && itemIds.includes(classId)) {
          return (
            <span
              key={id}
              className='small bg-white border p-1 mr-1 mb-1'
            >
              <span className='text-nowrap'>
                {title}
              </span>
            </span>
          );
        }

        return null;
      })}
    </div>
  );
};

export default GroupsCell;
