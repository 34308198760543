import React from 'react';
import { ICON_INFO_CIRCLE } from '@apprentage/constants';

const FullNameCell = ({
  value: firstName,
  row,
  tooltipId = ''
}) => {
  const email = row?.original?.email || '';
  const lastName = row?.original?.lastName || '';

  return (
    <span
      data-demo="disabled"
      className='d-flex align-items-center'
    >
      <span className='mr-1 text-nowrap'>
        <span>
          {firstName}
        </span>
        <span className='d-none d-sm-inline-block ml-1'>
          {lastName}
        </span>
      </span>
      <span className='d-none d-sm-inline-block ml-1'>
        <i
          className={`${ICON_INFO_CIRCLE} text-muted opacity-50`}
          // data-tooltip-content={email}
          // data-tooltip-id={tooltipId}
          data-tip={email}
          data-for={tooltipId}
        />
      </span>
    </span>
  );
};

export default FullNameCell;
