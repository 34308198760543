import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { compose } from 'redux';
import { ICON_PATHWAY } from '@apprentage/constants';
import { getPathway, resetPathway } from '../../../actions/Pathways';
import { ORG_CONSOLE_PATHWAYS } from '../../../constants/routes';
import { jobProfileIframeUrl } from '../../../services/pathways';
import withOrgConsole from '../../App/withOrgConsole';
import Loading from '../../Loading';
import { withAuthorization } from '../../Session';
import OrgConsoleHeader from '../OrgConsoleHeader';
import NavTabs from './NavTabs';

const JobProfile = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const pathwayId = pathOr(null, ['pathwayId'], params);
  const jobProfileId = pathOr(null, ['jobProfileId'], params);

  useEffect(() => {
    const href = pathOr('', ['location', 'href'], window);
    const pathwayRoute = `org/pathways/${pathwayId}`;

    dispatch(getPathway(pathwayId));

    return function cleanup() {
      // Only reset pathway if not going to other pathway management pages
      if (!href.includes(pathwayRoute)) {
        dispatch(resetPathway());
      }
    };
  }, [pathwayId]);

  if (!pathway.id) {
    return (
      <Loading />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={pathway.title}
            icon={ICON_PATHWAY}
            route={ORG_CONSOLE_PATHWAYS}
          />

          <NavTabs
            activeTab='jobProfile'
          />

          <div
            className="bg-white border-bottom border-left border-right p-3"
          >
            <iframe
              title={pathway.title}
              src={jobProfileIframeUrl({ jobProfileId, orgId, hideHeaderTitle: true })}
              style={{ border: 0, height: 'calc(100vh - 30px)' }}
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(JobProfile);
