import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import { FaDownload, FaListOl } from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import QuizOutcomesList from '../../QuizOutcomesList';
import {
  getQuiz,
  resetCurrentQuiz,
  getQuizOutcomes,
  resetQuizOutcomes,
  setCurrentClassQuizzes,
  resetCurrentClassQuizzes,
  setQuizOutcomesActiveOrgId,
  setQuizOutcomesActiveLocationId
} from '../../../actions/Quizzes';
import { canExportUserData, userOrgIsCourseOrg } from '../../../services/currentUser';
import { routeWithClassId } from '../../../services/courses';
import { CLASS_CONSOLE_QUIZZES } from '../../../constants/routes';
import {
  getSyndicatedCourseOrgs, resetSyndicatedCourseOrgs
} from '../../../actions/Organizations';
import OrganizationSelect from '../../ManageContent/OrganizationSelect';
import LocationSelect from '../../ManageContent/LocationSelect';
import Pagination from '../../Pagination';
import OrgConsoleHeader from '../../OrgConsole/OrgConsoleHeader';
import Loading from '../../Loading';
import OrgConsoleSubheader from '../../OrgConsole/OrgConsoleSubheader';
import Tabs from './Tabs';
import exportData from './exportData';

const Quiz = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  const currentUser = useSelector((state) => state.currentUser);
  const syndicatedCourseOrgs = useSelector((state) => state.syndicatedCourseOrgs);

  // Misc
  const { quizId, userId } = params;
  const { id: classId, orgIds } = course;
  const { id: orgId, name: orgName, type: orgType } = organization;
  const title = pathOr('', ['title'], currentQuiz);
  const role = pathOr([], ['role'], currentUser);
  // const paginationTotal = pathOr(0, ['total'], quizOutcomes.pagination);
  const paginationSkip = pathOr(0, ['skip'], quizOutcomes.pagination);
  const activeOrgId = pathOr('', ['activeOrgId'], quizOutcomes);
  const activeLocationId = pathOr(undefined, ['activeLocationId'], quizOutcomes);
  // Local
  const [skip, setSkip] = useState(paginationSkip || 0);
  const [limit] = useState(50); // setLimit
  const [organizationId, setOrganizationId] = useState(activeOrgId || orgId);
  const [locationId, setLocationId] = useState(activeLocationId);

  // useEffect(() => {
  //   $(document).on('click', '.userFilters-stop-propagation', (e) => {
  //     e.stopPropagation();
  //   });
  // }, []);

  useEffect(() => {
    if (quizId) {
      dispatch(getQuiz({ quizId }));
    }

    if (classId) {
      dispatch(setCurrentClassQuizzes({ classId }));
    }

    return function cleanup() {
      dispatch(resetCurrentQuiz());
      dispatch(resetCurrentClassQuizzes());
    };
  }, [quizId]);

  useEffect(() => {
    dispatch(getQuizOutcomes({
      limit,
      skip,
      ...(classId ? { classId } : {}),
      ...(quizId ? { quizId } : {}),
      ...(locationId ? { locationId } : {}),
      orgId: organizationId
    }));

    return function cleanup() {
      dispatch(resetQuizOutcomes());
    };
  }, [limit, skip, organizationId, locationId, quizId]);

  useEffect(() => {
    if (orgIds) {
      dispatch(getSyndicatedCourseOrgs({ orgId, orgIds }));
    }

    return function cleanup() {
      dispatch(resetSyndicatedCourseOrgs());
    };
  }, [orgId, orgIds]);

  const handleClickPage = (page) => {
    const currentPageIndex = parseInt(page, 10);

    if (currentPageIndex !== skip) {
      setSkip(currentPageIndex * limit);
    }
  };

  if (!currentQuiz.fetched && !currentQuiz.id) {
    return (
      <Loading text="Loading Quiz..." />
    );
  }

  return (
    <>
      <OrgConsoleHeader
        pageTitle={title}
        iconComponent={() => <FaListOl size={20} />}
        className="my-3"
        rawRoute={routeWithClassId({
          route: CLASS_CONSOLE_QUIZZES,
          classId
        })}
      />

      <Tabs
        active='outcomes'
      />

      <div className="bg-white border-left border-right border-bottom shadow mb-3 p-3">
        <OrgConsoleSubheader
          className='mb-3'
        >
          {syndicatedCourseOrgs.list && course.id && userOrgIsCourseOrg({ course, currentUser }) && (
            <OrganizationSelect
              className="mr-0 mr-sm-2 flex-fill"
              defaultValue={organizationId}
              data={[
                { name: orgName, id: orgId },
                ...syndicatedCourseOrgs.list
              ]}
              onChange={({ selectedOrgId }) => {
                setSkip(0);
                setOrganizationId(selectedOrgId);
                setLocationId(null);
                dispatch(setQuizOutcomesActiveOrgId(selectedOrgId, null));
              }}
            />
          )}

          {/* When locations include multiple orgs, follow syndicatedCourseOrgs aggregated patterns */}
          {locations.list && Array.isArray(locations.list) && locations.list.length !== 0 && organizationId === orgId && course.id && (
            <LocationSelect
              className="mr-0 mr-sm-2 flex-fill"
              defaultValue={locationId}
              onChange={({ selectedLocationId }) => {
                setSkip(0);
                setLocationId(selectedLocationId);

                if (selectedLocationId === null) {
                  dispatch(setQuizOutcomesActiveOrgId(activeOrgId, selectedLocationId));
                } else {
                  dispatch(setQuizOutcomesActiveLocationId(selectedLocationId));
                }
              }}
              data={locations.list}
            />
          )}

          <button
            className="btn btn-sm btn-outline-primary text-nowrap"
            onClick={() => {
              exportData({
                orgName,
                course,
                currentQuiz,
                quizOutcomes,
                syndicatedCourseOrgs,
                syndicatedCourseOrgId: organizationId
              });
            }}
            title="Export Data"
            type="button"
            disabled={!canExportUserData(role, orgType)}
          >
            <span className='d-flex align-items-center'>
              <FaDownload />
              <span className='ml-2'>
                Export
              </span>
            </span>
          </button>
        </OrgConsoleSubheader>

        <div className="border">
          <QuizOutcomesList
            data={quizOutcomes.list}
            quizId={quizId}
            userId={userId}
            showUserNameAsLink={!userId}
            showQuizTitleAsLink={!!userId}
            showOutcomeButton
          />
        </div>

        {quizOutcomes.list && quizOutcomes.pagination && (
          <Pagination
            items={quizOutcomes.list}
            paginationData={quizOutcomes.pagination}
            handleClickPage={handleClickPage}
          />
        )}

      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(Quiz);
