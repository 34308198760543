import * as twApi from './tw-api';

/**
 * Gets Open Graph Data for a given URL
 * @param {string} url
 * @returns {Promise<object>} - returns object containing any open graph data
 */
export async function fetchOpenGraphData(url) {
  const response = await twApi.get('/v1/external/opengraph', { url });

  return response;
}
