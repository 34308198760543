import React from 'react';

const GroupsCell = ({ value: departments }) => {
  return (
    <div>
      {departments && (
        <div>
          {departments.split(',')?.map((department, index) => (
            <div key={index}>{department}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupsCell;
