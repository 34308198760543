import * as twApi from './tw-api';

/**
 * Gets Account Links
 * https://stripe.com/docs/connect/connect-onboarding#info-to-collect
 * https://stripe.com/docs/api/account_links
 *
 * @returns {Promise<string>} - A URL that allows a company to connect their account to Turbine LMS Stripe Acc.
 */
export async function getAccountLink() {
  const response = await twApi.get('/v1/external/stripe/account_links');

  return response.accountLink;
}

/**
 * Gets Account Links
 * @param {string} customer - stripe customer_id
 * @returns {Promise<string>} - A URL that allows a company to connect their account to Turbine LMS Stripe Acc.
 */
export async function getCustomers(customer) {
  const response = await twApi.get('/v1/external/stripe/customers', {
    customer
  });

  return response.customer;
}

/**
  * Create Customer
  * @param {string} email
  * @param {string} paymentMethod // stripe pm_id
  * @returns {Promise<object>} - A customer obj
  */
export async function createCustomer({
  name,
  email,
  address,
  paymentMethod
}) {
  const data = {
    email,
    paymentMethod
  };

  if (name) data.name = name;
  if (address) data.address = JSON.stringify(address);

  const response = await twApi.post('/v1/external/stripe/customers/create', data);

  return response.customer;
}

/**
  * Create Subscription
  * @param {string} customer // stripe customer_id
  * @param {string} price // stripe price_id
  * @param {integer} quantity // ex, 50
  * @returns {Promise<object>} - A subscription obj
  */
export async function createSubscription({
  customer,
  price,
  quantity,
  coupon
}) {
  const data = {
    customer,
    price,
    quantity
  };

  if (coupon) {
    data.coupon = coupon;
  }

  const response = await twApi.post('/v1/external/stripe/subscriptions/create', data);

  return response.subscription;
}

/**
  * Get Payment Methods
  * @summary lists a customer's payment methods
  * @param {string} customer // stripe customer_id
  * @param {string} type // ex, card
  * @returns {Promise<object>} - A list of payment methods
  */
export async function getPaymentMethods(customer, type = 'card') {
  const response = await twApi.get('/v1/external/stripe/payment_methods', {
    customer,
    type
  });

  return response.paymentMethods;
}

/**
  * Get Subscriptions
  * @summary lists a customer's subscriptions
  * @param {string} customer // stripe customer_id
  * @param {integer} limit // (optional) ex,3
  * @returns {Promise<object>} - A list of subscriptions
  */
export async function getSubscriptions(customer, limit) {
  const query = { customer };

  if (limit) query.limit = limit;

  const response = await twApi.get('/v1/external/stripe/subscriptions/list', query);

  return response.subscriptions;
}

/**
* Get Products
* @summary lists products
* @param {array of strings} ids // [product_id]
* @returns {Promise<object>} - A list of products
*/
export async function getProducts(ids) {
  const query = {};

  if (ids) query.ids = ids;

  const response = await twApi.get('/v1/external/stripe/products/list', query);

  return response.products;
}

/**
* Get Price
* @summary get price obj from id
* @param {string} price // [product_id]
* @returns {Promise<object>} - Price
*/
export async function getPrice(price) {
  const response = await twApi.get('/v1/external/stripe/prices', {
    price
  });

  return response.price;
}

/**
* Get Invoices
* @summary lists invoices
* @param {string} customer // customer id
* @param {string} subscription // subscription id
* @param {integer} limit // limit results by this number
* @returns {Promise<object>} - A list of invoices
*/
export async function getInvoices({ customer, subscription, limit }) {
  const query = {};

  if (customer) query.customer = customer;
  if (subscription) query.subscription = subscription;
  if (limit) query.limit = limit;

  const response = await twApi.get('/v1/external/stripe/invoices/list', query);

  return response.invoices;
}
