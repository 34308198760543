import sysState from './sysState';

const answerState = {
  title: '',
  orgId: '',
  topicId: '',
  challengeId: '',
  challengeTitle: '',
  classId: '',
  userId: '',
  userName: '',
  status: '',
  solution: '',
  feedback: null, // { list: [{ userName: '', userId: '', date: '', body: '' }] }
  ...sysState
};

export default answerState;
