import React from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import VelaOverviewImg from './vela-overview.png';

const VelaOverview = ({
  onClick,
  loading
}) => {
  // Local State
  return (
    <div>
      <div className='d-flex align-items-center mb-3'>
        <FaCloudUploadAlt size={30} />
        <div className='ml-2'>
          <div>
            Users
          </div>
          <h1 className="h6 m-0 font-weight-bold text-purple-light">
            Build a culture of documentation
          </h1>
        </div>
      </div>

      <div className="h4 mt-4 mb-3">
        We created VELA to put the power of Turbine in a simple to use mobile interface.
      </div>
      <div>
        Users don't need to worry about where the knowledge ultimately is
        used, but rather get it out of their head and accessible to everyone.
      </div>

      <div className='my-4 bg-light p-3 rounded'>
        <img
          className='w-100'
          src={VelaOverviewImg}
          alt="Vela Overview"
        />
      </div>

      <p className="font-weight-bold mb-4 text-center">
        Let's begin building your culture of documentation.
      </p>

      <div className='d-flex align-items-center justify-content-center'>
        <button
          type="button"
          disabled={loading}
          className="btn bg-purple-light btn-md text-white"
          onClick={onClick}
        >
          {loading ? 'Loading...' : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default VelaOverview;
