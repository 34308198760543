import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_USERS_READONLY,
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../../../constants/flagsmith';
import { setCurrentModal } from '../../../../../actions/Modals';
import { canManageUsers } from '../../../../../services/currentUser';
import { showFeatureFlagNoticeModal } from '../../../../../actions/FeatureFlags';

const DotMenu = () => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Current User
  const role = currentUser?.role || [];
  // Apprenticeship
  const apprenticeshipUser = apprenticeship?.user || null;
  // Permissions
  const manageUsers = canManageUsers(role);

  if (!manageUsers) {
    return;
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label='Manage Menu'
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />
          <button
            className="dropdown-item p-2"
            type="button"
            onClick={() => {
              if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
                dispatch(showFeatureFlagNoticeModal({
                  modalTitle: NOTICE_USERS_READONLY_TITLE,
                  modalBody: NOTICE_USERS_READONLY_BODY
                  // preventClose: true
                }));
                return;
              }

              dispatch(setCurrentModal({
                key: 'userSettings',
                data: { user: apprenticeshipUser }
              }));
            }}
          >
            <span className='d-flex align-items-center'>
              <FaCog />
              <span className='ml-1'>
                Settings
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
