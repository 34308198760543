import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findIndex, pathOr, propEq } from 'ramda';
import { toast } from 'react-toastify';
import { resetCurrentModal } from '../../../actions/Modals';
import { fetchOrgs } from '../../../services/organizations';
import sortArrByArr from '../../../utils/sortArrByArr';
import sortArrByKey from '../../../utils/sortArrByKey';
import Modal from '../../Modal';
import OrgAuthor from '../../OrgAuthor';

const SearchSelectOrganizations = () => {
  const dispatch = useDispatch();
  const key = 'searchSelectOrganizations';
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  // Organization
  const orgId = pathOr(null, ['id'], organization);
  // Local State
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const [allOrgs, setAllOrgs] = useState([]);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  // Misc
  const title = pathOr('Search Organizations', ['data', 'title'], currentModal);
  const ids = pathOr([], ['data', 'ids'], currentModal);
  const callbackPrimaryAction = pathOr(null, ['callbackPrimaryAction'], currentModal);

  useEffect(() => {
    if (orgId && ids && Array.isArray(ids) && ids.length !== 0) {
      fetchOrgs({
        orgIds: ids,
        orgId
      }).then((newOrgs) => {
        const whichOrgs = newOrgs ? sortArrByArr(newOrgs, ids, 'name') : [];

        setSelectedOrgs(whichOrgs);
      });
    }
  }, [ids, orgId]);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleFocus = (e) => {
    e.preventDefault();

    if (!allOrgs) {
      setAllOrgs([]);
    }
  };

  const handleSearchOrgs = (e) => {
    e.preventDefault();
    const { orgName } = e.currentTarget;

    orgName.blur();

    if (orgName.value === '') {
      toast.info('Please try again');

      return setAllOrgs(null);
    }

    setSearching(true);

    setSearchValue(orgName.value);

    if (orgId) {
      fetchOrgs({
        searchValue: orgName.value,
        orgId
      }).then((newOrgs) => {
        const selectedIds = selectedOrgs.map((o) => o.id);
        const filteredOrgs = newOrgs.filter((nO) => !selectedIds.includes(nO.id));

        setSearching(false);
        setAllOrgs(filteredOrgs);
      });
    }
  };

  const selectOrg = (org) => {
    const newSelectedOrgIds = [
      ...(selectedOrgs || []),
      org
    ];

    const orgIndex = findIndex(propEq('id', org.id))(allOrgs);
    const updatedOrgs = allOrgs.slice();

    updatedOrgs.splice(orgIndex, 1);

    setAllOrgs(updatedOrgs);
    const orgExists = selectedOrgs.find((o) => o.id === org.id);

    if (!orgExists) {
      setSelectedOrgs(newSelectedOrgIds);
    }
  };

  const removeOrg = (org) => {
    const orgIndex = findIndex(propEq('id', org.id))(selectedOrgs);
    const updatedSelectedOrgIds = selectedOrgs.slice();

    updatedSelectedOrgIds.splice(orgIndex, 1);

    setSelectedOrgs(updatedSelectedOrgIds);
  };

  const saveOrgs = () => {
    setLoading(true);
    const selectedIds = selectedOrgs.map((org) => org.id);

    callbackPrimaryAction(selectedIds);
    handleClose();
  };

  if (currentModal && !currentModal.visible) return null;
  if (currentModal.key !== key) return null;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModal.key === key}
      close={false}
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">
            { title }
          </h1>
        </div>
        <div className="card-body">
          {selectedOrgs && selectedOrgs.length !== 0 && (
            <>
              <div className="mt-3 mb-2">
                <div className="h6 font-weight-bold">
                  <i className="fas fa-check text-keppel" /> Selected:
                </div>
              </div>
              {sortArrByKey(selectedOrgs, 'name').map((org) => (
                <div
                  key={org.id}
                  className="border p-2 d-flex align-items-center justify-content-between mb-1 position-relative"
                >
                  <OrgAuthor
                    fetchData={false}
                    orgLogo={org?.orgLogo}
                    orgName={org?.name}
                    orgId={org?.id}
                  />
                  <div className="p-2">
                    <button
                      title="Remove org"
                      className="btn btn-sm btn-outline-secondary"
                      type="button"
                      onClick={() => {
                        removeOrg(org);
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Remove
                      </span>
                      <i
                        className="fas fa-times d-block d-sm-none"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}

          <form
            onSubmit={handleSearchOrgs}
            className="mt-3"
          >
            <div className="input-group input-group-md">
              <input
                type="text"
                className="form-control mr-2"
                name="orgName"
                defaultValue={searchValue}
                placeholder=""
                autoFocus
                onFocus={handleFocus}
              />
              <button
                className="btn btn-primary btn-md"
                type="submit"
                disabled={searching}
              >
                Search
              </button>
            </div>
          </form>

          <div className="text-muted mt-1">
            Search Organizations by Name
          </div>
        </div>
        <div className="card-body pt-0">
          {!allOrgs && (
            <div className="bg-light p-2">
              No results.
            </div>
          )}
          {allOrgs && allOrgs.length !== 0 && (
            <>
              <div className="mb-2">
                <div className="font-weight-bold">
                  Organizations:
                </div>
              </div>
              {allOrgs.map((org) => (
                <div
                  key={org.id}
                  className="border p-2 d-flex align-items-center justify-content-between mb-1"
                >
                  <OrgAuthor
                    fetchData={false}
                    orgLogo={org?.orgLogo}
                    orgName={org?.name}
                    orgId={org?.id}
                  />
                  <div className="p-2">
                    <button
                      title="Select org"
                      className="btn btn-sm btn-outline-primary"
                      type="button"
                      onClick={() => {
                        selectOrg(org);
                      }}
                    >
                      <span className="d-none d-sm-block">
                        Select
                      </span>
                      <i
                        className="fas fa-check d-block d-sm-none"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="card-footer d-flex align-items-center">
          <button
            className="btn btn-primary btn-md mr-2"
            disabled={loading}
            type="button"
            onClick={saveOrgs}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <button
            className="btn btn-link"
            disabled={loading}
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SearchSelectOrganizations;
