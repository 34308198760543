import React from 'react';
import ProjectBlock from '../../ProjectBlock';

const ProjectModule = ({ isConfig, projectIds = [] }) => {
  return (
    <ProjectBlock
      skipUnmount
      showAllProjectsLink={!isConfig}
      showHeader
      projectId={projectIds[0]}
      allowToggleDetails
    />
  );
};

export default ProjectModule;
