const apprenticeshipState = {
  archived: null,
  classifications: null,
  competencies: null,
  competencyGroups: null,
  competencyNameSpace: null,
  courseIds: null,
  createdAt: null,
  employerIds: null,
  estimatedLength: null,
  expectedEndDate: null,
  id: null,
  instructionHours: null,
  instructionProviderIds: null,
  occupation: null,
  // XRWCJQVuhDXvWRW958EE?orgId=7bc6APXSkbx7hNHrVHljjm
  occupationName: null,
  occupationONetCode: null, // TODO List all keys
  occupationONetDescription: null,
  occupationONetTitle: null,
  // 4nDPEfld3VvFe3KCbrK1?orgId=3cGnNZMS3biPwCDMKoNAmu
  occupationTitle: null,
  ojtHours: null,
  orgId: null,
  rapidsCode: null,
  startDate: null,
  title: null,
  type: null,
  updatedAt: null
};

export default apprenticeshipState;
