import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { resetCurrentModal } from '../../../actions/Modals';
import { saveResourceGroupTags } from '../../../actions/Resources';
import Modal from '../../Modal';
import KeyValueItem from '../../KeyValueItem';

const ManageResourceGroupTag = () => {
  const dispatch = useDispatch();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  // Current User
  const userId = pathOr(null, ['id'], currentUser);
  const userName = pathOr(null, ['name'], currentUser);
  // misc
  const orgId = pathOr(null, ['id'], organization);
  const visible = pathOr(null, ['visible'], currentModal);
  const key = pathOr(null, ['key'], currentModal);
  const resourceGroupTagId = pathOr(null, ['data', 'id'], currentModal);
  const currentLabel = pathOr(null, ['data', 'label'], currentModal);
  // local state
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(currentLabel || '');

  if (!visible) return null;

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSuccess = () => {
    toast.success(`${label || 'Tag'} ${resourceGroupTagId ? 'updated' : 'created'}!`);
    handleClose();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const dataToSave = { label };

    setLoading(true);

    dispatch(saveResourceGroupTags({
      data: dataToSave,
      orgId,
      authorUserId: userId,
      authorUserName: userName,
      id: resourceGroupTagId
    })).then(() => {
      handleSuccess();
    });
  };

  if (currentModal.key !== key) return null;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModal.key === key}
      close={false}
      pos="middle"
      theme="dark"
    >
      <div className="card border-0">
        <div className="card-header bg-dark">
          <h5>
            <i className="fas fa-tag mr-1" /> {resourceGroupTagId ? label : 'Resource Tag'}
          </h5>
        </div>
        <form onSubmit={onSubmit}>
          <div className="card-body">
            <div id="group-name" className="mb-4">
              <label htmlFor="label">
                <b>Label:</b> <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="label"
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setLabel(value);
                }}
                defaultValue={label}
                autoFocus
                required
              />
            </div>
          </div>
          <div className="card-footer d-flex justify-content-between">
            <div>
              <button
                type="submit"
                className="btn btn-md btn-primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
              <button
                type="button"
                className="btn btn-link"
                disabled={loading}
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>

            {resourceGroupTagId && (
              <div>
                <KeyValueItem
                  title='ID'
                  value={resourceGroupTagId}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ManageResourceGroupTag;
