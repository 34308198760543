import React from 'react';
import { FaCheck } from 'react-icons/fa';

const AgentImage = ({
  className = '',
  handleClick,
  imgSrc,
  imgName,
  activeImgSrc,
  bkgColor
}) => {
  return (
    <button
      className={`AgentImage ${className}`}
      style={{
        cursor: handleClick ? 'pointer' : 'default',
        ...(bkgColor ? { backgroundColor: bkgColor } : {})
      }}
      type="button"
      {...(handleClick ? { onClick: () => { handleClick(imgSrc); } } : {})}
    >
      <img
        src={imgSrc}
        alt={imgName}
        height="30"
        style={{ height: '30px' }}
      />

      {activeImgSrc !== undefined && activeImgSrc === imgSrc && (
        <div className='AgentImageCheckMark bg-keppel'>
          <FaCheck className='text-white' />
        </div>
      )}
    </button>
  );
};

export default AgentImage;
