import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPayments } from '../../../actions/Billing';
import { formatCurrency } from '../../../services/billing';

const PaymentsLegacy = () => {
  const dispatch = useDispatch();
  // redux
  const billing = useSelector((state) => state.billing);
  // misc
  const payments = pathOr(null, ['payments'], billing);

  useEffect(() => {
    dispatch(getPayments());
  }, []);

  if (!payments || payments.length === 0) {
    return null;
  }

  return (
    <div className="payments-legacy-container mt-4">
      <h5>
        Custom Usage
      </h5>

      <div className="card overflow-hidden">
        <div className="card-content">
          <table className="table table-striped mb-0">
            <thead>
              <tr>
                <th scope="col">Invoice</th>
                <th scope="col">Date</th>
                <th scope="col">Type</th>
                <th scope="col">Amount</th>
                <th scope="col">Class</th>
                <th
                  scope="col"
                  className="text-right"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {payments && payments.map((payment, i) => {
                const title = pathOr('', ['title'], payment);
                const date = pathOr('', ['date'], payment);
                const type = pathOr('', ['type'], payment);
                const amount = pathOr('', ['amount'], payment);
                const invoice = pathOr(null, ['invoice'], payment);
                const receipt = pathOr(null, ['receipt'], payment);
                const paymentCourse = pathOr(null, ['class'], payment); // TODO payment flatten
                const cohort = pathOr(null, ['cohort'], payment); // TODO payment flatten

                return (
                  <tr key={i}>
                    <th scope="row">{title}</th>
                    <td>{date}</td>
                    <td>{type}</td>
                    <td>
                      {formatCurrency(amount)}
                    </td>
                    <td>
                      {paymentCourse && paymentCourse.fields ? (
                        <>
                          <div>
                            {paymentCourse.fields.title}
                          </div>

                          {cohort && cohort.fields && (
                            <div>
                              {cohort.fields.title}
                            </div>
                          )}
                        </>
                      ) : 'n/a'}
                    </td>
                    <td>
                      {invoice && (
                        <a className="btn btn-sm btn-primary" href={invoice} target="_blank" rel="noopener noreferrer">
                          <i className="fas fa-file-invoice" /> Invoice
                        </a>
                      )}
                      {receipt && (
                        <a className="btn btn-sm btn-outline-primary" href={receipt} target="_blank" rel="noopener noreferrer">
                          <i className="fas fa-file-invoice" /> Receipt
                        </a>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentsLegacy;
