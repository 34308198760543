import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaUsers } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { getGroups, resetUserGroups } from '../../../actions/Groups';
import { handleOpenUserSideSheet } from '../../../actions/SideSheets';
import DataNotAvailable from '../DataNotAvailable';
import List from './List';

const UsersList = ({
  route,
  data,
  header = false,
  title = 'Users',
  className = 'mt-3 mb-5',
  theme = 'light',
  showSkillsCol,
  showGroupsCol,
  showContactCol,
  // Course Cell Action Handlers
  takeAttendance,
  manageCertificate
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const sideSheet = useSelector((state) => state.sideSheet);
  const course = useSelector((state) => state.course);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || '';
  const enableUserGroups = organization?.enableUserGroups || null;
  // Directory User
  const directoryUserId = params?.directoryUserId || '';

  useEffect(() => {
    if (directoryUserId) {
      dispatch(handleOpenUserSideSheet({
        userId: directoryUserId,
        orgId,
        currentUserOrgId: currentUser?.orgId,
        ...(route ? { route, history } : {})
      }));
    }
    // else if (sideSheet?.key === SHEET_KEY_USER_DETAILS) {
    //   dispatch(resetSideSheet());
    // }
  }, [currentUser?.orgId, directoryUserId, dispatch, history, orgId, route, sideSheet?.key]);

  useEffect(() => {
    dispatch(getGroups({ orgId, type: 'user' }));
  }, [dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetUserGroups());
    };
  }, [dispatch]);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!showContactCol) {
      cols.push('contactCol');
    }

    if (!showSkillsCol) {
      cols.push('skillIds');
    }

    // Organization Columns
    if (orgType === 'community') {
      cols.push('phoneExt');
    }

    if (!showGroupsCol || !enableUserGroups) {
      cols.push('departments');
    }

    // Course Columns
    if (!course?.id || (course?.id && !course?.trackAttendance)) {
      cols.push('attendanceCol');
    }

    if (!course?.id || (course?.id && !course?.completionCertificate)) {
      cols.push('certificatesCol');
    }

    if (!course?.id || (course?.id && !manageCertificate)) {
      cols.push('certificateActionsCol');
    }

    return cols;
  }, [
    showSkillsCol,
    showContactCol,
    orgType,
    showGroupsCol,
    enableUserGroups,
    course?.id,
    course?.trackAttendance,
    course?.completionCertificate,
    manageCertificate
  ]);

  if (!data) {
    return;
  }

  if (Array.isArray(data) && data.length === 0) {
    return (
      <DataNotAvailable
        text="No users found"
        iconComponent={() => <FaUsers size={30} />}
      />
    );
  }

  return (
    <div className={`users-list ${className}`}>
      <div className="row">
        <div className="col">
          <div className="card mb-1 border-bottom h-100">
            {header && (
              <div
                className={`card-header d-flex align-items-center ${theme === 'dark' ? 'bg-dark text-white' : 'bg-light'}`}
              >
                <FaUsers size={25} />
                <span className='h5 ml-2 mb-0'>
                  {title}
                </span>

              </div>
            )}

            <div
              className="card-body overflow-x-hidden p-0"
              style={{
                minHeight: '380px'
              }}
            >
              <List
                data={data}
                hiddenColumns={hiddenColumns}
                // Course Action Handlers
                manageCertificate={manageCertificate}
                takeAttendance={takeAttendance}
                // Click User Name Handler
                handleOnClickRow={(userId) => {
                  if (route) {
                    history.push(`${route}/${userId}`);
                    return;
                  }

                  dispatch(handleOpenUserSideSheet({
                    userId,
                    orgId,
                    currentUserOrgId: currentUser?.orgId,
                    ...(route ? { route, history } : {})
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UsersList;
