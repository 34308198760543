const apprenticeAgreementState = {
  accessCode: null, // Radom 6 digit integer
  name: '',
  userId: '',
  userProfileId: '',
  orgId: '',
  locationId: '',
  apprenticeshipId: '',
  employerAgreementId: '',
  wageScheduleId: '',
  instructionProviderId: '',
  // Apprentice Signatory
  signerEmail: '',
  signerSignature: '',
  signerSignatureDate: '',
  signerIpAddress: '',
  signerInviteSentAt: '',
  signerName: '',
  signerFirstName: '',
  signerLastName: '',
  signerMiddleName: '',
  signerPhone: '',
  // sys
  id: '',
  createdAt: '',
  updatedAt: '',
  // Apprentice (Generated when signing)
  employmentStatus: '',
  employmentStatusDate: '',
  ssn: '',
  gender: '',
  dob: '',
  ethnicity: '',
  races: null,
  veteranStatus: '',
  disability: '',
  educationLevel: '',
  orgReferral: '',
  citizenStatus: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  addressPostalCode: '',
  criminalBkg: '',
  // Sponsor (Generated when signing)
  hoursProbationary: '',
  creditPrevJob: '',
  wagesPaidDuringRTI: '',
  RTIDuringWorkHrs: '',
  creditPrevRTIHrs: '',
  priorHourlyWage: '',
  occupationName: '',
  ojtHours: '',
  instructionHours: '',
  startDate: '',
  expectedEndDate: ''
};

export default apprenticeAgreementState;
