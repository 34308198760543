import React, { useMemo } from 'react';
import Table from './Table';
import DateCell from '../../../ManageContent/Tables/DateCell';
import ArrLenCell from '../../../ManageContent/Tables/ArrLenCell';
import TitleCell from './TitleCell';

const List = ({
  data,
  handleClickRow,
  showLearningModulesCol,
  showSkillsCol,
  showCreatedAtCol
}) => {
  const columns = useMemo(() => [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: TitleCell
    },
    {
      Header: 'Courses',
      accessor: 'classIds',
      Cell: ArrLenCell
    },
    {
      Header: 'Skills',
      accessor: 'skillIds',
      Cell: ArrLenCell
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: DateCell
    }
  ], []);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!showLearningModulesCol) {
      cols.push('classIds');
    }

    if (!showSkillsCol) {
      cols.push('skillIds');
    }

    if (!showCreatedAtCol) {
      cols.push('createdAt');
    }

    return cols;
  }, [showCreatedAtCol, showLearningModulesCol, showSkillsCol]);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Projects.
        </div>
      </div>
    );
  }

  return (
    <Table
      handleClickRow={handleClickRow}
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
