import {
  CREATE_RESOURCE_GROUP,
  RESET_RESOURCE_GROUPS, SET_RESOURCE_GROUPS
} from '../../../actions/types';

const initialState = {
  fetched: false,
  list: null,
  pagination: null,
  groupTags: null, // string[]
  groupIds: null // string[]
};

export default function resourceGroups(state = initialState, action) {
  switch (action.type) {
    case SET_RESOURCE_GROUPS: {
      return {
        ...state,
        fetched: true,
        list: action.list,
        pagination: action.pagination,
        groupTags: action.groupTags,
        groupIds: action.groupIds
      };
    }
    case CREATE_RESOURCE_GROUP:
      return {
        ...state,
        list: action.list
      };
    case RESET_RESOURCE_GROUPS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
