import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ICON_USER_LOCK } from '@apprentage/constants';
import { setCurrentClass } from '../../../actions/Class';
import { updateCourse } from '../../../services/courses';
import { canManageCoursePrereqs } from '../../../services/currentUser';
import convertUrlParamsToObject from '../../../utils/convertUrlParamsToObject';
import RadioGroup from '../../ManageContent/RadioGroup';
import SelectMenu from '../../ManageContent/SelectMenu';
import References from '../../ManageContent/References';

const Form = ({ data }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const courses = useSelector((state) => state.courses);
  const currentUser = useSelector((state) => state.currentUser);
  const role = pathOr([], ['role'], currentUser);
  const classId = pathOr(null, ['id'], data);
  const locationSearch = pathOr(null, ['search'], location);
  const searchParams = convertUrlParamsToObject(locationSearch);
  const [loading, setLoading] = useState(false);
  const [locked, setLocked] = useState(data.locked);
  const [prerequisite] = useState(data.prerequisite);
  const [prerequisiteId, setPrerequisiteId] = useState(data.prerequisiteId);

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    toast.dismiss('lockedNoPrereq'); // dismiss toast if visible

    const dataToSave = {
      classId,
      locked,
      prerequisiteId
    };

    // LOCKED, NO PREREQUISITE
    if (locked && !prerequisiteId) {
      // User chose to lock this course, but did not select a prerequisite
      toast.error('Locked courses require a prerequisite, please select a course.', {
        autoClose: false,
        toastId: 'lockedNoPrereq'
      });

      setLoading(false);

      return false;
    }

    if (!locked) {
      // Remove prerequisite
      dataToSave.prerequisiteId = null;
    }

    updateCourse(dataToSave).then(() => {
      toast.success('Course pacing saved!');
      dispatch(setCurrentClass({ classId }));
      setLoading(false);
    });
  };

  // Course Pacing (locked) | Prerequisite
  const coursesExcludeCurrent = courses.list && courses.list.filter((c) => c.id !== classId);
  const prereqOptions = coursesExcludeCurrent && coursesExcludeCurrent.length && coursesExcludeCurrent.map((c) => {
    return {
      value: c.id,
      label: c.title
    };
  });

  return (
    <form
      name="form-courseSettings"
      onSubmit={onSubmit}
    >
      <div className="card border-bottom my-3">
        <div className="card-header p-3">
          <h1 className="h5 m-0">
            <i className={`${ICON_USER_LOCK} mr-1`} aria-hidden="true" /> Course Pacing
          </h1>
        </div>
        <div className="card-body p-0">
          <div
            className={`${canManageCoursePrereqs(role) ? '' : 'opacity-50 pointer-events-none'} card-body`}
          >
            <div id="class-locked" className="form-group mb-3">
              <label className="mb-1">
                <b>Locked:</b> <span className="text-danger">*</span>
              </label>
              <div className="text-muted medium mb-2">
                Prevents users from accessing this Course until any Courses that list it as a Prerequisite are completed successfully.
              </div>
              <RadioGroup
                name="locked"
                value={locked}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  if (value === 'false') {
                    toast.dismiss('lockedNoPrereq'); // dismiss toast if visible
                  }

                  setLocked(value === 'true');
                }}
                showYes={prereqOptions && prereqOptions.length}
                disabled={!canManageCoursePrereqs(role)}
              />
            </div>

            {locked && prereqOptions && prereqOptions.length && (
              <div
                id="class-prerequisite"
                className="position-relative"
                style={{ zIndex: 2 }}
              >
                <label className="mb-1">
                  <b>Prerequisite:</b>
                </label>
                <div className="text-muted medium mb-2">
                  This Course will unlock once the Course below is completed successfully.
                </div>
                {canManageCoursePrereqs(role) ? (
                  <SelectMenu
                    id="prerequisiteId"
                    name="prerequisiteId"
                    options={prereqOptions}
                    isMulti={false}
                    defaultValue={prerequisiteId && prereqOptions.find((option) => prerequisiteId === option.value)}
                    onChange={({
                      prerequisiteId: newPrerequisiteId
                    }) => {
                      setPrerequisiteId(newPrerequisiteId);
                    }}
                  />
                ) : (
                  <References
                    contents={[prerequisite]}
                    classId={classId}
                    searchParams={searchParams}
                  />
                )}
              </div>
            )}
          </div>

        </div>
        <div className="card-footer d-flex p-3">
          <button
            className="btn btn-primary d-block"
            type="submit"
            disabled={loading || !canManageCoursePrereqs(role)}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
