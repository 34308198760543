import React, { useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { JsonEditor } from 'json-edit-react';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import formatData from '../../../services/formatData';
import { createEntry } from '../../../services/entry';
import { addEntriesToTopic } from '../../../services/topics';
import { resetSideSheet } from '../../../actions/SideSheets';
import { resetCurrentTopic, setCurrentTopic } from '../../../actions/Topic';
import DotMenu from './DotMenu';
import './style.css';

const ImportMaterialSideSheet = ({ className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const project = useSelector((state) => state.project);
  const sideSheet = useSelector((state) => state.sideSheet);
  // SideSheet Data
  const topicId = sideSheet?.data?.topicId || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [jsonData, setJsonData] = useState({
    topicMaterials: [
      {
        title: 'Material Title Goes Here', // string
        body: `### **Course Title**: Fundamentals of Silicon Photonics

### **Course Description**:
This course provides a comprehensive introduction to the basics of silicon photonics, a field that combines photonics with silicon-based electronics. It is designed for learners new to the subject, offering foundational knowledge essential for further study and professional development in photonics, optoelectronics, and integrated circuit design. Through a series of self-paced modules, learners will explore key concepts, including the behavior of light in silicon, waveguides, optical fibers, and the fabrication techniques used in silicon photonics.
        `
      },
      {
        title: 'Material Title Goes Here', // string
        body: '<p>Use HTML</p>',
        html: true
      }
    ]
  });

  const onClickImport = () => {
    const converter = new Showdown.Converter();

    if (!jsonData.topicMaterials) {
      toast.error('topicMaterials missing.');
      return false;
    }

    if (!Array.isArray(jsonData.topicMaterials)) {
      toast.error('topicMaterials should be an array.');
      return false;
    }

    if (Array.isArray(jsonData.topicMaterials) && jsonData?.topicMaterials.length === 0) {
      toast.error('topicMaterials Array should have at least one item.');
      return false;
    }

    setLoading(true);

    const formattedTopicMaterials = jsonData.topicMaterials.map((topicMaterial) => {
      const bodyHTML = converter.makeHtml(topicMaterial.body);

      const formattedData = formatData({
        title: topicMaterial.title,
        body: topicMaterial?.html ? topicMaterial?.body : bodyHTML,
        enableNewEditor: true
      }, 'material');
      return formattedData;
    });

    const sleep = (m) => new Promise((r) => setTimeout(r, m));

    const materialIds = [];

    const promisesMaterials = formattedTopicMaterials.map(async (formattedData, i) => {
      return [
        await sleep(1000 * i + 1),
        await createEntry({
          contentType: 'material',
          data: formattedData
        }).then((entry) => {
          materialIds.push(entry?.sys?.id);
        })
      ];
    });

    if (Array.isArray(promisesMaterials) && promisesMaterials.length > 0) {
      Promise.all(promisesMaterials).then(() => {
        toast.info('Updating Topic...');
        addEntriesToTopic({
          entryIds: materialIds.length > 0 ? materialIds : null,
          contentType: 'material',
          topicId
        }).then(() => {
          toast.success('Materials imported');
          dispatch(resetCurrentTopic());
          dispatch(setCurrentTopic({ topicId })).then(() => {
            dispatch(resetSideSheet());
          });
        });
      }).catch((error) => {
        setLoading(false);
        toast.error('Something went wrong, refresh the page and try again.');
        console.error(error);
        throw new Error(error);
      });
    } else {
      throw new Error('Something went wrong, try again');
    }
  };

  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex align-items-center justify-content-between'>
        <h6 className="m-0 font-weight-bold">
          <span className="d-flex align-items-center">
            <FaCloudUploadAlt />
            <span className="ml-1 text-nowrap">
              Import Materials (JSON)
            </span>
          </span>
        </h6>
        <DotMenu projectId={project?.id} />
      </div>
      <div className='card-body overflow-y-scroll'>
        <p>
          Hover over "data" and click the edit (pencil) icon & paste JSON.
        </p>
        <JsonEditor
          data={jsonData}
          setData={setJsonData} // optional
          // enableClipboard
          rootName='data'
          restrictAdd
          restrictDrag
        />
        <p>
          ( Structure your data as shown above )
        </p>
      </div>
      <div className='card-footer'>
        <button
          onClick={onClickImport}
          disabled={loading}
          type='button'
          className='btn btn-m btn-primary'
        >
          {loading ? 'Importing...' : 'Import'}
        </button>
      </div>
    </div>
  );
};

export default ImportMaterialSideSheet;
