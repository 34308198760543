import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { constructWidgetUrl } from '../../../../services/widgets';

const ButtonCell = ({ value: url, row }) => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Link
  const title = row?.original?.title || '';
  const linkTarget = row?.original?.linkTarget || '';
  const includeEmail = row?.original?.includeEmail || false;
  const includeExternalUserId = row?.original?.includeExternalUserId || false;

  const widgetUrl = useMemo(() => {
    return constructWidgetUrl({
      email: currentUser?.email || null,
      externalUserId: currentUser?.externalUserId || null,
      includeEmail,
      includeExternalUserId,
      url
    });
  }, [
    currentUser?.email,
    currentUser?.externalUserId,
    includeEmail,
    includeExternalUserId,
    url
  ]);

  return (
    <div className="d-flex flex-column">
      <a
        className="mx-1 btn btn-sm btn-white py-1"
        target={linkTarget}
        rel="noopener noreferrer"
        href={widgetUrl}
        title={title}
        style={{
          minWidth: '70px',
          borderRadius: '1rem',
          fontSize: '.75rem'
        }}
      >
        GO
      </a>
    </div>
  );
};

export default ButtonCell;
