import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import {
  ICON_COPY,
  ICON_EXTERNAL_LINK,
  ICON_PATHWAY
} from '@apprentage/constants';
import { TURBINE_CONSOLE } from '../../../../constants/urls';
import { ORG_CONSOLE_PATHWAYS } from '../../../../constants/routes';
import { updatePathway } from '../../../../services/pathways';
import { canManagePathwayPublishSettings } from '../../../../services/currentUser';
import Loading from '../../../Loading';
import RadioGroup from '../../../ManageContent/RadioGroup';
import NavTabs from '../NavTabs';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import Collapse from '../../../ManageContent/Collapse';

const Form = () => {
  const publicPathwayEmbedCodeRef = useRef();
  const publicPathwayUrlRef = useRef();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  const orgSlug = pathOr(null, ['slug'], organization);
  const role = pathOr([], ['role'], currentUser);
  const pathwayId = pathOr(null, ['id'], pathway);
  const managePathwayPublishSettings = canManagePathwayPublishSettings(role);
  // local state
  const [isPublic, setIsPublic] = useState(pathway.isPublic || false);
  const [searchEnginesEnabled, setSearchEnginesEnabled] = useState(pathway.searchEnginesEnabled || false);

  const selectAll = (e) => {
    e.currentTarget.select();
  };

  const copyLink = (refElem) => {
    const { value } = refElem.current;

    refElem.current.focus();
    refElem.current.select();

    navigator.clipboard.writeText(value);

    toast.success('Link copied!');
  };

  const publicPathwayUrl = ({ embed }) => {
    let url = `${TURBINE_CONSOLE}/o/${orgSlug}/pathways/${pathwayId}`;

    if (embed) {
      url += '?embed=true';
    }

    return url;
  };

  const iframeHeight = () => {
    // TODO make dynamic
    return '500px';
  };

  const iframeWidth = () => {
    // TODO make dynamic
    return '100%';
  };

  const publicPathwayEmbedCode = () => {
    return `<iframe src="${publicPathwayUrl({ embed: true })}" title="${pathway.title}" height="${iframeHeight()}" width="${iframeWidth()}">Powered by Turbine Workforce</iframe>`;
  };

  const handleIsPublicChange = (e) => {
    const { value } = e.currentTarget;
    const dataToSave = {
      isPublic: value === 'true',
      searchEnginesEnabled: value === 'true'
    };

    setIsPublic(dataToSave.isPublic);
    setSearchEnginesEnabled(dataToSave.searchEnginesEnabled);

    updatePathway(pathwayId, dataToSave).then(() => {
      toast.success(`Pathway is ${dataToSave.isPublic ? 'Public' : 'Private'}`);
    });
  };

  const handleSearchEnginesEnabledChange = (e) => {
    const { value } = e.currentTarget;
    const dataToSave = {
      searchEnginesEnabled: value === 'true'
    };

    setSearchEnginesEnabled(dataToSave.searchEnginesEnabled);

    updatePathway(pathwayId, dataToSave).then(() => {
      toast.success(`Course Page is ${dataToSave.searchEnginesEnabled ? 'visible in' : 'hidden from'} search engines`);
    });
  };

  if (!pathway.id) {
    return (
      <Loading text="Loading Pathway..." />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={pathway.title}
            icon={ICON_PATHWAY}
            route={ORG_CONSOLE_PATHWAYS}
          />

          <NavTabs
            activeTab='publish'
          />

          <div
            className="bg-white border-bottom border-left border-right p-3"
          >
            <div className="d-flex align-items-center justify-content-between border-bottom pb-3">
              <h5 className="font-weight-bold m-0">
                Publish Settings
              </h5>
              {isPublic && (
                <a
                  className="btn btn-sm btn-info d-flex align-items-center"
                  href={publicPathwayUrl({ embed: false })}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Preview"
                >
                  <span>
                    Preview
                  </span>
                  <small className="ml-2">
                    <i className={ICON_EXTERNAL_LINK} />
                  </small>
                </a>
              )}
            </div>

            <div
              id="pathway-isPublic"
              className={`${managePathwayPublishSettings ? '' : 'opacity-50 pointer-events-none'} form-group mt-3`}
            >
              <label className="mb-1">
                <b>Pathway Status:</b>
              </label>
              <div className="text-muted medium mb-2">
                Make this pathway public to share a link or embed it in your website.
              </div>
              <RadioGroup
                name="isPublic"
                value={isPublic}
                labelYes="Public"
                labelNo="Private"
                onChange={handleIsPublicChange}
              />
            </div>

            {isPublic && (
              <Collapse
                id="pathwaySearchEnginesEnabledCollapse"
                title="Search Engine Optimization"
                ariaExpanded={isPublic}
                className="mb-3"
              >
                <div
                  id="pathway-searchEnginesEnabled"
                  className={`${managePathwayPublishSettings ? '' : 'opacity-50 pointer-events-none'} form-group mb-3`}
                >
                  <label className="mb-1">
                    <b>SEO: Search Engine Visibility</b>
                  </label>
                  <div className="text-muted medium mb-2">
                    Control whether or not this pathway page appears in search results on sites like Google.
                  </div>
                  <RadioGroup
                    name="searchEnginesEnabled"
                    value={searchEnginesEnabled}
                    labelYes="Visible"
                    labelNo="Hidden"
                    onChange={handleSearchEnginesEnabledChange}
                  />
                </div>
              </Collapse>
            )}

            {isPublic && (
              <Collapse
                id="pathwayIsPublicCollapse"
                title="Pathway Page URL & Embed Code"
                ariaExpanded={isPublic}
              >
                <div className="border p-2 mb-3">
                  <p>
                    Copy the <b>Pathway Page URL</b> and share via email, social media, etc.
                  </p>
                  <div className="mb-2">
                    Pathway Page URL:
                  </div>
                  <div className="input-group">
                    <input
                      readOnly
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      defaultValue={publicPathwayUrl({ embed: false })}
                      onClick={selectAll}
                      ref={publicPathwayUrlRef}
                    />
                    <div className="input-group-append">
                      <button
                        title="Copy Link"
                        aria-label="Copy Link"
                        type='button'
                        className='btn btn-info'
                        onClick={() => {
                          copyLink(publicPathwayUrlRef);
                        }}
                      >
                        <i className={ICON_COPY} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="border p-2">
                  <p>
                    Copy the <b>Pathway Embed Code</b> and include in the code of your website.
                  </p>
                  <div className="mb-2">
                    Pathway Embed Code:
                  </div>
                  <div className="input-group">
                    <textarea
                      readOnly
                      style={{ minHeight: '100px' }}
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      value={publicPathwayEmbedCode()}
                      onClick={selectAll}
                      ref={publicPathwayEmbedCodeRef}
                    />
                    <div className="input-group-append">
                      <button
                        title="Copy Link"
                        aria-label="Copy Link"
                        type='button'
                        className='btn btn-info'
                        onClick={() => {
                          copyLink(publicPathwayEmbedCodeRef);
                        }}
                      >
                        <i className={ICON_COPY} />
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
