import {
  deleteSupabaseEntry,
  fetchSupabaseEntries
} from './supabaseProxy';
import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';

const table = 'documents';

export const fetchDocuments = async ({
  orgId,
  refId,
  ids,
  order = 'title',
  page = 1,
  select,
  limit = DEFAULT_PAGINATION_LIMIT
}) => {
  const params = {
    order,
    page,
    limit
  };

  if (orgId) {
    params.orgId = orgId;
    params['f.orgId[eq]'] = orgId;
  }

  if (refId) {
    params['f.refId[eq]'] = refId;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const deleteDocument = (id) => {
  return new Promise((resolve, reject) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`delete ${table}`, error);
      reject(error);
    });
  });
};
