import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaListAlt } from 'react-icons/fa';
import LogbookView from '../../LogbookView';
import ProgramContainer from './ProgramContainer';
import LogbookProgress from './LogbookProgress';
import Vitals from '../WorkforceSuite/Apprenticeship/User/Records/Vitals';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../constants/routes';

const View = () => {
  // Redux
  const logbook = useSelector((state) => state.logbook);
  const logbookRecords = useSelector((state) => state.logbookRecords);
  const logbookHours = useSelector((state) => state.logbookHours);
  const apprenticeship = useSelector((state) => state.apprenticeship);

  return (
    <>
      <ProgramContainer
        className="animateIn my-3"
        apprenticeshipId={apprenticeship?.id}
        apprenticeshipTitle={apprenticeship?.title}
        logbookId={logbook?.id}
        logbookLocationId={logbook?.employerId}
      >
        <div className='w-100'>
          <LogbookProgress
            records={logbookRecords.list}
            ojtHours={apprenticeship?.ojtHours}
          />
        </div>
      </ProgramContainer>

      <h6>
        Records
      </h6>

      <div
        className='bg-white rounded my-2 shadow p-3 mb-3'
      >
        <Vitals />
        <div
          className='d-flex align-items-center justify-content-center'
        >
          <Link
            className="btn btn-link btn-md"
            to={`${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeship?.id}/users/${logbook?.userId}/records`}
          >
            <span className='d-flex align-items-center'>
              <FaListAlt />
              <span className='ml-2'>
                View all records ({logbookHours?.totalRecords})
              </span>
            </span>
          </Link>
        </div>
      </div>

      <h6>
        Competencies
      </h6>

      <div className='bg-white border shadow rounded overflow-content'>
        <div className="card-body">
          <LogbookView
            ariaExpanded
          />
        </div>
      </div>
    </>
  );
};

export default View;
