import React from 'react';

const UserNameCell = ({
  value: userName
}) => {
  return (
    <div className='text-nowrap'>
      <span
        className="ml-2"
      >
        {userName}
      </span>
    </div>
  );
};

export default UserNameCell;
