const logbookHoursState = {
  totalHours: 0,
  approvedHours: 0,
  totalRecords: 0,
  competencyHours: null,
  logbookId: null
};

export default logbookHoursState;

// Response
// {
//   "totalHours": 74.7,
//   "totalRecords": 43,
//   "approvedHours": 20
//   "competencyHours": {
//     "95ebcd50-3dec-4f23-a8ac-1dd1aa2f0fd0": 71.33,
//     "7a57ef95-b81a-4161-9a20-73b51d96617d": 3.37
//   }
// }
