import React from 'react';

const DataNotAvailable = ({
  text,
  iconComponent,
  className = 'd-flex align-items-center justify-content-center py-5 bold font-weight-bold'
}) => (
  <div
    className={className}
  >
    <div className='d-flex flex-column'>
      {iconComponent && (
        <div className='mb-2 mx-auto'>
          {iconComponent()}
        </div>
      )}

      <p className='mb-0'>
        {text}
      </p>
    </div>
  </div>
);

export default DataNotAvailable;
