import { roleReadable } from '@apprentage/utils';
import { useSelector } from 'react-redux';

const RoleCell = ({ value: role = [] }) => {
  const organization = useSelector((state) => state.organization);
  const orgType = organization?.type || '';

  if (typeof role === 'string') {
    return roleReadable([role], orgType);
  }

  return roleReadable(role, orgType);
};

export default RoleCell;
