import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { ICON_TRIANGLE_BANG } from '@apprentage/constants';
import {
  setCurrentModal, resetCurrentModal
} from '../../../actions/Modals';
import { TURBINE_TOS_URL } from '../../../constants/urls';
import convertUrlParamsToObject from '../../../utils/convertUrlParamsToObject';

class Agreements extends Component {
  componentDidMount() {
    const { location: { search: locationSearch } } = this.props;
    const { agreement } = convertUrlParamsToObject(locationSearch);

    if (agreement && agreement === 'decline') {
      this.props.resetCurrentModal();
    }
  }

  openAgreementModal = () => {
    this.props.setCurrentModal({
      key: 'billingAgreement'
    });
  }

  render() {
    const { organization, billingAgreement } = this.props;
    const { name: orgName, tos } = organization;
    const hasCustomAgreement = billingAgreement && billingAgreement.title;

    return (
      <div>
        Maintaining an active account indicates acceptance of:
        <ul>
          <li>
            the Turbine Workforce <a href={TURBINE_TOS_URL} target="_blank" rel="noopener noreferrer">Terms of Service</a>;
          </li>
          {tos && (
            <li>
              and the {orgName} <a href={tos} target="_blank" rel="noopener noreferrer">Terms of Service</a>{hasCustomAgreement ? ';' : '.'}
            </li>
          )}
          {hasCustomAgreement && (
            <li>
              and the
              <button
                className="btn-link px-0 ml-1"
                onClick={this.openAgreementModal}
                type="button"
              >
                {billingAgreement.title}
              </button>.
              {!billingAgreement.accepted && (
                <span className="ml-2 alert-warning p-1">
                  <i className={ICON_TRIANGLE_BANG} /> Acceptance required
                </span>
              )}
            </li>
          )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({
  organization,
  billingAgreement
}) => ({
  organization,
  billingAgreement
});

export default compose(
  withRouter,
  connect(mapStateToProps, { setCurrentModal, resetCurrentModal })
)(Agreements);
