import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaCheckCircle, FaCircle, FaExclamationTriangle } from 'react-icons/fa';
import { fetchLogbookRecords } from '../../../../../../services/logbookRecords';
import { fetchLogbookHours } from '../../../../../../services/logbooks';
import NumberCard from '../../../../../NumberCard';

const Vitals = ({
  className = '',
  title = ''
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const logbook = useSelector((state) => state.logbook);
  // Organization
  const orgId = organization?.id || '';
  // Local State
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [approvedHours, setApprovedHours] = useState(0);
  const [approvedRecords, setApprovedRecords] = useState(0);
  const [unapprovedRecords, setUnapprovedRecords] = useState(0);

  useEffect(() => {
    if (logbook.fetched && logbook?.id) {
      fetchLogbookRecords({
        orgId,
        logbookId: logbook?.id,
        isApproved: true,
        count: true
      }).then((response) => {
        setApprovedRecords(response?.total);
      });

      fetchLogbookRecords({
        orgId,
        logbookId: logbook?.id,
        isApproved: null,
        count: true
      }).then((response) => {
        setUnapprovedRecords(response?.total);
      });

      fetchLogbookHours({
        orgId,
        logbookIds: [logbook?.id],
        showCompHours: true
      }).then((response) => {
        const logbookHours = response?.items ? response?.items[0] : null;

        setTotalRecords(logbookHours?.totalRecords);
        setApprovedHours(logbookHours?.approvedHours);
        setTotalHours(logbookHours?.totalHours);
      });
    }
  }, [logbook?.id, logbook.fetched, orgId]);

  return (
    <div className={className}>
      {title && (
        <h6>
          Vitals
        </h6>
      )}
      <div className="row">
        <div className="col-sm-4">
          <NumberCard
            className='mb-2'
            num={unapprovedRecords}
            text="Unapproved Records"
            icon={() => <FaExclamationTriangle className='text-warning mr-1' />}
          />
        </div>

        <div className="col-sm-4">
          <NumberCard
            className='mb-3'
            num={approvedRecords}
            text="Approved Records"
            icon={() => <FaCheckCircle className='text-success mr-1' />}
          />
        </div>

        <div className="col-sm-4">
          <NumberCard
            className='mb-2'
            num={totalRecords || 0}
            text="Total Records"
            icon={() => <FaCircle className='text-primary mr-1' />}
          />
        </div>

        <div className="col-sm-4">
          <NumberCard
            className='mb-2'
            num={(totalHours - approvedHours).toFixed(2) || 0}
            text="Unapproved Hours"
            icon={() => <FaExclamationTriangle className='text-warning mr-1' />}
          />
        </div>

        <div className="col-sm-4">
          <NumberCard
            className='mb-2'
            num={approvedHours || 0}
            text="Approved Hours"
            icon={() => <FaCheckCircle className='text-success mr-1' />}
          />
        </div>

        <div className="col-sm-4">
          <NumberCard
            className='mb-2'
            num={totalHours || 0}
            text="Total Hours"
            icon={() => <FaCircle className='text-primary mr-1' />}
          />
        </div>
      </div>
    </div>
  );
};

export default Vitals;
