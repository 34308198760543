import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaFolder } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { fetchResourceGroups } from '../../services/resourceGroups';

const SearchFolders = ({ ids, onChange }) => {
  const organization = useSelector((state) => state.organization);
  const orgId = organization?.id || null;

  const [folders, setFolders] = useState([]);

  useEffect(() => {
    if (ids) {
      fetchResourceGroups({ orgId, ids }).then((response) => {
        setFolders(response.items);
      });
    }
  }, [ids, orgId]);

  if (!ids || !folders || (Array.isArray(folders) && folders.length === 0)) {
    return null;
  }

  return (
    <div>
      {folders.map((folder, i) => {
        return (
          <Fragment key={folder?.id}>
            <div
              className={`d-flex align-items-center justify-content-between rounded border px-2 py-1 ${i === 0 ? '' : 'mt-3'}`}
            >
              <div className='d-flex align-items-center'>
                <FaFolder className='text-primary' size={20} />
                <span className='ml-2'>
                  {folder?.title}
                </span>
              </div>

              {onChange && (
                <button
                  className='btn btn-sm btn-white ml-3 px-1'
                  onClick={() => {
                    onChange(folder?.id);
                  }}
                  type='button'
                  aria-label="Remove Folder"
                >
                  <FaXmark />
                </button>
              )}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default SearchFolders;
