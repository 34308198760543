import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaCog, FaCopy, FaEllipsisH, FaMapMarkedAlt, FaPaperPlane
} from 'react-icons/fa';
import { ORG_CONSOLE_LOCATIONS } from '../../constants/routes';
import { routeWithOrgId } from '../../services/organizations';
import { canEditLocation } from '../../services/currentUser';
import { getFullAddress, locationTerminology } from '../../services/locations';
import { setCurrentModal } from '../../actions/Modals';
import copyToClipBoard from '../../utils/copyToClipBoard';

const DotMenu = ({
  className = '',
  location,
  showMenuManageLinks = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const role = currentUser?.role || [];
  const manageLocation = showMenuManageLinks && canEditLocation(role);
  const locationTerm = locationTerminology({ orgType });

  const locationId = location?.id || '';
  const locationName = location?.name || '';
  const mapLink = location?.mapLink || '';

  const fullAddress = getFullAddress({ location });

  const inviteToCreateJobProfile = () => {
    dispatch(setCurrentModal({
      key: 'inviteUserCreateJobProfile',
      data: {
        locationId
      }
    }));
  };

  return (
    <div
      className={className}
    >
      <button
        className="btn btn-sm btn-white"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage Menu"
        aria-label='Manage Menu'
      >
        <FaEllipsisH />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white d-flex align-items-center">
          <small>
            <i className={`${locationTerm.icon} mr-2`} />
          </small>
          <span className='text-nowrap'>
            {locationName}
          </span>
        </div>

        {mapLink && (
          <a
            className="dropdown-item border-bottom py-2"
            href={mapLink}
            role="button"
            title="View on map"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className='d-flex align-items-center'>
              <FaMapMarkedAlt />
              <span className='ml-2'>
                Open Map
              </span>
            </span>
          </a>
        )}

        <button
          type="button"
          title='Copy Address'
          className="dropdown-item border-bottom py-2"
          onClick={() => {
            copyToClipBoard(fullAddress);
            toast.success('Address Copied!');
          }}
        >
          <span className='d-flex align-items-center'>
            <FaCopy />
            <span className='ml-2'>
              Copy Address
            </span>
          </span>
        </button>

        {manageLocation && orgType === 'community' && (
          <button
            type="button"
            className="dropdown-item border-bottom py-2"
            onClick={inviteToCreateJobProfile}
          >
            <span className='d-flex align-items-center'>
              <FaPaperPlane />
              <span className='ml-2'>
                Invite: Create Job Profile
              </span>
            </span>
          </button>
        )}

        {manageLocation && (
          <Link
            className="dropdown-item py-2"
            to={routeWithOrgId({
              route: `${ORG_CONSOLE_LOCATIONS}/manage/${locationId}`,
              orgId
            })}
            title={`Edit ${locationTerm.text}`}
          >
            <span className='d-flex align-items-center'>
              <FaCog />
              <span className='ml-2'>
                Settings
              </span>
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

export default DotMenu;
