import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { ICON_USER_LOCK } from '@apprentage/components/dist/constants/icons';
import { INVITE_TYPE_EMPLOYER_PROFILE_UPDATE } from '@apprentage/constants';
import { resetCurrentModal } from '../../../actions/Modals';
import { sendEmailUserInviteHtml } from '../../../services/mailer';
import generateRandomNumber from '../../../utils/generateRandomNumber';
import { createEmployerProfile, updateEmployerProfile } from '../../../services/employers';
import { getEmployerProfile } from '../../../actions/Employers';
import { createInvite, generateInviteLink } from '../../../services/invites';
import { getInvites } from '../../../actions/Invites';
import { updateLocation } from '../../../actions/Locations';
import Modal from '../../Modal';

const ManageEmployerPointOfContact = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const locations = useSelector((state) => state.locations);
  const employerProfile = useSelector((state) => state.employerProfile);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Current Modal
  const key = 'manageEmployerPointOfContact';
  const currentModalKey = pathOr(null, ['key'], currentModal);
  const modalData = pathOr(null, ['data'], currentModal);
  const locationId = pathOr(null, ['locationId'], modalData);
  // Organization
  const orgId = pathOr(null, ['id'], organization);
  const orgName = pathOr(null, ['name'], organization);
  const orgEmail = pathOr(null, ['email'], organization);
  const orgLogo = pathOr(null, ['orgLogo'], organization);
  // Location
  const locationName = pathOr('Employer', ['name'], locations.hash[locationId]);
  // Training Program
  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);
  // Employer Profile
  const employerProfileId = pathOr(null, ['id'], employerProfile);
  // Local State
  const [loading, setLoading] = useState(false);
  const [sendInvitation, setSendInvitation] = useState(Boolean(!employerProfileId)); // TODO revisit
  const [contactFirstName, setContactFirstName] = useState(employerProfile?.contactFirstName || '');
  const [contactLastName, setContactLastName] = useState(employerProfile?.contactLastName || '');
  const [contactTitle, setContactTitle] = useState(employerProfile?.contactTitle || '');
  const [contactEmail, setContactEmail] = useState(employerProfile?.contactEmail || '');
  const [contactPhone, setContactPhone] = useState(employerProfile?.contactPhone || '');
  const [contactPhoneExt, setContactPhoneExt] = useState(employerProfile?.contactPhoneExt || '');

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSaveSuccessCallback = (id) => {
    dispatch(getEmployerProfile(id)).then(() => {
      handleClose();
    });
  };

  const handleSaveErrorCallback = (error) => {
    setLoading(false);
    console.error(error);
    toast.error('Something went wrong, please try again.');
  };

  const handleSendInvitation = (newEmployerProfileId) => {
    toast.info('Sending Invitation...');

    const accessCode = generateRandomNumber(6);

    // Create Invite
    createInvite({
      firstName: contactFirstName,
      lastName: contactLastName,
      email: contactEmail,
      orgId,
      locationId,
      inviteType: INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
      accessCode,
      sentAt: new Date().toISOString(),
      viewedAt: '',
      // Invite Specific Details
      employerProfileId: newEmployerProfileId,
      apprenticeshipId
    }).then((inviteId) => {
      // Email invitee
      sendEmailUserInviteHtml({
        orgName,
        orgEmail,
        orgLogo,
        locationName,
        inviteType: INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
        inviteLink: generateInviteLink(inviteId),
        emails: [contactEmail],
        accessCode
      });

      // Get invites
      dispatch(getInvites({
        employerProfileId: newEmployerProfileId,
        apprenticeshipId
      })).then(() => {
        toast.success('Invitation sent!');
        handleClose();
      });
    }).catch((error) => {
      handleSaveErrorCallback(error);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    toast.info('Saving...');

    const dataToSave = {
      contactFirstName,
      contactLastName,
      ...(contactTitle ? { contactTitle } : {}),
      contactEmail,
      ...(contactPhone ? { contactPhone } : {}),
      ...(contactPhoneExt ? { contactPhoneExt } : {})
    };

    if (employerProfileId) {
      updateEmployerProfile(dataToSave, employerProfileId).then(() => {
        handleSaveSuccessCallback(employerProfileId);
      }).catch((error) => {
        handleSaveErrorCallback(error);
      });
    } else {
      createEmployerProfile({
        ...dataToSave,
        orgId,
        ...(orgLogo ? { orgLogo } : {}),
        orgName,
        locationName,
        locationId
      }).then((docId) => {
        if (docId) {
          dispatch(updateLocation({
            employerProfileId: docId
          }, locationId)).then(() => {
            if (sendInvitation) {
              handleSendInvitation(docId);
              return;
            }

            handleSaveSuccessCallback(docId);
          });
        } else {
          handleSaveErrorCallback(new Error('create employerProfile - no docId returned'));
        }
      }).catch((error) => {
        handleSaveErrorCallback(error);
      });
    }
  };

  if (currentModal && !currentModal.visible) return null;

  if (currentModalKey !== key) return null;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModalKey === key}
      close={handleClose}
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">
            Point of Contact
          </h1>
        </div>
        <form
          id="invite-user"
          name="invite-user"
          onSubmit={onSubmit}
        >
          <div className="card-body pb-4">
            <div className='d-flex mb-3'>
              <div className="mr-3 flex-fill">
                <label
                  htmlFor="contactFirstName"
                >
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="contactFirstName"
                  className="form-control"
                  value={contactFirstName}
                  required
                  autoFocus={!employerProfileId}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setContactFirstName(value);
                  }}
                />
              </div>
              <div className="flex-fill">
                <label
                  htmlFor="contactLastName"
                >
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="contactLastName"
                  className="form-control"
                  value={contactLastName}
                  required
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setContactLastName(value);
                  }}
                />
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="contactTitle">
                Title
              </label>
              <input
                type="text"
                name="contactTitle"
                className="form-control"
                value={contactTitle}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setContactTitle(value);
                }}
              />
              <div className="small mt-1">
                Ex: President, Director of Education, etc.
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="contactEmail">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="contactEmail"
                className="form-control"
                value={contactEmail}
                required
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setContactEmail(value);
                }}
              />
            </div>

            <div className="row">
              <div className="col-6 mb-3">
                <label htmlFor="contactPhone">
                  Phone
                </label>
                <input
                  data-demo="disabled"
                  type="text"
                  name="contactPhone"
                  onChange={(e) => {
                    const { value: newPhone } = e.currentTarget;

                    setContactPhone(newPhone);
                  }}
                  className="form-control"
                  value={contactPhone}
                />
                <div className="small mt-1">
                  Ex: 412-444-8615
                </div>
              </div>

              <div className="col-6">
                <label htmlFor="contactPhoneExt">
                  Phone Ext.
                </label>
                <input
                  data-demo="disabled"
                  type="text"
                  name="contactPhoneExt"
                  onChange={(e) => {
                    const { value: newContactPhoneExt } = e.currentTarget;

                    setContactPhoneExt(newContactPhoneExt);
                  }}
                  className="form-control"
                  value={contactPhoneExt}
                />
                <div className="small mt-1">
                  Ex: 101
                </div>
              </div>

              {!employerProfileId && contactEmail && (
                <div className='card mt-3 mx-3'>
                  <div className='card-body'>
                    <div className="custom-control custom-switch">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="sendInvite"
                        defaultChecked={sendInvitation}
                        onChange={(e) => {
                          const { checked } = e.currentTarget;
                          setSendInvitation(checked);
                        }}
                      />
                      <label
                        className="custom-control-label h6"
                        htmlFor="sendInvite"
                      >
                        Invite <strong>{`${contactFirstName} ${contactLastName}`}</strong> to provide Workforce information
                      </label>
                    </div>
                  </div>
                  {sendInvitation && (
                    <div className='card-footer'>
                      <i className={`${ICON_USER_LOCK} mr-1`} /> Restricted access will be granted to provide information about {locationName}'s Employees, Journeyworkers and Wage Schedules. Access is automatically revoked after information is submitted.
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="card-footer d-flex justify-content-between align-items-center">
            <button
              title="Save"
              aria-label="Save"
              className="btn btn-md btn-primary"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ManageEmployerPointOfContact;
