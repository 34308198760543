import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { setCurrentModal } from '../../../actions/Modals';
import { MODAL_KEY_MANAGE_NOTIFICATION } from '../../../constants/modals';
import { createNotification } from '../../../services/notifications';

const PostNotification = ({
  className = 'btn btn-sm btn-primary text-nowrap',
  notificationType = 'organization', // || 'course'
  listConfig
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  // Organization
  const orgId = organization?.id || '';
  // Course
  const classId = course?.id || '';
  // Current User
  const authorName = currentUser?.name || '';
  const authorUid = currentUser?.uid || '';
  const authorId = currentUser?.id || '';

  const handleClick = () => {
    const dataToSave = {
      isDraft: true,
      ...(notificationType === 'course' && classId ? { classId } : {}),
      orgId,
      authorId,
      authorUid,
      authorName
    };

    createNotification(dataToSave).then((response) => {
      if (!response?.id) {
        toast.error('Something went wrong, try again.');
        return;
      }

      dispatch(setCurrentModal({
        key: MODAL_KEY_MANAGE_NOTIFICATION,
        data: {
          isNewEntry: true,
          listConfig,
          notificationType,
          notification: {
            id: response?.id,
            ...dataToSave
          }
        }
      }));
    }).catch(() => {
      toast.error('Something went wrong, try again.');
    });
  };

  return (
    <button
      className={className}
      title="Post Notification"
      onClick={handleClick}
      type="button"
    >
      <span className='d-flex align-items-center'>
        <FaPlus />
        <span className='ml-2 font-weight-bold'>
          New
        </span>
      </span>
    </button>
  );
};

export default connect(null, { setCurrentModal })(PostNotification);
