import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { manageEntry } from '../../../services/entry';
import { canManageCourseContent } from '../../../services/currentUser';
import { CLASS_CONSOLE_USERS, MANAGE_CURRENT_ENTRY } from '../../../constants/routes';
import { routeWithClassId } from '../../../services/courses';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import ClassImage from './ClassImage';
import OrgAuthor from '../../OrgAuthor';
import SkillsTags from '../../SkillsTags';
import './style.css';

const Card = ({
  course,
  className = ''
}) => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  // misc
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });
  // Course
  const {
    id: classId,
    title,
    description,
    skillIds,
    schedule,
    testClass,
    classColor,
    classImage,
    enableCourseContent,
    orgId: courseOrgId,
    orgIds: courseOrgIds
  } = course;

  const courseMangeRouteConfig = {
    pathname: MANAGE_CURRENT_ENTRY,
    search: manageEntry({
      manageType: 'edit',
      classId,
      contentType: 'class'
    })
  };

  const courseUsersRoute = routeWithClassId({
    route: CLASS_CONSOLE_USERS,
    classId
  });

  return (
    <div className={`class-card-container ${className}`}>
      <div className="card mb-4 shadow-sm">
        <ClassImage
          title={title}
          classColor={classColor}
          classImage={classImage}
        />

        <div className="card-body">
          <h5>
            <b>{title}</b> {testClass && (<span className="badge badge-warning">Test Class</span>)}
          </h5>
          <div className="card-text">
            <RenderMarkdown source={description} />
          </div>

          <SkillsTags
            title="Skills:"
            className='mb-3'
            size='sm'
            ids={skillIds}
          />

          {schedule && (
            <div className='mb-2'>
              <b>Schedule:</b>
              <div className="text-muted mt-1">
                <RenderMarkdown source={schedule} />
              </div>
            </div>
          )}
        </div>

        {courseOrgIds && (
          <Fragment>
            {courseOrgIds.includes(orgId) ? (
              <OrgAuthor
                className="border-top bg-white px-3 py-2"
                orgId={courseOrgId}
              />
            ) : (
              <div className="h6 m-0 px-3 py-2 border-top">
                <span className="badge badge-info">Syndicated</span>
              </div>
            )}
          </Fragment>
        )}

        <div
          className="card-footer px-3 d-flex align-items-center justify-content-between"
        >
          <Link
            className="btn btn-sm btn-outline-primary"
            to={manageCourse && enableCourseContent ? courseMangeRouteConfig : courseUsersRoute}
            title="Class Console"
          >
            Console
          </Link>
        </div>

      </div>
    </div>
  );
};

export default Card;
