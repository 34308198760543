import { RESET_DIRECTORY_USER_PROFILE, SET_DIRECTORY_USER_PROFILE } from '../../actions/types';
import userProfileState from '../initialState/userProfileState';

const initialState = {
  ...userProfileState,
  fetched: false
};

export default function directoryUserProfile(state = initialState, action) {
  switch (action.type) {
    case SET_DIRECTORY_USER_PROFILE:
      return {
        ...state,
        ...action.directoryUserProfile,
        fetched: true
      };
    case RESET_DIRECTORY_USER_PROFILE:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
