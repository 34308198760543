import React from 'react';

const ScanningAnimation = ({
  jobTitle,
  animationActionStep,
  rawText
}) => {
  return (
    <div className='col-12 col-sm-6'>
      <div className=' shadow p-3 border mb-3'>
        <div className='d-flex justify-content-center'>
          <p
            className='transform-file-title m-0'
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '95%',
              overflow: 'hidden'
            }}
          >
            {jobTitle}
          </p>
        </div>
      </div>

      <div className="ocr-container">
        <div
          className={`ocr ${animationActionStep}`}
        >
          <p>{animationActionStep}</p>
          <em />
          <span />
        </div>

        <div className="raw-text-container">
          <div className="raw-text-content">
            {rawText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanningAnimation;
