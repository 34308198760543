import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { ICON_SETTINGS } from '@apprentage/constants';
import { getLocation, resetLocation } from '../../actions/Locations';
import { withAuthorization } from '../Session';
import { ORG_CONSOLE_DASHBOARD } from '../../constants/routes';
import withOrgConsole from '../App/withOrgConsole';
import Form from '../OrgConsole/Location/Form';
import CdnFiles from '../ManageContent/CdnFiles';
import Loading from '../Loading';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import Tabs from './Tabs';

const OrganizationDefaultLocation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const orgLocation = useSelector((state) => state.orgLocation);
  // Misc
  const { locationId } = params;

  useEffect(() => {
    if (!orgLocation.id && locationId) {
      dispatch(getLocation(locationId));
    }

    return function cleanup() {
      dispatch(resetLocation());
    };
  }, [locationId]);

  if (locationId && !orgLocation.id) {
    return (
      <Loading />
    );
  }

  if (locationId && !orgLocation.id) return null;

  return (
    <div className="row">
      <div className="col-sm-12">

        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Settings"
            icon={ICON_SETTINGS}
            route={ORG_CONSOLE_DASHBOARD}
          />

          <Tabs active="location" />

          <div className="">
            <Form
              data={orgLocation || {}}
            />

            <div className="mt-3 mb-5">
              <CdnFiles
                id="orgLocation-integration-files"
                files={orgLocation.cdnFiles}
                contentId={orgLocation.id}
                contentType="location"
                editMenu
              />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(OrganizationDefaultLocation);
