import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CLASS_CONSOLE_QUIZZES } from '../../../constants/routes';
import { routeWithClassId } from '../../../services/courses';

const Tabs = ({
  className = '',
  active = 'all'
}) => {
  // redux
  const course = useSelector((state) => state.course);
  // misc
  const classId = pathOr(null, ['id'], course);

  return (
    <div className={`${className} bg-light`}>
      <ul className="nav nav-tabs pl-3">
        <li className="nav-item">
          <Link
            className={`nav-link ${active === 'all' ? 'active' : ''} mr-2`}
            to={routeWithClassId({
              route: CLASS_CONSOLE_QUIZZES,
              classId
            })}
            title="All Quizzes"
          >
            All
          </Link>
        </li>
        <li className="nav-item disabled">
          <Link
            to={routeWithClassId({
              route: `${CLASS_CONSOLE_QUIZZES}`,
              classId
            })}
            className={`disabled nav-link ${active === 'outcomes' ? 'active' : ''} d-flex align-items-center`}
            title="Quiz Outcomes"
          >
            <span>
              Outcomes
            </span>
            {/* <span
              className="badge bg-white border px-2 py-1 ml-2"
            >
              {currentAnswers.list && currentAnswers.list.length !== total && `${currentAnswers.list.length} / `}{total}
            </span> */}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
