import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  isAdminOwnerSuperAdmin,
  isSuperAdmin,
  reactSelectDefaultValue,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import { TbAppsFilled, TbTrash } from 'react-icons/tb';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import CircleButton from '@apprentage/components/dist/components/CircleButton';
import { FaArrowLeft, FaThumbtack } from 'react-icons/fa';
import * as aws from '../../../services/aws';
import { getOrg } from '../../../actions/Organizations';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
import { getWidgets } from '../../../actions/Widgets';
import {
  createWidget,
  updateWidget,
  deleteWidget
} from '../../../services/widgets';
import { updateOrganization } from '../../../services/organizations';
import { MODAL_KEY_WIDGET_TYPE } from '../../../constants/modals';
import KeyValueItem from '../../KeyValueItem';
import Modal from '../../Modal';
import WidgetLink from '../../lists/WidgetsList/ListView/WidgetLink';
import ImageUploader from '../../ManageContent/ImageUploader';
import ConfirmationToast from '../../Toasts/ConfirmationToast';
import Switch from '../../Switch';
import './style.css';

const ManageWidget = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  // Organization
  const dashboardWidgetIds = organization?.dashboardWidgetIds || null;
  // Current Modal
  const callbackPrimaryAction = currentModal?.callbackPrimaryAction || null;
  // Can add Widget when filtering "All" || "Featured"
  const filterAll = currentModal?.data?.filterAll || false;
  // Widget
  const app = currentModal?.data?.app || {};
  const type = app?.type || 'link';
  // Local State
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  // Fields
  const [title, setTitle] = useState(app?.title || '');
  const [image, setImage] = useState(app?.image || '');
  const [body, setBody] = useState(app?.body || '');
  const [embedCode, setEmbedCode] = useState(app?.embedCode || '');
  const [linkTarget, setLinkTarget] = useState(app?.linkTarget || '');
  const [url, setUrl] = useState(app?.url || '');
  const [includeEmail, setIncludeEmail] = useState(app?.includeEmail || false);
  const [showTitle, setShowTitle] = useState(app?.showTitle || false);
  const [isFeatured, setIsFeatured] = useState(app?.isFeatured || false);
  const [includeExternalUserId, setIncludeUserExternalId] = useState(
    app?.includeExternalUserId || false
  );
  const [appImagePreview, setClassImagePreview] = useState('');
  const [appImageToDelete, setAppImageToDelete] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  // User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  // Misc
  const editMode = currentModal?.data?.editMode;

  const linkTargetEnum = {
    _self: 'Open in the same tab',
    _blank: 'Open in a new tab'
  };
  const linkTargetOptions = reactSelectOptionsFromEnum(linkTargetEnum);
  const linkTargetDefaultValue = reactSelectDefaultValue(
    linkTarget,
    linkTargetOptions
  );

  const handleClose = () => {
    setShowConfirmation(false);
    dispatch(resetCurrentModal());
  };

  const onImageUploadCallback = (data) => {
    setImage(data?.url);
  };

  const previewFile = (file) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        // convert image file to base64 string
        setClassImagePreview(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setAppImageToDelete(image);
    setImage('');
  };

  const deleteImage = (imageUrl) => {
    /**
     * Only delete image if it's NOT a default image
     *
     * Context:
     * In Onboarding, Tools step, e use hardcoded image values
     * These images are used when creating new Tool (widget type:link) in DB
     * These images live in a S3 bucket called "turbine-content"
     *
     */
    if (imageUrl.includes(`/user-content/${orgId}/`)) {
      // DELETE image from AWS if matches the pattern of
      // storing images in an ORG's folder in the S3 bucket
      aws.deleteFile({
        url: imageUrl,
        orgId
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      type,
      title,
      image: image || null,
      embedCode: embedCode || null,
      body: body || null,
      linkTarget: linkTarget || null,
      url: url || null,
      includeEmail: includeEmail || null,
      includeExternalUserId: includeExternalUserId || null,
      showTitle: showTitle || null,
      isFeatured: isFeatured || null
    };

    setLoading(true);

    if (appImageToDelete) {
      deleteImage(appImageToDelete);
    }

    if (editMode) {
      toast.info(`Updating ${title}...`, { toastId: 'updatingWidgetInfo' });

      updateWidget(dataToSave, app?.id).then(() => {
        if (callbackPrimaryAction) {
          callbackPrimaryAction(type).then(() => {
            toast.dismiss('updatingWidgetInfo');
            toast.success(`${title} Updated!`);
            handleClose();
          });
        }

        dispatch(getWidgets({
          orgId,
          type,
          ...(!filterAll ? { isFeatured: true } : {}),
          ...(!filterAll && type === 'link' ? { sortByIds: dashboardWidgetIds } : {})
        })).then(() => {
          toast.dismiss('updatingWidgetInfo');
          toast.success(`${title} Updated!`);

          handleClose();
        });
      }).catch((error) => {
        toast.error('Something went wrong');
        console.error(error);
      });

      return;
    }

    toast.info(`Adding ${title}...`, { toastId: 'addingWidgetInfo' });

    // Widget: Link (FEATURED)
    if (type === 'link' && isFeatured) {
      createWidget({
        ...dataToSave,
        orgId
      }).then((response) => {
        const newDashboardWidgetIds = [...(dashboardWidgetIds || []), response?.id];
        updateOrganization(
          {
            dashboardWidgetIds: newDashboardWidgetIds
          },
          orgId
        )
          .then(() => {
            dispatch(getOrg({ orgId })).then(() => {
              if (callbackPrimaryAction) {
                callbackPrimaryAction(type, isFeatured).then(() => {
                  toast.dismiss('updatingWidgetInfo');
                  toast.success(`${title} Updated!`);
                  handleClose();
                });
              }
              dispatch(getWidgets({
                orgId,
                type: 'link',
                ...(!filterAll ? {
                  isFeatured: true,
                  sortByIds: newDashboardWidgetIds
                } : {})
              })).then(() => {
                toast.dismiss('updatingWidgetInfo');
                toast.success(`${title} Updated!`);

                handleClose();
              });
            });
          })
          .catch((error) => {
            console.error(error);
          });
      });

      return;
    }

    // Widget: Link (NOT featured)
    if (type === 'link' && !isFeatured) {
      createWidget({
        ...dataToSave,
        orgId
      }).then(() => {
        if (callbackPrimaryAction) {
          callbackPrimaryAction(type).then(() => {
            toast.dismiss('updatingWidgetInfo');
            toast.success(`${title} Updated!`);
            handleClose();
          });
        }
        dispatch(
          getWidgets({
            orgId,
            type: 'link',
            sortByIds: dashboardWidgetIds
          })
        ).then(() => {
          toast.dismiss('addingWidgetInfo');
          toast.success(`${title} Updated!`);

          handleClose();
        });
      });

      return;
    }

    // Widget: Embed
    if (type === 'embed') {
      createWidget({
        ...dataToSave,
        orgId
      }).then(() => {
        dispatch(
          getWidgets({
            orgId,
            type: 'embed',
            ...(!filterAll ? { isFeatured: true } : {})
          })
        ).then(() => {
          toast.dismiss('addingWidgetInfo');
          toast.success(`${title} Updated!`);

          handleClose();
        });
      });
    }
  };

  return (
    <>
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
        visible={currentModal.visible}
        theme="dark"
      >
        <div className="card">
          <form
            id="manage-app"
            name="manage-app"
            onSubmit={handleSubmit}
          >
            <div className="card-header bg-dark text-white">
              <h1 className="h5 m-0 text-capitalize d-flex align-items-center">
                <TbAppsFilled />
                <span className="ml-1">{app?.id ? 'Edit' : 'New'} Widget</span>
                <span className="badge bg-white text-ships-officer ml-2 ">
                  {type}
                </span>
              </h1>
            </div>
            {type === 'link' && (
              <div className="container border-bottom">
                <div
                  id="widget-preview-area"
                  className="row bkg-pattern-light pt-4 pb-2 justify-content-center align-items-center"
                >
                  <WidgetLink
                    className='col-sm-12 col-md-6 mb-3'
                    image={image || ''}
                    title={title || 'Title'}
                    body={body || 'Subtitle'}
                    includeEmail={includeEmail}
                    includeExternalUserId={includeExternalUserId}
                    isFeatured={isFeatured}
                    {...(url ? { url, linkTarget: '_blank' } : {})}
                  />
                </div>
              </div>
            )}
            {!editMode && (
              <div className="container mt-2">
                <div className="d-flex justify-content-start align-items-center">
                  <CircleButton
                    className="shadow mr-3"
                    size="sm"
                    onClick={() => {
                      dispatch(
                        setCurrentModal({
                          key: MODAL_KEY_WIDGET_TYPE
                        })
                      );
                    }}
                  >
                    <FaArrowLeft
                      size={17}
                      className="text-primary"
                    />
                  </CircleButton>
                  <div className="font-weight-bold">Change widget type</div>
                </div>
              </div>
            )}
            {type === 'link' && (
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div
                      id="app-title"
                      className="mb-4"
                    >
                      <label htmlFor="title">
                        <b>Title:</b> <span className="text-danger">*</span>
                      </label>
                      <input
                        data-cy="ManageWidget-input-title"
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setTitle(value);
                        }}
                        defaultValue={title}
                        autoFocus
                        required
                      />
                    </div>
                    <div
                      id="app-body"
                      className="mb-4"
                    >
                      <label htmlFor="body">
                        <b>Subtitle:</b> <span className="text-danger">*</span>
                      </label>
                      <input
                        data-cy="ManageWidget-input-body"
                        type="text"
                        className="form-control"
                        name="body"
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setBody(value);
                        }}
                        defaultValue={body}
                      />
                    </div>
                    <div
                      id="app-image"
                      className=""
                    >
                      <div className="font-weight-bold">Image:</div>
                      <div
                        className={`small text-muted ${appImagePreview || image ? 'mb-2' : ''}`}
                      >
                        Dimensions: 80x80 pixels
                      </div>

                      {(appImagePreview || image) && (
                        <div className="border rounded shadow-sm d-inline-block">
                          <img
                            src={appImagePreview || image}
                            alt="Widget"
                            className="p-2"
                            style={{ width: '80px', height: '80px' }}
                          />
                        </div>
                      )}

                      <div className="d-flex align-items-start flex-column">
                        <ImageUploader
                          inputId="image"
                          name="image"
                          style={{ margin: '.8rem 0' }}
                          hideInputField={Boolean(image)}
                          onImageUploadCallback={onImageUploadCallback}
                          onImagePreviewCallback={previewFile}
                        />

                        {image && (
                          <button
                            className="btn-link px-0 mb-3"
                            onClick={handleRemoveImage}
                            type="button"
                          >
                            Remove Image
                          </button>
                        )}
                      </div>
                    </div>
                    <div>

                      <div className='d-flex align-items-center font-weight-bold'>
                        <FaThumbtack className={isFeatured ? 'text-primary' : 'text-muted'} />
                        <span className='ml-1'>
                          Pinned
                        </span>
                      </div>

                      <div className='mb-2 text-muted'>
                        Show in navigation menu.
                      </div>
                      <div>
                        <div className="bg-light p-2 border rounded mb-2">
                          <Switch
                            id="manageWidget-isFeatured"
                            className="bg-white"
                            label="Pinned"
                            value={isFeatured}
                            onChange={() => {
                              setIsFeatured((prevState) => !prevState);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    {/* <div
                    id="app-type"
                    className="mb-4"
                  >
                    <label htmlFor="type">
                      <b>Type:</b> <span className="text-danger">*</span>
                    </label>
                    <SelectMenu
                      name="type"
                      onChange={({ value }) => {
                        setType(value);
                      }}
                      defaultValue={typeDefaultValue}
                      placeholder="Select type..."
                      options={typeOptions}
                    />
                  </div> */}

                    <div
                      id="app-url"
                      className="mb-4"
                    >
                      <label htmlFor="type">
                        <b>URL:</b> <span className="text-danger">*</span>
                      </label>
                      <input
                        data-cy="ManageWidget-input-url"
                        type="url"
                        className="form-control"
                        name="type"
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setUrl(value);
                        }}
                        defaultValue={url}
                        required
                      />
                      <div className="small text-muted">
                        Ex: https://website.com/...
                      </div>
                    </div>

                    <div
                      id="app-linkTarget"
                      className="mb-4"
                    >
                      <label htmlFor="type">
                        <b>Link target:</b> <span className="text-danger">*</span>
                      </label>
                      <SelectMenu
                        name="linkTarget"
                        onChange={({ value }) => {
                          setLinkTarget(value);
                        }}
                        defaultValue={linkTargetDefaultValue}
                        placeholder="Select type..."
                        options={linkTargetOptions}
                        required
                      />
                    </div>

                    <div>
                      <label>
                        <b>User Data Integration</b>
                        <div className="small text-muted">
                          Include logged in user data in URL parameters
                        </div>
                      </label>
                      <div>
                        <div className="bg-light p-2 border rounded mb-2">
                          <Switch
                            id="includeEmail"
                            className="mb-2 bg-white"
                            label="Include Email"
                            value={includeEmail}
                            onChange={() => {
                              setIncludeEmail(!includeEmail);
                            }}
                          />
                          <Switch
                            id="includeExternalUserId"
                            className="bg-white"
                            label="Include External User ID"
                            value={includeExternalUserId}
                            onChange={() => {
                              setIncludeUserExternalId(!includeExternalUserId);
                            }}
                          />
                        </div>
                        <div className="small text-muted">
                          Ex: https://website.com?externalUserId=...
                        </div>
                      </div>
                      {isSuperAdmin(role) && app?.id && (
                        <KeyValueItem
                          className="mt-1"
                          title="ID"
                          value={app?.id}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {type === 'embed' && (
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div
                      id="app-title"
                      className="mb-4"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <label htmlFor="title">
                          <b>Title:</b> <span className="text-danger">*</span>
                        </label>
                        <Switch
                          id="titleVisibility"
                          className="mb-2 bg-white"
                          label="Show Title"
                          name="titleVisibility"
                          slim
                          value={showTitle}
                          onChange={() => {
                            setShowTitle(
                              (prevStateShowTitle) => !prevStateShowTitle
                            );
                          }}
                        />
                      </div>

                      <input
                        data-cy="ManageWidget-input-title"
                        type="text"
                        className="form-control"
                        name="title"
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setTitle(value);
                        }}
                        defaultValue={title}
                        autoFocus
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      id="app-body"
                      className="mb-4"
                    >
                      <label htmlFor="">
                        <b>Embed Code:</b> <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="<iframe src=..."
                        name="embedCode"
                        onChange={(e) => {
                          const { value } = e.currentTarget;
                          setEmbedCode(value);
                        }}
                        defaultValue={embedCode}
                        required
                      />
                      <div className="small text-muted">
                        Paste embed code from youtube, vimeo, etc.
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {/* <div className="font-weight-bold">
                      Featured
                    </div>
                    <div className='mb-2 small text-muted'>
                      Show on dashboard.
                    </div>
                    <div>
                      <div className="bg-light p-2 border rounded mb-2">
                        <Switch
                          id="isFeatured"
                          className="mb-2 bg-white"
                          label="Featured"
                          value={isFeatured}
                          onChange={() => {
                            setIsFeatured((prevState) => !prevState);
                          }}
                        />
                      </div>
                    </div> */}
                    {isSuperAdmin(role) && app?.id && (
                      <KeyValueItem
                        title="ID"
                        value={app?.id}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="card-footer d-flex align-items-center justify-content-between">
              <div>
                <button
                  className="btn btn-primary btm-sm"
                  type="submit"
                  disabled={loading || deleting}
                >
                  {loading ? 'Saving...' : 'Save'}
                </button>
                <button
                  className="btn btn-link ml-2"
                  type="button"
                  onClick={() => {
                    dispatch(resetCurrentModal());
                  }}
                  disabled={loading || deleting}
                >
                  Cancel
                </button>
              </div>

              {isAdminOwnerSuperAdmin(currentUser?.role) && app?.id && (
                <button
                  type="button"
                  className="btn"
                  disabled={deleting}
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                >
                  <div className="row align-items-center">
                    <TbTrash
                      className="mr-1"
                      color="red"
                    />
                    <div className="text-danger">Delete</div>
                  </div>
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
      {showConfirmation && (
        <ConfirmationToast
          title="Are you sure?"
          subtitle="This action cannot be undone."
          toastType="danger"
          onConfirm={() => {
            toast.info('Processing...', { toastId: 'deleteWidgetInfo' });
            setDeleting(true);
            deleteWidget(app?.id)
              .then(() => {
                if (app?.image) {
                  deleteImage(app?.image);
                }

                dispatch(
                  getWidgets({
                    orgId,
                    type,
                    ...(type === 'link' ? { sortByIds: dashboardWidgetIds } : {})
                  })
                ).then(() => {
                  toast.dismiss('deleteWidgetInfo');
                  toast.success(`${title} Deleted!`);
                  setDeleting(false);
                  handleClose();
                });
              })
              .catch((error) => {
                setDeleting(false);
                console.error(error);
                toast.error('Something went wrong, try again.');
              });
            setShowConfirmation(false);
          }}
          handleCancel={() => {
            setShowConfirmation(false);
          }}
          handleClose={() => {
            setShowConfirmation(false);
          }}
        />
      )}
    </>
  );
};

export default ManageWidget;
