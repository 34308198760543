import React, { useState } from 'react';
import CourseType from '../../Console/Settings/CourseType';

const ChooseCourseType = ({ data, updateState }) => {
  const [currentState, setState] = useState(data);
  const [nextDisabled, setNextDisabled] = useState(true);

  const handleNext = () => {
    updateState({
      ...currentState,
      currentStep: 1
    });
  };

  const handleChange = (newState) => {
    const dataToSave = {
      ...currentState,
      ...newState
    };

    if (dataToSave.enableCourseContent === true) {
      // Create Content
      dataToSave.externalCourseUrl = undefined;
      setNextDisabled(false);
    } else if (dataToSave.enableCourseContent === false && dataToSave.externalCourseUrl !== undefined) {
      // External Course
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }

    setState(dataToSave);
    updateState(dataToSave);
  };

  return (
    <>
      <div className="card-body">
        <CourseType
          showStepTitles
          enableCourseContent={currentState.enableCourseContent}
          setEnableCourseContent={(newEnableCourseContent) => {
            handleChange({ enableCourseContent: newEnableCourseContent });
          }}
          externalCourseUrl={currentState.externalCourseUrl}
          setExternalCourseUrl={(newExternalCourseUrl) => {
            handleChange({ externalCourseUrl: newExternalCourseUrl });
          }}
        />
      </div>
      <div className="card-footer">
        <button
          className="btn btn-sm btn-primary"
          onClick={handleNext}
          type="button"
          disabled={nextDisabled}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ChooseCourseType;
