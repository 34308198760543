import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import Form from './Form';

const ManageInstructionProvider = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Instruction Provider
  const instructionProviderId = currentModal?.data?.instructionProviderId || '';

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-fullscreen turbine-modal--${currentModal?.key}`}
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
      full
    >
      <div className="card w-100">
        <div className="card-header w-100 bg-dark">
          <h1 className="h5 m-0 text-white">
            {instructionProviderId ? 'Edit' : 'Create'} Instruction Provider
          </h1>
        </div>
        <div className="card-body w-100 p-0">
          <Form />
        </div>
      </div>
    </Modal>
  );
};

export default ManageInstructionProvider;
