import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircleButton from '@apprentage/components/dist/components/CircleButton';
import { FaArrowLeft } from 'react-icons/fa';
import { routeWithOrgId } from '../../services/organizations';

const OrgConsoleHeader = ({
  pageTitle,
  route,
  rawRoute,
  badge,
  icon = '',
  iconComponent,
  image,
  children,
  className = '',
  theme = 'light'
}) => {
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  // misc
  const orgId = organization?.id || '';

  const handleClick = () => {
    if (route) {
      history.push(routeWithOrgId({
        route,
        orgId
      }));
    }

    if (rawRoute) {
      history.push(rawRoute);
    }
  };

  return (
    <div
      className={`d-flex align-items-center ${className}`}
    >
      {(route || rawRoute) && (
        <CircleButton
          className="shadow mr-3"
          size='sm'
          onClick={handleClick}
        >
          <FaArrowLeft size={25} className='text-primary' />
        </CircleButton>
      )}

      <div
        className={`card-header bg-${theme} ${theme === 'dark' ? 'text-white' : ''} shadow p-3 d-flex align-items-center justify-content-between flex-fill rounded`}
        style={{
          minHeight: '64px'
        }}
      >
        <div
          className="m-0 d-flex align-items-center h5"
        >
          {image && (
            <img
              src={image}
              className='mr-2'
              alt="Logo"
              height="30"
              width="30"
              style={{
                height: '30px',
                width: '30px'
              }}
            />
          )}

          {!image && icon && (
            <i className={`${icon} mr-2`} aria-hidden="true" />
          )}

          {!image && iconComponent && iconComponent()}

          <div className="ml-2 d-flex align-items-center">
            <span>{pageTitle}</span>
            {badge && badge()}
          </div>
        </div>

        {children || null}

      </div>
    </div>
  );
};

export default OrgConsoleHeader;
