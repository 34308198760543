import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table'; // useFilters

function Table({
  columns,
  data,
  handleClickRow
  // hiddenColumns
}) {
  // const initialState = {
  //   sortBy: [
  //     {
  //       id: 'description',
  //       desc: false
  //     }
  //   ]
  //   // hiddenColumns
  // };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      // initialState,
      data
    },
    useSortBy
  );

  const onClickRow = (row) => {
    const { original } = row;
    handleClickRow(original);
  };

  return (
    <table
      className='table table-bordered m-0'
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return column.hideHeader ? null : (
                <th
                  className='text-nowrap bg-light py-1'
                  style={{ verticalAlign: 'center' }}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  {column.canSort && (
                    <span className='ml-1'>
                      {column.isSorted ? (
                        <Fragment>
                          {column.isSortedDesc ? (
                            <i className='fas fa-sort-down' />
                          ) : (
                            <i className='fas fa-sort-up' />
                          )}
                        </Fragment>
                      ) : (
                        <i className='fas fa-sort' style={{ opacity: '.25' }} />
                      )}
                    </span>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              onClick={() => {
                if (handleClickRow) {
                  onClickRow(row);
                }
              }}
              className={handleClickRow ? 'row-clickable' : ''}
            >
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Table;

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape())
};
