export const apprenticeshipTypesEnum = {
  CB: 'Competency-Based',
  TB: 'Time-Based',
  H: 'Hybrid'
};

export const createTrainingEnum = {
  CLONE_SUCCESSFUL_TRAINING: 'CLONE_SUCCESSFUL_TRAINING',
  BUILD_YOUR_OWN_TRAINING: 'BUILD_YOUR_OWN_TRAINING'
};
