import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import {
  ICON_CHALLENGE,
  ICON_MATERIAL,
  ICON_QUIZ,
  ICON_TOPIC,
  ICON_TRIANGLE_BANG
} from '@apprentage/constants';
import { manageEntry } from '../../services/entry';
import { canManageTopicPrereqs } from '../../services/currentUser';
import { createTopicInClass, updateCurrentTopic } from '../../services/topics';
import { routeWithOrgId } from '../../services/organizations';
import { setCurrentTopic, getPrerequisiteTopics } from '../../actions/Topic';
import { setCurrentClass } from '../../actions/Class';
import {
  MANAGE_CURRENT_ENTRY, ORG_CONSOLE_DASHBOARD
} from '../../constants/routes';
import isPublished from '../../utils/isPublished';
import RadioGroup from '../ManageContent/RadioGroup';
import References from '../ManageContent/References';
import EmptyData from '../ManageContent/EmptyData';
import ReferencesDnd from '../ManageContent/ReferencesDnd';
import EntryButton from '../btns/EntryButton';
import SelectMenu from '../ManageContent/SelectMenu';
import Alert from '../Alert';
import TagInput from '../ManageContent/TagInput';
import RenderTinyMceEditor from '../ManageContent/Wysiwyg/RenderTinyMceEditor';

const Form = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const data = useSelector((state) => state.currentTopic);
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const courseTopics = useSelector((state) => state.courseTopics);
  // Local State
  const [loading, setLoading] = useState(false);
  const [prerequisiteId, setPrerequisiteId] = useState(isPublished(data.prerequisite) ? data.prerequisite.sys.id : null);
  const [locked, setLocked] = useState(() => {
    if (data.locked && !isPublished(data.prerequisite)) {
      return false;
    }

    return data.locked;
  });
  const [featured, setFeatured] = useState(data.featured || false);
  const [title, setTitle] = useState(data.title || '');
  const [sections, setSections] = useState(data.sections || null);
  const [level, setLevel] = useState(data.level || null);
  const [category, setCategory] = useState(data.category || null); // categories
  const [topicHeader, setTopicHeader] = useState(data?.topicHeader || null);
  // Not editable RN
  const [challenges] = useState(data.challenges || null);
  const [quizes] = useState(data.quizes || null); // TODO change to quizzes in data model
  const [prerequisite] = useState(data.prerequisite || null);
  // Query Params
  const queryParams = new URLSearchParams(window.location.search);
  const fallbackReferenceIndex = Array.isArray(course?.topicIds) && data?.id ? course?.topicIds.indexOf(data?.id) : null;
  const referenceIndex = queryParams.get('referenceIndex') || fallbackReferenceIndex;
  const topicId = queryParams.get('topicId');
  const redirectUrl = queryParams.get('redirectUrl'); // TODO change to redirectRoute
  // Misc
  const orgId = organization?.id || '';
  const role = currentUser?.role || [];
  const classId = course?.id || '';
  const orgConsoleDashboardRoute = routeWithOrgId({
    route: ORG_CONSOLE_DASHBOARD,
    orgId
  });
  const canManageTopicPrerequisites = canManageTopicPrereqs(role);

  // EDITOR
  const descriptionRef = useRef(null);
  const [description, setDescription] = useState(data.description || '');

  const instructionNotesRef = useRef(null);
  const [instructionNotes, setInstructionNotes] = useState(data.instructionNotes || '');

  // NEW EDITOR
  const [enableNewEditor] = useState(true);

  const convertOldEditorToNewEditor = () => {
    const converter = new Showdown.Converter();

    const descriptionHTML = converter.makeHtml(data.description);
    setDescription(descriptionHTML);

    const instructionNotesHTML = converter.makeHtml(data.instructionNotes);
    setInstructionNotes(instructionNotesHTML);
  };

  useEffect(() => {
    // If first time loading
    // If the new editor is not enabled
    if (data.id && !data.enableNewEditor) {
      console.log('run conversion');
      convertOldEditorToNewEditor();
    }
  }, []);
  // /NEW EDITOR

  useEffect(() => {
    if (data.id) {
      dispatch(getPrerequisiteTopics({ topicId: data.id }));
    }
  }, [data.id, dispatch]);

  const showSuccess = () => {
    toast.success('Topic updated!');
    setLoading(false);
  };

  const handleCancel = () => {
    history.push(`${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=class&classId=${classId}`);
  };

  const createTopic = () => {
    const dataToSave = {
      locked,
      featured,
      classId,
      title,
      enableNewEditor
    };

    createTopicInClass({ data: dataToSave }).then((newTopic) => {
      dispatch(setCurrentClass({ classId })).then(() => {
        const searchParams = manageEntry({
          manageType: 'edit',
          // referenceIndex: manageType === 'insertBelow' ? parseInt(referenceIndex, 10) + 1 : referenceIndex,
          classId,
          topicId: newTopic.sys.id,
          entryId: newTopic.sys.id, // TODO rm, shouldn't be needed
          contentType: 'topic'
        });

        // Update URL to match same structure as if User was editing an existing Topic
        history.replace(MANAGE_CURRENT_ENTRY + searchParams);
      });
    });
  };

  const editTopic = () => {
    const dataToSave = {
      title,
      instructionNotes,
      featured,
      locked,
      classId,
      topicId,
      sectionIds: null,
      sections,
      category,
      level,
      prerequisiteId,
      enableNewEditor,
      topicHeader
    };

    if (Array.isArray(sections) && sections.length > 0) {
      dataToSave.sectionIds = sections.map((section) => section?.sys?.id);
    }

    if (descriptionRef && descriptionRef.current && descriptionRef.current.isDirty) {
      dataToSave.description = descriptionRef.current.getContent();
    }

    if (instructionNotesRef && instructionNotesRef.current && instructionNotesRef.current.isDirty) {
      dataToSave.instructionNotes = instructionNotesRef.current.getContent();
    }

    if (locked && !prerequisiteId) {
      // User chose to lock topic, but did not select a prerequisite
      toast.error('Locked topics require a prerequisite, please select a topic.', {
        autoClose: false,
        toastId: 'lockedNoPrereq'
      });

      setLoading(false);

      return false;
    }

    if (!locked) {
      // Remove prerequisite
      dataToSave.prerequisiteId = null;
    }

    updateCurrentTopic(dataToSave).then((updatedTopic) => {
      dispatch(setCurrentClass({ classId })).then(() => {
        dispatch(setCurrentTopic({ topicId: updatedTopic.sys.id }));
        showSuccess();
      });
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (data.id) {
      editTopic();
    } else {
      createTopic();
    }
  };

  const emptyDataOptions = [
    {
      contentType: 'material',
      subtitle: 'Use Markdown, text, HTML and images to illustrate the main points of this Topic.',
      icon: ICON_MATERIAL,
      importJSON: true,
      addResourceMaterial: true
    },
    {
      contentType: 'challenge',
      subtitle: 'A question that requires students to submit an answer and teachers to review.',
      icon: ICON_CHALLENGE
    },
    {
      contentType: 'quiz',
      subtitle: 'Multiple choice questions that students answer and scored automatically.',
      icon: ICON_QUIZ
    }
  ];

  // Topic Pacing (locked) | Prerequisite
  const topicsExcludeCurrent = courseTopics.list && courseTopics.list.filter((t) => t.id !== topicId);
  const prereqOptions = topicsExcludeCurrent && topicsExcludeCurrent.length && topicsExcludeCurrent.map((t) => {
    return {
      value: t.id,
      label: t.title
    };
  });

  const controlFlowShowYes = !!((referenceIndex === undefined || referenceIndex > 0) && prereqOptions && prereqOptions.length !== 0);

  return (
    <div className="card mt-3">
      <form onSubmit={onSubmit}>
        <div
          className="card-header p-3 d-flex align-items-center justify-content-between"
          style={{ backgroundColor: '#e9ecef' }}
        >
          <h5 className="m-0 text-capitalize d-flex align-items-center">
            <i className={`${ICON_TOPIC} mr-2`} aria-hidden="true" /> {data.id && title ? title : 'New Topic'}
          </h5>
          {data.id && (
            <EntryButton
              title="Preview"
              classId={classId}
              topicId={topicId}
              entryId={sections && sections.length >= 1 ? sections[0].sys.id : undefined}
            />
          )}
        </div>
        <div className="card-body">
          <div id="topic-title" className="mb-4">
            <label htmlFor="title">
              <b>Title:</b> <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control mb-4"
              name="title"
              defaultValue={title}
              onChange={(e) => {
                const { value } = e.currentTarget;

                setTitle(value);
              }}
              autoFocus={!data.id}
              required
            />
          </div>

          {data.id && (
            <div className="card mb-4">
              <div className="card-header">
                <h5 className="m-0 text-capitalize">
                  Topic Content
                </h5>
              </div>
              <div className="card-content">
                <div className="list-group list-group-flush">
                  <div id="topic-sections" className="py-4 list-group-item">
                    {sections && (
                      <ReferencesDnd
                        title="Sections"
                        items={sections}
                        onUpdate={(updatedSections) => {
                          setSections(updatedSections);
                        }}
                        required
                        // References props
                        classId={classId}
                        topicId={topicId}
                        searchParams={queryParams}
                        editContent
                      />
                    )}
                    <EmptyData
                      disabled={!data.id}
                      className="mt-3"
                      options={emptyDataOptions}
                      data={sections}
                      classId={classId}
                      topicId={topicId}
                      manageButton
                    />
                  </div>

                  <div id="class-category" className="py-4 list-group-item">
                    <label>
                      <b>Topic Header:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      Appears above Topic's Title in Left Side Course Navigation.
                    </div>
                    <input
                      type="text"
                      className="form-control mb-4"
                      name="topicHeader"
                      defaultValue={topicHeader}
                      onChange={(e) => {
                        const { value } = e.currentTarget;

                        setTopicHeader(value);
                      }}
                    />
                  </div>

                  <div id="class-category" className="py-4 list-group-item">
                    <label>
                      <b>Category:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      Simplified tags describing the contents of this Topic.
                    </div>
                    <TagInput
                      id="category"
                      name="category"
                      tags={category || []}
                      onChange={({ category: newCategoryTags }) => {
                        // null converted to undefined when data is formatted before sent to BE
                        setCategory((newCategoryTags.length === 0) ? null : newCategoryTags);
                      }}
                    />
                  </div>

                  <div id="class-level" className="py-4 list-group-item">
                    <label>
                      <b>Level:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      Indicate the difficulty of the contents of this Topic.
                    </div>
                    <TagInput
                      id="level"
                      name="level"
                      tags={level || []}
                      onChange={({ level: newLevelTags }) => {
                        // null converted to undefined when data is formatted before sent to BE
                        setLevel((newLevelTags.length === 0) ? null : newLevelTags);
                      }}
                    />
                    <div className="text-muted mt-2">
                      Ex: Basic, Intermediate, Advanced
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}

          {data.id && (
            <div id="topic-instructionNotes" className="card mb-4">
              <div className="card-header">
                <h5 className="m-0 text-capitalize">
                  Instruction Notes
                </h5>
              </div>
              <div className="card-body">
                {enableNewEditor && (
                  <RenderTinyMceEditor
                    id="topic-instructionNotes"
                    contentId={data.id}
                    contentType="topic"
                    // className="list-group-item"
                    title="Lesson Plan"
                    subtitle="Save time and provide invaluable guidance by adding teaching scripts, talking points and important takeaways for this Topic."
                    defaultValue={instructionNotes}
                    editorRef={instructionNotesRef}
                    // uploadImgButton={false}
                    uploadPdfButton={false}
                  // embedMediaButton={false}
                  // maxCharCount={5000}
                  />
                )}
              </div>
            </div>
          )}

          {data.id && (
            <div id="topic-class-dashboard" className="card mb-4">
              <div className="card-header">
                <h5 className="m-0 text-capitalize">
                  Class Dashboard
                </h5>
              </div>
              <div className="card-body">
                <div id="topic-featured" className="form-group mb-4">
                  <label className="mb-1">
                    <b>Featured:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Select Yes to feature this Topic on the Class Dashboard.
                  </div>
                  <RadioGroup
                    name="featured"
                    value={featured}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setFeatured(value === 'true');
                    }}
                  />
                </div>

                <div id="topic-description" className="m-0">
                  {enableNewEditor && (
                    <RenderTinyMceEditor
                      id="course-description"
                      contentId={data.id}
                      contentType="topic"
                      // className="list-group-item"
                      title="Short Description"
                      subtitle="Topic descriptions are visible on the Class dashboard."
                      defaultValue={description}
                      editorRef={descriptionRef}
                      uploadImgButton={false}
                      uploadPdfButton={false}
                      embedMediaButton={false}
                      maxCharCount={5000}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {data.id && (
            <div id="topic-control-flow" className="card mb-3">
              <div className="card-header">
                <h5 className="m-0 text-capitalize">
                  Topic Pacing
                </h5>
              </div>
              <div
                className={`${canManageTopicPrerequisites ? '' : 'opacity-50 pointer-events-none'} card-body`}
              >
                <div id="topic-locked" className="form-group mb-3">
                  <label className="mb-1">
                    <b>Locked:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Prevent users from accessing this Topic until any Topics that list it as a Prerequisite are completed successfully.
                  </div>
                  <RadioGroup
                    name="locked"
                    value={locked}
                    showYes={controlFlowShowYes}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setLocked(value === 'true');

                      if (value === 'false') {
                        toast.dismiss('lockedNoPrereq'); // dismiss toast, if visible
                      }
                    }}
                    disabled={!canManageTopicPrerequisites}
                  />
                </div>

                {locked && prereqOptions && prereqOptions.length && (
                  <div
                    id="topic-prerequisite"
                    className="my-4 position-relative"
                    style={{ zIndex: 2 }}
                  >
                    <label className="mb-1">
                      <b>Prerequisite:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      This topic will unlock once the Topic below is completed successfully.
                    </div>

                    {canManageTopicPrerequisites ? (
                      <SelectMenu
                        id="prerequisiteId"
                        name="prerequisiteId"
                        options={prereqOptions}
                        isMulti={false}
                        defaultValue={prerequisiteId && prereqOptions.find((option) => prerequisiteId === option.value)}
                        onChange={({ prerequisiteId: newPrerequisiteId }) => {
                          if (newPrerequisiteId) {
                            toast.dismiss('lockedNoPrereq'); // dismiss toast if visible
                          }

                          setPrerequisiteId(newPrerequisiteId);
                        }}
                      />
                    ) : (
                      <References
                        contents={[prerequisite]}
                        classId={classId}
                        topicId={topicId}
                        searchParams={queryParams}
                      />
                    )}
                  </div>
                )}

                {referenceIndex === '0' && locked && (
                  <Alert
                    type="danger"
                    icon={ICON_TRIANGLE_BANG}
                    className="m-0 w-100 border"
                  >
                    <div>
                      <h6>
                        Topic Pacing Error
                      </h6>

                      <hr className="mt-0" />

                      The first topic in a course can <b>NOT</b> be locked or it will prevent users from properly accessing this course.

                      <h6 className="mt-2 font-weight-bold">
                        Steps to fix:
                      </h6>
                      <ul className="mb-0">
                        <li>
                          Click "No" then click "Save"
                        </li>
                      </ul>
                      <ul className="mb-0" style={{ listStyleType: 'none' }}>
                        <li>
                          or
                        </li>
                      </ul>
                      <ul>
                        <li>
                          Reorder this topic to NOT be first topic in the course.
                        </li>
                      </ul>
                    </div>
                  </Alert>
                )}

                {challenges && (
                  <div id="topic-challenges" className="my-4">
                    <label className="mb-1">
                      <b>Challenges:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      This Topic will be completed successfully when an accepted Answer is submitted to all Challenges below.
                    </div>
                    <References
                      contents={challenges}
                      classId={classId}
                      topicId={topicId}
                      searchParams={queryParams}
                    />
                  </div>
                )}

                {quizes && (
                  <div id="topic-quizzes" className="my-4">
                    <label className="mb-1">
                      <b>Quizzes:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      This Topic will be completed successfully when a successful Quiz Outcome is submitted to all Quizzes below.
                    </div>
                    <References
                      contents={quizes}
                      classId={classId}
                      topicId={topicId}
                      searchParams={queryParams}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="card-footer d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="submit"
              disabled={!!loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            {redirectUrl ? (
              <Link
                className="btn btn-md btn-outline-primary"
                to={orgConsoleDashboardRoute}
                title="Cancel"
              >
                Cancel
              </Link>
            ) : (
              <button
                className="btn btn-sm btn-link"
                onClick={handleCancel}
                title="Cancel"
                type="button"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
