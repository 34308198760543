import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CLASS_CONSOLE_CHALLENGES_SUBMISSIONS } from '../../../../constants/routes';
import { routeWithClassId } from '../../../../services/courses';

const CourseCell = ({ value: classId }) => {
  const course = useSelector((state) => state.course);
  const courses = useSelector((state) => state.courses);

  let answerCourse = course;

  if (!course?.id && classId && Array.isArray(courses.list)) {
    answerCourse = courses.list.find((c) => c.id === classId) || null;
  }

  return (
    <Link
      to={routeWithClassId({
        route: CLASS_CONSOLE_CHALLENGES_SUBMISSIONS,
        classId
      })}
      title={`Edit ${answerCourse.title}`}
      className='btn-link'
      style={{
        maxWidth: '200px',
        textOverflow: 'ellipsis'
      }}
    >
      {answerCourse.title}
    </Link>
  );
};

export default CourseCell;
