import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CalendarDatePicker from '@apprentage/components/dist/components/CalendarDatePicker';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import {
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromEnum
} from '@apprentage/utils';
import {
  USER_GENDER_ENUM, // genderEnum
  USER_EMPLOYMENT_ENUM, // employmentStatusEnum
  USER_EDUCATION_ENUM, // educationLevelEnum
  USER_DISABILITY_ENUM, // disabilityEnum
  USER_ETHNICITY_ENUM, // ethnicityEnum
  USER_RACE, // raceEnum
  USER_VETERAN_ENUM, // veteranStatusEnum
  USER_REFERRAL_ENUM, // orgReferralEnum
  USER_CITIZEN_ENUM, // citizenStatusEnum
  USER_CRIMINAL_BKG_ENUM, // criminalBkg
  LETS_ENCRYPT_LOGO
} from '@apprentage/constants';
import { FaCheckCircle, FaLock } from 'react-icons/fa';
import { FaFileSignature } from 'react-icons/fa6';
import { getDirectoryUser, resetDirectoryUserProfile } from '../../../../actions/Directory';
import { getCurrentUser } from '../../../../actions/Users';
import { updateUserProfile, createUserProfile } from '../../../../services/userProfiles';
import { formatSSN, obfuscateSSN } from '../../../../utils/ssn';
import { decodePayload, encodePayload } from '../../../../utils/payload';
import { canManageUserPII } from '../../../../services/currentUser';
import { updateUser } from '../../../../services/user';
import PrivacyPolicyLink from '../../../Legal/PrivacyPolicyLink';
import SecurityPolicyLink from '../../../Legal/SecurityMeasuresLink';
import Address from '../../../ManageContent/Address';
import UserSignatureBlock from '../../../UserSignatureBlock';
import { goToElem } from '../../../../services/manageContent';
// import { MODAL_KEY_APPRENTICE_INFO } from '../../../constants/modal';
import './style.css'; // TODO use styled components

const PrivateInfoForm = ({
  handleCancel
}) => {
  const dispatch = useDispatch();
  const ssnRef = useRef(null);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const directoryUser = useSelector((state) => state.directoryUser);
  const directoryUserProfile = useSelector((state) => state.directoryUserProfile);
  // Directory User Profile
  const data = directoryUserProfile || {};
  const userProfileId = data?.id || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [ssn, setSSN] = useState(data.ssn || '');
  const [showObfuscatedSSN, setShowObfuscatedSSN] = useState(data.ssn);
  // Address
  const [addressLine1, setAddressLine1] = useState(data.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(data.addressLine2 || '');
  const [addressCity, setAddressCity] = useState(data.addressCity || '');
  const [addressState, setAddressState] = useState(data.addressState || '');
  const [addressPostalCode, setAddressPostalCode] = useState(data.addressPostalCode || '');
  const [addressCountry, setAddressCountry] = useState(data.addressCountry || '');
  // /Address
  const [dob, setDob] = useState(data?.dob || '');
  const [gender, setGender] = useState(data?.gender);
  const [educationLevel, setEducationLevel] = useState(data?.educationLevel || '');
  const [employmentStatus, setEmploymentStatus] = useState(data?.employmentStatus || '');
  const [employmentStatusDate, setEmploymentStatusDate] = useState(data?.employmentStatusDate || '');
  const [disability, setDisability] = useState(data?.disability || '');
  const [ethnicity, setEthnicity] = useState(data?.ethnicity || '');
  const [races, setRaces] = useState(data?.races || null);
  const [veteranStatus, setVeteranStatus] = useState(data?.veteranStatus || '');
  const [orgReferral, setOrgReferral] = useState(data?.orgReferral || '');
  const [citizenStatus, setCitizenStatus] = useState(data?.citizenStatus || '');
  const [criminalBkg, setCriminalBkg] = useState(data?.criminalBkg || '');
  // Directory User
  const [userSignature, setUserSignature] = useState(directoryUser?.userSignature || '');
  // Organization
  const orgId = organization?.id || null;
  const orgName = organization?.name || null;
  const enableWorkforceSuite = organization?.enableWorkforceSuite || null;
  // Current User
  const userId = currentUser?.id || null;
  const userName = currentUser?.name || null;
  const role = currentUser?.role || [];
  const canEditSSN = canManageUserPII(role, enableWorkforceSuite) || directoryUser?.id === userId;

  // Gender
  const genderOptions = reactSelectOptionsFromEnum(USER_GENDER_ENUM);
  const genderDefaultValue = reactSelectDefaultValue(gender, genderOptions);
  // Employment Status
  const employmentStatusOptions = reactSelectOptionsFromEnum(USER_EMPLOYMENT_ENUM);
  const employmentStatusDefaultValue = reactSelectDefaultValue(employmentStatus, employmentStatusOptions);
  // Education Level
  const educationLevelOptions = reactSelectOptionsFromEnum(USER_EDUCATION_ENUM);
  const educationLevelDefaultValue = reactSelectDefaultValue(educationLevel, educationLevelOptions);
  // Disability
  const disabilityOptions = reactSelectOptionsFromEnum(USER_DISABILITY_ENUM);
  const disabilityDefaultValue = reactSelectDefaultValue(disability, disabilityOptions);
  // Ethnicity
  const ethnicityOptions = reactSelectOptionsFromEnum(USER_ETHNICITY_ENUM);
  const ethnicityDefaultValue = reactSelectDefaultValue(ethnicity, ethnicityOptions);
  // Races
  const racesOptions = reactSelectOptionsFromEnum(USER_RACE);
  const racesDefaultValues = reactSelectDefaultValues(races, racesOptions);
  // Veteran Status
  const veteranStatusOptions = reactSelectOptionsFromEnum(USER_VETERAN_ENUM);
  const veteranStatusDefaultValue = reactSelectDefaultValue(veteranStatus, veteranStatusOptions);
  // Organization Referral
  const orgReferralOptions = reactSelectOptionsFromEnum(USER_REFERRAL_ENUM);
  const orgReferralDefaultValue = reactSelectDefaultValue(orgReferral, orgReferralOptions);
  // Citizenship Status
  const userCitizenStatusOptions = reactSelectOptionsFromEnum(USER_CITIZEN_ENUM);
  const userCitizenDefaultValue = reactSelectDefaultValue(citizenStatus, userCitizenStatusOptions);
  // Criminal Background
  const criminalBkgOptions = reactSelectOptionsFromEnum(USER_CRIMINAL_BKG_ENUM);
  const criminalBkgDefaultValue = reactSelectDefaultValue(criminalBkg, criminalBkgOptions);

  useEffect(() => {
    // Only show if user is apprentice, they don't have a profile
    // and the logged in users is editing their user information
    // if (isApprentice && !userProfileId && userId === directoryUser?.id) {
    //   dispatch(setCurrentModal({
    //     key: MODAL_KEY_APPRENTICE_INFO
    //   }));
    // }

    return function cleanup() {
      dispatch(resetDirectoryUserProfile());
    };
  }, []);

  useEffect(() => {
    if (data.ssn) {
      decodePayload(data?.ssn).then((responseDecodedSSN) => {
        setSSN(responseDecodedSSN);
        setShowObfuscatedSSN(true);
      });
    }
  }, [data?.ssn]);

  if (!data?.id) {
    return null;
  }

  const onCancel = () => {
    dispatch(resetDirectoryUserProfile());
    handleCancel();
  };

  const handleUserAddressOnChange = (e) => {
    const { name, value } = e.currentTarget;

    if (name === 'line1') {
      setAddressLine1(value);
    }

    if (name === 'line2') {
      setAddressLine2(value);
    }

    if (name === 'city') {
      setAddressCity(value);
    }

    if (name === 'state') {
      setAddressState(value);
    }

    if (name === 'postal_code') {
      setAddressPostalCode(value);
    }

    if (name === 'country') {
      setAddressCountry(value);
    }
  };

  const handleSaveSuccessCallback = () => {
    toast.success('Profile saved!');
    if (directoryUser?.id === userId) {
      dispatch(getCurrentUser({ userId: directoryUser?.id })).then(() => {
        setLoading(false);
        onCancel();
      });
    }

    dispatch(getDirectoryUser({ orgId, userId: directoryUser?.id })).then(() => {
      if (directoryUser?.id !== userId) {
        // Don't reset form twice if editing current user in directory
        setLoading(false);
        onCancel();
      }
    });
  };

  const handleSaveErrorCallback = () => {
    setLoading(false);
    toast.error('Something went wrong, try again.');
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (currentUser?.id === directoryUser?.id) {
      if (!userSignature) {
        toast.error('Your signature is required.');
        goToElem('UserSignatureBlock');
        return false;
      }

      if (!ssn) {
        toast.error('Social Security Number is required.');
        ssnRef.current.focus();
        return false;
      }

      if (!ssn || ssn.length !== 11) {
        toast.error('Enter a valid Social Security Number.');
        ssnRef.current.focus();
        return false;
      }
    }

    setLoading(true);
    toast.info('Saving...');

    const dataToSave = {
      addressLine1: addressLine1 ? addressLine1.trim() : null,
      addressLine2: addressLine2 ? addressLine2.trim() : null,
      addressCity: addressCity ? addressCity.trim() : null,
      addressState: addressState || null,
      addressPostalCode: addressPostalCode ? addressPostalCode.trim() : null,
      addressCountry: addressCountry || null,
      ssn: ssn ? encodePayload(ssn) : null, // TODO logic: is currentUser or Owner
      gender: gender || null,
      dob: dob || null,
      employmentStatus: employmentStatus || null,
      employmentStatusDate: employmentStatusDate || null,
      educationLevel: educationLevel || null,
      disability: disability || null,
      ethnicity: ethnicity || null,
      races: races || null,
      veteranStatus: veteranStatus || null,
      orgReferral: orgReferral || null,
      citizenStatus: citizenStatus || null,
      criminalBkg: criminalBkg || null
    };

    if (userProfileId) {
      updateUserProfile(dataToSave, userProfileId).then(() => {
        handleSaveSuccessCallback();
      }).catch(() => {
        handleSaveErrorCallback();
      });
    } else {
      createUserProfile({
        ...dataToSave,
        orgId: directoryUser?.orgId,
        userId: directoryUser?.id,
        locationId: directoryUser?.locationId
      }).then((response) => {
        if (response?.id) {
          updateUser({
            userProfileId: response?.id
          }, directoryUser?.id).then(() => {
            handleSaveSuccessCallback();
          });
        } else {
          handleSaveErrorCallback();
          console.error('create user profile - no docId returned');
        }
      }).catch(() => {
        handleSaveErrorCallback();
      });
    }
  };

  return (
    <form
      className="edit-user-details-form"
      onSubmit={onSubmit}
    >
      <div
        className='card-body'
        style={{
          overflowY: 'scroll',
          height: 'calc(100vh - 170px)'
        }}
      >
        <div className='small mb-3'>
          <FaLock className='d-inline' />
          <span className='ml-1'>
            Your private information is used for mandatory reporting on official government documents. Read more about our <SecurityPolicyLink />.
          </span>
        </div>

        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="dob" className='d-block'>
              Date of Birth <span className="text-danger">*</span>
            </label>
            <CalendarDatePicker
              selected={dob ? new Date(dob) : null}
              id='dob'
              name='dob'
              className="form-control"
              placeholderText="MM/DD/YYYY"
              onChange={(date) => {
                setDob(date ? date.toISOString() : '');
              }}
              dropdownMode
              required
            />
          </div>

          <div className="col-sm-6">
            <label className="mb-2 d-block">
              Gender <span className="text-danger">*</span>
            </label>
            <SelectMenu
              name="gender"
              options={genderOptions}
              defaultValue={genderDefaultValue}
              required
              onChange={({ value }) => {
                setGender(value);
              }}
            />
          </div>
        </div>

        <div className='mb-3'>
          <label className="mb-2 d-block">
            Education Level <span className="text-danger">*</span>
          </label>
          <SelectMenu
            name="educationLevel"
            options={educationLevelOptions}
            defaultValue={educationLevelDefaultValue}
            required
            onChange={({ value }) => {
              setEducationLevel(value);
            }}
          />
        </div>

        <Address
          title="Address"
          className='my-4'
          address={{
            line1: addressLine1,
            line2: addressLine2,
            city: addressCity,
            state: addressState,
            postal_code: addressPostalCode,
            country: addressCountry
          }}
          editMode
          onChange={handleUserAddressOnChange}
          inputFieldStyle={{ padding: '5px 7px' }}
          autoFocus={false}
          cardStyle
        />

        <div className="row mb-3">
          <div className="col-sm-6">
            <label className="mb-2 d-block">
              Disability <span className="text-danger">*</span>
            </label>
            <SelectMenu
              name="disability"
              options={disabilityOptions}
              defaultValue={disabilityDefaultValue}
              required
              onChange={({ value }) => {
                setDisability(value);
              }}
            />
          </div>

          <div className="col-sm-6">
            <label className="mb-2 d-block">
              Criminal Background <span className="text-danger">*</span>
            </label>
            <SelectMenu
              name="criminalBkg"
              options={criminalBkgOptions}
              defaultValue={criminalBkgDefaultValue}
              required
              onChange={({ value }) => {
                setCriminalBkg(value);
              }}
            />
          </div>
        </div>

        <div className='card rounded p-3 mb-4'>
          <div className="row">
            <div className={`${employmentStatus === 'ue' ? 'col-sm-6' : 'col-12 '}`}>
              <label className="mb-2 d-block">
                Employment Status <span className="text-danger">*</span>
              </label>
              <SelectMenu
                name="employmentStatus"
                options={employmentStatusOptions}
                defaultValue={employmentStatusDefaultValue}
                required
                onChange={({ value }) => {
                  setEmploymentStatus(value);

                  if (value !== 'ue') {
                    setEmploymentStatusDate('');
                  }
                }}
              />
            </div>

            {employmentStatus === 'ue' && (
              <div className="col-sm-6 mt-3 mt-md-0">
                <label htmlFor="employmentStatusDate">
                  Last Employment Date <span className="text-danger">*</span>
                </label>
                <CalendarDatePicker
                  selected={employmentStatusDate ? new Date(employmentStatusDate) : null}
                  id='employmentStatusDate'
                  name='employmentStatusDate'
                  className="form-control"
                  placeholderText="MM/DD/YYYY"
                  onChange={(date) => {
                    setEmploymentStatusDate(date ? date.toISOString() : '');
                  }}
                  dropdownMode
                  required
                />
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          <div className='d-flex align-items-center justify-content-between'>
            <label htmlFor="title">
              Social Security Number <span className="text-danger">*</span>
            </label>
            <img
              src={LETS_ENCRYPT_LOGO}
              className="opacity-50"
              alt="Let's Encrypt"
              height="32"
              style={{
                height: '32px'
              }}
            />
          </div>

          {!showObfuscatedSSN && (
            <input
              data-demo="disabled"
              ref={ssnRef}
              type="text"
              name="ssn"
              onChange={(e) => {
                const { value } = e.currentTarget;
                const formattedSSN = formatSSN(value);

                setSSN(formattedSSN);
              }}
              onKeyUp={(e) => {
                const { value } = e.currentTarget;

                if (value.length === 11) {
                  setShowObfuscatedSSN(true);
                }
              }}
              onBlur={(e) => {
                const { value } = e.currentTarget;

                if (!showObfuscatedSSN && value.length === 11) {
                  setShowObfuscatedSSN(true);
                }
              }}
              className="form-control"
              value={ssn}
            />
          )}

          {showObfuscatedSSN && (
            <div className='position-relative'>
              <input
                data-demo="disabled"
                readOnly
                disabled
                type="text"
                name="ssn2"
                className="form-control pointer-events-none"
                value={obfuscateSSN(ssn)}
              />

              {canEditSSN && (
                <button
                  className='btn btn-sm btn-primary position-absolute py-1 px-2'
                  style={{
                    right: '4px',
                    top: '4px'
                  }}
                  type="button"
                  tabIndex="-1"
                  onClick={() => {
                    setShowObfuscatedSSN(false);
                    setTimeout(() => {
                      ssnRef.current.focus();
                    }, 250);
                  }}
                >
                  <span className='small'>
                    View/Change
                  </span>
                </button>
              )}
            </div>
          )}

          <div className="small mt-1">
            <FaLock className='d-inline mr-1' />
            <span>
              Your Social Security Number is encrypted for your protection. It is only visible in a limited capacity when you change it and when submitted to government agencies.
            </span>
          </div>
        </div>

        <hr className="mt-4 mb-3" />

        <div className='mb-3'>
          <label className="mb-2 d-block">
            Ethnicity <span className="text-danger">*</span>
          </label>
          <SelectMenu
            name="ethnicity"
            options={ethnicityOptions}
            defaultValue={ethnicityDefaultValue}
            required
            onChange={({ value }) => {
              setEthnicity(value);
            }}
          />
        </div>

        <div className='mb-3'>
          <label className="mb-2 d-block">
            Race <span className="text-danger">*</span>
          </label>
          <SelectMenu
            name="races"
            options={racesOptions}
            required
            isMulti
            defaultValue={racesDefaultValues}
            onChange={(values) => {
              const newValues = values.length === 0 ? null : values.map((tag) => tag.value);
              // OLD NOTE: null converted to undefined when data is formatted before sent to BE
              // NOTE: setting to null may not be needed because we're saving to Firebase
              setRaces(newValues);
            }}
          />
          <div className="small mt-1">
            Multiple selections allowed.
          </div>
        </div>

        <div className='mb-3'>
          <label className="mb-2 d-block">
            Veteran Status <span className="text-danger">*</span>
          </label>
          <SelectMenu
            name="veteranStatus"
            options={veteranStatusOptions}
            defaultValue={veteranStatusDefaultValue}
            required
            onChange={({ value }) => {
              setVeteranStatus(value);
            }}
          />
        </div>

        <div className='mb-3'>
          <label className="mb-2 d-block">
            Organization that referred you <span className="text-danger">*</span>
          </label>
          <SelectMenu
            name="orgReferral"
            options={orgReferralOptions}
            defaultValue={orgReferralDefaultValue}
            required
            onChange={({ value }) => {
              setOrgReferral(value);
            }}
          />
        </div>

        <div className=''>
          <label className="mb-2 d-block">
            Citizen Status <span className="text-danger">*</span>
          </label>
          <SelectMenu
            name="citizenStatus"
            options={userCitizenStatusOptions}
            defaultValue={userCitizenDefaultValue}
            required
            onChange={({ value }) => {
              setCitizenStatus(value);
            }}
          />
        </div>

        {currentUser?.id !== directoryUser?.id && (
          <div className='mt-3'>
            <label className="mb-2 d-block">
              User Signature
            </label>

            <div
              className='d-flex align-items-center justify-content-between border p-2 rounded'
            >
              <div
                className='border p-2 d-inline-block rounded'
                style={{
                  minWidth: '214px'
                }}
              >
                {userSignature ? (
                  <img
                    src={userSignature}
                    style={{
                      height: '50px'
                    }}
                    alt={`${userName} Signature`}
                  />
                ) : (
                  <div className='d-flex align-items-center'>
                    <FaFileSignature
                      className='mr-3 opacity-50'
                      size={30}
                    />
                    <div className='ml-2 text-danger'>
                      No signature on file.
                    </div>
                  </div>
                )}
              </div>
              {userSignature && (
                <FaCheckCircle
                  className='mr-3 text-keppel'
                  size={30}
                />
              )}
            </div>
          </div>
        )}

        {currentUser?.id === directoryUser?.id && (
          <div className='mt-3'>
            <label className="mb-2 d-block">
              User Signature <span className="text-danger">*</span>
            </label>
            <UserSignatureBlock
              textBelowSignature={userName}
              onChange={(newUserSignature) => {
                setUserSignature(newUserSignature);
              }}
            />
          </div>
        )}

        <div className='small my-3'>
          <FaLock className='d-inline' />
          <span className='ml-1'>
            Your data is only shared with a limited number of people at {orgName} for the purpose of submitting official government forms. Read more about our <PrivacyPolicyLink />.
          </span>
        </div>

      </div>

      <div className="card-footer p-3 d-flex align-items-center bg-light">
        <button
          className="btn btn-md btn-primary"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>

        <button
          className="btn btn-md btn-link ml-2"
          type="button"
          onClick={onCancel}
          disabled={loading}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PrivateInfoForm;
