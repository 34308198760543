import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import { addEntriesToTopic } from '../../services/topics';
import { createEntry, manageEntry, updateEntry } from '../../services/entry';
import { MANAGE_CURRENT_ENTRY } from '../../constants/routes';
import formatData from '../../services/formatData';
import Questions from './Questions';
import RadioGroup from '../ManageContent/RadioGroup';
import RenderTinyMceEditor from '../ManageContent/Wysiwyg/RenderTinyMceEditor';
import Collapse from '../ManageContent/Collapse';
import SkillsSelectMenu from '../OrgConsole/Skills/SkillsSelectMenu';

const difficultyOptions = ['1', '2', '3', '4', '5'];

const Form = ({ className = '' }) => {
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const data = useSelector((state) => state.currentQuiz);
  const course = useSelector((state) => state.course);
  const currentTopic = useSelector((state) => state.currentTopic);
  const currentTopicTitle = currentTopic?.title || '';
  const currentModal = useSelector((state) => state.currentModal);
  // Misc
  const currentModalKey = currentModal?.key || '';
  const classId = course?.id || '';
  const topicId = currentTopic?.id || '';
  const queryParams = new URLSearchParams(window.location.search);
  const referenceIndex = queryParams.get('referenceIndex');

  const orgId = organization?.id || '';
  const [loading, setLoading] = useState(false);
  const [id] = useState(data.id);
  const [title, setTitle] = useState(data.title || '');
  const [difficulty, setDifficulty] = useState(
    data.difficulty || difficultyOptions[0]
  );
  const [questions, setQuestions] = useState(data.questions || []);
  const [activeQuiz, setActiveQuiz] = useState(data.activeQuiz || false);
  const [guidedQuiz, setGuidedQuiz] = useState(data.guidedQuiz || false);
  const [allowPracticeAfterSubmission, setAllowPracticeAfterSubmission] = useState(data.allowPracticeAfterSubmission || false);
  const [quizSkills, setQuizSkills] = useState(data?.quizSkills);

  const onChangeSkills = (options) => {
    setQuizSkills(options.map((skill) => skill.value));
  };

  const descriptionRef = useRef(null);
  const [description, setDescription] = useState(data.description);

  // NEW EDITOR
  const [firstTimeConvertEditor, setFirstTimeConvertEditor] = useState(true);
  const [enableNewEditor] = useState(data.enableNewEditor || true);

  const convertOldEditorToNewEditor = () => {
    const converter = new Showdown.Converter();
    const descriptionHtml = converter.makeHtml(data.description);

    setDescription(descriptionHtml);
  };

  // If first time loading
  // If the new editor is not enabled
  // If user is editing an existing entry
  if (firstTimeConvertEditor && !data.enableNewEditor && data.id) {
    console.log('run conversion');
    setFirstTimeConvertEditor(false);
    convertOldEditorToNewEditor();
  }
  // /NEW EDITOR

  const showSuccess = () => {
    setLoading(false);
    toast.success('Quiz Saved!');
  };

  const createQuiz = (formattedData) => {
    return createEntry({
      contentType: 'quiz',
      data: formattedData
    }).then((entry) => {
      toast.info('Adding Quiz to Topic...');
      addEntriesToTopic({
        entryIds: entry?.sys?.id ? [entry.sys.id] : null,
        contentType: 'quiz',
        topicId
      }).then(() => {
        showSuccess();

        const redirectParams = manageEntry({
          manageType: 'edit',
          referenceIndex: parseInt(referenceIndex, 10) + 1,
          classId,
          topicId,
          entryId: entry?.sys?.id,
          contentType: 'quiz'
        });

        // Update URL to match same structure as if User was editing an existing Topic
        history.push(MANAGE_CURRENT_ENTRY + redirectParams);
      });
    });
  };

  const editQuiz = (formattedData) => {
    updateEntry(formattedData, id).then(() => {
      showSuccess();
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      enableNewEditor,
      title,
      difficulty,
      classId,
      questions,
      guidedQuiz,
      allowPracticeAfterSubmission,
      activeQuiz,
      topicId,
      orgId
    };

    if (id) {
      if (activeQuiz) {
        if (!questions || (questions && questions.length < 2)) {
          toast.error('Add at least 2 questions');

          return false;
        }
      }

      if (
        descriptionRef
        && descriptionRef.current
        && descriptionRef.current.isDirty
      ) {
        dataToSave.description = descriptionRef.current.getContent();
      }

      if (quizSkills !== undefined) {
        dataToSave.quizSkills = quizSkills;
      }
    }

    setLoading(true);

    const formattedData = formatData(dataToSave, 'quiz');

    if (id) {
      editQuiz(formattedData);
    } else {
      createQuiz(formattedData);
    }
  };

  const goBack = () => {
    history.push(
      `${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=topic&classId=${classId}&topicId=${topicId}&entryId=${topicId}`
    );
  };

  return (
    <>
      <div
        className={`shadow bg-white border-left border-right border-bottom ${className}`}
      >
        <form onSubmit={handleSubmit}>
          {/* <div className="card-header bg-light p-3 d-flex align-items-center justify-content-between">
            <h5 className="m-0 text-capitalize d-flex align-items-center">
              <i className={`${QUIZ} mr-2`} /> {title || 'New Quiz'}
            </h5>
          </div> */}
          <div className="card-body">
            <div className="mb-3">
              <label className="mb-2">
                <b>Title:</b> <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                defaultValue={title}
                autoFocus
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setTitle(value);
                }}
                required
              />
              <div className="text-muted medium mt-1">
                Ex: {currentTopicTitle} Knowledge Check
              </div>
            </div>

            {id && (
              <>
                <div
                  id="quiz-activeQuiz"
                  className="form-group border-top pt-3 mb-3"
                >
                  <label className="mb-1">
                    <b>Active:</b>
                  </label>
                  <div className="text-muted medium mb-2">
                    Allow users to submit answers to this quiz.
                  </div>
                  <RadioGroup
                    name="activeQuiz"
                    value={activeQuiz}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setActiveQuiz(value === 'true');
                    }}
                  />
                </div>

                <div className="form-group border-bottom pb-4 mb-4">
                  <label className="mb-1">
                    <b>Difficulty:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Rate the difficulty of this quiz from 1-5.
                  </div>
                  <select
                    type="text"
                    className="form-control"
                    name="difficulty"
                    defaultValue={difficulty}
                    style={{ width: '100px' }}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setDifficulty(value);
                    }}
                    required
                  >
                    {difficultyOptions.map((value, i) => (
                      <option
                        key={i}
                        value={value}
                      >
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <SkillsSelectMenu
                  showManageLink
                  showHelpText
                  className='form-group border-bottom pb-4 mb-4'
                  ids={quizSkills}
                  onChange={onChangeSkills}
                />

                <div className="my-3">
                  <Questions
                    key={currentModalKey}
                    enableNewEditor={enableNewEditor}
                    contentId={id}
                    contentType="quiz"
                    parent={{
                      title: 'Questions'
                    }}
                    child={{
                      title: 'Options'
                    }}
                    data={questions}
                    onUpdate={(updatedQuestions) => {
                      setQuestions(
                        updatedQuestions && updatedQuestions.length > 0
                          ? updatedQuestions
                          : []
                      );
                    }}
                    guidedQuiz={guidedQuiz}
                  />
                </div>

                <Collapse
                  title="Description"
                  id="quizDesc"
                >
                  {enableNewEditor && (
                    <RenderTinyMceEditor
                      id="quiz-description"
                      contentId={id}
                      contentType="quiz"
                      defaultValue={description}
                      editorRef={descriptionRef}
                      // uploadImgButton={false}
                      uploadPdfButton={false}
                    // embedMediaButton={false}
                    />
                  )}
                </Collapse>

                <Collapse
                  title="Settings"
                  id="quizSettings"
                >
                  <div
                    id="quiz-guidedQuiz"
                    className="form-group mb-4 border-bottom pb-3"
                  >
                    <label className="mb-1">
                      <b>Guided Quiz:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      Guide users to the correct answer by informing them why an
                      answer is incorrect.
                    </div>
                    <RadioGroup
                      name="guidedQuiz"
                      value={guidedQuiz}
                      onChange={(e) => {
                        const { value } = e.currentTarget;

                        setGuidedQuiz(value === 'true');
                      }}
                    />
                  </div>

                  <div
                    id="quiz-allowPracticeAfterSubmission"
                    className="form-group mb-4 border-bottom pb-3"
                  >
                    <label className="mb-1">
                      <b>Allow Practice after 1st Submission:</b>
                    </label>
                    <div className="text-muted medium mb-2">
                      Allow users to submit practice answers that won't impact
                      their overall score after they submit their first answer.
                    </div>
                    <RadioGroup
                      name="allowPracticeAfterSubmission"
                      value={allowPracticeAfterSubmission}
                      onChange={(e) => {
                        const { value } = e.currentTarget;

                        setAllowPracticeAfterSubmission(value === 'true');
                      }}
                    />
                  </div>
                </Collapse>
              </>
            )}
          </div>

          <div className="card-footer d-flex justify-content-between">
            <div>
              <button
                className="btn btn-primary mr-2"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>

              <button
                className="btn btn-sm btn-link"
                onClick={goBack}
                title="Cancel"
                type="button"
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
