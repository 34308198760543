import React, { useCallback, useEffect, useState } from 'react';
import { FaFileAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { FaCircleCheck } from 'react-icons/fa6';
import { fetchUserPrompt } from '../../../../services/userPrompts';
import { fetchMaterial } from '../../../../services/materials';
import { getMaterial } from '../../../../actions/Materials';
import { setSideSheet } from '../../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL } from '../../../../constants/sideSheets';

const PromptMaterial = ({ userPromptId }) => {
  const dispatch = useDispatch();
  // Local State
  const [userPrompt, setUserPrompt] = useState(null);
  const [promptMaterial, setPromptMaterial] = useState(null);

  const retrieveMaterial = useCallback(() => {
    if (userPromptId) {
      fetchUserPrompt(userPromptId).then((response) => {
        setUserPrompt(response);
        if (response?.materialId) {
          fetchMaterial(response?.materialId).then((materialResponse) => {
            setPromptMaterial(materialResponse);
          });
        }
      });
    }
  }, [userPromptId]);

  useEffect(() => {
    retrieveMaterial();
  }, [retrieveMaterial]);

  if (userPrompt?.id && userPrompt?.materialId) {
    return (
      <div className='mt-3 p-3 rounded border shadow'>
        <h6 className='font-weight-bold'>
          <span className='d-flex align-items-center'>
            <span>
              Material
            </span>
            <span className='badge d-flex align-items-center ml-1'>
              <FaCircleCheck className='text-keppel' />
              <span className='ml-1'>
                Saved
              </span>
            </span>
          </span>
        </h6>
        <div
          className='bg-light row-clickable border p-3 rounded'
          aria-hidden
          onClick={() => {
            dispatch(getMaterial(userPrompt?.materialId)).then(() => {
              dispatch(setSideSheet({
                key: SHEET_KEY_MATERIAL,
                className: 'MaterialSideSheet z-index-100'
              }));
            });
          }}
        >
          <div className='d-flex align-items-center'>
            <FaFileAlt />
            <span className='ml-2'>
              Material {promptMaterial?.title}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default PromptMaterial;
