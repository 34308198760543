import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import $ from 'jquery';
import { ICON_SETTINGS } from '@apprentage/constants';
import { getOrg } from '../../actions/Organizations';
// import { resetCourses } from '../../actions/Courses';
// import { resetGroups } from '../../actions/Groups';
import { withAuthorization } from '../Session';
import { ORG_CONSOLE_DASHBOARD, SLASH } from '../../constants/routes';
import withOrgConsole from '../App/withOrgConsole';
import Form from './Form';
import CdnFiles from '../ManageContent/CdnFiles';
import Loading from '../Loading';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import Tabs from './Tabs';

const Organization = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const orgType = pathOr(null, ['type'], organization);

  if (!orgId) {
    history.replace(SLASH);
  }

  useEffect(() => {
    dispatch(getOrg({ orgId }));

    // TODO why are these needed?
    // dispatch(resetCourses());

    $(document).on('click', '.admin-menu--reference .dropdown-collapse-container', (e) => {
      e.stopPropagation();
    });
  }, []);

  if (!organization.id) {
    return (
      <Loading text="Loading Organization..." />
    );
  }

  return (
    <div className="row">
      <div className="col-sm-12">

        <div className="mb-5">
          <OrgConsoleHeader
            className="mb-3"
            pageTitle="Settings"
            icon={ICON_SETTINGS}
            route={ORG_CONSOLE_DASHBOARD}
          />

          {orgType === 'community' && (
            <Tabs active="general" />
          )}

          <div
            className={`card-body ${orgType === 'workforce' ? 'border-top' : ''} border-left border-right border-bottom bg-white shadow`}
          >
            <div className="">
              <div className="">
                <Form />

                <div className="mt-3 mb-5">
                  <CdnFiles
                    id="organization-integration-files"
                    files={organization.cdnFiles}
                    contentId={orgId}
                    contentType="organization"
                    editMenu
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Organization);
