export const formatSSN = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const ssn = value.replace(/[^\d]/g, '');

  // ssnLength is used to know when to apply our formatting for the ssn
  const ssnLength = ssn.length;

  // we need to return the value with no formatting if its less then four digits
  if (ssnLength < 4) return ssn;

  // if ssnLength is greater than 4 and less the 6 we start to return
  // the formatted number
  if (ssnLength < 6) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }

  // finally, if the ssnLength is greater then 6, we add the last
  // bit of formatting and return it.
  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
};

export const obfuscateSSN = (value) => {
  if (!value) return value;

  if (value.length !== 11) return value;

  return `***-**-${value.slice(7, 11)}`;
};
