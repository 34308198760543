import React, { useMemo } from 'react';
import Table from './Table';
import TitleCell from './TitleCell';
import SelectBtnCell from './SelectBtnCell';
import TableLoading from '../../../../ManageContent/Tables/TableLoading';

const List = ({
  data,
  handleOnClickRow
}) => {
  const columns = useMemo(() => [
    {
      Header: '',
      style: { width: '45px' },
      accessor: 'selectBtnCol',
      onSelect: (row, index) => {
        handleOnClickRow(row, index);
      },
      disableSortBy: true,
      Cell: SelectBtnCell
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: TitleCell
    }
  ], [handleOnClickRow]);

  if (Array.isArray(data) && data.length > 0) {
    return (
      <Table
        columns={columns}
        data={data}
      />
    );
  }

  return (
    <TableLoading />
  );
};

export default List;
