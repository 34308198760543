import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ICON_CHECK_CIRCLE, ICON_CHEVRON_RIGHT } from '@apprentage/components/dist/constants/icons';
import Icon from '@apprentage/components/dist/components/Icon';
import {
  GLOBAL_PLATFORM_NAME,
  INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE
} from '@apprentage/constants';
import styled from 'styled-components';
import { setCurrentModal } from '../../../../../actions/Modals';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import { TURBINE } from '../../../../../constants/urls';
import { canManageProgramUsers } from '../../../../../permissions/manage';
import { canAccessUserDocs, canAccessUserWageSchedule } from '../../../../../permissions/access';
import { MODAL_KEY_USER_INVITATION } from '../../../../../constants/modals';

const ChecklistRow = styled.div`
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  div {
    align-items: center;
    display: flex;

    i {
      font-size: 1.5rem;
    }
  }

  div > span {
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
`;

const UserChecklist = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  const employerAgreement = useSelector((state) => state.employerAgreement);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Training Program
  const apprenticeshipId = params?.apprenticeshipId || '';
  const apprenticeshipUserId = params?.apprenticeshipUserId || '';
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipUserWageSchedule = apprenticeship?.userWageSchedule || null;
  const apprenticeshipUserInstructionProvider = apprenticeship?.userInstructionProvider || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  const messageUser = () => {
    dispatch(setCurrentModal({
      key: 'sendEmail',
      data: {
        name: apprenticeshipUser?.name,
        email: apprenticeshipUser?.email,
        message: `Friendly reminder to fill out required personal information in ${GLOBAL_PLATFORM_NAME} for your participation in the ${apprenticeship.title} Training Program.

Use this link to enter the required information: ${TURBINE}
        `,
        fromName: currentUser?.name,
        replyTo: currentUser?.email,
        subject: 'Reminder to fill out required personal information',
        hideSubject: true,
        disableSubject: true
      }
    }));
  };

  const inviteToSignApprenticeAgreement = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_USER_INVITATION,
        data: {
          modalTitle: 'New Request',
          locationId: apprenticeshipUser?.locationId,
          employerAgreementId: employerAgreement?.id,
          apprenticeshipId,
          singleUserInvite: true,
          includeName: true,
          firstName: apprenticeshipUser?.firstName,
          lastName: apprenticeshipUser?.lastName,
          email: apprenticeshipUser?.email,
          inviteType: INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE,
          inviteTypeTitle: 'Trainee Agreement : Request Signature'
        }
      })
    );
  };

  const canRequestApprenticeSignature = useMemo(() => {
    if (!canManageProgramUsers(role)) {
      return false;
    }

    if (!employerAgreement?.id) {
      return false;
    }

    if (!apprenticeshipUserWageSchedule?.id) {
      return false;
    }

    if (!apprenticeshipUserInstructionProvider?.id) {
      return false;
    }

    return true;
  }, [
    employerAgreement?.id,
    apprenticeshipUserWageSchedule?.id,
    apprenticeshipUserInstructionProvider?.id
  ]);

  const reasonCantRequestApprenticeSignature = useMemo(() => {
    if (!apprenticeshipUser.userProfileId) {
      return 'Personal Info. Needed';
    }

    if (!apprenticeshipUserWageSchedule?.id) {
      return 'Wage Schedule Needed';
    }

    if (!apprenticeshipUserInstructionProvider?.id) {
      return 'Instruction Provider Needed';
    }

    if (!employerAgreement?.id) {
      return 'Employer Agreement Needed';
    }

    return '';
  }, [
    apprenticeshipUser?.userProfileId,
    employerAgreement?.id,
    apprenticeshipUserWageSchedule?.id,
    apprenticeshipUserInstructionProvider?.id
  ]);

  return (
    <div className='card'>
      <div
        className='card-header bg-dark text-white'
        style={{
          minHeight: '64px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <h6 className='font-weight-bold m-0'>
          Trainee Agreement Checklist
        </h6>
      </div>
      <div className='card-body p-0'>

        <ChecklistRow>
          <div>
            <i
              className={`${ICON_CHECK_CIRCLE} text-${apprenticeshipUser.userProfileId ? 'success' : 'muted opacity-25'}`}
            />
            <span
              className='d-none d-sm-inline-block'
            >
              Personal Information (PII, EEO)
            </span>
            <span
              className='d-inline-block d-sm-none'
            >
              Personal Info.
            </span>
          </div>

          {!apprenticeshipUser.userProfileId && (
            <button
              className="btn btn-primary btn-sm text-nowrap"
              type="button"
              onClick={messageUser}
            >
              <span>
                Take Action
              </span>
              <Icon className={`ml-2 d-none d-md-inline-block ${ICON_CHEVRON_RIGHT}`} />
            </button>
          )}
        </ChecklistRow>

        <ChecklistRow>
          <div>
            <i
              className={`${ICON_CHECK_CIRCLE} text-${apprenticeshipUserWageSchedule?.id ? 'success' : 'muted opacity-25'}`}
            />
            <span>Wage Schedule</span>
          </div>

          {apprenticeshipUser.userProfileId ? (
            <>
              {!apprenticeshipUserWageSchedule?.id && canAccessUserWageSchedule(role) && (
                <Link
                  className="btn btn-primary btn-sm text-nowrap"
                  to={routeWithOrgId({
                    route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/wage-schedule`,
                    orgId
                  })}
                  title="Wage Schedule"
                >
                  <span>
                    Take Action
                  </span>
                  <Icon className={`ml-2 d-none d-md-inline-block ${ICON_CHEVRON_RIGHT}`} />

                </Link>
              )}
            </>
          ) : (
            <span className='h6 mb-0 ml-2'>
              <span className='badge badge-warning px-2 py-1'>
                Personal Info. Needed
              </span>
            </span>
          )}

        </ChecklistRow>
        <ChecklistRow>
          <div>
            <i
              className={`${ICON_CHECK_CIRCLE} text-${apprenticeshipUserInstructionProvider?.id ? 'success' : 'muted opacity-25'}`}
            />
            <span>Instruction Provider</span>
          </div>

          {apprenticeshipUser.userProfileId ? (
            <>
              {!apprenticeshipUserInstructionProvider?.id && canManageProgramUsers(role) && (
                <button
                  className="btn btn-primary btn-sm text-nowrap"
                  onClick={() => {
                    dispatch(setCurrentModal({
                      key: 'userApprenticeshipSettings',
                      data: { user: apprenticeshipUser }
                    }));
                  }}
                  type='button'
                  title="Take Action"
                >
                  <span>
                    Take Action
                  </span>
                  <Icon className={`ml-2 d-none d-md-inline-block ${ICON_CHEVRON_RIGHT}`} />

                </button>
              )}
            </>
          ) : (
            <span className='h6 mb-0 ml-2'>
              <span className='badge badge-warning px-2 py-1'>
                Personal Info. Needed
              </span>
            </span>
          )}
        </ChecklistRow>

        {!apprenticeAgreement?.signerSignature && (
          <ChecklistRow>
            <div>
              <i
                className={`${ICON_CHECK_CIRCLE} text-${apprenticeAgreement?.id ? 'keppel' : 'muted opacity-25'}`}
              />
              <span className='d-block d-sm-none'>
                Request Signature
              </span>
              <span className='d-none d-sm-block'>
                Request Trainee Signature
              </span>
            </div>

            {canRequestApprenticeSignature ? (
              <button
                className={`btn btn-${apprenticeAgreement?.id ? 'outline-secondary' : 'primary'} btn-sm text-nowrap`}
                type="button"
                onClick={inviteToSignApprenticeAgreement}
              >
                <span>
                  {apprenticeAgreement?.id ? 'New Request' : 'Send Request'}
                </span>
                <i
                  className={`ml-2 d-none d-md-inline-block ${ICON_CHEVRON_RIGHT}`}
                />
              </button>
            ) : (
              <span className='h6 mb-0 ml-2'>
                <span className='badge badge-warning px-2 py-1'>
                  {reasonCantRequestApprenticeSignature}
                </span>
              </span>
            )}
          </ChecklistRow>
        )}

        {/* <ChecklistRow>
          <div>
            <i
              className={`${ICON_CHECK_CIRCLE} text-${hasTraineeAgreement ? 'success' : 'muted opacity-25'}`}
            />
            <span>Request Trainee Sign Agreement</span>
          </div>

          {!hasTraineeAgreement && (
            <>
              <button
                className="btn btn-primary btn-sm text-nowrap"
                onClick={() => {
                  dispatch(setCurrentModal({
                    key: 'userApprenticeshipSettings',
                    data: { user: apprenticeshipUser }
                  }));
                }}
                type='button'
                title="Take Action"
              >
                <span>
                  Take Action
                </span>
                <Icon className={`ml-2 d-none d-md-inline-block ${ICON_CHEVRON_RIGHT}`} />

              </button>
              <Link
                className="btn btn-white btn-sm text-nowrap disabled"
                to={routeWithOrgId({
                  route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/docs`,
                  orgId
                })}
                title="Take Action"
              >
                <span>
                  Take Action
                </span>
                <Icon className={`ml-2 d-none d-md-inline-block ${ICON_CHEVRON_RIGHT}`} />

              </Link>
            </>
          )}
        </ChecklistRow> */}

        <ChecklistRow className="border-bottom-0">
          <div>
            <i
              className={`${ICON_CHECK_CIRCLE} text-${apprenticeAgreement?.signerSignature ? 'keppel' : 'muted opacity-25'}`}
            />
            <span className='d-inline-block d-sm-none'>
              Agreement
            </span>
            <span className='d-none d-sm-inline-block'>
              Trainee Agreement
            </span>

            {apprenticeAgreement?.id && !apprenticeAgreement?.signerSignature && (
              <span className='h6 mb-0 ml-2'>
                <span className='badge badge-warning px-2 py-1'>
                  Not Signed
                </span>
              </span>
            )}
          </div>

          {apprenticeAgreement?.id && canAccessUserDocs(role) && (
            <Link
              className="btn btn-primary btn-sm text-nowrap"
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/users/${apprenticeshipUserId}/docs`,
                orgId
              })}
              title="Trainee Agreement"
            >
              <span className='d-inline-block d-sm-none'>
                Docs
              </span>
              <span className='d-none d-sm-inline-block'>
                Documents
              </span>
              <i
                className={`ml-2 d-none d-md-inline-block ${ICON_CHEVRON_RIGHT}`}
              />
            </Link>
          )}
        </ChecklistRow>
      </div>
    </div>
  );
};

export default UserChecklist;
