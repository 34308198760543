import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { useHistory } from 'react-router-dom';
import { ICON_CREDIT_CARD } from '@apprentage/constants';
import { roleReadable } from '@apprentage/utils';
import { withAuthorization } from '../Session';
import { ORG_CONSOLE_DASHBOARD } from '../../constants/routes';
import { notifyTurbineSupport, routeWithOrgId } from '../../services/organizations';
import { canAccessBilling } from '../../services/currentUser';
import { getCustomer } from '../../actions/Billing';
import { setCurrentModal } from '../../actions/Modals';
// import Membership from '../Membership';
import withOrgConsole from '../App/withOrgConsole';
import Subscriptions from './Subscriptions';
import TurbineAccess from './TurbineAccess';
import Invoices from './Invoices';
import PaymentsLegacy from './PaymentsLegacy';
import Agreements from './Agreements';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import './style.css';

const Billing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const billing = useSelector((state) => state.billing);
  // organization
  const orgId = pathOr(null, ['id'], organization);
  const orgName = pathOr(null, ['name'], organization);
  const orgType = pathOr(null, ['type'], organization);
  // current user
  const role = pathOr([], ['role'], currentUser);
  const userName = pathOr(null, ['name'], currentUser);
  const userEmail = pathOr(null, ['email'], currentUser);
  const updatePaymentMethod = pathOr(null, ['updatePaymentMethod'], currentUser);
  // billing
  const products = pathOr(null, ['products'], billing);
  const customer = pathOr(null, ['customer'], billing);
  // local state
  const [updatePaymentSuccess, setUpdatePaymentSuccess] = useState(false);

  const userRole = useMemo(() => {
    if (orgType) {
      return roleReadable(role, orgType);
    }

    return null;
  }, [role, orgType]);

  useEffect(() => {
    if (!canAccessBilling(role)) {
      const orgConsoleDashboardRoute = routeWithOrgId({
        route: ORG_CONSOLE_DASHBOARD,
        orgId
      });
      history.push(orgConsoleDashboardRoute);
    } else {
      dispatch(getCustomer()).catch((error) => console.warn(error.message));
    }
  }, []);

  const showBillingQuestionsModal = () => {
    dispatch(setCurrentModal({
      key: 'sendEmail',
      data: {
        name: 'Turbine Workforce Support',
        fromName: userName,
        subject: `New billing question from ${orgName}`,
        hideSubject: true,
        disableSubject: true,
        replyTo: userEmail,
        notifyTurbineEmail: true
      }
    }));
  };

  const handleUpdatePaymentMethod = () => {
    setUpdatePaymentSuccess(true);

    const data = {
      subject: `Update payment method (${userRole})`,
      disableSubject: true,
      message: `${userName} (${userRole}) needs to update their payment method`,
      replyTo: userEmail
    };

    notifyTurbineSupport(data);
  };

  const updateCC = () => {
    return (
      <>
        <div className="alert alert-danger mt-3" role="alert">
          <h4 className="alert-heading">
            Update Payment Method
          </h4>
          <p>
            We were unable to complete your transaction with the payment method on file.
          </p>
          <hr />
          <p className="mb-0">
            To continue using Turbine Workforce, please update your <b>payment method</b>:
          </p>
        </div>

        <div className="mt-2 mb-5">
          {updatePaymentSuccess ? (
            <div className="alert alert-info">
              A Turbine Workforce team member will contact you to update your payment method.
            </div>
          ) : (
            <div className="alert alert-secondary bg-light">
              <button
                onClick={handleUpdatePaymentMethod}
                className="btn btn-md btn-primary"
                target="_blank"
                rel="noreferrer noopener"
                title="Update Payment Method"
                type="button"
              >
                Update Payment Method
              </button>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Billing"
            icon={ICON_CREDIT_CARD}
            route={ORG_CONSOLE_DASHBOARD}
          />

          <div className='card bg-white shadow mt-3'>
            <div
              className="card-body"
            >
              <div className="row">
                <div className="col-sm-12 ">
                  <Agreements className="mt-2" />
                </div>
              </div>

              { updatePaymentMethod && updateCC() }

              <div className="row">
                <div className="col-sm-12 ">
                  <PaymentsLegacy />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 ">
                  <TurbineAccess />
                </div>
              </div>

              {customer && (
                <div className="row">
                  <div className="col-sm-12 ">
                    <Subscriptions />
                  </div>
                </div>
              )}

              {customer && products && (
                <div className="row">
                  <div className="col-sm-12 ">
                    <Invoices />
                  </div>
                </div>
              )}

              {/* <Membership /> */}

            </div>
            <div className='card-footer'>
              <span>Direct any billing questions to </span>
              <button
                className="btn-link px-0"
                type="button"
                onClick={showBillingQuestionsModal}
              >
                Turbine Workforce Support
              </button>
              <span>.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withOrgConsole,
  withAuthorization(condition)
)(Billing);
