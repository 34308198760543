import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetMaterial, setMaterial } from '../../actions/Materials';
import withConsole from '../App/withConsole';
import CdnFiles from '../ManageContent/CdnFiles';
import Form from './Form';
import Loading from '../Loading';
import DeleteMaterial from '../ManageContent/DeleteContent/DeleteMaterial';
import Breadcrumb from '../Console/Breadcrumb';

const Material = () => {
  const dispatch = useDispatch();
  // Redux
  const material = useSelector((state) => state.material);
  // Misc
  const queryParams = new URLSearchParams(window.location.search);
  const entryId = queryParams.get('entryId');
  const contentType = queryParams.get('contentType');

  useEffect(() => {
    if (contentType === 'material' && entryId) {
      dispatch(setMaterial(entryId));
    }
  }, [contentType, dispatch, entryId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetMaterial());
    };
  }, [dispatch]);

  if (entryId && !material.id) {
    return (
      <Loading text="Loading Material..." />
    );
  }

  return (
    <>
      <Breadcrumb
        className="my-3"
        currentPageTitle={material?.title}
      />

      <Form
        showHeader
        contentType="material" // contentful
        uploadPdfButton
        embedMediaButton
      />

      {material?.id && (
        <DeleteMaterial
          contentId={material?.id}
          contentType="material" // contentful
          title={material?.title}
        />
      )}

      {material?.id && (
        <div className="mb-5">
          <CdnFiles
            id="material-integration-files"
            contentId={material?.id}
            contentType="material" // contentful
            files={material?.cdnFiles}
            editMenu
          />
        </div>
      )}
    </>
  );
};

export default withConsole(Material);
