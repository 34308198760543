import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import Form from '../../OrgConsole/WorkforceSuite/Apprenticeship/Manage/Form';
import DuplicateApprenticeshipTitle from './DuplicateApprenticeshipTitle';

const DuplicateApprenticeship = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);

  if (!currentModal?.visible) {
    return;
  }

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <DuplicateApprenticeshipTitle
            handleContinue={() => setStep(2)}
            modalTitle="Customize Proven Framework"
          />
        );
      case 2:
        return (
          <div className="card">
            <div className="card-header bg-dark">
              <h1 className="h5 m-0 text-white">New Training Program</h1>
            </div>
            <div className="card-body p-0">
              <Form isDuplicating />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={handleClose}
      theme="dark"
      full
    >
      {renderStepContent()}
    </Modal>
  );
};

export default DuplicateApprenticeship;
