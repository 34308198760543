import React from 'react';
import { useSelector } from 'react-redux';
import MarkdownCheatsheet from './MarkdownCheatsheet';
import SendEmail from './SendEmail';
import InviteUser from './InviteUser';
import UserInvitation from './UserInvitation';
import UserInvitationConfirm from './UserInvitationConfirm';
import ManageGroup from './ManageGroup';
import CreateCourse from './CreateCourse';
import UploadFiles from './UploadFiles';
import ManageResourceGroupTag from './ManageResourceGroupTag';
import BillingAgreement from './BillingAgreement';
import ManageNotification from './ManageNotification';
import ManageExperts from './ManageExperts';
import ManageSkill from './ManageSkill';
import ManageJobProfile from './ManageJobProfile';
import ManageAttendance from './ManageAttendance';
import CreatePathway from './CreatePathway';
import SearchSelectUsers from './SearchSelectUsers';
import ImportQuizQuestions from './ImportQuizQuestions';
import ManageSubmissions from './ManageSubmissions';
import InviteUserCreateJobProfile from './InviteUserCreateJobProfile';
import UserSettings from './UserSettings';
import ManageCdnFile from './ManageCdnFile';
import ManageWageSchedule from './ManageWageSchedule';
import SearchContent from './SearchContent';
import ImageCrop from './ImageCrop';
import ManageEmployerPointOfContact from './ManageEmployerPointOfContact';
import ManageWorkforceInformation from './ManageWorkforceInformation';
import ManageCompetencyGroup from './ManageCompetencyGroup';
import ManageCompetency from './ManageCompetency';
import CompetencyInitial from './CompetencyInitial';
import SearchSelectOrganizations from './SearchSelectOrganizations';
import ManageRecord from './ManageRecord';
import FullScreenIframe from './FullScreenIframe';
import DuplicateApprenticeship from './DuplicateApprenticeship';
import ManageInstructionProvider from './ManageInstructionProvider';
import UserApprenticeshipSettings from './UserApprenticeshipSettings';
import ChangeZipCodeInstructionProvider from './InstructionProvider/ChangeZipCodeModal';
import ManageQuizOutcomes from './ManageQuizOutcomes';
import ManagePrompt from './ManagePrompt';
import {
  MODAL_KEY_MANAGE_PROMPT,
  MODAL_KEY_BUILD_YOUR_OWN_TRAINING,
  MODAL_KEY_CHANGE_OCCUPATION,
  MODAL_KEY_CLONE_SUCCESSFUL_TRAININGS,
  MODAL_KEY_CONFIRM_ACTION,
  MODAL_KEY_COURSE_CREATE_FROM_DOC,
  MODAL_KEY_CREATE_TRAINING_PROGRAM,
  MODAL_KEY_DASHBOARD_CHANGE_LAYOUT,
  MODAL_KEY_DASHBOARD_MODULE_CONFIG,
  MODAL_KEY_DASHBOARD_NEW_MODULE,
  MODAL_KEY_DASHBOARD_REORDER_LINK_WIDGETS,
  MODAL_KEY_MANAGE_CDN_FILE,
  MODAL_KEY_MANAGE_NOTIFICATION,
  MODAL_KEY_MANAGE_RECORD,
  MODAL_KEY_MANAGE_SUBMISSIONS,
  MODAL_KEY_MANAGE_WIDGET,
  MODAL_KEY_NOTIFICATIONS_MENU,
  MODAL_KEY_ONBOARD_WELCOME,
  MODAL_KEY_RELOAD,
  MODAL_KEY_RESOURCE_PREVIEW,
  MODAL_KEY_SEARCH_CONTENT,
  MODAL_KEY_SEARCH_SELECT_USERS,
  MODAL_KEY_UPLOAD_FILES,
  MODAL_KEY_USER_INVITATION,
  MODAL_KEY_USER_INVITATION_CONFIRM,
  MODAL_KEY_USER_SIGNATURE,
  MODAL_KEY_WIDGET_TYPE,
  MODAL_KEY_VELA_HANDOFF,
  MODAL_KEY_MANAGE_GROUP,
  MODAL_KEY_COMPETENCY_INITIAL,
  MODAL_KEY_MANAGE_COMPETENCY
} from '../../constants/modals';
import OnboardWelcome from './OnBoardWelcome';
import CreateTrainingProgram from './CreateTrainingProgram';
import CloneSuccessfulTrainings from './CloneSuccessfulTrainings';
import BuildYourOwnTraining from './BuildYourOwnTraining';
import AppsMenu from './AppsMenu';
import NotificationsMenu from './NotificationsMenu';
import ManageWidget from './ManageWidget';
import ChangeOccupationModal from './ChangeOccupation';
import FeatureFlagNotice from './FeatureFlagNotice';
import Reload from './Reload';
import ResourcePreview from './ResourcePreview';
import UserSignature from './UserSignature';
import WidgetTypeForm from './ManageWidget/WidgetTypeForm';
import CreateCourseFromDocument from './CreateCourseFromDocument';
import CreateModuleModal from './DashboardModals/CreateModuleModal';
import ConfirmAction from './ConfirmAction';
import ChangeDashboardLayout from './DashboardModals/ChangeDashboardLayoutModal';
import ConfigModuleModal from './DashboardModals/ConfigModule';
import ReorderLinkWidgetsModal from './DashboardModals/ReorderLinkWidgetsModal';
import VelaHandoff from './VelaHandoff';

const Modals = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);

  if (!currentUser.id || !currentModal.key) {
    return;
  }

  return (
    <>
      <MarkdownCheatsheet />

      {currentModal.key === MODAL_KEY_RELOAD && <Reload />}
      {currentModal.key === MODAL_KEY_CONFIRM_ACTION && <ConfirmAction />}
      {currentModal?.key === MODAL_KEY_USER_SIGNATURE && <UserSignature />}
      {currentModal.key === 'appsMenu' && <AppsMenu />}

      {currentModal?.key === MODAL_KEY_VELA_HANDOFF && <VelaHandoff />}

      {currentModal.key === MODAL_KEY_MANAGE_WIDGET && <ManageWidget />}
      {currentModal.key === MODAL_KEY_WIDGET_TYPE && <WidgetTypeForm />}

      {currentModal.key === MODAL_KEY_NOTIFICATIONS_MENU && (
        <NotificationsMenu />
      )}
      {currentModal.key === MODAL_KEY_COURSE_CREATE_FROM_DOC && (
        <CreateCourseFromDocument />
      )}

      {currentModal.key === 'fullScreenIframe' && <FullScreenIframe />}
      {currentModal.key === MODAL_KEY_RESOURCE_PREVIEW && <ResourcePreview />}
      {currentModal.key === 'featureFlagNotice' && <FeatureFlagNotice />}

      {currentModal.key === 'duplicateApprenticeship' && (
        <DuplicateApprenticeship />
      )}
      {currentModal.key === 'importQuizQuestions' && <ImportQuizQuestions />}

      {currentModal && currentModal.key === 'imageCrop' && <ImageCrop />}

      {currentModal.key === MODAL_KEY_SEARCH_CONTENT && <SearchContent />}

      {currentModal.key === 'userSettings' && <UserSettings />}

      {currentModal.key === 'userApprenticeshipSettings' && (
        <UserApprenticeshipSettings />
      )}

      {currentModal.key === MODAL_KEY_MANAGE_RECORD && <ManageRecord />}

      {currentModal.key === 'sendEmail' && <SendEmail />}

      {currentModal.key === 'manageInstructionProvider' && (
        <ManageInstructionProvider />
      )}

      {currentModal.key === MODAL_KEY_MANAGE_NOTIFICATION && (
        <ManageNotification />
      )}

      {currentModal.key === 'manageExperts' && <ManageExperts />}

      {currentModal.key === 'inviteUser' && <InviteUser />}

      {currentModal.key === MODAL_KEY_USER_INVITATION && <UserInvitation />}
      {currentModal.key === MODAL_KEY_USER_INVITATION_CONFIRM && <UserInvitationConfirm />}

      {currentModal.key === 'manageEmployerPointOfContact' && (
        <ManageEmployerPointOfContact />
      )}

      {currentModal.key === 'manageEmployerProfile' && (
        <ManageWorkforceInformation />
      )}

      {currentModal.key === MODAL_KEY_MANAGE_GROUP && <ManageGroup />}

      {currentModal.key === 'createCourse' && <CreateCourse />}

      {currentModal.key === 'createPathway' && <CreatePathway />}

      {currentModal.key === MODAL_KEY_SEARCH_SELECT_USERS && (
        <SearchSelectUsers />
      )}

      {currentModal.key === 'searchSelectOrganizations' && (
        <SearchSelectOrganizations />
      )}

      {currentModal.key === MODAL_KEY_UPLOAD_FILES && <UploadFiles />}

      {currentModal.key === MODAL_KEY_MANAGE_PROMPT && <ManagePrompt />}

      {currentModal.key === 'manageResourceGroupTag' && (
        <ManageResourceGroupTag />
      )}

      {currentModal.key === 'manageSkill' && <ManageSkill />}

      {currentModal.key === 'billingAgreement' && <BillingAgreement />}

      {currentModal.key === 'manageJobProfile' && <ManageJobProfile />}

      {currentModal.key === MODAL_KEY_MANAGE_CDN_FILE && <ManageCdnFile />}

      {currentModal.key === 'manageAttendance' && <ManageAttendance />}

      {currentModal.key === MODAL_KEY_MANAGE_SUBMISSIONS && (
        <ManageSubmissions />
      )}

      {currentModal.key === 'manageQuizOutcomes' && <ManageQuizOutcomes />}

      {currentModal.key === 'inviteUserCreateJobProfile' && (
        <InviteUserCreateJobProfile />
      )}

      {/* TODO DELETE : Replaced by <UserInvitation /> */}
      {/* {currentModal.key === 'inviteUpdateEmployerProfile' && <InviteUpdateEmployerProfile />} */}

      {currentModal.key === 'manageWageSchedule' && <ManageWageSchedule />}

      {currentModal.key === 'manageCompetencyGroup' && (
        <ManageCompetencyGroup />
      )}

      {currentModal.key === MODAL_KEY_MANAGE_COMPETENCY && <ManageCompetency />}

      {currentModal.key === MODAL_KEY_COMPETENCY_INITIAL && <CompetencyInitial />}

      {currentModal.key === 'changePinInstructionProvider' && (
        <ChangeZipCodeInstructionProvider />
      )}

      {currentModal.key === MODAL_KEY_ONBOARD_WELCOME && <OnboardWelcome />}

      {currentModal.key === MODAL_KEY_CREATE_TRAINING_PROGRAM && (
        <CreateTrainingProgram />
      )}

      {currentModal.key === MODAL_KEY_CLONE_SUCCESSFUL_TRAININGS && (
        <CloneSuccessfulTrainings />
      )}

      {currentModal.key === MODAL_KEY_BUILD_YOUR_OWN_TRAINING && (
        <BuildYourOwnTraining />
      )}

      {currentModal.key === MODAL_KEY_CHANGE_OCCUPATION && (
        <ChangeOccupationModal />
      )}

      {currentModal.key === MODAL_KEY_DASHBOARD_NEW_MODULE && (
        <CreateModuleModal />
      )}

      {currentModal.key === MODAL_KEY_DASHBOARD_CHANGE_LAYOUT && (
        <ChangeDashboardLayout />
      )}

      {currentModal.key === MODAL_KEY_DASHBOARD_MODULE_CONFIG && (
        <ConfigModuleModal />
      )}

      {currentModal.key === MODAL_KEY_DASHBOARD_REORDER_LINK_WIDGETS && (
        <ReorderLinkWidgetsModal />
      )}
    </>
  );
};

export default Modals;
