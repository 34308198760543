import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { ICON_QUIZ, ICON_USER } from '@apprentage/constants';
import { withAuthorization } from '../../Session';
import {
  resetCurrentClassQuizOutcomes, setCurrentClassQuizzes, getQuizOutcomes
} from '../../../actions/Quizzes';
import { CLASS_CONSOLE_QUIZZES } from '../../../constants/routes';
import { routeWithClassId } from '../../../services/courses';
import withConsole from '../../App/withConsole';
import QuizOutcomesList from '../../QuizOutcomesList';
import OrgConsoleHeader from '../../OrgConsole/OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsole/OrgConsoleSubheader';

const QuizOutcomes = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Misc
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const classId = course?.id || '';
  const userId = params?.userId || '';
  const quizId = currentQuiz?.id || '';

  const pageTitle = useMemo(() => {
    if (Array.isArray(quizOutcomes.list) && quizOutcomes.list.length && quizOutcomes.list[0] !== undefined) {
      return quizOutcomes.list[0]?.userName || '';
    }

    return 'User';
  }, [quizOutcomes.list]);

  const subheaderRoute = useMemo(() => {
    if (redirectUrl) {
      return redirectUrl;
    }

    if (quizId) {
      return `${CLASS_CONSOLE_QUIZZES}/${quizId}`;
    }

    return CLASS_CONSOLE_QUIZZES;
  }, [redirectUrl, quizId]);

  useEffect(() => {
    dispatch(setCurrentClassQuizzes({ classId }));
    dispatch(getQuizOutcomes({ userId, classId }));

    return function cleanup() {
      dispatch(resetCurrentClassQuizOutcomes());
    };
  }, []);

  return (
    <>
      <OrgConsoleHeader
        pageTitle="Quiz Outcomes"
        icon={ICON_QUIZ}
        className="my-3"
        rawRoute={routeWithClassId({
          route: CLASS_CONSOLE_QUIZZES,
          classId
        })}
      />

      <div className="bg-white border rounded shadow p-3">
        <OrgConsoleSubheader
          pageTitle={pageTitle}
          icon={ICON_USER}
          className="mb-3"
          rawRoute={routeWithClassId({
            route: subheaderRoute,
            classId
          })}
        // data-demo={userName ? 'disabled' : ''}
        >
          {quizOutcomes.list && (
            <span>
              Total: {quizOutcomes.list.length}
            </span>
          )}
        </OrgConsoleSubheader>

        <div className="border">
          <QuizOutcomesList
            data={quizOutcomes.list}
            userId={userId}
            showUserNameAsLink={!userId}
            showQuizTitleAsLink={!!userId}
            showOutcomeButton
          />
        </div>

      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(QuizOutcomes);
