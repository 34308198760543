import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setCurrentCohort, resetCurrentCohort
} from '../../../actions/Cohorts';
import {
  getUsers, resetUsers
} from '../../../actions/Users';
import SelectMenu from '../../SelectMenu';

class CohortContainer extends Component {
  componentDidMount() {
    const { currentCohort, courseCohorts } = this.props;
    const config = { roles: ['student'] };

    if (!currentCohort.id && courseCohorts.list && courseCohorts.list.length) {
      const [firstCohort] = courseCohorts.list;

      if (firstCohort) {
        this.props.setCurrentCohort({
          cohortId: firstCohort.id
        }).then(() => {
          this.props.getUsers(config);
        });
      } else {
        this.props.getUsers(config);
      }
    }
  }

  handleChangeCohort = (e) => {
    const { value } = e.currentTarget;
    const config = { roles: ['student'] };

    this.props.resetUsers();

    if (value === '') {
      this.props.resetCurrentCohort();
      this.props.getUsers(config);
    } else {
      this.props.setCurrentCohort({ cohortId: value }).then(() => {
        this.props.getUsers(config);
      });
    }
  }

  render() {
    const {
      children,
      title,
      courseCohorts,
      currentCohort,
      users,
      showPrintButton = false
    } = this.props;

    if (!courseCohorts.list || !courseCohorts.list.length) {
      return false;
    }

    const options = courseCohorts.list.map((courseCohort) => {
      return {
        text: courseCohort.title,
        value: courseCohort.id
      };
    });

    return (
      <div className="card border-bottom mt-3 mb-5">
        <div className="card-header d-flex align-items-center justify-content-between p-3 d-print-none">
          <h1 className="h5 m-0">
            <span className="d-none d-md-inline">Continuing Education</span> <span className="d-inline d-md-none">C.E.</span> | <b>{ title || '' }</b>
          </h1>

          {showPrintButton && (
            <button
              className="btn btn-md btn-outline-primary"
              title="Print"
              onClick={() => {
                window.print();
              }}
              type="button"
            >
              <i className="fas fa-print" /> Print
            </button>
          )}
        </div>

        <div className="card-body p-0">
          <div className="p-3 border-bottom d-flex justify-content-between align-items-center d-print-none">
            {options && (
              <SelectMenu
                defaultOption={{ text: 'All Cohorts', value: '' }}
                className="form-control-sm"
                onChange={this.handleChangeCohort}
                defaultValue={currentCohort.id ? currentCohort.id : ''}
                name="selectedCohortId"
                options={options}
              />
            )}

            {users.list && (
              <span>
                Total: {users.list.length}
              </span>
            )}
          </div>

          { children || (
            <div className="p-3">
              <p>Select a Cohort</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  courseCohorts,
  currentCohort,
  users
}) => ({
  courseCohorts,
  currentCohort,
  users
});

export default connect(mapStateToProps, {
  setCurrentCohort,
  resetCurrentCohort,
  getUsers,
  resetUsers
})(CohortContainer);
