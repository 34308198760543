import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import { ICON_COURSE } from '@apprentage/constants';
import {
  getSyndicatedCourses, getSyndicatedCoursesSharing, resetSyndicatedCourses
} from '../../../../actions/Courses';
import { withAuthorization } from '../../../Session';
import { routeWithOrgId } from '../../../../services/organizations';
import { ORG_CONSOLE_DASHBOARD } from '../../../../constants/routes';
import { canAccessCourseSyndication } from '../../../../services/currentUser';
import withOrgConsole from '../../../App/withOrgConsole';
import Tabs from '../Tabs';
import SharedWithOrg from './SharedWithOrg';
import SharedOnTurbine from './SharedOnTurbine';
import OrgConsoleHeader from '../../OrgConsoleHeader';

const Syndication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const role = pathOr([], ['role'], currentUser);
  const orgConsoleDashboardRoute = routeWithOrgId({
    route: ORG_CONSOLE_DASHBOARD,
    orgId
  });
  // Local State

  useEffect(() => {
    dispatch(getSyndicatedCourses({ orgIds: [orgId] }));
    dispatch(getSyndicatedCoursesSharing({ orgId }));
  }, []);

  useEffect(() => {
    if (!canAccessCourseSyndication(role)) {
      history.replace(orgConsoleDashboardRoute);
    }

    return function cleanup() {
      dispatch(resetSyndicatedCourses());
    };
  }, []);

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Courses"
            icon={ICON_COURSE}
            route={ORG_CONSOLE_DASHBOARD}
          />

          <Tabs active="syndication" />

          <div className='bg-white p-3 border-left border-right border-bottom'>
            <SharedWithOrg />
            <SharedOnTurbine />
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Syndication);
