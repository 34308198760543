import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withAuthorization } from '../Session';
import { getCourses } from '../../actions/Courses';
import { getGroups, getOrgCourseGroupIds } from '../../actions/Groups';
import { canAccessByRole } from '../../services/currentUser'; // canAccessCourseCatalog
import sortArrByArr from '../../utils/sortArrByArr';
import isPublished from '../../utils/isPublished';
import Loading from '../Loading';
import SearchBar from './SearchBar';
import Collapse from '../ManageContent/Collapse';
import CollapseControl from './CollapseControl';
import Card from './Card';
import { IMAGE_CARD_TURBINE_COURSE_GEN } from '../../constants/assets';

const Classes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  const courseGroups = useSelector((state) => state.courseGroups);
  // Local State
  const [searchTerm, setSearchTerm] = useState('');
  const [expanded, setExpanded] = useState(false);
  // Misc
  const courseGroupIds = organization?.courseGroupIds || null;
  const orgId = organization?.id || null;
  const enableCourseCatalog = organization?.enableCourseCatalog || null;
  const role = currentUser?.role || [];
  const userId = currentUser?.id || null;

  const filterBySearchTerm = (c) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const title = c.title.toLowerCase().includes(lowerCaseSearchTerm);
    const description = c.description.toLowerCase().includes(lowerCaseSearchTerm);
    const courseTags = c.topics && c.topics.filter((t) => t.toLowerCase().includes(lowerCaseSearchTerm));

    if (title || description || (courseTags && courseTags.length > 0)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    // if (!canAccessCourseCatalog({ enableCourseCatalog })) {
    //   history.push(SLASH);
    //   return null;
    // }

    // dispatch(setCurrentUserAnswers({ userId })); // Used in <Progress />
    dispatch(getOrgCourseGroupIds({ orgId }));
    dispatch(getCourses({ orgId }));
    dispatch(getGroups({ orgId, type: 'course' })).then((groupsResponse) => {
      if (groupsResponse && groupsResponse.list && groupsResponse.list.length === 1) {
        setExpanded(true);
      }
    });
  }, [orgId, userId, enableCourseCatalog, history, dispatch]);

  if (!currentUser) {
    return (
      <Loading />
    );
  }

  let whichGroups = null;
  let whichCourses = null;

  if (courses.list) {
    whichCourses = courses.list.filter((c) => isPublished(c));
    // Exclude test courses if they exist and the user is not a test user
    whichCourses = whichCourses.filter(((c) => {
      if (c.testClass && !currentUser.testUser) {
        return c;
      }

      return c;
    }));

    if (searchTerm) {
      whichCourses = whichCourses.filter((c) => filterBySearchTerm(c));
    }
  }

  if (courseGroups.list) {
    whichGroups = courseGroups.list.filter((g) => isPublished(g));

    if (whichGroups) {
      // only show courseGroups that have courses
      whichGroups = whichGroups.filter((g) => g.itemIds);
    }

    if (whichGroups) {
      // only show courseGroups user has access to
      whichGroups = whichGroups.filter((g) => canAccessByRole(role, g.userRoles));
    }

    // TODO allow group/list button to be available to all users
    // filter courses by group permissions
    // whichCourses = whichGroups.map((group) => group.itemIds && group.itemIds.map(classId => whichCourses.find(course => course.id === classId)))
  }

  if (whichCourses && whichGroups && courseGroupIds) {
    return (
      <>
        <div className="row mb-4">
          <div className="col d-flex align-items-center justify-content-between">
            <SearchBar
              onChange={(newSearchTerm) => {
                setSearchTerm(newSearchTerm);
                setExpanded(true);
                if (newSearchTerm) {
                  setExpanded(false);
                }
              }}
            />

            <div className="d-flex">
              <CollapseControl
                className="mr-1"
                onChange={() => {
                  setExpanded(!expanded);
                }}
                expanded={expanded}
              />
            </div>
          </div>
        </div>

        <div className="row">
          {sortArrByArr(whichGroups, courseGroupIds, 'id').map((group, groupIndex) => {
            let groupCourses = group.itemIds && group.itemIds.map((classId) => whichCourses.find((course) => course.id === classId));

            // If search term is present
            // Don't show groups that are empty
            if (searchTerm) {
              groupCourses = groupCourses.filter((gc) => gc !== undefined);
            }

            if (!groupCourses.length) return null;

            return (
              <Collapse
                key={`${group.id}-${groupIndex}`}
                className="col-sm-12 mb-4"
                title={group.title}
                id={`group-${group.id}-${groupIndex}`}
                // ariaExpanded={!searchTerm}
                ariaExpanded={expanded}
                badge={() => {
                  return (
                    <span
                      className={`ml-1 badge ${searchTerm ? 'badge-primary' : 'bg-white border'}`}
                    >
                      {groupCourses.length}
                    </span>
                  );
                }}
              >
                <div className="row">
                  {groupCourses && groupCourses.map((course, courseIndex) => {
                    if (!course) return null; // Not available
                    if (!course.title) return null;

                    if (course.testClass && !currentUser.testUser) {
                      return null;
                    }

                    return (
                      <Card
                        key={`${course.id}-${courseIndex}`}
                        course={course}
                        className="col-sm-12 col-md-5 col-lg-4 col-xl-3"
                      />
                    );
                  })}
                </div>
              </Collapse>
            );
          })}
        </div>
      </>
    );
  }

  return (
    <div className="w-100 py-5 d-flex justify-content-center flex-column text-center">
      <div className='d-flex justify-content-center'>
        <img
          src={IMAGE_CARD_TURBINE_COURSE_GEN}
          alt='Courses'
          width={350}
        />
      </div>
      <div className="mt-3 w-100 text-center">
        No Courses.
      </div>
    </div>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(Classes);
