import React from 'react';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import WidgetLink from '../../../components/lists/WidgetsList/ListView/WidgetLink';
import './style.css';
import Loading from '../../../components/Loading';

const Apps = ({
  list,
  selected,
  setSelected,
  loading
}) => {
  return (
    <div className="container">
      <div
        className="Apps-row row shadow pb-3 position-relative"
        style={{
          overflowX: 'hidden',
          overflowY: 'scroll',
          height: '220px'
        }}
      >
        {list.map((item) => {
          const isSelected = selected.includes(item.id);

          return (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div
                className={`p-3 mx-1 mt-3 rounded bg-white cursor-pointer ${isSelected ? 'border-primary' : ''}`}
                style={{
                  border: '1px dashed #dee2e6'
                }}
                onClick={() => {
                  if (typeof setSelected === 'function' && !loading) {
                    setSelected(item?.id);
                  }
                }}
                aria-hidden
              >
                <div
                  className="d-flex align-items-center justify-content-between"
                >
                  <WidgetLink
                    image={item.image}
                    title={item.title}
                    body={item.body}
                  />
                  <div>
                    {isSelected ? (
                      <FaCheckSquare size={20} className='text-primary' />
                    ) : (
                      <FaRegSquare size={20} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {loading && (
          <Loading text="Saving context" className='h-100' style={{ top: 0, left: 0 }} />
        )}
      </div>
    </div>
  );
};

export default Apps;
