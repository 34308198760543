import * as twApi from './tw-api';
import { FIREBASE_STORE_COLLECTION } from '../constants/api';

/**
 * @summary saves document to firebase collection
 * @param {object} data
 * @param {string} collection
 */
export const createFirebaseEntry = ({ data, collection }) => {
  return new Promise((resolve, reject) => {
    return twApi
      .post(
        `${FIREBASE_STORE_COLLECTION}/${collection}`,
        '',
        JSON.stringify(data)
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

/**
 * Get firebase document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase document id
 * @param {object} data - firebase document id
 * @returns {Promise<string>} - firebase document object.
 */
export async function updateFirebaseEntry({ collection, id, data }) {
  const dataToSave = JSON.stringify({ id, ...data });
  const response = await twApi.put(
    `${FIREBASE_STORE_COLLECTION}/${collection}`,
    '',
    dataToSave
  );

  return response;
}

/**
 * Get firebase document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase document id
 * @returns {Promise<string>} - firebase document object.
 */
export async function getFirebaseEntry({ collection, id }) {
  const response = await twApi.get(
    `${FIREBASE_STORE_COLLECTION}/${collection}`,
    { id }
  );

  return {
    ...response,
    id
  };
}

/**
 * Delete firebase document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase document id
 * @returns {Promise<string>}
 */
export async function deleteFirebaseEntry({ collection, id }) {
  if (!id) {
    return false;
  }

  const response = await twApi.requestDelete(
    `${FIREBASE_STORE_COLLECTION}/${collection}`,
    { id }
  );

  return response;
}

/**
 * Get firebase documents
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} orgId - organization id (contentful)
 * @returns {Promise<string>} - firebase document object.
 */
export async function getFirebaseEntries({ collection, id, orgId }) {
  const query = {};

  if (id) query.id = id;
  if (orgId) query.orgId = orgId;

  const response = await twApi.get(
    `/v1/external/firebase/store/${collection}`,
    query
  );

  return response;
}

/**
 * [NEW] Get firebase entries
 *
 * @param {} params
 * @param {string} params.collection - firebase collection name
 * @param {string} params.ids - comma separate string of IDs
 *
 * Field queries with operators: (up to 5 supported)
 * @param {string} ['f.locationId[eq]']
 * @param {string} ['f.apprenticeshipIds[ac]']
 *
 * @returns {Promise<string>} - firebase documents
 */
export async function getFirebaseEntriesNew(params, version = 'v1') {
  // fields
  const { collection, order, ...remainingParams } = params;
  let query = {
    ...remainingParams,
    ...(order ? { order } : {})
  };
  /**
   * Query Operators
   *
   * Learn more: https://github.com/upstreamcoding/lms-api#firestore-query-operators
   *
   * Ex: config['f.locationId[eq]'] = locationId;
   * Ex: config['f.apprenticeshipIds[ac]'] = apprenticeshipId;
   *
   */

  if (remainingParams?.ids) {
    query = {
      ids: remainingParams.ids,
      ...(order ? { order } : {})
    }; // ids take precedence over all other queries
  }

  const response = await twApi.get(
    `/${version}/external/firebase/store/entries/${collection}`,
    query
  );

  return response;
}
