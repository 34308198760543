const resourceGroupState = {
  authorUserId: '',
  authorUserName: '',
  groupTags: null, // ['']
  parentId: '',
  orgId: '',
  parentIds: null, // ['']
  title: '',
  topLevel: null,
  userRoles: null, // ['']
  // metadata
  id: '',
  createdAt: '',
  updatedAt: ''
};

export default resourceGroupState;
