import React from 'react';
import withConsole from '../App/withConsole';
import Form from './Form';

const Course = () => {
  return (
    <Form />
  );
};

export default withConsole(Course);
