import React from 'react';
import { Link } from 'react-router-dom';
import { FaFolder } from 'react-icons/fa';
import { ORG_CONSOLE_RESOURCES } from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';

const TitleCell = ({
  value: title,
  row,
  orgId,
  groupIds,
  onClick
}) => {
  const currentGroupIds = groupIds || [];
  const groupId = row?.original?.id || '';
  const routeConfig = {
    route: `${ORG_CONSOLE_RESOURCES}`,
    urlParams: {
      orgId,
      groupIds: [...currentGroupIds, groupId].join(',')
    }
  };

  if (onClick) {
    return (
      <button
        data-cy="ResourcesList-title"
        title={title}
        className="btn btn-sm btn-link d-flex align-items-center text-decoration-none text-nowrap"
        onClick={() => {
          onClick(row?.original);
        }}
        type='button'
      >
        <FaFolder size={22} />
        <span className='ml-2'>
          {title}
        </span>
      </button>
    );
  }

  return (
    <Link
      data-cy="ResourcesList-title"
      title={title}
      className="btn btn-sm btn-link d-flex align-items-center text-decoration-none text-nowrap"
      to={routeWithOrgId(routeConfig)}
    >
      <FaFolder size={22} />
      <span className='ml-2'>
        {title}
      </span>
    </Link>
  );
};

export default TitleCell;
