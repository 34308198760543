import React from 'react';
import { FaCheckCircle, FaEllipsisH, FaTrashAlt } from 'react-icons/fa';
import { isLocalhost } from '../../serviceWorker';
import RenderMarkdown from '../ManageContent/RenderMarkdown';

const Item = ({
  index,
  id,
  innerRef,
  provided,
  featured,
  title,
  type,
  typesReadableHash,
  onClickEdit,
  onClickDelete,
  enableNewEditor,
  plainText = false
}) => {
  const badgeType = (themeType) => {
    switch (themeType) {
      case 'header':
        return 'dark';
      case 'radio':
        return 'info';
      default:
        return 'light';
    }
  };

  return (
    <div
      className="reference d-flex justify-content-between align-items-center border rounded"
      ref={innerRef}
      {...provided.draggableProps}
    >
      <div className="d-flex align-items-center">
        <div
          className="item-drag-handle px-2"
          {...provided.dragHandleProps}
        >
          <i className="fas fa-grip-vertical" />
        </div>

        {/* <button
          className="item-drag-handle py-3 px-2"
          type="button"
          title={`Drag to reorder ${title}`}
          onClick={() => {
            onClickDrag(index);
          }}
        >
          <i className="fas fa-grip-vertical" />
        </button> */}

        <div className="px-3">
          {type && typesReadableHash && (
            <div className={`${type === 'header' ? 'h5' : 'h6'} mt-1 mb-0`}>
              <span className={`badge badge-${badgeType(type)} border`}>
                {typesReadableHash[type]}
              </span>
            </div>
          )}

          {plainText ? (
            <h6 className="my-1">{title}</h6>
          ) : (
            <RenderMarkdown
              source={title}
              enableNewEditor={enableNewEditor}
            />
          )}

          {id && isLocalhost && (
            <small className="p-1 text-nowrap text-muted">ID: {id}</small>
          )}
        </div>
      </div>

      <div className="btn-group align-items-center px-2 py-3">
        {featured && (
          <FaCheckCircle
            className='text-success mx-2'
            title='Correct Answer'
          />
        )}

        <button
          className="btn btn-link btn-sm rounded"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <button
            className="dropdown-item py-2"
            type="button"
            title={`Edit ${title}`}
            onClick={() => {
              onClickEdit(index);
            }}
          >
            Edit
          </button>
          <button
            className="dropdown-item border-top py-2"
            type="button"
            title={`Delete ${title}?`}
            onClick={() => {
              onClickDelete(index);
            }}
          >
            <span className='d-flex align-items-center'>
              <FaTrashAlt className='text-danger' />
              <span className='ml-2'>
                Delete
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Item;
