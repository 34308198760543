import {
  RESET_COURSE_GROUP, SET_COURSE_GROUP
} from '../../../actions/types';
import groupState from '../../initialState/groupState';

const initialState = {
  ...groupState
};

export default function courseGroup(state = initialState, action) {
  switch (action.type) {
    case SET_COURSE_GROUP:
      return {
        ...state,
        ...action.courseGroup,
        itemIds: action.courseGroup.itemIds || initialState.itemIds
      };
    case RESET_COURSE_GROUP:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
