import colorLib from '@kurkle/color';

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function months(config) {
  const count = config?.count || 12;
  const values = [];

  for (let i = 0; i < count; i += i) {
    const value = MONTHS[Math.ceil(i) % 12];
    values.push(value.substring(0, config?.section));
  }

  return values;
}

const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba'
];

export function color(index) {
  return COLORS[index % COLORS.length];
}

export function transparentize(value, opacity) {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  // yellow: 'rgb(255, 205, 86)',
  blueBell: 'rgba(59, 59, 152,1.0)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  // CHART_COLORS.yellow,
  CHART_COLORS.blueBell,
  CHART_COLORS.grey
];

// export const CHART_COLORS_INDIAN = { // Flat UI
//   orchidOrange: 'rgba(254, 164, 127, 1.0)', // #FEA47F
//   spiralDiscoBall: 'rgba(37, 204, 247,1.0)', // #25CCF7
//   honeyGlow: 'rgba(234, 181, 67,1.0)', // #EAB543
//   sweetGarden: 'rgba(85, 230, 193,1.0)', // #55E6C1
//   fallingStar: 'rgba(202, 211, 200,1.0)', // #CAD3C8
//   richGardenia: 'rgba(249, 127, 81,1.0)', // #F97F51
//   clearChill: 'rgba(27, 156, 252,1.0)', // #1B9CFC
//   sarawakWhitePepper: 'rgba(248, 239, 186,1.0)', // #F8EFBA
//   keppel: 'rgba(88, 177, 159,1.0)', // #58B19F
//   shipsOfficer: 'rgba(44, 58, 71,1.0)', // #2C3A47
//   fieryFuchsia: 'rgba(179, 55, 113,1.0)', // #B33771
//   blueBell: 'rgba(59, 59, 152,1.0)', // #3B3B98
//   georgiaPeach: 'rgba(253, 114, 114,1.0)', // #FD7272
//   oasisStream: 'rgba(154, 236, 219,1.0)', // #9AECDB
//   brightUbe: 'rgba(214, 162, 232,1.0)', // #D6A2E8
//   magentaPurple: 'rgba(109, 33, 79,1.0)', // #6D214F
//   endingNavyBlue: 'rgba(24, 44, 97,1.0)', // #182C61
//   sasquatchSocks: 'rgba(252, 66, 123,1.0)', // #FC427B
//   pineGlade: 'rgba(189, 197, 129,1.0)', // #BDC581
//   highlighterLavender: 'rgba(130, 88, 159,1.0)' // #82589F
// };

export function namedColor(index) {
  return NAMED_COLORS[index % NAMED_COLORS.length] || CHART_COLORS.grey;
}
