import {
  RESET_PATHWAYS, SET_PATHWAYS
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function pathways(state = initialState, action) {
  switch (action.type) {
    case SET_PATHWAYS:
      return {
        ...state,
        list: action.list,
        fetched: true
      };
    case RESET_PATHWAYS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
