import React from 'react';
import pluralize from 'pluralize';

const CourseInfo = ({ title, eUnitTracking, courseCodes }) => {
  if (!eUnitTracking) return null;
  const {
    fields: {
      // TODO cohort flatten
      code,
      semesterCode,
      sponsorCodes
    }
  } = eUnitTracking;

  return (
    <div className="course-info">
      <div>Course Title:</div>
      <p>
        <b>{title}</b>
      </p>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Course Code</th>
            {courseCodes && courseCodes.length > 0 && (
              <th scope="col">Course Variants</th>
            )}
            {semesterCode && <th scope="col">Semester</th>}
            {sponsorCodes && (
              <th scope="col">
                Sponsor {pluralize('Number', sponsorCodes.length)}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{code || '-'}</td>
            {courseCodes && courseCodes.length > 0 && (
              <td>{courseCodes.join(', ')}</td>
            )}
            {semesterCode && <td>{semesterCode}</td>}
            {sponsorCodes && <td>{sponsorCodes.join(', ')}</td>}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CourseInfo;
