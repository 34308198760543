import isProgramTrainer from '../isProgramTrainer';
import isProgramManager from '../isProgramManager';
import isAdminOwnerSuperAdmin from '../isAdminOwnerSuperAdmin';

export default function canAccessWorkforceSuite(
  role = [],
  orgType = '',
  enableWorkforceSuite = false
) {
  if (!orgType || !enableWorkforceSuite) {
    return false;
  }

  // if (orgType !== 'community') {
  //   return false;
  // }

  if (isProgramTrainer(role) || isProgramManager(role)) {
    return true;
  }

  return isAdminOwnerSuperAdmin(role);
}
