import React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import { totalSessionCredits } from '../../../services/attendance';
import percentComplete from '../../../utils/percentComplete';
import ManageButton from '../../btns/ManageButton';
import Badge from '../../Badge';
import References from '../../ManageContent/References';
import CohortContainer from './CohortContainer';
import ProgressBars from '../../ProgressBars';

const CeuTracking = () => {
  const course = useSelector((state) => state.course);
  const currentCohort = useSelector((state) => state.currentCohort);
  // misc
  const classId = pathOr(null, ['id'], course);
  const subjects = pathOr(null, ['subjects'], course);
  const eUnitTracking = pathOr(null, ['eUnitTracking', 'fields'], currentCohort); // TODO cohort flatten
  // TODO Expose eUnits and make editable

  if (!currentCohort.id) {
    return (
      <CohortContainer title="Tracking" />
    );
  }

  if (!eUnitTracking) {
    return false;
  }

  const {
    // code,
    // semesterCode,
    trackingInterval,
    sessions,
    currentSession,
    attendancePerSession,
    integration,
    sessionsTopicSync
  } = eUnitTracking;

  // TODO move more things to integration?
  // Redux course has example of what can be put in integration JSON
  const sessionCredits = pathOr(null, ['sessionCredits'], integration);
  const totalSessions = sessionsTopicSync && subjects && subjects.length ? subjects.length : sessions;

  const percentage = percentComplete({
    current: currentSession,
    total: totalSessions
  });

  return (
    <CohortContainer title="Tracking">
      <div className="p-3 mt-3">
        <div className="bd-callout">
          <h5>
            <b>Sessions & Credits</b>
          </h5>

          {currentSession && (
            <>
              <div className="form-group mb-2">
                <label className="mb-1">
                  <b>Progress:</b>
                </label>
                <ProgressBars
                  title="Class Session Progress"
                  size="sm"
                  data={[
                    {
                      className: 'progress-bar-striped bg-info',
                      dataTip: 'Class Session Progress',
                      valueNow: currentSession,
                      valueMin: 0,
                      style: { width: percentage },
                      text: `${currentSession} / ${totalSessions}`
                    }
                  ]}
                />
                <div className="text-muted mt-2 mb-1">
                  Current Session: <span className="h6"><span className="badge border mr-1">{currentSession}</span></span>
                </div>
                <div className="text-muted mb-1">
                  Total Sessions: <span className="h6"><span className="badge border mr-1">{totalSessions}</span></span>
                </div>
              </div>

              <hr />
            </>
          )}

          <div className="form-group mb-3">
            <label className="mb-1">
              <b>Sessions Topic Sync:</b> <Badge type={sessionsTopicSync ? 'primary' : 'secondary'}>{sessionsTopicSync ? 'ON' : 'OFF'}</Badge>
            </label>
            <div className="text-muted medium mb-2">
              Synchronize Sessions with Topics for detailed reporting, including [number of] Sessions, Topic Name, Category and Level.
            </div>
          </div>

          {!sessionsTopicSync && (
            <div className="form-group mb-3">
              <label className="mb-1">
                <b>Total Sessions:</b> <Badge>{sessions}</Badge>
              </label>
              <div className="text-muted medium mb-2">
                Number of sessions in a class.
              </div>
            </div>
          )}

          <div className="form-group mb-3">
            <label className="mb-1">
              <b>Tracking Interval:</b> <Badge>{trackingInterval}</Badge>
            </label>
            <div className="text-muted medium mb-2">
              Number of minutes between each student self check-in.
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-1">
              <b>Attendance Check-ins per Session:</b> <Badge>{attendancePerSession}</Badge>
            </label>
            <div className="text-muted medium mb-2">
              Number of times students must check in per session.
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-1">
              <b>Session Credits:</b> <Badge>{totalSessionCredits({ sessionCredits })}</Badge>
            </label>
            <div className="text-muted medium mb-2">
              Number and type of credits a student can earn per session.
            </div>
          </div>

          <ManageButton
            className="btn btn-md btn-primary"
            classId={classId}
            manageType="edit"
            buttonText="Edit Settings"
            entryId={currentCohort.eUnitTracking.sys.id}
            contentType="eUnitTracking"
          />

        </div>

        {sessionsTopicSync && (
          <div className="bd-callout">
            <h5>
              <b>Sessions Topic Sync</b>
            </h5>

            <div className="form-group mb-3">
              <label className="mb-1">
                <b>Topics:</b> {subjects && subjects.length && (<Badge>{subjects.length}</Badge>)}
              </label>

              {subjects && subjects.length ? (
                <References
                  contents={subjects}
                  classId={classId}
                  // searchParams={searchParams}
                  editContent
                  hidePrerequisite
                />
              ) : (
                <>
                  <div className="text-muted medium mb-2">
                    No topics.
                  </div>
                  <ManageButton
                    className="btn btn-md btn-primary"
                    classId={classId}
                    manageType="edit"
                    buttonText="Edit Topics"
                    contentType="class"
                    scrollToId="class-content"
                  />
                </>
              )}
            </div>

          </div>
        )}
      </div>
    </CohortContainer>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(CeuTracking);
