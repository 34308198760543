import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { isProgramTrainer } from '@apprentage/utils';
import { getUsers, resetUsers } from '../../../../../actions/Users';
import {
  canAccessAllLocations,
  canAccessApprenticeshipEmployers
} from '../../../../../permissions/access';
import NumberStat from '../../../../NumberStat';
import { fetchLogbookRecords } from '../../../../../services/logbookRecords';

const Vitals = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const users = useSelector((state) => state.users);
  //  Organization
  const orgId = organization?.id || '';
  // Apprenticeship
  const apprenticeshipId = params?.apprenticeshipId || '';
  const competencies = Object.keys(apprenticeship?.competencies || {});
  // Current User
  const userId = currentUser?.id || '';
  const locationId = currentUser?.locationId || '';
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const [totalRecords, setTotalRecords] = useState(0);

  const numUsers = useMemo(() => {
    return Array.isArray(users?.list) ? users?.pagination?.total : 0;
  }, [users?.list, users?.pagination?.total]);

  const numEmployers = useMemo(() => {
    return apprenticeship?.employerIds ? apprenticeship.employerIds.length : 0;
  }, [apprenticeship?.employerIds]);

  const numCourses = useMemo(() => {
    return apprenticeship?.courseIds ? apprenticeship.courseIds.length : 0;
  }, [apprenticeship?.courseIds]);

  const numCompetencies = useMemo(() => {
    return competencies.length;
  }, [competencies]);

  useEffect(() => {
    if (apprenticeshipId) {
      fetchLogbookRecords({
        orgId,
        apprenticeshipId,
        count: true,
        // Program Manager or Trainer
        ...(canAccessAllLocations(role) ? {} : { locationId })
      }).then((response) => {
        setTotalRecords(response?.total);
      });
    }
  }, [apprenticeshipId, locationId, orgId, role]);

  useEffect(() => {
    if (apprenticeshipId) {
      const config = {
        orgId,
        roles: ['student'],
        apprenticeshipId,
        excludeInactive: true,
        excludeTestUsers: true,
        // Program Manager or Trainer
        ...(canAccessAllLocations(role) ? {} : { locationId }),
        ...(isProgramTrainer(role) ? { supervisorIds: [userId] } : {})
      };

      dispatch(getUsers(config));
    }

    return function cleanup() {
      dispatch(resetUsers());
    };
  }, [apprenticeshipId, dispatch, locationId, orgId, role, userId]);

  return (
    <div className="row my-3 my-md-4">
      <div
        className="col-6 mb-3"
      >
        <NumberStat
          num={numUsers}
          numTitle={pluralize('Trainee', numUsers)}
        />
      </div>

      {canAccessApprenticeshipEmployers(role) ? (
        <div
          className="col-6 mb-3"
        >
          <NumberStat
            num={numEmployers}
            numTitle={pluralize('Employer', numEmployers)}
          />
        </div>
      ) : (
        <div
          className="col-6 mb-3"
        >
          <NumberStat
            num={totalRecords}
            numTitle={pluralize('Record', totalRecords || 0)}
          />
        </div>
      )}

      <div
        className="col-6 mb-3"
      >
        <NumberStat
          num={numCourses}
          numTitle={pluralize('Course', numCourses)}
        />
      </div>
      <div
        className="col-6 mb-3"
      >
        <NumberStat
          num={numCompetencies}
          numTitle={pluralize('Competency', numCompetencies)}
        />
      </div>
    </div>
  );
};

export default Vitals;
