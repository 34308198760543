// Import the functions you need from the SDKs you need
import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { FIREBASE_API_KEY, FIREBASE_MESSAGING_SENDER_ID } from './constants/firebase';
// import { getFirestore } from '@firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: 'portal-upstreamcoding.firebaseapp.com',
  databaseURL: 'https://portal-upstreamcoding.firebaseio.com',
  projectId: 'portal-upstreamcoding',
  storageBucket: 'portal-upstreamcoding.appspot.com',
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: '1:558430000570:web:cc9ea48376d07364be7983',
  measurementId: 'G-8VXY0CRFY2'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Auth
export const auth = getAuth(app);
// export const database = getFirestore(app);
