import { pathOr } from 'ramda';
import React from 'react';

const CheckBoxCell = ({ row, selectedCourseIds, handleSelectCourse }) => {
  const classId = pathOr(null, ['original', 'id'], row);

  return (
    <div className="d-flex align-items-center font-weight-bold">
      <input
        type="checkbox"
        value={classId}
        checked={selectedCourseIds && selectedCourseIds.includes(classId)}
        onChange={(e) => {
          const { checked } = e.currentTarget;

          handleSelectCourse({ classId, type: checked ? 'add' : 'remove' });
        }}
      />
    </div>
  );
};

export default CheckBoxCell;
