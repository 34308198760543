import React from 'react';
import { useSelector } from 'react-redux';
import Table from './Table';
import NameCell from './NameCell';
import ProfileStatusCell from './ProfileStatusCell';
import WageSchedulesCell from './WageSchedulesCell';
import EmployerAgreementCell from './EmployerAgreementCell';
import ManagersCell from './ManagersCell';

const List = ({
  data,
  wageSchedulesEnum,
  handleClickRow,
  hiddenColumns
}) => {
  const users = useSelector((state) => state.users);

  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props) => (
        <NameCell {...props} />
      )
    },
    {
      Header: 'Managers',
      accessor: 'managerIds',
      Cell: (props) => <ManagersCell {...props} />
    },
    {
      Header: 'Workforce Info.',
      accessor: 'employerProfileId',
      Cell: (props) => (
        <ProfileStatusCell {...props} />
      )
    },
    {
      Header: 'Wage Schedules',
      accessor: 'createdAt',
      Cell: (props) => (
        <WageSchedulesCell
          {...props}
          wageSchedulesEnum={wageSchedulesEnum}
        />
      )
    },
    {
      Header: 'Employer Agreement',
      accessor: 'updatedAt',
      Cell: (props) => (
        <EmployerAgreementCell
          {...props}
        />
      )
    }
  ], [wageSchedulesEnum, users.list]);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="mt-3 w-100 text-center">
          No Employers in this Training Program
        </div>
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      handleClickRow={handleClickRow}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default List;
