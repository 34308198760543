import React from 'react';
import { pathOr } from 'ramda';
import { ICON_MAP_LOCATION } from '@apprentage/constants';
import AddressReadable from '../AddressReadable';
import CurrentWeather from '../Weather/CurrentWeather';
import DotMenu from './DotMenu';
import Alert from '../Alert';

const Location = ({
  data,
  className = '',
  showHeader = true,
  headerTitle = 'Location',
  headerSubtitle = '',
  showWeather = false,
  showMenuManageLinks = false,
  currentWeather = null
}) => {
  // Location
  const name = pathOr(null, ['name'], data);
  const subtitle = pathOr('', ['subtitle'], data);
  const locationImage = pathOr(null, ['locationImage'], data);
  // Address : Location
  const addressLine1 = pathOr('', ['addressLine1'], data);
  const addressLine2 = pathOr('', ['addressLine2'], data);
  const addressCity = pathOr('', ['addressCity'], data);
  const addressState = pathOr('', ['addressState'], data);
  const addressPostalCode = pathOr('', ['addressPostalCode'], data);
  // const addressCountry = pathOr('', ['addressCountry'], data);

  return (
    <div className={`card ${className}`}>
      {showHeader && (
        <div
          className="card-header bg-white px-3 py-2 d-flex align-items-center justify-content-between"
        >
          <div className="d-flex align-items-center">
            <div className='ml-1 text-left'>
              <div className='h5 font-weight-bold m-0'>
                {headerTitle}
              </div>
              {headerSubtitle && (
                <div>
                  {headerSubtitle}
                </div>
              )}
            </div>
          </div>
          <DotMenu
            location={data}
            showMenuManageLinks={showMenuManageLinks}
          />
        </div>
      )}

      <div className="card-body">
        <div className="row">
          {locationImage && (
            <div className="col-md-5">
              <img
                className="featurette-image img-fluid mx-auto"
                src={locationImage}
                alt={name + subtitle}
              />
            </div>
          )}
          <div className="col-md-7">

            <div className=''>
              {!showHeader && (
                <div className="m-0 h4 font-weight-bold">
                  <div className="d-block text-muted">
                    {name}
                  </div>
                  {subtitle && (
                    <small>
                      {subtitle}
                    </small>
                  )}
                </div>
              )}

              {showWeather && currentWeather?.current && currentWeather?.location && (
                <CurrentWeather
                  className='py-3 px-1'
                  current={currentWeather.current}
                  location={currentWeather.location}
                  iconHeight={60}
                  icon
                />
              )}

              <div className='mt-2'>
                <Alert
                  type=""
                  icon={ICON_MAP_LOCATION}
                  className="m-0 mb-5 w-100 border bg-light"
                >
                  <AddressReadable
                    addressLine1={addressLine1}
                    addressLine2={addressLine2}
                    addressCity={addressCity}
                    addressState={addressState}
                    addressPostalCode={addressPostalCode}
                  // addressCountry={addressCountry}
                  />
                </Alert>
              </div>
            </div>

            {!showHeader && (
              <DotMenu
                location={data}
              />
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
