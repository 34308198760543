import React from 'react';
import Table from './Table';
import QuestionsCell from './QuestionsCell';
import TopicCell from './TopicCell';
import ActiveQuiz from './ActiveQuiz';

const QuizzesList = ({
  data,
  classId
}) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Status',
      accessor: 'activeQuiz',
      Cell: (props) => (
        <ActiveQuiz {...props} />
      )
    },
    {
      Header: 'Title',
      accessor: 'title'
    },
    {
      Header: 'Questions',
      accessor: 'questions',
      Cell: (props) => (
        <QuestionsCell {...props} />
      )
    },
    {
      Header: 'Topic',
      accessor: 'topicId',
      Cell: (props) => (
        <TopicCell
          {...props}
        />
      )
    }
  ], [classId]); // editItem

  if (!data || (data && data.length === 0)) {
    return (
      <div className="p-4">
        No Quizzes.
      </div>
    );
  }

  return (
    <Table
      classId={classId}
      columns={columns}
      data={data}
    />
  );
};

export default QuizzesList;
