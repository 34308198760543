import React, { Component, Fragment } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { ICON_TRIANGLE_BANG, ICON_UPLOAD } from '@apprentage/constants';
import { setCurrentModal } from '../../actions/Modals';
import RenderTinyMceEditor from '../ManageContent/Wysiwyg/RenderTinyMceEditor';
import Collapse from '../ManageContent/Collapse';
import Modal from '../Modal';
import Item from './Item';
import Alert from '../Alert';

const questionTypesReadableHash = {
  radio: 'Multiple Choice',
  textarea: 'Long Text',
  text: 'Short Text',
  header: 'Header',
  file: 'Upload File'
};

const questionTypeOptions = Object.entries(questionTypesReadableHash).map(([key, questionType]) => ({
  label: questionType,
  value: key
}));

class Items extends Component {
  constructor(props) {
    super(props);

    const { data, parent, child } = this.props;

    this.state = {
      // Question
      isNewQuestion: null,
      questionIndex: null,
      questionModal: false,
      questionDraft: null,
      questionHasMultipleAnswers: false,
      numCorrectAnswers: 0,
      // Answers
      isNewAnswer: null,
      answerIndex: null,
      answerModal: false,
      answerDraft: null,
      showDialogConfirmDeleteQuestion: false,
      showDialogConfirmDeleteAnswer: false,
      questions: data, // TODO may need to default to []
      parent,
      child
    };

    this.quizQuesTitleRef = React.createRef(); // Quiz/Challenge title (WYSIWYG)
    this.quizQuesTitleInputRef = React.createRef(); // Challenge Header (Input)
    this.quizQuesDescRef = React.createRef();
    this.quizQuesHintRef = React.createRef();
    this.quizQuesOptIncorrectRespRef = React.createRef();
  }

  convertOldEditorToNewEditor = (markdown) => {
    const converter = new Showdown.Converter();
    const html = converter.makeHtml(markdown);

    return html;
  }

  toggleModal = ({ key, val }) => {
    this.setState({
      [key]: val
    });
  }

  // manageQuestion // TODO move to component
  manageQuestion = (index) => {
    const { questions, questionDraft } = this.state;
    const questionId = uuid(); // = index ? questionDraft.id : uuid();
    let questionIndex = index;
    let tempData = {
      ...questionDraft
    };
    let isNewQuestion = false;

    if (questionIndex === undefined) {
      // Add New Question
      questionIndex = (questions && questions.length) || 0;
      // tempData = { title: '', answers: [] };
      tempData = {
        questionId,
        questionType: 'radio'
      };

      isNewQuestion = true;
    } else if (questions && questions[questionIndex]) {
      // Edit Existing Question

      tempData = {
        ...questions[questionIndex]
      };

      // Migrate existing data that do NOT have questionType or questionId
      if (!tempData.questionType) {
        tempData.questionType = 'radio';
      }

      if (!tempData.questionId) {
        tempData.questionId = questionId;
      }
      // /end migrate existing data
    }

    const correctAnswers = tempData.answers && tempData.answers.filter((a) => a.correct);
    const numCorrectAnswers = correctAnswers ? correctAnswers.length : 0;

    if (tempData.description) {
      // TODO check if enableNewEditor is true, only run if it's undefined
      console.log('run conversion');
      tempData.description = this.convertOldEditorToNewEditor(tempData.description);
    }

    this.setState({
      questionDraft: tempData,
      questionHasMultipleAnswers: numCorrectAnswers > 1,
      numCorrectAnswers,
      questionIndex,
      questionModal: true, // open question modal
      isNewQuestion
    });
  }

  closeQuestionModal = () => {
    this.setState({
      questionModal: false,
      questionDraft: null,
      questionHasMultipleAnswers: false,
      numCorrectAnswers: 0,
      isNewQuestion: null
    });
  }

  confirmDeleteQuestion = (i) => {
    this.setState({
      questionIndex: i,
      showDialogConfirmDeleteQuestion: true
    });
  }

  deleteQuestion = () => {
    const { questionIndex, questions } = this.state;
    const questionsNew = [...questions];

    questionsNew.splice(questionIndex, 1);

    this.setState({
      questionIndex: null,
      questions: questionsNew,
      showDialogConfirmDeleteQuestion: false
    }, () => {
      this.props.onUpdate(questionsNew); // Save to parent
    });
  }

  saveQuestion = () => {
    const { contentType } = this.props;
    const { questions, questionIndex, questionDraft } = this.state;
    const { answers, questionType } = questionDraft;

    if (questionType === 'radio') {
      if (!answers || (answers && answers.length < 2)) {
        toast.error('Create at least 2 options');

        return false;
      }

      if (answers && contentType === 'quiz') {
        const correctAnswers = answers.filter((a) => a.correct);
        const numCorrectAnswers = correctAnswers ? correctAnswers.length : 0;

        if (numCorrectAnswers === 0) {
          toast.error('Set at least one option as correct.');

          return false;
        }
      }
    }

    const questionsNew = questions ? [...questions] : [];
    const dataToSave = {
      ...questionDraft
    };

    // Question Title : INPUT
    if (questionDraft.questionType === 'header') {
      if (this.quizQuesTitleInputRef && this.quizQuesTitleInputRef.current) {
        dataToSave.title = this.quizQuesTitleInputRef.current.value;
      }
    }

    // Question Title : Tiny MCE
    if (questionDraft.questionType !== 'header') {
      if (this.quizQuesTitleRef && this.quizQuesTitleRef.current) {
        if (this.quizQuesTitleRef.current.isDirty) {
          dataToSave.title = this.quizQuesTitleRef.current.getContent();
        }
      }
    }

    let titleErrorText = 'Enter a question';

    if (!dataToSave.title || dataToSave.title.length === 0) {
      if (questionDraft.questionType === 'header') {
        titleErrorText = 'Enter a title';
      }

      toast.error(titleErrorText);

      return false;
    }

    if (this.quizQuesDescRef && this.quizQuesDescRef.current && this.quizQuesDescRef.current.isDirty) {
      dataToSave.description = this.quizQuesDescRef.current.getContent();
    }

    if (this.quizQuesHintRef && this.quizQuesHintRef.current && this.quizQuesHintRef.current.isDirty) {
      dataToSave.hint = this.quizQuesHintRef.current.getContent();
    }

    if (this.state.isNewQuestion) {
      questionsNew.push(dataToSave);
    } else {
      questionsNew[questionIndex] = dataToSave;
    }

    this.setState({
      questions: questionsNew
    }, () => {
      this.props.onUpdate(questionsNew); // Save to parent
    });

    this.closeQuestionModal();
  }

  // ManageAnswer // TODO move to component
  manageAnswer = (index) => {
    const {
      questionDraft: { answers: answersDraft },
      answerDraft
    } = this.state;
    let answerIndex = index;
    let tempData = {
      ...answerDraft
    };
    let isNewAnswer = false;

    if (answerIndex === undefined) {
      // Add New Answer
      answerIndex = (answersDraft && answersDraft.length) || 0;
      isNewAnswer = true;
    } else if (answersDraft && answersDraft[answerIndex]) {
      // Edit Existing Answer
      isNewAnswer = false;
      tempData = {
        ...answersDraft[answerIndex]
      };
    }

    this.setState({
      answerDraft: tempData,
      answerIndex,
      answerModal: true, // Open Answer Modal
      isNewAnswer
    });
  }

  confirmDeleteAnswer = (i) => {
    this.setState({
      answerIndex: i,
      showDialogConfirmDeleteAnswer: true
    });
  }

  deleteAnswer = () => {
    const { answerIndex, questionDraft } = this.state;
    const questionNew = { ...questionDraft };
    const answersNew = [...questionDraft.answers];

    answersNew.splice(answerIndex, 1);

    questionNew.answers = answersNew;

    const correctAnswers = questionNew.answers.filter((a) => a.correct);
    const numCorrectAnswers = correctAnswers.length;

    this.setState({
      answerIndex: null,
      questionDraft: questionNew,
      questionHasMultipleAnswers: numCorrectAnswers > 1,
      numCorrectAnswers,
      showDialogConfirmDeleteAnswer: false
    });
  }

  closeAnswerModal = () => {
    this.setState({
      answerModal: false, // Close Answer modal
      answerIndex: null,
      answerDraft: null,
      isNewAnswer: null
    });
  }

  saveAnswer = () => {
    const {
      answerDraft,
      questionDraft,
      isNewAnswer,
      answerIndex
    } = this.state;

    const questionNew = { ...questionDraft };
    const answersNew = questionDraft && questionDraft.answers ? [...questionDraft.answers] : [];

    if (!answerDraft || !answerDraft.text) {
      toast.error('Please complete all required fields');

      return false;
    }

    const dataToSave = {
      ...answerDraft
    };

    if (this.quizQuesOptIncorrectRespRef && this.quizQuesOptIncorrectRespRef.current && this.quizQuesOptIncorrectRespRef.current.isDirty) {
      dataToSave.incorrectResponse = this.quizQuesOptIncorrectRespRef.current.getContent();
    }

    if (isNewAnswer) {
      answersNew.push(dataToSave);
    } else {
      answersNew[answerIndex] = dataToSave;
    }

    questionNew.answers = answersNew;

    const correctAnswers = questionNew.answers.filter((a) => a.correct);
    const numCorrectAnswers = correctAnswers.length;

    this.setState({
      questionDraft: questionNew,
      questionHasMultipleAnswers: numCorrectAnswers > 1,
      numCorrectAnswers,
      answerDraft: null
    }, () => {
      this.closeAnswerModal();
    });
  }

  saveField = (e) => {
    const { currentTarget } = e;
    const {
      name,
      value,
      dataset: {
        temp: tempDataKey
      }
    } = currentTarget;
    const tempData = this.state[tempDataKey];

    this.setState({
      [tempDataKey]: {
        ...tempData,
        [name]: value
      }
    });
  }

  saveCheckboxField = (e) => {
    const { currentTarget } = e;
    const {
      name,
      value,
      checked,
      dataset: {
        temp: tempDataKey
      }
    } = currentTarget;
    const tempData = this.state[tempDataKey];

    const val = (checked) ? value === 'true' : false;
    const data = {
      ...tempData,
      [name]: val
    };

    if (!val) {
      delete data[name];
    }

    this.setState({
      [tempDataKey]: data
    });
  }

  // TODO consolidate with saveField
  saveTextarea = (data) => {
    const { tempDataKey, ...dataToSave } = data;
    const tempData = this.state[tempDataKey];

    this.setState({
      [tempDataKey]: {
        ...tempData,
        ...dataToSave
      }
    });
  }

  onDragEndQuestions = (result) => {
    const {
      destination,
      source: { index: sourceIndex, droppableId: sourceDropId }
    } = result;

    if (!destination) return false;

    const { index: destinationIndex, droppableId: destinationDropId } = destination;

    if (destinationDropId === sourceDropId && destinationIndex === sourceIndex) {
      return false;
    }

    const { questions } = this.state;
    const questionsNew = Array.from(questions);
    const questionToMove = questionsNew.splice(sourceIndex, 1);

    questionsNew.splice(destinationIndex, 0, questionToMove[0]);

    this.setState({
      questions: questionsNew
    }, () => {
      this.props.onUpdate(questionsNew); // Save to parent
    });
  }

  onDragEndAnswers = (result) => {
    const {
      destination,
      source: { index: sourceIndex, droppableId: sourceDropId }
    } = result;

    if (!destination) return false;

    const { index: destinationIndex, droppableId: destinationDropId } = destination;

    if (destinationDropId === sourceDropId && destinationIndex === sourceIndex) {
      return false;
    }

    const { questionDraft } = this.state;
    const answersNew = Array.from(questionDraft.answers);
    const answerToMove = answersNew.splice(sourceIndex, 1);

    answersNew.splice(destinationIndex, 0, answerToMove[0]);

    questionDraft.answers = answersNew;

    this.setState({
      questionDraft
    });
  }

  importQuestions = () => {
    const { questions } = this.state;

    this.props.setCurrentModal({
      key: 'importQuizQuestions',
      handleImportSuccess: (importedQuestions) => {
        const updatedQuestions = questions && questions.length ? [...questions, ...importedQuestions] : importedQuestions;

        this.props.onUpdate(updatedQuestions);
      }
    });
  }

  render() {
    const {
      className, contentType, contentId, enableNewEditor
    } = this.props;

    const {
      questions,
      answerDraft,
      questionDraft,
      questionHasMultipleAnswers,
      numCorrectAnswers,
      parent,
      child
    } = this.state;

    const dialogConfirmDeleteQuestion = {
      visible: this.state.showDialogConfirmDeleteQuestion,
      title: 'Confirmation',
      text: 'Are you sure you want to delete this question?',
      primaryButton: {
        func: this.deleteQuestion,
        text: 'Yes'
      },
      secondaryButton: {
        func: () => {
          this.setState({
            showDialogConfirmDeleteQuestion: false
          });
        }
      },
      close: () => {
        this.setState({
          showDialogConfirmDeleteQuestion: false
        });
      }
    };

    const dialogConfirmDeleteAnswer = {
      visible: this.state.showDialogConfirmDeleteAnswer,
      title: 'Confirmation',
      text: 'Are you sure you want to delete this option?',
      primaryButton: {
        func: this.deleteAnswer,
        text: 'Yes'
      },
      secondaryButton: {
        func: () => {
          this.setState({
            showDialogConfirmDeleteAnswer: false
          });
        }
      },
      close: () => {
        this.setState({
          showDialogConfirmDeleteAnswer: false
        });
      }
    };

    return (
      <Fragment>
        <div
          className={`references-container ${className || ''}`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <label className="mb-2">
              <span className='h6'>
                <span className='badge bg-white border mr-1'>
                  {questions && questions.length ? questions.length : 0}
                </span>
              </span>
              <span>
                <b>{parent.title}:</b> <span className="text-danger">*</span>
              </span>
            </label>

            {questions && questions.length > 0 && (
              <div className="d-flex align-items-center text-muted justify-content-end">
                <i className={`${ICON_TRIANGLE_BANG} mr-1 text-warning`} />
                <span className="small">
                  Save changes before and after reordering questions.
                </span>
              </div>
            )}
          </div>

          {questions && questions.length > 0 && (
            <DragDropContext
              onDragEnd={this.onDragEndQuestions}
            >
              <Droppable droppableId="dnd-column--quiz">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="references references-manage rounded border p-1"
                  >
                    {questions.map((question, i) => (
                      <Draggable
                        draggableId={`question-${i}`}
                        index={i}
                        key={i}
                      >
                        {(providedItem) => (
                          <Item
                            index={i}
                            enableNewEditor={enableNewEditor}
                            provided={providedItem}
                            innerRef={providedItem.innerRef}
                            title={question.title}
                            id={question.questionId}
                            type={question.questionType}
                            typesReadableHash={questionTypesReadableHash}
                            onClickEdit={this.manageQuestion}
                            onClickDelete={this.confirmDeleteQuestion}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}

          <div
            className="references-actions mt-1 mb-4"
          >
            <div className="border w-100 py-2 rounded d-flex align-items-center justify-content-between">
              <button
                className="btn btn-link ml-2"
                onClick={() => {
                  this.manageQuestion(undefined);
                }}
                type="button"
              >
                <i className="fas fa-plus-circle mr-2" />
                <span>
                  Question
                </span>
              </button>
              {contentType === 'quiz' && (
                <button
                  className="btn btn-link"
                  onClick={() => {
                    this.importQuestions();
                  }}
                  type="button"
                >
                  <i className={`${ICON_UPLOAD} mr-2`} />
                  <span>
                    Import Questions
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>

        <Modal
          visible={this.state.questionModal}
          close={false}
        >
          <div className="card">
            <div className="card-header">
              <h5 className="m-0 font-weight-bold">
                Question
              </h5>
            </div>
            <div className="card-body">
              <div className="mb-3">
                <label className="mb-1">
                  <b>Type:</b> <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center">
                  <select
                    type="text"
                    className="form-control"
                    name="questionType"
                    defaultValue={(questionDraft && questionDraft.questionType)}
                    style={{ width: '170px' }}
                    data-temp="questionDraft"
                    onChange={this.saveField}
                    disabled={contentType === 'quiz'}
                    required
                  >
                    {questionTypeOptions.map(({ label, value }, i) => (
                      <option key={i} value={value}>
                        {label || value}
                      </option>
                    ))}
                  </select>
                  {contentType === 'quiz' && (
                    <i className="fas fa-lock ml-2" />
                  )}
                </div>
              </div>

              <div className="">
                <label htmlFor="quiz-question-title" className="mb-2">
                  <b>{questionDraft && questionDraft.questionType === 'header' ? 'Title' : 'Question'}:</b> <span className="text-danger">*</span>
                </label>

                {questionDraft && questionDraft.questionType === 'header' && (
                  <input
                    id="quiz-question-title"
                    type="text"
                    className="form-control mb-3"
                    name="title"
                    defaultValue={(questionDraft && questionDraft.title) || ''}
                    data-temp="questionDraft"
                    ref={this.quizQuesTitleInputRef}
                    required
                    autoFocus
                  />
                )}

                {questionDraft && questionDraft.questionType !== 'header' && (
                  <RenderTinyMceEditor
                    id="quiz-question-title"
                    contentId={contentId}
                    contentType={contentType}
                    defaultValue={(questionDraft && questionDraft.title) || ''}
                    editorRef={this.quizQuesTitleRef}
                    uploadPdfButton={false}
                    embedMediaButton={false}
                    uploadImgButton={false}
                    forcedRootBlock='div'
                    required
                    autoFocus
                  />
                )}
              </div>

              {questionDraft && questionDraft.questionType === 'radio' && (
                <div className="mb-4 references-container">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="mb-2">
                      <b>{child.title}:</b> <span className="text-danger">*</span>
                    </label>
                    {questionDraft && questionDraft.answers && questionDraft.answers.length > 0 && (
                      <div className="d-flex align-items-center text-muted justify-content-end">
                        <i className={`${ICON_TRIANGLE_BANG} mr-1 text-warning`} />
                        <span className="small">
                          Save changes before and after reordering options.
                        </span>
                      </div>
                    )}
                  </div>

                  {questionDraft && questionDraft.answers && questionDraft.answers.length > 0 && (
                    <DragDropContext
                      onDragEnd={this.onDragEndAnswers}
                    >
                      <Droppable droppableId="dnd-column--quiz-answers">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="references rounded border p-1"
                          >
                            {questionDraft.answers && questionDraft.answers.map(({ text, correct }, i) => (
                              <Draggable
                                draggableId={`quiz-answer-${i}`}
                                index={i}
                                key={i}
                              >
                                {(providedItem) => (
                                  <Item
                                    index={i}
                                    provided={providedItem}
                                    innerRef={providedItem.innerRef}
                                    title={text}
                                    plainText // TODO shouldn't this be plainText={plainText}
                                    enableNewEditor={enableNewEditor}
                                    featured={correct}
                                    onClickEdit={() => {
                                      this.manageAnswer(i);
                                    }}
                                    onClickDelete={() => {
                                      this.confirmDeleteAnswer(i);
                                    }}
                                  />
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  )}

                  {questionHasMultipleAnswers && (
                    <Alert type="info" icon="fas fa-info-circle" className="mt-2 mb-0 w-100">
                      You've marked {numCorrectAnswers} options as correct. A question is only deemed correct if a user chooses {numCorrectAnswers === 2 ? 'both' : `all ${numCorrectAnswers}`} correct options.
                    </Alert>
                  )}

                  <div className="references-actions mt-1">
                    <div className="border w-100 py-2 rounded">
                      <button
                        className="btn btn-link ml-2"
                        onClick={this.manageAnswer.bind(this, undefined)}
                        type="button"
                      >
                        <i className="fas fa-plus-circle mr-2" />
                        <span>Option</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <Collapse
                title="Description"
                id="quizQuestDesc"
              >
                <RenderTinyMceEditor
                  id="quiz-question-description"
                  contentId={contentId}
                  contentType={contentType}
                  defaultValue={(questionDraft && questionDraft.description) || ''}
                  editorRef={this.quizQuesDescRef}
                  uploadPdfButton={false}
                />
              </Collapse>

              {contentType === 'quiz' && (
                <div
                  className="accordion mb-3"
                  id="questions-optional-items-accordion"
                >
                  <Collapse
                    title="Hint"
                    id="quizQuestionHint"
                  >
                    <div className='text-muted mb-2'>
                      Give users helpful information to successfully answer this question.
                    </div>
                    <RenderTinyMceEditor
                      id="quiz-question-hint"
                      contentId={contentId}
                      contentType={contentType}
                      defaultValue={(questionDraft && questionDraft.hint) || ''}
                      editorRef={this.quizQuesHintRef}
                      uploadPdfButton={false}
                      embedMediaButton={false}
                      forcedRootBlock='div'
                    />
                  </Collapse>

                  <Collapse
                    title="Cumulative Exam"
                    id="quizQuestNum"
                  >
                    <label htmlFor="questionTitle" className="mb-2">
                      <b>Question Number</b> (optional)
                    </label>
                    <div>
                      Enter a number to be displayed next to the question.
                    </div>
                    <input
                      id="questionNumber"
                      type="number"
                      className="form-control"
                      name="questionNumber"
                      defaultValue={(questionDraft && questionDraft.questionNumber) || ''}
                      data-temp="questionDraft"
                      onChange={this.saveField}
                    />
                    <small className="mt-1 text-muted d-flex align-items-center">
                      Ex: 9
                    </small>
                  </Collapse>
                </div>
              )}
            </div>
            {/* card-body */}

            <div className="card-footer">
              <button
                className="btn btn-md btn-primary"
                onClick={this.saveQuestion}
                type="button"
              >
                Save
              </button>
              <button
                className="btn btn-link ml-3"
                onClick={this.closeQuestionModal}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          cssClassName='turbine-modal--style-card turbine-modal--quiz-answer'
          visible={this.state.answerModal}
          close={false}
          pos="middle"
        >
          <div className="card">
            <div className="card-header h6 font-weight-bold">
              Option
            </div>
            <div className="card-body">
              <div className="mb-3">
                <label htmlFor="newAnswerText" className="mb-2">
                  <b>Label</b> <span className="text-danger">*</span>
                </label>
                <input
                  id="newAnswerText"
                  type="text"
                  className="form-control"
                  name="text"
                  defaultValue={(answerDraft && answerDraft.text) || ''}
                  data-temp="answerDraft"
                  onChange={this.saveField}
                  required
                  autoFocus
                />
                <small className="mt-1 text-muted d-flex align-items-center">
                  Ex: Can't tune a fish
                </small>
              </div>

              {contentType === 'quiz' && (
                <div className="mb-3">
                  <input
                    id="correct"
                    name="correct"
                    className="mr-2"
                    type="checkbox"
                    data-temp="answerDraft"
                    value
                    onChange={this.saveCheckboxField}
                    defaultChecked={answerDraft && answerDraft.correct === true}
                  />
                  <label htmlFor="correct">
                    Correct Answer
                  </label>
                </div>
              )}

              {contentType === 'quiz' && answerDraft && !answerDraft.correct && (
                <Collapse
                  title="Incorrect Response Feedback"
                  id="quizQuestOptionIncorrectResponse"
                >
                  <div className="text-muted medium mb-2">
                    Inform the user why this is the incorrect choice.
                  </div>
                  <RenderTinyMceEditor
                    id="quiz-question-option-incorrect-response"
                    contentId={contentId}
                    contentType={contentType}
                    defaultValue={(answerDraft && answerDraft.incorrectResponse) || ''}
                    editorRef={this.quizQuesOptIncorrectRespRef}
                    uploadPdfButton={false}
                    embedMediaButton={false}
                    forcedRootBlock='div'
                  />
                  <div className="mt-1 text-muted ">
                    <b>Note:</b> Only a Guided Quiz feature.
                  </div>
                </Collapse>
              )}
            </div>
            <div className="card-footer">
              <button
                className="btn btn-md btn-primary"
                onClick={this.saveAnswer}
                type="button"
              >
                Save
              </button>
              <button
                className="btn btn-link ml-3"
                onClick={this.closeAnswerModal}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {dialogConfirmDeleteQuestion.visible && (
          <Modal.Dialog {...dialogConfirmDeleteQuestion} />
        )}

        {dialogConfirmDeleteAnswer.visible && (
          <Modal.Dialog {...dialogConfirmDeleteAnswer} />
        )}
      </Fragment>
    );
  }
}

export default connect(null, { setCurrentModal })(Items);
