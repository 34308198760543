import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { MANAGE_CURRENT_ENTRY } from '../../../../constants/routes';
import { canManageCourseContent } from '../../../../services/currentUser';
import { manageEntry } from '../../../../services/entry';

const TopicCell = ({ value: topicId }) => {
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const courseTopics = useSelector((state) => state.courseTopics);
  const course = useSelector((state) => state.course);
  // Course
  const classId = course?.id || null;
  // Organization
  const orgType = organization?.type || '';

  if (!courseTopics.list) {
    return null;
  }

  const manageCourse = canManageCourseContent({ course, currentUser, orgType });

  const topic = courseTopics.list.find((t) => t.id === topicId);
  let topicTitle = topicId;

  if (topic) {
    const { title } = topic;
    topicTitle = title;
  }

  return (
    <Fragment>
      {(manageCourse && classId) ? (
        <Link
          to={{
            pathname: MANAGE_CURRENT_ENTRY,
            search: manageEntry({
              manageType: 'edit',
              entryId: topicId,
              topicId,
              classId,
              contentType: 'topic'
            })
          }}
          title={`Edit ${topicTitle}`}
          className='btn-link'
        >
          <div
            className='overflow-hidden text-nowrap text-ships-officer'
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '250px'
            }}
          >
            {topicTitle}
          </div>
        </Link>
      ) : (
        <div
          className='overflow-hidden text-nowrap'
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '250px'
          }}
        >
          {topicTitle}
        </div>
      )}
    </Fragment>
  );
};

export default withRouter(TopicCell);
