import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import sortArrByKey from '../../../utils/sortArrByKey';
import {
  MERCHANT_MARINE_BLUE,
  TURKISH_AQUA_GREEN,
  PUFFINS_BILL_ORANGE,
  CIRCUMORBITAL_RING_PURPLE,
  BARA_RED,
  SUNFLOWER_YELLOW,
  //
  HOLLY_COCK_MAGENTA,
  MEDITERRANEAN_SEA_BLUE,
  RADIANT_YELLOW,
  PIXELATED_GRASS_GREEN,
  FORGOTTEN_PURPLE,
  QUINCE_JELLY,
  //
  CARMINE_PINK,
  DEEP_KAOMARU_BLUE,
  TURBO_YELLOW,
  PURE_APPLE_GREEN,
  LIVID_PURPLE,
  ICELAND_POPPY_ORANGE
} from '../../../constants/dataVisualization';

const SkillGainChart = ({
  title,
  usersSkills,
  className = ''
}) => {
  if (!usersSkills) return null;
  const skills = Object.entries(usersSkills).map(([key, obj]) => obj); // eslint-disable-line no-unused-vars
  const sortedSkills = sortArrByKey(skills, 'name');
  const labels = sortedSkills.map(({ name }) => name);
  const data = sortedSkills.map(({ outcomes }) => outcomes);

  if (!labels || !data) return null;

  const backgroundColors = [
    BARA_RED,
    MERCHANT_MARINE_BLUE,
    SUNFLOWER_YELLOW,
    TURKISH_AQUA_GREEN,
    CIRCUMORBITAL_RING_PURPLE,
    PUFFINS_BILL_ORANGE,
    //
    HOLLY_COCK_MAGENTA,
    MEDITERRANEAN_SEA_BLUE,
    RADIANT_YELLOW,
    PIXELATED_GRASS_GREEN,
    FORGOTTEN_PURPLE,
    QUINCE_JELLY,
    //
    CARMINE_PINK,
    DEEP_KAOMARU_BLUE,
    TURBO_YELLOW,
    PURE_APPLE_GREEN,
    LIVID_PURPLE,
    ICELAND_POPPY_ORANGE
  ];
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Users',
        data,
        backgroundColor: backgroundColors
      }
    ]
  };

  const options = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom'
      }
    }
  };

  return (
    <div className={className}>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <span className="h5 m-0 font-weight-bold">
            {title} <sup className="text-muted">*</sup>
          </span>
          <span className="h5 m-0">
            <span className="py-2 px-3 badge bg-light border">
              {labels.length}
            </span>
          </span>
        </div>
        <div className="card-body">
          <Chart type="bar" data={chartData} options={options} />
        </div>
        <div className="card-footer text-muted">
          <sup>*</sup>Skills users earned by successfully completing
          <span className="h5 m-0 mx-1">
            <span className="badge bg-light border">
              quizzes
            </span>
          </span>
          &
          <span className="h5 m-0 ml-1">
            <span className="badge bg-light border">
              challenges
            </span>
          </span>.
        </div>
      </div>
    </div>
  );
};

export default SkillGainChart;
