import { toast } from 'react-toastify';
import {
  createSupabaseEntry,
  deleteSupabaseEntry,
  fetchSupabaseEntries,
  fetchSupabaseEntry,
  getSupabaseLogbookHours,
  updateSupabaseEntry
} from './supabaseProxy';

const table = 'logbooks';

export const colHeaderClassNames = (colId) => {
  switch (colId) {
    case 'recordsHoursApproved':
    case 'recordsHours':
    case 'recordsCount':
      return 'bg-black text-white';
    default:
      return '';
  }
};

/**
   * @param {Array} ids
   * @param {String} orgId
   */
export const fetchLogbookHours = ({
  orgId, // required
  logbookIds,
  locationId,
  apprenticeshipId,
  gteDate = undefined,
  lteDate = undefined,
  showCompHours = false
}) => {
  return new Promise((resolve, reject) => {
    const config = {
      orgId,
      logbookIds,
      ...(locationId ? { locationId } : {}),
      ...(apprenticeshipId ? { apprenticeshipId } : {}),
      ...(gteDate ? { gteDate } : {}),
      ...(lteDate ? { lteDate } : {}),
      ...(showCompHours ? { showCompHours } : {})
    };

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getSupabaseLogbookHours(config, table).then(async (response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`fetch ${table}`, error);
      reject(error);
    });
  });
};

const correlateLogbooksAndRecords = (logbooks, logbooksHours) => {
  if (!logbooks || !logbooksHours) {
    return logbooks;
  }

  let logbooksHoursHash = {};

  if (logbooksHours?.items) {
    logbooksHoursHash = logbooksHours?.items.reduce((acc, curr) => {
      return {
        ...acc,
        [curr?.logbookId]: curr
      };
    }, {});
  }

  return logbooks.map((logbook) => ({
    ...logbook,
    recordsHours: logbooksHoursHash[logbook?.id]?.totalHours,
    recordsCount: logbooksHoursHash[logbook?.id]?.totalRecords,
    recordsHoursApproved: logbooksHoursHash[logbook?.id]?.approvedHours,
    recordsCompetencyHours: logbooksHoursHash[logbook?.id]?.competencyHours
  }));
};

/**
   * @param {Array} ids
   * @param {String} orgId
   */
export const fetchLogbooks = ({
  ids = [],
  userId = undefined,
  apprenticeshipId = undefined,
  employerId = undefined,
  orgId = undefined
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    if (apprenticeshipId !== undefined) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (employerId !== undefined) {
      config['f.employerId[eq]'] = employerId;
    }

    if (userId !== undefined) {
      config['f.userId[eq]'] = userId;
    }

    if (orgId !== undefined) {
      config['f.orgId[eq]'] = orgId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    fetchSupabaseEntries(config, table).then(async ({ items }) => {
      const list = items.length ? items : null;
      let records = null;

      if (list) {
        // records = await fetchRecordsFromLogbooks(list);
        records = await fetchLogbookHours({
          orgId,
          logbookIds: list ? list.map((l) => l.id) : null
          // showCompHours: true
        });
        const logbooksWithRecords = correlateLogbooksAndRecords(list, records);

        resolve(logbooksWithRecords);
        return;
      }

      resolve(records);
    }).catch((error) => {
      console.error(`fetch ${table}`, error);
      reject(error);
    });
  });
};

/**
   *
   * @param {string} id
   */
export const fetchLogbook = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const logbook = response || null;

        resolve(logbook);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const createLogbook = (data) => {
  return new Promise((resolve) => {
    createSupabaseEntry({ data, table })
      .then((response) => {
        const id = response?.id || null;

        resolve(id);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`create ${table}`, error);
      });
  });
};

/**
   *
   * @param {string} id
   * @param {object} data
   */
export const updateLogbook = (data, id) => {
  return new Promise((resolve) => {
    updateSupabaseEntry({
      table,
      data,
      id
    })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`update ${table}`, error);
      });
  });
};

/**
   *
   * @param {string} id
   * @param {object} data
   */
export const deleteLogbook = (id) => {
  return new Promise((resolve) => {
    deleteSupabaseEntry({
      data: { id },
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`delete ${table}`, error);
    });
  });
};
