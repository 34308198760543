import React, { useEffect, useMemo, useState } from 'react';
import { FaLink } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { fetchWidgets } from '../../../services/widgets';

const Tools = () => {
  const organization = useSelector((state) => state.organization);
  const orgId = organization?.id || null;
  const [widgetsList, setWidgetsList] = useState([]);
  const linksLimit = 5;

  useEffect(() => {
    fetchWidgets({ orgId, type: 'link', limit: linksLimit }).then((response) => {
      if (Array.isArray(response?.list) && response.list.length > 0) {
        setWidgetsList(response?.list);
      }
    });
  }, [orgId]);

  const links = useMemo(() => {
    const numWidgets = widgetsList ? widgetsList.length : 0;
    const numDefaultLinks = numWidgets < linksLimit ? linksLimit - numWidgets : 0;
    const defaultLinks = Array(numDefaultLinks).fill({});

    return widgetsList.concat(defaultLinks);
  }, [widgetsList]);
  console.log('links', links);

  return (
    <div className='mb-3'>
      <div className='h5 mb-2 d-flex align-items-center'>
        <span>
          Tools
        </span>
      </div>

      <div className='d-flex'>
        {links.map((link, i) => (
          <div
            key={`tool-${i}`}
            className='p-3 mr-3 rounded d-flex align-items-center justify-content-center bg-white text-muted'
            style={{
              border: '1px dashed #dee2e6',
              width: '30%'
            }}
          >
            {link?.image ? (
              <img src={link?.image} alt={link.name} height={30} width={30} />
            ) : (
              <FaLink size={30} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tools;
