import {
  reactSelectDefaultValue, reactSelectOptionsFromArray, sortArrByKey
} from '@apprentage/utils';
import React from 'react';
import SelectMenu from './SelectMenu';

const OrganizationSelect = ({
  id = 'selectedOrgId',
  name = 'selectedOrgId',
  onChange,
  data,
  className = '',
  defaultValue = ''
}) => {
  const sortedData = sortArrByKey(data, 'name');
  const options = reactSelectOptionsFromArray(sortedData, 'name');
  const defaultVal = reactSelectDefaultValue(defaultValue, options);

  return (
    <div className={className}>
      <SelectMenu
        id={id}
        name={name}
        options={options}
        defaultValue={defaultVal}
        // isMulti
        onChange={onChange}
      />
    </div>
  );
};

export default OrganizationSelect;
