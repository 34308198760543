import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaPlus } from 'react-icons/fa6';
import { RiLayoutMasonryFill } from 'react-icons/ri';
import { Redirect, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  DASHBOARD_DEFAULT_LAYOUT,
  DASHBOARD_MODULE_TYPE,
  LAYOUTS
} from '../../../constants/dashboard';
import { withAuthorization } from '../../Session';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
import {
  MODAL_KEY_DASHBOARD_CHANGE_LAYOUT,
  MODAL_KEY_DASHBOARD_MODULE_CONFIG,
  MODAL_KEY_DASHBOARD_NEW_MODULE,
  MODAL_KEY_DASHBOARD_REORDER_LINK_WIDGETS
} from '../../../constants/modals';
import {
  fetchDashboardByType,
  updateDashboardData
} from '../../../services/dashboard';
import { SLASH } from '../../../constants/routes';
import { canManageOrgDashboard } from '../../../permissions/manage';
import Module from './Module';
import Loading from '../../Loading';
import OrgConsoleHeader from '../../OrgConsole/OrgConsoleHeader';
import DotMenu from './DotMenu';

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const role = currentUser?.role || [];
  // Local State
  const [isLoading, setIsLoading] = useState({});

  const [dashboardData, setDashboardData] = useState({});
  const [zones, setZones] = useState({
    zone1: [],
    zone2: [],
    zone3: []
  });

  const defaultLayout = dashboardData?.activeLayout || DASHBOARD_DEFAULT_LAYOUT;
  const [activeLayout, setActiveLayout] = useState(defaultLayout);
  const [selectedLayout, setSelectedLayout] = useState(defaultLayout);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const newZonesData = { ...zones };
      const zone = newZonesData[source.droppableId];
      const [removed] = zone.splice(source.index, 1);
      zone.splice(destination.index, 0, removed);

      setZones(newZonesData);
    } else {
      const sourceZone = zones[source.droppableId];
      const destZone = zones[destination.droppableId];
      const [removed] = sourceZone.splice(source.index, 1);
      destZone.splice(destination.index, 0, removed);

      setZones({ ...zones });
    }
  };

  /** Add New Module */
  const handleAddNewModule = (zone) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_DASHBOARD_NEW_MODULE,
        callbackPrimaryAction: (moduleType) => {
          const newUuid = uuid();

          setZones((prevState) => {
            prevState[zone]?.push({
              moduleType,
              id: newUuid,
              ...(moduleType === DASHBOARD_MODULE_TYPE.notifications
                ? {
                  showOnlyPinned: false,
                  limit: 3
                }
                : {
                  selectedIds: []
                })
            });
            return { ...prevState };
          });

          dispatch(resetCurrentModal());

          setTimeout(() => {
            const editModuleBtn = document.getElementById(`module-settings-${newUuid}`);

            if (editModuleBtn) {
              editModuleBtn.click();
            }
          }, [150]);
        }
      })
    );
  };

  /** Save Dashboard Data */
  const handleSaveDashboard = () => {
    const data = {
      ...zones,
      ...(selectedLayout !== activeLayout
        ? { activeLayout: selectedLayout }
        : {})
    };
    updateDashboardData(data, dashboardData.id).then((response) => {
      if (response?.id) {
        history.push(SLASH);
      }
    });
  };

  /** Handle Delete Module */
  const handleDeleteModule = (zoneId, arrayIndex) => {
    setZones((prevZones) => {
      prevZones[zoneId].splice(arrayIndex, 1);
      return {
        ...prevZones
      };
    });
  };

  const handleOnclickReorderMenu = (zoneId, index) => {
    const data = zones[zoneId][index];
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_DASHBOARD_REORDER_LINK_WIDGETS,
        data: {
          moduleType: data?.moduleType,
          selectedItemsList: data?.selectedIds
        },
        callbackPrimaryAction: (reorderedData) => {
          // console.log('ReorderedData:', reorderedData);
          // console.log('moduleData:', data.selectedIds);
          handleReorderLinkWidgets(zoneId, index, reorderedData);
        }
      })
    );
  };

  const handleReorderLinkWidgets = (zoneId, index, reorderedLinkWidgets) => {
    setZones((prevZones) => {
      const newArr = prevZones[zoneId];
      newArr[index] = {
        ...newArr[index],
        selectedIds: [...reorderedLinkWidgets]
      };
      prevZones[zoneId] = newArr;
      return { ...prevZones };
    });
  };

  const handleSubmitEditModule = (
    zoneKey,
    arrayIndex,
    moduleData,
    moduleType
  ) => {
    setZones((prevZones) => {
      const newArr = prevZones[zoneKey];
      newArr[arrayIndex] = {
        ...newArr[arrayIndex],
        ...(moduleType === DASHBOARD_MODULE_TYPE.notifications
          ? {
            showOnlyPinned: moduleData?.showOnlyPinned,
            limit: moduleData?.limit
          }
          : {
            selectedIds: moduleData?.selectedIds || []
          })
      };
      prevZones[zoneKey] = newArr;
      return { ...prevZones };
    });
  };

  /** Handle Edit Module */
  const handleModuleConfig = (moduleType, zoneId, index) => {
    const data = zones[zoneId][index];
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_DASHBOARD_MODULE_CONFIG,
        data: {
          moduleType,
          ...(moduleType === DASHBOARD_MODULE_TYPE.notifications
            ? {
              showOnlyPinnedNotifications: data.showOnlyPinned || false,
              notificationsLimit: data.limit || 0,
              selectedIds: []
            }
            : {
              showOnlyPinnedNotifications: false,
              notificationsLimit: 0,
              selectedIds: data.selectedIds || []
            })
        },
        callbackPrimaryAction: (moduleData) => {
          handleSubmitEditModule(zoneId, index, moduleData, moduleType);
        }
      })
    );
  };

  /** Get Dashboard Data */
  const getDashboardData = useCallback(() => {
    setIsLoading(true);
    fetchDashboardByType({ orgId }).then((response) => {
      setIsLoading(false);
      if (response !== null) {
        setZones((prevZones) => ({
          ...prevZones,
          zone1: response?.zone1 || [],
          zone2: response?.zone2 || [],
          zone3: response?.zone3 || []
        }));
        setDashboardData({
          ...response
        });
        setActiveLayout(response?.activeLayout);
        setSelectedLayout(response?.activeLayout);
      }
    });
  }, [orgId]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  if (!canManageOrgDashboard(role)) {
    return <Redirect to={SLASH} />;
  }

  return (
    <div className="container my-3">
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <div className="row">
            <OrgConsoleHeader
              pageTitle="Configure Dashboard"
              route={SLASH}
              className="mb-2 mb-md-4 w-100"
            >
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                onClick={() =>
                  dispatch(
                    setCurrentModal({
                      key: MODAL_KEY_DASHBOARD_CHANGE_LAYOUT,
                      data: {
                        activeLayout,
                        selectedLayout
                      },
                      callbackPrimaryAction: setSelectedLayout
                    })
                  )}
              >
                <span className="d-flex align-items-center">
                  <RiLayoutMasonryFill />
                  <span className="ml-2">Change Layout</span>
                </span>
              </button>
            </OrgConsoleHeader>
          </div>
          <div className="row">
            <DragDropContext onDragEnd={onDragEnd}>
              {Object.keys(zones).map((zoneId, zoneIndex) => (
                <Droppable
                  key={zoneId}
                  droppableId={zoneId}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`col-12 col-md-${LAYOUTS[selectedLayout][zoneIndex]} w-100 p-1 p-md-3`}
                      style={{
                        border: '1px dashed black',
                        minHeight: '50px'
                      }}
                    >
                      {zones[zoneId]?.map((module, index) => (
                        <Draggable
                          key={module.id}
                          draggableId={module.id}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              className="border border-2 rounded-2 mb-3"
                              style={{
                                ...draggableProvided.draggableProps.style
                              }}
                            >
                              <div className="p-4 position-relative">
                                <div
                                  className="position-absolute"
                                  style={{
                                    top: '4px',
                                    right: '4px'
                                  }}
                                >
                                  <DotMenu
                                    id={module?.id}
                                    handleRemove={() => {
                                      handleDeleteModule(zoneId, index);
                                    }}
                                    handleEditModule={(moduleType) =>
                                      handleModuleConfig(
                                        moduleType,
                                        zoneId,
                                        index
                                      )}
                                    moduleData={module}
                                    handleClickReorder={() => {
                                      handleOnclickReorderMenu(zoneId, index);
                                    }}
                                    moduleType={module.moduleType}
                                  />
                                </div>

                                <Module
                                  type={module.moduleType}
                                  isConfig
                                  moduleData={module}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      <div
                        className="mt-3 w-100 d-flex justify-content-center p-3"
                        style={{ border: '1px dashed black' }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            handleAddNewModule(zoneId);
                          }}
                          aria-label="New Module"
                        >
                          <FaPlus size={25} />
                        </button>
                      </div>

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={handleSaveDashboard}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-link ml-3"
              onClick={() => {
                history.push(SLASH);
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition))(Dashboard);
