import { client, flattenItem, flattenItems } from './contentful';
import { getEntry, updateEntry } from './entry';
import formatData from './formatData';

export const updateGroup = (data, groupId) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'group');

    updateEntry(preppedData, groupId).then((updatedGroup) => {
      setTimeout(() => {
        resolve(updatedGroup);
      }, 250);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchGroup = (id) => {
  return new Promise((resolve, reject) => {
    getEntry(id).then((response) => {
      const group = response ? flattenItem(response) : null;

      resolve(group);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchGroups = ({
  orgId,
  type = 'course',
  groupTags = null,
  title = '',
  groupIds,
  limit = 1000
}) => {
  const hasGroupIds = groupIds && Array.isArray(groupIds) && groupIds.length >= 1;
  const hasGroupTags = groupTags && Array.isArray(groupTags) && groupTags.length >= 1;
  const config = {
    content_type: 'group',
    include: 1,
    limit
  };

  if (hasGroupIds && !hasGroupTags) {
    config['sys.id[in]'] = groupIds.join(',');
  } else {
    config['fields.orgId'] = orgId;
    config['fields.type'] = type;
  }

  if (title) {
    config['fields.title[match]'] = title;
  }

  if (hasGroupTags) {
    config['fields.groupTags[in]'] = groupTags.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const groups = items.length ? flattenItems(items) : null;

      resolve(groups);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};
