import { fetchUser } from '../services/currentUser';
import { fetchUserProfile } from '../services/userProfiles';
import {
  EDITING_DIRECTORY_USER_PROFILE,
  RESET_DIRECTORY_USER,
  RESET_DIRECTORY_USER_PROFILE,
  SET_DIRECTORY_USER,
  SET_DIRECTORY_USER_PROFILE
} from './types';

export const getDirectoryUserProfile = (userProfileId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUserProfile(userProfileId)
        .then((directoryUserProfile) => {
          dispatch({
            type: SET_DIRECTORY_USER_PROFILE,
            directoryUserProfile
          });
          resolve(directoryUserProfile);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getDirectoryUser = ({ orgId, userId }) => {
  let error = 'Something went wrong. Please try again.';

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId) {
        error = 'orgId required!';
        console.error(error);
        reject(new Error(error));
        return false;
      }

      if (!userId) {
        error = 'userId required!';
        console.error(error);
        reject(new Error(error));
        return false;
      }

      fetchUser({ userId }).then((directoryUser) => {
        if (directoryUser?.orgId !== orgId) {
          error = 'User is not part of this organization.';
          console.error(error);
          dispatch({
            type: SET_DIRECTORY_USER,
            directoryUser: null
          });
          resolve({
            error,
            directoryUser: null
          });

          return false;
        }

        if (directoryUser?.id) {
          dispatch({
            type: SET_DIRECTORY_USER,
            directoryUser
          });

          if (directoryUser?.userProfileId) {
            dispatch(getDirectoryUserProfile(directoryUser.userProfileId));
          }

          resolve({ directoryUser });
        } else {
          error = 'No directory user found';
          console.error(error);
          reject(new Error(error));
        }
      });
    });
  };
};

export const setDirectoryUserEditing = (bool) => {
  return (dispatch) => {
    dispatch({
      type: EDITING_DIRECTORY_USER_PROFILE,
      directoryUserEditing: bool
    });
  };
};

export const resetDirectoryUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DIRECTORY_USER });
  };
};

export const resetDirectoryUserProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DIRECTORY_USER_PROFILE });
  };
};
