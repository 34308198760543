import React, { Component } from 'react';

class ModalDialog extends Component {
  state = {
    loading: false
  }

  handlePrimaryBtnFunc = () => {
    const { primaryButton, close } = this.props;

    this.setState({ loading: true });

    if (primaryButton && primaryButton.func) {
      primaryButton.func();
    } else {
      close();
    }
  }

  render() {
    const {
      title, text, primaryButton, secondaryButton, close, visible
    } = this.props;
    const { loading } = this.state;

    if (!visible) return null;

    return (
      <div
        data-cy="ModalDialog"
        className="turbine-modal dialog"
      >
        <div className="modal-container">
          <div className="modal-content">
            <div className="card border-0">
              <div className="card-header bg-dark">
                <h5 className="title m-0 text-white">
                  Confirm Action
                </h5>
              </div>

              {(text || title) && (
                <div className="card-body p-5">
                  {title && (
                    <h4 className="">
                      {title}
                    </h4>
                  )}

                  {text && (
                    <p className="m-0">
                      {text}
                    </p>
                  )}
                </div>
              )}
              <div className="card-footer">
                {primaryButton && (
                  <button
                    data-cy="ModalDialog-PrimaryBtn"
                    onClick={this.handlePrimaryBtnFunc}
                    className="btn btn-md btn-primary"
                    type="button"
                    disabled={loading}
                  >
                    {loading ? 'Loading...' : primaryButton.text}
                  </button>
                )}
                <button
                  data-cy="ModalDialog-SecondaryBtn"
                  onClick={secondaryButton.func || close}
                  className="btn btn-md btn-link ml-2"
                  type="button"
                  disabled={loading}
                >
                  {secondaryButton.text || 'Cancel'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalDialog;
