import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roleReadable } from '@apprentage/utils';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import Loading from '../../../../Loading';
import InfoField from '../../../../InfoField';
import OrgLocation from '../../../../OrgLocation';
import NameBlock from '../../../../ManageContent/NameBlock';
import KeyValueItem from '../../../../KeyValueItem';
import UserInstructionProvider from '../../../../UserInstructionProvider';
import DotMenu from './DotMenu';
import { setSideSheet } from '../../../../../actions/SideSheets';
import { SHEET_KEY_USER_DETAILS } from '../../../../../constants/sideSheets';

const InfoBlock = () => {
  // redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Training Program : User
  const apprenticeshipUser = apprenticeship?.user || null;
  // Organization
  const orgType = organization?.type || '';

  const dispatch = useDispatch();

  return (
    <>
      {apprenticeshipUser ? (
        <div className='card'>
          <div className='card-header'>
            <NameBlock
              profilePicture={apprenticeshipUser?.profilePicture || DEFAULT_PROFILE_PIC}
              name={apprenticeshipUser?.name}
              role={roleReadable(apprenticeshipUser?.role, orgType)}
              nameSize="sm"
              pictureWidth={40}
              pictureHeight={40}
              onClick={() => {
                dispatch(
                  setSideSheet({
                    key: SHEET_KEY_USER_DETAILS
                  })
                );
              }}
            />
          </div>
          <div className='card-body'>
            <div className='mb-3'>
              <div className='font-weight-bold'>
                Employer:
              </div>
              <div
                className='mt-2 d-flex align-items-center justify-content-between border p-2'
              >
                <OrgLocation
                  locationId={apprenticeshipUser.locationId}
                />
                <DotMenu />
              </div>
            </div>

            <UserInstructionProvider
              className="mb-3"
            />

            <InfoField
              className="mb-2"
              name="Phone"
              value={apprenticeshipUser.phone}
            />

            <KeyValueItem
              title="Email"
              value={apprenticeshipUser.email}
              size="md"
            />
          </div>

          {/* {
              apprenticeshipUser && (canManageUsers(currentUserRole) || orgType === 'workforce') && (
                <div className='card-footer text-center'>
                  <a
                    className="btn-link"
                    href={`${TURBINE}/directory/${apprenticeshipUser.id}/profile`}
                    href={`${TURBINE}/directory/${apprenticeshipUser.id}/privateInfo`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View All Information <small><i className={ICON_EXTERNAL_LINK} /></small>
                  </a>
                </div>
              )
            } */}
        </div>
      ) : (
        <div className='position-relative'>
          <Loading
            className='position-absolute h-auto'
            text='Loading User Info...'
          />
        </div>
      )}
    </>
  );
};

export default InfoBlock;
