import React from 'react';
import { FaThumbtack } from 'react-icons/fa';

const IsFeaturedCell = ({
  value: isFeatured
}) => {
  if (isFeatured) {
    return (
      <div className='d-flex align-items-center justify-content-start'>
        <FaThumbtack className='text-primary' />
        <span className='ml-1'>
          Pinned
        </span>
      </div>
    );
  }

  return null;
};

export default IsFeaturedCell;
