import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaAtom, FaChevronLeft } from 'react-icons/fa';
import BadWordsFilter from 'bad-words';
import pluralize from 'pluralize';
import { resetCurrentModal } from '../../../actions/Modals';
import { createSkill, fetchSkills, updateSkill } from '../../../services/skills';
import { getSkills } from '../../../actions/Skills';
import Modal from '../../Modal';
import KeyValueItem from '../../KeyValueItem';
import DeleteSkill from '../../ManageContent/DeleteContent/DeleteSkill';
import SkillsTag from '../../SkillsTag';

const ManageSkill = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  // Organization
  const orgId = organization?.id || null;
  // Skill
  const skill = currentModal?.data?.skill || null;
  const skillId = skill?.id || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const [currentScreen, setCurrentScreen] = useState('form');
  const [similarSkills, setSimilarSkills] = useState(null);
  const [name, setName] = useState(skill?.name || null);
  const [difficulty, setDifficulty] = useState(skill?.difficulty || 1);
  // Form Fields
  const difficultyDefault = [1, 2, 3, 4, 5];
  // Bad Words
  const BadWords = new BadWordsFilter();

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSuccess = () => {
    dispatch(getSkills({ orgId, limit: 1000 })).then(() => {
      toast.success(`Skill ${skillId ? 'updated' : 'created'}!`);
      handleClose();
    });
  };

  const onSave = () => {
    if (skillId) {
      // Edit
      updateSkill({ name, difficulty }, skillId).then(() => {
        handleSuccess();
      });
    } else {
      // Create
      createSkill({
        name,
        difficulty,
        orgId
      }).then(() => {
        handleSuccess();
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim()) {
      toast.error('Name is required.');
    }

    if (BadWords.isProfane(name)) {
      toast.error("Let's keep it clean, try again.");
      return;
    }

    setLoading(true);

    let exactMatch = false;
    let isSimilar = false;

    if (!skill?.name || skill?.name !== name) {
      await fetchSkills({
        orgId,
        name
      }).then((response) => {
        if (response?.total > 0) {
          response?.items.forEach((item) => {
            if (item?.name.toLowerCase() === name.toLowerCase()) {
              exactMatch = true;
            }
          });

          if (!exactMatch) {
            isSimilar = true;
            setSimilarSkills(response?.items || []);
            setCurrentScreen('review');
            setLoading(false);
          }
        }
      });
    }

    if (!exactMatch && !isSimilar) {
      onSave();
    }

    if (exactMatch) {
      toast.error('A skill with the same name already exists', { autoClose: false, toastId: 'exactMatch' });
      setLoading(false);
    }
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card border-0">
        <div className="card-header bg-dark">
          <h5 className='m-0'>
            <span className='d-flex align-items-center'>
              <FaAtom />
              <span className='ml-2'>
                {skillId ? name : 'Add Skill'}
              </span>
            </span>
          </h5>
        </div>
        {currentScreen === 'review' && (
          <>
            <div className="card-body">
              <h5>
                <strong>{similarSkills.length}</strong> {pluralize('Skill', similarSkills.length)} found with a similar name to <strong>{name}</strong>.
              </h5>

              <h6 className='font-weight-bold mt-3'>
                Similar {pluralize('Skill', similarSkills.length)}:
              </h6>
              <div className='mb-3 bg-light rounded p-3 border'>
                {similarSkills.map((similarSkill) => (
                  <Fragment
                    key={similarSkill?.id}
                  >
                    <SkillsTag
                      id={similarSkill?.id}
                      name={similarSkill?.name}
                      size='md'
                      badgeType='bg-white'
                      showTagIcon
                    />
                  </Fragment>
                ))}
              </div>
              <h5>
                Would you like to continue?
              </h5>
            </div>
            <div className='card-footer d-flex justify-content-between align-items-center'>
              <button
                className='btn btn-md btn-link pl-0'
                type="button"
                onClick={() => {
                  setCurrentScreen('form');
                }}
              >
                <span className='d-flex align-items-center'>
                  <FaChevronLeft />
                  <span className='ml-1'>
                    Change Name
                  </span>
                </span>
              </button>
              <button
                className='btn btn-md btn-outline-primary'
                type="button"
                onClick={onSave}
              >
                Continue
              </button>

            </div>
          </>
        )}
        {currentScreen === 'form' && (
          <form onSubmit={onSubmit}>
            <div className="card-body">
              <div id="skill-name" className="mb-4">
                <label htmlFor="name">
                  <b>Name:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onFocus={() => {
                    toast.dismiss('exactMatch');
                  }}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setName(value);
                  }}
                  value={name}
                  autoFocus
                  required
                />
              </div>
              <div id="skill-difficulty">
                <div>
                  <label className="mb-1">
                    <b>Difficulty:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Rate the difficulty of this skill from 1-5.
                  </div>
                  <select
                    type="text"
                    className="form-control"
                    name="difficulty"
                    value={difficulty}
                    style={{ width: '100px' }}
                    onChange={(e) => {
                      const { value } = e.currentTarget;
                      setDifficulty(parseInt(value, 10));
                    }}
                    required
                  >
                    {difficultyDefault.map((value, i) => (
                      <option key={i} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <div className="medium mt-1 small">
                    Domain-specific skills tend to rate higher than Domain-general skills.
                  </div>
                  <div className="medium mt-1 small">
                    If not applicable, set to 1.
                  </div>
                </div>
              </div>

              {skillId && (
                <DeleteSkill
                  title={name}
                  contentId={skillId}
                />
              )}
            </div>

            <div className="card-footer d-flex justify-content-between">
              <button
                type="submit"
                className="btn btn-md btn-primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>

              {skillId && (
                <KeyValueItem
                  title='ID'
                  value={skillId}
                />
              )}
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ManageSkill;
