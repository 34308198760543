import React from 'react';
import { useSelector } from 'react-redux';
import { TURBINE_PRIVACY_URL } from '../../constants/urls';

const PrivacyPolicyLink = ({ useTurbinePolicy = false }) => {
  const organization = useSelector((state) => state.organization);
  // Organization
  const privacyPolicy = organization?.privacyPolicy || null;

  const url = privacyPolicy && !useTurbinePolicy ? privacyPolicy : TURBINE_PRIVACY_URL;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="btn-link"
      href={url}
    >
      Privacy Policy
    </a>
  );
};

export default PrivacyPolicyLink;
