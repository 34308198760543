import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChallenges } from '../../../actions/Challenges';
import List from './List';

const ChallengesList = ({ classId, orgId }) => {
  const dispatch = useDispatch();
  // Redux
  const challenges = useSelector((state) => state.challenges);

  useEffect(() => {
    const config = {};

    if (orgId) config.orgId = orgId;
    if (classId) config.classId = classId;

    dispatch(getChallenges(config));
  }, [classId, dispatch, orgId]);

  return (
    <div className="challenges-list">
      <List
        data={challenges.list}
      />
    </div>
  );
};

export default ChallengesList;
