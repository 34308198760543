import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PLATFORM_NAME } from '../../../constants/globals';
import { updateUser } from '../../../services/user';
import { getCurrentUser } from '../../../actions/Users';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
import { MODAL_KEY_CREATE_TRAINING_PROGRAM } from '../../../constants/modals';

const Owner = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgType = organization?.type || '';
  // Current User
  const userId = currentUser?.id || '';
  // Local State
  const [loading, setLoading] = useState(false);

  const startCommunity = () => {
    setLoading(true);
    updateUser({
      onboarded: true
    }, userId).then(() => {
      dispatch(getCurrentUser({ userId }));
      dispatch(setCurrentModal({ key: MODAL_KEY_CREATE_TRAINING_PROGRAM }));
    });
  };

  const startWorkforce = () => {
    setLoading(true);
    updateUser({
      onboarded: true
    }, userId).then(() => {
      dispatch(getCurrentUser({ userId }));
      // dispatch(setCurrentModal({ key: '...tbd' }));
      // Add some widgets
      // Add Resources
      // Add your logo
      // Nicely Done!
      // Enterprise Dashboard
      dispatch(resetCurrentModal());
    });
  };

  // Apprentage

  if (orgType === 'community') {
    return (
      <div>
        <h1 className="font-weight-bolder text-purple-light">
          Welcome to {PLATFORM_NAME}
        </h1>
        <p className="font-weight-bold text-dark">
          Everything about apprenticeships and workforce
          <br />
          development just got easier.
        </p>

        <p className="text-dark">
          Lets start all success.
          <br /> Next, you'll select a training framework that replicates a
          <br /> registered apprenticeship and is proven to be effective.
        </p>
        <p className="font-weight-bold text-dark">
          This takes about 40 seconds.
        </p>

        <button
          type="button"
          className="btn bg-purple-light btn-md text-white"
          onClick={startCommunity}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Get Started'}
        </button>
      </div>
    );
  }

  // Enterprise

  if (orgType === 'workforce') {
    return (
      <div>
        <h1 className="font-weight-bolder text-purple-light">
          Welcome to {PLATFORM_NAME}
        </h1>
        <p className="font-weight-bold text-dark">
          Everything about workforce development just got easier.
        </p>

        <p className="text-dark">
          Lets start all success.
          <br /> Next, we'll ask you some questions and guide you through
          <br /> setting up your Workforce Space
        </p>
        <p className="font-weight-bold text-dark">
          This takes about 5 minutes.
        </p>

        <button
          type="button"
          className="btn bg-purple-light btn-md text-white"
          onClick={startWorkforce}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Get Started'}
        </button>
      </div>
    );
  }

  return null;
};

export default Owner;
