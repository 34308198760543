import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  canAccessAllLocations,
  reactSelectDefaultValue,
  reactSelectDefaultValues,
  reactSelectOptionsFromArray,
  reactSelectOptionsFromEnum,
  roleReadable
} from '@apprentage/utils';
import NameBlock from '@apprentage/components/dist/components/NameBlock';
import SelectMenu from '@apprentage/components/dist/components/SelectMenu';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { toast } from 'react-toastify';
import { FaExclamationTriangle } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { updateUser } from '../../../services/user';
import { getUsers } from '../../../actions/Users';
import { inviteExistingUsers } from '../../../services/mailer';
import { fetchCourses } from '../../../services/courses';
import { canManageUserRoles } from '../../../services/currentUser';
import { locationTerminology } from '../../../services/locations';
import Modal from '../../Modal';
import OrgLocation from '../../OrgLocation';

const UserInvitationConfirm = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const currentUser = useSelector((state) => state.currentUser);
  const locations = useSelector((state) => state.locations);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;
  const orgName = organization?.name || null;
  const orgEmail = organization?.email || null;
  const enableWorkforceSuite = organization?.enableWorkforceSuite || null;
  // Modal
  const modalTitle = currentModal?.data?.modalTitle || 'User Invitation Confirmation';
  const locationEditable = currentModal?.data?.locationEditable || true;
  // Current User
  const currentUserRole = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // User to Invite
  const userId = currentModal?.data?.userId || null;
  const firstName = currentModal?.data?.firstName || null;
  const lastName = currentModal?.data?.lastName || null;
  const email = currentModal?.data?.email || null;
  const profilePicture = currentModal?.data?.profilePicture || null;
  const userLocationId = currentModal?.data?.locationId || null;
  const userListConfig = currentModal?.data?.userListConfig || null;
  const userRole = useMemo(() => {
    return currentModal?.data?.role || [];
  }, [currentModal?.data?.role]);
  // Local State
  const [role, setRole] = useState(userRole || []);
  const [locationId, setLocationId] = useState(userLocationId);

  // Apprenticeship
  const apprenticeshipTitle = apprenticeship?.title || null;
  const apprenticeshipId = apprenticeship?.id || null;
  const apprenticeshipCourseIds = apprenticeship?.courseIds || null;

  // Local State
  const [loading, setLoading] = useState(false);
  const [apprenticeshipCourses, setApprenticeshipCourses] = useState(null);

  const locationOptions = useMemo(() => {
    let allowedLocations = !locations.list ? [] : [...locations.list];

    if (!canAccessAllLocations(currentUserRole)) {
      allowedLocations = allowedLocations.filter((l) => !l.defaultLocation);
    }

    const options = reactSelectOptionsFromArray(allowedLocations, 'name');
    return options;
  }, [currentUserRole, locations.list]);
  const locationDefaultValue = reactSelectDefaultValue(
    locationId,
    locationOptions
  );

  const locationName = locationTerminology({ orgType }).text;

  const roleHasChanged = useMemo(() => {
    return role.join('') !== userRole.join('');
  }, [role, userRole]);

  const locationHasChanged = useMemo(() => {
    return locationId !== userLocationId;
  }, [locationId, userLocationId]);

  const hasApprenticeshipCourses = useMemo(() => {
    return Array.isArray(apprenticeshipCourseIds) && apprenticeshipCourseIds.length > 0;
  }, [apprenticeshipCourseIds]);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onClickConfirm = () => {
    setLoading(true);
    toast.info('Adding User to Training Program...', { toastId: 'addingExistingUser' });

    const dataToSave = {
      apprenticeshipId
    };

    if (hasApprenticeshipCourses) {
      dataToSave.classIds = apprenticeshipCourseIds;
    }

    if (locationHasChanged) {
      dataToSave.locationId = locationId;
    }

    if (roleHasChanged) {
      dataToSave.role = role.join(',');
    }

    updateUser(dataToSave, userId).then(() => {
      toast.dismiss('addingExistingUser');
      toast.success(`Added ${firstName} ${lastName}!`);

      inviteExistingUsers({
        email,
        fullName: `${firstName} ${lastName}`,
        role,
        orgName,
        orgEmail,
        // Training Program
        apprenticeshipInvitation: true,
        apprenticeshipId,
        apprenticeshipTitle,
        ...(hasApprenticeshipCourses ? { apprenticeshipCourses } : {})
      }).then(() => {
        dispatch(getUsers(userListConfig)).then(() => {
          handleClose();
        });
      }).catch((error) => {
        console.error(error);
      });
    });
  };

  const trainingProgramUserRoles = {
    programManager: 'Program Manager',
    programTrainer: 'Program Trainer'
  };

  const userRolesEnum = {
    student: orgType !== 'workforce' ? 'Trainee' : 'User',
    teacher: 'Expert',
    admin: 'Admin',
    owner: 'Owner',
    ...(enableWorkforceSuite ? trainingProgramUserRoles : {})
  };

  const userRoleOptions = reactSelectOptionsFromEnum(userRolesEnum);
  const userRoleDefaultValue = reactSelectDefaultValues(role, userRoleOptions);

  useEffect(() => {
    if (hasApprenticeshipCourses) {
      fetchCourses({
        orgId,
        courseIds: apprenticeshipCourseIds,
        select: ['fields.title', 'sys.id']
      }).then((response) => {
        setApprenticeshipCourses(response);
      });
    }
  }, [apprenticeshipCourseIds, hasApprenticeshipCourses, orgId]);

  if (!userId || !currentModal?.visible) {
    return null;
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">{modalTitle}</h1>
        </div>

        <div className="card-body pb-4">
          <h5 className='mb-3'>
            Add <strong>{firstName}</strong> to <strong>{apprenticeshipTitle}</strong>?
          </h5>
          <div className='border p-3 rounded mb-2 overflow-hidden'>
            <NameBlock
              key={userId}
              name={`${firstName} ${lastName}`}
              nameSize="md"
              title={email}
              profilePicture={profilePicture || DEFAULT_PROFILE_PIC}
              pictureSize="sm"
              className="my-1"
            />
          </div>

          <div className="mb-3">
            <div className="mb-2 font-weight-bold">
              {locationName}:
            </div>

            {locationEditable ? (
              <div
                className={`mb-2 p-3 border rounded ${locationHasChanged ? 'alert-warning border-warning' : ''}`}
              >
                <div className="d-flex align-items-center w-100">
                  {locationDefaultValue && (
                    <OrgLocation
                      locationId={locationId}
                      className="h6 m-0"
                      imageHeight={30}
                      showOnlyImage
                    />
                  )}

                  <div style={{ flexGrow: 1 }}>
                    <SelectMenu
                      id="locationId"
                      name="locationId"
                      autoComplete="new-location"
                      options={locationOptions}
                      defaultValue={locationDefaultValue}
                      onChange={({ value }) => {
                        setLocationId(value);
                      }}
                    />
                  </div>
                </div>

                {locationHasChanged && (
                  <div className='mt-2'>
                    <div>
                      Original {locationName}:
                    </div>
                    <OrgLocation
                      locationId={userLocationId}
                      className="font-weight-bold"
                      showOnlyName
                    />
                  </div>
                )}
              </div>
            ) : (
              <OrgLocation
                locationId={locationId}
                className="h6 m-0"
                imageHeight={30}
              />
            )}

            {locationHasChanged && (
              <div className='d-flex align-items-start'>
                <FaExclamationTriangle className='text-warning mt-1' size={15} />
                <div className='ml-2'>
                  New data created by <strong>{firstName}</strong> will be associated with this new {locationName}.
                </div>
              </div>
            )}
          </div>

          <div className="mb-2">
            <div className="mb-2 font-weight-bold">
              Role:
            </div>
            {canManageUserRoles(currentUserRole) ? (
              <div
                className={`mb-2 p-3 border rounded ${roleHasChanged ? 'alert-warning border-warning' : ''}`}
              >
                <SelectMenu
                  onChange={({ value }) => {
                    setRole([value]);
                  }}
                  defaultValue={userRoleDefaultValue}
                  name="role"
                  options={userRoleOptions}
                />
                {roleHasChanged && (
                  <div className='mt-2 d-flex align-items-center'>
                    <span>
                      Original Role:
                    </span>
                    <span
                      className="font-weight-bold text-muted ml-1"
                    >
                      {roleReadable(userRole, orgType)}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className="mb-2">
                <input
                  type='text'
                  disabled
                  value={roleReadable(role, orgType)}
                  className='form-control'
                />
              </div>
            )}
          </div>

          {roleHasChanged && (
            <div className='d-flex align-items-start'>
              <FaExclamationTriangle className='text-warning mt-1' size={15} />
              <span className='ml-2'>
                Changing <strong>{firstName}'s</strong> Role will alter their permissions or access.
              </span>
            </div>
          )}
        </div>

        <div className="card-footer d-flex justify-content-end align-items-center">
          <button
            onClick={handleClose}
            type='button'
            className='btn btn-link mr-2'
          >
            Cancel
          </button>
          <button
            title="Send Invitation"
            aria-label="Send Invitation"
            className="btn btn-primary"
            type="button"
            disabled={loading}
            onClick={onClickConfirm}
          >
            {loading ? 'Adding User...' : 'Confirm'}
          </button>
        </div>

      </div>
    </Modal>
  );
};

export default UserInvitationConfirm;
