import { pathOr } from 'ramda';
import React from 'react';
import CourseImageColor from '../../CourseImageColor';

const TitleCell = ({ value: title, row }) => {
  const classColor = pathOr(null, ['original', 'classColor'], row);
  const classImage = pathOr(null, ['original', 'classImage'], row);

  return (
    <div
      data-cy="coursesList-title"
      className="d-flex align-items-center font-weight-bold"
    >
      <CourseImageColor
        title={title}
        classColor={classColor}
        classImage={classImage}
        className="mr-2"
      />
      <div>
        {title}
      </div>
    </div>
  );
};

export default TitleCell;
