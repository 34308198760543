import React, { useState } from 'react';
import { ICON_TRIANGLE_BANG } from '@apprentage/constants';
import Alert from '../Alert';

const FieldTextArea = ({
  disabled = false,
  value,
  maxCharCount,
  onChange,
  onFocus,
  autoFocus = false,
  onExceedMaxChars
}) => {
  const [maxCharsExceeded, setMaxCharsExceeded] = useState(false);
  const [charCount, setCharCount] = useState(value.length || 0);

  const handleOnFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const handleOnChange = (e) => {
    const { currentTarget } = e;
    const content = currentTarget.value || '';
    let count = 0;

    if (content) {
      count = content.length;
    }

    onChange(content);
    setCharCount(count);

    if (!maxCharsExceeded && count > maxCharCount) {
      setMaxCharsExceeded(true);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(true);
      }
    }

    if (maxCharsExceeded && count <= maxCharCount) {
      setMaxCharsExceeded(false);

      if (onExceedMaxChars && typeof onExceedMaxChars === 'function') {
        onExceedMaxChars(false);
      }
    }

    return charCount;
  };

  return (
    <div>
      <textarea
        disabled={disabled}
        className='form-control'
        name="body"
        type="text"
        value={value}
        autoFocus={autoFocus}
        maxLength={maxCharCount}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
      />

      <div className="d-flex justify-content-end mb-2">
        <div>
          <strong>{maxCharCount - charCount}</strong> characters remaining
        </div>
      </div>

      {charCount > maxCharCount && (
        <Alert
          type="danger"
          icon={`${ICON_TRIANGLE_BANG} text-danger`}
          className="m-0 mb-5 w-100 border"
        >
          <div>
            This field exceeds the maximum number of characters ({maxCharCount}) allowed.
          </div>
        </Alert>
      )}
    </div>
  );
};

export default FieldTextArea;
