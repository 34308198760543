import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { deleteEntry } from '../../../services/entry';
import canDeleteMaterial from '../../../permissions/manage/canDeleteMaterial';
import { MANAGE_CURRENT_ENTRY } from '../../../constants/routes';
import { removeEntryFromTopic } from '../../../services/topics';
import { setCurrentTopic } from '../../../actions/Topic';
import { deleteMaterial } from '../../../services/materials';
import DeleteContent from '.';
import TaskStatus from './TaskStatus';

const DeleteMaterial = ({
  contentId,
  title,
  contentType,
  buttonText = 'Delete Material',
  onSuccess
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const material = useSelector((state) => state.material);
  const currentTopic = useSelector((state) => state.currentTopic);
  // Material
  const materialUserId = material?.userId || null;
  // Course
  const classId = course?.id || null;
  // Current Topic
  const topicId = currentTopic?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    $('#collapse-deleteContentHowTo').collapse('hide');
  }, [contentId]);

  const tempDisableDeleteForSupabaseMaterial = useMemo(() => {
    if (contentType === 'materials') {
      if (material?.isEmbedded) {
        return true;
      }

      if (material?.resourceId) {
        // TODO: handle deleting resource (type=material)
        return true;
      }

      if (material?.fileUploadFlow) {
        // TODO: clean up "embeddings" entries in documents table
        // based on resourceIds in material?.resourceIds
        return true;
      }
    }

    return false;
  }, [contentType, material?.fileUploadFlow, material?.isEmbedded, material?.resourceId]);

  const showDeleteButton = useMemo(() => {
    if (!canDeleteMaterial(role, contentType, userId, materialUserId)) {
      return false;
    }

    if (material.cdnFiles && material.cdnFiles.length !== 0) {
      return false;
    }

    if (tempDisableDeleteForSupabaseMaterial) {
      return false;
    }

    return true;
  }, [contentType, material.cdnFiles, materialUserId, role, tempDisableDeleteForSupabaseMaterial, userId]);

  const deleteContentfulMaterial = () => {
    setLoading(true);
    toast.info('Processing...', { toastId: 'processingDelete' });
    removeEntryFromTopic({
      entryId: contentId,
      topicId,
      contentType
    }).then(() => {
      deleteEntry(contentId).then(() => {
        // Update Topic
        dispatch(setCurrentTopic({ topicId })).then(() => {
          toast.dismiss('processingDelete');
          toast.success(`${title} Deleted!`);
          // Redirect User back to Topic Edit UI
          history.replace(`${MANAGE_CURRENT_ENTRY}?manageType=edit&contentType=topic&classId=${classId}&topicId=${topicId}&entryId=${topicId}`);
        });
      }).catch((error) => {
        toast.dismiss('processingDelete');
        toast.error('Something went wrong, please refresh and try again.');
        console.error(`deleteEntry: ${contentType}`, error);
      });
    }).catch((error) => {
      toast.dismiss('processingDelete');
      toast.error('Something went wrong, please refresh and try again.');
      console.error('removeEntryFromTopic', error);
    });
  };

  const deleteSupabaseMaterial = () => {
    setLoading(true);
    toast.info('Processing...', { toastId: 'processingDelete' });
    deleteMaterial(material?.id).then(() => {
      toast.dismiss('processingDelete');
      toast.success(`${title} Deleted!`);

      if (onSuccess) {
        onSuccess();
      }
    }).catch((error) => {
      toast.dismiss('processingDelete');
      toast.error('Something went wrong, please refresh and try again.');
      console.error(`deleteMaterial: ${contentType}`, error);
    });
  };

  const handleDelete = () => {
    // NOTE: This will be more complex in the future once all Materials are in Supabase
    if (contentType === 'materials') {
      // We will need to use similar logic regarding topics even when in Supabase
      // Currently, Supabase Materials are not associated with Courses
      deleteSupabaseMaterial();
    } else {
      deleteContentfulMaterial();
    }
  };

  return (
    <DeleteContent
      id={contentId}
      title={title}
      contentType={contentType}
      buttonText={buttonText}
      handleDelete={handleDelete}
      showDeleteButton={showDeleteButton}
      loading={loading}
      className="mt-2 mb-3"
    >
      {tempDisableDeleteForSupabaseMaterial && ( // Supabase Specific
        <div className='alert alert-warning'>
          <div>
            Material can not be deleted at this time.
          </div>
        </div>
      )}

      <ol className="m-0 px-4">
        <li className="mb-2">
          Delete all of this Material's Files <TaskStatus statusType="files" complete={!material.cdnFiles || material.cdnFiles.length === 0} />
        </li>
      </ol>
    </DeleteContent>
  );
};

export default DeleteMaterial;
