import React from 'react';
import {
  FaChevronRight, FaChevronDown, FaMagic
} from 'react-icons/fa';
import reactTableSortCaseInsensitive from '../../../../utils/reactTableSortCaseInsensitive';
import Table from './Table';
import NameCell from './NameCell';
import EditCell from './EditCell';
import BodyCell from './BodyCell';
import DateCell from '../../Tables/DateCell';
import FolderCell from '../../Tables/FolderCell';
import RelevanceCell from './RelevanceCell';
import IsEmbeddedCell from './IsEmbeddedCell';

const CdnFilesList = ({
  data,
  contentId,
  contentType,
  deleteItem,
  editMenu,
  hiddenColumns,
  sortByAccessorId,
  sortByAccessorDesc,
  renderRowSubComponent
}) => {
  const columns = React.useMemo(() => [
    {
      id: 'expander',
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? <FaChevronDown /> : <FaChevronRight />}
        </span>
      ),
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FaChevronDown /> : <FaChevronRight />}
        </span>
      )
    },
    {
      Header: 'Name',
      accessor: 'name',
      sortType: reactTableSortCaseInsensitive,
      Cell: NameCell
    },
    {
      Header: 'Description',
      accessor: 'body',
      Cell: BodyCell
    },
    {
      Header: 'Relevance',
      accessor: 'similarity',
      Cell: RelevanceCell
    },
    {
      Header: 'Folder',
      accessor: 'parentId',
      Cell: FolderCell
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      Cell: DateCell
    },
    {
      Header: () => {
        return (
          <span
            className='d-inline-flex align-items-center'
          >
            <FaMagic className='text-keppel opacity-75' />
            <span className='ml-2 mr-1 d-flex align-items-center'>Context-Aware</span>
          </span>
        );
      },
      accessor: 'isEmbedded',
      Cell: IsEmbeddedCell
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: (props) => (
        <EditCell
          {...props}
          contentId={contentId}
          contentType={contentType}
          editMenu={editMenu}
          onClickDelete={deleteItem}
        />
      )
    }
  ], [contentId, contentType, deleteItem, editMenu]);

  if (!data || (Array.isArray(data) && data.length === 0)) {
    return null;
  }

  return (
    <Table
      columns={columns}
      data={data}
      sortByAccessorId={sortByAccessorId}
      sortByAccessorDesc={sortByAccessorDesc}
      renderRowSubComponent={renderRowSubComponent}
      hiddenColumns={[
        ...hiddenColumns,
        ...(editMenu ? [] : ['id'])
      ]}
    />
  );
};

export default CdnFilesList;
