import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import $ from 'jquery';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  FaFolder,
  FaImages,
  FaLayerGroup,
  FaSearch
} from 'react-icons/fa';
import { MdOutlineScreenSearchDesktop } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { getResourceGroupTags } from '../../actions/Resources';
import { resetSearch, searchTurbine, setSearchUserFeedback } from '../../actions/Search';
import { withAuthorization } from '../Session';
import { SEARCH_CONTENT, SLASH } from '../../constants/routes';
import { SEARCH_ADVANCED_FILTERS, SEARCH_CONTENT_TYPES } from '../../constants/globals';
import { routeWithOrgId } from '../../services/organizations';
import { fetchSearch } from '../../services/searches';
import { resetResourceGroup } from '../../actions/ResourceGroups';
import { isLocalhost } from '../../serviceWorker';
import { chatEngine } from '../../services/openAi';
import Loading from '../Loading';
import withOrgConsole from '../App/withOrgConsole';
import GroupsList from '../OrgConsole/Resources/List'; // TODO change this
import CdnFiles from '../ManageContent/CdnFiles';
import SearchBox from '../SearchBox';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import UsersList from '../lists/UsersList';
import SearchFilters from './SearchFilters';
import DataNotAvailable from '../lists/DataNotAvailable';
import ProjectsList from '../lists/ProjectsList';
import CoursesList from '../CoursesList';
import Tabs from './Tabs';
// import PromptDotMenu from '../Modals/ResourcePreview/Assistant/PromptDotMenu';
import SearchResultsSkeleton from './SearchResultsSkeleton';
import SearchUserInput from './SearchUserInput';
import SearchFolders from './SearchFolders';

import SearchVelaResponse from './SearchVelaResponse';
import SearchVectorResults from './SearchVectorResults';

const SearchContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const resourceGroups = useSelector((state) => state.resourceGroups);
  const resourceGroupTags = useSelector((state) => state.resourceGroupTags);
  const material = useSelector((state) => state.material);
  const search = useSelector((state) => state.search);
  // Current User
  const userId = currentUser?.id || null;
  const locationId = currentUser?.locationId || null;
  // Organizations
  const orgId = organization?.id || null;
  // Resource Groups
  const groupIds = resourceGroups?.groupIds || null;
  const groupTags = resourceGroups?.groupTags || null;
  // Search Params
  const searchParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  // Search Query
  const searchQuery = useMemo(() => {
    let query = searchParams.get('q');

    query = query ? query.replaceAll('+', ' ') : '';

    return query;
  }, [searchParams]);

  const searchGroups = search?.groups || [];
  const vectorSearchId = search?.vectorSearchId || null;
  const searchVectorFiles = search?.vectorFiles || [];
  const searchVectorNodes = search?.vectorNodes || [];
  const searchFiles = search?.cdnFiles || [];
  const searchUsers = search?.users;
  const searchProjects = useMemo(() => {
    return search?.projects || [];
  }, [search?.projects]);
  const searchCourses = search?.courses || [];

  const [searchGroupIds, setSearchGroupIds] = useState(search?.searchGroupIds || null);
  const [searchValue, setSearchValue] = useState(search?.value || '');
  const [vectorAnswer, setVectorAnswer] = useState('');

  const [searchContentTypes, setSearchContentTypes] = useState(
    localStorage.getItem('searchContentTypes') || SEARCH_CONTENT_TYPES
  );
  const searchAdvancedFilters = localStorage.getItem('searchAdvancedFilters');
  const advancedFilters = searchAdvancedFilters ? JSON.parse(searchAdvancedFilters) : SEARCH_ADVANCED_FILTERS;
  const [searchFilters, setSearchFilters] = useState(advancedFilters);

  const onChangeSearchTypes = useCallback((contentTypes) => {
    setSearchContentTypes(contentTypes);
  }, [setSearchContentTypes]);

  const onChangeAdvancedFilters = useCallback((filters) => {
    setSearchFilters(filters);
  }, [setSearchFilters]);

  useEffect(() => {
    dispatch(resetResourceGroup());
  }, [dispatch]);

  const handlePrompt = useCallback(async ({ prompt, resourceVecIds }) => {
    if (resourceVecIds && prompt) {
      const response = await chatEngine({
        orgId,
        prompt,
        resourceVecIds,
        streaming: true
      });

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        const { value, done } = await reader.read();
        if (done) {
          // TODO Save updatePrompt .then(() => {})
          break;
        }
        setVectorAnswer((prev) => prev + value);
      }
    }
  }, [orgId]);

  const sanitizedSearchGroupIds = useMemo(() => {
    let result = null;
    if (Array.isArray(searchGroupIds) && searchGroupIds.length > 0) {
      result = searchGroupIds.slice(0).pop();

      return [result];
    }

    return result;
  }, [searchGroupIds]);

  const runSearch = useCallback((searchValueInput) => {
    const config = {
      orgId,
      locationId,
      userId,
      searchValue: searchValueInput,
      resourceGroupTags,
      saveSearch: !isLocalhost, // Only save production searches
      contentTypes: searchContentTypes,
      ...searchFilters
    };

    if (sanitizedSearchGroupIds) {
      config.searchGroupIds = sanitizedSearchGroupIds;
    }

    if (searchValueInput) {
      // If a user searches for a term and there are no or unexpected results
      // then the user updates the name of a resource and returns to search
      // execute search again if the searchInputValue exists so the new results
      // display for better UX

      setVectorAnswer('');
      // TODO Save userPrompt .then(() => {})
      dispatch(searchTurbine(config)).then((searchResponse) => {
        // Only run if search was classified as a prompt
        if (searchResponse?.resourceVecIds && searchResponse?.valueIsPrompt) {
          handlePrompt({
            orgId,
            prompt: searchValueInput,
            resourceVecIds: searchResponse?.resourceVecIds
          });
        }
      });
    }
  }, [
    orgId,
    locationId,
    userId,
    resourceGroupTags,
    searchContentTypes,
    searchFilters,
    sanitizedSearchGroupIds,
    dispatch,
    handlePrompt
  ]);

  useEffect(() => {
    if (!searchQuery && search?.value) {
      runSearch(search?.value);
    }
  }, []);

  useEffect(() => {
    if (orgId) {
      dispatch(getResourceGroupTags({ orgId }));
    }
  }, [dispatch, orgId]);

  useEffect(() => {
    if (searchQuery) {
      history.replace(SEARCH_CONTENT);
    }
  }, [history, searchQuery]);

  useEffect(() => {
    if (vectorSearchId) {
      fetchSearch(vectorSearchId).then((response) => {
        dispatch(setSearchUserFeedback({ userFeedback: response?.userFeedback }));
      });
    }
  }, [dispatch, vectorSearchId]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetSearch());
    };
  }, [dispatch]);

  const onSubmit = (e) => {
    dispatch(resetSearch());
    e.preventDefault();
    const { searchValueInput } = e.currentTarget;

    const valueTrimmed = (searchValueInput.value || '').trim();

    if (!valueTrimmed.length) {
      dispatch(resetSearch());
      toast.error('Please enter a search term.');
      return false;
    }

    if (valueTrimmed.length < 5 && valueTrimmed.includes('.')) {
      dispatch(resetSearch());
      toast.error('Too broad a query. Try a different search term.');
      return false;
    }

    if (searchContentTypes.length === 0) {
      toast.error('Select at least one content type to search.');
      return;
    }

    setSearchValue(valueTrimmed);

    $('#searchContentTypes').collapse('hide');

    runSearch(valueTrimmed);
  };

  const goBackRoute = useMemo(() => {
    const redirectUrl = searchParams.get('redirectUrl');

    if (redirectUrl) {
      return redirectUrl;
    }

    return routeWithOrgId({
      route: SLASH,
      orgId
    });
  }, [orgId, searchParams]);

  const searchPlaceholder = useMemo(() => {
    let placeholder = 'Search...';

    if (searchContentTypes.includes('resources')) {
      placeholder = 'Ask or Search...';
    }

    return placeholder;
  }, [searchContentTypes]);

  const noDataFound = useMemo(() => {
    if (search.fetched) {
      return !searchUsers && !searchProjects.length && !searchGroups.length && !searchVectorFiles.length && !searchFiles.length;
    }
    return false;
  }, [search.fetched, searchFiles.length, searchGroups.length, searchProjects, searchUsers, searchVectorFiles.length]);

  const onChangeSearchFolders = (groupId) => {
    let newSearchGroupIds = [];

    if (Array.isArray(searchGroupIds)) {
      newSearchGroupIds = searchGroupIds.filter((gId) => gId !== groupId);
      newSearchGroupIds = newSearchGroupIds.length ? newSearchGroupIds : null;
      setSearchGroupIds(newSearchGroupIds);
    }
  };

  if (!organization?.id || !currentUser?.id) {
    return <Loading />;
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <OrgConsoleHeader
            pageTitle="Search"
            iconComponent={() => <FaSearch className='mr-1' />}
            rawRoute={goBackRoute}
          />
        </div>
      </div>

      <Tabs active="search" className='mt-4' />

      <div className="row">
        <div className="col-sm-12">
          <div className="mb-3">
            <div
              className="card-body border-bottom border-left border-right bg-white"
            >

              <SearchUserInput theme="light">
                <>
                  <SearchBox
                    value={searchValue}
                    className="p-0 p-sm-3"
                    onSubmit={onSubmit}
                    placeholder={searchPlaceholder}
                    loading={search?.fetching}
                    autoFocus
                  />

                  <SearchFilters
                    className='mt-3 mt-sm-0'
                    expanded={!searchValue || sanitizedSearchGroupIds}
                    contentTypes={searchContentTypes}
                    filters={searchFilters}
                    onChangeSearchTypes={onChangeSearchTypes}
                    onChangeAdvancedFilters={onChangeAdvancedFilters}
                    searchGroupIds={sanitizedSearchGroupIds}
                  >
                    <SearchFolders
                      ids={sanitizedSearchGroupIds}
                      onChange={onChangeSearchFolders}
                    />
                  </SearchFilters>
                </>
              </SearchUserInput>

              {search?.fetching && (
                <SearchResultsSkeleton />
              )}

              {noDataFound && (
                <DataNotAvailable
                  iconComponent={() => <MdOutlineScreenSearchDesktop size={45} />}
                  text="No search results."
                />
              )}

              <UsersList
                header
                theme="dark"
                data={searchUsers}
                className='my-3'
              />

              {searchCourses && searchCourses.length !== 0 && (
                <div className="card my-3">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="m-0 d-flex align-items-center">
                      <FaLayerGroup size={25} />
                      <span className='ml-2'>
                        Courses
                      </span>
                    </h5>
                  </div>
                  <div className="card-body p-0">
                    <CoursesList
                      list={searchCourses || []}
                      showGroupsCol
                    />
                  </div>
                </div>
              )}

              {searchProjects && searchProjects.length !== 0 && (
                <div className="card my-3">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="m-0 d-flex align-items-center">
                      <FaImages size={25} />
                      <span className='ml-2'>
                        Projects
                      </span>
                    </h5>
                  </div>
                  <div className="card-body p-0 overflow-x-hidden">
                    <ProjectsList
                      list={searchProjects || []}
                      showLearningModulesCol
                      showSkillsCol
                    />
                  </div>
                </div>
              )}

              {Array.isArray(searchVectorFiles) && searchVectorFiles.length !== 0 && (
                <div className="my-3">
                  {search.valueIsPrompt && (
                    <SearchVelaResponse
                      vectorAnswer={vectorAnswer || null}
                      vectorSearchId={vectorSearchId}
                      materialId={material?.id || null}
                    />
                  )}

                  <SearchVectorResults
                    vectorSearchId={vectorSearchId}
                    vectorFiles={searchVectorFiles}
                    vectorNodes={searchVectorNodes}
                  />
                </div>
              )}

              {searchGroups && searchGroups.length !== 0 && (
                <div className="card my-3">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <h5 className="m-0 d-flex align-items-center">
                      <FaFolder size={25} />
                      <span className='ml-2'>
                        Folders
                      </span>
                    </h5>
                  </div>
                  <div className="card-body p-0">
                    <GroupsList
                      data={searchGroups}
                      orgId={orgId}
                      groupIds={groupIds}
                      groupTags={groupTags}
                      hideUpdatedDateCol
                      limit={1000}
                    />
                  </div>
                </div>
              )}

              {searchFiles && searchFiles.length !== 0 && (
                <div className='my-3'>
                  <CdnFiles
                    id="resource-integration-files"
                    contentId={undefined}
                    contentType="resourceGroup"
                    theme="light"
                    title="Resources"
                    files={searchFiles}
                    editMenu
                    hiddenColumns={['createdAt', 'similarity', 'expander', 'isEmbedded']}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(SearchContent);
