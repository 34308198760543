import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEnvelope } from 'react-icons/fa';
import { setCurrentModal } from '../../../actions/Modals';

const SendEmailButton = ({
  userName,
  userEmail,
  text = 'Email',
  showIcon = true,
  className = ''
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // User
  const fromName = currentUser?.name || '';
  const replyTo = currentUser?.email || '';

  const handleClick = () => {
    dispatch(setCurrentModal({
      key: 'sendEmail',
      data: {
        name: userName,
        email: userEmail,
        fromName,
        replyTo,
        subject: `New message from ${fromName}`,
        hideSubject: true,
        disableSubject: true
      }
    }));
  };

  return (
    <button
      className={className || 'btn btn-sm btn-primary text-nowrap'}
      title={text}
      onClick={handleClick}
      type="button"
    >
      <span className='d-flex align-items-center'>
        {showIcon && (
          <FaEnvelope size={15} className='mr-2' />
        )}
        <span>
          {text}
        </span>
      </span>
    </button>
  );
};

export default SendEmailButton;
