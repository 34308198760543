import React, { useEffect, useState } from 'react';
import { FaBell } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { NO_NOTIFICATIONS } from '@apprentage/constants';
import { fetchNotifications } from '../../../../services/notifications';
import NotificationsList from '../../../lists/NotificationsList';

const Notifications = ({
  title = '',
  className = '',
  showOnlyPinned = false,
  limit = 0,
  children,
  showMenu = false
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || '';
  // Pagination
  const [pinnedNotifications, setPinnedNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (pinnedNotifications.length < limit && showOnlyPinned) {
      fetchNotifications({
        orgId,
        classId: null,
        isDraft: null,
        isPinned: showOnlyPinned,
        page: 1,
        order: '-createdAt',
        limit
      }).then((response) => {
        setPinnedNotifications(response.items);
        setNotifications([]);
      });
    } else if (!showOnlyPinned && pinnedNotifications.length < limit) {
      fetchNotifications({
        orgId,
        classId: null,
        isDraft: null,
        isPinned: true,
        page: 1,
        order: '-createdAt',
        limit
      }).then((response) => {
        setPinnedNotifications(response.items);
        if (response.items.length < limit) {
          fetchNotifications({
            orgId,
            classId: null,
            isDraft: null,
            isPinned: null,
            page: 1,
            order: '-createdAt',
            limit: limit - response.items.length
          }).then((notificationsResponse) => {
            setNotifications(notificationsResponse.items);
          });
        } else {
          setNotifications([]);
        }
      });
    }
  }, [limit, orgId, showOnlyPinned]);

  if (pinnedNotifications?.length === 0 && notifications?.length === 0) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center mx-auto"
        style={{
          padding: '1rem 0'
        }}
      >
        <img
          src={NO_NOTIFICATIONS}
          width="100"
          className="my-3"
          alt="No notifications"
        />
        <p className="m-0">Notifications not found.</p>
      </div>
    );
  }

  return (
    <div className={className}>
      {title && (
        <div className="card-header bg-light p-3 h5 m-0">
          <span className="d-flex align-items-center">
            <FaBell />
            <span className="ml-2">{title}</span>
          </span>
        </div>
      )}

      <NotificationsList
        list={pinnedNotifications}
        showMenu={showMenu}
        showNotFoundUi={false}
      />

      {!showOnlyPinned && (
        <NotificationsList
          list={notifications}
          showMenu={showMenu}
          showNotFoundUi={false}
        />
      )}

      {children || null}
    </div>
  );
};

export default Notifications;
