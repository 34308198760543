import React, { Component, Fragment } from 'react';
import CalendarDatePicker from '../../ManageContent/CalendarDatePicker';
import Modal from '../../Modal';
import Item from './Item';
import './style.css';

class Records extends Component {
  constructor(props) {
    super(props);

    const {
      data, parent, maxItems
    } = this.props;

    this.state = {
      // Parent Item
      isNewParent: null,
      parentIndex: null,
      parentModal: false,
      parentDraft: null,
      showDialogConfirmDeleteParentItem: false,
      parentItems: data,
      parent,
      maxItems
    };
  }

  setModalData = ({ key, val }) => {
    this.setState({
      [key]: val
    });
  }

  toggleModal = ({ key, val }) => {
    this.setState({
      [key]: val
    });
  }

  manageParent = (index) => {
    const { parentItems, parentDraft } = this.state;
    let parentIndex = index;
    let tempData = parentDraft || '';
    let isNewParent = false;

    if (parentIndex === undefined) {
      // Add New Parent
      parentIndex = (parentItems && parentItems.length) || 0;
      isNewParent = true;
    } else if (parentItems && parentItems[parentIndex]) {
      // Edit Existing Parent
      tempData = parentItems[parentIndex];
    }

    this.setState({
      parentDraft: tempData,
      parentIndex,
      parentModal: true, // open parent modal
      isNewParent
    });
  }

  closeParentModal = () => {
    this.setState({
      parentModal: false,
      parentDraft: null,
      isNewParent: null
    });
  }

  confirmDeleteParent = (i) => {
    this.setState({
      parentIndex: i,
      showDialogConfirmDeleteParentItem: true
    });
  }

  deleteParent = () => {
    const { parentIndex, parentItems } = this.state;
    const parentItemsNew = [...parentItems];

    parentItemsNew.splice(parentIndex, 1);

    this.setState({
      parentIndex: null,
      parentItems: parentItemsNew,
      showDialogConfirmDeleteParentItem: false
    }, () => {
      this.props.onUpdate(parentItemsNew); // Save to parent
    });
  }

  saveParent = () => {
    const { parentItems, parentIndex, parentDraft } = this.state;

    const parentItemsNew = parentItems ? [...parentItems] : [];

    if (this.state.isNewParent) {
      parentItemsNew.push(parentDraft);
    } else {
      parentItemsNew[parentIndex] = parentDraft;
    }

    this.setState({
      parentItems: parentItemsNew
    }, () => {
      this.props.onUpdate(parentItemsNew); // Save to parent
    });

    this.closeParentModal();
  }

  saveRecord = ({ value, tempDataKey }) => {
    // const tempData = this.state[tempDataKey];

    this.setState({
      [tempDataKey]: value
    });
  }

  render() {
    const {
      parentItems,
      parentDraft,
      parent,
      showDialogConfirmDeleteParentItem,
      maxItems
    } = this.state;

    const dialogConfirmDeleteParent = {
      visible: showDialogConfirmDeleteParentItem,
      title: 'Confirmation',
      text: 'Are you sure you want to delete?',
      primaryButton: {
        func: this.deleteParent,
        text: 'Yes'
      },
      secondaryButton: {
        func: () => {
          this.setState({
            showDialogConfirmDeleteParentItem: false
          });
        }
      },
      close: () => {
        this.setState({
          showDialogConfirmDeleteParentItem: false
        });
      }
    };

    // const showAddButton = (!parentItems || (parentItems && parentItems.length < maxItems));

    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center">
          <label htmlFor="type" className="mb-2">
            <b>{parent.title}:</b> <span className="text-danger">*</span>
          </label>
        </div>

        {parentItems && parentItems.length > 0 && (
          <div className="references rounded border p-1">
            {parentItems.map((parentItem, i) => (
              <Item
                key={i}
                index={i}
                value={parentItem}
                onClickEdit={this.manageParent}
                onClickDelete={this.confirmDeleteParent}
              />
            ))}
          </div>
        )}

        {(!parentItems || (parentItems && parentItems.length < maxItems)) && (
          <div className="references-actions mt-2">
            <button
              className="btn btn-link"
              onClick={() => {
                this.manageParent();
              }}
              type="button"
            >
              <i className="fas fa-plus mr-1" />
              <span>
                Add Record
              </span>
            </button>
          </div>
        )}

        <Modal
          cssClassName="turbine-modal--style-card turbine-modal--attendanceDate"
          visible={this.state.parentModal}
          close={false}
          theme="dark"
        >
          <div className="card">
            <div className="card-header bg-dark">
              <h5>
                {parentDraft ? 'Edit' : 'Add'} Record
              </h5>
            </div>
            <div className="card-body">
              <label htmlFor="attendanceDate" className="mb-2">
                <b>Date/Time</b> <span className="text-danger">*</span>
              </label>
              {/* <div className="text-muted medium mb-2">
                Select date and time
              </div> */}
              <CalendarDatePicker
                selected={parentDraft ? new Date(parentDraft) : null}
                id='attendanceDate'
                name='attendanceDate'
                className="form-control"
                // onChange={(date) => onChangeCalendar('startDate', date)}
                onChange={(date) => {
                  this.saveRecord({
                    tempDataKey: 'parentDraft',
                    value: date.toISOString()
                  });
                }}
                inline
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                required
                autoFocus
              />
              <div>
                Click the clock icon or type the specific time.
              </div>
            </div>

            <div className="card-footer">
              <button
                className="btn btn-md btn-primary"
                onClick={this.saveParent}
                type="button"
              >
                Save
              </button>
              <button
                className="btn btn-link ml-3"
                onClick={this.closeParentModal}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {dialogConfirmDeleteParent.visible && (
          <Modal.Dialog {...dialogConfirmDeleteParent} />
        )}

      </Fragment>
    );
  }
}

export default Records;
