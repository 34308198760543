import React from 'react';
import styled from 'styled-components';
import { VELA_ICON } from '@apprentage/constants';
import { FaFan } from 'react-icons/fa';
import PromptMaterial from '../../Modals/ResourcePreview/Assistant/PromptMaterial';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import './three-dot-loader.css';

const SearchVelaResponse = ({
  vectorAnswer,
  vectorSearchId,
  materialId
}) => {
  return (
    <div
      key={`message-${vectorSearchId}`}
      className="d-flex mb-4"
    >
      <div className="d-none d-sm-flex justify-content-center mr-3">
        <MessageAvatar
          className="d-flex align-items-center justify-content-center text-white"
          style={{
            background: 'black',
            border: '1px solid #4b4b4b'
          }}
        >
          {vectorAnswer ? (
            <img
              src={VELA_ICON}
              alt="VELA"
              height={30}
              style={{
                height: '30px'
              }}
            />
          ) : (
            <FaFan className='fa-spin' />
          )}
        </MessageAvatar>
      </div>
      <div className="w-100">
        <MessageContainer
          data-demo="disabled"
          className="m-0"
        >
          <div className="d-flex align-items-center justify-content-between mb-1">
            <div
              className='font-weight-bold opacity-75 text-dark'
              style={{ fontSize: '1rem' }}
            >
              VELA
            </div>
            <div className='d-flex align-items-center'>

              {/* {vectorAnswer && (
                                <PromptDotMenu
                                  key={material?.id || vectorSearchId}
                                  output={vectorAnswer}
                                  outputTitle={searchValue}
                                  resourceId={null} // TODO
                                  promptId={null}
                                  promptType="vectorSearch"
                                  promptText={searchValue}
                                />
                              )} */}
            </div>
          </div>
          <div
            className="shadow p-3 rounded bg-white position-relative"
            style={{
              wordBreak: 'break-word'
            }}
          >
            {vectorAnswer ? (
              <RenderMarkdown
                // enableNewEditor
                className="p-3"
                source={vectorAnswer || ''}
              />
            ) : (
              <div className="pb-3 px-3 pt-0">
                <div className="three-dot-loader m-0" />
              </div>
            )}

            <PromptMaterial
              key={materialId || vectorSearchId}
              userPromptId={null}
            />
          </div>
        </MessageContainer>
      </div>
    </div>
  );
};

const MessageContainer = styled.div`
  * {
    font-size: 1.25rem;
  }

  p {
    font-size: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MessageAvatar = styled.div`
  border-radius: 100%;
  font-family: 'Arial Bold', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
`;

export default SearchVelaResponse;
