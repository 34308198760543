import { SHEET_KEY_USER_DETAILS } from '../constants/sideSheets';
import { entryOrgMatchesUserOrg } from '../services/organizations';
import { getDirectoryUser, resetDirectoryUser, resetDirectoryUserProfile } from './Directory';
import { SET_SIDE_SHEET, RESET_SIDE_SHEET, UPDATE_SIDE_SHEET } from './types';
import { scrollElem } from '../services/manageContent';

export const setSideSheet = (sideSheet) => {
  document.body.classList.add('turbine-side-sheet-active');

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_SIDE_SHEET,
        sideSheet: {
          ...sideSheet
        }
      });
      resolve();
    });
  };
};

export const updateSideSheet = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_SIDE_SHEET,
        payload: data
      });
      resolve();
    });
  };
};

export const resetSideSheet = () => {
  document.body.classList.remove('turbine-side-sheet-active');

  return (dispatch) => {
    dispatch({ type: RESET_SIDE_SHEET });
  };
};

export const handleOpenUserSideSheet = ({
  userId,
  orgId,
  currentUserOrgId,
  route,
  history
}) => {
  return (dispatch) => {
    dispatch(getDirectoryUser({ orgId, userId })).then((response) => {
      if (entryOrgMatchesUserOrg(response?.directoryUser?.orgId, currentUserOrgId)) {
        dispatch(setSideSheet({
          key: SHEET_KEY_USER_DETAILS,
          ...(route ? { data: { route } } : {}),
          callbackPrimaryAction: () => {
            if (route) {
              // Removes userId from URL
              history.push(route);
            }
            dispatch(resetDirectoryUserProfile());
            dispatch(resetDirectoryUser());
          }
        }));
        scrollElem('user-details-body');
      }
    });
  };
};
