const shortenString = (str = '', maxCharLen, ellipsis = true) => {
  if (!str) {
    return str;
  }

  if (str.length > maxCharLen && maxCharLen) {
    const shortString = str.substring(0, maxCharLen);
    return `${shortString}${ellipsis ? '...' : ''}`;
  }

  return str;
};

export default shortenString;
