import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import pluralize from 'pluralize';
import { resetCurrentModal } from '../../../actions/Modals';
import { unpublish } from '../../../services/contentful';
import { getQuizOutcomes } from '../../../actions/Quizzes';
import Modal from '../../Modal';
import Pagination from '../../Pagination';
import Loading from '../../Loading';
import QuizOutcomesList from '../../QuizOutcomesList';

const ManageQuizOutcomes = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Local State
  const [loading, setLoading] = useState(false);
  // Misc
  const orgId = pathOr('', ['id'], organization);
  const quizId = pathOr('', ['data', 'quizId'], currentModal);
  const pagination = pathOr(null, ['pagination'], quizOutcomes);
  const total = pathOr(0, ['total'], pagination);
  const limit = pathOr(200, ['limit'], pagination);
  const skip = pathOr(0, ['skip'], pagination);

  const numQuizOutcomes = useMemo(() => {
    return quizOutcomes?.list ? quizOutcomes?.list.length : 0;
  }, [quizOutcomes?.list]);

  if (!quizOutcomes.list || !quizOutcomes.list.length) {
    return false;
  }

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleClickPage = (page) => {
    const currentPageIndex = parseInt(page, 10);
    const config = {
      ...(quizId ? { quizId } : { quizId }),
      orgId,
      limit,
      skip: currentPageIndex * limit
    };

    if (currentPageIndex !== skip) {
      dispatch(getQuizOutcomes(config));
    }
  };

  const deleteEntries = () => {
    setLoading(true);

    const entriesToUnpublish = quizOutcomes.list.map((a) => a.id);

    unpublish(entriesToUnpublish, 'Entry').then(() => {
      dispatch(
        getQuizOutcomes({
          ...(quizId ? { quizId } : { quizId }),
          orgId,
          skip: 0,
          limit
        })
      ).then((response) => {
        if (!response) {
          toast.success('Quiz Outcomes deleted!');
          handleClose();
        } else {
          toast.info('Please review and delete remaining Quiz Outcomes', {
            autoClose: false
          });
          setLoading(false);
        }
      });
    });
  };

  const deleteVerbiage = ({ baseText }) => {
    return `${baseText} ${pluralize('Quiz Outcome', numQuizOutcomes, true)}`;
  };

  const confirmDelete = () => {
    const stringCheck = 'permanently delete';
    const promptText = `${deleteVerbiage({ baseText: 'Permanently delete' })}?\n\nType "permanently delete" in the text field and click OK.`;
    const confirmString = prompt(promptText);

    if (confirmString === null || confirmString === '') {
      // No action
      // Cancel or empty field
    } else if (confirmString !== stringCheck) {
      toast.info(
        'We found a typo, try again if you wish to delete Quiz Outcomes.',
        { autoClose: false }
      );
    } else {
      // Success
      // entered stringCheck value and clicked OK
      deleteEntries();
    }
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-fullscreen turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={false}
      pos="middle"
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0 d-flex align-items-center">
            <span>Manage Quiz Outcomes</span>
            <span className="badge bg-white border ml-2">
              {quizOutcomes.list
                && total !== 0
                && `${quizOutcomes.list.length}`}
              {total === 0 || total <= limit ? '' : ` / ${total}`}
            </span>
          </h1>
        </div>

        <div className="card-body p-0">
          {loading ? (
            <Loading text={deleteVerbiage({ baseText: 'Deleting' })} />
          ) : (
            <QuizOutcomesList
              data={quizOutcomes.list}
              quizId={quizId}
              reviewActive
            />
          )}
        </div>

        {quizOutcomes.list && pagination && (
          <Pagination
            items={quizOutcomes.list}
            paginationData={pagination}
            handleClickPage={handleClickPage}
          />
        )}

        <div className="card-footer">
          <button
            onClick={() => {
              toast.dismiss();
              confirmDelete();
            }}
            className="btn btn-primary btm-sm"
            type="button"
            disabled={loading}
          >
            {loading ? 'Deleting...' : deleteVerbiage({ baseText: 'Delete' })}
          </button>

          <button
            onClick={handleClose}
            className="btn btn-link ml-2"
            type="button"
            disabled={loading}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ManageQuizOutcomes;
