import React from 'react';
import { FaEnvelope, FaIdBadge } from 'react-icons/fa';

const IncludeEmailCell = ({
  row
}) => {
  const includeEmail = row?.original?.includeEmail || false;
  const includeExternalUserId = row?.original?.includeExternalUserId || false;
  if (includeEmail || includeExternalUserId) {
    return ( // row
      <div className='d-flex justify-content-start flex-column'>
        {includeEmail && (
          <div>
            <div className="badge border bg-white p-1 mb-1">
              <span className='d-flex align-items-center'>
                <FaEnvelope size={12} className='text-muted' />
                <span className="ml-1">Email</span>
              </span>
            </div>
          </div>
        )}

        {includeExternalUserId && (
          <div>
            <div className="badge border bg-white p-1 mb-1">
              <span className='d-flex align-items-center'>
                <FaIdBadge size={12} className='text-muted' />
                <span className="ml-1">External ID</span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default IncludeEmailCell;
