import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_USERS_READONLY,
  NOTICE_USERS_READONLY_BODY,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../actions/FeatureFlags';
import { setCurrentModal } from '../../../actions/Modals';
import {
  canInviteCourseUser,
  canInviteUser
} from '../../../permissions/invite';

const InviteUserButton = ({
  text = 'Invite Users',
  className = 'btn btn-sm btn-primary text-nowrap',
  children,
  style = {},
  modalTitle = 'Invite Users',
  disabled
}) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_USERS_READONLY]);
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const currentCohort = useSelector((state) => state.currentCohort);
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Misc
  const role = pathOr([], ['role'], currentUser);
  const orgSlug = pathOr(null, ['slug'], organization);
  const orgName = pathOr(null, ['name'], organization);
  const orgEmail = pathOr(null, ['email'], organization);
  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);

  const showInviteModal = () => {
    if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_USERS_READONLY_TITLE,
        modalBody: NOTICE_USERS_READONLY_BODY
        // preventClose: true
      }));
      return;
    }

    dispatch(
      setCurrentModal({
        key: 'inviteUser',
        data: {
          orgSlug,
          orgName,
          orgEmail,
          modalTitle,
          ...(currentCohort.id ? { cohortId: currentCohort.id } : {}),
          ...(apprenticeshipId ? { apprenticeshipId } : {})
        }
      })
    );
  };

  const handleClick = () => {
    showInviteModal();
  };

  if (!currentUser.id) {
    return null;
  }

  if (!canInviteUser(role) && !canInviteCourseUser(role)) {
    return null;
  }

  return (
    <button
      className={className}
      title={text}
      onClick={handleClick}
      type="button"
      style={style}
      disabled={disabled}
    >
      {children || text}
    </button>
  );
};

export default InviteUserButton;
