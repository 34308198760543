import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routeWithClassId } from '../../../services/courses';
import { CLASS_CONSOLE_USERS, CLASS_CONSOLE_USER_ROLES } from '../../../constants/routes';
import { canManageCourseContent } from '../../../services/currentUser';

const Tabs = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.course);
  const users = useSelector((state) => state.users);
  // Course
  const classId = course?.id || '';
  // Organization
  const orgType = organization?.type || '';
  // Permissions
  const manageCourse = canManageCourseContent({ course, currentUser, orgType });

  return (
    <ul
      className="nav nav-tabs border-0 position-relative"
      style={{ zIndex: 1 }}
    >
      <li className='nav-item mr-2'>
        <Link
          to={routeWithClassId({
            route: CLASS_CONSOLE_USERS,
            classId
          })}
          className="nav-link active"
        >
          <span>
            Users
          </span>
          {users?.pagination && (
            <span className='badge bg-white border ml-2 text-ships-officer'>
              {users?.pagination?.total || 0}
            </span>
          )}
        </Link>
      </li>

      {manageCourse && (
        <li className='nav-item'>
          <Link
            to={routeWithClassId({
              route: CLASS_CONSOLE_USER_ROLES,
              classId
            })}
            className="nav-link"
          >
            Roles
          </Link>
        </li>
      )}
    </ul>
  );
};

export default Tabs;
