import React from 'react';
import { FaStar } from 'react-icons/fa';
import RenderHtml from '../RenderHtml';

const EmbedWidget = ({
  className = '',
  embedCode,
  title,
  showTitle = false,
  onClickSetting,
  showSetting = false,
  isFeatured = false
}) => {
  return (
    <div className="card border shadow d-flex flex-column mb-4 position-relative">
      {isFeatured && (
        <div
          className="position-absolute"
          style={{ top: '-8px', left: '-8px' }}
        >
          <FaStar
            className='text-primary opacity-50'
            size={20}
          />
        </div>
      )}

      {(showTitle || showSetting) && (
        <div
          className={`card-header d-flex flex-row ${showTitle ? 'justify-content-between' : 'justify-content-end'} align-items-center`}
        >
          {showTitle && (
            <div className="h6 m-0 font-weight-bold">{title || ''}</div>
          )}
          {showSetting && (
            // TODO: Covert Setting to dot menu
            <div>
              <button
                className="btn-link btn-md"
                type="button"
                onClick={onClickSetting}
              >
                Settings
              </button>
            </div>
          )}
        </div>
      )}

      <RenderHtml
        className={className}
        html={embedCode}
      />
    </div>
  );
};

export default EmbedWidget;
