import {
  RESET_SEARCH,
  SET_SEARCH,
  SET_SEARCH_FETCHING,
  SET_SEARCH_GROUP_IDS,
  SET_SEARCH_USER_FEEDBACK,
  SET_SEARCH_VALUE
} from '../../actions/types';

const initialState = {
  groups: null,
  cdnFiles: null,
  searchId: null,
  searchGroupIds: null,
  resourceVecIds: null,
  resourceGroupIds: null,
  userFeedback: null,
  vectorFiles: null,
  vectorNodes: null,
  users: null,
  projects: null,
  courses: null,
  value: '',
  valueIsPrompt: false,
  fetching: false,
  fetched: false
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return {
        ...state,
        value: action.value,
        searchGroupIds: action.searchGroupIds || null
      };
    case SET_SEARCH_GROUP_IDS:
      return {
        ...state,
        searchGroupIds: action.searchGroupIds
      };
    case SET_SEARCH_FETCHING:
      return {
        ...state,
        fetching: action.fetching
      };
    case SET_SEARCH:
      return {
        ...state,
        groups: action.groups,
        cdnFiles: action.cdnFiles,
        vectorSearchId: action.vectorSearchId,
        resourceVecIds: action.resourceVecIds,
        resourceGroupIds: action.resourceGroupIds,
        resourceGroupsHash: action.resourceGroupsHash,
        vectorFiles: action.vectorFiles,
        vectorNodes: action.vectorNodes,
        value: action.value,
        valueIsPrompt: action.valueIsPrompt,
        users: action.users,
        courses: action.courses,
        projects: action.projects,
        fetched: true,
        fetching: false
      };
    case SET_SEARCH_USER_FEEDBACK:
      return {
        ...state,
        userFeedback: action.userFeedback !== null ? action.userFeedback : null
      };
    case RESET_SEARCH:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
