import React, { Fragment } from 'react';
import { ICON_CHECK_CIRCLE } from '@apprentage/components/dist/constants/icons';

const CourseType = ({
  showStepTitles,
  enableCourseContent,
  setEnableCourseContent,
  externalCourseUrl,
  setExternalCourseUrl
}) => {
  return (
    <Fragment>
      <div className="">
        <div className="row">
          {showStepTitles && (
            <div className="col-sm-12">
              <h5 className="mb-3">
                <b>Step 1.</b> Choose External or Create Content
              </h5>
            </div>
          )}
          <div className="col-sm-12 col-md-6">
            <div className="card">
              <div className="card-header bg-dark">
                <div className="h5 mb-0 text-white text-center">
                  Link External Course
                </div>
              </div>
              <div
                className="card-body border-left border-right"
                style={{ backgroundColor: '#f3f3f3' }}
              >
                <img
                  src="https://turbine-content.s3.us-east-2.amazonaws.com/course-external.jpg"
                  alt="External Course"
                  className="w-100"
                />
              </div>
              <div
                className="card-footer border-bottom border-left border-right text-center d-flex align-items-center justify-content-center"
                style={{ minHeight: '56px' }}
              >
                {enableCourseContent === false ? (
                  <i
                    className={`${ICON_CHECK_CIRCLE} text-success`}
                    style={{ fontSize: '1.3rem' }}
                  />
                ) : (
                  <button
                    className={`btn btn-sm btn-${
                      enableCourseContent === null
                        ? 'primary'
                        : 'outline-primary'
                    }`}
                    onClick={() => {
                      setEnableCourseContent(false);
                    }}
                    type="button"
                  >
                    Link External Course
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 mt-3 mt-md-0">
            <div className="card">
              <div className="card-header bg-dark">
                <div className="h5 mb-0 text-white text-center">
                  Create Content
                </div>
              </div>
              <div className="card-body border-left border-right">
                <img
                  src="https://turbine-content.s3.us-east-2.amazonaws.com/course-internal.jpg"
                  alt="Turbine Course"
                  className="w-100"
                />
              </div>
              <div
                className="card-footer border-bottom border-left border-right text-center d-flex align-items-center justify-content-center"
                style={{ minHeight: '56px' }}
              >
                {enableCourseContent === true ? (
                  <i
                    className={`${ICON_CHECK_CIRCLE} text-success`}
                    style={{ fontSize: '1.3rem' }}
                  />
                ) : (
                  <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => {
                      setEnableCourseContent(true);
                    }}
                    type="button"
                  >
                    Use Turbine LMS
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {enableCourseContent === false && (
        <div className="mt-3">
          {showStepTitles && (
            <h5 className="mb-3">
              <b>Step 2.</b> Enter an existing course URL
            </h5>
          )}
          <div>
            <div id="class-title">
              <label
                htmlFor="title"
                className="mb-1"
              >
                <b>External Course URL:</b>{' '}
                <span className="text-danger">*</span>
              </label>
              <div className="text-muted mb-2">
                Enter the URL of an existing course in your catalog.
              </div>
              <input
                type="text"
                className="form-control"
                name="externalCourseUrl"
                defaultValue={externalCourseUrl}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setExternalCourseUrl(value);
                }}
                required
                autoFocus
              />
              <div className="text-muted small mt-1">
                Ex: https://webiste.com/courses/course-name
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CourseType;
