import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { ICON_QUIZ } from '@apprentage/constants';
import { withAuthorization } from '../../Session';
import withConsole from '../../App/withConsole';
import { setCurrentClassQuizzes } from '../../../actions/Quizzes';
import { getCourses } from '../../../actions/Courses';
import QuizzesList from '../../QuizzesList';
import OrgConsoleHeader from '../../OrgConsole/OrgConsoleHeader';
import Tabs from './Tabs';

const Quizzes = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentClassQuizzes = useSelector((state) => state.currentClassQuizzes);
  const course = useSelector((state) => state.course);
  // Misc
  const orgId = pathOr(undefined, ['id'], organization);
  const classId = pathOr(undefined, ['id'], course);

  useEffect(() => {
    if (orgId && classId) {
      dispatch(getCourses({ orgId }));
      dispatch(setCurrentClassQuizzes({ classId }));
    }
  }, [orgId, classId]);

  return (
    <>
      <OrgConsoleHeader
        pageTitle="Quizzes"
        icon={ICON_QUIZ}
        className="my-3"
      />

      <Tabs
        active="all"
      />
      <div className="bg-white border-bottom border-left border-right shadow">
        <QuizzesList
          data={currentClassQuizzes.list}
          classId={classId}
        />
      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(Quizzes);
