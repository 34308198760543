import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCube } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { modulesTypesHash } from './constants';
import Modal from '../../Modal';

const moduleTypes = Object.entries(modulesTypesHash).map(([key, value]) => ({ ...value, moduleType: key }));

const CreateModuleModal = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };
  const handleSelectModule = (type) => {
    currentModal.callbackPrimaryAction(type);
  };

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h5 className='m-0 d-flex align-items-center'>
            <FaCube className='mr-2' size={15} />
            <span>
              Module
            </span>
            <span className='badge badge-secondary ml-2'>
              New
            </span>
          </h5>
        </div>
        <div className="card-body container">
          <div className="row">
            {moduleTypes.map((moduleTypeData) => (
              <div
                key={moduleTypeData.type}
                className="col-6 mb-2"
              >
                <ModuleType
                  title={moduleTypeData.title}
                  icon={moduleTypeData.icon}
                  type={moduleTypeData.type}
                  handleSelect={handleSelectModule}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ModuleType = ({
  title = '', icon, handleSelect, type
}) => {
  return (
    <div className="card rounded rounded-2 text-center widget-type-card shadow w-100">
      <div className="border p-3 bg-light">
        <h5 className="m-0">{title}</h5>
      </div>
      <div
        className="card-body d-flex justify-content-center p-5"
        style={{
          fontSize: '40px'
        }}
      >
        {icon}
      </div>
      <div className="card-footer">
        <button
          className="btn btn-primary btn-sm"
          type="button"
          onClick={() => handleSelect(type)}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default CreateModuleModal;
