import { fetchSupabaseEntries } from '../supabaseProxy';

const fetchUserData = async ({
  excludeInactive = true,
  excludeTestUsers = true,
  enrolledClassId,
  completedClassId,
  completedTopicId,
  orgId,
  roles,
  ...remainingConfig
}) => {
  const totalConfig = {
    ...remainingConfig,
    'f.orgId[eq]': orgId
  };
  const allRoles = roles || ['student', 'teacher', 'admin', 'owner', 'programManager', 'programTrainer'];

  totalConfig['f.role[ov]'] = allRoles.join(',');

  if (completedClassId) {
    totalConfig['f.completedCourseIds[ov]'] = completedClassId;
  }

  if (completedTopicId) {
    totalConfig['f.completedCourseTopicIds[ov]'] = completedTopicId;
  }

  if (enrolledClassId) {
    totalConfig['f.courseIds[ov]'] = enrolledClassId;
  }

  if (excludeTestUsers !== undefined) {
    totalConfig['f.testUser[is]'] = null;
  }

  if (excludeInactive !== undefined) {
    totalConfig['f.membership[!ov]'] = 'inactive';
  }

  try {
    const response = await fetchSupabaseEntries(totalConfig, 'users');

    return response?.total || 0;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const fetchUsersCompletedCourse = ({ completedClassId, orgId }) => {
  return new Promise((resolve, reject) => {
    const config = { completedClassId, orgId };

    fetchUserData(config).then((total) => {
      resolve(total);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchUsersCompletedTopic = ({ completedTopicId, orgId }) => {
  return new Promise((resolve, reject) => {
    const config = { completedTopicId, orgId };

    fetchUserData(config).then((total) => {
      resolve(total);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchUsersEnrolled = ({ enrolledClassId, orgId }) => {
  return new Promise((resolve, reject) => {
    const config = { enrolledClassId, orgId };

    fetchUserData(config).then((total) => {
      resolve(total);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};
