import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import ReactTooltip from 'react-tooltip';
import { FaCog } from 'react-icons/fa';
import { getApprenticeships } from '../../../actions/Apprenticeships';
import { canAccessAllLocations } from '../../../permissions/access';
import Loading from '../../Loading';
import List from './List';
import ListDisplayType from './ListDisplayType';
import CardsDisplay from './CardsDisplay';
import Tabs from './Tabs';
import Switch from '../../Switch';

/**
 * @param {string} employerId Employer Id of User
 * @param {string} instructionProviderId
 * @param {'TABLE' | 'CARD'} displayType
 * @param {string[]} hiddenColumns Hide columns from the table
 * @param {Function} onClickRow function to execute when we click on  table row or card
 * @returns {ReactElement} returns JSX with the component ApprenticeshipsList.
 */

const ApprenticeshipsList = ({
  ids,
  employerId,
  instructionProviderId,
  displayType,
  hiddenColumns,
  onClickRow,
  showControls = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const apprenticeships = useSelector((state) => state.apprenticeships);
  // Defaults
  const defaultDisplayType = 'TABLE';
  // Organization
  const orgId = organization?.id || '';
  // Current User
  const locationId = currentUser?.locationId || '';
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [activeDisplayType, setActiveDisplayType] = useState(displayType || defaultDisplayType);
  const [searchTerm, setSearchTerm] = useState('');

  const changeDisplayType = useCallback((type) => {
    setActiveDisplayType(type);
    localStorage.setItem('programsListDisplayType', type);
  }, []);

  const filterBySearchTerm = useCallback((c) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const title = c.title.toLowerCase().includes(lowerCaseSearchTerm);

    if (title) {
      return true;
    }

    return false;
  }, [searchTerm]);

  // TODO Give full control to hide/show columns
  // const columns = [
  //   'title',
  //   'employerIds',
  //   'competencies',
  //   'ojtHours',
  //   'startDate',
  //   'expectedEndDate'
  // ];

  const [hiddenCols, setHiddenCols] = useState(hiddenColumns || []);

  const filteredApprenticeships = useMemo(() => {
    let list = apprenticeships?.list;

    if (searchTerm) {
      list = list.filter((c) => filterBySearchTerm(c));
    }

    return list;
  }, [apprenticeships?.list, filterBySearchTerm, searchTerm]);

  useEffect(() => {
    const config = {
      orgId,
      ...(canAccessAllLocations(role) ? {} : { employerId: locationId })
    };

    if (employerId) {
      config.employerId = employerId;
    }

    if (instructionProviderId) {
      config.instructionProviderId = instructionProviderId;
    }

    if (ids) {
      config.ids = ids;
    }

    if (orgId) {
      dispatch(getApprenticeships(config));
    }
  }, [ids, orgId, employerId, locationId, instructionProviderId, role, dispatch]);

  useEffect(() => {
    const type = localStorage.getItem('programsListDisplayType') || null;

    if (type && !displayType) {
      setActiveDisplayType(type);
    }
  }, [displayType]);

  useEffect(() => {
    // https://www.npmjs.com/package/react-tooltip/v/4.5.1#3-tooltip-not-binding-to-dynamic-content
    ReactTooltip.rebuild();
  }, [changeDisplayType, activeDisplayType]);

  if (!apprenticeships.fetched && !apprenticeships.list) {
    return <Loading text="Loading Apprenticeships..." />;
  }

  return (
    <>
      <div className="apprenticeships-list">
        {showControls && (
          <div className="row py-3">
            <div className="col d-flex align-items-center justify-content-between">
              <SearchBox
                onSubmit={(newSearchTerm) => {
                  setSearchTerm(newSearchTerm);
                }}
                autoFocus
                placeholder="Search by title..."
              />
              <div className="d-flex">

                <div className="dropdown">
                  <button
                    className={`btn btn-sm ${hiddenCols.includes('ojtHours') ? 'btn-outline-secondary border' : 'btn-primary'} dropdown-toggle mr-2 d-flex py-2 align-items-center`}
                    type="button"
                    id="userConfig"
                    title="Settings"
                    aria-label='Settings'
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FaCog />
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-right p-3"
                    aria-labelledby="userConfig"
                  >
                    <h6>
                      Table Columns
                    </h6>
                    <Switch
                      id="ojtHours"
                      className='mb-3'
                      label="OJT Hours"
                      value={!hiddenCols.includes('ojtHours')}
                      onChange={() => {
                        setHiddenCols((prev) => {
                          if (hiddenCols.includes('ojtHours')) {
                            // Currently hidden
                            const newCols = prev.filter((items) => items !== 'ojtHours');

                            return newCols;
                          }
                          // Currently visible
                          return [...prev, 'ojtHours'];
                        });
                      }}
                    />
                  </div>
                </div>

                <ListDisplayType
                  onChange={changeDisplayType}
                  activeType={displayType || activeDisplayType}
                />
              </div>
            </div>
          </div>
        )}

        {activeDisplayType === 'TABLE' && (
          <>
            {showControls && (
              <Tabs active="active" />
            )}
            <div
              className='bg-white'
              style={{
                overflowX: 'scroll'
              }}
            >
              <List
                data={filteredApprenticeships}
                hiddenColumns={hiddenCols}
                onClickRow={onClickRow}
              />
            </div>
          </>
        )}

        {activeDisplayType === 'CARD' && (
          <CardsDisplay
            data={filteredApprenticeships}
          />
        )}
      </div>
      <ReactTooltip id="ApprenticeshipsList" />
    </>
  );
};

export default ApprenticeshipsList;
