import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import { ICON_COURSE, ICON_REORDER } from '@apprentage/constants';
import { getCourseGroup, resetCourseGroup } from '../../../../actions/Groups';
import { withAuthorization } from '../../../Session';
import { routeWithOrgId } from '../../../../services/organizations';
import withOrgConsole from '../../../App/withOrgConsole';
import { ORG_CONSOLE_COURSES, ORG_CONSOLE_DASHBOARD } from '../../../../constants/routes';
import { resetCourses } from '../../../../actions/Courses';
import Form from './Form';
import Tabs from '../Tabs';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsoleSubheader';

const ReorderCourses = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  const courseGroup = useSelector((state) => state.courseGroup);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const groupId = pathOr(null, ['groupId'], params);
  const courseGroupRouteBase = `${ORG_CONSOLE_COURSES}/group/${groupId}`;
  const courseGroupRoute = routeWithOrgId({
    route: courseGroupRouteBase,
    orgId
  });

  useEffect(() => {
    if (groupId) {
      dispatch(getCourseGroup({ groupId }));
    }

    return function cleanup() {
      const href = pathOr('', ['location', 'href'], window);
      const groupRoute = `org/courses/group/${groupId}`;

      dispatch(resetCourses());

      // Only reset course group if not going to back to course group
      if (!href.includes(groupRoute)) {
        dispatch(resetCourseGroup());
      }
    };
  }, []);

  if (!groupId || (groupId && !courses.fetched && !courses.list)) {
    history.replace(courseGroupRoute);
    return false;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-xl-7">
        <OrgConsoleHeader
          pageTitle="Courses"
          icon={ICON_COURSE}
          route={ORG_CONSOLE_DASHBOARD}
        />

        <Tabs active="groups" />

        <div
          className='mb-5 bg-white border-left border-right border-bottom p-3'
        >
          <OrgConsoleSubheader
            pageTitle="Reorder"
            icon={ICON_REORDER}
            iconStyle={{
              fontSize: '1rem'
            }}
            route={courseGroupRouteBase}
          />

          <div className='card mt-3'>
            <div className='card-header bg-dark border-dark'>
              <h6 className='m-0 text-white font-weight-bold'>
                {courseGroup.title}
              </h6>
            </div>

            <Form />
          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ReorderCourses);
