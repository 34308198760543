import React from 'react';

const QuestionsCell = ({ value: questions }) => {
  return (
    <div className="text-center">
      {questions && questions.length}
    </div>
  );
};

export default QuestionsCell;
