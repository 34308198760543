import { RESET_INSTRUCTION_PROVIDERS, SET_INSTRUCTION_PROVIDERS } from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  // Pagination
  pagination: null,
  page: 1,
  total: 0,
  limit: 25,
  after: null,
  before: null
};

export default function instructionProviders(state = initialState, action) {
  switch (action.type) {
    case SET_INSTRUCTION_PROVIDERS: {
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        page: action.page || initialState.page,
        total: action.total,
        limit: action.limit,
        after: action.after || initialState.after,
        before: action.after || initialState.before,
        fetched: true
      };
    }
    case RESET_INSTRUCTION_PROVIDERS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
