import { compose, isEmpty, symmetricDifference } from 'ramda';

const arraysValuesAreEqual = (arr1, arr2) => {
  if (arr1 && Array.isArray(arr1) && arr2 && Array.isArray(arr2)) {
    const eqValues = compose(isEmpty, symmetricDifference);

    return eqValues(arr1, arr2);
  }

  return true;
};

export default arraysValuesAreEqual;
