import {
  RESET_WAGE_SCHEDULES,
  SET_WAGE_SCHEDULES
} from '../../actions/types';

const initialState = {
  list: null,
  fetched: false
};

export default function wageSchedules(state = initialState, action) {
  switch (action.type) {
    case SET_WAGE_SCHEDULES:
      return {
        ...state,
        list: action.list,
        fetched: true
      };
    case RESET_WAGE_SCHEDULES:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
