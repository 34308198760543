const jobProfileState = {
  createdAt: '',
  id: '',
  OccupationDescription: '',
  OnetCode: '',
  OnetTitle: '',
  location: '', // State Abbr (capitalized)
  occupationLocation: '', // Postal Code
  orgId: '',
  selectedCerts: null, // []
  selectedKnowledge: null, // []
  selectedSchoolPrograms: null, // []
  selectedSkills: null, // []
  title: '',
  updatedAt: ''
};

export default jobProfileState;
