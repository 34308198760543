import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VELA_ICON } from '@apprentage/constants';
import ReactTooltip from 'react-tooltip';
import { canInviteUser } from '../../../../permissions/invite';
import { ORG_CONSOLE_USERS } from '../../../../constants/routes';
import MessageAvatar from '../../../MessageAvatar';
import TypingText from '../../../TypingText';
import './style.css';

const NextActions = ({
  className = 'mt-3',
  badgeText,
  badgeClassName = ''
}) => {
  const history = useHistory();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];
  const firstName = currentUser?.firstName || null;

  const onClick = () => {
    history.push(ORG_CONSOLE_USERS);
  };

  return (
    <>
      {canInviteUser(role) && (
        <div
          className={`NextActions nba-row-clickable border p-3 rounded ${className}`}
        >

          <div
            className='d-flex align-items-start'
            onClick={onClick}
            aria-hidden
          >
            <MessageAvatar
              height={30}
              width={30}
              className="d-flex align-items-center justify-content-center mt-1 text-white"
              style={{
                background: 'black',
                border: '1px solid #4b4b4b'
              }}
              // data-tooltip-content
              // data-tooltip-id="NextActions"
              data-tip={`👋 Hi ${firstName}, click to take action`}
              data-for="NextActions"
            >
              <img
                src={VELA_ICON}
                alt="VELA"
                height={20}
                style={{
                  height: '20px',
                  flexGrow: 0,
                  flexShrink: 0
                }}
              />
            </MessageAvatar>
            <div className='ml-2'>
              <div className='small'>
                VELA
              </div>
              <div
                className='nba-text'
              >
                <TypingText text="Invite colleagues to join your workforce space." ms={30} />
              </div>
            </div>
          </div>

          {badgeText && (
            <div className='nbaBadge h6 m-0'>
              <div className={`badge ${badgeClassName}`}>{badgeText}</div>
            </div>
          )}
        </div>
      )}

      <ReactTooltip id="NextActions" html />
    </>
  );
};

export default NextActions;
