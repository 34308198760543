import React, { useCallback, useEffect, useState } from 'react';
import './style.css';
import { FaArrowCircleRight, FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';
import InputFakeLoader from '../../../components/ManageContent/InputFakeLoader';
import copyToClipBoard from '../../../utils/copyToClipBoard';

const InputChangeValue = ({
  title,
  helpText,
  name,
  textarea,
  textareaStyle = {},
  autoFocus,
  placeholder,
  inputGroupPrepend,
  defaultValue,
  isBoolean,
  primaryActionText = 'Yes',
  callbackPrimaryAction,
  secondaryActionText = 'No',
  callbackSecondaryAction,
  tertiaryActionText = 'Skip',
  callbackTertiaryAction,
  loading,
  copyValue
}) => {
  // Local State
  const [changeValue, setChangeValue] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [showAppsTertiaryLink, setShowAppsTertiaryLink] = useState(false);

  const onClickPrimary = () => {
    callbackPrimaryAction(currentValue);
  };

  const onClickTertiary = () => {
    callbackTertiaryAction(currentValue);
  };

  const onClickSecondary = () => {
    if (!isBoolean) {
      setChangeValue(true);
    }

    if (callbackSecondaryAction) {
      callbackSecondaryAction();
    }
  };

  const timeoutAppsTertiaryLink = useCallback(() => {
    if (!showAppsTertiaryLink) {
      setTimeout(() => {
        setShowAppsTertiaryLink(true);
      }, 2000);
    }
  }, [showAppsTertiaryLink]);

  useEffect(() => {
    timeoutAppsTertiaryLink();
  }, [timeoutAppsTertiaryLink]);

  return (
    <div className="InputChangeValue">
      <div className="InputChangeValue-title my-4">
        {title}
      </div>

      {!isBoolean && (
        <div>

          {textarea ? (
            <textarea
              name={name}
              className="form-control form-control-md shadow"
              value={currentValue}
              autoFocus={autoFocus}
              onChange={(e) => {
                const { value } = e.currentTarget;
                setCurrentValue(value);
              }}
              style={textareaStyle}
            />
          ) : (
            <InputFakeLoader
              loading={loading}
              size="lg"
            >
              {inputGroupPrepend ? (
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text"
                      style={{
                        fontSize: '12px',
                        color: 'black'
                      }}
                    >
                      {inputGroupPrepend}
                    </span>
                  </div>
                  <Input
                    name={name}
                    autoFocus={autoFocus}
                    currentValue={currentValue}
                    setCurrentValue={setCurrentValue}
                    placeholder={placeholder}
                  />
                  {copyValue && !loading && (
                    <button
                      type="button"
                      title="Copy vanity URL"
                      aria-label="Copy vanity URL"
                      className='btn btn-sm btn-white px-2'
                      onClick={() => {
                        copyToClipBoard(copyValue);
                        toast.success('Copied to clipboard!');
                      }}
                    >
                      <FaCopy />
                    </button>
                  )}
                </div>
              ) : (
                <Input
                  name={name}
                  currentValue={currentValue}
                  setCurrentValue={setCurrentValue}
                  placeholder={placeholder}
                />
              )}
            </InputFakeLoader>
          )}
        </div>
      )}

      {helpText && (
        <div className='mt-2 text-center'>
          {helpText}
        </div>
      )}

      <div
        className={`mt-4 mx-5 mx-sm-0 d-flex align-items-center ${!isBoolean ? 'justify-content-center' : 'justify-content-between'}`}
      >
        {isBoolean && onClickSecondary && (
          <button
            onClick={onClickSecondary}
            className="btn btn-outline-secondary InputChangeValue-btn"
            type='button'
            disabled={!currentValue && !isBoolean}
          >
            {secondaryActionText}
          </button>
        )}

        {isBoolean && onClickPrimary && (
          <button
            onClick={onClickPrimary}
            className="btn bg-000 text-white py-2 InputChangeValue-btn text-nowrap position-relative overflow-hidden"
            type='button'
          >
            <span style={{ zIndex: 2 }} className='d-flex align-items-center justify-content-center position-relative'>
              <span
                className='position-relative mr-2'
              >
                {changeValue ? 'Next' : primaryActionText}
              </span>
              <FaArrowCircleRight />
            </span>
          </button>
        )}

        {!isBoolean && onClickPrimary && (
          <button
            onClick={onClickPrimary}
            className={`btn ${!currentValue ? 'btn-white' : 'bg-000 text-white'} py-2 InputChangeValue-btn text-nowrap position-relative overflow-hidden`}
            type='button'
            disabled={!currentValue}
          >
            <span style={{ zIndex: 2 }} className='d-flex align-items-center justify-content-center position-relative'>
              <span
                className='position-relative mr-2'
              >
                {changeValue ? 'Next' : primaryActionText}
              </span>
              <FaArrowCircleRight className={!currentValue ? 'opacity-25' : ''} />
            </span>
            {currentValue && (
              <span className="start-btn-pulse" />
            )}
          </button>
        )}
      </div>

      {callbackTertiaryAction && (
        <div
          className="mt-3 d-flex align-items-center justify-content-center"
          style={{
            opacity: showAppsTertiaryLink || currentValue ? 1 : 0
          }}
        >
          <button
            onClick={onClickTertiary}
            className="btn btn-sm btn-link"
            type='button'
          >
            {tertiaryActionText}
          </button>
        </div>
      )}
    </div>
  );
};

const Input = ({
  name,
  placeholder,
  autoFocus,
  currentValue,
  setCurrentValue
}) => {
  return (
    <input
      name={name}
      type="text"
      autoFocus={autoFocus}
      placeholder={placeholder}
      className="InputChangeValue-input form-control form-control-lg"
      value={currentValue}
      onChange={(e) => {
        const { value } = e.currentTarget;
        setCurrentValue(value);
      }}
    />
  );
};

export default InputChangeValue;
