import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal, setCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import ChooseTrainingProgramTemplate from './ChooseTrainingProgramTemplate';
import {
  fetchApprenticeshipTemplate,
  findApprenticeshipTemplates
} from '../../../services/apprenticeshipTemplates';

import { resetApprenticeship } from '../../../actions/Apprenticeships';
import DuplicateApprenticeshipTitle from '../DuplicateApprenticeship/DuplicateApprenticeshipTitle';
import Form from '../../OrgConsole/WorkforceSuite/Apprenticeship/Manage/Form';
import { SET_APPRENTICESHIP } from '../../../actions/types';
import { createTrainingEnum } from '../../OrgConsole/WorkforceSuite/Apprenticeship/Manage/constants';
import { MODAL_KEY_CREATE_TRAINING_PROGRAM } from '../../../constants/modals';

const CloneSuccessfulTrainings = () => {
  const MODALSTEPS = {
    CHOOSE_TRAINING_PROGRAM: 'CHOOSETRAININGPROGRAM',
    DUPLICATE_APPRENTICESHIP: 'DUPLICATEAPPRENTICESHIP',
    CREATE_TRAINING_PROGRAM: 'CREATE_TRAINING_PROGRAM'
  };
  const dispatch = useDispatch();
  const currentModal = useSelector((state) => state.currentModal);

  const [programTitle, setProgramTitle] = useState('');
  const [step, setStep] = useState(MODALSTEPS.CHOOSE_TRAINING_PROGRAM);
  const [trainingList, setTrainingList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };
  const fetchTrainingProgramTemplates = async (title = '', limit = 6) => {
    try {
      setLoading(true);
      const response = await findApprenticeshipTemplates({ title, limit });

      if (Array.isArray(response)) {
        setTrainingList(response);
      } else {
        setTrainingList([]);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const getApprenticeshipTemplate = (id) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeshipTemplate(id)
        .then((apprenticeshipTemplate) => {
          dispatch({
            type: SET_APPRENTICESHIP,
            apprenticeship: apprenticeshipTemplate
          });
          resolve(apprenticeshipTemplate);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    return function cleanup() {
      dispatch(resetApprenticeship());
    };
  }, []);

  const renderStepContent = () => {
    switch (step) {
      case MODALSTEPS.CHOOSE_TRAINING_PROGRAM:
        return (
          <ChooseTrainingProgramTemplate
            handleClickBack={() => {
              dispatch(
                setCurrentModal({ key: MODAL_KEY_CREATE_TRAINING_PROGRAM })
              );
            }}
            trainingList={trainingList}
            handleSearch={(title, limit) => {
              fetchTrainingProgramTemplates(title, limit);
            }}
            handleSelectTrainingProgram={(apprenticeshipTemplate) => {
              dispatch(getApprenticeshipTemplate(apprenticeshipTemplate.id));
              setStep(MODALSTEPS.DUPLICATE_APPRENTICESHIP);
            }}
            setTrainingList={setTrainingList}
            loadingTraining={loading}
            title={programTitle}
            setTitle={setProgramTitle}
          />
        );
      case MODALSTEPS.DUPLICATE_APPRENTICESHIP:
        return (
          <DuplicateApprenticeshipTitle
            handleContinue={() => setStep(MODALSTEPS.CREATE_TRAINING_PROGRAM)}
            modalTitle="Customize Proven Framework"
            handleBack={() => setStep(MODALSTEPS.CHOOSE_TRAINING_PROGRAM)}
          />
        );
      case MODALSTEPS.CREATE_TRAINING_PROGRAM:
        return (
          <div className="card">
            <div className="card-header">
              <h1 className="h5 m-0 text-dark">Create Training Program</h1>
            </div>
            <div className="card-body p-0">
              <Form
                isDuplicating
                isBuildingOwn={createTrainingEnum.CLONE_SUCCESSFUL_TRAINING}
                editOccupation={false}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchTrainingProgramTemplates();
  }, []);

  return (
    <Modal
      visible={currentModal.visible}
      close={handleClose}
      size="large"
    >
      <div>{renderStepContent()}</div>
    </Modal>
  );
};

export default CloneSuccessfulTrainings;
