import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SKILLS_IMG } from '@apprentage/constants';
import { canManageSkills } from '@apprentage/utils';
import Table from './Table';
import LabelCell from './NameCell';
import EditCell from './EditCell';
import { getSkills } from '../../../../actions/Skills';
import Loading from '../../../Loading';
import reactTableSortCaseInsensitive from '../../../../utils/reactTableSortCaseInsensitive';

const SkillsList = ({
  list,
  listConfig,
  editItem
}) => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const skills = useSelector((state) => state.skills);
  // Organization
  const orgId = organization?.id || null;
  const orgType = organization?.type || null;

  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!canManageSkills(role, orgType)) {
      cols.push('menuCell');
    }

    return cols;
  }, [role]);

  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: reactTableSortCaseInsensitive,
      Cell: LabelCell
    },
    {
      Header: 'Difficulty',
      accessor: 'difficulty'
    },
    {
      Header: '',
      accessor: 'menuCell',
      disableSortBy: true,
      Cell: (props) => (
        <EditCell
          onClickEdit={editItem}
          {...props}
        />
      )
    }
  ], [editItem]);

  useEffect(() => {
    if (orgId && listConfig) {
      dispatch(getSkills(listConfig));
    }
  }, [dispatch, listConfig, orgId]);

  if (!listConfig && !list) {
    return (
      <div className="py-5 d-flex justify-content-center flex-column">
        <div className="d-flex justify-content-center">
          <img
            src={SKILLS_IMG}
            width="250"
            alt="Skills"
          />
        </div>
        <div className="mt-3 w-100 text-center">
          No skills.
        </div>
      </div>
    );
  }

  if (listConfig && !skills.fetched && !skills.list) {
    return (
      <Loading text="Loading Skills..." />
    );
  }

  return (
    <Table
      columns={columns}
      hiddenColumns={hiddenColumns}
      data={list || skills?.list}
    />
  );
};

export default SkillsList;
