import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';

const inviteUsersHtml = ({
  linkText = 'Get Started',
  orgName,
  courseInvitation,
  courseTitle,
  fullName,
  apprenticeshipInvitation,
  apprenticeshipTitle,
  registerLink
}) => {
  let invitationOpeningLine = `
    <p>
      You've been invited to join ${orgName}'s Workforce Space.
    </p>
  `;

  let salutation = `
    <p>
      Hello there!
    </p>
  `;

  if (fullName) {
    salutation = `
      <p>
        Hi ${fullName}!
      </p>
    `;
  }

  if (courseInvitation) {
    invitationOpeningLine = `
      <p>
        You've been invited to join ${courseTitle} in ${orgName}'s Workforce Space.
      </p>
    `;
  }

  if (apprenticeshipInvitation) {
    invitationOpeningLine = `
      <p>
        You've been invited to join ${apprenticeshipTitle} sponsored by ${orgName}.
      </p>
    `;
  }

  return `
    ${salutation}

    ${invitationOpeningLine}

    <p>
      Click below to get started.
    </p>

    <div
      align="center"
      style="line-height:10px; padding-top:20px;padding-bottom:20px;"
    >
      ${ButtonPrimary({ text: linkText, href: registerLink })}
    </div>

    <p>
      Team ${orgName}
    </p>

    ${Divider()}

    <p>
      "${linkText}" button not working?
    </p>

    <p>
      Copy and paste this link in your browser:
    </p>

    <p>
      ${registerLink}
    </p>
  `;
};

export default inviteUsersHtml;
