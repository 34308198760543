import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { percentComplete } from '@apprentage/utils';
import { FaArrowCircleRight } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { withAuthorization } from '../../../components/Session';
import withOrgConsole from '../../../components/App/withOrgConsole';
import { IMAGE_TURBINE_RETRO_ICON_WHITE } from '../../../constants/assets';
import { ONBOARDING_TEAM_CONTEXT } from '../../../constants/routes';
import TypingText from '../../../components/TypingText';
import ProgressBars from '../../../components/ProgressBars';

const Welcome = () => {
  const history = useHistory();
  const turbineLogo = useRef(null);
  const screenFirst = useRef(null);
  const screenLast = useRef(null);

  const [totalSteps] = useState(8);
  const [currentStep, setCurrentStep] = useState(1);
  const [string0, setString0] = useState('');
  const [showStart, setShowStart] = useState(false);
  const [showContinueButton, setShowContinueButton] = useState(false);
  // const string1 = 'Smarter work-based Knowledge and Talent Systems';

  const percentage = percentComplete({
    current: currentStep,
    total: totalSteps
  });

  const onStart = () => {
    screenFirst.current.classList.add('scale-down');

    setTimeout(() => {
      setCurrentStep(2);
    }, 500);
  };

  const onContinue = () => {
    screenLast.current.classList.add('scale-down');

    setTimeout(() => {
      history.push(ONBOARDING_TEAM_CONTEXT);
    }, 500);
  };

  useEffect(() => {
    document.body.classList.add('onboarding');

    return function cleanup() {
      document.body.classList.remove('onboarding');
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // scale-up
      turbineLogo.current.classList.add('scale-up');
      setString0('Meet Turbine.');
    }, 250);
    setTimeout(() => {
      setShowStart(true);
    }, 2500);
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      setTimeout(() => {
        setCurrentStep(3);
      }, 5000);
    }
    if (currentStep === 3) {
      setTimeout(() => {
        setCurrentStep(4);
      }, 5000);
    }
    if (currentStep === 4) {
      setTimeout(() => {
        setCurrentStep(5);
      }, 5000);
    }
    if (currentStep === 5) {
      setTimeout(() => {
        setCurrentStep(6);
      }, 5000);
    }
    if (currentStep === 6) {
      setTimeout(() => {
        setCurrentStep(7);
      }, 5000);
    }
    if (currentStep === 7) {
      setTimeout(() => {
        setCurrentStep(8);
      }, 5000);
    }
    if (currentStep === 8) {
      setTimeout(() => {
        setShowContinueButton(true);
      }, 2500);
    }
  }, [currentStep]);

  return (
    <div
      className='h-100 row'
    >
      <div
        className='position-fixed'
        style={{
          top: 0,
          width: '100%',
          left: 0
        }}
      >
        <ProgressBars
          size="md"
          className='w-100 border-radius-0'
          data={[
            {
              className: 'bg-000',
              valueNow: currentStep,
              valueMax: totalSteps,
              valueMin: 0,
              style: { width: percentage },
              text: ''
            }
          ]}
        />
      </div>

      {currentStep === 1 && (
        <div
          id="screenFirst"
          ref={screenFirst}
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <span
              ref={turbineLogo}
              className="turbine-logo overflow-hidden shadow"
              style={{
                borderRadius: '1.5rem'
              }}
            >
              <img
                src={IMAGE_TURBINE_RETRO_ICON_WHITE}
                height={80}
                style={{
                  height: '80px'
                }}
                alt="Turbine"
              />
            </span>
            <h5
              className="my-4 font-weight-bold"
            >
              <span>
                <TypingText text={string0} />
              </span>
            </h5>

            <button
              style={{
                opacity: showStart ? 1 : 0
              }}
              className='btn btn-lg bg-000 text-white position-relative overflow-hidden'
              type='button'
              onClick={onStart}
            >
              <span style={{ zIndex: 2 }} className='d-flex align-items-center position-relative'>
                <span
                  className='position-relative mr-2'
                >
                  Get Started
                </span>
                <FaArrowCircleRight />
              </span>
              <span className="start-btn-pulse" />
            </button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <h5
              className="mb-4 font-weight-bold"
            >
              <span>
                <TypingText text="Capture and share team expertise" ms={20} />
              </span>
            </h5>

            <span
              className="overflow-hidden shadow onboarding-showcase-image"
            >
              <img
                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/vela-recording.jpg"
                height={472}
                style={{
                  height: '472px'
                }}
                alt="Capture Knowledge, Decisions & Agile Workflow"
              />
            </span>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <h5
              className="mb-4 font-weight-bold"
            >
              <span>
                <TypingText text="Foster collaboration through peer-to-peer learning" ms={20} />
              </span>
            </h5>

            <span
              className="overflow-hidden shadow onboarding-showcase-image"
            >
              <img
                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/course-groups.jpg"
                height={450}
                style={{
                  height: '450px'
                }}
                alt="Peer-to-peer learning"
              />
            </span>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <div
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <h5
              className="mb-4 font-weight-bold"
            >
              <span>
                <TypingText text="Find internal expertise to capture opportunities previously outsourced" ms={20} />
              </span>
            </h5>

            <span
              className="overflow-hidden shadow onboarding-showcase-image"
            >
              <img
                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/user-directory-skills.jpg"
                height={450}
                style={{
                  height: '450px'
                }}
                alt="Skills-centric User Directory"
              />
            </span>
          </div>
        </div>
      )}

      {currentStep === 5 && (
        <div
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <h5
              className="mb-4 font-weight-bold"
            >
              <span>
                <TypingText text="Find knowledge gaps through employee & customer searches" ms={20} />
              </span>
            </h5>

            <span
              className="overflow-hidden shadow onboarding-showcase-image"
            >
              <img
                src="https://turbine-content.s3.us-east-2.amazonaws.com/knowledge-gap-missing-resources.jpg"
                height={450}
                style={{
                  height: '450px'
                }}
                alt="Find Knowledge Gaps"
              />
            </span>
          </div>
        </div>
      )}

      {currentStep === 6 && (
        <div
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <h5
              className="mb-4 font-weight-bold"
            >
              <span>
                <TypingText text="Logbooks capture competencies & hours automatically." ms={20} />
              </span>
            </h5>

            <span
              className="overflow-hidden shadow onboarding-showcase-image"
            >
              <img
                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/logbook-review.jpg"
                height={450}
                style={{
                  height: '450px'
                }}
                alt="AI-Powered Logbooks"
              />
            </span>
          </div>
        </div>
      )}

      {currentStep === 7 && (
        <div
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <h5
              className="mb-4 font-weight-bold"
            >
              <span>
                <TypingText text="Real-time observability of training programs." ms={20} />
              </span>
            </h5>

            <span
              className="overflow-hidden shadow onboarding-showcase-image"
            >
              <img
                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/logbook-hours-chart.jpg"
                height={450}
                style={{
                  height: '450px'
                }}
                alt="Logbook Hours Chart"
              />
            </span>
          </div>
        </div>
      )}

      {currentStep === 8 && (
        <div
          className='col'
          id="screenLast"
          ref={screenLast}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <h5
              className="mb-4 font-weight-bold"
            >
              <span>
                <TypingText text="Knowledge Transfer, simplified." ms={20} />
              </span>
            </h5>

            <span
              className="overflow-hidden shadow onboarding-showcase-image"
            >
              <img
                src="https://turbine-content.s3.us-east-2.amazonaws.com/knowledge-tranfer-simplified.jpg"
                height={472}
                style={{
                  height: '472px'
                }}
                alt="Capture Knowledge, Decisions & Agile Workflow"
              />
            </span>

            <button
              style={{
                opacity: showContinueButton ? 1 : 0
              }}
              className='mt-4 btn btn-lg bg-000 text-white position-relative overflow-hidden'
              type='button'
              onClick={onContinue}
            >
              <span style={{ zIndex: 2 }} className='d-flex align-items-center position-relative'>
                <span
                  className='position-relative mr-2'
                >
                  Continue
                </span>
                <FaArrowCircleRight />
              </span>
              <span className="start-btn-pulse" />
            </button>
          </div>
        </div>
      )}

      {currentStep === 9 && (
        <div
          // id="screenLast"
          className='col'
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center h-100"
          >
            <span
              ref={turbineLogo}
              className="turbine-logo overflow-hidden shadow"
              style={{
                borderRadius: '1.5rem'
              }}
            >
              <img
                src={IMAGE_TURBINE_RETRO_ICON_WHITE}
                height={80}
                style={{
                  height: '80px'
                }}
                alt="Turbine"
              />
            </span>
            <h5
              className="my-4 font-weight-bold"
            >
              <span>
                <TypingText text={string0} />
              </span>
            </h5>

            <button
              style={{
                opacity: showStart ? 1 : 0
              }}
              className='btn btn-lg bg-000 text-white position-relative overflow-hidden'
              type='button'
              onClick={onContinue}
            >
              <span style={{ zIndex: 2 }} className='d-flex align-items-center position-relative'>
                <span
                  className='position-relative mr-2'
                >
                  Continue
                </span>
                <FaArrowCircleRight />
              </span>
              <span className="start-btn-pulse" />
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(Welcome);
