import React, { useState } from 'react';
import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CreditCardForm = ({
  hidePostalCode = false,
  onAddPaymentMethod,
  onAddPaymentMethodError
}) => {
  const stripe = useStripe();
  const elements = useElements();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Misc
  const defaultName = pathOr('', ['name'], currentUser);
  // Local State
  const [cardholderName, setCardholderName] = useState(defaultName);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      type: 'card',
      card: elements.getElement(CardElement)
    };

    if (cardholderName) {
      data.billing_details = {
        name: cardholderName
      };
    }

    if (!cardholderName) {
      toast.error('Please enter the Cardholder Name');

      return false;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod(data);

    if (error) {
      toast.error(error);

      if (onAddPaymentMethodError) onAddPaymentMethodError(error);

      return false;
    }

    if (paymentMethod && paymentMethod.id) {
      setCardholderName(''); // TODO why isn't this working?
      // TODO hide add payment method form
      onAddPaymentMethod(paymentMethod);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode,
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <div className="mb-4">
        <label htmlFor="cardholderName">Cardholder name</label>
        <input
          type="text"
          className="form-control"
          id="cardholderName"
          name="cardholderName"
          defaultValue={cardholderName}
          onChange={(e) => setCardholderName(e.target.value)}
        />
      </div>
      <button
        className="btn btn-outline-primary btn-sm"
        type="submit"
        disabled={!stripe}
      >
        Add Payment Method
      </button>
    </form>
  );
};

export default CreditCardForm;
