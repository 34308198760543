// FILES
export const SET_ORG_FILES = 'SET_ORG_FILES';
export const SET_COURSE_FILES = 'SET_COURSE_FILES';
export const SET_TOPIC_FILES = 'SET_TOPIC_FILES';
export const SET_CHALLENGE_FILES = 'SET_CHALLENGE_FILES';
export const SET_QUIZ_FILES = 'SET_QUIZ_FILES';
export const SET_ORG_LOCATION_FILES = 'SET_ORG_LOCATION_FILES';
export const SET_E_UNIT_TRACKING_FILES = 'SET_E_UNIT_TRACKING_FILES';
export const SET_CURRENT_ENTRY_FILES = 'SET_CURRENT_ENTRY_FILES';

// Current User (supabase)
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// DIRECTORY (supabase)
export const SET_DIRECTORY_USERS = 'SET_DIRECTORY_USERS';

// DIRECTORY USER (supabase)
export const SET_DIRECTORY_USER = 'SET_DIRECTORY_USER';
export const RESET_DIRECTORY_USER = 'RESET_DIRECTORY_USER';
export const EDITING_DIRECTORY_USER_PROFILE = 'EDITING_DIRECTORY_USER_PROFILE';

// DIRECTORY USER PROFILE (firebase)
export const SET_DIRECTORY_USER_PROFILE = 'SET_DIRECTORY_USER_PROFILE';
export const RESET_DIRECTORY_USER_PROFILE = 'RESET_DIRECTORY_USER_PROFILE';

// User (firebase auth)
export const SET_USER = 'SET_USER';

// Users (supabase)
export const SET_USERS = 'SET_USERS';
export const RESET_USERS = 'RESET_USERS';
export const REMOVE_USER_FROM_LIST = 'REMOVE_USER_FROM_LIST';

// QUIZ (contentful)
export const SET_QUIZ_OUTCOME = 'SET_QUIZ_OUTCOME';
export const RESET_QUIZ_OUTCOME = 'RESET_QUIZ_OUTCOME';
export const SET_QUIZ_OUTCOMES = 'SET_QUIZ_OUTCOMES';
export const RESET_QUIZ_OUTCOMES = 'RESET_QUIZ_OUTCOMES';
export const SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID = 'SET_QUIZ_OUTCOMES_ACTIVE_ORG_ID';
export const RESET_QUIZ_OUTCOMES_ACTIVE_ORG_ID = 'RESET_QUIZ_OUTCOMES_ACTIVE_ORG_ID';
export const SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID = 'SET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID';
export const RESET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID = 'RESET_QUIZ_OUTCOMES_ACTIVE_LOCATION_ID';
export const SET_CURRENT_QUIZ = 'SET_CURRENT_QUIZ';
export const RESET_CURRENT_QUIZ = 'RESET_CURRENT_QUIZ';
// QUIZ Content Flow
export const SET_CURRENT_CLASS_QUIZZES = 'SET_CURRENT_CLASS_QUIZZES';
export const SET_CURRENT_CLASS_QUIZ_OUTCOMES = 'SET_CURRENT_CLASS_QUIZ_OUTCOMES';
export const SET_CURRENT_TOPIC_QUIZ_OUTCOMES = 'SET_CURRENT_TOPIC_QUIZ_OUTCOMES';
export const RESET_CURRENT_CLASS_QUIZZES = 'RESET_CURRENT_CLASS_QUIZZES';
export const RESET_CURRENT_CLASS_QUIZ_OUTCOMES = 'RESET_CURRENT_CLASS_QUIZ_OUTCOMES';
export const RESET_CURRENT_TOPIC_QUIZ_OUTCOMES = 'RESET_CURRENT_TOPIC_QUIZ_OUTCOMES';

// PATHWAYS (firebase)
export const SET_PATHWAYS = 'SET_PATHWAYS';
export const RESET_PATHWAYS = 'RESET_PATHWAYS';
export const SET_PATHWAY = 'SET_PATHWAY';
export const RESET_PATHWAY = 'RESET_PATHWAY';

// APPRENTICESHIPS (supabase * userProfiles are in firebase)
export const SET_APPRENTICESHIPS = 'SET_APPRENTICESHIPS';
export const RESET_APPRENTICESHIPS = 'RESET_APPRENTICESHIPS';
export const SET_APPRENTICESHIP = 'SET_APPRENTICESHIP';
export const RESET_APPRENTICESHIP = 'RESET_APPRENTICESHIP';

export const SET_APPRENTICESHIP_USER = 'SET_APPRENTICESHIP_USER';
export const RESET_APPRENTICESHIP_USER = 'RESET_APPRENTICESHIP_USER';
export const SET_APPRENTICESHIP_MATERIALS = 'SET_APPRENTICESHIP_MATERIALS';
export const RESET_APPRENTICESHIP_MATERIALS = 'RESET_APPRENTICESHIP_MATERIALS';
export const SET_APPRENTICESHIP_USER_PROFILE = 'SET_APPRENTICESHIP_USER_PROFILE';
export const SET_APPRENTICESHIP_USER_WAGE_SCHEDULE = 'SET_APPRENTICESHIP_USER_WAGE_SCHEDULE';
export const SET_APPRENTICESHIP_USER_INSTRUCTION_PROVIDER = 'SET_APPRENTICESHIP_USER_INSTRUCTION_PROVIDER';

// USER COMPETENCIES (supabase)
export const SET_APPRENTICESHIP_USER_COMPETENCIES = 'SET_APPRENTICESHIP_USER_COMPETENCIES';
export const RESET_APPRENTICESHIP_USER_COMPETENCIES = 'RESET_APPRENTICESHIP_USER_COMPETENCIES';

// EMPLOYERS (LOCATIONS) (contentful)
export const SET_EMPLOYERS = 'SET_EMPLOYERS';
export const RESET_EMPLOYERS = 'RESET_EMPLOYERS';
export const SET_EMPLOYER = 'SET_EMPLOYER';
export const RESET_EMPLOYER = 'RESET_EMPLOYER';
export const SET_EMPLOYER_PROFILE = 'SET_EMPLOYER_PROFILE';
export const RESET_EMPLOYER_PROFILE = 'RESET_EMPLOYER_PROFILE';
export const SET_EMPLOYER_PROFILES = 'SET_EMPLOYER_PROFILES';
export const RESET_EMPLOYER_PROFILES = 'RESET_EMPLOYER_PROFILES';

// APPRENTICESHIPS (firebase)
export const SET_WAGE_SCHEDULES = 'SET_WAGE_SCHEDULES';
export const RESET_WAGE_SCHEDULES = 'RESET_WAGE_SCHEDULES';

// JOB PROFILES (firebase)
export const SET_JOB_PROFILES = 'SET_JOB_PROFILES';
export const RESET_JOB_PROFILES = 'RESET_JOB_PROFILES';
export const SET_JOB_PROFILE = 'SET_JOB_PROFILE';
export const RESET_JOB_PROFILE = 'RESET_JOB_PROFILE';

// PROJECTS (supabase)
export const SET_PROJECT = 'SET_PROJECT';
export const RESET_PROJECT = 'RESET_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const RESET_PROJECTS = 'RESET_PROJECTS';
// ASSISTANTS (supabase)
export const SET_ASSISTANT = 'SET_ASSISTANT';
export const RESET_ASSISTANT = 'RESET_ASSISTANT';
export const SET_ASSISTANTS = 'SET_ASSISTANTS';
export const RESET_ASSISTANTS = 'RESET_ASSISTANTS';
// ASSISTANT Files
export const SET_ASSISTANT_FILES = 'SET_ASSISTANT_FILES';
export const RESET_ASSISTANT_FILES = 'RESET_ASSISTANT_FILES';

// PROJECT Files
export const SET_PROJECT_FILES = 'SET_PROJECT_FILES';
export const RESET_PROJECT_FILES = 'RESET_PROJECT_FILES';

// PROJECTS (firebase)
export const SET_INSTRUCTION_PROVIDER = 'SET_INSTRUCTION_PROVIDER';
export const RESET_INSTRUCTION_PROVIDER = 'RESET_INSTRUCTION_PROVIDER';
export const SET_INSTRUCTION_PROVIDERS = 'SET_INSTRUCTION_PROVIDERS';
export const RESET_INSTRUCTION_PROVIDERS = 'RESET_INSTRUCTION_PROVIDERS';

// LOGBOOK (supabase)
export const SET_LOGBOOK = 'SET_LOGBOOK';
export const RESET_LOGBOOK = 'RESET_LOGBOOK';
// LOGBOOK HOURS (supabase)
export const SET_LOGBOOK_HOURS = 'SET_LOGBOOK_HOURS';
export const RESET_LOGBOOK_HOURS = 'RESET_LOGBOOK_HOURS';
// LOGBOOK RECORD (supabase)
export const SET_LOGBOOK_RECORD = 'SET_LOGBOOK_RECORD';
export const RESET_LOGBOOK_RECORD = 'RESET_LOGBOOK_RECORD';
// LOGBOOK RECORDS (supabase)
export const SET_LOGBOOK_RECORDS = 'SET_LOGBOOK_RECORDS';
export const RESET_LOGBOOK_RECORDS = 'RESET_LOGBOOK_RECORDS';
// LOGBOOKS (supabase)
export const SET_LOGBOOKS = 'SET_LOGBOOKS';
export const RESET_LOGBOOKS = 'RESET_LOGBOOKS';

// Class (contentful)
export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS';
export const RESET_CURRENT_CLASS = 'RESET_CURRENT_CLASS';

export const SET_CURRENT_CLASS_ANSWERS = 'SET_CURRENT_CLASS_ANSWERS';
export const RESET_CURRENT_CLASS_ANSWERS = 'RESET_CURRENT_CLASS_ANSWERS';

export const SET_CURRENT_TOPIC_ANSWERS = 'SET_CURRENT_TOPIC_ANSWERS';
export const RESET_CURRENT_TOPIC_ANSWERS = 'RESET_CURRENT_TOPIC_ANSWERS';

export const SET_CHALLENGES = 'SET_CHALLENGES';
export const RESET_CHALLENGES = 'RESET_CHALLENGES';

export const SET_CURRENT_CHALLENGE = 'SET_CURRENT_CHALLENGE';
export const RESET_CURRENT_CHALLENGE = 'RESET_CURRENT_CHALLENGE';

export const SET_CURRENT_TOPIC = 'SET_CURRENT_TOPIC';
export const RESET_CURRENT_TOPIC = 'RESET_CURRENT_TOPIC';
export const SET_PREREQUISITE_TOPICS = 'SET_PREREQUISITE_TOPICS';

export const SET_CURRENT_ENTRY = 'SET_CURRENT_ENTRY';
export const RESET_CURRENT_ENTRY = 'RESET_CURRENT_ENTRY';

// COHORT (contentful)
export const SET_CURRENT_COHORT = 'SET_CURRENT_COHORT';
export const RESET_CURRENT_COHORT = 'RESET_CURRENT_COHORT';
export const SET_COHORTS = 'SET_COHORTS';
export const RESET_COHORTS = 'RESET_COHORTS';

// Current Answer (contentful)
export const SET_CURRENT_ANSWER = 'SET_CURRENT_ANSWER';
export const RESET_CURRENT_ANSWER = 'RESET_CURRENT_ANSWER';
export const SET_CURRENT_ANSWERS = 'SET_CURRENT_ANSWERS';
export const RESET_CURRENT_ANSWERS = 'RESET_CURRENT_ANSWERS';

// AUTH
export const SET_ENTRY_ORG_ID = 'SET_ENTRY_ORG_ID';
export const SET_AUTH_ACTION = 'SET_AUTH_ACTION';
export const RESET_AUTH_ACTION = 'RESET_AUTH_ACTION';
export const SET_AUTH_ALERT = 'SET_AUTH_ALERT';
export const RESET_AUTH_ALERT = 'RESET_AUTH_ALERT';

// App
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LOADING = 'SET_LOADING';
export const LOGOUT = 'LOGOUT';

// Billing Agreement
export const SET_BILLING_AGREEMENT = 'SET_BILLING_AGREEMENT';
export const RESET_BILLING_AGREEMENT = 'RESET_BILLING_AGREEMENT';

// MODALS
export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';
export const RESET_CURRENT_MODAL = 'RESET_CURRENT_MODAL';

// SIDE SHEETS
export const SET_SIDE_SHEET = 'SET_SIDE_SHEET';
export const UPDATE_SIDE_SHEET = 'UPDATE_SIDE_SHEET';
export const RESET_SIDE_SHEET = 'RESET_SIDE_SHEET';

// BILLING
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRICE = 'SET_PRICE';
export const SET_INVOICES = 'SET_INVOICES';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

// RESOURCE : SEARCH
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const SET_SEARCH_GROUP_IDS = 'SET_SEARCH_GROUP_IDS';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_SEARCH_FETCHING = 'SET_SEARCH_FETCHING';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_SEARCH_USER_FEEDBACK = 'SET_SEARCH_USER_FEEDBACK';

// SEARCHES
export const SET_SEARCHES = 'SET_SEARCHES';
export const RESET_SEARCHES = 'RESET_SEARCHES';
export const SET_SEARCHES_USERS_HASH = 'SET_SEARCHES_USERS_HASH';

// COURSES (contentful)
export const SET_COURSES = 'SET_COURSES';
export const RESET_COURSES = 'RESET_COURSES';
export const SET_SYNDICATED_COURSES = 'SET_SYNDICATED_COURSES';
export const RESET_SYNDICATED_COURSES = 'RESET_SYNDICATED_COURSES';
export const SET_SYNDICATED_COURSES_SHARING = 'SET_SYNDICATED_COURSES_SHARING';
export const RESET_SYNDICATED_COURSES_SHARING = 'RESET_SYNDICATED_COURSES_SHARING';

// COURSE CERT
export const SET_CLASS_CERTIFICATE = 'SET_CLASS_CERTIFICATE';
export const RESET_CLASS_CERTIFICATE = 'RESET_CLASS_CERTIFICATE';

// GROUP (contentful)
export const CREATE_COURSE_GROUP = 'CREATE_COURSE_GROUP';
export const CREATE_USER_GROUP = 'CREATE_USER_GROUP';

export const SET_COURSE_GROUP = 'SET_COURSE_GROUP';
export const RESET_COURSE_GROUP = 'RESET_COURSE_GROUP';

// RESOURCE GROUP (supabase)
export const CREATE_RESOURCE_GROUP = 'CREATE_RESOURCE_GROUP';
export const SET_RESOURCE_GROUP = 'SET_RESOURCE_GROUP';
export const RESET_RESOURCE_GROUP = 'RESET_RESOURCE_GROUP';
// RESOURCE GROUP: FILES
export const SET_RESOURCE_GROUP_FILES = 'SET_RESOURCE_GROUP_FILES';
// RESOURCE GROUP: TAGS
export const SET_RESOURCE_GROUP_TAGS = 'SET_RESOURCE_GROUP_TAGS';
export const RESET_RESOURCE_GROUP_TAGS = 'RESET_RESOURCE_GROUP_TAGS';
// RESOURCE GROUPS
export const SET_RESOURCE_GROUPS = 'SET_RESOURCE_GROUPS';
export const RESET_RESOURCE_GROUPS = 'RESET_RESOURCE_GROUPS';

// GROUPS
export const SET_GROUPS = 'SET_GROUPS';

// COURSE GROUPS (contentful)
export const SET_COURSE_GROUPS = 'SET_COURSE_GROUPS';
export const RESET_COURSE_GROUPS = 'RESET_COURSE_GROUPS';

export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const RESET_USER_GROUPS = 'RESET_USER_GROUPS';

// ORGANIZATION (contentful)
export const SET_ORG = 'SET_ORG';
export const SET_SYNDICATED_COURSE_ORGS = 'SET_SYNDICATED_COURSE_ORGS';
export const RESET_SYNDICATED_COURSE_ORGS = 'RESET_SYNDICATED_COURSE_ORGS';

export const SET_ORG_USER_GROUP_IDS = 'SET_ORG_USER_GROUP_IDS';
export const RESET_ORG_USER_GROUP_IDS = 'RESET_ORG_USER_GROUP_IDS';

export const SET_ORG_COURSE_GROUP_IDS = 'SET_ORG_COURSE_GROUP_IDS';
export const RESET_ORG_COURSE_GROUPS = 'RESET_ORG_COURSE_GROUPS';

export const SET_ORG_SKILLS = 'SET_ORG_SKILLS';
export const RESET_ORG_SKILLS = 'RESET_ORG_SKILLS';

export const SET_CRON_USERS = 'SET_CRON_USERS';
export const RESET_CRON_USERS = 'RESET_CRON_USERS';

export const RESET_ORG = 'RESET_ORG';

// LOCATIONS (contentful)
export const SET_LOCATION = 'SET_LOCATION';
export const RESET_LOCATION = 'RESET_LOCATION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';

/**
 * MATERIALS
 *
 * contentful: course topic materials
 * supabase: user materials (VELA)
 *
 */
export const SET_MATERIAL = 'SET_MATERIAL';
export const RESET_MATERIAL = 'RESET_MATERIAL';

// MATERIALS (supabase)
export const SET_MATERIALS = 'SET_MATERIALS';
export const RESET_MATERIALS = 'RESET_MATERIALS';

// CONTENT
export const SET_E_UNIT_TRACKING = 'SET_E_UNIT_TRACKING';
export const RESET_E_UNIT_TRACKING = 'RESET_E_UNIT_TRACKING';

// WEATHER
export const SET_USER_WEATHER_CURRENT = 'SET_USER_WEATHER_CURRENT';
export const RESET_USER_WEATHER_CURRENT = 'RESET_USER_WEATHER_CURRENT';

export const SET_LOCATIONS_WEATHER_CURRENT = 'SET_LOCATIONS_WEATHER_CURRENT';
export const RESET_LOCATIONS_WEATHER_CURRENT = 'RESET_LOCATIONS_WEATHER_CURRENT';

// Invites (firebase)
export const SET_INVITES = 'SET_INVITES';
export const RESET_INVITES = 'RESET_INVITES';

// Employer Agreements (firebase)
export const SET_EMPLOYER_AGREEMENT = 'SET_EMPLOYER_AGREEMENT';
export const RESET_EMPLOYER_AGREEMENT = 'RESET_EMPLOYER_AGREEMENT';
export const SET_EMPLOYER_AGREEMENTS = 'SET_EMPLOYER_AGREEMENTS';
export const RESET_EMPLOYER_AGREEMENTS = 'RESET_EMPLOYER_AGREEMENTS';

// Apprentice Agreements (firebase)
export const SET_APPRENTICE_AGREEMENT = 'SET_APPRENTICE_AGREEMENT';
export const RESET_APPRENTICE_AGREEMENT = 'RESET_APPRENTICE_AGREEMENT';
export const SET_APPRENTICE_AGREEMENTS = 'SET_APPRENTICE_AGREEMENTS';
export const RESET_APPRENTICE_AGREEMENTS = 'RESET_APPRENTICE_AGREEMENTS';

// WIDGETS (firebase)
export const SET_WIDGET_LINKS = 'SET_WIDGET_LINKS';
export const RESET_WIDGET_LINKS = 'RESET_WIDGET_LINKS';

export const SET_WIDGET_EMBEDS = 'SET_WIDGET_EMBEDS';
export const RESET_WIDGET_EMBEDS = 'RESET_WIDGET_EMBEDS';

// Material Files (aws)
export const SET_MATERIAL_FILES = 'SET_MATERIAL_FILES';
export const RESET_MATERIAL_FILES = 'RESET_MATERIAL_FILES';

// Notification (supabase)
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
// Notification Files (aws)
export const SET_NOTIFICATION_FILES = 'SET_NOTIFICATION_FILES';
export const RESET_NOTIFICATION_FILES = 'RESET_NOTIFICATION_FILES';
// Notifications Organization (supabase)
export const SET_NOTIFICATIONS_ORG = 'SET_NOTIFICATIONS_ORG';
export const SET_NOTIFICATIONS_ORG_PINNED = 'SET_NOTIFICATIONS_ORG_PINNED';
export const RESET_NOTIFICATIONS_ORG = 'RESET_NOTIFICATIONS_ORG';

// Notifications Course (supabase)
export const SET_NOTIFICATIONS_COURSE = 'SET_NOTIFICATIONS_COURSE';
export const SET_NOTIFICATIONS_COURSE_PINNED = 'SET_NOTIFICATIONS_COURSE_PINNED';
export const RESET_NOTIFICATIONS_COURSE = 'RESET_NOTIFICATIONS_COURSE';
