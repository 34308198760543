import React, { useEffect, useState } from 'react';
import { fetchOrg } from '../services/organizations';

const OrgAuthor = ({
  orgId,
  orgName = null,
  orgLogo = null,
  fetchData = true,
  small = false,
  className = ''
}) => {
  const [name, setName] = useState(orgName);
  const [logo, setLogo] = useState(orgLogo);

  useEffect(() => {
    let mounted = true;

    if (orgId && fetchData && mounted) {
      fetchOrg({
        orgId,
        select: ['fields.name', 'fields.orgLogo']
      }).then((org) => {
        if (mounted) {
          setLogo(org?.orgLogo);
          setName(org?.name);
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  }, [orgId]);

  if (!name) {
    return null;
  }

  return (
    <div className={className}>
      <p
        className="text-muted m-0 d-flex"
        style={{ fontSize: '.9rem' }}
      >
        <img
          src={logo}
          height="20"
          style={{ height: '20px' }}
          alt={name}
        />
        <span
          className={`font-weight-bold ml-2 ${small ? 'small' : ''}`}
          style={{ lineHeight: '1.25rem' }}
        >
          {name}
        </span>
      </p>
    </div>
  );
};

export default OrgAuthor;
