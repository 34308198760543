import React from 'react';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';

const UserGroupsBlock = () => {
  // Local State
  const organization = useSelector((state) => state.organization);
  const userGroups = useSelector((state) => state.userGroups);
  const directoryUser = useSelector((state) => state.directoryUser);
  // Organization
  const orgType = organization?.type || '';
  // Directory User
  const groupIds = directoryUser?.groupIds || null;
  // TODO create groupsHash like locationsHash
  const orgUserGroups = Array.isArray(userGroups.list) && Array.isArray(groupIds) && userGroups.list.filter(((g) => groupIds.includes(g.id) === true));

  if (!orgUserGroups) {
    return null;
  }

  return (
    <div className="d-flex justify-content-center text-center">
      <div className="pb-4">
        <div className="h6 font-weight-bold mb-3">
          {pluralize(`${orgType === 'workforce' ? 'Department' : 'Group'}`, userGroups.length)}
        </div>

        <div className="contact-row">
          {orgUserGroups && orgUserGroups.map((g) => (
            <div key={`userGroups-group-${g.id}`} data-demo="disabled">
              {g.title}
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default UserGroupsBlock;
