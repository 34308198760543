import { DEFAULT_PAGINATION_LIMIT } from '../constants/api';
import { fetchIntegration, fetchIntegrations } from '../services/integrations';
import { getResourcesByParentId } from './Resources';
import {
  SET_ASSISTANTS,
  RESET_ASSISTANTS,
  RESET_ASSISTANT,
  SET_ASSISTANT,
  SET_ENTRY_ORG_ID,
  SET_ASSISTANT_FILES
} from './types';

export const getAssistants = ({
  orgId,
  locationId,
  archived = null,
  limit = DEFAULT_PAGINATION_LIMIT,
  page = 1,
  order = 'name',
  name = ''
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchIntegrations({
        locationId,
        orgId,
        archived,
        page,
        limit,
        order,
        name
      })
        .then((response) => {
          const list = response?.items || [];
          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: list && list[0] ? list[0]?.orgId : null
          });

          dispatch({
            type: SET_ASSISTANTS,
            list,
            pagination: {
              limit,
              total: response?.total,
              page: response?.page || 1,
              rangeFrom: response?.rangeFrom,
              rangeTo: response?.rangeTo
            }
          });

          resolve(response?.items);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const getAssistant = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchIntegration(id)
        .then((assistant) => {
          if (!assistant?.id) {
            reject(new Error('No assistant found (id)'));
            return;
          }

          // ORG CHECK
          dispatch({
            type: SET_ENTRY_ORG_ID,
            entryOrgId: assistant?.orgId || null
          });

          dispatch({
            type: SET_ASSISTANT,
            assistant: assistant?.id ? { ...assistant, cdnFiles: null } : null
          });
          dispatch(getResourcesByParentId(assistant?.id, 'assistants'));
          resolve(assistant);
        })
        .catch((error) => {
          console.error('get assistant', error);
          reject(error);
        });
    });
  };
};

export const setAssistantFiles = (cdnFiles = []) => {
  return (dispatch, getState) => {
    const { assistant } = getState();
    return new Promise((resolve) => {
      const newCdnFiles = [
        ...(assistant?.cdnFiles ? assistant.cdnFiles : []),
        ...cdnFiles
      ];

      // const sortedCdnFiles = newCdnFiles.sort((a, b) => sortCaseInsensitive(a, b, 'name'));

      dispatch({
        type: SET_ASSISTANT_FILES,
        cdnFiles: newCdnFiles
      });
      resolve(newCdnFiles);
    });
  };
};

export const resetAssistant = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ASSISTANT });
  };
};

export const resetAssistants = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ASSISTANTS });
  };
};
