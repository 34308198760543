import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { FaPlus, FaTh } from 'react-icons/fa';
import { useParams, useHistory } from 'react-router-dom';
import { withAuthorization } from '../../components/Session';
import {
  ORG_CONSOLE_BASE,
  ORG_CONSOLE_DASHBOARD
} from '../../constants/routes';
import { canManageWidgets } from '../../services/currentUser';
import { setCurrentModal } from '../../actions/Modals';
import withOrgConsole from '../../components/App/withOrgConsole';
import OrgConsoleHeader from '../../components/OrgConsole/OrgConsoleHeader';
import WidgetsList from '../../components/lists/WidgetsList';
import WidgetTabs from './WidgetTabs';
import {
  MODAL_KEY_MANAGE_WIDGET,
  MODAL_KEY_WIDGET_TYPE
} from '../../constants/modals';
import EmbedWidgetList from '../../components/lists/WidgetsList/EmbedWidgetList';
import './style.css';

const Widgets = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { widgetType } = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  // Current User
  const role = currentUser?.role || [];
  // Local State
  const [widgetTab, setWidgetTab] = useState(widgetType || 'link');
  const [isFeatured, setIsFeatured] = useState(false);

  const handleSetWidgetTab = (type) => {
    setWidgetTab(type);
    history.replace(`${ORG_CONSOLE_BASE}/widgets/${type}`);
  };

  const handleClick = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_WIDGET_TYPE
      })
    );
  };

  const onClickSettings = (app) => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_MANAGE_WIDGET,
        data: {
          app,
          editMode: true,
          filterAll: !isFeatured
        }
      })
    );
  };

  const handleToggleIsFeatured = () => {
    setIsFeatured(!isFeatured);
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <OrgConsoleHeader
          pageTitle="Toolbox"
          iconComponent={() => (
            <FaTh
              size={20}
              className="mr-2"
            />
          )}
          route={ORG_CONSOLE_DASHBOARD}
        >
          {canManageWidgets(role) && (
            <button
              className="btn btn-sm btn-primary"
              title="Post Notification"
              onClick={handleClick}
              type="button"
            >
              <span className="d-flex align-items-center">
                <FaPlus />
                <span className="ml-2 font-weight-bold">New</span>
              </span>
            </button>
          )}
        </OrgConsoleHeader>
        <div className="my-3">
          <WidgetTabs
            widgetTab={widgetTab}
            setWidgetTab={handleSetWidgetTab}
            showIsFeaturedSwitch={widgetTab === 'link'}
            isFeatured={isFeatured}
            onChangeIsFeatured={handleToggleIsFeatured}
          />
          <div
            className="bg-white border-right border-left border-bottom shadow p-3"
            style={{
              minHeight: '400px'
            }}
          >
            {widgetTab === 'link' && (
              <WidgetsList
                className="mt-3"
                showReorderButton
                showNoDataImage
                showTableView={!isFeatured}
                isFeatured={isFeatured ? true : undefined}
                {...(canManageWidgets(role) ? { onClickSettings } : {})}
              />
            )}

            {widgetTab === 'embed' && (
              <EmbedWidgetList
                className="mt-3"
                isFeatured={isFeatured ? true : undefined}
                showSetting={canManageWidgets(role)}
                showTitles
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(withAuthorization(condition), withOrgConsole)(Widgets);
