import { searchSupabaseEntries, fetchSupabaseEntry } from './supabaseProxy';

const collection = 'apprenticeshipTemplates';

/**
 * @param {String} orgId
 * @param {String} employerId
 */
export const findApprenticeshipTemplates = ({ title = '', limit = 6 }) => {
  return new Promise((resolve, reject) => {
    searchSupabaseEntries({
      table: collection,
      column: 'title',
      lookup: title,
      limit
    })
      .then((response) => {
        const list = response?.data?.length ? response?.data : null;

        resolve(list);
      })
      .catch((error) => {
        console.error(`find ${collection}`, error);
        reject(error);
      });
  });
};

/**
 *
 * @param {string} id
 */
export const fetchApprenticeshipTemplate = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table: collection, id })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error('fetch apprenticeshipTemplate', error);
        reject(error);
      });
  });
};
