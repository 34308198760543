import React from 'react';
import { answerEnum } from '../../constants/answers';

const StatusFeedbackBadge = ({ status, className = '' }) => {
  if (status === 'requestChanges') {
    return (
      <span className={className}>
        <span className="badge badge-warning">
          {answerEnum[status]}
        </span>
      </span>
    );
  }

  if (status === 'accepted') {
    return (
      <span className={className}>
        <span className="badge badge-success">
          {answerEnum[status]}
        </span>
      </span>
    );
  }

  if (status === 'rejected') {
    return (
      <span className={className}>
        <span className="badge badge-danger">
          {answerEnum[status]}
        </span>
      </span>
    );
  }

  return (
    <span className={className}>
      <span className="badge badge-info">
        {answerEnum.inReview}
      </span>
    </span>
  );
};

export default StatusFeedbackBadge;
