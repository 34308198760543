export const BARA_RED = '#ED4C67';
export const MERCHANT_MARINE_BLUE = '#0652DD';
export const SUNFLOWER_YELLOW = '#FFC312';
export const TURKISH_AQUA_GREEN = '#006266';
export const CIRCUMORBITAL_RING_PURPLE = '#5758BB';
export const PUFFINS_BILL_ORANGE = '#EE5A24';
//
export const HOLLY_COCK_MAGENTA = '#833471';
export const MEDITERRANEAN_SEA_BLUE = '#1289A7';
export const RADIANT_YELLOW = '#F79F1F';
export const PIXELATED_GRASS_GREEN = '#009432';
export const FORGOTTEN_PURPLE = '#9980FA';
export const QUINCE_JELLY = '#f0932b';
//
export const CARMINE_PINK = '#eb4d4b';
export const DEEP_KAOMARU_BLUE = '#30336b';
export const TURBO_YELLOW = '#f9ca24';
export const PURE_APPLE_GREEN = '#6ab04c';
export const LIVID_PURPLE = '#6a89cc';
export const ICELAND_POPPY_ORANGE = '#fa983a';
// Turbine
export const LIGHT_SEA_GREEN = '#17a2b8';
export const LIGHT_GRAY = '#d3d3d3';
