import React from 'react';
import { ICON_TRIANGLE_BANG } from '@apprentage/constants';
import Alert from '../../Alert';

const DeleteUserSoft = ({
  children
}) => {
  return (
    <div>
      <div className="mb-0 text-right">
        <button
          className="btn-link text-dark p-0"
          type="button"
          data-toggle="collapse"
          data-target="#collapseDeleteUserSoft"
          aria-expanded="false"
          aria-controls="collapseDeleteUserSoft"
        >
          <i className="fas fa-caret-right" />
          <i className="fas fa-caret-down" />
          Danger Zone
        </button>
      </div>

      <div id="collapseDeleteUserSoft" className="collapse">
        <div className="mt-2">
          <Alert
            type=""
            icon={`${ICON_TRIANGLE_BANG} text-danger`}
            className="m-0 w-100 border"
          >
            <div className="form-group mb-3">
              <h6 className='text-danger font-weight-bold'>
                Danger Zone
              </h6>
              <hr />
              {children || null}
            </div>
          </Alert>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserSoft;
