import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { isLocalhost } from '../../../serviceWorker';
import { TURBINE_STRIPE_LIVE, TURBINE_STRIPE_TEST } from '../../../constants/globals';
import { getPaymentMethods, addPaymentMethod } from '../../../actions/Billing';
import CreditCardForm from './CreditCardForm';
import { getStripeCustomerId } from '../../../services/organizations';
import './style.css';

const stripePromise = loadStripe(isLocalhost ? TURBINE_STRIPE_TEST : TURBINE_STRIPE_LIVE);

class PaymentMethods extends Component {
  state = {
    selectedPaymentMethod: null,
    showCreditCardForm: true
  }

  componentDidMount() {
    this.props.getPaymentMethods().catch((error) => console.warn(error.message));
  }

  handleSelectPaymentMethod = (paymentMethod) => {
    this.setState({
      selectedPaymentMethod: paymentMethod
    });

    this.props.onPaymentMethodChange(paymentMethod);
  }

  handleAddPaymentMethod = (paymentMethod) => {
    const { organization } = this.props;

    if (paymentMethod && paymentMethod.id) {
      const customer = getStripeCustomerId(organization);

      // Hide CC Form
      this.setState({ showCreditCardForm: false });

      console.log(paymentMethod);

      this.props.addPaymentMethod(paymentMethod).then(() => {
        this.handleSelectPaymentMethod(paymentMethod);
        this.setState({ showCreditCardForm: true }); // Show CC Form
      }).catch((error) => console.warn(error.message));

      if (!customer) {
        this.props.onCreateCustomer(paymentMethod);
      } else {
        // TODO needs to be built
        this.props.onAttachPaymentMethod({ paymentMethod, customer });
      }
    }
  }

  render() {
    const { billing, hidePostalCode } = this.props;
    const { selectedPaymentMethod, showCreditCardForm } = this.state;
    const paymentMethods = pathOr(null, ['paymentMethods'], billing);

    return (
      <div className="payment-methods-container mt-4">
        <h6>
          Payment Methods
        </h6>
        <div className="card overflow-hidden">
          <div className="card-content">
            {paymentMethods ? (
              <Fragment>
                {paymentMethods.map((paymentMethod, i) => {
                  const { card } = paymentMethod;

                  return (
                    <div
                      key={i}
                      className="payment-method-item p-3 border-bottom d-flex align-items-center"
                      onClick={() => {
                        this.handleSelectPaymentMethod(paymentMethod);
                      }}
                    >
                      <div className="mr-2">
                        <i className={selectedPaymentMethod && selectedPaymentMethod.id === paymentMethod.id ? 'fas fa-circle text-primary' : 'far fa-circle'} />
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="cc-icon mr-2">
                          <i className={`fab fa-cc-${card.brand}`} />
                        </div>
                        <div>
                          <div
                            className="text-capitalize font-weight-bold"
                          >
                            {card.brand} •••• {card.last4} {selectedPaymentMethod && selectedPaymentMethod.id === paymentMethod.id && (<span className="h6 m-0"><span className="badge badge-primary">Selected</span></span>)}
                          </div>
                          <div
                            className="text-muted"
                          >
                            Expires {card.exp_month}/{card.exp_year}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <div className="p-3 border-bottom d-flex align-items-center">
                <div className="cc-icon mr-2">
                  <i className="fas fa-credit-card opacity-50" />
                </div>
                <div>
                  <div className="font-weight-bold">
                    No payment methods.
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer">
            <div>
              Add new Payment Method
            </div>
            <Elements stripe={stripePromise}>
              {showCreditCardForm && (
                <CreditCardForm
                  onAddPaymentMethod={this.handleAddPaymentMethod}
                  hidePostalCode={hidePostalCode}
                />
              )}
            </Elements>
          </div>
        </div>
        <div className="mt-2">
          <i className="fas fa-shield-alt" /> All data transmissions encrypted by <a href="https://letsencrypt.org" target="_blank" rel="noopener noreferrer" title="Learn more about Let's Encrypt">Let's Encrypt</a> and are <a href="https://stripe.com/guides/pci-compliance#overview-of-pci-data-security-standard-pci-dss" target="_blank" rel="noopener noreferrer" title="Learn more about PCI Data Security Standard">PCI compliant</a>.
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  organization, billing
}) => ({
  organization, billing
});

export default compose(
  connect(mapStateToProps, { getPaymentMethods, addPaymentMethod })
)(PaymentMethods);
