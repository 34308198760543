import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { FaDownload, FaListOl, FaUser } from 'react-icons/fa';
import { withAuthorization } from '../../Session';
import { routeWithClassId } from '../../../services/courses';
import {
  getQuiz, getQuizOutcome, getQuizOutcomes, resetQuizOutcome
} from '../../../actions/Quizzes';
import { CLASS_CONSOLE_QUIZZES } from '../../../constants/routes';
import { canExportUserData } from '../../../services/currentUser';
import exportData from './exportData';
import withConsole from '../../App/withConsole';
import OrgConsoleHeader from '../../OrgConsole/OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsole/OrgConsoleSubheader';
import QuizOutcome from '../../QuizOutcome';
import Outcomes from './Outcomes';
import Loading from '../../Loading';

const QuizOutcomes = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const course = useSelector((state) => state.course);
  const syndicatedCourseOrgs = useSelector((state) => state.syndicatedCourseOrgs);
  const quizOutcome = useSelector((state) => state.quizOutcome);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  const quizOutcomes = useSelector((state) => state.quizOutcomes);
  // Misc
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const role = currentUser?.role || [];
  const orgName = organization?.name || '';
  const orgType = organization?.type || '';
  const classId = course?.id || '';
  const quizId = params?.quizId || '';
  const quizOutcomeId = params?.quizOutcomeId || '';

  const userName = useMemo(() => {
    return quizOutcome?.userName || 'User Outcome';
  }, [quizOutcome]);

  useEffect(() => {
    if (quizId) {
      dispatch(getQuiz({ quizId }));
    }

    if (quizOutcomeId) {
      dispatch(getQuizOutcome(quizOutcomeId)).then((response) => {
        const userId = response?.userId || '';

        if (quizId) {
          dispatch(getQuiz({ quizId }));
        }

        if (quizId && userId && classId) {
          dispatch(getQuizOutcomes({ quizId, userId, classId }));
        }
      });
    }

    return function cleanup() {
      dispatch(resetQuizOutcome());
    };
  }, []);

  if (quizId && !currentQuiz.id) {
    return (
      <Loading text="Loading Quiz..." />
    );
  }

  if (quizOutcomeId && !quizOutcome.id) {
    return (
      <Loading text="Loading Outcome..." />
    );
  }

  return (
    <>
      <OrgConsoleHeader
        pageTitle={currentQuiz?.title}
        iconComponent={() => <FaListOl size={20} />}
        className="my-3"
        rawRoute={routeWithClassId({
          route: CLASS_CONSOLE_QUIZZES,
          classId
        })}
      />

      <div
        className="bg-white border rounded shadow p-3"
      >
        <OrgConsoleSubheader
          pageTitle={userName}
          iconComponent={() => <FaUser className='mr-2' />}
          rawRoute={routeWithClassId({
            route: redirectUrl,
            classId
          })}
        >
          <button
            className="btn btn-sm btn-outline-primary text-nowrap"
            onClick={() => {
              exportData({
                orgName,
                syndicatedCourseOrgs,
                course,
                currentQuiz,
                quizOutcome
              });
            }}
            title="Export Data"
            type="button"
            disabled={!canExportUserData(role, orgType)}
          >
            <span className='d-flex align-items-center'>
              <FaDownload />
              <span className='ml-2'>
                Export
              </span>
            </span>
          </button>
        </OrgConsoleSubheader>

        <Outcomes
          className='mt-3'
          quizOutcomes={quizOutcomes}
        />

        <QuizOutcome />

      </div>
    </>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withConsole
)(QuizOutcomes);
