import React from 'react';

const ActiveQuiz = ({ value: activeQuiz }) => {
  return (
    <div>
      <i className={`fas fa-circle mr-1 ${!activeQuiz ? 'text-danger' : 'text-success'}`} />
      <span>
        {!activeQuiz ? 'Inactive' : 'Active'}
      </span>
    </div>
  );
};

export default ActiveQuiz;
