import sysState from './sysState';

const instructionProviderState = {
  id: '',
  name: '',
  url: '',
  externalId: '',
  phone: '',
  // Address
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  addressPostalCode: '',
  addressCountry: '',
  addressLat: '',
  addressLng: '',
  // Point of Contact
  contactFirstName: '',
  contactLastName: '',
  contactTitle: '',
  contactEmail: '',
  contactPhone: '',
  contactPhoneExt: '',
  cdnFiles: null,
  ...sysState
};

export default instructionProviderState;
