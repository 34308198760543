import sysState from './sysState';

const eUnitTrackingState = {
  title: '',
  code: '',
  semesterCode: '',
  sponsorCodes: null,
  currentSession: null, // number
  sessions: null, // number
  sessionsTopicSync: true,
  trackingInterval: null, // number
  attendancePerSession: null, // number
  integration: null, // {}
  info: '', // TODO change to "body"
  certificate: false,
  enableNewEditor: false,
  ...sysState
};

export default eUnitTrackingState;
