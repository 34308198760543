import React from 'react';
import { FaCircle } from 'react-icons/fa';
import { statusFeedback } from '../../services/answers';

const AnswerTabs = ({
  className = '',
  activeTab,
  status,
  instructorNotes,
  setActiveTab,
  cardStyle = false
}) => {
  const statusInfo = statusFeedback(status);

  return (
    <div
      className={`border-bottom ${cardStyle && 'bg-light'} ${className}`}
    >
      <ul
        className={`nav nav-tabs border-0 ${cardStyle && 'pl-3'}`}
        style={{ zIndex: 1 }}
      >
        <li className="nav-item mr-2">
          <button
            className={`nav-link ${activeTab === 'submission' && 'active'}`}
            type='button'
            disabled={activeTab === 'submission'}
            onClick={() => {
              setActiveTab('submission');
            }}
          >
            Submission
          </button>
        </li>

        <li className="nav-item mr-2">
          <button
            className={`nav-link ${activeTab === 'review' && 'active'}`}
            type='button'
            disabled={activeTab === 'review'}
            onClick={() => {
              setActiveTab('review');
            }}
          >
            <span className='d-flex align-items-center'>
              <FaCircle
                className={`text-${statusInfo?.alertType}`}
              />
              <span className='ml-2'>
                Review
              </span>
            </span>

          </button>
        </li>

        {instructorNotes && (
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === 'instructorNotes' && 'active'}`}
              type='button'
              disabled={activeTab === 'instructorNotes'}
              onClick={() => {
                setActiveTab('instructorNotes');
              }}
            >
              <span className='d-flex align-items-center'>
                <span className='d-none d-sm-block mr-1'>
                  Instructor
                </span>
                <span>
                  Notes
                </span>
              </span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default AnswerTabs;
