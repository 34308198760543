import React from 'react';
import CompetencyBreakdown from '../../../CompetenyBreakdown';

const CompetenciesCell = ({ row }) => {
  return (
    <CompetencyBreakdown
      competencies={row?.original?.competencies}
      competencyGroups={row?.original?.competencyGroups}
      ojtHours={row?.original?.ojtHours || 0}
      tooltipContainerId="ApprenticeshipsList"
    />
  );
};

export default CompetenciesCell;
