import { pathOr } from 'ramda';
import { client, flattenItems } from '../services/contentful';
import { isLocalhost } from '../serviceWorker';
import * as turbineStripe from '../services/stripe';
import { getStripeCustomerId } from '../services/organizations';
import { updateIntegrationStripeData } from './Organizations';
import {
  SET_PAYMENTS,
  SET_PAYMENT_METHODS,
  SET_SUBSCRIPTIONS,
  SET_PRODUCTS,
  SET_PRICE,
  SET_INVOICES,
  SET_CUSTOMER,
  SET_SUBSCRIPTION
} from './types';

export const getPayments = () => {
  return (dispatch, getState) => {
    const { organization } = getState();
    const { id: orgId } = organization;

    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'payment',
        'fields.organization.sys.id': orgId // TODO payments flatten (orgId)
      };

      return client.getEntries(config).then(({ items }) => {
        const payments = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_PAYMENTS,
          payments
        });
        resolve({ payments });
      }).catch((error) => {
        console.warn(error.message);
        reject(error);
      });
    });
  };
};

export const getCustomer = () => {
  return (dispatch, getState) => {
    const { organization } = getState();
    const customerId = getStripeCustomerId(organization);

    return new Promise((resolve, reject) => {
      if (!customerId) {
        reject(new Error('getCustomer: No customerId'));
        return false;
      }

      turbineStripe.getCustomers(customerId).then((customer) => {
        dispatch({
          type: SET_CUSTOMER,
          customer
        });
        resolve({ customer });
      }).catch((error) => {
        console.warn(error.message);
        reject(error);
      });
    });
  };
};

export const createCustomer = ({
  name,
  email,
  address,
  paymentMethod
}) => {
  return (dispatch) => {
    const errorCreateCustomer = new Error('Something went wrong, please refresh and try again.');

    return new Promise((resolve, reject) => {
      if (!name || !email || !address || !paymentMethod) {
        console.warn('createCustomer: missing params');
        reject(errorCreateCustomer);

        return false;
      }

      const sanitizedAddress = {
        ...address
      };

      // If user entered a Line 2 Street Address and then removed it
      // this will prevent sending the line2 key to the api
      if (sanitizedAddress.line2 && sanitizedAddress.line2.trim() === '') {
        delete sanitizedAddress.line2;
      }

      turbineStripe.createCustomer({
        name,
        email,
        address: sanitizedAddress,
        paymentMethod
      }).then((customer) => {
        dispatch({
          type: SET_CUSTOMER,
          customer
        });

        // Add integration.stripe.customer_id to Organization
        dispatch(updateIntegrationStripeData(customer.id));

        resolve({ customer });
      }).catch((error) => {
        // TODO user needs to go to BILLING ROUTE to start again
        console.warn(`createCustomer: ${error.message}`);
        reject(errorCreateCustomer);
      });
    });
  };
};

export const getPaymentMethods = () => {
  return (dispatch, getState) => {
    const { billing } = getState();
    const customer = pathOr(null, ['customer'], billing);

    return new Promise((resolve, reject) => {
      if (!customer || !customer.id) {
        reject(new Error('getPaymentMethods: No customer.'));
        return false;
      }

      turbineStripe.getPaymentMethods(customer.id).then((response) => {
        const paymentMethods = response && response.data && response.data.length !== 0 ? response.data : null;

        dispatch({
          type: SET_PAYMENT_METHODS,
          paymentMethods
        });

        resolve({ paymentMethods });
      }).catch((error) => {
        console.warn(error.message);
        reject(error);
      });
    });
  };
};

export const addPaymentMethod = (paymentMethod) => {
  return (dispatch, getState) => {
    const { billing } = getState();
    const paymentMethods = pathOr(null, ['paymentMethods'], billing);

    let newPaymentMethods = [];

    if (paymentMethods && paymentMethods.length !== 0) {
      newPaymentMethods = [
        ...paymentMethods
      ];
    }

    if (paymentMethod) newPaymentMethods.push(paymentMethod);

    return new Promise((resolve, reject) => {
      if (!paymentMethod) {
        reject(new Error('No payment method.'));

        return false;
      }

      dispatch({
        type: SET_PAYMENT_METHODS,
        paymentMethods: newPaymentMethods
      });

      resolve({ paymentMethods: newPaymentMethods });
    });
  };
};

export const createSubscription = ({ price, quantity, coupon }) => {
  return (dispatch, getState) => {
    const errorCreateSubscription = new Error('Something went wrong, please refresh and try again.');
    const { billing } = getState();
    const customer = pathOr(null, ['customer'], billing);

    return new Promise((resolve, reject) => {
      if (!customer || !customer.id || !price || !quantity) {
        console.warn('createSubscription: missing params');
        reject(errorCreateSubscription);
        return false;
      }

      const data = {
        customer: customer.id,
        price,
        quantity
      };

      if (coupon) {
        data.coupon = coupon;
      }

      turbineStripe.createSubscription(data).then((subscription) => {
        dispatch({
          type: SET_SUBSCRIPTION,
          subscription
        });

        resolve({ subscription });
      }).catch((error) => {
        // TODO user needs to go to BILLING ROUTE to start again
        console.warn(`createSubscription: ${error.message}`);
        reject(errorCreateSubscription);
      });
    });
  };
};

export const getSubscriptions = () => {
  return (dispatch, getState) => {
    const { billing } = getState();
    const customer = pathOr(null, ['customer'], billing);

    return new Promise((resolve, reject) => {
      if (!customer || !customer.id) {
        reject(new Error('getSubscriptions: No customer.'));
        return false;
      }

      turbineStripe.getSubscriptions(customer.id).then((response) => {
        const subscriptions = response && response.data && response.data.length !== 0 ? response.data : null;

        dispatch({
          type: SET_SUBSCRIPTIONS,
          subscriptions
        });

        resolve({ subscriptions });
      }).catch((error) => {
        console.warn(error.message);
        reject(error);
      });
    });
  };
};

export const getProducts = () => {
  return (dispatch, getState) => {
    const { organization } = getState();
    const { integration } = organization;

    let ids = null;

    const stripeProducts = pathOr(null, ['stripe', 'products'], integration);

    if (stripeProducts) {
      ids = stripeProducts[isLocalhost ? 'test' : 'live'];
    }

    return new Promise((resolve, reject) => {
      if (!ids) {
        reject(new Error('getProducts: No product IDs.'));
        return false;
      }

      turbineStripe.getProducts(ids).then((response) => {
        const products = response && response.data && response.data.length !== 0 ? response.data : null;

        dispatch({
          type: SET_PRODUCTS,
          products
        });

        resolve({ products });
      }).catch((error) => {
        console.warn(error.message);
        reject(error);
      });
    });
  };
};

export const getPrice = (priceId) => {
  return (dispatch) => {
    const errorGetPrice = new Error('Something went wrong, please refresh and try again.');

    return new Promise((resolve, reject) => {
      if (!priceId) {
        console.warn('getPrice: missing price ID');
        reject(errorGetPrice);
        return false;
      }

      turbineStripe.getPrice(priceId).then((price) => {
        dispatch({
          type: SET_PRICE,
          price
        });

        resolve({ price });
      }).catch((error) => {
        console.warn(`getPrice: ${error.message}`);
        reject(errorGetPrice);
      });
    });
  };
};

export const getInvoices = () => {
  return (dispatch, getState) => {
    const { billing } = getState();
    const customer = pathOr(null, ['customer'], billing);

    return new Promise((resolve, reject) => {
      if (!customer || !customer.id) {
        reject(new Error('getInvoices: No customer.'));
        return false;
      }

      turbineStripe.getInvoices({ customer: customer.id }).then((response) => {
        const invoices = response && response.data && response.data.length !== 0 ? response.data : null;

        dispatch({
          type: SET_INVOICES,
          invoices
        });

        resolve({ invoices });
      }).catch((error) => {
        console.warn(error.message);
        reject(error);
      });
    });
  };
};
