import * as turbineDocs from './turbine-docs-api';
import { CREATE_PDF_HTML, CREATE_PDF_URL } from '../constants/api';
import { TURBINE_DOCS_API } from '../constants/urls';

/**
 * @summary creates pdf from URL
 * @param {string} name // filename
 * @param {string} url // templateA
 *
 */
export const generatePdfFromUrlLink = ({
  url,
  name
}) => {
  let link = TURBINE_DOCS_API + CREATE_PDF_URL;

  link += `?url=${encodeURIComponent(url)}&name=${encodeURIComponent(name)}`;

  return link;
};

/**
 * @summary creates pdf from URL
 * @param {string} filename // filename
 * @param {boolean as string} auth // requires authentication
 * @param {string} url
 *
 */
export const createPdfFromUrl = ({
  url,
  filename,
  auth
}) => {
  return new Promise((resolve, reject) => {
    const params = {
      name: filename,
      ...(url ? { url } : {}),
      ...(auth ? { auth } : {})
    };

    return turbineDocs.get(CREATE_PDF_URL, params).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 * @summary creates pdf from template & JSON
 * @param {object} data // json
 * @param {string} filename // filename
 * @param {boolean as string} auth // requires authentication
 * @param {string} template // templateA
 *
 */
export const createPdfFromTemplate = ({
  data,
  template,
  filename,
  auth
}) => {
  return new Promise((resolve, reject) => {
    const params = {
      name: filename,
      ...(template ? { template } : {}),
      ...(auth ? { auth } : {})
    };

    return turbineDocs.post(CREATE_PDF_HTML, params, JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};
