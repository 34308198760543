import React, { useEffect, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import CircleButton from '@apprentage/components/dist/components/CircleButton';
import { getGeoLocation } from '../../services/ipApi';

const SearchOccupationsForm = ({
  title = '',
  handleNextClick,
  handleClickBack,
  occupation,
  setOccupation,
  handleClickSkip
}) => {
  const [zipCode, setZipCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [disableZipCodeInput, setDisableZipCodeInput] = useState(true);

  const getZipCodes = async () => {
    setIsLoading(true);
    try {
      const response = await getGeoLocation();
      setZipCode(response?.zip);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getZipCodes();
  }, []);

  return (
    <div className="p-3">
      <div className="d-flex align-items-center mt-2">
        {handleClickBack && (
          <CircleButton
            className="mr-3"
            size='sm'
            onClick={(e) => {
              e.preventDefault();
              handleClickBack?.();
            }}
          >
            <FaArrowLeft size={18} className='text-primary' />
          </CircleButton>
        )}
        <div className="flex-grow-1">
          <h3 className="font-weight-bolder text-dark text-center">
            {title || ''}
          </h3>
        </div>
      </div>
      <form
        id="create-training-program"
        onSubmit={(e) => {
          e.preventDefault();
          handleNextClick();
        }}
      >
        <div className="mt-3 p-4">
          <p className="text-dark my-2">
            Synchronize program with federal identifiers including RAPIDS &
            O*NET (CIP) Codes.
          </p>
          <h6 className="text-dark font-weight-bold mt-4">Occupation Name</h6>
          <input
            name="occupation-name"
            onChange={(e) => {
              setOccupation(e.target.value);
            }}
            value={occupation}
            className="form-control"
          />
          <div className="text-light font-weight-light mt-1 mb-4">
            Ex. Aircraft Mechanic
          </div>
          <h6 className="text-dark font-weight-bold mt-4">Zip Code</h6>
          <div className="position-relative">
            <input
              name="zip-code"
              disabled={isLoading || disableZipCodeInput}
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
              value={zipCode ?? ''}
              className="form-control"
              style={{ paddingRight: '5rem' }}
            />
            <button
              type="button"
              className="btn btn-link btn-md position-absolute text-decoration-none"
              style={{ top: 0, right: 0, bottom: 0 }}
              onClick={() => {
                setDisableZipCodeInput(!disableZipCodeInput);
              }}
            >
              Change
            </button>
          </div>
          <div className="my-4 text-center d-flex flex-column align-items-center">
            <button
              type="submit"
              disabled={
                zipCode?.trim().length === 0 || occupation?.trim().length === 0
              }
              className="btn btn-md bg-primary text-white px-3 my-3 text"
            >
              Next
            </button>
            {handleClickSkip && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  handleClickSkip?.();
                }}
              >
                I'll do it later
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchOccupationsForm;
