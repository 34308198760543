import {
  RESET_ASSISTANT_FILES,
  RESET_ASSISTANT,
  SET_ASSISTANT,
  SET_ASSISTANT_FILES
} from '../../actions/types';
import assistantState from '../initialState/assistantState';

const initialState = {
  ...assistantState,
  fetched: false,
  cdnFiles: null
};

export default function assistant(state = initialState, action) {
  switch (action.type) {
    case SET_ASSISTANT:
      return {
        ...state,
        ...action.assistant,
        cdnFiles: null,
        fetched: true
      };
    case SET_ASSISTANT_FILES:
      return {
        ...state,
        cdnFiles: action.cdnFiles || null
      };
    case RESET_ASSISTANT_FILES:
      return {
        ...state,
        cdnFiles: initialState.cdnFiles
      };
    case RESET_ASSISTANT:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
