import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PLATFORM_NAME } from '../../../constants/globals';
import { updateUser } from '../../../services/user';
import { getCurrentUser } from '../../../actions/Users';
import { resetCurrentModal } from '../../../actions/Modals';

const NotOwner = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgType = organization?.type || '';
  // Current User
  const userId = currentUser?.id || '';
  // Local State
  const [loading, setLoading] = useState(false);

  const startCommunity = () => {
    setLoading(true);
    updateUser({
      onboarded: true
    }, userId).then(() => {
      dispatch(getCurrentUser({ userId }));
      dispatch(resetCurrentModal());
    });
  };

  const startWorkforce = () => {
    setLoading(true);
    updateUser({
      onboarded: true
    }, userId).then(() => {
      dispatch(getCurrentUser({ userId }));
      // dispatch(setCurrentModal({ key: '...tbd' }));
      // Favorite some widgets
      // Add Resources
      // Nicely Done!
      // Enterprise Dashboard
      dispatch(resetCurrentModal());
    });
  };

  // Apprentage

  if (orgType === 'community') {
    return (
      <div>
        <h1 className="font-weight-bolder text-purple-light">
          Welcome to {PLATFORM_NAME}
        </h1>
        <p className="font-weight-bold text-dark">
          Everything about apprenticeships just got easier.
        </p>

        <p className="text-dark">
          Lets start all success.
          <br /> Next, you'll select a training program
          <br /> review your Trainee's Logbook Records and
          <br /> track progress in their Logbook
        </p>

        <button
          type="button"
          className="btn bg-purple-light btn-md text-white"
          onClick={startCommunity}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Get Started'}
        </button>
      </div>
    );
  }

  // Enterprise

  if (orgType === 'workforce') {
    return (
      <div>
        <h1 className="font-weight-bolder text-purple-light">
          Welcome to {PLATFORM_NAME}
        </h1>
        <p className="font-weight-bold text-dark">
          Everything about workforce development just got easier.
        </p>

        <p className="text-dark">
          Lets start all success.
          <br /> Next, explore the Dashboard, choose widgets
          <br /> you use on a daily basis and begin
          <br /> contributing by adding a helpful resource
        </p>

        <button
          type="button"
          className="btn bg-purple-light btn-md text-white"
          onClick={startWorkforce}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Get Started'}
        </button>
      </div>
    );
  }

  return null;
};

export default NotOwner;
