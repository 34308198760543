import { CHEERIO_PARSE_JD, CHEERIO_PARSE_ORG_CONTEXT } from '../constants/api';
import * as twApi from './tw-api';

export async function cheerioParseJobDescription(url = '') {
  const response = await twApi.get(CHEERIO_PARSE_JD, { url });

  return response;
}

export async function cheerioParseOrgContext(url = '') {
  const response = await twApi.get(CHEERIO_PARSE_ORG_CONTEXT, { url });

  return response;
}
