import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { getEmployers, getWageSchedules } from '../../actions/Employers';
import { getEmployerAgreements } from '../../actions/EmployerAgreements';
import Loading from '../Loading';
import List from './List';
import { getInvites } from '../../actions/Invites';
import { getUsers } from '../../actions/Users';

const EmployersList = ({
  locationIds,
  apprenticeshipId,
  handleClickRow,
  hiddenColumns = []
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const employers = useSelector((state) => state.employers);
  const wageSchedules = useSelector((state) => state.wageSchedules);
  const employerAgreements = useSelector((state) => state.employerAgreements);
  // Organization
  const orgId = pathOr(null, ['id'], organization);
  // Local State
  const [wageSchedulesEnum, setWageSchedulesEnum] = useState({});

  useEffect(() => {
    const config = {
      orgId,
      ...(locationIds ? { locationIds } : {})
    };

    dispatch(getEmployers(config));
  }, [orgId, locationIds]);

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getWageSchedules({ apprenticeshipId })).then((wageSchedulesList) => {
        if (wageSchedulesList && Array.isArray(wageSchedulesList) && wageSchedulesList.length) {
          // Create enum to determine if each employer has at least one wageSchedule
          const wsEnum = {
            ...wageSchedulesEnum
          };

          wageSchedulesList.forEach((wS) => {
            wsEnum[wS.employerProfileId] = true;
          });

          setWageSchedulesEnum(wsEnum);
        }
      });

      // Get all Employer Agreements associated with this Training Program
      dispatch(getEmployerAgreements({ apprenticeshipId }));
      dispatch(getInvites({ apprenticeshipId }));
    }
  }, [apprenticeshipId]);

  useEffect(() => {
    if (Array.isArray(employers.list) && employers.list.length !== 0) {
      let userIds = employers.list.reduce((acc, curr) => {
        if (curr.managerIds) {
          acc.push(...curr.managerIds);
        }
        return acc;
      }, []);

      userIds = [...new Set(userIds)];

      dispatch(getUsers({ userIds }));
    }
  }, [employers.list]);

  if (!employers.fetched && !employers.list) {
    return (
      <Loading text="Loading Employers..." />
    );
  }

  if (apprenticeshipId && !wageSchedules.fetched) {
    return (
      <Loading text="Loading Wage Schedules..." />
    );
  }

  if (apprenticeshipId && !employerAgreements.fetched) {
    return (
      <Loading text="Loading Documents..." />
    );
  }

  if (employers.fetched && !employers.list) {
    return (
      <div>
        No Employers
      </div>
    );
  }

  return (
    <div className="users-list">
      <List
        handleClickRow={handleClickRow}
        data={employers.list}
        wageSchedulesEnum={apprenticeshipId ? wageSchedulesEnum : undefined}
        hiddenColumns={hiddenColumns}
      />
    </div>
  );
};

export default EmployersList;
