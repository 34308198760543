import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups, getOrgUserGroupIds } from '../../../actions/Groups';
import Loading from '../../Loading';
import List from './List';

const UserGroupsList = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const userGroups = useSelector((state) => state.userGroups);
  const orgId = pathOr(null, ['id'], organization);

  useEffect(() => {
    dispatch(getOrgUserGroupIds({ orgId })).then((userGroupIds) => {
      dispatch(getGroups({
        orgId,
        type: 'user',
        sortByIds: userGroupIds
      }));
    });
  }, []);

  if (!userGroups.fetched) {
    return (
      <Loading text="Loading Groups..." />
    );
  }

  return (
    <div className="groups-list border">
      <List
        data={userGroups.list}
      />
    </div>
  );
};

export default UserGroupsList;
