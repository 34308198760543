import React, { Fragment, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaCity, FaCopy, FaLink } from 'react-icons/fa';
import { resetCurrentModal } from '../../../actions/Modals';
import { TURBINE_CONSOLE } from '../../../constants/urls';
import { sendEmailUserCreateJobProfilesHtml } from '../../../services/mailer';
import { canAccessOrgLocations } from '../../../services/currentUser';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_LOCATIONS } from '../../../constants/routes';
import Modal from '../../Modal';
import SelectMenu from '../../SelectMenu';

const InviteUserCreateJobProfile = () => {
  const dispatch = useDispatch();
  const userRegLink = useRef(null);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);
  const locations = useSelector((state) => state.locations);

  // Modal
  const key = 'inviteUserCreateJobProfile';
  const currentModalKey = currentModal?.key || null;
  const modalDataLocationId = currentModal?.data?.locationId || null;
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const orgName = organization?.name || '';
  const orgEmail = organization?.email || '';
  const orgLogo = organization?.orgLogo || '';
  // Local State
  const [loading, setLoading] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState(!modalDataLocationId);
  const [emails, setEmails] = useState(null); // TODO [] ?
  const [locationId, setLocationId] = useState(modalDataLocationId);
  // Location
  const locationsHash = locations?.hash || {};
  const location = locationsHash[locationId] || {};
  const locationName = location?.name || 'Employer';

  const userRegisterLink = () => {
    let url = `${TURBINE_CONSOLE}/external/pathways`;

    url += '?embedded=true';
    url += `&orgId=${orgId}`;

    if (locationId) {
      url += `&locationId=${locationId}`;
    }

    return url;
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    sendEmailUserCreateJobProfilesHtml({
      orgName,
      orgEmail,
      orgLogo,
      locationName,
      inviteLink: userRegisterLink(),
      emails
    }).then(() => {
      handleClose();
    }).catch((error) => {
      console.error(error);
    });
  };

  const selectAll = (e) => {
    e.currentTarget.select();
  };

  const copyLink = (refElem) => {
    const { value } = refElem.current;

    refElem.current.focus();
    refElem.current.select();

    navigator.clipboard.writeText(value);

    toast.success('Link copied!');
  };

  if (currentModal && !currentModal.visible) return null;

  if (currentModalKey !== key) return null;

  let locationsOptions = null;

  if (locations.list) {
    locationsOptions = locations.list.filter((l) => !l.defaultLocation);

    locationsOptions = locationsOptions.map((l) => {
      return {
        text: l.name,
        value: l.id
      };
    });

    if (modalDataLocationId) {
      locationsOptions = locationsOptions.filter((item) => item.value === locationId);
    }
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModalKey === key}
      close={handleClose}
    >
      <div className="card">
        <div className="card-header">
          <h1 className="h5 m-0">
            Invite Employer
          </h1>
        </div>
        <div className="card-body p-0">

          {locationId && (
            <div className="border-bottom p-3">
              <form
                id="invite-user"
                name="invite-user"
                onSubmit={onSubmit}
              >
                <h6>
                  to create <b>{locationName} Job Profiles</b>
                </h6>

                <textarea
                  type="text"
                  name="emails"
                  className="form-control mb-2"
                  placeholder="name@gmail.com"
                  aria-label="User Emails"
                  aria-describedby="input-sendInvitation"
                  value={emails}
                  onChange={(e) => {
                    const { value: newEmails } = e.currentTarget;

                    setEmails(newEmails);
                  }}
                  disabled={loading}
                  autoFocus
                  required
                />
                <div className="mb-3 text-muted small">
                  Ex: dylan@company.com, rmaxwell@organization.com
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-muted text-break-all">
                    <button
                      title="Copy Link"
                      type='button'
                      className='btn-link pr-1'
                      onClick={() => {
                        copyLink(userRegLink);
                      }}
                    >
                      <span className='d-flex align-items-center'>
                        <FaLink />
                        <span className='ml-2'>
                          Copy invite link
                        </span>
                      </span>
                    </button>

                    {!advancedSettings && (
                      <span className="text-muted ml-1">
                        <span>-</span>
                        <button
                          title="Show advanced settings"
                          type='button'
                          className='btn-link text-muted pl-1'
                          onClick={() => {
                            setAdvancedSettings(true);
                          }}
                        >
                          Settings
                        </button>
                      </span>
                    )}
                  </div>
                  <button
                    title="Send Invitation"
                    aria-label="Send Invitation"
                    className={`btn ${emails ? 'btn-primary' : 'btn-secondary'}`}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Sending...' : 'Send'}
                  </button>
                </div>

              </form>
            </div>
          )}

          <div id="invite-link-settings" className={advancedSettings ? 'd-block' : 'd-none'}>
            <div className="card-body">
              {locationId || (!modalDataLocationId && locationsOptions.length !== 0) ? (
                <div className="mb-2 border p-2">
                  <h6 className="font-weight-bold">
                    <span className='d-flex align-items-center'>
                      <FaCity />
                      <span className='ml-2'>
                        Employer
                      </span>
                    </span>
                  </h6>
                  <SelectMenu
                    defaultOption={locationId ? false : { text: 'Select Location', value: '' }}
                    className="form-control-sm mt-2"
                    onChange={(e) => {
                      const { value: newLocation } = e.currentTarget;
                      setLocationId(newLocation);
                    }}
                    defaultValue={locationId}
                    name="locationId"
                    options={locationsOptions}
                    required
                  />
                </div>
              ) : (
                <div>
                  <p>
                    No employers found.
                  </p>
                  {canAccessOrgLocations(role, orgType) && (
                    <p>
                      <Link
                        to={routeWithOrgId({
                          route: `${ORG_CONSOLE_LOCATIONS}/manage`,
                          orgId
                        })}
                        onClick={() => {
                          dispatch(resetCurrentModal());
                        }}
                        className="btn-link"
                      >
                        Create an employer
                      </Link>, then invite them to create Job Profiles.
                    </p>
                  )}
                </div>
              )}

              {locationId && locations.hash[locationId] && (
                <Fragment>
                  <h5 className="font-weight-bold">
                    {locations.hash[locationId].name} Job Profiles
                  </h5>
                  <div className="mb-2">
                    Invitation Link:
                  </div>
                  <div className="input-group">
                    <textarea
                      readOnly
                      style={{ minHeight: '100px' }}
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      key={locationId}
                      value={locationId ? userRegisterLink() : ''}
                      onClick={selectAll}
                      ref={userRegLink}
                    />
                    <div className="input-group-append">
                      <button
                        title="Copy Link"
                        aria-label="Copy Link"
                        type='button'
                        className='btn btn-info'
                        onClick={() => {
                          copyLink(userRegLink);
                        }}
                      >
                        <FaCopy />
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>

        {/* <div className="card-footer text-muted">
          <b>Note:</b> New users are automatically assigned the role <b>Student</b>. An organization <b>Owner</b> can modify user roles.
        </div> */}
      </div>
    </Modal>
  );
};

export default InviteUserCreateJobProfile;
