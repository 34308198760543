import {
  isAdminOwnerSuperAdmin,
  isProgramManager,
  isProgramTrainer,
  isStudent,
  isTeacher
} from '@apprentage/utils';

export default function canAccessAdminDocs(role = []) {
  if (isProgramTrainer(role) || isProgramManager(role)) {
    return false;
  }

  return isStudent(role) || isTeacher(role) || isAdminOwnerSuperAdmin(role);
}
