import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCog, FaEllipsisH } from 'react-icons/fa';
import { canManageProgramUsers } from '../../permissions/manage';
import { setCurrentModal } from '../../actions/Modals';

const DotMenu = () => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // Training Program: User Profile
  const apprenticeshipUser = apprenticeship?.user || null;
  // Current User
  const role = currentUser?.role || [];

  if (!canManageProgramUsers(role)) {
    return;
  }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH size={15} />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white" />

          {canManageProgramUsers(role) && (
            <button
              className="dropdown-item p-2"
              onClick={() => {
                dispatch(setCurrentModal({
                  key: 'userApprenticeshipSettings',
                  data: { user: apprenticeshipUser }
                }));
              }}
              title="Settings"
              type="button"
            >
              <span className='d-flex align-items-center'>
                <FaCog size={15} className='mr-2' />
                <span>Settings</span>
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
