import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ICON_UPLOAD } from '@apprentage/constants';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import Blackboard from './Blackboard';
import Canvas from './Canvas';
import ChooseProvider from './ChooseProvider';
import Moodle from './Moodle';

class ImportQuizQuestions extends Component {
  state = {
    key: 'importQuizQuestions',
    screen: 'CHOOSE_PROVIDER',
    ...this.props.currentModal.data
  }

  handleClose = () => {
    this.props.resetCurrentModal();
  }

  handleSuccess = (importedQuestions) => {
    toast.success(`${importedQuestions.length} Questions imported!`);
    const { currentModal } = this.props;
    const { handleImportSuccess } = currentModal;

    handleImportSuccess(importedQuestions);
    this.handleClose();
  }

  handleChooseProvider = (newState) => {
    this.setState(newState);
  }

  handleGoBack = () => {
    this.setState({
      screen: 'CHOOSE_PROVIDER'
    });
  }

  render() {
    const { currentModal } = this.props;
    if (currentModal && !currentModal.visible) return null;

    const { key, screen } = this.state;
    if (currentModal.key !== key) return null;

    return (
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
        visible={currentModal.key === key}
        close={this.handleClose}
        pos="middle"
        theme="dark"
      >
        <div className="card border-0">
          <div className="card-header bg-dark">
            <h5>
              <i className={`${ICON_UPLOAD} mr-1`} /> Import Questions
            </h5>
          </div>

          {screen !== 'CHOOSE_PROVIDER' && (
            <div className='p-2 border-bottom'>
              <button
                className='btn-link'
                title="Choose another provider"
                onClick={this.handleGoBack}
                type="button"
              >
                <i className="fas fa-arrow-circle-left text-muted mr-1" /> Choose another provider
              </button>
            </div>
          )}

          <div className="card-body">
            {screen === 'CHOOSE_PROVIDER' && (
              <ChooseProvider
                onClick={this.handleChooseProvider}
              />
            )}

            {screen === 'MOODLE' && (
              <Moodle
                handleImportSuccess={(importedQuestions) => {
                  this.handleSuccess(importedQuestions);
                }}
              />
            )}

            {screen === 'BLACKBOARD' && (
              <Blackboard />
            )}

            {screen === 'CANVAS' && (
              <Canvas />
            )}
          </div>
          <div className='card-footer'>
            <div className="font-weight-bold">
              Supported question types:
            </div>
            <ul className='m-0 pl-4 pt-2'>
              <li>
                Multiple Choice
              </li>
              <li>
                Multiple Choice with Multiple Right Answers
              </li>
              <li>
                True-False
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ currentModal }) => ({ currentModal });

export default connect(mapStateToProps, { resetCurrentModal })(ImportQuizQuestions);
