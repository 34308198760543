import NameBlock from '@apprentage/components/dist/components/NameBlock';
import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import { roleReadable } from '@apprentage/utils';
import React from 'react';
import { useSelector } from 'react-redux';

const People = () => {
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <div className='mb-3'>
      <h5>
        People
      </h5>
      <div
        className='shadow p-3 mr-3 rounded bg-white'
        style={{
          border: '1px dashed #dee2e6'
        }}
      >
        <NameBlock
          className="text-left"
          name={currentUser?.name}
          role={roleReadable(currentUser?.role || [], organization?.type)}
          nameSize="sm"
          pictureSize='sm'
          pictureHeight={40}
          pictureWidth={40}
          profilePicture={currentUser?.profilePicture || DEFAULT_PROFILE_PIC}
        />
      </div>
    </div>
  );
};

export default People;
