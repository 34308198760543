import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEllipsisH, FaCog } from 'react-icons/fa';
import { setCurrentModal } from '../../../../actions/Modals';
import { updateLocation } from '../../../../actions/Locations';
import { routeWithOrgId } from '../../../../services/organizations';
import {
  createLocation,
  locationTerminology
} from '../../../../services/locations';
import {
  ORG_CONSOLE_SETTINGS,
  ORG_CONSOLE_LOCATIONS
} from '../../../../constants/routes';
import { MODAL_KEY_SEARCH_SELECT_USERS } from '../../../../constants/modals';
import ImageUploadAndPreview from '../../../ManageContent/ImageUploadAndPreview';
import Users from '../../../ManageContent/Users';
import Collapse from '../../../ManageContent/Collapse';
import Address from '../../../ManageContent/Address';
import { updateAcl } from '../../../../services/aws';

const Form = ({ data }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // Local State
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  // Location : State
  const [name, setName] = useState(data.name || '');
  const [subtitle, setSubtitle] = useState(data.subtitle || '');
  const [phone, setPhone] = useState(data.phone || '');
  const [fax, setFax] = useState(data.fax || '');
  const [calendar, setCalendar] = useState(data.calendar || '');
  const [mapLink, setMapLink] = useState(data.mapLink || '');
  const [locationImage, setLocationImage] = useState(data.locationImage || '');
  // Address : Location : State
  const [addressLine1, setAddressLine1] = useState(data.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(data.addressLine2 || '');
  const [addressCity, setAddressCity] = useState(data.addressCity || '');
  const [addressState, setAddressState] = useState(data.addressState || '');
  const [addressPostalCode, setAddressPostalCode] = useState(
    data.addressPostalCode || ''
  );
  const [addressCountry, setAddressCountry] = useState(
    data.addressCountry || ''
  );
  // Misc
  const searchParams = new URLSearchParams(window.location.search);
  const redirectRoute = searchParams.get('redirectRoute');
  const userId = currentUser?.id || '';
  const orgId = organization?.id || '';
  const orgType = organization?.type || '';
  const pathname = location?.pathname || '';
  const locationId = data?.id || '';
  const defaultLocation = data?.defaultLocation || false;
  const locationTerm = locationTerminology({ orgType });
  // Managers
  const [managerIds, setManagerIds] = useState(data.managerIds || [userId]);

  const showCommunityUI = useMemo(() => {
    return orgType !== 'workforce' && !pathname.includes(ORG_CONSOLE_SETTINGS);
  }, [orgType, pathname]);

  const editLocation = (dataToSave) => {
    dispatch(updateLocation(dataToSave, locationId)).then(() => {
      const successMsg = `${showCommunityUI ? 'Employer' : 'Location'} updated!`;

      toast.success(successMsg);

      if (pathname.includes('/org/locations/')) {
        // Editing Locations or Employers
        history.replace(
          routeWithOrgId({
            route: redirectRoute || ORG_CONSOLE_LOCATIONS,
            orgId
          })
        );
      } else {
        setLoading(false);
      }
    });
  };

  const addLocation = (dataToSave) => {
    const infoMsg = `Saving ${showCommunityUI ? 'Employer' : 'Location'}...`;
    toast.info(infoMsg);

    createLocation(dataToSave).then((newLocation) => {
      const newLocationId = newLocation?.sys?.id || '';
      const successMsg = `${showCommunityUI ? 'Employer' : 'Location'} Saved!`;

      toast.success(successMsg);

      // New Location route to redirect user to after updating Org.
      const route = routeWithOrgId({
        route: `${ORG_CONSOLE_LOCATIONS}/manage/${newLocationId}`,
        orgId
      });

      history.replace(route);
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const dataToSave = {
      enableNewEditor: true,
      name,
      calendar,
      locationId,
      subtitle,
      phone,
      fax,
      mapLink,
      locationImage,
      managerIds,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressPostalCode,
      addressCountry
    };

    if (!managerIds || (managerIds && !managerIds.length)) {
      toast.error('At least one Manager is required.');
      return null;
    }

    setLoading(true);

    if (locationId) {
      editLocation(dataToSave);
    } else {
      addLocation({
        ...dataToSave,
        orgId
      });
    }
  };

  const handleUserAddressOnChange = (e) => {
    const { name: fieldName, value } = e.currentTarget;

    if (fieldName === 'line1') {
      setAddressLine1(value);
    }

    if (fieldName === 'line2') {
      setAddressLine2(value);
    }

    if (fieldName === 'city') {
      setAddressCity(value);
    }

    if (fieldName === 'state') {
      setAddressState(value);
    }

    if (fieldName === 'postal_code') {
      setAddressPostalCode(value);
    }

    if (fieldName === 'country') {
      setAddressCountry(value);
    }
  };

  const selectManagers = () => {
    dispatch(
      setCurrentModal({
        key: MODAL_KEY_SEARCH_SELECT_USERS,
        data: {
          title: 'Select Managers',
          ids: managerIds,
          searchFilters: {
            excludeRoles: [
              ...(orgType === 'community' ? ['student', 'programTrainer', 'programManager'] : []),
              'superAdmin'
            ]
          }
        },
        callbackPrimaryAction: (newManagerIds) => {
          setManagerIds(newManagerIds);
        }
      })
    );
  };

  return (
    <div className="bg-white border-left border-right border-bottom">
      <form onSubmit={onSubmit}>
        <div className="card-body">
          <div className="mb-3">
            <label
              className="font-weight-bold"
              htmlFor="name"
            >
              Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="name"
              className="form-control"
              defaultValue={name}
              autoFocus
              onChange={(e) => {
                const { value } = e.currentTarget;

                setName(value);
              }}
              required
            />
          </div>

          {locationId && (
            <div className="mb-3">
              <label
                className="font-weight-bold"
                htmlFor="subtitle"
              >
                Subtitle
              </label>
              <input
                type="text"
                name="subtitle"
                className="form-control"
                defaultValue={subtitle}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setSubtitle(value);
                }}
              />
            </div>
          )}

          {locationId && (
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label
                  className="font-weight-bold"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  defaultValue={phone}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setPhone(value);
                  }}
                />
              </div>

              <div className="col-sm-6">
                <label
                  className="font-weight-bold"
                  htmlFor="fax"
                >
                  Fax
                </label>
                <input
                  type="text"
                  name="fax"
                  className="form-control"
                  defaultValue={fax}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setFax(value);
                  }}
                />
              </div>
            </div>
          )}

          {locationId && orgType === 'workforce' && (
            <div className="mb-4">
              <label
                className="font-weight-bold mb-0"
                htmlFor="calendar"
              >
                Calendar
              </label>
              <div className="small mb-1">
                Provide a shared calendar (URL) to the users of this location
              </div>
              <input
                type="url"
                name="calendar"
                className="form-control"
                defaultValue={calendar}
                placeholder='https://...'
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setCalendar(value);
                }}
              />
              <div className='small text-muted'>
                Ex: https://calendar.google.com/calendar...
              </div>
            </div>
          )}

          {locationId && (
            <Address
              title={
                defaultLocation ? 'Address' : `${locationTerm.text} Address`
              }
              cardStyle
              address={{
                line1: addressLine1,
                line2: addressLine2,
                city: addressCity,
                state: addressState,
                postal_code: addressPostalCode,
                country: addressCountry
              }}
              editMode
              onChange={handleUserAddressOnChange}
              inputFieldStyle={{ padding: '5px 7px' }}
              autoFocus={false}
              required
            >
              <div className="mt-3">
                <label
                  className="font-weight-bold"
                  htmlFor="mapLink"
                >
                  Map Link
                </label>
                <input
                  type="text"
                  name="mapLink"
                  className="form-control"
                  defaultValue={mapLink}
                  onChange={(e) => {
                    const { value } = e.currentTarget;

                    setMapLink(value);
                  }}
                />
                <div className="small mt-1">Ex: https://goo.gl/maps/xyz</div>
              </div>
            </Address>
          )}

          {locationId && (
            <Collapse
              title="Branding"
              className="mt-3"
              id="locationLogoCollapse"
            >
              <div className="mb-3">
                <label
                  className="font-weight-bold"
                  htmlFor="name"
                >
                  {showCommunityUI ? 'Employer Logo' : 'Location Image'}
                </label>
                <ImageUploadAndPreview
                  stateKey="locationImage"
                  defaultValue={locationImage}
                  contentId={locationId}
                  contentType="location"
                  style={{ margin: '.8rem 0' }}
                  onImagePreview={() => {
                    setLoading(true);
                    setLoadingText('Processing image...');
                  }}
                  onImageUpload={({ locationImage: newLocationImage }) => {
                    setLocationImage(newLocationImage);
                    updateAcl({
                      acl: 'public-read',
                      fileUrl: newLocationImage,
                      orgId
                    });
                    setLoading(false);
                    setLoadingText(null);
                  }}
                  onImageRemove={() => {
                    setLocationImage('');
                  }}
                />
              </div>
            </Collapse>
          )}

          {locationId && (
            <div className="card mt-3">
              <div
                className="card-header d-flex align-items-center justify-content-between"
              >
                <div>
                  <div className="h5 m-0">Managers</div>
                </div>
                <div className="btn-group align-items-center">
                  <button
                    className="btn btn-link btn-sm rounded text-ships-officer"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    title="Manage Menu"
                    aria-label="Manage Menu"
                  >
                    <FaEllipsisH />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right p-0">
                    {/* {canManageLocationManagers(role) && ( */}
                    <button
                      className="dropdown-item py-2 d-flex align-items-center"
                      type="button"
                      title="Select Manager"
                      onClick={selectManagers}
                    >
                      <FaCog />
                      <span className='ml-2'>
                        Select Managers
                      </span>
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Users
                  key={managerIds.join(',')}
                  userIds={managerIds}
                />
              </div>
              <div className="card-footer bg-white text-muted py-2">
                <i className="fas fa-info-circle mr-1" /> Managers receive
                notifications when new users join and submit answers.
              </div>
            </div>
          )}
        </div>

        <div className="card-footer p-3 d-flex align-items-center bg-light">
          <button
            className="btn btn-md btn-primary"
            type="submit"
            disabled={loading}
          >
            {loading ? loadingText || 'Saving...' : 'Save'}
          </button>

          <Link
            className="btn btn-md btn-link ml-2"
            to={routeWithOrgId({
              route: redirectRoute || ORG_CONSOLE_LOCATIONS,
              orgId
            })}
            title="Cancel"
            disabled={loading}
          >
            Cancel
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Form;
