import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const TopicCell = ({
  value: topicId
}) => {
  const courseTopics = useSelector((state) => state.courseTopics);

  const topicTitle = useMemo(() => {
    const topic = Array.isArray(courseTopics?.list) ? courseTopics.list.find((t) => t.id === topicId) : null;

    if (topic) {
      return topic?.title;
    }

    return topicId;
  }, [courseTopics.list, topicId]);

  if (!courseTopics.list) {
    return null;
  }

  return topicTitle;
};

export default TopicCell;
