import { fetchEmployerProfile, fetchEmployerProfiles, fetchWageSchedules } from '../services/employers';
import { fetchLocation, fetchLocations } from '../services/locations';
import {
  RESET_EMPLOYER, RESET_EMPLOYERS, RESET_EMPLOYER_PROFILE, RESET_WAGE_SCHEDULES, SET_EMPLOYER,
  SET_EMPLOYERS, SET_EMPLOYER_PROFILE, SET_EMPLOYER_PROFILES, SET_ENTRY_ORG_ID, SET_WAGE_SCHEDULES
} from './types';

export const getEmployers = ({
  orgId, locationIds, include = 1, excludeDefaultLocation = true
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLocations({
        orgId, locationIds, include, excludeDefaultLocation
      }).then((list) => {
        let employerProfileIds = [];

        if (list && list.length !== 0) {
          // Exclude any locations that don't have an employerProfileId
          // create array of employerProfileIds from locations list
          employerProfileIds = list.filter((item) => item.employerProfileId !== undefined).map(({ employerProfileId }) => employerProfileId);
        }

        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });

        if (employerProfileIds.length !== 0) {
          dispatch(getEmployerProfiles({ ids: employerProfileIds })).then(() => {
            dispatch({ type: SET_EMPLOYERS, list });
            resolve(list);
          });
        } else {
          dispatch({ type: SET_EMPLOYERS, list });
          resolve(list);
        }
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const getEmployerProfile = (employerProfileId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchEmployerProfile(employerProfileId).then((employerProfile) => {
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: employerProfile?.orgId || null
        });
        dispatch({ type: SET_EMPLOYER_PROFILE, employerProfile });
        resolve(employerProfile);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getEmployerProfiles = ({ ids }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchEmployerProfiles({ ids }).then((list) => {
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });
        dispatch({ type: SET_EMPLOYER_PROFILES, list });
        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getWageSchedules = ({
  ids,
  apprenticeshipId,
  employerProfileId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchWageSchedules({
        ids,
        apprenticeshipId,
        employerProfileId
      }).then((list) => {
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: list && list[0] ? list[0]?.orgId : null
        });
        dispatch({ type: SET_WAGE_SCHEDULES, list });
        resolve(list);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const setEmployerProfile = (employerProfile) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      // ORG CHECK
      dispatch({
        type: SET_ENTRY_ORG_ID,
        entryOrgId: employerProfile?.orgId || null
      });

      dispatch({ type: SET_EMPLOYER_PROFILE, employerProfile });
      resolve(employerProfile);
    });
  };
};

export const getEmployer = (locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!locationId) {
        const customError = new Error('getEmployer, missing argument: locationId');

        reject(customError);
      }

      fetchLocation({ locationId }).then((employer) => {
        // ORG CHECK
        dispatch({
          type: SET_ENTRY_ORG_ID,
          entryOrgId: employer?.orgId || null
        });

        // Has Profile
        if (employer && employer.employerProfileId) {
          dispatch(getEmployerProfile(employer.employerProfileId)).then(() => {
            dispatch({
              type: SET_EMPLOYER,
              employer
            });
            resolve(employer);
          });
        } else {
          // No profile
          dispatch({
            type: SET_EMPLOYER,
            employer
          });
          resolve(employer);
        }
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const resetEmployer = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYER });
  };
};

export const resetEmployerProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYER_PROFILE });
  };
};

export const resetEmployers = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYERS });
  };
};

export const resetWageSchedules = () => {
  return (dispatch) => {
    dispatch({ type: RESET_WAGE_SCHEDULES });
  };
};
