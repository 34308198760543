import { toast } from 'react-toastify';

export const activateDemoMode = () => {
  window.localStorage?.setItem('turbine_demo', 'active');

  document.documentElement.classList.add('turbine-demo-active');

  toast.success('Private Mode activated!');
};

export const deactivateDemoMode = () => {
  window.localStorage?.removeItem('turbine_demo');

  document.documentElement.classList.remove('turbine-demo-active');

  toast.info('Private Mode deactivated!');
};

export const isDemoModeActive = () => {
  if (window.localStorage?.getItem('turbine_demo')) {
    document.documentElement.classList.add('turbine-demo-active');

    return true;
  }

  document.documentElement.classList.remove('turbine-demo-active');

  return false;
};

export const toggleDemoMode = () => {
  if (isDemoModeActive()) {
    deactivateDemoMode();
  } else {
    activateDemoMode();
  }
};
