import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaFan } from 'react-icons/fa';
import { VELA_ICON } from '@apprentage/constants';
import { mdySimple } from '../../../../utils/date';
import { queryEngine } from '../../../../services/openAi';
import { diataxisPrompts, executivePrompts } from '../../../../constants/resources';
import RenderMarkdown from '../../../ManageContent/RenderMarkdown';
import UserProfilePicture from '../../../UserProfilePicture';
import InputField from './InputField';
import Prompt from './Prompt';
import PromptDotMenu from './PromptDotMenu';
import './style.css';
import { createUserPrompt, updateUserPrompt } from '../../../../services/userPrompts';
import PromptMaterial from './PromptMaterial';

const Assistant = () => {
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const material = useSelector((state) => state.material);
  // Organization
  const orgId = organization?.id || '';
  // Modal
  const modalData = currentModal?.data;
  const resource = modalData?.resource;
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  // Search Query
  let searchQuery = searchParams.get('q');
  searchQuery = searchQuery ? searchQuery.replaceAll('+', ' ') : '';

  const searchValue = searchQuery || '';
  const [loading, setLoading] = useState(false);
  const [responses, setResponses] = useState([]);

  const prompts = [
    ...executivePrompts,
    ...diataxisPrompts
  ];

  const saveUserPrompt = async (promptText, promptType) => {
    return await createUserPrompt({
      orgId,
      locationId: currentUser?.locationId,
      userId: currentUser?.id,
      resourceIds: resource?.id ? [resource?.id] : null,
      input: promptType === 'userPrompt' ? promptText : null,
      inputType: promptType
    });
  };

  const performQuery = async (promptText, promptType) => {
    setLoading(true);

    setResponses((prevResponses) => {
      return [
        ...prevResponses,
        {
          date: new Date().toISOString(),
          authorName: currentUser?.name,
          message: promptText
        }
      ];
    });

    saveUserPrompt(promptText, promptType).then(async (responseUserPrompt) => {
      try {
        const response = await queryEngine({
          ...(promptType === 'userPrompt' ? { prompt: promptText } : []),
          promptType,
          refId: resource?.id,
          orgId
        });

        updateUserPrompt({
          output: !response?.data?.answer ? null : response?.data?.answer,
          outputType: !response?.data?.answer ? null : 'JSON',
          outputError: response?.error?.message || null
        }, responseUserPrompt?.id);

        if (!response?.data?.answer) {
          toast.error('Something went wrong, please try again.');
          setLoading(false);
          return;
        }

        const answer = JSON.parse(response?.data?.answer);

        const newResponse = {
          id: responseUserPrompt?.id,
          date: new Date().toISOString(),
          postAsCpu: true,
          message: answer?.body,
          output: answer?.body,
          outputTitle: answer?.title,
          promptText,
          promptType
        };

        setResponses((prevResponses) => {
          return [
            ...prevResponses,
            newResponse
          ];
        });
      } catch (error) {
        toast.error('We\'ve been notified that something went wrong, please try again.');
        saveUserPrompt(promptText, promptType);
        updateUserPrompt({
          outputError: error?.message || null
        }, responseUserPrompt?.id);
      } finally {
        setLoading(false);
        document.getElementById('searchValueInput').focus();
      }
    });
  };

  useEffect(() => {
    document.body.classList.add('turbine-assistant');

    return function cleanup() {
      document.body.classList.remove('turbine-assistant');
    };
  }, []);

  // Learning Objective: Clearly state the specific goal or outcome that learners are expected to achieve.
  // Duration: Indicate the number of days required to cover each section or chapter.
  // Hands - On Activity: Propose a practical, interactive task or exercise that reinforces the learning objectives and engages learners in applying the concepts covered.

  return (
    <div
      className="py-3"
    >
      {/* <div
        className='h6 position-fixed'
        style={{
          top: '60px',
          left: '5px',
          zIndex: 1
        }}
      >
        <div className='badge badge-warning'>beta</div>
      </div> */}

      {responses.length === 0 && (
        <>
          {prompts.map((prompt, i) => (
            <Fragment key={prompt?.id || `default-prompts-${i}`}>
              <Prompt
                disabled={loading}
                showButton
                showIcon
                id={prompt?.id}
                promptBadge={prompt?.badge}
                promptText={prompt?.text}
                promptType={prompt?.type}
                onClick={performQuery}
              />
            </Fragment>
          ))}
        </>
      )}

      {responses.length !== 0 && (
        <div>
          <div className="d-flex flex-column">
            {responses.map((data, i) => {
              const {
                id = null,
                postAsCpu = false,
                authorName,
                message,
                output,
                outputTitle,
                promptType,
                promptText
              } = data;
              return (
                <div
                  key={`message-${i}`}
                  className="d-flex mb-3"
                >
                  <div className="d-flex justify-content-center">
                    {postAsCpu ? (
                      <MessageAvatar
                        className="d-flex align-items-center justify-content-center text-white"
                        style={{
                          background: 'black',
                          border: '1px solid #4b4b4b'
                        }}
                      >
                        <img
                          src={VELA_ICON}
                          alt="VELA"
                          className="d-none d-sm-inline-block"
                          height={30}
                          style={{
                            height: '30px'
                          }}
                        />
                      </MessageAvatar>
                    ) : (
                      <>
                        {/* <MessageAvatar className="d-flex align-items-center justify-content-center border bg-light">
                          {avatar(authorName)}
                        </MessageAvatar> */}
                        <UserProfilePicture
                          src={currentUser?.profilePicture}
                          alt={authorName}
                        />
                      </>
                    )}
                  </div>
                  <div className="ml-3 w-100">
                    <MessageContainer
                      data-demo="disabled"
                      className="notification-message-container m-0"
                    >
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <div className='font-weight-bold text-white opacity-75' style={{ fontSize: '1rem' }}>
                          {postAsCpu ? 'VELA' : authorName}
                        </div>
                        <div className='d-flex align-items-center'>
                          {/* <div
                            className="text-muted"
                          >
                            {mdySimple(date)}
                          </div> */}
                          {postAsCpu && (
                            <PromptDotMenu
                              key={material?.id || id}
                              output={output}
                              outputTitle={outputTitle}
                              resourceId={resource?.id}
                              promptId={id}
                              promptType={promptType}
                              promptText={promptText}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="shadow p-3 rounded bg-white position-relative"
                        style={{
                          wordBreak: 'break-word'
                        }}
                      >
                        <RenderMarkdown source={message} />

                        <PromptMaterial
                          key={material?.id || id}
                          userPromptId={id}
                        />
                      </div>
                    </MessageContainer>
                  </div>
                </div>
              );
            })}

            {loading && (
              <div className="d-flex mb-3">
                <div className="d-flex justify-content-center">

                  <MessageAvatar
                    className="d-flex align-items-center justify-content-center text-white"
                    style={{
                      background: 'black',
                      border: '1px solid #4b4b4b'
                    }}
                  >
                    <FaFan className='fa-spin' />
                  </MessageAvatar>
                </div>
                <div className="ml-2">
                  <MessageContainer
                    data-demo="disabled"
                    className="notification-message-container m-0 text-white"
                  >
                    <p>Analyzing file...</p>
                  </MessageContainer>

                  <div className="text-muted">
                    <span>VELA • </span>
                    <span>{mdySimple(new Date().toISOString())} </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <InputField
        value={searchValue}
        className="mt-3 rounded"
        performQuery={performQuery}
        autoFocus
        disabled={loading}
        placeholder={loading ? '...' : 'Enter your query...'}
      />
    </div>
  );
};

const MessageContainer = styled.div`
  p {
    font-size: 1.00rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const MessageAvatar = styled.div`
  border-radius: 100%;
  font-family: 'Arial Bold', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
`;

export default Assistant;
