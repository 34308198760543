import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { getInvites } from '../../actions/Invites';
import DotMenuInvitesBlock from './DotMenuInvitesBlock';
import InvitesList from '../lists/InvitesList';
import InvitesHistoryList from '../InvitesHistoryList';

const InvitesBlock = ({
  className = 'card mt-3',
  data
}) => {
  const dispatch = useDispatch();
  // Redux
  const employerProfile = useSelector((state) => state.employerProfile);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const invites = useSelector((state) => state.invites);
  // Employer Profile
  const employerProfileId = pathOr(null, ['id'], employerProfile);
  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);

  useEffect(() => {
    dispatch(getInvites({
      employerProfileId,
      apprenticeshipId
    }));
  }, []);

  return (
    <div className={className}>
      <div
        className='card-header d-flex align-items-center justify-content-between'
      >
        <div className='font-weight-bold'>
          <span>
            Data & Signature Requests
          </span>
        </div>
        <DotMenuInvitesBlock
          data={data}
        />
      </div>

      <div className='card-body p-0'>
        <InvitesList
          list={invites.list}
          hiddenColumns={['role', 'locationId']}
          noDataText='No Active Requests.'
        />
      </div>

      {employerProfile?.inviteHistory && (
        <>
          <div
            className='card-header d-flex align-items-center justify-content-between border-top'
          >
            History
          </div>

          <div className='card-body p-0'>
            <InvitesHistoryList
              list={employerProfile?.inviteHistory}
              data={data}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InvitesBlock;
