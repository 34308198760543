import { client, flattenItem, flattenItems } from '../contentful';
import { quizAccepted } from '../contentFlow/quizzes';
// import { getFirebaseEntry } from '../firebaseProxy';

export const fetchQuizzes = ({
  orgId,
  classId,
  skillIds,
  select
}) => {
  const config = {
    content_type: 'quiz',
    include: 1,
    limit: 200,
    'fields.orgId': orgId
  };

  if (select) {
    config.select = select.join(',');
  }

  if (classId) {
    config['fields.classId'] = classId;
  }

  if (skillIds) {
    config['fields.quizSkills[in]'] = skillIds.join(',');
  }

  return new Promise((resolve) => {
    client.getEntries(config).then(({ items }) => {
      const list = items.length ? flattenItems(items) : null;

      resolve(list);
    }).catch((error) => {
      console.error(error);
    });
  });
};

export const fetchQuizzesBySkillIds = ({
  skillIds,
  orgId
}) => {
  return new Promise((resolve, reject) => {
    fetchQuizzes({
      orgId,
      skillIds,
      select: ['sys.id', 'fields.title', 'fields.quizSkills', 'sys.createdAt']
    })
      .then((response) => {
        resolve({ quizzes: response }); // TODO responses?.items when migrated to Supabase
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const fetchQuizOutcome = (id) => {
  return new Promise((resolve) => {
    // TODO LMS-ADMIN #355 : Move QuizOutcomes to Firebase
    // https://github.com/upstreamcoding/lms-admin/issues/355
    //
    // getFirebaseEntry({ collection: 'quizOutcomes', id }).then((response) => {
    //   const quizOutcome = response || null;

    //   resolve(quizOutcome);
    // });

    client.getEntry(id).then((response) => {
      const quizOutcome = response ? flattenItem(response) : null;

      resolve(quizOutcome);
    });
  });
};

// TODO LMS-ADMIN #355 : Move QuizOutcomes to Firebase
// https://github.com/upstreamcoding/lms-admin/issues/355
//
export const fetchQuizOutcomes = ({
  orgId,
  classId
}) => {
  const config = {
    content_type: 'quizOutcome',
    include: 1,
    limit: 200,
    'fields.orgId': orgId,
    'fields.classId': classId
  };

  return new Promise((resolve) => {
    client.getEntries(config).then(({ items }) => {
      const list = items.length ? flattenItems(items) : null;
      let acceptedList = null;
      let outcomesHash = null;

      if (!list) {
        resolve({
          outcomesList: list,
          outcomesAcceptedList: acceptedList,
          outcomesHash
        });
        return null;
      }

      acceptedList = list.map((outcome) => {
        if (quizAccepted({ score: outcome.score, maxScore: outcome.maxScore })) {
          if (!outcomesHash) outcomesHash = {};

          if (outcomesHash[outcome.quizId] === undefined) outcomesHash[outcome.quizId] = 0;

          outcomesHash[outcome.quizId] += 1;

          return outcome;
        }

        return null;
      });

      resolve({
        outcomesList: list,
        outcomesAcceptedList: acceptedList,
        outcomesHash
      });
    }).catch((error) => {
      console.error(error);
    });
  });
};

// export const classQuizOutcomesEval = ({ quizzes, quizOutcomes }) => { // TODO is this needed, why not consolidate with topicQUizOutcomesEval?
//   const quizHash = {};
//   const correctOutcomes = [];

//   if (!quizzes || (quizzes && Array.isArray(quizzes) && quizzes.length === 0)) {
//     // No Quizzes, no need to eval
//     console.log('classQuizOutcomesEval: no quizzes', { quizzes, quizOutcomes });
//     return true;
//   }

//   if (quizzes.length && quizOutcomes && quizOutcomes.length) {
//     quizOutcomes.forEach((outcome) => {
//       if (isPublished(outcome)) {
//         const {
//           id: outcomeId, score, maxScore, quizId,
//         } = outcome;

//         // Quiz key doesn't exist, create key
//         if (quizHash[quizId] === undefined) quizHash[quizId] = [];

//         if (quizAccepted({ score, maxScore })) {
//           quizHash[quizId].push(outcomeId);
//         }
//       }
//     });

//     quizzes.forEach((quiz) => {
//       if (quizHash[quiz.sys.id] && quizHash[quiz.sys.id].length > 0) {
//         correctOutcomes.push([quiz.sys.id, quizHash[quiz.sys.id][0]]); // [quizId, quizOutcome (approved)]
//       }
//     });

//     // Evaluate total topic quizzes against number of accepted QuizOutcomes
//     // left side of expression can be greater than if a user had submitted more than one accepted QuizOutcome
//     if ((correctOutcomes.length) >= quizzes.length) {
//       // Add Topic to user Completed Topics
//       console.log('classQuizOutcomesEval: approved', { quizzes, quizOutcomes });
//       return true;
//     }
//     // Still more Quizzes that need accepted QuizOutcomes
//     console.log('classQuizOutcomesEval: more quizOutcomes needed', { quizzes, quizOutcomes });
//     return false;
//   }

//   // No Quizzes, no need to eval
//   console.log('classQuizOutcomesEval: edge case: false', { quizzes, quizOutcomes });

//   return false;
// };
