import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import ChooseApprovalOccupationTemplate from '../../ChooseApprovalOccupationTemplate';
import { SET_APPRENTICESHIP } from '../../../actions/types';
import SearchOccupationsForm from '../../SearchOccupationForm';

const ChangeOccupationModal = () => {
  const dispatch = useDispatch();
  const MODALSTEPS = {
    CREATE_TRAINING_PROGRAM: 'CREATETRAININGPROGRAM',
    CHOOSE_APPROVAL_OCCUPATION: 'CHOOSEAPPROVALOCCUPATION'
  };

  const [step, setStep] = useState(MODALSTEPS.CREATE_TRAINING_PROGRAM);
  const [occupation, setOccupation] = useState('');

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const renderStepContent = () => {
    switch (step) {
      case MODALSTEPS.CREATE_TRAINING_PROGRAM:
        return (
          <SearchOccupationsForm
            title="Change Occupation"
            handleNextClick={() => {
              setStep(MODALSTEPS.CHOOSE_APPROVAL_OCCUPATION);
            }}
            occupation={occupation}
            setOccupation={setOccupation}
          />
        );
      case MODALSTEPS.CHOOSE_APPROVAL_OCCUPATION:
        return (
          <div className="card">
            <div className="card-header">
              <h1 className="h5 m-0 text-dark">Change Occupation</h1>
            </div>
            <div className="card-body p-0">
              <ChooseApprovalOccupationTemplate
                handleBackPress={() => {
                  setStep(MODALSTEPS.CREATE_TRAINING_PROGRAM);
                }}
                onItemClick={(selectedOccupation) => {
                  dispatch({
                    type: SET_APPRENTICESHIP,
                    apprenticeship: {
                      title: selectedOccupation.OnetTitle,
                      occupationName: selectedOccupation.OnetTitle,
                      occupationONetCode: selectedOccupation.OnetCode,
                      occupationONetDescription:
                        selectedOccupation.OccupationDescription
                    }
                  });
                  handleClose();
                }}
                occupation={occupation}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      visible
      close={handleClose}
      pos="middle"
      size="large"
    >
      <div>{renderStepContent()}</div>
    </Modal>
  );
};
export default ChangeOccupationModal;
