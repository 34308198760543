import sysState from './sysState';

const challengeState = {
  title: '',
  challengeType: '',
  submissionPromptText: '',
  activeChallenge: false,
  enableNewEditor: false,
  difficulty: '', // TODO should be a number
  question: '',
  solution: '',
  instructorNotes: '',
  topicId: '',
  classId: '',
  orgId: '',
  challengeSkills: null, // string[]
  ...sysState
};

export default challengeState;
