const removeStringFromArray = (arr, str) => {
  const arrCopy = arr.slice(0);
  const strIndex = arrCopy.indexOf(str);

  if (strIndex !== -1) {
    arrCopy.splice(strIndex, 1);
  }

  return arrCopy;
};

export default removeStringFromArray;
