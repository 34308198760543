import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { useHistory } from 'react-router-dom';
import { routeWithOrgId } from '../../../services/organizations';
import { ORG_CONSOLE_INSTRUCTION_PROVIDERS } from '../../../constants/routes';
import Loading from '../../Loading';
import List from './List';
import PaginationFirebase from '../../ManageContent/PaginationFirebase';
import { getInstructionProviders } from '../../../actions/InstructionProviders';

const InstructionProvidersList = ({
  instructionProviderIds,
  onClickRow
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const instructionProviders = useSelector((state) => state.instructionProviders);
  // misc
  const orgId = pathOr(null, ['id'], organization);
  const searchParams = new URLSearchParams(window.location.search);
  // Page
  const pageParam = searchParams.get('page');
  const page = parseInt(pageParam, 10) || 1;
  // After
  const afterParam = searchParams.get('after');
  const after = afterParam || '';
  // Before
  const beforeParam = searchParams.get('before');
  const before = beforeParam || '';

  useEffect(() => {
    if (orgId) {
      const config = {
        orgId,
        limit: instructionProviders?.limit,
        page,
        ...(after ? { after } : {}),
        ...(before ? { before } : {})
      };

      if (instructionProviderIds) {
        config.instructionProviderIds = instructionProviderIds;
      }

      dispatch(getInstructionProviders(config));
    }
  }, [page]);

  if (!instructionProviders.fetched && !instructionProviders.list) {
    return (
      <Loading text="Loading Instruction Providers..." />
    );
  }

  return (
    <div className="instructionProviders-list">
      <List
        data={instructionProviders.list}
        pagination={instructionProviders.pagination}
        onClickRow={onClickRow}
      />
      <PaginationFirebase
        items={instructionProviders?.list}
        limit={instructionProviders?.limit}
        pagination={instructionProviders?.pagination}
        total={instructionProviders?.total}
        page={page}
        onClickNext={() => {
          const config = {
            route: ORG_CONSOLE_INSTRUCTION_PROVIDERS,
            urlParams: {
              orgId,
              page: page + 1
            }
          };

          if (instructionProviders?.pagination?.next) {
            config.urlParams.after = instructionProviders?.pagination.next;
          }

          const route = routeWithOrgId(config);
          history.push(route);
          // goToElem('root');
        }}
        onClickPrev={() => {
          const config = {
            route: ORG_CONSOLE_INSTRUCTION_PROVIDERS,
            urlParams: {
              orgId,
              page: page - 1
            }
          };

          if (config.urlParams.page === 1) {
            config.urlParams.before = instructionProviders?.pagination.prev;
          }

          const route = routeWithOrgId(config);
          history.push(route);
          // goToElem('root');
        }}
      />
    </div>
  );
};

export default InstructionProvidersList;
