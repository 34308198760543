import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import { routeWithOrgId } from '../../../../../services/organizations';
import {
  canAccessProgramInvites,
  canAccessProgramTrainers
} from '../../../../../permissions/access';

const UsersTabs = ({ active, className = 'mt-3' }) => {
  const params = useParams();
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  // Current User
  const role = pathOr([], ['role'], currentUser);
  // Organization
  const orgId = pathOr('', ['id'], organization);
  // Training Program
  const apprenticeshipId = pathOr('', ['apprenticeshipId'], params);
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item">
          <Link
            className={`nav-link ${active === 'trainees' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: `${apprenticeshipRoute}/users`,
              orgId
            })}
            title="Trainees"
          >
            Trainees
          </Link>
        </li>

        {canAccessProgramTrainers(role) && (
          <li className="nav-item ml-2">
            <Link
              className={`nav-link ${active === 'trainers' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/trainers`,
                orgId
              })}
              title="Mentors"
            >
              Mentors
            </Link>
          </li>
        )}

        {canAccessProgramInvites(role) && (
          <li className="nav-item ml-2">
            <Link
              className={`nav-link  ${active === 'invites' ? 'active' : ''}`}
              to={routeWithOrgId({
                route: `${apprenticeshipRoute}/invites`,
                orgId
              })}
              title="Invitations"
            >
              Invitations
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default UsersTabs;
