import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';
import { FaSortAmountUp } from 'react-icons/fa';
import { withAuthorization } from '../../../../Session';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../constants/routes';
import {
  getApprenticeship,
  getApprenticeshipUser,
  resetApprenticeship,
  resetApprenticeshipUser
} from '../../../../../actions/Apprenticeships';
import withOrgConsole from '../../../../App/withOrgConsole';
import OrgConsoleHeader from '../../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../../OrgConsoleSubheader';
import Tabs from '../Tabs';
import DotMenu from './DotMenu';
import Loading from '../../../../Loading';
import LogbookBlock from './LogbookBlock';
import UserTabs from './UserTabs';
import { resetLogbook } from '../../../../../actions/Logbooks';

const ApprenticeshipUserLogbook = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const organization = useSelector((state) => state.organization);
  // Organization
  const orgId = organization?.id || null;
  // Training Program User
  const apprenticeshipId = params?.apprenticeshipId || null;
  const apprenticeshipUserId = params?.apprenticeshipUserId || null;
  const apprenticeshipUser = apprenticeship?.user || null;
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }
  }, [apprenticeshipId, dispatch]);

  useEffect(() => {
    if (apprenticeshipUserId) {
      dispatch(getApprenticeshipUser(apprenticeshipUserId, apprenticeshipId, orgId));
    }
  }, [apprenticeshipId, apprenticeshipUserId, dispatch, orgId]);

  useEffect(() => {
    return function cleanup() {
      const { href } = window.location;

      // Only reset if not going to apprentice user route
      if (
        !href.includes(`${apprenticeshipRoute}/users/${apprenticeshipUserId}`)
      ) {
        dispatch(resetApprenticeshipUser());
        dispatch(resetLogbook());
      }

      // Only reset if not going to apprentice route
      if (!href.includes(apprenticeshipRoute)) {
        dispatch(resetApprenticeship());
      }
    };
  }, [apprenticeshipRoute, apprenticeshipUserId, dispatch]);

  if (!apprenticeship.id) {
    return <Loading text="Loading Training Program" />;
  }

  if (!apprenticeship.user) {
    return <Loading text="Loading Training Program User" />;
  }

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle={apprenticeship.title}
            iconComponent={() => <FaSortAmountUp size={20} />}
            // route={ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}
            className="mb-3"
          />

          <Tabs active="users" />

          <div className="bg-white shadow p-3 overflow-content border-left border-right border-bottom">
            <OrgConsoleSubheader
              pageTitle={apprenticeshipUser.name}
              route={`${apprenticeshipRoute}/users`}
            >
              <DotMenu />
            </OrgConsoleSubheader>

            <UserTabs active="logbook" />

            <LogbookBlock className="border-left border-right border-bottom" />
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(ApprenticeshipUserLogbook);
