import sysState from './sysState';

const billingAgreementState = {
  title: '',
  body: '',
  accepted: false,
  acceptedDate: '',
  acceptedName: '',
  acceptedEmail: '',
  userId: '',
  orgId: '',
  ...sysState
};

export default billingAgreementState;
