import React from 'react';
import './style.css';
import { FaRegObjectGroup, FaTable } from 'react-icons/fa';

const displayTypes = {
  TABLE: {
    title: 'List',
    icon: <FaTable size={15} />
  },
  CARD: {
    title: 'Cards',
    icon: <FaRegObjectGroup size={15} />
  }
};

const ListDisplayType = ({
  onChange,
  activeType = 'list'
}) => {
  const displayTypesArray = Object.entries(displayTypes).map(([key, displayType]) => ({
    type: key,
    ...displayType
  }));

  return (
    <div className="dropdown">
      <button
        data-cy="displayTypesBtn"
        className="displayItemType d-flex align-items-center border p-2 rounded"
        type="button"
        id="displayItemTypeDropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {displayTypes[activeType].icon}

        <span className="ml-2 d-none d-md-block">
          {displayTypes[activeType].title}
        </span>
      </button>

      <div
        className="displayItemTypeDropdown dropdown-menu dropdown-menu-right p-0"
        aria-labelledby="displayItemTypeDropdownMenuButton"
      >
        {displayTypesArray.map(({ title, type, icon }, i) => (
          <button
            key={`${type}-${i}`}
            title={title}
            type="button"
            className={`dropdown-item displayItemType py-2 align-items-center ${activeType === type ? 'd-none' : 'd-flex'}`}
            onClick={() => onChange(type)}
          >
            {icon}
            <span className="ml-2">
              {title}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ListDisplayType;
