import React from 'react';
import { FaEllipsisH, FaExternalLinkAlt } from 'react-icons/fa';
import { previewLink } from '../../../services/pathways';
import KeyValueItem from '../../KeyValueItem';

const EditCell = ({ value: id, row }) => {
  const { original: { OnetTitle } } = row;

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
        >
          <FaEllipsisH />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark px-4 py-2 text-white">
            <h6
              className="m-0 text-ellipsis"
            >
              {OnetTitle}
            </h6>
          </div>
          {/* <button
            type="button"
            className="dropdown-item py-2 border-bottom"
            onClick={() => {
              openManageJobProfileModal(id);
            }}
          >
            Edit
          </button> */}
          <a
            className="dropdown-item py-2"
            href={previewLink(id)}
            target="_blank"
            rel="noopener noreferrer"
            title="View in new tab"
          >
            <span className='d-flex align-items-center'>
              <span className='mr-2'>
                Preview
              </span>
              <FaExternalLinkAlt size={10} />
            </span>
          </a>
          <div className='card-footer py-1 d-flex align-items-center'>
            <small className='text-nowrap'>
              <KeyValueItem value={id} title="ID" />
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCell;
