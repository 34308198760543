import sysState from './sysState';

const quizOutcomeState = {
  attempts: 0,
  class: null, // TODO REMOVE (ref)
  classId: '',
  difficulty: 0,
  evaluation: null, // []
  maxScore: 0,
  orgId: '',
  organization: null, // TODO REMOVE (ref)
  questions: null, // []
  quizId: '',
  rubric: null, // []
  score: 0,
  title: '',
  topic: null, // TODO REMOVE (ref)
  topicId: '',
  user: null, // TODO REMOVE (ref)
  userId: '',
  userName: '',
  userAnswers: null, // []
  integration: null, //
  ...sysState
};

export default quizOutcomeState;
