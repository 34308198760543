import { TURBINE_LOGO } from '@apprentage/constants';
import { PLATFORM_NAME } from '../../constants/globals';

const OrgLogo = ({
  orgName = PLATFORM_NAME,
  orgLogo = TURBINE_LOGO
}) => `
  <table
    align="center"
    border="0"
    cellpadding="0"
    cellspacing="0"
    class="heading_block"
    role="presentation"
    style="mso-table-lspace: 0; mso-table-rspace: 0;"
    width="100%"
  >
    <tbody>
      <tr>
        <td
          style="width:100%;text-align:center;padding-top:25px;padding-bottom:10px;padding-left:10px;padding-bottom:10px;"
        >
          <img
            src="${orgLogo}"
            style="display: block; height: auto; border: 0; width: 125px; max-width: 100%;"
            width="125"
            alt="${orgName}"
          />
        </td>
      </tr>
    </tbody>
  </table>
`;

export default OrgLogo;
