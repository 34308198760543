import React from 'react';
import IndeterminateCheckbox from '../../../ManageContent/Tables/IndeterminateCheckBox';

export default function IndeterminateCheckboxCell({ row }) {
  const disabled = row?.original?.isApproved;

  return (
    <div>
      <IndeterminateCheckbox
        {...row.getToggleRowSelectedProps()}
        disabled={disabled}
        style={{
          cursor: disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        }}
      />
    </div>
  );
}
