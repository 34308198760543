import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import './style.css';

const FullScreenIframe = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  //  Current Modal
  const modalTitle = currentModal?.data?.modalTitle || '';
  const iframeTitle = currentModal?.data?.iframeTitle || modalTitle;
  const iframeSrc = currentModal?.data?.iframeSrc || '';

  if (!currentModal?.visible) {
    return;
  }

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={handleClose}
      theme="dark"
      full
    >
      <div className="card">
        <div className="card-header bg-dark">
          <h1 className="h5 m-0 text-white">
            {modalTitle}
          </h1>
        </div>
        <div className="card-body p-0">
          <iframe
            title={iframeTitle}
            src={iframeSrc}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FullScreenIframe;
