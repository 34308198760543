import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentModal } from '../../../../actions/Modals';
import { DASHBOARD_MODULE_TYPE, DASHBOARD_MODULES } from '../../../../constants/dashboard';
import { MODULES_PAGINATION_LIMIT } from '../../../../constants/api';
import { getProjects } from '../../../../actions/Projects';
import { fetchWidgets } from '../../../../services/widgets';
import { modulesTypesHash } from '../constants';
import Modal from '../../../Modal';
import SelectModulesData from './SelectModulesData';
import NotificationConfig from './NotificationsConfig';

const ConfigModuleModal = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const organization = useSelector((state) => state.organization);
  const projects = useSelector((state) => state.projects);
  // Organization
  const orgId = organization.id || '';
  // Current Modal
  const {
    moduleType,
    showOnlyFeatured,
    selectedIds = [],
    notificationsLimit = 0,
    showOnlyPinnedNotifications = false
  } = currentModal?.data;
  const initialListConfig = {
    searchValue: '',
    limit: MODULES_PAGINATION_LIMIT,
    page: 1
  };

  // Local State
  const [config, setConfig] = useState(initialListConfig);

  const [moduleSettings, setModuleSettings] = useState({
    showOnlyFeatured,
    selectedIds,
    showOnlyPinned: showOnlyPinnedNotifications,
    limit: notificationsLimit
  });

  const [tableData, setTableData] = useState({
    list: [],
    fetched: false,
    pagination: null
  });

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  const handleSubmitConfig = () => {
    currentModal?.callbackPrimaryAction({ ...moduleSettings });
    handleClose();
  };

  const getProjectsData = useCallback(() => {
    dispatch(
      getProjects({
        orgId,
        page: config.page,
        limit: config.limit,
        title: config.searchValue
      })
    );
  }, [config.limit, config.page, config.searchValue, dispatch, orgId]);

  const handleOnClickRow = (rowData) => {
    if (isSingleSelect) {
      moduleSettings.selectedIds = [rowData.id];
      setTableData((prevData) => {
        const selData = prevData?.list.map((item) => {
          if (item.id === rowData.id) {
            return {
              ...item,
              selected: true
            };
          }
          return {
            ...item,
            selected: false
          };
        });

        return {
          ...prevData,
          list: selData
        };
      });
    } else {
      // multiSelect
      setTableData((prevData) => {
        const selData = prevData?.list?.map((item) => {
          if (item.id === rowData.id) {
            const clickedRowIndex = moduleSettings.selectedIds.indexOf(
              rowData.id
            );

            if (clickedRowIndex >= 0) {
              setModuleSettings((prevState) => {
                const data = prevState.selectedIds.filter((id) => id !== rowData.id);

                return {
                  ...prevState,
                  selectedIds: data
                };
              });
            } else {
              setModuleSettings((prevState) => {
                prevState?.selectedIds?.push(rowData.id);
                return {
                  ...prevState
                };
              });
            }
            return {
              ...item,
              selected: !rowData.selected
            };
          }
          return item;
        });

        return {
          ...prevData,
          list: selData
        };
      });
    }
  };

  const selectSpecificModuleData = useMemo(() => {
    return [DASHBOARD_MODULE_TYPE.projects, DASHBOARD_MODULE_TYPE.links, DASHBOARD_MODULE_TYPE.embeds].includes(moduleType);
  }, [moduleType]);

  const isSingleSelect = useMemo(() => {
    return moduleType === DASHBOARD_MODULE_TYPE.projects || moduleType === DASHBOARD_MODULE_TYPE.embeds;
  }, [moduleType]);

  useEffect(() => {
    if (moduleType === DASHBOARD_MODULE_TYPE.projects) {
      /**
       * PROJECTS
       */
      getProjectsData();
    } else if (moduleType === DASHBOARD_MODULE_TYPE.links) {
      /**
       * LINKS
       */
      fetchWidgets({
        orgId,
        type: 'link',
        page: config.page,
        limit: config.limit,
        title: config.searchValue
      }).then((linksResponse) => {
        const newLinkList = Array.isArray(linksResponse?.list) ? linksResponse?.list?.map((link) => ({
          id: link.id,
          title: link.title,
          image: link.image || null,
          body: link.body || null,
          selected: !!moduleSettings?.selectedIds?.find((id) => link.id === id),
          moduleType: DASHBOARD_MODULE_TYPE.links
        })) : [];

        setTableData((prevState) => ({
          ...prevState,
          list: newLinkList,
          pagination: linksResponse.pagination
        }));
      });
    } else if (moduleType === DASHBOARD_MODULE_TYPE.embeds) {
      /**
       * EMBEDS
       */
      fetchWidgets({
        orgId,
        type: 'embed',
        page: config.page,
        limit: config.limit,
        title: config.searchValue
      }).then((embedsResponse) => {
        const newEmbedList = Array.isArray(embedsResponse?.list) ? embedsResponse?.list?.map((embed) => ({
          id: embed.id,
          title: embed.title,
          selected: !!moduleSettings?.selectedIds?.find((id) => embed.id === id),
          moduleType: DASHBOARD_MODULE_TYPE.embeds
        })) : [];

        setTableData((prevState) => ({
          ...prevState,
          list: newEmbedList,
          pagination: embedsResponse?.pagination || null
        }));
      });
    }
  }, [config, getProjectsData, moduleSettings?.selectedIds, moduleType, orgId]);

  useEffect(() => {
    /**
     * PROJECTS
     */
    if (moduleType === DASHBOARD_MODULE_TYPE.projects && projects) {
      const newProjectList = Array.isArray(projects?.list) ? projects?.list?.map((project) => ({
        id: project.id,
        title: project.title,
        selected: !!moduleSettings?.selectedIds?.find((id) => project.id === id),
        moduleType: DASHBOARD_MODULE_TYPE.projects
      })) : [];

      setTableData({
        ...projects,
        list: newProjectList
      });
    }
  }, [moduleSettings?.selectedIds, moduleType, projects]);

  if (!currentModal?.visible) {
    return null;
  }

  return (
    <Modal
      visible={currentModal?.visible}
      close={handleClose}
      cssClassName={`turbine-modal--style-fullscreen turbine-modal--${currentModal?.key}`}
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark text-white">
          <h5 className='m-0 d-flex align-items-center'>
            {modulesTypesHash[moduleType].icon}

            <span className='ml-2'>
              {DASHBOARD_MODULES[moduleType]} Module
            </span>
            <span className='badge badge-secondary ml-2'>
              Settings
            </span>
          </h5>
        </div>

        {selectSpecificModuleData && (
          <SelectModulesData
            // title={`Select ${DASHBOARD_MODULES[moduleType]}`}
            tableData={tableData}
            setDataConfig={setConfig}
            handleOnClickRow={handleOnClickRow}
            handleSubmitConfig={handleSubmitConfig}
            handleClose={handleClose}
          />
        )}

        {moduleType === DASHBOARD_MODULE_TYPE.notifications && (
          <NotificationConfig
            notificationConfig={moduleSettings}
            setNotificationConfig={setModuleSettings}
            handleSubmitConfig={handleSubmitConfig}
            handleClose={handleClose}
          />
        )}
      </div>
    </Modal>
  );
};

export default ConfigModuleModal;
