import React, { useCallback, useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { fetchUsers } from '../../../../../../services/users';
import { routeWithOrgId } from '../../../../../../services/organizations';
import { canInviteProgramUsers } from '../../../../../../permissions/invite';
import { ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS } from '../../../../../../constants/routes';
import { fetchLogbookRecords } from '../../../../../../services/logbookRecords';
import './styles.css';

const NextActions = () => {
  const history = useHistory();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const organization = useSelector((state) => state.organization);
  // Params
  const apprenticeshipId = params?.apprenticeshipId || null;
  // Current User
  const role = currentUser?.role || [];
  // Organization
  const orgId = organization?.id || '';
  // Apprenticeship
  const employerIds = apprenticeship?.employerIds || [];
  const instructionProviderIds = apprenticeship?.instructionProviderIds || [];
  // Local State
  const [trainee, setTrainee] = useState([]);
  const [mentor, setMentor] = useState([]);
  const [unapprovedRecords, setUnapprovedRecords] = useState(0);
  // Routing
  const apprenticeshipRoute = `${ORG_CONSOLE_WORKFORCE_APPRENTICESHIPS}/${apprenticeshipId}`;

  const requestLogbookRecords = useCallback(async () => {
    fetchLogbookRecords({
      orgId,
      locationId: currentUser?.locationId,
      isApproved: null,
      count: true
    }).then((response) => {
      setUnapprovedRecords(response?.total);
    });
  }, []);

  const getTrainees = useCallback(async () => {
    try {
      const response = await fetchUsers({
        orgId,
        roles: ['student'],
        apprenticeshipId,
        limit: 10
      });
      if (response.length) {
        setTrainee(response);
      } else {
        setTrainee([]);
      }
    } catch (error) {
      setTrainee([]);
      console.error(error);
    }
  }, [apprenticeshipId, orgId]);

  const getMentors = useCallback(async () => {
    try {
      const response = await fetchUsers({
        orgId,
        roles: ['teacher'],
        apprenticeshipId,
        limit: 10
      });
      if (response.length) {
        setMentor(response);
      } else {
        setMentor([]);
      }
    } catch (error) {
      setMentor([]);
      console.error(error);
    }
  }, [apprenticeshipId, orgId]);

  const showSubmitRequestButton = (...checked) => {
    return checked.every((item) => item === true);
  };

  const handleSubmitRequestButton = (routeUrl) => {
    const newGenRoute = routeWithOrgId({
      route: routeUrl,
      orgId
    });

    history.push(newGenRoute);
  };

  useEffect(() => {
    getTrainees();
    getMentors();
    requestLogbookRecords();
  }, [getMentors, getTrainees, requestLogbookRecords]);

  return (
    <div
      className="Apprenticeship-Dashboard-NextActions shadow p-4 pt-2 rounded h-100 border"
    >
      <div className="h4">Next Best Actions</div>
      <div className="font-weight-bold">
        Get the most out of Turbine by completing a few more onboarding steps
      </div>
      <hr className="my-1" />
      <div className="d-flex flex-column justify-content-between">
        {!employerIds.length && (
          <Action
            isCompleted={employerIds.length}
            actionText="Add Employers"
            showSubmitRequestButton={showSubmitRequestButton(
              employerIds.length <= 0
            )}
            onSubmitRequestClick={() => {
              handleSubmitRequestButton(`${apprenticeshipRoute}/employers`);
            }}
          />
        )}
        {!(employerIds.length && instructionProviderIds.length) && (
          <Action
            actionText="Add Instruction Providers"
            isCompleted={employerIds.length && instructionProviderIds.length}
            showSubmitRequestButton={showSubmitRequestButton(
              employerIds.length > 0,
              instructionProviderIds.length <= 0
            )}
            onSubmitRequestClick={() => {
              handleSubmitRequestButton(
                `${apprenticeshipRoute}/instruction-providers`
              );
            }}
          />
        )}

        {!canInviteProgramUsers(role) && (
          <>
            <Action
              actionText="Review Records"
              buttonText='Review'
              isCompleted={unapprovedRecords.length === 0}
              showSubmitRequestButton={showSubmitRequestButton(
                employerIds.length > 0,
                instructionProviderIds.length > 0,
                trainee.length <= 0
              )}
              onSubmitRequestClick={() => {
                handleSubmitRequestButton(`${apprenticeshipRoute}/users`);
              }}
            />
          </>
        )}

        {canInviteProgramUsers(role) && (
          <>
            {!(employerIds.length && instructionProviderIds.length && trainee.length) && (
              <Action
                actionText="Invite Trainees"
                isCompleted={
                  employerIds.length
                  && instructionProviderIds.length
                  && trainee.length
                }
                showSubmitRequestButton={showSubmitRequestButton(
                  employerIds.length > 0,
                  instructionProviderIds.length > 0,
                  trainee.length <= 0
                )}
                onSubmitRequestClick={() => {
                  handleSubmitRequestButton(`${apprenticeshipRoute}/users`);
                }}
              />
            )}

            {!(employerIds.length && instructionProviderIds.length && mentor.length) && (
              <Action
                actionText="Invite Mentors"
                isCompleted={
                  employerIds.length
                  && instructionProviderIds.length
                  && trainee.length
                  && mentor.length
                }
                showSubmitRequestButton={showSubmitRequestButton(
                  employerIds.length > 0,
                  instructionProviderIds.length > 0,
                  mentor.length <= 0
                )}
                onSubmitRequestClick={() => {
                  handleSubmitRequestButton(`${apprenticeshipRoute}/trainers`);
                }}
              />
            )}
          </>
        )}

        {!!(employerIds.length && instructionProviderIds.length && trainee.length && mentor.length) && (
          <div className="h3 font-weight-bold text-center my-4">
            You are all caught up.
          </div>
        )}
      </div>
    </div>
  );
};

const Action = ({
  isCompleted = false,
  actionText = '',
  buttonText = 'Invite',
  showSubmitRequestButton = false,
  onSubmitRequestClick
}) => {
  return (
    <div className="d-flex flex-row justify-content-between border-bottom border-1 py-2 ">
      <div className="d-flex flex-row align-items-center">
        <div>
          <input
            className="rounded-checkbox-input"
            type="checkbox"
            defaultChecked={isCompleted}
            readOnly
          />
        </div>
        <div className="m-0 p-0 h6 ml-2">{actionText}</div>
      </div>
      {showSubmitRequestButton && (
        <div>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={onSubmitRequestClick}
          >
            <span className='d-flex align-items-center'>
              <span className='mr-1'>
                {buttonText}
              </span>
              <FaAngleRight
                className="d-inline-block"
                color="white"
              />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default NextActions;
