import React from 'react';
import { toast } from 'react-toastify';
import { ICON_TRASH, ICON_TRIANGLE_BANG } from '@apprentage/constants';
import { FaTrashAlt } from 'react-icons/fa';
import Alert from '../../Alert';

const DeleteContent = ({
  title = '',
  contentType = '',
  buttonText = '',
  showDeleteButton = false,
  handleDisableDelete,
  handleDelete,
  children,
  loading = false,
  className = 'mb-3'
}) => {
  const confirmDelete = () => {
    if (handleDisableDelete && typeof handleDisableDelete === 'function') {
      handleDisableDelete();
      return;
    }

    const stringCheck = 'permanently delete';
    const confirmString = prompt(`Permanently delete ${title || contentType}?\n\nType "permanently delete" in the text field and click OK.`);

    if (confirmString === null || confirmString === '') {
      // No action
      // Cancel or empty field
    } else if (confirmString !== stringCheck) {
      toast.info('We found a typo, try again if you wish to delete.', { autoClose: false });
    } else {
      // Success
      // entered stringCheck value and clicked OK
      handleDelete();
    }
  };

  return (
    <div className={className}>
      <div className="d-flex justify-content-end mb-1">
        <button
          data-cy="Resource-DeleteContentToggleBtn"
          className="btn-link text-capitalize"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-deleteContentHowTo"
          aria-expanded="false"
          aria-controls="collapse-deleteContentHowTo"
        >
          <span className='d-flex align-items-center'>
            <FaTrashAlt className='text-ships-officer mr-1' />
            <span>
              {buttonText || `Delete ${contentType}`}
            </span>
          </span>
        </button>
      </div>
      <div className="collapse" id="collapse-deleteContentHowTo">
        <Alert
          type=""
          icon={`${ICON_TRIANGLE_BANG} text-danger`}
          className="m-0 mb-5 w-100 border"
        >
          <div>
            <h6 className="pb-2 border-bottom">
              How to delete <i className="font-weight-bold">{title}</i>:
            </h6>

            {children}
            <hr />
            <button
              data-cy="DeleteContent-PermanentlyDeleteBtn"
              className={`btn btn-sm btn-${showDeleteButton ? 'danger' : 'outline-secondary'}`}
              onClick={() => {
                confirmDelete();
                // setShowDialogConfirmRemoveItem(true);
              }}
              title="Permanently Delete"
              type="button"
              disabled={!showDeleteButton || loading}
            >
              <i className={`${ICON_TRASH} text-${showDeleteButton ? 'white' : 'danger'} pr-1`} /> Permanently Delete
            </button>
          </div>
        </Alert>
      </div>
    </div>
  );
};

export default DeleteContent;
