import sysState from './sysState';

const organizationState = {
  enableUserDirectory: false,
  enableProjects: false,
  enablePathways: false,
  enableNewEditor: false,
  enableWorkforceSuite: false,
  enableVelaMaterials: false,
  enableCourseCatalog: false,
  enableUserGroups: false,
  enableResources: false,
  restrictRegister: false,
  integrateSlack: false,
  description: null,
  billing: false, // User billing
  dashboard: false, // Organization Dashboard
  integrateMicrosoftTeams: false,
  integrateStripe: false,
  name: null,
  orgLogo: null,
  orgIndustry: null,
  type: null,
  email: null,
  welcomeMessage: null,
  dashboardWidgetIds: null,
  courseGroupIds: null,
  resourceGroupIds: null,
  userGroupIds: null,
  locations: null, // [{}] ref TODO remove
  locationIds: null, // string[]
  includedBenefits: null, // references[] // TODO remove
  url: null,
  slug: null,
  tos: null,
  privacyPolicy: null,
  externalRegisterUrl: null,
  slackToken: null,
  slackTeamId: null,
  slackJoinLink: null,
  integration: null, // {} TODO remove from state, set specific keys
  groupTags: null,
  agreementId: null,
  orgOwnerIds: null, // TODO remove - appears to be unused
  orgAdminIds: null, // TODO remove - appears to be unused
  orgEditorIds: null, // TODO remove - appears to be unused
  appleTouchIcon: null,
  androidIconSmall: null,
  androidIconLarge: null,
  ...sysState
};

export default organizationState;
