import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import Table from './Table';
import UserCell from './UserCell';
import QuizCell from './QuizCell';
import ScoreCell from './ScoreCell';
// import TotalPointsCell from './TotalPointsCell';
// import AttemptsCell from './AttemptsCell';
// import DifficultyCell from './DifficultyCell';
import PercentageCell from './PercentageCell';
import DateCell from '../ManageContent/Tables/DateCell';
import ReviewCheckboxCell from '../ManageContent/Tables/ReviewCheckboxCell';
import OutcomeCell from './OutcomeCell';

const QuizOutcomesList = ({
  data,
  userId,
  quizId,
  reviewActive = false,
  showUserNameAsLink = false,
  showQuizTitleAsLink = false,
  showOutcomeButton = false
}) => {
  // redux
  const course = useSelector((state) => state.course);
  const currentQuiz = useSelector((state) => state.currentQuiz);
  // misc
  const classId = pathOr(null, ['id'], course);
  const currentQuizId = pathOr(null, ['id'], currentQuiz);

  const hiddenColumns = useMemo(() => {
    const list = [];

    if (currentQuizId) {
      list.push('quizId');
    }

    if (!reviewActive) {
      list.push('attempts');
    }

    if (!showOutcomeButton) {
      list.push('id');
    }

    return list;
  }, [currentQuizId]);

  const columns = React.useMemo(() => [
    {
      Header: () => {
        return (<input type="checkbox" checked readOnly />);
      },
      accessor: 'attempts',
      disableSortBy: true,
      Cell: (props) => (
        <ReviewCheckboxCell {...props} />
      )
    },
    {
      Header: 'Submitted',
      accessor: 'createdAt',
      Cell: (props) => (
        <DateCell {...props} />
      )
    },
    {
      Header: 'Score',
      accessor: 'score',
      Cell: (props) => (
        <ScoreCell {...props} />
      )
    },
    {
      Header: '',
      accessor: 'updatedAt', // react-table hack to use random accessor so there are no dupes
      disableSortBy: true,
      Cell: (props) => (
        <PercentageCell {...props} />
      )
    },
    // {
    //   Header: 'Attempts',
    //   accessor: 'attempts',
    //   Cell: (props) => (
    //     <AttemptsCell {...props} />
    //   )
    // },
    // {
    //   Header: 'Difficulty',
    //   accessor: 'difficulty',
    //   disableSortBy: true,
    //   Cell: (props) => (
    //     <DifficultyCell {...props} />
    //   )
    // },
    // {
    //   Header: 'Total Points',
    //   accessor: 'maxScore',
    //   Cell: (props) => (
    //     <TotalPointsCell {...props} />
    //   )
    // },
    {
      Header: 'User',
      accessor: 'userName',
      Cell: (props) => (
        <UserCell
          {...props}
          classId={classId}
          quizId={quizId}
          showUserNameAsLink={showUserNameAsLink}
        />
      )
    },
    {
      Header: 'Quiz',
      accessor: 'quizId',
      disableSortBy: !!userId,
      Cell: (props) => (
        <QuizCell
          {...props}
          classId={classId}
          showQuizTitleAsLink={showQuizTitleAsLink}
        />
      )
    },
    {
      Header: '',
      accessor: 'id',
      disableSortBy: true,
      Cell: (props) => (
        <OutcomeCell {...props} />
      )
    }
  ], [classId, quizId, userId, showQuizTitleAsLink, showUserNameAsLink, showOutcomeButton]);

  if (!data || (data && data.length === 0)) {
    return (
      <div className="p-4">
        No Quiz Outcomes.
      </div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data}
      hiddenColumns={hiddenColumns}
    />
  );
};

export default QuizOutcomesList;
