import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import { FaLayerGroup, FaRegObjectGroup } from 'react-icons/fa';
import { getCourseGroup, resetCourseGroup } from '../../../../actions/Groups';
import { withAuthorization } from '../../../Session';
import {
  ORG_CONSOLE_COURSES, ORG_CONSOLE_COURSES_GROUPS, ORG_CONSOLE_DASHBOARD
} from '../../../../constants/routes';
import { routeWithOrgId } from '../../../../services/organizations';
import withOrgConsole from '../../../App/withOrgConsole';
import CoursesList from '../../../CoursesList';
import Loading from '../../../Loading';
import DotMenu from './DotMenu';
import Tabs from '../Tabs';
import OrgConsoleHeader from '../../OrgConsoleHeader';
import OrgConsoleSubheader from '../../OrgConsoleSubheader';

const CourseGroup = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  const courseGroup = useSelector((state) => state.courseGroup);
  // Misc
  const orgId = organization?.id || null;
  const groupId = params?.groupId || null;

  useEffect(() => {
    if ((groupId && !courseGroup.id) || (courseGroup.id !== groupId)) {
      dispatch(getCourseGroup({ groupId }));
    }
  }, [courseGroup.id, dispatch, groupId]);

  useEffect(() => {
    return function cleanup() {
      const href = window?.location?.href || '';
      const groupRoute = `org/courses/group/${groupId}`;

      // Only reset course group if not going to reorder or manage courses
      if (!href.includes(groupRoute)) {
        dispatch(resetCourseGroup());
      }
    };
  }, [dispatch, groupId]);

  if (groupId && !courseGroup.id) {
    return (
      <Loading text="Loading group..." />
    );
  }

  return (
    <div className="row">
      <div className="col">
        <div className="mb-5">
          <OrgConsoleHeader
            pageTitle="Courses"
            // icon={ICON_COURSE}
            iconComponent={() => <FaLayerGroup />}
            route={ORG_CONSOLE_DASHBOARD}
          />

          <Tabs active="groups" />

          <div
            className='bg-white border-left border-right border-bottom p-3'
          >

            <OrgConsoleSubheader
              pageTitle={courseGroup.title}
              badge={() => (
                <span className='badge bg-white border ml-2'>
                  {courses?.list?.length || 0}
                </span>
              )}
              iconComponent={() => <FaRegObjectGroup className='mr-2' />}
              route={ORG_CONSOLE_COURSES_GROUPS}
            >
              <DotMenu />
            </OrgConsoleSubheader>

            <div className='card mt-3 overflow-content'>
              {/* <div className='card-header d-flex align-items-center justify-content-between'>
                <h6 className='m-0 font-weight-bold'>
                  <i className={`${GROUP} mr-1`} /> {courseGroup.title}
                </h6>
                <div>
                  <DotMenu />
                </div>
              </div> */}

              {courseGroup.itemIds ? (
                <CoursesList
                  courseIds={courseGroup.itemIds}
                  showGroupsCol
                />
              ) : (
                <div className="py-5 d-flex justify-content-center flex-column">
                  <div className="mt-3 w-100 text-center">
                    <div className='mb-3'>
                      No Courses.
                    </div>

                    <Link
                      className="btn btn-primary btn-sm"
                      to={routeWithOrgId({
                        route: `${ORG_CONSOLE_COURSES}/group/${groupId}/manage`,
                        orgId
                      })}
                      title="Manage Courses"
                    >
                      Manage Courses
                    </Link>
                  </div>
                </div>
              )}
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

const condition = (user) => !!user.uid;

export default compose(
  withAuthorization(condition),
  withOrgConsole
)(CourseGroup);
