export default function isPublished(entry) {
  if (!entry) {
    return false;
  }

  if (entry.title || entry.name || entry.code) {
    return true;
  }

  // TODO remove after migration
  if (entry.fields) {
    if (entry.fields.title || entry.fields.name || entry.fields.code) {
      return true;
    }
  }

  return false;
}
