import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../actions/Users';
import Loading from '../Loading';
import List from './List';
import { canAccessAllApprenticeshipUsers } from '../../permissions/access';
import { getLogbooks } from '../../actions/Logbooks';
import { getCronUsers } from '../../actions/CronUsers';

const UsersList = ({
  apprenticeshipId,
  userIds = [],
  handleClickRow,
  roles = [],
  supervisorIds = [],
  hiddenColumns = [],
  showRole = false,
  noDataText = 'No Users.',
  excludeTestUsers = false,
  showLogbookRecords = false,
  excludeInactive = false
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const users = useSelector((state) => state.users);
  const logbooks = useSelector((state) => state.logbooks);
  const cronUsers = useSelector((state) => state.cronUsers);
  // Current User
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  const locationId = currentUser?.locationId || null;
  // Organization
  const orgId = organization?.id || null;

  const data = useMemo(() => {
    let results = [];

    if (showLogbookRecords && logbooks.list && users.list) {
      results = users.list.map((user) => {
        const userLogbook = logbooks.list.find((l) => l.userId === user.id);

        user.recordsCount = userLogbook?.recordsCount ? userLogbook?.recordsCount : 0;
        user.recordsHours = userLogbook?.recordsHours ? userLogbook?.recordsHours : 0;
        user.recordsHoursApproved = userLogbook?.recordsHoursApproved ? userLogbook?.recordsHoursApproved : 0;

        // TODO calculate Hours
        // user.logbookRecordsHours

        // TODO calculate Hours Approved
        // user.logbookRecordsHoursApproved

        return user;
      });
    } else {
      results = users.list;
    }

    return results;
  }, [showLogbookRecords, logbooks?.list, users?.list]);

  const showReminders = useMemo(() => {
    return !hiddenColumns || (Array.isArray(hiddenColumns) && !hiddenColumns.includes('remindersCell'));
  }, [hiddenColumns]);

  useEffect(() => {
    const usersConfig = {
      orgId,
      ...(apprenticeshipId ? { apprenticeshipId } : {}),
      ...(!userIds.length ? {} : { userIds }),
      ...(!roles.length ? {} : { roles }),
      ...(!supervisorIds.length ? {} : { supervisorIds }),
      ...(excludeTestUsers ? { excludeTestUsers } : {}),
      ...(excludeInactive ? { excludeInactive } : {})
    };
    const logbooksConfig = {
      orgId,
      ...(apprenticeshipId ? { apprenticeshipId } : {})
    };

    if (!canAccessAllApprenticeshipUsers(role)) {
      usersConfig.locationId = locationId;
      logbooksConfig.employerId = locationId;
    }

    if (orgId) {
      if (showLogbookRecords) {
        dispatch(getLogbooks(logbooksConfig));
      }

      // Email Reminders
      if (showReminders) {
        dispatch(getCronUsers({ orgId, apprenticeshipId, limit: 1000 }));
      }

      dispatch(getUsers(usersConfig));
    }
  }, [orgId, showReminders, showLogbookRecords, dispatch]);

  if (!users.fetched && !users.list) {
    return <Loading text="Loading Users..." />;
  }

  if (users.fetched && !users.list) {
    return <div className="p-4">{noDataText}</div>;
  }

  if (showLogbookRecords && !logbooks.fetched && logbooks.list) {
    return <Loading text="Loading Logbook Records..." />;
  }

  if (showReminders && !cronUsers.fetched && cronUsers.list) {
    return <Loading text="Loading Reminders..." />;
  }

  return (
    <div className="users-list">
      <List
        handleClickRow={handleClickRow}
        data={data}
        excludeInactive={excludeInactive}
        excludeTestUsers={excludeTestUsers}
        hiddenColumns={[
          ...hiddenColumns,
          ...(!showLogbookRecords ? ['recordsCount', 'recordsHours', 'recordsHoursApproved'] : [])
        ]}
        showRole={showRole}
      />
    </div>
  );
};

export default UsersList;
