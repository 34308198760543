import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';
import SupportEmailLine from '../SupportEmailLine';

const inviteUserSignApprenticeAgreementHtml = ({
  linkText = 'Continue',
  orgName,
  locationName,
  inviteLink,
  accessCode
}) => {
  return `
    <p>
      ${locationName} is participating in an training program with ${orgName}.
    </p>
    <p>
      You've been invited by ${orgName} to sign the ${locationName} Apprentice Agreement.
    </p>
    <p>
      The information you provide is needed for mandatory reporting to government agencies.
    </p>
    <p>
      To get started, click the ${linkText} button below.
    </p>

    <div
      align="center"
      style="line-height:10px; padding-top:5px; font-weight: bold;"
    >
      <p>Invitation Access Code</p>
    </div>

    <div
      align="center"
      style="padding-top:20px;padding-bottom:20px; background-color: #f9f9f9; border: 1px solid #f2f2f2;font-size:34px;font-weight:600;color:#8e44ad"
    >
      ${accessCode}
    </div>

    <div
      align="center"
      style="line-height:10px; padding-top:20px;padding-bottom:20px;"
    >
      ${ButtonPrimary({ text: linkText, href: inviteLink })}
    </div>

    ${SupportEmailLine()}

    <p>
      Team ${orgName}
    </p>

    ${Divider()}

    <p>
      "${linkText}" button not working?
    </p>

    <p>
      Copy and paste this link in your browser:
    </p>

    <p>
      ${inviteLink}
    </p>
  `;
};

export default inviteUserSignApprenticeAgreementHtml;
