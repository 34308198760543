import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import { jobProfileManageIframeUrl } from '../../../services/pathways';
import './style.css';

const ManageJobProfile = () => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.organization);
  const currentModal = useSelector((state) => state.currentModal);
  const orgId = pathOr(null, ['id'], organization);
  const visible = pathOr(null, ['visible'], currentModal);
  const key = pathOr(null, ['key'], currentModal);
  const jobProfileId = pathOr(false, ['data', 'jobProfileId'], currentModal);

  if (!visible) return null;

  const handleClose = () => {
    dispatch(resetCurrentModal());
    // TODO update job profiles list so the newest one is in the list
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={visible}
      close={handleClose}
      theme="dark"
      full
    >
      <div className="card">
        <div className="card-header bg-dark">
          <h1 className="h5 m-0 text-white">
            {jobProfileId ? 'Edit' : 'Create'} Job Profile
          </h1>
        </div>
        <div className="card-body p-0">
          <iframe
            title="Create or edit job profiles"
            src={jobProfileManageIframeUrl({ jobProfileId, orgId })}
          />
        </div>
        {/* <div className="card-footer">
          <button
            className="btn btn-primary btm-sm"
            type="submit"
            disabled={!!loading}
          >
            <i className="fas fa-paper-plane" /> {loading ? 'Sending...' : 'Send'}
          </button>
        </div> */}
      </div>
    </Modal>
  );
};

export default ManageJobProfile;
