import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ORG_CONSOLE_RESOURCES, ORG_CONSOLE_RESOURCES_TAGS
} from '../../../constants/routes';
import { routeWithOrgId } from '../../../services/organizations';

const Tabs = ({
  active,
  className = 'mt-3',
  children = null
}) => {
  const organization = useSelector((state) => state.organization);
  const orgId = organization?.id || null;

  return (
    <div className={`${className} position-relative`}>
      {children && (
        <div
          className='position-absolute'
          style={{
            right: 0,
            top: 0
          }}
        >
          {children}
        </div>
      )}

      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item">
          <Link
            className={`nav-link mr-2 ${active === 'all' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: ORG_CONSOLE_RESOURCES,
              orgId
            })}
            title="All Resources"
          >
            All
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link mr-2 ${active === 'tags' ? 'active' : ''}`}
            to={routeWithOrgId({
              route: ORG_CONSOLE_RESOURCES_TAGS,
              orgId
            })}
            title="All Tags"
          >
            Tags
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
