function formatAnswer({
  // IDS
  userId,
  orgId,
  classId,
  challengeId,
  topicId,
  // Fields
  status,
  solution, // TODO DEPRECATE - use form challenge
  feedback,
  results
}) {
  const data = {};

  if (status !== undefined) {
    data.status = {
      'en-US': status
    };
  }

  if (challengeId !== undefined) {
    data.challengeId = {
      'en-US': challengeId
    };
  }

  if (solution !== undefined) {
    data.solution = {
      'en-US': solution
    };
  }

  if (feedback !== undefined) {
    data.feedback = {
      'en-US': feedback
    };
  }

  if (results !== undefined) {
    data.results = {
      'en-US': results
    };
  }

  if (userId !== undefined) {
    data.userId = {
      'en-US': userId
    };
  }

  if (classId !== undefined) {
    data.classId = {
      'en-US': classId
    };
  }

  if (topicId !== undefined) {
    data.topicId = {
      'en-US': topicId
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  return { fields: { ...data } };
}

export default formatAnswer;
