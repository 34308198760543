import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withAuthorization } from '../../Session';
import { ACCOUNT } from '../../../constants/routes';
import convertUrlParamsToObject from '../../../utils/convertUrlParamsToObject';
import Loading from '../../Loading';

class Integrations extends Component {
  componentDidMount() {
    const {
      match: { params: { integration } },
      location: { search: locationSearch },
      history
    } = this.props;

    if (integration) {
      console.log('integration', integration);
    }

    if (locationSearch) {
      const searchParams = convertUrlParamsToObject(locationSearch);

      console.log('searchParams', searchParams);

      // do other things with turbine api and stripe data passed in search params

      // update user, idk..

      // then in callback, send user back to /accounts
      history.push(ACCOUNT);
    }
  }

  render() {
    const {
      match: { params: { integration } }
    } = this.props;

    return (
      <Loading text={`Integrating ${integration || ''}`} />
    );
  }
}

const condition = (user) => !!user.uid;

export default compose(
  withRouter,
  withAuthorization(condition)
)(Integrations);
