import React, { useState } from 'react';
import { FaChevronLeft, FaCloudUploadAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Showdown from 'showdown';
import SearchBox from '@apprentage/components/dist/components/SearchBox';
import formatData from '../../../services/formatData';
import { createEntry } from '../../../services/entry';
import { addEntriesToTopic } from '../../../services/topics';
import { resetSideSheet } from '../../../actions/SideSheets';
import { resetCurrentTopic, setCurrentTopic } from '../../../actions/Topic';
import DotMenu from './DotMenu';
import MyMaterials from '../../Dashboard/MyMaterials';
import PaginationSupabase from '../../ManageContent/PaginationSupabase';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import Tabs from './Tabs';
import './style.css';

const ImportResourceMaterialSideSheet = ({ className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const materials = useSelector((state) => state.materials);
  const sideSheet = useSelector((state) => state.sideSheet);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id || null;
  // SideSheet Data
  const topicId = sideSheet?.data?.topicId || null;
  // Local State
  const [loading, setLoading] = useState(false);
  const initialListConfig = {
    orgId,
    limit: 10,
    page: 1,
    isDraft: null,
    // workflowType: null,
    order: 'title',
    title: ''
  };
  const [listConfig, setListConfig] = useState(initialListConfig);
  const [searchValue, setSearchValue] = useState(initialListConfig.title);
  const [activeTab, setActiveTab] = useState('materials');
  const [material, setMaterial] = useState(null);
  const [jsonData, setJsonData] = useState(null);

  const onSelectMaterial = (data) => {
    setMaterial(data);
    setJsonData({
      topicMaterials: [{
        title: data.title,
        body: data.body,
        html: true
      }]
    });
  };

  const onClickImport = () => {
    const converter = new Showdown.Converter();

    if (!jsonData.topicMaterials) {
      toast.error('topicMaterials missing.');
      return false;
    }

    if (!Array.isArray(jsonData.topicMaterials)) {
      toast.error('topicMaterials should be an array.');
      return false;
    }

    if (Array.isArray(jsonData.topicMaterials) && jsonData?.topicMaterials.length === 0) {
      toast.error('topicMaterials Array should have at least one item.');
      return false;
    }

    setLoading(true);

    toast.info('Making a copy...');

    const formattedTopicMaterials = jsonData.topicMaterials.map((topicMaterial) => {
      const bodyHTML = converter.makeHtml(topicMaterial.body);

      const formattedData = formatData({
        title: topicMaterial.title,
        body: topicMaterial?.html ? topicMaterial?.body : bodyHTML,
        enableNewEditor: true
      }, 'material');
      return formattedData;
    });

    const sleep = (m) => new Promise((r) => setTimeout(r, m));

    const materialIds = [];

    const promisesMaterials = formattedTopicMaterials.map(async (formattedData, i) => {
      return [
        await createEntry({
          contentType: 'material',
          data: formattedData
        }).then((entry) => {
          materialIds.push(entry?.sys?.id);
        }),
        await sleep(50 * i)
      ];
    });

    if (Array.isArray(promisesMaterials) && promisesMaterials.length !== 0) {
      Promise.all(promisesMaterials).then(() => {
        toast.info('Updating Topic...');
        addEntriesToTopic({
          entryIds: materialIds.length > 0 ? materialIds : null,
          contentType: 'material',
          topicId
        }).then(() => {
          toast.success('Materials imported');
          dispatch(resetCurrentTopic());
          dispatch(setCurrentTopic({ topicId })).then(() => {
            dispatch(resetSideSheet());
          });
        });
      }).catch((error) => {
        setLoading(false);
        toast.error('Something went wrong, refresh the page and try again.');
        console.error(error);
        throw new Error(error);
      });
    } else {
      throw new Error('Something went wrong, try again');
    }
  };

  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex align-items-center justify-content-between'>
        <h6 className="m-0 font-weight-bold">
          <span className="d-flex align-items-center">
            <FaCloudUploadAlt />
            <span className="ml-1 text-nowrap">
              Import Resource Materials
            </span>
          </span>
        </h6>
        <DotMenu />
      </div>
      <div className='card-body overflow-y-scroll p-0'>
        {material?.id ? (
          <div className='position-relative p-3'>
            <h1>
              {material?.title}
            </h1>
            <RenderMarkdown
              source={material?.body}
              enableNewEditor
            />
          </div>
        ) : (
          <>
            <Tabs
              activeTab={activeTab}
              setActiveTab={(newActiveTab) => {
                setListConfig((prev) => ({
                  ...prev,
                  page: 1,
                  ...(newActiveTab === 'myMaterials' ? { userId, isDraft: true, order: '-createdAt' } : {}),
                  ...(newActiveTab === 'materials' ? { userId: undefined, isDraft: null, order: '-title' } : {}),
                  title: ''
                }));

                setSearchValue('');

                setActiveTab(newActiveTab);
              }}
              cardStyle
              className='pt-3'
              setListConfig={setListConfig}
            />

            <div className="w-100 d-flex justify-content-between align-items-center px-3 pt-3">
              <SearchBox
                className="w-100"
                onSubmit={(value) => {
                  setListConfig((prev) => ({
                    ...prev,
                    page: 1,
                    title: value
                  }));
                  setSearchValue(value);
                }}
                onClear={() => {
                  setSearchValue('');
                  const searchBox = document.querySelector(
                    'input[name="searchValue"]'
                  );

                  setListConfig((prev) => ({
                    ...prev,
                    page: 1,
                    title: ''
                  }));

                  searchBox.focus();
                }}
                name="searchValue"
                value={searchValue}
                placeholder="Search by title..."
                autoFocus
              />
            </div>

            <MyMaterials
              className='p-3'
              selectable
              listConfig={listConfig}
              onSelect={onSelectMaterial}
            />
          </>
        )}

      </div>
      <div
        className={`card-footer d-flex align-items-center ${material?.id ? 'justify-content-between' : 'justify-content-end pr-1'}`}
      >
        {material?.id && (
          <>
            <button
              onClick={() => {
                setMaterial(null);
              }}
              disabled={loading}
              type='button'
              className='btn btn-m btn-white'
            >
              <span className='d-flex align-items-center'>
                <FaChevronLeft />
                <span>
                  Back
                </span>
              </span>
            </button>

            <button
              onClick={onClickImport}
              disabled={loading}
              type='button'
              className='btn btn-m btn-primary'
            >
              {loading ? 'Importing...' : 'Copy & Import'}
            </button>
          </>
        )}

        {!material?.id && Array.isArray(materials?.list) && materials?.list.length > 0 && (
          <PaginationSupabase
            className='bg-light'
            items={materials?.list}
            pagination={materials?.pagination}
            page={materials?.pagination?.page}
            onClickNext={() => {
              setListConfig((prev) => ({
                ...prev,
                page: materials?.pagination?.page + 1
              }));
              // goToElem('root');
            }}
            onClickPrev={() => {
              setListConfig((prev) => ({
                ...prev,
                page: materials?.pagination?.page - 1
              }));
              // goToElem('root');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ImportResourceMaterialSideSheet;
