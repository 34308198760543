import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../actions/Courses';
import { getGroups } from '../../actions/Groups';
import Loading from '../Loading';
import List from './List';

const CoursesList = ({
  courseIds,
  list,
  showCheckBoxes = false,
  showContentCol = false,
  showSyndicationCol = false,
  showGroupsCol = false,
  selectedCourseIds,
  handleSelectCourse
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const courses = useSelector((state) => state.courses);
  // Organization
  const orgId = organization?.id || null;

  const hiddenColumns = useMemo(() => {
    const cols = [];

    if (!showContentCol) {
      cols.push('enableCourseContent');
    }

    if (!showGroupsCol) {
      cols.push('groupsCol');
    }

    if (!showSyndicationCol) {
      cols.push('orgIds');
    }

    if (showCheckBoxes === false) {
      cols.push('createdAt'); // Have to use random accessor for this Cell
    }

    return cols;
  }, [showCheckBoxes, showContentCol, showGroupsCol, showSyndicationCol]);

  console.log('hiddenColumns', hiddenColumns);

  useEffect(() => {
    if (courseIds) {
      dispatch(getCourses({ orgId, courseIds }));
    }
  }, [courseIds, dispatch, orgId]);

  useEffect(() => {
    if (orgId) {
      dispatch(getGroups({ orgId, type: 'course' }));
    }
  }, [dispatch, orgId]);

  if (!list && !courses.fetched) {
    return (
      <Loading text="Loading Courses..." className='position-relative' />
    );
  }

  return (
    <div
      data-cy="coursesList"
    >
      <List
        data={list || courses?.list}
        courseIds={courseIds}
        selectedCourseIds={selectedCourseIds}
        handleSelectCourse={handleSelectCourse}
        hiddenColumns={hiddenColumns}
      />
    </div>
  );
};

export default CoursesList;
