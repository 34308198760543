// import { referenceType } from '../contentful';

function formatLocation({
  name,
  subtitle,
  phone,
  fax,
  calendar,
  addressLine1,
  addressLine2,
  addressCity,
  addressState,
  addressPostalCode,
  addressCountry,
  mapLink,
  locationImage,
  managerIds,
  orgId,
  employerProfileId
}) {
  const data = {};

  if (employerProfileId !== undefined) {
    data.employerProfileId = {
      'en-US': employerProfileId
    };
  }

  if (name !== undefined) {
    data.name = {
      'en-US': name
    };
  }

  if (subtitle !== undefined) {
    data.subtitle = {
      'en-US': subtitle
    };
  }

  if (phone !== undefined) {
    data.phone = {
      'en-US': phone
    };
  }

  if (fax !== undefined) {
    data.fax = {
      'en-US': fax
    };
  }

  if (calendar !== undefined) {
    data.calendar = {
      'en-US': calendar
    };
  }

  if (addressLine1 !== undefined) {
    data.addressLine1 = {
      'en-US': addressLine1
    };
  }

  if (addressLine2 !== undefined) {
    data.addressLine2 = {
      'en-US': addressLine2
    };
  }

  if (addressCity !== undefined) {
    data.addressCity = {
      'en-US': addressCity
    };
  }

  if (addressState !== undefined) {
    data.addressState = {
      'en-US': addressState
    };
  }

  if (addressPostalCode !== undefined) {
    data.addressPostalCode = {
      'en-US': addressPostalCode
    };
  }

  if (addressCountry !== undefined) {
    data.addressCountry = {
      'en-US': addressCountry
    };
  }

  if (mapLink !== undefined) {
    data.mapLink = {
      'en-US': mapLink
    };
  }

  if (locationImage !== undefined) {
    data.locationImage = {
      'en-US': locationImage
    };
  }

  if (managerIds !== undefined && managerIds.length) {
    data.managerIds = {
      'en-US': managerIds
    };

    // data.managers = {
    //   'en-US': managerIds.map((managerId) => referenceType(managerId))
    // };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  return { fields: { ...data } };
}

export default formatLocation;
