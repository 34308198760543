import React from 'react';

const TitleCell = ({
  value: title,
  row
}) => {
  const images = row?.original?.images || [];

  return (
    <div className='d-flex align-items-center'>
      {images.length !== 0 && (
        <img
          src={images[0].url}
          alt={title}
          height="30"
          width="30"
          style={{
            height: '30px',
            width: '30px'
          }}
        />
      )}

      <span className="ml-2">
        {title}
      </span>
    </div>
  );
};

export default TitleCell;
