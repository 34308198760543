function convertUrlParamsToObject(locationSearch) {
  const pairs = locationSearch.slice(1).split('&');
  const results = {};

  pairs.forEach((pair) => {
    pair = pair.split('=');
    results[pair[0]] = decodeURIComponent(pair[1] || '');
  });

  return results;
}

export default convertUrlParamsToObject;
