import { RESET_ASSISTANTS, SET_ASSISTANTS } from '../../actions/types';

const initialState = {
  list: null,
  fetched: false,
  pagination: null
};

export default function assistants(state = initialState, action) {
  switch (action.type) {
    case SET_ASSISTANTS: {
      return {
        ...state,
        list: action.list,
        pagination: action.pagination,
        fetched: true
      };
    }
    case RESET_ASSISTANTS:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
