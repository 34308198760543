const logbookState = {
  id: null,
  employerId: null,
  locationId: null,
  apprenticeshipId: null,
  userName: null,
  userId: null,
  orgId: null,
  records: null,
  completedCompetencies: null,
  createdAt: null,
  updatedAt: null,
  // Computed Keys
  recordsHours: 0,
  recordsCount: 0,
  recordsHoursApproved: 0,
  recordsCompetencyHours: 0
};

export default logbookState;
