import currencyFormatter from 'currency-formatter';

/**
 *
 * @param {number} amount
 * @param {string} code
 * @returns
 */
export const formatCurrency = (amount, code = 'USD') => {
  return currencyFormatter.format(amount, { code });
};
