import React from 'react';
import AgentImage from '../../../../routes/AssistantManage/AgentImages/AgentImage';

const NameCell = ({
  value: name,
  row
}) => {
  const agentImage = row?.original?.agentImage || null;
  const colorBtnBkg = row?.original?.colorBtnBkg || null;

  return (
    <div className='d-flex align-items-center'>
      {agentImage && (
        <AgentImage
          imgSrc={agentImage}
          imgName={name}
          bkgColor={colorBtnBkg}
        />
      )}

      <span className="ml-2">
        {name}
      </span>
    </div>
  );
};

export default NameCell;
