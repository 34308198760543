import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import $ from 'jquery';
import {
  ICON_CHALLENGE, ICON_ELLIPSIS, ICON_MATERIAL, ICON_TOPIC
} from '@apprentage/constants';
import { pathOr } from 'ramda';
import { MANAGE_CURRENT_ENTRY } from '../../constants/routes';
import { canManageContent } from '../../services/currentUser';
import { manageEntry } from '../../services/entry';
import './style.css';

class AdminMenu extends Component {
  handleAdminMenuClick = () => {
    $(`topic-${this.props.topicId}`).collapse('hide');
  }

  render() {
    const {
      scrollToId,
      clickButtonId,
      referenceIndex,
      id,
      containerClassName,
      buttonClassName,
      className = '',
      classId,
      topicId,
      entryId,
      contentType,
      addContent,
      editContent,
      addTypes,
      noEntries,
      currentUser,
      organization
    } = this.props;
    const role = pathOr([], ['role'], currentUser);
    const orgType = pathOr('', ['type'], organization);

    if (!canManageContent(role, orgType)) {
      return;
    }

    if (!addContent && !editContent) {
      return;
    }

    return (
      <Fragment>
        <div className={`dropdown ${containerClassName || ''}`}>
          <button
            className={`${buttonClassName || 'btn btn-md btn-link admin-menu-link h-100'}`}
            type="button"
            id={id || 'adminMenu'}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={this.handleAdminMenuClick}
          >
            {this.props.children || <i className={ICON_ELLIPSIS} />}
          </button>
          <div
            className={`dropdown-menu ${className}`}
            aria-labelledby={id || 'adminMenu'}
          >
            {addContent === true && (
              <Fragment>
                <div className="dropdown-collapse-container">
                  <button
                    className="text-left w-100 border-0 bg-white px-3 py-2"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#${id}-above-collapse`}
                    aria-expanded="false"
                    aria-controls={`${id}-above-collapse`}
                  >
                    <i className="fas fa-caret-right" />
                    <i className="fas fa-caret-down" />
                    {noEntries ? 'Add 1 Entry' : 'Insert 1 above'}
                  </button>
                  <div className="collapse p-0" id={`${id}-above-collapse`}>
                    {addTypes && addTypes.includes('material') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertAbove',
                              referenceIndex,
                              classId,
                              topicId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['material']
                            })
                          }}
                          title={noEntries ? 'Add 1 Entry' : 'Insert 1 above'} // no entires : existing entries
                        >
                          <i className={`${ICON_MATERIAL} mr-1`} /> Material
                        </Link>
                      </Fragment>
                    )}

                    {addTypes && addTypes.includes('quiz') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertAbove',
                              referenceIndex,
                              classId,
                              topicId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['quiz']
                            })
                          }}
                          title={noEntries ? 'Add 1 Entry' : 'Insert 1 above'} // no entires : existing entries
                        >
                          <i className="fas fa-list-ol mr-1" /> Quiz
                        </Link>
                      </Fragment>
                    )}

                    {addTypes && addTypes.includes('challenge') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertAbove',
                              referenceIndex,
                              classId,
                              topicId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['challenge']
                            })
                          }}
                          title={noEntries ? 'Add 1 Entry' : 'Insert 1 above'} // no entires : existing entries
                        >
                          <i className={`${ICON_CHALLENGE} mr-1`} /> Challenge
                        </Link>
                      </Fragment>
                    )}

                    {addTypes && addTypes.includes('topic') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertAbove',
                              referenceIndex,
                              classId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['topic']
                            })
                          }}
                          title={noEntries ? 'Add 1 Entry' : 'Insert 1 above'} // no entires : existing entries
                        >
                          <i className={`${ICON_TOPIC} mr-1`} /> Topic
                        </Link>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Fragment>
            )}

            {addContent === true && !noEntries && (
            // Insert 1 Below
            // TODO refactor into component with "above"

              <Fragment>
                <div className="dropdown-divider" />
                <div className="dropdown-collapse-container">
                  <button
                    className="text-left w-100 border-0 bg-white px-3 py-2"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#${id}-below-collapse`}
                    aria-expanded="false"
                    aria-controls={`${id}-below-collapse`}
                  >
                    <i className="fas fa-caret-right" />
                    <i className="fas fa-caret-down" />
                    Insert 1 below
                  </button>
                  <div className="collapse p-0" id={`${id}-below-collapse`}>
                    {addTypes && addTypes.includes('material') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertBelow',
                              referenceIndex,
                              classId,
                              topicId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['material']
                            })
                          }}
                          title="Insert 1 below"
                        >
                          <i className={`${ICON_MATERIAL} mr-1`} /> Material
                        </Link>
                      </Fragment>
                    )}

                    {addTypes && addTypes.includes('quiz') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertBelow',
                              referenceIndex,
                              classId,
                              topicId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['quiz']
                            })
                          }}
                          title="Insert 1 below"
                        >
                          <i className="fas fa-list-ol mr-1" /> Quiz
                        </Link>
                      </Fragment>
                    )}

                    {addTypes && addTypes.includes('challenge') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertBelow',
                              referenceIndex,
                              classId,
                              topicId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['challenge']
                            })
                          }}
                          title="Insert 1 below"
                        >
                          <i className={`${ICON_CHALLENGE} mr-1`} /> Challenge
                        </Link>
                      </Fragment>
                    )}

                    {addTypes && addTypes.includes('topic') && (
                      <Fragment>
                        <div className="dropdown-divider" />
                        <Link
                          className="dropdown-item"
                          to={{
                            pathname: MANAGE_CURRENT_ENTRY,
                            search: manageEntry({
                              manageType: 'insertBelow',
                              referenceIndex,
                              classId,
                              pointerId: entryId,
                              addContent,
                              addTypes: ['topic']
                            })
                          }}
                          title="Insert 1 below"
                        >
                          <i className={`${ICON_TOPIC} mr-1`} /> Topic
                        </Link>
                      </Fragment>
                    )}
                  </div>
                </div>
                <div className="dropdown-divider" />
              </Fragment>
            )}

            {editContent === true && (
              <Link
                className="dropdown-item"
                to={{
                  pathname: MANAGE_CURRENT_ENTRY,
                  search: manageEntry({
                    scrollToId,
                    clickButtonId,
                    manageType: 'edit',
                    referenceIndex,
                    classId,
                    topicId,
                    entryId,
                    contentType
                  })
                }}
                title="Edit"
              >
                <i className="fas fa-pencil-alt mr-1" /> Edit
              </Link>
            )}

          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ currentUser, organization }) => ({ currentUser, organization });

export default compose(
  withRouter,
  connect(mapStateToProps)
)(AdminMenu);
