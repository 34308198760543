import {
  SET_CLASS_CERTIFICATE, RESET_CLASS_CERTIFICATE
} from '../../actions/types';

const initialState = {
  student: null,
  attendance: null,
  id: '', // This "id" is the classId, not a certificate "id"
  classTitle: '',
  // TODO is courseCohorts needed?
  currentCohort: null
};

export default function classCertificate(state = initialState, action) {
  switch (action.type) {
    case SET_CLASS_CERTIFICATE:
      return {
        ...state,
        ...action.classCertificate
      };
    case RESET_CLASS_CERTIFICATE: {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
}
