import React from 'react';
import AdminMenu from '../../AdminMenu';
import contentTypeIcon from '../../../services/contentTypeIcon';
import isPublished from '../../../utils/isPublished';

const defaultContent = {
  title: 'No entries',
  id: undefined,
  contentType: 'None'
};

const showIcon = ({ contentType }) => {
  switch (contentType) {
    case 'material':
      return true;
    case 'challenge':
      return true;
    case 'quiz':
      return true;
    default:
      return false;
  }
};

const ReferenceDnd = ({
  index,
  innerRef,
  provided,
  //
  referenceIndex,
  content,
  classId,
  topicId,
  addContent,
  editContent,
  addTypes
}) => {
  const {
    // eUnit & eUnitTracking
    // code,
    // timeDate,
    // currentSession,
    // Topic
    title,
    category,
    level,
    featured,
    locked,
    prerequisite,
    activeQuiz,
    activeChallenge,
    id: entryId, // TODO better naming, technically this can be topicId
    contentType
  } = content && isPublished(content) ? content : defaultContent;

  const noEntries = content === undefined;

  return (
    <div
      className="reference d-flex justify-content-between align-items-center border rounded"
      ref={innerRef}
      {...provided.draggableProps}
    >
      <div className="d-flex align-items-center">
        <div
          className="item-drag-handle px-2"
          style={{ height: '120px' }}
          {...provided.dragHandleProps}
        >
          <i className="fas fa-grip-vertical" />
        </div>

        <span className="px-3">
          <div className="d-flex align-items-end">
            <div className="d-flex align-items-center">
              {showIcon({ contentType }) && (
                <div className="d-none d-lg-flex">
                  <span className="h3 mr-3 text-muted">
                    {contentTypeIcon({ contentType })}
                  </span>
                </div>
              )}
              <div>
                <div className="d-flex align-items-center">
                  <span className="text-muted text-capitalize">
                    {contentType} { contentType === 'topic' && index + 1 }
                  </span>

                  {contentType === 'quiz' && activeQuiz !== undefined && (
                    <span className="h6 m-0 ml-1">
                      <span className={`badge badge-${activeQuiz === true ? 'success' : 'danger'}`}>
                        {activeQuiz === true ? 'Active' : 'Inactive'}
                      </span>
                    </span>
                  )}

                  {contentType === 'challenge' && activeChallenge !== undefined && (
                    <span className="h6 m-0 ml-1">
                      <span className={`badge badge-${activeChallenge === true ? 'success' : 'danger'}`}>
                        {activeChallenge === true ? 'Active' : 'Inactive'}
                      </span>
                    </span>
                  )}

                  {contentType === 'topic' && featured && (
                    <span className="h6 m-0 ml-1">
                      <span className="badge badge-warning">Featured</span>
                    </span>
                  )}
                  {contentType === 'topic' && locked && (
                    <i className="fas fa-lock ml-1" />
                  )}
                </div>

                {!contentType.includes('eUnit') && (
                  <h6 className="my-1">
                    { title }
                  </h6>
                )}
                <div className="align-items-end d-none d-lg-flex">
                  {contentType === 'topic' && isPublished(prerequisite) && ( // TODO topic flatten
                    <div className="text-muted mr-3 border-right pr-3">
                      Prerequisite: <b>{prerequisite.fields.title}</b>
                    </div>
                  )}

                  {contentType === 'topic' && category && (
                    <span className="text-muted mr-3 border-right pr-3">
                      Category: { category ? (<span className="h6">{category.map((cat, i) => (<span key={`cat-${i}`} className="badge border mr-1">{cat}</span>))}</span>) : 'n/a' }
                    </span>
                  )}

                  {contentType === 'topic' && level && (
                    <span className="text-muted">
                      Level: { level ? (<span className="h6">{level.map((lev, i) => (<span key={`level-${i}`} className="badge border mr-1">{lev}</span>))}</span>) : 'n/a' }
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>

      <div className="reference-action btn-group align-items-center px-2 py-3">
        <AdminMenu
          referenceIndex={noEntries ? 0 : referenceIndex}
          id={`admin-menu-${classId}-${entryId || topicId}`}
          className="admin-menu--reference dropdown-menu-right"
          classId={classId}
          topicId={topicId}
          entryId={entryId}
          title={title}
          noEntries={noEntries}
          contentType={contentType}
          addContent={addContent}
          editContent={noEntries ? false : editContent}
          addTypes={addTypes}
        />
      </div>
    </div>
  );
};

export default ReferenceDnd;
